import { StorageService } from './../../../core/services/storage/storage.service';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Keys, LessonType, ProgressDTO, StudentRoutes } from 'src/app/core/model';
import { RedirectInfo } from 'src/app/shared/models';
import { environment } from 'src/environment';
import { getGroupAction } from "../../../core/state/student/groups/groups.actions";
import { Store } from "@ngrx/store";

@Injectable({
  providedIn: 'root'
})

export class StudentHelperService {

  private updateDeadlineSubject = new Subject<null>();
  public updateDeadline$ = this.updateDeadlineSubject.asObservable();

  constructor(
    private storageService: StorageService,
    private store: Store
  ) {}

  public getPicturePath(imageUrl: string): string {
    return `${environment.filesUrl}${imageUrl}`;
  }

  public generateLessonsSiblingsRoute(item: RedirectInfo | ProgressDTO): string {
    let lessonSubRoute = StudentRoutes.Theory;
    switch(item.lessonType) {
      case LessonType.Homework: {
        lessonSubRoute = StudentRoutes.Homework;
        break;
      }
      case LessonType.Quiz: {
        lessonSubRoute = StudentRoutes.Test;
        break;
      }
      case LessonType.WorkOnMistakes: {
        lessonSubRoute = StudentRoutes.WorkOnMistakes
      }
    }
    const route = lessonSubRoute ===
    StudentRoutes.Test ? `${lessonSubRoute}/${StudentRoutes.TestStart}/${item.groupId}/${item.courseId}/${item.id}`
    : `${StudentRoutes.Education}/${lessonSubRoute}/${item.groupId}/${item.id}`
    return route;
  }

  public updateDeadline() {
    this.updateDeadlineSubject.next(null);
  }

  public setCurrentGroupId(groupId: string | null): void {
    if(groupId) {
      this.storageService.setItem(Keys.CurrGroupId, groupId)
    }
  }

  public getCurrentGroupId(): string | null {
    return this.storageService.getItem(Keys.CurrGroupId);
  }

  public updateSideBarThemes() {
    this.store.dispatch(getGroupAction({groupId: this.getCurrentGroupId() as string, direction: this.storageService.getItem(Keys.SortType) === 'SORT_BY_NEW' ? 'DESC' : 'ASC'}));
  }
}
