<div class="admin-modal-curator-card border radius-8">
  <div class="flex flex-col wrapper">
    <div class="course-name">{{ group.courseName }}</div>
    <div class="info flex items-center">
      <div class="info__item" *ngIf="group.streamYear || group.streamMonth">
        {{ group.streamMonth | monthConverter }} - {{ group.streamYear }}
      </div>

      <div
        class="circle"
        *ngIf="(group.streamMonth || group.streamYear) && group.name"
      ></div>
      <div class="info__item">
        {{ group.name }}
      </div>
      <div class="circle" *ngIf="group.studentCount"></div>
      <div class="info__item">{{'OF_STUDENTS' | translate: {count: group.studentCount} }} </div>
    </div>
  </div>
</div>
