import {CuratorService} from 'src/app/core/services/curator/curator.service';
import {LessonsService} from '../../../../../core/services/lessons/lessons.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output, ViewChild
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {IShortLesson, LessonCreateResponse, LessonDTO, LessonType, MethodistRoutes, Roles} from 'src/app/core/model';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {MethodistService} from '../../../../../core/services/methodist/methodist.service';
import {HeadTeacherService} from 'src/app/core/services/head-teacher/head-teacher.service';
import {EducationService} from "../../../../../shared/services/education/education.service";
import {ActivatedRoute} from "@angular/router";
import {HeaderService} from "../../../../../core/services/header/header.service";

@Component({
  selector: 'app-lessons-item',
  templateUrl: './lessons-item.component.html',
  styleUrls: ['./lessons-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LessonsItemComponent implements OnInit {
  @ViewChild('menuButton') menuButton!: ElementRef;

  @Input() shortLesson!: IShortLesson;
  @Input() isOpen: boolean = false;
  @Input() groupId: string | undefined;
  @Input() themeId: string | undefined;
  @Output() toggle: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() removedEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() addLessonToCourse: EventEmitter<string> = new EventEmitter<string>();
  public saveSubject: Subject<void> = new Subject<void>();

  public lesson: LessonDTO | undefined;
  public lessonType = LessonType;
  public nameForm!: FormGroup;
  public isNameEditingMode: boolean = false;

  public allowSave: boolean = false;
  public isOpenList: boolean = false;

  public readonly Roles = Roles;
  public role: Roles = Roles.Methodist;

  public isDeleteModalOpen: boolean = false;
  public isLoading: boolean = false;
  public lessonIdToDelete: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private lessonsService: LessonsService,
    private cdr: ChangeDetectorRef,
    private curatorService: CuratorService,
    private authService: AuthService,
    private methodistService: MethodistService,
    private headTeacherService: HeadTeacherService,
    private educationService: EducationService,
    private route: ActivatedRoute,
    private headerService: HeaderService
  ) {
    this.nameForm = this.formBuilder.group({
      name: ['', Validators.required],
    })
  }

  ngOnInit(): void {
    this.role = this.authService.getRole();
    this.nameForm.patchValue({name: this.shortLesson.name})
  }

  public activateNameEditingMode(): void {
    this.isNameEditingMode = true;
  }
  public deactivateNameEditingMode(): void {
    this.isNameEditingMode = false;
  }
  public saveName(): void {
    this.lessonsService.editLessonName(this.shortLesson.id, this.nameForm.get('name')!.value).subscribe({
      next: (res) => {
        this.isNameEditingMode = false;
        console.log(res);
        this.cdr.detectChanges();
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  public toggleExpander(): void {
    switch (this.role) {
      case Roles.Curator: {
        if(!this.isOpen) {
          this.curatorService.getLesson(this.groupId!, this.shortLesson.id).subscribe({
            next: (res) => {
              if(res) {
                this.lesson = res;
                this.toggle.emit(!this.isOpen)
              }
              this.cdr.detectChanges();
            },
            error: (err) => {
              console.log(err);
            }
          })
        }
        else {
          this.toggle.emit(!this.isOpen)
        }
        break;
      }
      case Roles.HeadTeacher: {
        if(!this.isOpen) {
          this.headTeacherService.getLesson(this.groupId!, this.shortLesson.id).subscribe({
            next: (res) => {
              if(res) {
                this.lesson = res;
                this.toggle.emit(!this.isOpen);
                this.cdr.detectChanges();
              }
            },
            error: (err) => {
              console.log(err);
            }
          })
        }
        else {
          this.toggle.emit(!this.isOpen)
        }
        break;
      }
      case Roles.Methodist: {
        if(!this.isOpen) {
          this.methodistService.getLesson(this.shortLesson.id).subscribe({
            next: (res) => {
              if(res) {
                this.lesson = res;
                this.toggle.emit(!this.isOpen)
              }
              this.cdr.detectChanges();
            },
            error: (err) => {
              console.log(err);
            }
          })
        }
        else {
          this.toggle.emit(!this.isOpen)
        }
        break;
      }
      default: {
        this.toggle.emit(!this.isOpen)
      }
    }
  }

  public emitSaveToChild(): void {
    this.toggle.emit(false);
    this.saveSubject.next();
  }

  public toggleAllowSave(allow: boolean): void {
    this.allowSave = allow;
  }

  public lessonSaved(lesson: LessonDTO): void {
    this.lesson = lesson;
    this.cdr.detectChanges()
  }

  public edit(): void {
    switch (this.role) {
      case Roles.Curator: {
        this.curatorService.getLesson(this.groupId!, this.shortLesson.id).subscribe({
            next: (res) => {
              if(res) {
                this.lesson = res;
                this.toggle.emit(true)
              }
              this.cdr.detectChanges();
            },
            error: (err) => {
              console.log(err);
            }
          })
        break;
      }
      case Roles.HeadTeacher: {
          this.headTeacherService.getLesson(this.groupId!, this.shortLesson.id).subscribe({
            next: (res) => {
              if(res) {
                this.lesson = res;
                this.toggle.emit(true)
              }
              this.cdr.detectChanges()
            },
            error: (err) => {
              console.log(err);
            }
          })
        break;
      }
      case Roles.Methodist: {
          this.methodistService.getLesson(this.shortLesson.id).subscribe({
            next: (res) => {
              if(res) {
                this.lesson = res;
                this.toggle.emit(!this.isOpen)
              }
              this.cdr.detectChanges();
            },
            error: (err) => {
              console.log(err);
            }
          })
        break;
      }
      default: {
        this.toggle.emit(true)
      }
    }
  }

  public duplicate(): void {
    if(this.role === Roles.Curator) {
      this.curatorService.duplicateLesson(this.groupId!, this.shortLesson.id).subscribe({
        next: (res) => {
          location.reload();
        },
        error: (err) => {
          console.log(err);
        }
      })
    }
    else {
      this.lessonsService.duplicateLessonById(this.shortLesson.id).subscribe(res => {
        location.reload();
      })
    }
  }

  public delete(id: string): void {
    if(this.role === Roles.Methodist || this.role === Roles.Curator) {
      this.isLoading = true;
      this.getService(this.shortLesson.id)
        .subscribe({
          next: () => {
            this.removedEvent.emit(this.shortLesson.id);
            this.isDeleteModalOpen = false;
            this.lessonIdToDelete = '';
            this.isLoading = false;
            this.cdr.detectChanges();
          },
          error: err => {
            console.error('Error:', err);
            this.isLoading = false;
          }
        });
    }
  }

  private getService(lessonId: string): Observable<LessonCreateResponse | boolean> {
    switch (this.role) {
      case Roles.Methodist:
        return this.lessonsService.deleteLessonById(lessonId);
      default:
        return this.curatorService.removeLessonById(lessonId);
    }
  }

  public onToggleChange(status: any): void {
    this.lessonsService.publishLesson(this.shortLesson.id, status.checked)
      .subscribe({
        next: (res) => {
          this.shortLesson.published = res.published;
          this.cdr.detectChanges();
        },
        error: _ => {
          status.source.checked = false;
          this.shortLesson.published = false;
          this.cdr.detectChanges();
        }
      });
  }

  public navigate() {
    if (this.role === Roles.Methodist) {
      const courseId = this.route?.snapshot?.params['id'];
      if (courseId && this.themeId) {
        this.headerService.setBackButtonValue(`/${Roles.Methodist.toLowerCase()}/${MethodistRoutes.Course}/${courseId}?themeId=${this.themeId}`);
      }
    }
    this.educationService.navigateByLessonType(this.shortLesson as LessonDTO, this.groupId);
  }

  openDeleteModal(lessonId: string = '') {
    this.lessonIdToDelete = lessonId;
    this.isDeleteModalOpen = true;
  }

  closeDeleteModal() {
    this.isDeleteModalOpen = false;
  }

  toggleMenu(): void {
    this.isOpenList = !this.isOpenList;
  }

  addToCourse(): void {
    this.addLessonToCourse.emit(this.shortLesson?.id);
  }

  get isCanDelete(): boolean {
    return this.role !== Roles.HeadTeacher;
  }
}
