import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild, Output, EventEmitter, OnChanges } from '@angular/core';
import { ForAnswerTestQuestions, MiniTestAnswer, QuizState, UserAnswer } from 'src/app/core/model';

@Component({
  selector: 'app-default-question',
  templateUrl: './default-question.component.html',
  styleUrls: ['./default-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultQuestionComponent implements OnChanges {
  @ViewChild('parentDiv') parentDiv!: ElementRef;

  public readonly _QuizState = QuizState;
  @Input() quizState: QuizState = QuizState.Passing;
  @Input() question!: ForAnswerTestQuestions;
  @Input() disabled: boolean = false;
  @Output() answeredEvent: EventEmitter<{ questionId: string, answer: UserAnswer[]}> = new EventEmitter<{ questionId: string, answer: UserAnswer[]}>();

  public userAnswer: string = "";
  public correctAnswer: string = "";

  constructor() {}

  ngOnChanges(): void {
    this.userAnswer = (this.question.userAnswers && this.question.userAnswers[0]?.answerId) ? this.question.userAnswers[0]?.answerId : "";

    if(this.quizState === QuizState.View && this.question.correctAnswerTexts) {
      this.correctAnswer = this.question.correctAnswerTexts[0];
    }
    console.log(this.question)

  }

  public triggeredClick(option: MiniTestAnswer): void {
    if(this.quizState === QuizState.View) {
      return;
    }

    this.userAnswer = option.id;
    this.answeredEvent.emit({ questionId: this.question.id, answer: [{answerId: option.id, answerTextValue: option.answerText}]});
  }
}
