<app-form-field [name]="name" [focused]="focused" [isActive]="isActive" [error]="error" [control]="(ngControl.control)">
    <div class="input-component">
        <input
            #inputField
            type="date"
            class="iconic-input"
            (focus)="onFocus()"
            (blur)="onBlur()"
            [formControl]="ngControl.control"
            [min]="minDate"
            [placeholder]="placeholder"
            [ngClass]="{'safari-properties': isSafari, 'has-value': ngControl.control && ngControl.control.value}"
        />
    </div>
</app-form-field>
