<app-form-field
  [name]="name"
  [focused]="focused"
  [isActive]="isActive"
  [error]="error"
  [control]="ngControl.control"
>
  <div class="input-component">
    <input
      #inputField
      [placeholder]="placeholder | translate"
      class="iconic-input"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [prefix]="prefix"
      [suffix]="suffix"
      [pattern]="pattern"
      [mask]="mask"
      [formControl]="ngControl.control"
      [dropSpecialCharacters]="dropSpecialCharacters"
    />

    <div (click)="clearInput($event)" class="delete-button">
      <img
        [hidden]="!focused"
        src="assets/svg/circles/close-circle.svg"
        alt=""
      />
    </div>
  </div>
</app-form-field>
