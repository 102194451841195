import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MethodistUser, Roles, StudentUser } from 'src/app/core/model';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { SubjectsService } from './../../../../core/services/subjects/subjects.service';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from './../../../services/helper/helper.service';
import { UsersService } from 'src/app/core/services/users/users.service';
import { AdminService } from 'src/app/core/services/admin/admin.service';

@Component({
  selector: 'app-contact-info-editor',
  templateUrl: './contact-info-editor.component.html',
  styleUrls: ['./contact-info-editor.component.scss']
})
export class ContactInfoEditorComponent implements OnInit {
  @Input() data!: StudentUser | MethodistUser;
  @Input() isOpen: boolean = false;
  @Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmEvent: EventEmitter<StudentUser | MethodistUser> = new EventEmitter<StudentUser | MethodistUser>();

  @Input() notRegisteredUser: boolean = false;

  public readonly Roles = Roles;
  public userForm!: FormGroup;

  public userFormGroupError: any = {
    email: '',
    phoneNumber: ''
  };

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private subjectsService: SubjectsService,
    private translateService: TranslateService,
    private helperService: HelperService,
    private usersService: UsersService,
    private adminService: AdminService
  ) {}

  ngOnInit() {
    this.userForm = this.formBuilder.group({});

    if (this.notRegisteredUser) {
      if (this.data?.username) {
        if (this.data.username.startsWith('+')) {
          this.userForm.setControl('phoneNumber', new FormControl(this.data.username, [Validators.required]));
          this.phoneTyping();
        } else {
          this.userForm.setControl('email', new FormControl(this.data.username, [Validators.required, Validators.email]));
          this.emailTyping();
        }
      }
    } else {
      if (this.data?.username) {
        this.userForm.setControl('email', new FormControl(this.data.username, [Validators.required, Validators.email]));
        this.emailTyping();
      }

      if (this.data?.phoneNumber) {
        this.userForm.setControl('phoneNumber', new FormControl(this.data.phoneNumber.substring(2), [Validators.required]));
        this.phoneTyping();
      }
    }
  }

  phoneTyping(): void {
    if(this.userForm.get('phoneNumber')?.valid) {
      const value = this.userForm.get('phoneNumber')!.value;
      if (value === this.data?.phoneNumber?.substring(2)) {
        this.userFormGroupError.phoneNumber = '';
        this.userForm.get('phoneNumber')?.setErrors({ customError: true });
        this.cdr.detectChanges();
      } else {
        this.setPhoneNullErrors();
      }
    }
  }

  emailTyping(): void {
    if(this.userForm.get('email')?.valid) {
      const value = this.userForm.get('email')!.value;
      if (value === this.data.username) {
        this.userFormGroupError.email = '';
        this.userForm.get('email')?.setErrors({ customError: true });
        this.cdr.detectChanges();
      } else {
        this.setMailNullErrors();
      }
    }
  }

  private setMailNullErrors(): void {
    this.userFormGroupError.email = "";
    this.userForm.get('email')?.setErrors(null);
    this.cdr.detectChanges();
  }

  private setPhoneNullErrors(): void {
    this.userFormGroupError.phoneNumber = "";
    this.userForm.get('phoneNumber')?.setErrors(null);
    this.cdr.detectChanges();
  }

  editProfile(): void {
    const formData = this.userForm.getRawValue();
    if (formData.phoneNumber) {
      formData.phoneNumber = this.helperService.convertPhoneNumberForSave(formData.phoneNumber);
    }

    let fromUserName = '';
    let toUserName = '';
    if (this.notRegisteredUser) {
      fromUserName = this.data.username;
      toUserName = formData.email || formData.phoneNumber;
    } else {
      if (formData.email !== this.data.username) {
        fromUserName = this.data.username;
        toUserName = formData.email;
      } else if (formData.phoneNumber !== this.data.phoneNumber) {
        fromUserName = this.data.phoneNumber;
        toUserName = formData.phoneNumber;
      }
    }

    this.adminService.discountsMigrate(fromUserName, toUserName)
      .subscribe({
        next: _ => {
          let res = {...this.data};
          if (this.notRegisteredUser) {
            res = {...res, username: formData.email || formData.phoneNumber};
          } else {
            res = {...res, ...formData, username: formData.email || formData.phoneNumber};
          }
          this.confirmEvent.emit(res);
          this.cdr.detectChanges();
        }
      });
  }
}
