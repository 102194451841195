import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import {  LessonCreateRequest,
          LessonCreateResponse,
          MiniTestQuestionsListRequest,
          MiniTestQuestion,
          MiniTestQuestionAnswerRequest,
          LessonDTO,
          LectureDTO,
          HomeworkDTO,
          MiniTestCreateRequest,
          QuizDTO,
          QuizQuestionDTO,
          QuizQuestianResponseDTO,
          TestQuestionCustom, WorkOnMistakesDTO} from '../../model';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LessonsService {
  private activeLessonIdSubject: BehaviorSubject<string | undefined>;

  constructor(private httpClient: HttpClient) {
    this.activeLessonIdSubject = new BehaviorSubject<string | undefined>(undefined);
  }

  public setActiveLesson(id: string): void {
    this.activeLessonIdSubject.next(id);
  }

  public resetActiveLesson(): void {
    this.activeLessonIdSubject.next(undefined);
  }

  public getActiveLesson(): BehaviorSubject<string | undefined> {
    return this.activeLessonIdSubject;
  }

  public getLessonById(lessonId : string, groupId?: string): Observable<LectureDTO & HomeworkDTO & QuizDTO> {
    let params = new HttpParams().set('groupId', groupId || '');
    return this.httpClient.get<LectureDTO & HomeworkDTO & QuizDTO & WorkOnMistakesDTO>(`/v1/lessons/${lessonId}`, {params}).pipe(
      map((res: LectureDTO & HomeworkDTO & QuizDTO) => {
        return res;
      })
    )
  }

  updateLectureById(groupId: string, themeId: string, lectureId: string, data: LectureDTO): Observable<LessonDTO> {
    let params = new HttpParams().set('themeId', themeId || '').set('groupId', groupId || '');

    return this.httpClient.put<LessonDTO>(`/v1/lessons/lectures/${lectureId}`, data, { params }).pipe(
      map((res: LessonDTO) => {
        return res;
      })
    );
  }

  updateTextById(groupId: string, themeId: string, textId: string, data: LectureDTO): Observable<LessonDTO> {
    let params = new HttpParams().set('themeId', themeId || '').set('groupId', groupId || '');

    return this.httpClient.put<LessonDTO>(`/v1/lessons/texts/${textId}`, data, { params }).pipe(
      map((res: LessonDTO) => {
        return res;
      })
    );
  }

  public duplicateLessonById(lessonId: string): Observable<LessonCreateResponse> {
    return this.httpClient.post<LessonCreateResponse>(`/v1/lessons/${lessonId}/duplicate`, {}).pipe(
      map((res: LessonCreateResponse) => {
        return res;
      })
    );
  }
  public deleteLessonById(lessonId: string): Observable<LessonCreateResponse> {
    return this.httpClient.delete<LessonCreateResponse>(`/v1/lessons/${lessonId}`, {}).pipe(
      map((res: LessonCreateResponse) => {
        return res;
      })
    );
  }
  public getLessonsList(themeId: string): Observable<LessonDTO[]> {
    return this.httpClient.get<LessonDTO[]>(`/v1/lessons`, { params: { themeId } }).pipe(
      map((res:LessonDTO[]) => {
        return res;
      })
    );
  };
  public createLesson(data: LessonCreateRequest): Observable<LessonCreateResponse> {
    return this.httpClient.post<LessonCreateResponse>(`/v1/lessons`, data).pipe(
      map((res: LessonCreateResponse) => {
        return res;
      })
    );
  }

  public updateHomeworksById(groupId: string, themeId: string, homeworkId: string, data: HomeworkDTO): Observable<LessonDTO> {
    let params = new HttpParams().set('themeId', themeId || '').set('groupId', groupId || '');

    return this.httpClient.put<LessonDTO>(`/v1/lessons/homeworks/${homeworkId}`, data, {params}).pipe(
      map((res: LessonDTO) => {
        return res;
      })
    )
  }

  public updateWorkOnMistakes(groupId: string, themeId: string, workOnMistakesId: string, data: WorkOnMistakesDTO): Observable<LessonDTO> {
    let params = new HttpParams().set('themeId', themeId || '').set('groupId', groupId || '');

    return this.httpClient.put<LessonDTO>(`/v1/lessons/workonmistakes/${workOnMistakesId}`, data, {params}).pipe(
      map((res: LessonDTO) => {
        return res;
      })
    )
  }

  public miniTestQuestionsList(lectureId: string): Observable<MiniTestQuestion[]> {
    return this.httpClient.get<MiniTestQuestion[]>(`/v1/lessons/lectures/${lectureId}/minitestquestions`).pipe(
      map((res: MiniTestQuestion[]) => {
        return res;
      })
    );
  }
  public miniTestCreateQuestion(lectureId: string, data: MiniTestCreateRequest): Observable<MiniTestQuestion> {
    return this.httpClient.post<MiniTestQuestion>(`/v1/lessons/lectures/${lectureId}/minitestquestions`, data).pipe(
      map((res: MiniTestQuestion) => {
        return res;
      })
    );
  }
  public miniTestUpdateQuestion(lectureId: string, miniTestQuestionId: string, data: MiniTestCreateRequest): Observable<MiniTestQuestion> {
    return this.httpClient.put<MiniTestQuestion>(`/v1/lessons/lectures/${lectureId}/minitestquestions/${miniTestQuestionId}`, data).pipe(
      map((res: MiniTestQuestion) => {
        return res;
      })
    );
  }
  public miniTestRemoveQuestion(lectureId: string, miniTestQuestionId: string): Observable<any> {
    return this.httpClient.delete<any>(`/v1/lessons/lectures/${lectureId}/minitestquestions/${miniTestQuestionId}`).pipe(
      map(res => {
        return res;
      })
    )
  }
  public miniTestAnswerQuestion(id: MiniTestQuestionAnswerRequest, data: MiniTestQuestion): Observable<MiniTestQuestion> {
    return this.httpClient.put<MiniTestQuestion>(`/v1/lessons/${id}`, data).pipe(
      map((res: MiniTestQuestion) => {
        return res;
      })
    );
  }

  public updateQuiz(groupId: string, themeId: string, quizLessonId: string, data: QuizDTO): Observable<LessonDTO> {
    let params = new HttpParams().set('themeId', themeId || '').set('groupId', groupId || '');

    return this.httpClient.put<LessonDTO>(`/v1/lessons/quizzes/${quizLessonId}`, data, { params }).pipe(
      map((res:LessonDTO) => {
        return res;
      })
    )
  }

  public deleteQuiz(quizLessonId: string, questionId: string): Observable<void> {
    return this.httpClient.delete<void>(`/v1/lessons/quizzes/${quizLessonId}/questions/${questionId}`).pipe(
      map((res:void) => {
        return res;
      })
    )
  }

  public getQuizQuestionsList(quizLessonId: string): Observable<QuizQuestionDTO[]> {
    return this.httpClient.get<QuizQuestionDTO[]>(`/v1/lessons/quizzes/${quizLessonId}/questions`).pipe(
      map(res => {
        return res;
      })
    )
  }
  public createTestQuiz(quizLessonId: string, data: QuizQuestionDTO): Observable<QuizQuestianResponseDTO> {
    return this.httpClient.post<QuizQuestianResponseDTO>(`/v1/lessons/quizzes/${quizLessonId }/questions/test`, data).pipe(
      map(res => {
        return res;
      })
    )
  };
  public updateTestQuiz(quizLessonId: string, questionId: string, data: TestQuestionCustom): Observable<QuizQuestianResponseDTO> {
    return this.httpClient.put<QuizQuestianResponseDTO>(`/v1/lessons/quizzes/${quizLessonId }/questions/test/${questionId}`, data ).pipe(
      map(res => {
        return res;
      })
    )
  };
  public createMultidropdownQuiz(quizLessonId: string, data: QuizQuestionDTO): Observable<QuizQuestianResponseDTO> {
    return this.httpClient.post<QuizQuestianResponseDTO>(`/v1/lessons/quizzes/${quizLessonId }/questions/multidropdown`, data).pipe(
      map(res => {
        return res;
      })
    )
  };
  public updateMultidropdownQuiz(quizLessonId: string, questionId: string, data: TestQuestionCustom): Observable<QuizQuestianResponseDTO> {
    return this.httpClient.put<QuizQuestianResponseDTO>(`/v1/lessons/quizzes/${quizLessonId }/questions/multidropdown/${questionId}`, data ).pipe(
      map(res => {
        return res;
      })
    )
  };

  public createOpenQuiz(quizLessonId: string, data: QuizQuestionDTO): Observable<QuizQuestianResponseDTO> {
    return this.httpClient.post<QuizQuestianResponseDTO>(`/v1/lessons/quizzes/${quizLessonId }/questions/open`, data).pipe(
      map(res => {
        return res;
      })
    )
  };
  public updateOpenQuiz(quizLessonId: string, questionId: string, data: TestQuestionCustom): Observable<QuizQuestianResponseDTO> {
    return this.httpClient.put<QuizQuestianResponseDTO>(`/v1/lessons/quizzes/${quizLessonId }/questions/open/${questionId}`, data).pipe(
      map(res => {
        return res;
      })
    )
  };

  public createMatchingQuiz(quizLessonId: string, data: QuizQuestionDTO): Observable<QuizQuestianResponseDTO> {
    return this.httpClient.post<QuizQuestianResponseDTO>(`/v1/lessons/quizzes/${quizLessonId }/questions/matching`, data).pipe(
      map(res => {
        return res;
      })
    )
  };
  public updateMatchingQuiz(quizLessonId: string, questionId: string, data: TestQuestionCustom): Observable<QuizQuestianResponseDTO> {
    return this.httpClient.put<QuizQuestianResponseDTO>(`/v1/lessons/quizzes/${quizLessonId }/questions/matching/${questionId}`, data).pipe(
      map(res => {
        return res;
      })
    )
  };

  public createDragAndDropQuiz(quizLessonId: string, data: QuizQuestionDTO): Observable<QuizQuestianResponseDTO> {
    return this.httpClient.post<QuizQuestianResponseDTO>(`/v1/lessons/quizzes/${quizLessonId }/questions/dragndrop`, data).pipe(
      map(res => {
        return res;
      })
    )
  };
  public updateDragAndDropQuiz(quizLessonId: string, questionId: string, data: TestQuestionCustom): Observable<QuizQuestianResponseDTO> {
    return this.httpClient.put<QuizQuestianResponseDTO>(`/v1/lessons/quizzes/${quizLessonId }/questions/dragndrop/${questionId}`, data).pipe(
      map(res => {
        return res;
      })
    )
  };

  public createFillQuiz(quizLessonId: string, data: QuizQuestionDTO): Observable<QuizQuestianResponseDTO> {
    return this.httpClient.post<QuizQuestianResponseDTO>(`/v1/lessons/quizzes/${quizLessonId }/questions/fill`, data).pipe(
      map(res => {
        return res;
      })
    )
  };
  public updateFillQuiz(quizLessonId: string, questionId: string, data: TestQuestionCustom): Observable<QuizQuestianResponseDTO> {
    return this.httpClient.put<QuizQuestianResponseDTO>(`/v1/lessons/quizzes/${quizLessonId }/questions/fill/${questionId}`, data).pipe(
      map(res => {
        return res;
      })
    )
  };

  public publishLesson(lessonId: string, publish: boolean): Observable<LessonDTO> {
    return this.httpClient.patch<LessonDTO>(`/v1/lessons/${lessonId}/publish`, {}, { params: { publish: publish } });
  }

  public editLessonName(lessonId: string, name: string): Observable<LessonDTO> {
    return this.httpClient.patch<LessonDTO>(`/v1/lessons/${lessonId}/rename`, { name})
  }

  public isOpenedForAll(lessonId: string): Observable<any> {
    return this.httpClient.get<any>(`/v1/lessons/${lessonId}/is-opened-for-all`);
  }

  public correctLessonsSpreading(lessonId: string): Observable<any> {
    return this.httpClient.post<any>(`/v1/lessons/${lessonId}/correct-modifications`, {});
  }
}
