<div class="app-test-result">
  <img *ngIf="byWhat === testFinished.ByUser" src="assets/img/congrats.png" alt=""/>
  <img *ngIf="byWhat === testFinished.ByTime" src="assets/img/sad-smile.png" alt=""/>
  <h1
    class="title">{{ byWhat === testFinished.ByUser ? ('congratulations' | translate) : ('timeIsUp' | translate)}}</h1>
  <div class="score">
    <img src="assets/svg/informative/base-verified.svg" alt=""/>
    <span class="score-text">{{'scores' | translate}} <span class="bold">{{ (score | localizedNumberFormat) + '/' + (maxScore | localizedNumberFormat)}}</span></span>
  </div>
  <button class="iconic-button primary-button button" (click)="toHome()">{{'toMain' | translate}}</button>
</div>
