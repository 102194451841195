import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideNgxMask, NgxMaskDirective } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { FormFieldComponent } from './form-field/form-field.component';
import { InputComponent } from './input/input.component';
import { InputPasswordComponent } from './input-password/input-password.component';
import { SelectComponent } from './select/select.component';
import { SearchComponent } from './search/search.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { TextareaComponent } from './textarea/textarea.component';
import { ControlErrorMessageComponent } from './control-error-message/control-error-message.component';
import { SelectInputComponent } from './select-input/select-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { ControlSkeletonComponent } from '../skeletons/control-skeleton/control-skeleton.component';
import { ButtonComponent } from './button/button.component';

@NgModule({
  declarations: [
    FormFieldComponent,
    InputComponent,
    InputPasswordComponent,
    SelectComponent,
    SearchComponent,
    DatePickerComponent,
    TextareaComponent,
    ControlErrorMessageComponent,
    SelectInputComponent,
    ControlSkeletonComponent,
    ButtonComponent
  ],
  imports: [
    CommonModule,
    NgxMaskDirective,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    TranslateModule,
  ],
  exports: [
    FormFieldComponent,
    InputComponent,
    InputPasswordComponent,
    SelectComponent,
    SearchComponent,
    DatePickerComponent,
    TextareaComponent,
    ControlErrorMessageComponent,
    SelectInputComponent,
    ButtonComponent,
    ControlSkeletonComponent
  ],
  providers: [provideNgxMask()],
})
export class BaseModule {}
