import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from "../services/auth/auth.service";
import {StorageService} from "../services/storage/storage.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private toastrService: ToastrService,
              private authService: AuthService,
              private storageService: StorageService) {
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 400) {
        console.log(err.error.messages[0]);
        if (err.error.messages.length) {
          this.toastrService.error(err.error.messages[0], 'Ошибка!');
        }
      }

      if (err.status === 403) {
        this.toastrService.error('У вас нет доступа для просмотра этого контента!', 'Ошибка доступа!');
      }

      // if (err.status > 499) {
      //   this.authService.navigateToErrorPage();
      // }

      if (err.status === 401 && !localStorage.getItem('mobile_app')) {
        if (this.storageService.getToken() !== null) {
          this.authService.refreshToken(this.storageService.getRefreshToken()).subscribe({
            next: (res) => {
              if (res) {
                console.log(res);
              }
            },
            error: (err) => {
              this.authService.logout();
            }
          });
        }
      }

      return throwError(err);
    }));
  }
}
