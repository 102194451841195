import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigatorMobileComponent } from '../components/navigator-mobile/navigator-mobile.component';
import { SharedModule } from 'src/app/shared/shared.module';
import {TranslateModule} from "@ngx-translate/core";



@NgModule({
  declarations: [
    NavigatorMobileComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule
  ],
  exports: [
    NavigatorMobileComponent,
  ]
})
export class ComponentsMobileModule { }
