<button
  [disabled]="isDisabled"
  class="button radius-8 flex items-center justify-center"
  [ngClass]="{
    color_brand: color === buttonColors.Brand,
    color_error: color === buttonColors.Error,
    color_success: color === buttonColors.Success,
    color_black: color === buttonColors.Black,
    color_default: color === buttonColors.Default,
    color_default_border: color === buttonColors.DefaultBorder,
    color_link: color === buttonColors.Link,
    color_success_link: color === buttonColors.SuccessLink,
    color_text: color === buttonColors.Text,
    type_outline: type === buttonTypes.Outline,
    type_outline_success:
      type === buttonTypes.Outline && color === buttonColors.Success,
    type_outline_default:
      type === buttonTypes.Outline && color === buttonColors.Filter,
    size_lg: size === buttonSizes.Large,
    size_sm: size === buttonSizes.Small,
    radius_m: radius === buttonRadius.Medium,
    'w-100': fullWidth
  }"
  (click)="handlerOnButtonClick()"
>
  <img
    *ngIf="leftIcon"
    [src]="leftIcon"
    alt=""
    [ngClass]="{
      'left-icon': leftIcon
    }"
  />

  <span class="flex items-center justify-center h-100">
    {{ text | translate }}
  </span>

  <img
    *ngIf="rightIcon"
    [src]="rightIcon"
    alt=""
    [ngClass]="{
      'right-icon': rightIcon
    }"
  />
</button>
