<div class="question" *ngIf="!form.get('isSaved')?.value">
    <form class="question-form" [formGroup]="form">
        <div class="input">
            <app-editor
                *ngIf="withQuestionText"
                [error]="formErrors.questionText"
                class="input-input"
                name="QUESTION_TEXT"
                formControlName="questionText"
                (change)="handleFormControlErrors(form, formErrors, 'questionText')"
                >
            </app-editor>
            <div class="input-icon-wrapper" (click)="questionImageInput.click()">
                <div class="input-icon" accept="image/*"image-input [style.backgroundImage]="'url(' + (form.get('questionImageUrl')?.value ? form.get('questionImageUrl')?.value : questionImageUrl) + ')'"></div>
            </div>
            <input #questionImageInput
                id="imageInput"
                class="image-input"
                type="file"
                accept="image/*"
                (change)="handleQuestionImageInput($event)"
            >
        </div>
        <div class="variants-wrapper">
            <div class="variants-header">
                <h1 class="variant-title">{{'ANSWER_OPTIONS' | translate}}</h1>
                <div class="variant-header-actions">
                    <img src="assets/svg/informative/mix.svg" alt="" />
                    <app-slide-toggle formControlName="shuffle" [toggleValue]="item.shuffle || true"  text="MIX"></app-slide-toggle>
                </div>
            </div>
            <ul class="variants-list" formArrayName="answerList">
                <li *ngFor="let variant of answerList().controls; let j = index" [formGroupName]="j">
                    <div class="variant">
                        <button (click)="variantImageInput.click()">
                            <div class="input-icon-wrapper">
                                <div class="input-icon" [style.backgroundImage]="'url(' + (variant.get('answerImageUrl')?.value ? variant.get('answerImageUrl')?.value : questionImageUrl) + ')'"></div>
                            </div>
                            <input class="image-input" accept="image/*" #variantImageInput type="file" #variantImageUploader (change)="handleAnswerItemImageUpload(j, $event)" />
                        </button>
                        <div class="variant-main-wrapper">
                            <div class="variant-main">
                                <div class="variant-input-wrapper" [ngClass]="{'variant-input-wrapper--error': errorVariantText[j]}">
                                    <app-editor class="variant-input" formControlName="answerText" ></app-editor>
                                    <div class="desktop">
                                        <mat-checkbox class="mat-checkbox-label" color="accent" formControlName="correct">
                                          {{'RIGHT_ANSWER' | translate}}
                                        </mat-checkbox>
                                    </div>
                                    <div class="mobile w-100">
                                        <div class="mobile-trash-answer">
                                            <mat-checkbox class="mat-checkbox-label" color="primary" formControlName="correct">
                                              {{'RIGHT_ANSWER' | translate}}
                                            </mat-checkbox>
                                            <button (click)="removeAnswerItem(j)">
                                                <img src="assets/svg/informative/trash.svg" alt="" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <button class="desktop" (click)="removeAnswerItem(j)">
                                    <img src="assets/svg/informative/trash.svg" alt="" />
                                </button>
                            </div>
                            <span *ngIf="errorVariantText" class="error-text">{{errorVariantText[j] | translate}}</span>
                        </div>
                    </div>
                </li>
            </ul>
            <button class="add-varinat-button" (click)="addAnswerItem()">{{'ADD_OPTION' | translate}}</button>
        </div>
    </form>
</div>
