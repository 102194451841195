import {
  ChangeDetectionStrategy,
  Component, EventEmitter,
  Input,
  OnChanges,
  OnInit, Output,
  SimpleChanges,
} from '@angular/core';
import { CommentDTO, ProgressDTO, StudentProgress } from 'src/app/core/model';
import { DateHelperServiceService } from 'src/app/shared/services/dateHelperService/date-helper-service.service';

@Component({
  selector: 'app-menu-header-student-comments',
  templateUrl: './menu-header-student-comments.component.html',
  styleUrls: ['./menu-header-student-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class MenuHeaderStudentCommentsComponent implements OnInit {
  @Input() comments: CommentDTO[] | undefined;
  @Input() progress!: StudentProgress | ProgressDTO | undefined;
  @Input() isScoreShown: boolean = true;

  @Output() editCommentChange: EventEmitter<CommentDTO> = new EventEmitter<CommentDTO>();
  @Output() removeCommentChange: EventEmitter<string> = new EventEmitter<string>();

  public files: string[] = [];
  commentValue: string = '';

  constructor(
    private dateHelper: DateHelperServiceService
  ) {}

  ngOnInit(): void {
    if(this.progress?.comments) {
      this.progress.comments.map(item => this.dateHelper.convertUTCToLocal(new Date(item.commentTime)));
    }
  }

  public openFile(link: string): void {
    window.open(link, '_blank')
  }

  public editComment(comment: CommentDTO, index: number) {
    this.commentValue = comment?.commentText?.replaceAll('<br>', '\n');
    this.progress!.comments.forEach((comm, i) => {
      if (i !== index) {
        comm.isEdit = false;
      } else {
        comm.isEdit = !comm.isEdit;
      }
    });
  }

  public submitComment(comment: CommentDTO) {
    console.log()
    const commentText = this.commentValue?.replace(/\n/g, '<br>');
    if (commentText !== comment.commentText) {
      comment.commentText = commentText;
      this.editCommentChange.emit(comment);
    }
    comment.isEdit = false;
  }

  public removeComment(comment: CommentDTO, index: number) {
    this.progress!.comments?.splice(index, 1);
    this.removeCommentChange.emit(comment.commentId);
  }

  public canEditComment(comment: CommentDTO): boolean {
    return this.progress!.studentId === comment.commenterId;
  }
}
