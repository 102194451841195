import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { LectureDTO, LessonDTO } from 'src/app/core/model';

@Component({
  selector: 'app-lecture-card-edit',
  templateUrl: './lecture-card-edit.component.html',
  styleUrls: ['./lecture-card-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LectureCardEditComponent {
  @Input() lesson: LessonDTO | undefined;

  public isExpand : boolean = false
}
