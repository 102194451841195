import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
// @ts-ignore
import ClassicEditor from "ckeditor5-mathtype/build/ckeditor";
import {
  injectNgControl,
  NoopValueAccessorDirectiveDirective
} from 'src/app/shared/directives/noop-value-accessor-directive/noop-value-accessor-directive.directive';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [NoopValueAccessorDirectiveDirective],
})
export class EditorComponent {
  ngControl = injectNgControl();

  public Editor = ClassicEditor;
  @Input() editorConfig = {};

  @Input() name: string = "";
  @Input() error: string = "";
  @Input() isViewMode: boolean = false;
  @Output() change: EventEmitter<void> = new EventEmitter<void>();
  
  onReady($event: any) {
    $event.set('isReadOnly', this.isViewMode);
    const toolbarElement = $event.ui.view.toolbar.element;

    if ( this.isViewMode ) {
        toolbarElement.style.display = 'none';
    } else {
        toolbarElement.style.display = 'flex';
    }
  }
}
