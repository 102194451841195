<div class="cart-modal-card-active flex">
  <button class="checkbox flex items-center" (click)="toggleChecked(card.id)">
    <div class="uncheked border" *ngIf="!card.checked"></div>
    <img
      *ngIf="card.checked"
      src="./assets/svg/checkbox.svg"
      alt=""
      class="icon-checkbox"
    />
  </button>

  <div class="cart-modal-card w-100 p-24 border flex radius-16" *ngIf="card">
    <div class="image">
      <img [src]="subjectsId[card.subjectId]" alt=""/>
    </div>

    <div class="description">
      <div class="title">{{ card.name }}</div>
      <div class="text text-md">{{'paymentExtension' | translate}}</div>
      <div class="price flex items-center">
        <div class="price_new">{{ price | number }} ₸</div>
        <!-- <div class="price_new">{{ card.monthlyPayment | number }} ₸</div> -->
        <!-- <div class="price_old">{{ card.oldPrice | number }} ₸</div> -->
      </div>
    </div>
  </div>
</div>
