
<form class="base" [formGroup]="baseForm">
    <app-select
        name="CHOOSE_FOLDER_FOR_TYPE_LESSON"
        placeholder="CHOOSE_FROM_LIST"
        formControlName="courseFolderId"
        [options]="foldersOptions">
    </app-select>

    <app-multi-select
    *ngIf="!data?.common"
    name="STUDENTS_ASSIGNED"
    [options]="studentsList"
    [withSearch]="true"
    [preSelectedOptions]="baseForm.get('usersIds')?.value"
    (selectedValue)="studentsSelected($event)">
    </app-multi-select>

    <app-slide-toggle text="TAKE_IN_RATING" formControlName="rating" class="note-in-rating"></app-slide-toggle>

  <div class="w-100 flex gap-24 flex-1 form-group">
    <div class="w-50 form-group-item flex flex-col">
      <app-slide-toggle text="MAX_POINTS_FOR_TASK" formControlName="hasMaxScore" class="note-in-rating"></app-slide-toggle>

      <app-input *ngIf="baseForm.get('hasMaxScore')?.value"
                 [error]="baseFormErrors.maxScore"
                 name=""
                 formControlName="maxScore"
                 mask="9*"
                 (input)="handleFormControlErrors(baseForm, baseFormErrors, 'maxScore')">
      </app-input>
    </div>
    <div class="w-50 form-group-item flex flex-col">
      <app-slide-toggle text="COMPLETION_DEADLINE" formControlName="hasDate" class="note-in-rating"></app-slide-toggle>

      <app-date-picker
        *ngIf="isOpen && baseForm.get('hasDate')?.value"
        [error]="baseFormErrors.date"
        name=""
        formControlName="date"
        (input)="handleFormControlErrors(baseForm, baseFormErrors, 'date')">
      </app-date-picker>
    </div>
  </div>
<!--    <app-input-->
<!--        [error]="baseFormErrors.maxScore"-->
<!--        name="MAX_POINTS_FOR_TASK"-->
<!--        formControlName="maxScore"-->
<!--        mask="9*"-->
<!--        (input)="handleFormControlErrors(baseForm, baseFormErrors, 'maxScore')">-->
<!--    </app-input>-->
<!--    <app-date-picker-->
<!--        *ngIf="isOpen"-->
<!--        [error]="baseFormErrors.date"-->
<!--        name="COMPLETION_DEADLINE"-->
<!--        formControlName="date"-->
<!--        (input)="handleFormControlErrors(baseForm, baseFormErrors, 'date')">-->
<!--    </app-date-picker>-->
    <app-select-input
      *ngIf="baseForm.get('hasDate')?.value"
        [error]="baseFormErrors.time"
        name="DEADLINE_TIME"
        formControlName="time"
        mask="00:00"
        [options]="timeOptions">
    </app-select-input>
    <app-editor
        [error]="baseFormErrors.description"
        formControlName="description"
        name="descriptionAndInstructions"
        (change)="handleFormControlErrors(baseForm, baseFormErrors, 'description')">
    </app-editor>
    <div class="uploader">
        <span class="uploader-title">{{'materials' | translate}}</span>
        <div class="uploader-wrapper">
            <app-file-uploader
                [fileUrls]="baseForm.get('materials')?.value || []"
                [error]="baseFormErrors.materials"
                (removeEvent)="removed($event)"
                (downloadedEvent)="materialDownloaded($event)"
                [courseId]="courseId">
            </app-file-uploader>
        </div>
    </div>
</form>
