import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MethodistSidebarComponent } from './methodist-sidebar/methodist-sidebar.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddNewCourseComponent } from './add-new-course/add-new-course.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterComponent } from './filter/filter.component';
import { AddNewLessonComponent } from './add-new-lesson/add-new-lesson.component';
import { AddNewThemeComponent } from './add-new-theme/add-new-theme.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CdkDrag, DragDropModule, CdkDragPreview } from '@angular/cdk/drag-drop';
import { MatMenuModule } from '@angular/material/menu';
import { ThemesListComponent } from './themes-list/themes-list.component';
import { LessonsListModule } from './lessons-list/lessons-list.module';
import { TranslateModule } from "@ngx-translate/core";
import { AddLessonToCourseComponent } from "./add-lesson-to-course/add-lesson-to-course.component";
import { AddLessonFromCourseComponent } from "./add-lesson-from-course/add-lesson-from-course.component";


@NgModule({
  declarations: [
    MethodistSidebarComponent,
    AddNewCourseComponent,
    FilterComponent,
    AddNewLessonComponent,
    AddNewThemeComponent,
    ThemesListComponent,
    AddLessonToCourseComponent,
    AddLessonFromCourseComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    CdkDrag,
    DragDropModule,
    CdkDragPreview,
    MatMenuModule,
    LessonsListModule,
    TranslateModule
  ],
  exports: [
    MethodistSidebarComponent,
    AddNewCourseComponent,
    FilterComponent,
    AddNewLessonComponent,
    AddNewThemeComponent,
    ThemesListComponent,
    LessonsListModule,
    AddLessonToCourseComponent,
    AddLessonFromCourseComponent
  ],
})
export class ComponentsModule { }
