import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-student-modal-reply-curator',
  templateUrl: './student-modal-reply-curator.component.html',
  styleUrls: ['./student-modal-reply-curator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentModalReplyCuratorComponent {

}
