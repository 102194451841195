import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  CdkDragDrop,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RedirectInfo, SelectValue } from 'src/app/shared/models';
import { FoldersService } from './../../../../core/services/folders/folders.service';
import { AuthService } from './../../../../core/services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseFolder, CoursesFolder, LessonFolder } from 'src/app/core/model/interface/folders';
import { BaseRoutes, Keys, LessonType, Roles, StudentRoutes } from 'src/app/core/model';
import { HelperService } from './../../../services/helper/helper.service';
import { StudentHelperService } from './../../../../feature/student/service/student-helper.service';
@Component({
  selector: 'app-folders',
  templateUrl: './folders.component.html',
  styleUrls: ['./folders.component.scss']
})
export class FoldersComponent implements OnInit {
  @Input() canCreateFolder: boolean = false;
  @Input() canGoToLesson: boolean = false;
  @Input() canLinkLessonToFolder: boolean = false;
  @Input() canSortFolders: boolean = false;
  @Input() canSortLessons: boolean = false;
  @Input() showEmptyFolders: boolean = false;

  public options: SelectValue[] = [];
  public selectedOptions: string[] = [];

  public form!: FormGroup;
  public creatorForm!: FormGroup;
  public isCreatorOpen: boolean = false;

  public editorForm!: FormGroup;
  public isEditerOpen: boolean = false;
  public currentCourse: CourseFolder | undefined;

  public isLoading: boolean = false;
  public isDeleteModalOpen: boolean = false;
  public folderIdToDelete: string = 'false';

  public courseId: string = "";
  public groupId: string = "";
  public curatorId: string = "";
  public subjectId: string = "";
  public padding: string = "";

  public data: CoursesFolder | undefined;
  constructor(
    private formBuilder: FormBuilder,
    private foldersService: FoldersService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private route:ActivatedRoute,
    private helperService: HelperService,
    private router: Router,
    private studentHelperService: StudentHelperService
  ) {
    this.form = this.formBuilder.group({
      type: ['Показать все папки']
    });

    this.creatorForm = this.formBuilder.group({
      name: ['', Validators.required]
    })

    this.editorForm = this.formBuilder.group({
      name: ['', Validators.required]
    })
  }

  ngOnInit() {
    if(this.authService.getRole() === Roles.HeadTeacher) {
      this.route.parent!.params.subscribe(params => {
        this.groupId = params['groupId'];
        this.courseId = params['courseId'];
        this.curatorId = params['curatorId'];
        this.padding = params['padding'];
        this.subjectId = params['subjectId'];
        this.loadData();
        this.cdr.detectChanges();
      });
    }
    else {
      this.route.paramMap.subscribe( paramMap => {
        this.courseId = paramMap.get('id')! || paramMap.get('courseId')!;
        this.groupId = paramMap.get('groupId')!;
        this.loadData();
        this.cdr.detectChanges();
      });
    }
  }

  private loadData(): void {
    if(this.authService.getRole() === Roles.Student){
      this.groupId = localStorage.getItem(Keys.CurrGroupId)!;
      this.foldersService.getFoldersStudent(this.groupId).subscribe({
        next: (res) => {
          if (res) {
            this.data = res;
            this.setOptions(this.data);
            this.cdr.detectChanges();
          }
        },
        error: (err) => {
          console.log(err)
        }
      });
    }
    else if(this.authService.getRole() === Roles.Curator) {
      this.foldersService.getFoldersCurator(this.groupId).subscribe({
        next: (res) => {
          this.data = res[0];
          this.setOptions(this.data);
          this.cdr.detectChanges();
        },
        error: (err) => {
          console.log(err)
        }
      });
    }
    else if (this.authService.getRole() === Roles.HeadTeacher) {
      this.foldersService.getFoldersHeadTeacher(this.courseId, this.groupId).subscribe({
        next: (res) => {
          this.data = res[0];
          this.setOptions(this.data);
          this.cdr.detectChanges();
        },
        error: (err) => {
          console.log(err)
        }
      });
    }
    else {
      this.foldersService.getFoldersMethodist(this.courseId).subscribe({
        next: (res) => {
          this.data = res[0];
          this.setOptions(this.data);
          this.cdr.detectChanges();
        },
        error: (err) => {
          console.log(err)
        }
      })
    }
  }
  public dropFolder(event: CdkDragDrop<string[]>): void {
    const courseFolderId: string = this.data?.courseFolders[event.previousIndex].courseFolderId!;
    this.foldersService.switchFolders(this.courseId, courseFolderId, event.currentIndex).subscribe({
      next: (res) => {
        moveItemInArray(this.data!.courseFolders, event.previousIndex, event.currentIndex);
        this.cdr.detectChanges();
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  public dropLesson(event: CdkDragDrop<string[]>, courseFolderId: string): void {
    const lessonId: string = this.data!.courseFolders.find(item => item.courseFolderId === courseFolderId)!.lessons[event.previousIndex].lessonId;
    this.foldersService.switchLessons(this.courseId, courseFolderId, lessonId, event.currentIndex).subscribe({
      next: (res) => {
        moveItemInArray(this.data!.courseFolders.find(item => item.courseFolderId === courseFolderId)!.lessons, event.previousIndex, event.currentIndex);
        this.cdr.detectChanges();

      },
      error: (err) => {
        console.log(err)
      }
    })
  }

  public goLessonDown(courseFolderId: string, lessonId: string,index: number): void {
    const newPossition = index+1;
    this.foldersService.switchLessons(this.courseId, courseFolderId, lessonId, newPossition).subscribe({
      next: (res) => {
        this.data!.courseFolders.find(item => item.courseFolderId === courseFolderId)!.lessons = this.swapElements(this.data!.courseFolders.find(item => item.courseFolderId === courseFolderId)!.lessons, index, newPossition);
        this.cdr.detectChanges();
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  public goLessonUp(courseFolderId: string, lessonId: string,index: number): void {
    if(!this.canSortLessons) {
      return;
    }
    const newPossition = index-1;
    this.foldersService.switchLessons(this.courseId, courseFolderId, lessonId, newPossition).subscribe({
      next: (res) => {
        this.data!.courseFolders.find(item => item.courseFolderId === courseFolderId)!.lessons = this.swapElements(this.data!.courseFolders.find(item => item.courseFolderId === courseFolderId)!.lessons, index, newPossition);
        this.cdr.detectChanges();
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  public goFolderDown(courseFolderId: string,index: number): void {
    const newPossition = index+1;
    this.foldersService.switchFolders(this.courseId, courseFolderId, newPossition).subscribe({
      next: (res) => {
        this.data!.courseFolders = this.swapElements(this.data!.courseFolders, index, newPossition);
        this.cdr.detectChanges();
      },
      error: (err) => {
        console.log(err);
      }
    })
  }
  public goFolderUp(courseFolderId: string,index: number): void {
    if(!this.canSortLessons) {
      return;
    }
    const newPossition = index-1;
    this.foldersService.switchFolders(this.courseId, courseFolderId, newPossition).subscribe({
      next: (res) => {
        this.data!.courseFolders = this.swapElements(this.data!.courseFolders, index, newPossition);
        this.cdr.detectChanges();
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  private swapElements(arr: any[], index1: number, index2: number) {
    // Check if the indices are valid
    if (index1 >= arr.length || index2 >= arr.length || index1 < 0 || index2 < 0) {
      return arr;
    }

    // Swap the elements at index1 and index2
    let temp = arr[index1];
    arr[index1] = arr[index2];
    arr[index2] = temp;

    return arr;
  }

  public openCreatorDialog(): void {
    this.isCreatorOpen = true;
  }

  public closeCreatorDialog(): void {
    this.isCreatorOpen = false;
  }

  public confirmCreatorDialog(): void {
    this.foldersService.createFolder(this.courseId, this.creatorForm.get('name')?.value).subscribe({
      next: (res) => {
        this.loadData();
        this.closeCreatorDialog();
        this.cdr.detectChanges();
      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  public openEditorDialog(course: CourseFolder): void {
    this.isEditerOpen = true;
    this.currentCourse = course;
    this.editorForm.patchValue({name: this.currentCourse.courseFolderName})
  }
  public closeEditorDialog(): void {
    this.isEditerOpen = false;
  }

  public confirmEditorDialog(): void {
    this.foldersService.editFolder(this.courseId, this.currentCourse!.courseFolderId ,this.editorForm.get('name')?.value).subscribe({
      next: (res) => {
        this.loadData();
        this.closeEditorDialog();
        this.cdr.detectChanges();
      },
      error: (err) => {
        console.log(err);
      }
    })
  }
  public goToLesson(courseFolderId: string, lesson: LessonFolder): void {
    if(this.authService.getRole() === Roles.Student) {
      this.router.navigate([`${BaseRoutes.Student}/${this.generateLessonsSiblingsRoute({
        groupId: this.groupId,
        courseId: courseFolderId,
        id: lesson.lessonId,
        name: lesson.lessonName,
        lessonType: lesson.lessonType as LessonType,
      })}`]);
    }

    if(this.authService.getRole() === Roles.Curator) {
      this.router.navigate([`${BaseRoutes.Curator}/${this.groupId}/${lesson.themeId}/${lesson.lessonId}`])
    }

    if(this.authService.getRole() === Roles.HeadTeacher) {
      this.router.navigate([`${BaseRoutes.HeadTeacher}/subjects/${this.subjectId}/${this.courseId}/${this.groupId}/${this.curatorId}/${lesson.themeId}/${lesson.lessonId}/padding`])
    }
  }

  public generateLessonsSiblingsRoute(item: RedirectInfo): string {
    let lessonSubRoute = StudentRoutes.Theory;
    switch(item.lessonType) {
      case LessonType.Homework: {
        lessonSubRoute = StudentRoutes.Homework;
        break;
      }
      case LessonType.Quiz: {
        lessonSubRoute = StudentRoutes.Test;
        break;
      }
      case LessonType.WorkOnMistakes: {
        lessonSubRoute = StudentRoutes.WorkOnMistakes
      }
    }
    const route = lessonSubRoute ===
    StudentRoutes.Test ? `${lessonSubRoute}/${StudentRoutes.TestStart}/${item.groupId}/${item.courseId}/${item.id}`
    : `${StudentRoutes.Education}/${lessonSubRoute}/${item.groupId}/${item.id}`
    return route;
  }

  public setOptions(data: CoursesFolder): void {
    this.options = data.courseFolders.map(item => {
      return {
        name: item.courseFolderName,
        value: item.courseFolderId
      }
    });
    this.options.unshift({
      name: 'Показать все папки',
      value: '',
    });
  }

  public selectedOptionsEvent(option: any[]): void {
    this.selectedOptions = option.length === 1 && option[0] === '' ? [] : option;
  }

  public openDeleteModal(folderId: string): void {
    this.isDeleteModalOpen = true;
    this.folderIdToDelete = folderId;
  }

  public closeDeleteModal(): void {
    this.isDeleteModalOpen = false;
  }

  public deleteFolder(folderId: string): void {
    this.isLoading = true;
    this.foldersService
      .deleteCourseFolder(this.courseId, folderId)
      .subscribe({
      next: () => {
        this.loadData();
        this.isDeleteModalOpen = false;
        this.folderIdToDelete = '';
        this.isLoading = false;
        this.cdr.detectChanges();
      },
      error: (err) => {
        console.log(err);
        this.isLoading = false;
      }
    })
  }
}
