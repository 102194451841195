<div class="container">
    <ul
        class="questions-list"
        *ngIf="questions"
        cdkDropList
        [cdkDropListData]="questions"
        (cdkDropListDropped)="drop($event)">
        <li *ngFor="let question of questions;let i = index;">
            <app-test-content-closed
                [isExpanded]="question.isSaved || false"
                [data]="question"
                [index]="i"
                (OpenEvent)="openQuestion($event)"
                (RemoveEvent)="removeQuestion($event, question.id)">
            </app-test-content-closed>
            <div class="item" *ngIf="!question.isSaved">
                <app-test-test-controller
                    (saveEvent)="saveQuestion(i, $event)"
                    [saveEventSubject]="saveQuizQuestionSubject.asObservable()"
                    [item]="question"
                    [index]="i"
                    (removeEvent)="removeQuestion(i, question.id)"
                    (validQuestionStatus)="setValidityOfQuestion($event, i)">
                </app-test-test-controller>
                <div class="buttons">
                    <button (click)="removeQuestion(i, question.id)">
                        <img src="assets/svg/informative/trash.svg" alt="" />
                    </button>
                    <button
                        class="iconic-button primary-button button"
                        (click)="sendSaveToChild(i)"
                        [disabled]="!question.isValid">
                        Сохранить
                    </button>
                </div>
            </div>
        </li>
        <button class="iconic-button additional-button add-button" (click)="addQuestion()">
          {{'ADD_QUESTION' | translate}}
        </button>
    </ul>
</div>




