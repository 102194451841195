import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CrmService } from 'src/app/shared/services/crm/crm.service';

@Component({
  selector: 'app-admin-crm',
  templateUrl: './admin-crm.component.html',
  styleUrls: ['./admin-crm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminCrmComponent {

  @Input() selectedUser: any;
  @Output() isGoingToLeaveToggleEvent: EventEmitter<void> = new EventEmitter<void>();

  public isLoading: boolean = false;

  constructor(
    private crmService: CrmService
  ) {

  }

  public toggleIsGoingToLeave(): void {
    this.isLoading = true;

    this.crmService
      .goingToLeave(this.selectedUser.id, this.selectedUser.goingToLeave)
      .subscribe({
        next: res => {
          this.isLoading = false;
          this.isGoingToLeaveToggleEvent.emit();
        },
        error: err => {
          console.error('Error:', err);
          this.isLoading = false;
        }
      });
  }
}
