import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ConverterService } from '../../../../core/services/converter/converter.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PdfFileServiceService } from '../../../services/pdf-file-service/pdf-file-service.service';
import { environment } from 'src/environment';
import { ToastrService } from "ngx-toastr";
import { debounceTime, distinctUntilChanged, fromEvent, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-pdf-file-viewer',
  templateUrl: './pdf-file-viewer.component.html',
  styleUrls: ['./pdf-file-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfFileViewerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() mobileAdaptive: boolean = false;
  @Input() url: string | null = null;
  @Input() isLocalFile: boolean = false;
  @Input() height: number = 100;
  iframeSrc: SafeResourceUrl | undefined;
  isMobilePdfLoading: boolean = false;
  public mobileUrl: string = "";

  @ViewChild('content', { static: false }) content!: ElementRef;

  resizeObservable$!: Observable<Event>;
  resizeSubscription$!: Subscription;

  constructor(private converterService: ConverterService,
    private sanitizer: DomSanitizer,
    private PdfFileServiceService: PdfFileServiceService,
    private toastrService: ToastrService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['url'] && this.url) {
      this.mobileUrl = "";
      this.mobileUrl = "https://converter.juz40-edu.kz/converter/convert?links=" 
        +  (this.isLocalFile ? window.location.origin + '/' : environment.filesUrl) 
        + this.url;
      this.iframeSrc = 
      this.sanitizer.bypassSecurityTrustResourceUrl(this.mobileUrl + '#toolbar=1');
      
      this.isMobilePdfLoading = window.innerWidth < 999;
    }
    if(changes['url'] && !this.url) {
      this.iframeSrc = undefined;
    }
  }

  ngOnInit(): void {
    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.pipe(distinctUntilChanged(), debounceTime(1500)).subscribe( evt => {
      if(!this.mobileUrl) {
        this.mobileUrl = "https://converter.juz40-edu.kz/converter/convert?links=" 
          +  (this.isLocalFile ? window.location.origin + '/' : environment.filesUrl) 
          + this.url;
        
        this.isMobilePdfLoading = window.innerWidth < 999;
      }
      this.cdr.detectChanges();
    })
  };

  ngOnDestroy(): void {
    this.resizeSubscription$.unsubscribe()
  }
  public close(): void {
    this.PdfFileServiceService.close();
  }

  setPdfLoading(): void {
    this.isMobilePdfLoading = true;
  }

  offPdfLoading(): void {
    this.isMobilePdfLoading = false;
  }

  setError(event: { message: string }): void {
    this.toastrService.warning(event?.message || '', 'Ошибка');
    this.offPdfLoading();
  }
}
