import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-view-photo',
  templateUrl: './view-photo.component.html',
  styleUrls: ['./view-photo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewPhotoComponent {
  zoomLevel = 1;
  maxZoom = 3;
  minZoom = 1;
  zoomStep = 0.1;
  transformOrigin = 'center center';

  @Input() image: string | undefined;

  public isViewMode: boolean = false;

  public closeImageDialog(): void {
    this.isViewMode = false;
  }

  onScroll(event: WheelEvent) {
    if (event.ctrlKey) {
      event.preventDefault();

      // Calculate the cursor's position within the image element
      const target = event.target as HTMLElement;
      const rect = target.getBoundingClientRect();
      const offsetX = event.clientX - rect.left;
      const offsetY = event.clientY - rect.top;

      // Set transform origin to the cursor's position
      this.transformOrigin = `${(offsetX / rect.width) * 100}% ${(offsetY / rect.height) * 100}%`;

      // Adjust zoom level based on scroll direction
      if (event.deltaY < 0) {
        this.zoomLevel = Math.min(this.zoomLevel + this.zoomStep, this.maxZoom);
      } else {
        this.zoomLevel = Math.max(this.zoomLevel - this.zoomStep, this.minZoom);
      }
    }
  }
}
