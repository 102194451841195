import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, ElementRef, Input, OnChanges, OnInit, QueryList, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-expander',
  templateUrl: './expander.component.html',
  styleUrls: ['./expander.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpanderComponent implements OnChanges, OnInit{
  private mutationObserver!: MutationObserver;

  @ContentChildren('button, content') contentList!: QueryList<ElementRef>;
  @ViewChild('expanderContent') expanderContent!: ElementRef<HTMLDivElement>;

  @Input() isExpanded: boolean = false;
  @Input() showContentFirst: boolean = false;

  private eventsSubscription: Subscription | undefined;

  @Input() updateEvent: Observable<void> | undefined;
  constructor(private cdr: ChangeDetectorRef) {}
  ngOnInit(): void {
      if(this.updateEvent) {
        this.eventsSubscription = this.updateEvent.subscribe(() => {
          this.changeHeight();
          this.cdr.detectChanges();
        })
      }
  }
  ngOnChanges() {
    if (!this.expanderContent || !this.expanderContent.nativeElement) {
      return;
    }
    this.changeHeight();
  }

  private changeHeight(): void {
    setTimeout(() => {
      const contentElement = this.expanderContent.nativeElement;
      const firstChild = contentElement.firstElementChild;
  
      if (!firstChild) {
        return;
      }
      const firstGrandchild = firstChild.firstElementChild;
      let firstGrandChildMarginTop = 0;
      if(firstGrandchild) {
        firstGrandChildMarginTop = parseInt(getComputedStyle(firstGrandchild).marginTop, 10);
      }
      if (this.isExpanded) {
        contentElement.style.height =
          firstChild.clientHeight
          + parseInt(getComputedStyle(firstChild).marginTop, 10)
          + firstGrandChildMarginTop
          + 'px';
      } else {
        contentElement.style.height = '0';
      }
    }, 50)
   
  }

  ngAfterViewInit() {
    this.observeHeightChanges();
  }

  ngOnDestroy() {
    this.disconnectObserver();
  }

  private observeHeightChanges() {
    const targetElement = this.expanderContent.nativeElement;

    this.mutationObserver = new MutationObserver(() => {
      const height = targetElement.getBoundingClientRect().height;
      this.changeHeight()
    });

    this.mutationObserver.observe(targetElement, { childList: true, subtree: true });
  }

  private disconnectObserver() {
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }
}
