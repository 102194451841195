<div class="container" [ngClass]="{'container-bordered': withBorder}">
  <div class="header">
    <div class="image"></div>
    <div class="header-right">
      <h1 class="title">Числовым функции</h1>
      <span class="subject">Математика</span>
      <span class="group">Группа 1234</span>
    </div>
  </div>
  <div class="bottom">
    <div class="b-left">
      <div class="rating">
        <div class="rating-ins">
          <span class="rating-number">4.5</span>
          <img class="big-star" src="assets/svg/star-yellow.svg" alt="" />
        </div>
        <span class="reviews">273 Reviews</span>
      </div>
      <button (click)="commentsClicked.emit()" class="iconic-button secondary-button button">
        <span class="comments">15</span>
        <img src="assets/svg/comment.svg" alt="" />
      </button>
    </div>
    <ul class="b-right">
      <li class="item" *ngFor="let item of items">
        <span class="star">{{item}}</span>
        <img class="star-star" src="assets/svg/star-yellow.svg" alt="" />
        <div class="bar"><div class="fill" [ngStyle]="{ width: (item * 20) + '%' }"></div></div>
      </li>
    </ul>
  </div>
</div>