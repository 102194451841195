<app-dialog
    title="ADD_ADDITIONAL_MATERIALS"
    [isOpen]="isOpen"
    [disabledConfirm]="fileUploaderError !=='' || (links && links.length > 0 && !isLinksValid)"
    (closeEvent)="cancelEvent.emit()"
    (confirmEvent)="save()">
    <div class="content">
        <div class="uploader-wrapper">
            <app-file-uploader
                [error]="fileUploaderError"
                [idList]="file ? file : []"
                (downloadedEvent)="downloaded($event)"
                (removeEvent)="removed($event)">
            </app-file-uploader>
        </div>
        <app-user-ful-links [list]="links" (valueChangesEvent)="userLinkChanged($event)" (validQuestionStatus)="setValidOfLinks($event)"></app-user-ful-links>
    </div>
</app-dialog>
