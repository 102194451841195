import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-shorturl',
  templateUrl: './shorturl.component.html',
  styleUrls: ['./shorturl.component.scss']
})
export class ShorturlComponent implements OnInit, OnDestroy {
  public routSub!: Subscription;

  public isLoading: boolean = true;
  constructor(
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private route: ActivatedRoute,
  ) 
  { }

  ngOnInit() {
    this.isLoading = true;
    this.routSub = this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      if(id) {
        this.authService.shorturl(id).subscribe({
          next: (res) => {
            document.location.href = res.link;
            this.cdr.detectChanges();
          },
          error: (error) => {
            console.log(error);
            this.isLoading = false;
            this.cdr.detectChanges()
          }
        })
      }
      else {
        console.log('uuid is undefined');
      }
    })
  }

  ngOnDestroy(): void {
    this.routSub.unsubscribe();
  }

}
