<div class="lecture-card-edit flex items-center">
  <img
    src="./assets/svg/ic_sharp-drag-indicator.svg"
    alt=""
    class="drag-icon"
  />
  <div
    class="content bg-white radius-16 bg-white w-100 border"
    [class.content_is-own]="lesson?.curator"
  >
    <div class="title">{{ lesson?.name }}</div>
    <div class="type">Тип: {{ lesson!.lessonType | reverseLessonType }}</div>

    <button class="w-100 btn-expand">
      <img src="./assets/svg/arraw-default-black.svg" alt="" />
    </button>

    <button
      class="btn-menu"
      [matMenuTriggerFor]="menu"
      [disabled]="!lesson?.curator"
    >
      <img src="./assets/svg/triple-dot.svg" alt="" />
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item>
        <img src="assets/svg/edit.svg" alt="" />
        <span>Редактировать</span>
      </button>
      <button mat-menu-item>
        <img src="assets/svg/duplicate.svg" alt="" /> <span>Дублировать</span>
      </button>
      <button mat-menu-item>
        <img src="assets/svg/trash.svg" alt="" /> <span>Удалить</span>
      </button>
    </mat-menu>
  </div>
</div>
