import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './pages/profile/profile.component';
import { SharedModule } from '../shared/shared.module';
import { FeatureModule } from '../feature/feature.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { AuthorizedGuard } from './guards/authorized/authorized.guard';
import { AutorizationGuard } from './guards/authorization/autorization.guard';
import { ToastrModule } from 'ngx-toastr';
import {TranslateModule} from "@ngx-translate/core";
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { UtcToLocalPipe } from '../shared/pipes/utc-to-local/utc-to-local.pipe';
import { ShorturlComponent } from './pages/shorturl/shorturl.component';
import {RouterModule} from "@angular/router";
import {MatTableModule} from "@angular/material/table";

@NgModule({
  declarations: [ProfileComponent, ResetPasswordComponent, ShorturlComponent],
  imports: [
    CommonModule,
    SharedModule,
    FeatureModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
    }),
    TranslateModule,
    RouterModule,
    MatTableModule,
  ],
  providers: [
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    AuthorizedGuard,
    AutorizationGuard,
    UtcToLocalPipe
  ],
})
export class CoreModule {}
