import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { CreateThemeRequest, ThemeDTO } from '../../model/interface/theme';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(private httpClient: HttpClient) { }

  public getThemeList(id: string): Observable<ThemeDTO[]> {
    return this.httpClient.get<ThemeDTO[]>(`/v1/themes`, { params: { courseId: id } }).pipe(
      map((res:ThemeDTO[]) => {
        return res;
      })
    );
  };
  public createTheme(data: CreateThemeRequest): Observable<ThemeDTO> {
    return this.httpClient.post<ThemeDTO>(`/v1/themes`, data).pipe(
      map((res: ThemeDTO) => {
        return res;
      })
    )
  };
  public updateTheme(themeId: string, data: CreateThemeRequest): Observable<ThemeDTO> {
    return this.httpClient.put<ThemeDTO>(`/v1/themes/${themeId}`, data).pipe(
      map((res: ThemeDTO) => {
        return res;
      })
    )
  };
  public deleteTheme(id: string): Observable<any> {
    return this.httpClient.delete<ThemeDTO>(`/v1/themes/${id}`).pipe(
      map((res: ThemeDTO) => {
        return res;
      })
    )
  }
  public orderingTheme(themeId: string, lessonsIdList: string[]): Observable<string[]> {
    return this.httpClient.put<string[]>(`/v1/themes/${themeId}/order`, lessonsIdList).pipe(
      map(res => {
        return res;
      })
    )
  }

}
