import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {LessonDTO, Roles, StudentProgress} from 'src/app/core/model';
import {UserRightModalComponent} from 'src/app/core/pages/curator/user-right-modal/user-right-modal.component';
import {FilesService} from 'src/app/core/services/files/files.service';
import {AuthService} from "../../../../core/services/auth/auth.service";

@Component({
  selector: 'app-students-list-item',
  templateUrl: './students-list-item.component.html',
  styleUrls: ['./students-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentsListItemComponent implements OnChanges, AfterViewInit {
  @Input() student: StudentProgress | undefined;
  @Input() lesson: LessonDTO | undefined;
  @Input() isSelectedStudent: boolean = false;
  @Input() width: number = 0;
  @Output() onDetailClick: EventEmitter<any> = new EventEmitter();

  public isLate: boolean = false;
  public Roles = Roles;

  public role!: Roles;

  public isMobile: boolean = false;

  public lastCuratorComment: string = "";
  public curatorCommented: boolean = false;
  public isShowMoreShown: boolean = false;
  public isShowMoreActive: boolean = false;


  constructor(
    private _fileService: FilesService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private authService: AuthService
  ) {
    this.role = this.authService.getRole();

    if(window.innerWidth > 999) {
      this.isMobile = false;
    }
    else {
      this.isMobile = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.lesson && this.lesson.deadline && this.student && this.student) {
      if((new Date(this.lesson.deadline).getTime() - new Date(this.student.submissionTime!).getTime()) < 0) {
        this.isLate = true;
      }
      else {
        this.isLate = false;
      }
      this.lastCuratorComment = this.student.comments.find(item => item.commenterRole === Roles.Curator) ? this.student.comments.reverse().find(item => item.commenterRole === Roles.Curator).commentText : '';
      if(this.role === this.Roles.HeadTeacher && this.lastCuratorComment) {
        this.curatorCommented = true;
      }
      else {
        this.curatorCommented = false;
      }
      this.cdr.detectChanges();

    }
  }

  ngAfterViewInit(): void {
    if(this.isMobile) {
      if(this.lastCuratorComment.length > 15) {
        this.isShowMoreShown = true;
      }
      else {
        this.isShowMoreShown = false;
      }
      return;
    }
    if(this.lastCuratorComment.length > 45) {
      this.isShowMoreShown = true;
    }
    else {
      this.isShowMoreShown = false;
    }
    this.cdr.detectChanges();

  }
  public onDetailButtonClick(): void {
    this.onDetailClick.emit(this.student);
  }

  public getImageUrl(): string {
    if (this.student && this.student?.studentPhotoUrl) {
      return this._fileService.getImageUrl(this.student.studentPhotoUrl);
    }
    return 'assets/img/default-ava.webp';
  }

  public openStudentCard(): void {
    console.log(this.student)
    this.dialog.open(UserRightModalComponent, {
      data: {
        userId: this.student!.studentId,
      },
      height: '100vw',
      width: '500px',
      position: { right: '0px', top: '0px' },
    })
  }

  get showComment(): boolean {
    return this.student?.commented && this.role === Roles.Curator || false;
  }

  public open(): void {
    this.isShowMoreActive = true;

  }
  public close(): void {
    this.isShowMoreActive = false;
  }
}
