import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LessonType } from 'src/app/core/model';
import { LessonStatus } from 'src/app/shared/models';

@Component({
  selector: 'app-lesson-status-image',
  templateUrl: './lesson-status-image.component.html',
  styleUrls: ['./lesson-status-image.component.scss']
})
export class LessonStatusImageComponent implements OnChanges {
  public readonly LessonStatus = LessonStatus;
  @Input() type: LessonType = LessonType.Lecture;
  @Input() active: boolean = false;
  @Input() status: LessonStatus | number | undefined = LessonStatus.Created;

  public pathToImage: string = "assets/svg/lesson/";

  ngOnChanges(changes: SimpleChanges): void {
    if(this.type) {
      this.pathToImage = "assets/svg/lesson/";
      switch (this.type) {
        case LessonType.Lecture: {
          this.pathToImage = this.pathToImage + 'lecture-'
          break;
        }
        case LessonType.Homework: {
          this.pathToImage = this.pathToImage + 'hw-'
          break;
        }
        case LessonType.WorkOnMistakes: {
          this.pathToImage = this.pathToImage + 'hw-'
          break;
        }
        case LessonType.Quiz: {
          this.pathToImage = this.pathToImage + 'test-'
          break;
        }
        case LessonType.Text: {
          this.pathToImage = this.pathToImage + 'text-'
          break;
        }
      }

      if(this.active) {
        this.pathToImage = this.pathToImage + 'active-';
      }
      else {
        this.pathToImage = this.pathToImage + 'not-active-';
      }

      if (this.status === LessonStatus.Success) {
        this.pathToImage = this.pathToImage + 'complited.svg';
      } else {
        this.pathToImage = this.pathToImage + 'not-complited.svg';
      }
    }
  }

}
