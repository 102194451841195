import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';
import {Languages, SelectValue} from '../../models';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateHelperServiceService {
  private language: Languages = Languages.KZ;

  constructor(private translate: TranslateService) {
    this.language = translate.getDefaultLang() as Languages;
  }
  getMonthName(monthNumber: number, isShortName: boolean = false): SelectValue {
    this.language = this.translate.currentLang as Languages;
    if (this.language === Languages.KZ) {
      return this.getMonthNameKazakh(monthNumber);
    } else if (this.language === Languages.RU) {
      return this.getMonthNameRussian(monthNumber, isShortName);
    } else {
      return { name: 'Invalid language', value: -1 };
    }
  }

  private getMonthNameKazakh(monthNumber: number): SelectValue {
    switch (monthNumber) {
      case 1: return { name: 'Қаңтар', value: 1 };
      case 2: return { name: 'Ақпан', value: 2 };
      case 3: return { name: 'Наурыз', value: 3 };
      case 4: return { name: 'Сәуір', value: 4 };
      case 5: return { name: 'Мамыр', value: 5 };
      case 6: return { name: 'Маусым', value: 6 };
      case 7: return { name: 'Шілде', value: 7 };
      case 8: return { name: 'Тамыз', value: 8 };
      case 9: return { name: 'Қыркүйек', value: 9 };
      case 10: return { name: 'Қазан', value: 10 };
      case 11: return { name: 'Қараша', value: 11 };
      case 12: return { name: 'Желтоқсан', value: 12 };
      default: return { name: '', value: -1 };
    }
  }

  private getMonthNameRussian(monthNumber: number, isShortName: boolean): SelectValue {
    switch (monthNumber) {
      case 1: return { name: isShortName ? 'Янв' : 'Январь', value: 1 };
      case 2: return { name: isShortName ? 'Февр' : 'Февраль', value: 2 };
      case 3: return { name: 'Март', value: 3 };
      case 4: return { name: isShortName ? 'Апр' : 'Апрель', value: 4 };
      case 5: return { name: 'Май', value: 5 };
      case 6: return { name: 'Июнь', value: 6 };
      case 7: return { name: 'Июль', value: 7 };
      case 8: return { name: isShortName ? 'Авг' : 'Август', value: 8 };
      case 9: return { name: isShortName ? 'Сен' : 'Сентябрь', value: 9 };
      case 10: return { name: isShortName ? 'Окт' : 'Октябрь', value: 10 };
      case 11: return { name: isShortName ? 'Нояб' : 'Ноябрь', value: 11 };
      case 12: return { name: isShortName ? 'Дек' : 'Декабрь', value: 12 };
      default: return { name: '', value: -1 };
    }
  }

  getMonths(): SelectValue[] {
    this.language = this.translate.getDefaultLang() as Languages;
    switch (this.language) {
      case Languages.KZ:
        return this.getMonthsKazakh();
      case Languages.RU:
        return this.getMonthsRussian();
      default:
        return [];
    }
  }

  private getMonthsKazakh(): SelectValue[] {
    return [
      { name: 'Қаңтар', value: 1 },
      { name: 'Ақпан', value: 2 },
      { name: 'Наурыз', value: 3 },
      { name: 'Сәуір', value: 4 },
      { name: 'Мамыр', value: 5 },
      { name: 'Маусым', value: 6 },
      { name: 'Шілде', value: 7 },
      { name: 'Тамыз', value: 8 },
      { name: 'Қыркүйек', value: 9 },
      { name: 'Қазан', value: 10 },
      { name: 'Қараша', value: 11 },
      { name: 'Желтоқсан', value: 12 }
    ];
  }

  private getMonthsRussian(): SelectValue[] {
    return [
      { name: 'Январь', value: 1 },
      { name: 'Февраль', value: 2 },
      { name: 'Март', value: 3 },
      { name: 'Апрель', value: 4 },
      { name: 'Май', value: 5 },
      { name: 'Июнь', value: 6 },
      { name: 'Июль', value: 7 },
      { name: 'Август', value: 8 },
      { name: 'Сентябрь', value: 9 },
      { name: 'Октябрь', value: 10 },
      { name: 'Ноябрь', value: 11 },
      { name: 'Декабрь', value: 12 }
    ];
  }

  public getYears(): SelectValue[] {
    const startYear = (new Date()).getFullYear();
    const endYear = 2030;
    const years: SelectValue[] = [];

    for (let year = startYear; year <= endYear; year++) {
      years.push({ name: year.toString(), value: year });
    }
    return years;
  };


  public convertUTCToLocal(utcDate: Date): Date {
    const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);
    return localDate;
  }

  public convertLocalToUtc(localDate: Date): Date {
    const utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
    return utcDate;
  }

  public timeAndDateToOneValue(time: string, dateP: string): string {
    const hours: any = time?.slice(0, 2);
    const minutes: any = time?.slice(-2);
    const date = new Date(dateP);

    console.log('hours: ', hours);
    console.log('minutes: ', minutes);
    const utcDate = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      hours,
      minutes,
    );

    return utcDate.toISOString();
  }

  public getDaysDifference(date: string) {
    const parsedDate = moment(date);
    const currentDate = moment();
    return currentDate.diff(parsedDate, 'days');
  }

  public daysToDate(dateTo: string): number {
    const to = new Date(dateTo);
    const currentDate: Date = new Date();
    const differenceInMillis: number = to.getTime() - currentDate.getTime();
    const millisecondsPerDay: number = 1000 * 60 * 60 * 24;

    return Math.ceil(differenceInMillis / millisecondsPerDay) || 0;
  }
}
