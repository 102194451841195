<div class="menu-header-student-status flex flex-col">
  <div class="item flex">
    <div class="label">Статус</div>
    <div class="value">{{ progress.status | progressStatusConverter | translate}}</div>
  </div>
  <ng-container
    *ngIf="
      progress &&
      progress.comments &&
      progress.comments.length > 0 &&
      progress.comments[0]?.commentTime
    "
  >
    <div class="item flex" *ngIf="progress.comments[0]?.commentTime">
      <div class="label">Дата сдачи</div>
      <div class="value">
        {{ progress.comments[0]?.commentTime | date : "dd.MM.yyyy, HH:mm"  }}
      </div>
    </div>
  </ng-container>
  <div class="item flex" *ngIf="progress?.score">
    <div class="label">Оценка</div>
    <div class="value">{{ progress.score }}</div>
  </div>
</div>
