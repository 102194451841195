import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import {
  NoopValueAccessorDirectiveDirective,
  injectNgControl,
} from 'src/app/shared/directives/noop-value-accessor-directive/noop-value-accessor-directive.directive';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [NoopValueAccessorDirectiveDirective],
})
export class DatePickerComponent {
  ngControl = injectNgControl();

  @Input() name: string = '';
  @Input() isActive: boolean = true;
  @Input() error: string = '';
  @Input() minDate: string | null = null;
  @Input() placeholder: string = ""

  public focused: boolean = false;
  isSafari: boolean = true;

  @ViewChild('inputField', { static: true })
  inputField!: ElementRef<HTMLInputElement>;

  constructor() {
    this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  onFocus(): void {
    this.focused = true;
  }
  onBlur(): void {
    this.focused = false;
  }

  clearInput(): void {
    this.inputField.nativeElement.value = '';
    this.inputField.nativeElement.focus();
    console.log()
  }
}
