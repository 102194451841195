import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localizedNumberFormat',
  pure: true
})
export class LocalizedNumberFormatPipe implements PipeTransform {

  transform(value: number | string | undefined, args?: any): string {
    if(value === 0) {
      return '0';
    }
    if(!value) {
      return '';
    }
    return value.toLocaleString('ru-RU', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    });
  }

}
