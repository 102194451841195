<div class="app-item-calender">
    <div class="header">
        <div class="status" [ngClass]="{
            'status-finished': status === courseStatus.Success,
            'status-processing': status === courseStatus.Created,
            'status-locked': !status
        }">
            <img [src]="getStatusIcon(status)" alt="" />
            <span class="status-name">{{ getStatusText(status) }}</span>
        </div>
        <span class="number">{{index}}</span>
    </div>
    <h1 class="name">{{data?.themeName}}</h1>
    <img *ngIf="isCommented" src="assets/img/commented.png" alt="" />
</div>