import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FilesService } from 'src/app/core/services/files/files.service';
import { HeaderService } from 'src/app/core/services/header/header.service';
import { ToastrService } from 'ngx-toastr';
import { ProgressService } from 'src/app/core/services/progress/progress.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HomeworkSubmission, LessonType, ProgressDTO } from 'src/app/core/model';
import { environment } from 'src/environment';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { AmplitudeService } from 'src/app/core/services/analytics/amplitude.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';

@Component({
  selector: 'app-download-homework',
  templateUrl: './download-homework.component.html',
  styleUrls: ['./download-homework.component.scss']
})
export class DownloadHomeworkComponent implements OnChanges {
  @Input() groupId!: string;
  @Input() lessonId!: string;
  @Input() type: LessonType = LessonType.Homework;

  public localFileUrls: string[] = [];
  @Input() fileUrls: string[] = [];
  @Input() courseId: string = "";
  @Input() profile: any;
  @Input() data: ProgressDTO | undefined;
  @Input() isViewMode: boolean = false;
  @Input() isViewTaskMode: boolean = false;
  @Input() canSendHomework: boolean = false;
  @Output() switchViewMode: EventEmitter<boolean> = new EventEmitter<boolean>();
  public removedFiles: string [] = [];
  public readonly environment = environment;
  public form!: FormGroup;

  public isHomeworkExist: boolean = false;
  constructor(
    private filesService: FilesService,
    private headerService: HeaderService,
    private toastrService: ToastrService,
    private progressService: ProgressService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private amplitudeService: AmplitudeService,
    private loadingService: LoadingService
    ) {
      this.form = this.fb.group({
        text: ['']
      });

      this.form.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((res) => {
        this.checkHomeworkExistence();
        this.cdr.detectChanges();
      })
    }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['fileUrls']) {
      this.localFileUrls = [];
      if(this.fileUrls) {
        this.localFileUrls = [...this.fileUrls]
      }
    }
    if(changes['data']) {
      this.form.patchValue({text: this.data?.submissionText});

      if (this.profile && this.data) {
        this.data!['studentFirstname'] = this.profile.firstname;
        this.data!.studentLastname = this.profile.lastname;
      }
    }

    if (changes['profile'] && this.data) {
      this.data!['studentFirstname'] = this.profile.firstname;
      this.data!.studentLastname = this.profile.lastname;
    }
  }


  public downloadedFile(fileUrl: string): void {
    this.localFileUrls && this.localFileUrls.length > 0  ? this.localFileUrls.push(fileUrl) : this.localFileUrls = [fileUrl];
    this.checkHomeworkExistence();
  };

  public removeFile($event: string): void {
    const index = this.localFileUrls.indexOf($event);
    this.removedFiles.push($event)
    this.localFileUrls.splice(index, 1);

    this.checkHomeworkExistence();
  }
  public passHomework(): void {
    this.loadingService.loadingHomework$.next(true);

    const data: HomeworkSubmission = {
      submissions: this.localFileUrls,
      submissionText: this.form.get('text')?.value
    }

    if(this.type === LessonType.Homework) {
      this.progressService.passHomeWork(this.groupId, this.lessonId, data)
        .subscribe(res => {
          this.loadingService.loadingHomework$.next(false);

          if(res) {
            this.headerService.passedDeadline();
            this.amplitudeService.trackEvent('passHomeWork', data)

            this.switchViewMode.emit(true);
            this.toastrService.success('Вы успешно отправили домашное задание!');

            this.filesService.removeListOfFiles(this.removedFiles).subscribe({
              next: (res) => {
                this.cdr.detectChanges();
              },
              error: (err) => {

              }
            })
            this.cdr.detectChanges();
          }
        });
    }

    if(this.type === LessonType.WorkOnMistakes) {
      this.progressService.passWorkOnMistakes(this.groupId, this.lessonId, data)
        .subscribe(res => {
          this.loadingService.loadingHomework$.next(false);
          if(res) {
            this.headerService.passedDeadline();
            this.switchViewMode.emit(true);
            this.toastrService.success('Вы успешно отправили домашное задание!');
            this.cdr.detectChanges();
          }
        });
    }
  }

  public checkHomeworkExistence(): void {
    this.isHomeworkExist = !!(this.form.get('text')?.value || (this.localFileUrls && this.localFileUrls.length > 0));
  }

  get checkValidate(): boolean {
    return !!(this.form.get('text')?.value || (this.localFileUrls && this.localFileUrls.length > 0));
  }
}

