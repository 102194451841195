import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef
} from '@angular/core';
import { NotificationCard } from "../../../../core/model/interface/notification-card";
import { environment } from 'src/environment';
import { StudentHelperService } from 'src/app/feature/student/service/student-helper.service';
import { RedirectInfo } from 'src/app/shared/models';
import { BaseRoutes, CuratorRoutes, Roles } from 'src/app/core/model';
import { Params, Router } from '@angular/router';
import { NotificationService } from "../../../../core/services/notification/notification.service";
import { AnnouncementService } from "../../../../core/services/announcement/announcement.service";
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-notification-item-card',
  templateUrl: './notification-item-card.component.html',
  styleUrls: ['./notification-item-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationItemCardComponent implements OnChanges {
  @Input() notification: NotificationCard | undefined;
  @Input() isRead: boolean = false;
  @Input() index: number = 0;
  @Output() onReadNotification: EventEmitter<NotificationCard> = new EventEmitter<NotificationCard>();

  public visibleText: string | undefined;
  public picture: string | null = null;
  public isTextFullyVisible: boolean = false;

  constructor (
    private studentHelperService: StudentHelperService,
    private router: Router,
    private announcementService: AnnouncementService,
    private notificationService: NotificationService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService
  ) {}

  showMore() {
    this.visibleText = this.notification?.content;
    this.isTextFullyVisible = true;
  }

  private updateVisibleText() {
    // const maxLengthToShow = 143;
    const maxLengthToShow = 113;
    this.isTextFullyVisible = !this.notification?.content || this.notification.content.length <= maxLengthToShow;
    this.visibleText = this.isTextFullyVisible
      ? this.notification?.content
      : this.notification?.content.substring(0, maxLengthToShow) + '...';
  }

  toggleTextVisibility() {
    if (this.isTextFullyVisible) {
      this.isTextFullyVisible = false;
      this.updateVisibleText(); // Show the truncated text
    } else {
      this.isTextFullyVisible = true;
      this.visibleText = this.notification?.content; // Show the full text
    }
  }

  readAnnouncement() {
    const api = this.notification!.isRead
      ? this.announcementService.unReadAnnouncement(this.notification!.id)
      : this.announcementService.readAnnouncement(this.notification!.id);

    const previousValue = this.notification!.isRead;
    this.notification!.isRead = !this.notification?.isRead;

    api.subscribe({
      next: () => {
        this.onReadNotification.emit(this.notification);
        this.cdr.detectChanges();
      },
      error: _ => {
        this.notification!.isRead = previousValue;
        this.cdr.detectChanges();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateVisibleText();
    this.picture = (this.notification?.creator && this.notification.creator.profilePhotoUrl)
    ? environment.filesUrl + this.notification.creator.profilePhotoUrl
    : null;
  }

  public openLink(additionalInformation: any, type?: string): void {
    if(this.authService.getRole() === Roles.Curator) {
      if(type === 'INVITED_TO_EVENT') {
        this.router.navigate([`${BaseRoutes.Curator}/${BaseRoutes.Calendar}`])
      }
      else if(type === 'PROGRESS_COMMENTED' || type === 'LESSON_UPLOADED') {
        this.router.navigate([`${BaseRoutes.Curator}/${additionalInformation.groupId}/${additionalInformation.themeId}/${additionalInformation.lessonId}`], );
      }
      this.notificationService.toggleIsShowNotificationModal();
      return
    }
    if(additionalInformation.event?.startTime?.length) {
      // window.open(additionalInformation.event.redirectUrl);
      const year = additionalInformation.event.startTime[0];
      const month = additionalInformation.event.startTime[1];
      const queryParams: Params = {
        chosenDate: `${year}-${month > 9 ? month : '0' + month}-01`
      };

      this.router.navigate([`${BaseRoutes.Student}/${BaseRoutes.Calendar}`], {
        queryParams,
        queryParamsHandling: 'merge',
      }).then();
      this.notificationService.toggleIsShowNotificationModal();
    }

    if(additionalInformation.groupId) {
      const info: RedirectInfo = {
        groupId: additionalInformation.groupId,
        courseId: additionalInformation.courseId,
        id: additionalInformation.lessonId,
        name: '',
        lessonType: additionalInformation.lessonType
      }
      this.router.navigate([`${BaseRoutes.Student}/${this.studentHelperService.generateLessonsSiblingsRoute(info)}`]);
      this.notificationService.toggleIsShowNotificationModal();
    }
  }
}
