<div class="pagination flex cg-8 p-12" [class.justify-center]="totalPages < 15">
  <ng-container *ngFor="let p of [].constructor(totalPages); let i = index">
    <button
      class="page flex justify-center items-center"
      [ngClass]="{
        page_active: i === page
      }"
      (click)="onPageClickHandler(i)"
    >
      {{ i + 1 }}
    </button>
  </ng-container>
</div>
