import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { FeatureModule } from './feature/feature.module';
import { CoreModule } from './core/core.module';
import { ApiInterceptor } from 'src/app/core/intercepters/api.interceptor';

import myLocaleRu from '@angular/common/locales/ru-KZ';
import { registerLocaleData } from '@angular/common';
import { ErrorInterceptor } from './core/intercepters/error.interceptor';
import { FullCalendarModule } from '@fullcalendar/angular';
import ru from '@angular/common/locales/ru';
import kk from '../assets/i18n/kkt';
import { TranslationService } from './shared/services/localeService/translate.service';
import { StoreModule } from '@ngrx/store';
import { groupsReducer } from './core/state/student/groups/groups.reducer';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { GroupEffects, GroupsEffects } from './core/state/student/groups/groups.effects';
import { progressReducer } from './core/state/student/progress/progress.reducer';
import { FinishQuizEffect, ProgressEffects, StartQuizEffect } from './core/state/student/progress/progress.effects';
import { userReducer } from './core/state/student/user/user.reducer';
import { PaymentEffects, UserEffects, UserUpdateEffects } from './core/state/student/user/user.effects';
import { environment } from "../environment";
import { AngularFireModule } from "@angular/fire/compat";

registerLocaleData(myLocaleRu);
registerLocaleData(ru);
registerLocaleData(kk);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    BrowserModule,
    FullCalendarModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationService,
        // useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    FeatureModule,
    CoreModule,
    StoreModule.forRoot({ groups: groupsReducer, progress: progressReducer, user: userReducer}, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forRoot([
      GroupsEffects,
      GroupEffects,
      ProgressEffects,
      UserEffects,
      UserUpdateEffects,
      PaymentEffects,
      StartQuizEffect,
      FinishQuizEffect,
    ]),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: !isDevMode()}),
    AngularFireModule.initializeApp(environment.firebaseConfig)
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
  ],
})
export class AppModule {}
