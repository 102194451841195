import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { SelectValue } from 'src/app/shared/models';

@Component({
  selector: 'app-discussion',
  templateUrl: './discussion.component.html',
  styleUrls: ['./discussion.component.scss']
})
export class DiscussionComponent implements OnInit {
  @Input() canExpand: boolean = false;
  @Input() canSelectTheme: boolean = false;
  @Input() canSelectLesson: boolean = false;

  public isOpen: boolean = true;
  public baseOpen: boolean = true;

  updateExpanderSubject: Subject<void> = new Subject<void>();

  public form!: FormGroup;

  public themesOptions: SelectValue[] = [{
    name: 'Theme 1',
    value: '12'
  }];
  public lessonsOptions: SelectValue[] = [{
    name: 'Lesson 1',
    value: '12'
  }];

  emitEventToChild() {
    this.updateExpanderSubject.next();
  }

  constructor(private fb: FormBuilder) { 
    this.form = this.fb.group({
      themes: [''],
      lessons: ['']
    })
  }

  ngOnInit() {
  }

  public toggle(): void {
    this.isOpen = !this.isOpen;
  }

  goToTest(): void {
    this.baseOpen = false;
    this.emitEventToChild();
  }

  goToBack() {
    this.baseOpen = true;
    this.emitEventToChild();
  }
}
