import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionnaireViewControllerComponent } from './questionnaire-view-controller.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FiveStarsComponent } from './five-stars/five-stars.component';
import { YerOrNoComponent } from './yer-or-no/yer-or-no.component';
import { OpenComponent } from './open/open.component';
import { BaseModule } from '../../base/base.module';

@NgModule({
  exports: [
    QuestionnaireViewControllerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BaseModule
  ],
  declarations: [
    QuestionnaireViewControllerComponent,
    FiveStarsComponent,
    YerOrNoComponent,
    OpenComponent
  ]
})
export class QuestionnaireViewControllerModule { }
