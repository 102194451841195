<app-dialog [isOpen]="isOpen" [title]="'finalizationTest' | translate" [showButtons]="false"
            (closeEvent)="closeEvent.emit()">
  <div class="app-finish-test">
    <span class="desc" [innerHTML]="'finishTestError' | translate"></span>
    <div class="buttons">
      <button class="iconic-button primary-button button"
              (click)="closeEvent.emit()">{{'continueTest' | translate}}</button>
      <button class="iconic-button secondary-button button"
              (click)="finishEvent.emit()">{{'finishTest' | translate}}</button>
    </div>
  </div>
</app-dialog>
