<div class="cart-modal-card p-24 border flex radius-16" *ngIf="card">
  <div class="image">
    <img [src]="subjectsId[card.subjectId]" alt="" />
  </div>

  <div class="description">
    <div class="title">{{ card.name }}</div>
    <div class="duration-date flex items-center">
      <div class="duration">{{ card.duration || '-'}}</div>
      <div class="circle"></div>
      <div class="date">Старт: {{ card.startDate || '-'}}</div>
    </div>
    <div class="price flex items-center">
      <div class="price_new">{{ card.price | number }} ₸</div>
      <div class="price_old" *ngIf="card.oldPrice">{{ card.oldPrice | number }} ₸</div>
    </div>
    <button class="btn btn-remove radius-8" (click)="removeItemFromCart(card)">{{ 'removeFromCart' | translate}}</button>
  </div>
</div>
