import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  IDicountsSubjects,
  IDiscounts,
  IDiscountsUserDetails
} from 'src/app/core/model/interface/discounts';
import { DiscountsService } from './../../../../core/services/discounts/discounts.service';
import { MatDialog } from '@angular/material/dialog';
import { AdminModalComponent } from 'src/app/core/pages/admin/_components/admin-modal/admin-modal.component';
import { Roles } from 'src/app/core/model';
import { environment } from "../../../../../environment";

interface IDicountsWithActive extends IDiscounts {
  isActive: boolean
}
@Component({
  selector: 'app-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class DiscountComponent implements OnInit, AfterViewInit {
  @Input() subject!: IDicountsSubjects;
  environment = environment

  @Input() isFirst: boolean = false;
  @Input() isOpen: boolean = false;

  @Output() isChanged: EventEmitter<string | null> = new EventEmitter<string | null>();

  public discounts: IDicountsWithActive[] = [];
  public data: IDiscountsUserDetails[] = [];
  public rows: any[] = [];

  public headers: string[] = ['Студент', 'Логин', 'Доступность', 'Лимит'];

  // public isOpen: boolean = false;
  public readonly Object = Object;


  constructor(
    private discountsService: DiscountsService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.discounts = this.subject.discounts.map((item, index) => {
      if(index === 0) {
        return {
          isActive: true,
          ...item,
        }
      } else {
        return {
          isActive: false,
          ...item
        }
      }
    });
  }

  ngAfterViewInit(): void {
    if(this.isFirst) {
      this.loadDiscountData(this.discounts[0].id);
      this.cdr.detectChanges();
    }
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
    this.subject.isOpen = !this.subject.isOpen;

    if(this.isOpen || this.subject.isOpen) {
      this.loadDiscountData(this.discounts[0].id);
    }
  }

  private loadDiscountData(id: string): void {
    this.discountsService.getDiscountUserDetails(id).subscribe({
      next: (res) => {
        this.data = res;
        this.rows = res.map(item => {
          return {
            fio: item?.studentFirstname + ' ' + item?.studentLastname,
            login: item.username,
            access: item.always ? 'Навсегда' : 'Ограниченный',
            limit: (item.discountLeft || item.discountLeft === 0) ? item.discountLeft : "&#8734;",
          }
        });
        this.cdr.detectChanges();
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  public setActiveDiscount(id: string): void {
    this.discounts = this.discounts.map(item => {
      if(item.id === id) {
        item.isActive = true;
      }
      else {
        item.isActive = false;
      }
      return item;
    });

    this.loadDiscountData(id);
  }

  public pickedUser(index: number): void {
    const row = this.data.find((item, i) => i === index);
    this.dialog.open(AdminModalComponent, {
      data: {
        userId: row?.studentId,
        username: row?.username,
        role: Roles.Student,
        isDiscount: true
      },
      height: '100vw',
      width: '500px',
      position: { right: '0px', top: '0px' },
      disableClose: true
    }).afterClosed().subscribe(res => {
      if (res?.id || res?.username) {
        const index = this.data.findIndex(x=>x.studentId === res.id || res?.username);
        this.data[index].studentFirstname = res.firstname;
        this.data[index].studentLastname = res.lastname;
        this.data[index].username = res.username;
        this.rows = this.data.map(item => {
          return {
            fio: item.studentFirstname + ' ' + item.studentLastname,
            login: item.username,
            access: item.always ? 'Навсегда' : 'Ограниченный',
            limit: item.discountLeft || "&#8734;",
          }
        });
        this.isChanged.emit(this.subject.subjectId);
      }

      if (res?.discountUserDetails) {
        const findActive = this.discounts.find(x => x.isActive);
        if (findActive) {
          this.setActiveDiscount(findActive.id);
        }
        this.isChanged.emit(this.subject.subjectId);
      }
      this.cdr.detectChanges();
    });
    return;
  }
}
