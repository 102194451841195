<app-announcements-alert/>
<div class="login-register">

    <section class="left">
        <a class="logo-link" routerLink="/">
          <img class="w-100" src="assets/img/logo-new.png" alt="" />
        </a>
        <app-translation-select [lang]="language" (change)="setLang($event)"/>
        <ng-content></ng-content>
    </section>
    <section class="right">
        <div class="draft-img"
          [style.background-image]="'url(/assets/img/registration-' + language + '.png)'"
        ></div>
    </section>
</div>
