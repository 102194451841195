import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

export interface ITab {
  id: number;
  label: string;
  isActive: boolean;
}

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TabsComponent {
  @Input() tabs: any = [];
  @Output() onTabClickListener: EventEmitter<any> = new EventEmitter();

  public onTabClick(tab: ITab): void {
    this.onTabClickListener.emit(tab);
  }
}
