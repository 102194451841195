import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { NoopValueAccessorDirectiveDirective, injectNgControl } from 'src/app/shared/directives/noop-value-accessor-directive/noop-value-accessor-directive.directive';
import { SelectValue } from 'src/app/shared/models';

@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [NoopValueAccessorDirectiveDirective], 
})
export class SelectInputComponent {
  ngControl = injectNgControl();

  @Input() name: string = "";
  @Input() isActive: boolean = true;
  @Input() prefix: string = "";
  @Input() mask: string | null | undefined;
  @Input() error: string = "";
  @Input() control: AbstractControl | undefined = undefined;
  @Input() placeholder: string = "";
  @Input() options: SelectValue[] = [];

  public focused: boolean = false;
  public isSelectShown: boolean = false;

  @ViewChild('inputField', { static: true }) inputField!: ElementRef<HTMLInputElement>;
  
  onFocus(): void {
    this.focused = true;
    this.isSelectShown = true;
  };
  onBlur(): void {
    this.focused = false;
  };

  handleInputChange(): void {
    if(this.ngControl.control.status === 'VALID') {
      this.isSelectShown = false;
    }
    else {
      this.isSelectShown = true;
    }
  }

  clearInput(): void {
    this.inputField.nativeElement.value = "";
    this.inputField.nativeElement.focus();
  }

  onSelect(value: number | string | boolean): void {
    this.isSelectShown = false;
    this.inputField.nativeElement.value = value.toString();

    // Update the FormControl's value when an option is selected
    if (this.ngControl.control instanceof FormControl) {
      this.ngControl.control.setValue(value);
    }
  }
}
