<app-dialog
  [isOpen]="isOpen"
  (confirmEvent)="finish()"
  (closeEvent)="close()"
  [title]="'miniTestForLecture' | translate"
  [showButtons]="false">
  <ng-container>
    <div class="app-mini-test">
      <ul class="list">
        <li class="item" *ngFor="let item of data?.lesson?.questions; let i = index">
          <app-questions
            [quizState]="quizState"
            [position]="i"
            [question]="item"
            [isViewTaskMode]="isViewModeTask"
            (answeredEvent)="userAnswered($event)">
          </app-questions>
        </li>
      </ul>
      <button
        class="iconic-button primary-button button"
        (click)="openConfirmDialog()"
        [disabled]="isViewModeTask">
        {{( quizState === QuizState.View ) ? ('close' | translate) : ('finishTest' |translate)}}
      </button>
    </div>
  </ng-container>
</app-dialog>

<app-dialog [isOpen]="openConfirmModel"
            title="finalizationTest"
            closeBntText="CONTINUE_TEST"
            confirmBntText="FINISH_TEST"
            [disabledConfirm]="false"
            (confirmEvent)="finish()">
  <p>{{'ARE_YOU_SURE_TO_FINISH_TEST' | translate}}</p>
</app-dialog>
