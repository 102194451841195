import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { VideoDTO } from 'src/app/shared/models';
import * as iframeApiLoader from '@kinescope/player-iframe-api-loader';
import * as uuid from 'uuid';

@Component({
  selector: 'app-iframe-kinoscope-player',
  templateUrl: './iframe-kinoscope-player.component.html',
  styleUrls: ['./iframe-kinoscope-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IframeKinoscopePlayerComponent implements OnInit, AfterViewInit {
  public uniqueId = uuid.v4();

  @ViewChild('#iframe') iframeRef: ElementRef | undefined;
  
  @Input() videoUrl: string | undefined;
  @Output() sendPlayed: EventEmitter<VideoDTO> = new EventEmitter<VideoDTO>();

  public iFrame: Kinescope.IframePlayer.Player | undefined | void;

  public player: any;
  constructor(
  ) 
    {}

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.createPlayer();
  }

  async createPlayer(): Promise<void> {
    const url: string = this.videoUrl!.match(/src="([^"]+)"/)![1];
    const factory = await iframeApiLoader.load();
    
    this.player = await factory.create(this.uniqueId, {
      url: url,
      size: { width: '100%', height: '100%' },
    })
    .then(function (player) {
      player
        // 4. Этот обработчик будет вызван когда плеер загрузит необходимые данные для воспроизведения.
        .once(player.Events.Ready, function (event: { target: { setVolume: (arg0: number) => void; }; }) {
          event.target.setVolume(0.5);
        })
        .on(player.Events.Playing, function(res) {
          console.log(res); 
        })
        .on(player.Events.Destroy, function(res) {
          console.log(res);
        })
    });
  }

}
