import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StreamService {
  constructor(private _http: HttpClient) {}

  public getById(id: string): Observable<any> {
    return this._http.get<any>(`/v1/streams/${id}`);
  }
  
  public getListBySubject(id: string): Observable<any> {
    return this._http.get<any>(`/v1/streams/subject/${id}`);
  }
  
  public swapCourses(streamId: string, newCourseId: string): Observable<any> {
    return this._http.patch<any>(`/v1/streams/${streamId}/swapcourses`, { newCourseId });
  }
}
