<mat-slide-toggle
    [formControl]="ngControl.control"
    [disabled]="disabled"
    [(ngModel)]="toggleValue"
    (change)="onToggleChange($event)"
    color="accent"
>
    {{text | translate}}
  <div *ngIf="tooltipImg"
       (mouseenter)="openCloseTooltip()"
       (mouseleave)="openCloseTooltip()"
       class="toggle-info">
    <img class="ml-10" src="./assets/svg/informative/base-info.svg" alt="">
    <div *ngIf="showInfo" class="toggle-info-block">
      <img src="./assets/svg/informative/base-info.svg" alt="">
      <p>{{'ACTIVE_COURSE_INFO' | translate}}</p>
    </div>
  </div>
</mat-slide-toggle>
