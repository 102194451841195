<app-dialog
    [isOpen]="isOpen"
    (closeEvent)="closeDialog()"
    [title]="data ? 'EDIT_COURSE' : 'ADD_NEW_COURSE'"
    [disabledConfirm]="form.invalid"
    [showButtons]="true"
    (confirmEvent)="save()">
    <ng-container>
        <div class="app-add-new-course">
            <form class="form" [formGroup]="form">
                <app-input
                    name="name"
                    formControlName="name"
                ></app-input>
                <app-select
                    name="TEACHER1"
                    formControlName="teachers"
                    [options]="teachers ? teachers : []"
                ></app-select>
                <div class="additional">
                    <app-select
                        class="select"
                        name="YEAR_OF_STUDY"
                        [options]="years"
                        formControlName="year"
                    ></app-select>
                    <app-select
                        class="select"
                        name="MONTH"
                        [options]="months"
                        formControlName="month"
                    ></app-select>
                </div>
            </form>
        </div>
    </ng-container>
</app-dialog>
