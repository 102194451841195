import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'earlierThanGivenTime'
})
export class EarlierThanGivenTimePipe implements PipeTransform {

  transform(value: string | null | Date, futureTime: string | null | Date): boolean {
    if (!value || !futureTime) {
      return false;
    }

    // Convert the value and futureTime to Date objects if they are strings
    const dateValue = value instanceof Date ? value : new Date(value);
    const dateFutureTime = futureTime instanceof Date ? futureTime : new Date(futureTime);
      
    // Check if the date value is earlier than the future time
    return dateValue.getTime() < dateFutureTime.getTime();
  }

}
