import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Languages } from '../../models';
import { LessonType, LessonTypeKZ, LessonTypeRU } from 'src/app/core/model';

@Pipe({
  name: 'reverseLessonType',
  pure: true,
})
export class ReverseLessonTypePipe implements PipeTransform {

  transform(lessonType: LessonType, language: Languages = Languages.KZ): LessonTypeKZ | LessonTypeRU {
    switch (language) {
      case Languages.KZ:
        switch (lessonType) {
          case LessonType.Lecture:
            return LessonTypeKZ.Lecture;
          case LessonType.Homework:
            return LessonTypeKZ.Homework;
          case LessonType.Quiz:
            return LessonTypeKZ.Quiz;
          case LessonType.WorkOnMistakes:
            return LessonTypeKZ.WorkOnMistakes;
          case LessonType.Text:
            return LessonTypeKZ.Text;
          default:
            return LessonTypeKZ.Lecture;
        }
      case Languages.RU:
        switch (lessonType) {
          case LessonType.Lecture:
            return LessonTypeRU.Lecture;
          case LessonType.Homework:
            return LessonTypeRU.Homework;
          case LessonType.Quiz:
            return LessonTypeRU.Quiz;
          case LessonType.WorkOnMistakes:
            return LessonTypeRU.WorkOnMistakes;
          case LessonType.Text:
            return LessonTypeRU.Text;
          default:
            return LessonTypeRU.Lecture;
        }
      default:
        return LessonTypeKZ.Lecture;
    }
  }
}
