import {Injectable} from '@angular/core';
import * as amplitude from '@amplitude/analytics-browser';
import {environment} from "../../../../environment";

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {

  public init() {
    amplitude.init(environment.amplitudeApiKey);
  }

  public trackEvent(eventName: string, eventData?: any) {
    amplitude.track(eventName, eventData);
  }
}
