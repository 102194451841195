import {Injectable} from "@angular/core";
import {NotificationCard} from "../../model/interface/notification-card";
import {IAnnouncement} from "../../model/interface/announcement";
import {an} from "@fullcalendar/core/internal-common";
import {FilesService} from "../../services/files/files.service";
import {environment} from "../../../../environment";
import { DateHelperServiceService } from './../../../shared/services/dateHelperService/date-helper-service.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationMapper {

  constructor(private dateHelper: DateHelperServiceService) {
  }

  public toNotificationCard(announcement: any): NotificationCard {
    return {
      id: announcement.id,
      fullName: NotificationMapper.getName(announcement),
      header: announcement.header,
      imagePath: this.getPhotoUrl(announcement),
      roleName: `${announcement.creator?.roles[0] ?? ''}`,
      content: announcement.description,
      date: this.dateHelper.convertUTCToLocal(NotificationMapper.parseDate(announcement)).toISOString(),
      isRead: announcement.isRead,
      creator: announcement.creator,
      additionalInformation: announcement.additionalInformation,
      type: announcement.type
    } as unknown as NotificationCard;
  }

  private static parseDate(announcement: any) {

    return new Date(announcement.createdAt)
  }

  private static getName(announcement: any) {
    if (announcement.system) {
      return 'Juz40';
    }

    return `${announcement.creator.firstname} ${announcement.creator.lastname}`;
  }

  private getPhotoUrl(announcement: any) {
    if (announcement.system) {
      return 'assets/img/avatar.png';
    }

    if (announcement.creator.profilePhotoUrl) {
      return `${environment.filesUrl}${announcement.creator.profilePhotoUrl}`;
    }

    return 'assets/img/no-img.png';
  }
}
