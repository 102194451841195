import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-video-card',
  templateUrl: './video-card.component.html',
  styleUrls: ['./video-card.component.scss']
})
export class VideoCardComponent implements OnInit {
  @Input() withBorder: boolean = true;
  @Output() commentsClicked: EventEmitter<void> = new EventEmitter<void>();
  public items: number[] = [5, 4, 3, 2, 1]
  constructor() { }

  ngOnInit() {
  }

}
