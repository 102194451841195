export const environment = {
  production: false,
  baseUrl: 'https://test-api.juz40-edu.kz',
  filesUrl: 'https://files-api.juz40-edu.kz/dev-bucket/',
  uploadFilesUrl: 'https://converter.juz40-edu.kz/converter/upload?isTest=',
  base: 'https://test.juz40-edu.kz',
  amplitudeApiKey: '60410abcfcaa54e4f6d1946d5d5d03f0',
  kaspi: {
    successUrl: 'https://test.juz40-edu.kz/kaspi-pay-success',
    serviceId: 'Juz40Education',
    refererHost: 'juz40-edu.kz',
  },
  firebaseConfig: {
    apiKey: "AIzaSyDdigUtNEO-M1zf_4sbga-TO1R-I_m1fsk",
    authDomain: "juz40-3e1db.firebaseapp.com",
    projectId: "juz40-3e1db",
    storageBucket: "juz40-3e1db.appspot.com",
    messagingSenderId: "539773800657",
    appId: "1:539773800657:web:d267f7cc7f9d0e57e8f5f0",
    measurementId: "G-VH4WZ0CFH7"
  }
};
