import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ForAnswerTestQuestions, QuizState, TestType, UserAnswer } from 'src/app/core/model';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionsComponent {
  public readonly TestType = TestType;
  public readonly QuizState = QuizState;

  @Input() question!: ForAnswerTestQuestions;
  @Input() quizState: QuizState = QuizState.Passing;

  @Input() position: number = 1;
  @Input() pattern: string = "";
  @Input() isViewTaskMode: boolean = false;

  @Output() answeredEvent: EventEmitter<{ questionId: string, answer: UserAnswer[]}> = new EventEmitter<{ questionId: string, answer: UserAnswer[]}>();

}
