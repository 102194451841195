import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationModalComponent } from './components/notification-modal/notification-modal.component';
import { NotificationItemCardComponent } from './components/notification-item-card/notification-item-card.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {TranslateModule} from "@ngx-translate/core";
import { NonBaseModule } from 'src/app/shared/components/non-base/non-base.module';



@NgModule({
  declarations: [
    NotificationModalComponent,
    NotificationItemCardComponent
  ],
  exports: [
    NotificationModalComponent
  ],
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        TranslateModule,
        NonBaseModule
    ]
})
export class NotificationModule { }
