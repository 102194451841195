import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-sidebar-main',
  templateUrl: './sidebar-main.component.html',
  styleUrls: ['./sidebar-main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarMainComponent {

}
