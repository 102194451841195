<button *ngIf="course"
        (click)="openGroup(course.id)"
        [disabled]="(currentGroupWithDetails$ | async)?.groupId === course.id"
        class="duty-card">
  <div class="duty-card_img flex justify-center items-center">
    <img class="image" [src]="subjectsId[course.subjectId || '']" alt="">
  </div>

  <div class="duty-card_content flex flex-col">
    <div>
      <h2 class="title">{{course.courseName}}</h2>
      <h4 *ngIf="RolesEnum.Student || RolesEnum.Methodist"
          class="subtitle">{{course.name}}</h4>
    </div>
<!--    <div *ngIf="RolesEnum.Student" class="duty-card_content-progress">-->
<!--      <app-progress-bar class="progress-bar" [value]="100"></app-progress-bar>-->
<!--      <div class="progress-desc flex justify-between">-->
<!--        <span>{{'myProgress' | translate}}</span>-->
<!--        <span class="value">100%</span>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</button>
