<div *ngIf="iframeSrc" class="pdf-viewer" [ngStyle]="{'height': height + 'vh'}" #content>
    <div *ngIf="mobileAdaptive" class="pdf-viewer-btn">
      <img
        [src]="'assets/svg/files/' + (url || '' | fileFormat: true)?.icon"
        alt=""
      />
      <p class="pdf-viewer-btn_text">{{(url || '' | fileFormat).fileName}}</p>
      <button
        class="pdf-viewer-btn_close flex items-center justify-center"
        (click)="close()"
      >
        <img src="./assets/svg/plus.svg" alt="" />
      </button>
    </div>
    <div class="h-100" [class.frame-container]="mobileAdaptive">
      <iframe class="desktop" [src]="iframeSrc"  height="100%"  width="100%"></iframe>
      <div class="h-100 w-100 mobile">
        <app-page-progress-spinner *ngIf="isMobilePdfLoading"/>
        <pdf-viewer [src]="mobileUrl || ''"
                    [original-size]="false"
                    [render-text]="false"
                    [fit-to-page]="true"
                    [autoresize]="true"
                    (error)="setError($event)"
                    (on-progress)="setPdfLoading()"
                    (pages-initialized)="offPdfLoading()"
                    [ngStyle]="{height: isMobilePdfLoading ? '10px' : '100%'}"
                    style="width: 100%"/>
      </div>
    </div>
</div>
<div class="no-file" *ngIf="!iframeSrc">
    <h1 class="title">Файл отсуствует</h1>
</div>
