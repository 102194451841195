import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { ToastService } from "../toast/toast.service";
import { StorageService } from "../../../core/services/storage/storage.service";
import { StudentHelperService } from "../../../feature/student/service/student-helper.service";
import { RedirectInfo } from "../../models";
import { BaseRoutes } from "../../../core/model";

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  currentMessage$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private afMessaging: AngularFireMessaging,
    private _ns: ToastService,
    private storageService: StorageService,
    private studentHelperService: StudentHelperService
  ) {}

  requestPermission() {
    console.log('requestPermission');
    this.afMessaging.requestToken.subscribe({
      next: (token)  => {
        console.log('firebase token: ', token);
        this.storageService.setFcmToken(token || '');
      },
      error: (err) => {
        this.storageService.removeFcmToken();
        console.error('Unable to get permission to notify.', err);
      }
    });
  }

  receiveMessage() {
    this.afMessaging.messages.subscribe({
    next: (payload) => {
      console.log("new message received: ", payload);
      this.currentMessage$.next(payload);
      this.showCustomNotification(payload);
      }
    });
  }

  public showCustomNotification(payload: any) {
    const notify_data = payload['notification'];
    const title = notify_data?.['title'];
    const description = notify_data?.['description'];
    const role = notify_data?.['role'];

    const info: RedirectInfo = {
      groupId: notify_data?.['groupId'],
      courseId: notify_data?.['courseId'],
      id: notify_data?.['lessonId'],
      name: '',
      lessonType: notify_data?.['lessonType']
    }

    const options = {
      title: title,
      body: description,
      icon: './assets/img/welcome.png',
      image: './assets/img/welcome.png',
      click_action: role === BaseRoutes.Student
        ? `${BaseRoutes.Student}/${this.studentHelperService.generateLessonsSiblingsRoute(info)}`
        : 'https://test.juz40-edu.kz/'
    }

    console.log('notify data: ', notify_data, ' [options]: ', options);
    const notify: Notification = new Notification(title, options);

    notify.onclick = event => {
      event.preventDefault();
      window.open(options.click_action, '_blank');
    }
  }
}
