import { QuizState, TestAnswerDTO, UserAnswer } from './../../../../core/model/interface/progress';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { TestType, LectureWithProgress } from 'src/app/core/model';
import { LessonStatus } from 'src/app/shared/models';
import { AmplitudeService } from './../../../../core/services/analytics/amplitude.service';

@Component({
  selector: 'app-mini-test',
  templateUrl: './mini-test.component.html',
  styleUrls: ['./mini-test.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MiniTestComponent implements OnChanges {
  public readonly TestType = TestType;

  @Input() groupId: string = "";
  @Input() data: LectureWithProgress | undefined;
  @Input() isOpen: boolean = false;
  @Input()  quizState: QuizState = QuizState.Passing;
  @Input() isViewModeTask: boolean = false;
  @Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() finishEvent: EventEmitter<TestAnswerDTO[]> = new EventEmitter<TestAnswerDTO[]>();

  public readonly QuizState = QuizState;
  openConfirmModel: boolean = false;

  constructor(private amplitudeService: AmplitudeService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['quizState'] && this.data) {
      if(this.quizState === QuizState.View) {
          const sortedQuestions = this.data.lesson?.questions?.slice().sort((a ,b ) => {
          const idAIndex =  this.data!.progress?.questionPassingDtos?.findIndex(item => item.questionId === a.id);
          const idBindex =  this.data!.progress?.questionPassingDtos?.findIndex(item => item.questionId === b.id);
          return idAIndex - idBindex;
        });

        if(this.data.lesson && this.data.lesson.questions && this.data.progress && this.data.progress.questionPassingDtos) {
          this.data.progress.questionPassingDtos = this.data.progress.questionPassingDtos.filter(item => this.data!.lesson.questions?.find(ques => ques.id === item.questionId))
          
        }

        if(this.data.lesson && this.data.lesson.questions) {
          this.data.lesson.questions = sortedQuestions?.map((item, index) => {
            const currentPassingDto = this.data!.progress?.questionPassingDtos?.[index];
            return {
              ...item,
              correctAnswerTexts: currentPassingDto ? currentPassingDto.correctAnswerTexts : [],
              userAnswers: currentPassingDto ? currentPassingDto.userAnswers : [],
              score: currentPassingDto ? currentPassingDto.score : 0,
              isUserAnswered: false,
            };
          }) || [];
        }
      }
    }
  }

  public userAnswered($event: {questionId: string,  answer: UserAnswer[]}) {

    let question = this.data?.progress.questionPassingDtos.find(item => item.questionId === $event.questionId);
    question!.userAnswers = $event.answer;
    this.amplitudeService.trackEvent('userAnswered', question)
  }

  public close(): void {
    this.closeEvent.emit();
  }

  public finish(): void {
    this.openConfirmModel = false;
    if(this.data!.progress?.status === LessonStatus.Success) {
      this.closeEvent.emit();
      return;
    }

    const answers: TestAnswerDTO[] = this.data!.progress.questionPassingDtos.map(item => {
      return {
        questionId: item.questionId,
        groupId: this.groupId,
        lessonId: this.data!.lesson.id,
        userAnswers: item.userAnswers,
        quizQuestionType: item.quizQuestionType,
      }
    });

    this.finishEvent.emit(answers)
  }

  openConfirmDialog(): void {
    if (this.quizState === QuizState.View) {
      this.finish();
      return;
    }
    this.openConfirmModel = !this.openConfirmModel;
  }
}
