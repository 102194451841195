import { AuthService } from './../../../../../../core/services/auth/auth.service';
import { CuratorService } from './../../../../../../core/services/curator/curator.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LectureDTO, LessonDTO, Roles, UsefullLink } from 'src/app/core/model';
import { LessonsService } from 'src/app/core/services/lessons/lessons.service';
import { Observable, Subscription } from 'rxjs';
import { getFormControlErrors } from 'src/app/shared/validators';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { SelectValue } from "../../../../../../shared/models";
import { getTimeOptions } from "../common/common";
import { FoldersService } from './../../../../../../core/services/folders/folders.service';

@Component({
  selector: 'app-theory-controller',
  templateUrl: './theory-controller.component.html',
  styleUrls: ['./theory-controller.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TheoryControllerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data: LessonDTO | undefined;
  @Input() name!: string;
  @Input() isOpen: boolean = false;

  private eventsSubscription!: Subscription;
  private routerSubscription!: Subscription;
  public timeOptions: SelectValue[];
  @Input() saveEvent!: Observable<void>;
  @Output() allowSaveEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() savedEvent: EventEmitter<LessonDTO> = new EventEmitter<LessonDTO>();

  public baseForm!: FormGroup;
  public baseFormErrors = {
    description: "",
    videoUrl: "",
    miniTestMaxScore: "",
    mustHaveMaterials: "",
    additionalMaterials: ""
  }

  public userFulLinks: UsefullLink[] = [];
  public unsavedChanges: boolean = false;
  public confirmationShown: boolean = false;

  public roles = Roles;
  public role: Roles = Roles.Methodist;

  public studentsList: SelectValue[] = [];
  public groupId: string = "";
  public themeId: string = ""

  public foldersOptions: SelectValue[] = [];

  constructor(
    private lessonsService: LessonsService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private translateService: TranslateService,
    private authService: AuthService,
    private curatorService: CuratorService,
    private route: ActivatedRoute,
    private FoldersService: FoldersService

  ) {
    this.timeOptions = getTimeOptions();
    this.baseForm = this.formBuilder.group({
      markedStudents: [null],
      description: ['', Validators.required],
      content: [''],
      videoUrl: ['', Validators.required],
      rating: [true],
      miniTestMaxScore: [0, Validators.required],
      mustHaveMaterials: [[]],
      additionalMaterials: [[]],
      usefulLinks: formBuilder.array([]),
      usersIds: [[]],
      courseFolderId: ['']
    });

    this.baseForm.statusChanges.subscribe(status => {
      const anyCustomError: boolean =
        this.baseFormErrors.description
        && this.baseFormErrors.videoUrl
        && this.baseFormErrors.miniTestMaxScore
        && this.baseFormErrors.mustHaveMaterials
        ? true : false;
      this.allowSaveEvent.emit((status === 'VALID' && !anyCustomError) ? true : false);
    });

    this.baseForm.valueChanges.subscribe(res => {
      this.unsavedChanges = true;

      if(!res.description) {
        this.baseFormErrors.description = "TYPE_DESCRIPTION";
        this.allowSaveEvent.emit(false);
      }
      else {
        this.baseFormErrors.description = "";
      }

      if(!res.videoUrl) {
        this.baseFormErrors.videoUrl = "TYPE_DESCRIPTION";
        this.allowSaveEvent.emit(false);
      } else {
        this.baseFormErrors.videoUrl = "";
        const videoUrl = res.videoUrl;
        const trimmedValue = videoUrl?.trim();
        if (trimmedValue !== videoUrl) {
          this.baseForm.get('videoUrl')?.setValue(trimmedValue);
        }
      }

      if((res.miniTestMaxScore !== 0) && !res.miniTestMaxScore) {
        this.baseFormErrors.miniTestMaxScore = "TYPE_DESCRIPTION";
        this.allowSaveEvent.emit(false);
      }
      else {
        this.baseFormErrors.miniTestMaxScore = "";
      }

      this.cdr.detectChanges();
    });

  }

  ngOnInit(): void {
    this.role = this.authService.getRole();

    this.eventsSubscription = this.saveEvent.subscribe(() => {
      this.save();
    });

    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.isOpen && this.unsavedChanges && !this.confirmationShown) {
          this.confirmationShown = true;
          const shouldContinue = confirm(`В "${this.data?.name}" есть не сохраненые изменения.Хотите продолжить?`);
          if (!shouldContinue) {
            const currentRoute = this.router.routerState;
            this.router.navigateByUrl(currentRoute.snapshot.url, { skipLocationChange: true });
          }
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['data'] && this.data) {
      if(this.authService.getRole() === Roles.Methodist) {
        this.route.params.subscribe(params => {
          this.FoldersService.getFoldersMethodist(params['id']!).subscribe({
            next: (res) => {
              this.foldersOptions = res[0].courseFolders.map(item => {
                return {
                  name: item.courseFolderName,
                  value: item.courseFolderId
                }
              });
              this.cdr.detectChanges();
              this.baseForm.patchValue({ courseFolderId: this.data?.courseFolderId || []})

              this.cdr.detectChanges();
            },
            error: (err) => {
              console.log(err);
            }
          })
          this.cdr.detectChanges();
        })
      }
      if(this.authService.getRole() === Roles.Curator) {
        this.route.params.subscribe(params => {
          this.groupId = params['groupId'];
          this.themeId = params['themeId'];

          const groups = [this.groupId];

          this.curatorService.getStudentsByGroupIds(groups).subscribe(res => {
            this.studentsList = res.map(item => {
              return {
                name: item.firstname + " " + item.lastname,
                value: item.id || ''
              }
            }).sort((a, b) => (a.name).localeCompare((b.name)));;
            this.cdr.detectChanges();
          });

          this.userFulLinks = this.data?.usefulLinks || [];

          if(!this.data?.common) {
            this.baseForm.setControl('usersIds', new FormControl([], ));
            this.baseForm.patchValue({'usersIds': this.data?.usersIds})

          }

          this.FoldersService.getFoldersCurator(this.groupId).subscribe({
            next: (res) => {
              this.foldersOptions = res[0].courseFolders.map(item => {
                return {
                  name: item.courseFolderName,
                  value: item.courseFolderId
                }
              });
              this.cdr.detectChanges();
              this.baseForm.patchValue({ courseFolderId: this.data?.courseFolderId || []})

              this.cdr.detectChanges();
            },
            error: (err) => {
              console.log(err);
            }
          })
          this.cdr.detectChanges();
          console.log(this.baseForm);
          this.cdr.detectChanges();
        });
      }
      if(this.authService.getRole() === Roles.HeadTeacher) {
        this.route.params.subscribe(params => {
          const groupId = params['groupId'];
          const courseId = params['id'];
          this.FoldersService.getFoldersHeadTeacher(courseId, groupId).subscribe({
            next: (res) => {

              this.foldersOptions = res[0].courseFolders.map(item => {
                return {
                  name: item.courseFolderName,
                  value: item.courseFolderId
                }
              })
              this.baseForm.patchValue({ courseFolderId: this.data?.courseFolderId || []})

              this.cdr.detectChanges();
            },
            error: (err) => {
              console.log(err);
            }
          })
          this.cdr.detectChanges();
        });

        if(!this.data?.common) {
          this.baseForm.setControl('usersIds', new FormControl([], []));
          this.baseForm.patchValue({'usersIds': this.data?.usersIds});
        }
      }


      this.baseForm.patchValue({ description: this.data?.description});
      this.baseForm.patchValue({ videoUrl: this.data?.videoUrl });
      this.baseForm.patchValue({ rating: this.data?.rating});
      this.baseForm.patchValue({ miniTestMaxScore: this.data?.miniTestMaxScore});
      this.baseForm.patchValue({ usefulLinks: this.data?.content });
      this.baseForm.patchValue({ mustHaveMaterials: this.data?.mustHaveMaterials})
      this.baseForm.patchValue({ additionalMaterials: this.data?.additionalMaterials});

      this.userFulLinks = this.data?.usefulLinks || [];

      this.cdr.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  public downloadedMaterial($event: FileList): void {
    this.baseForm.patchValue({mustHaveMaterials: $event})
  }

  public userFulLinksChanged(list: UsefullLink[]): void {
    this.userFulLinks = list;
    const isUserFulLinksFull: boolean = list.every(item => item.name && item.link);
    if(isUserFulLinksFull) {
      this.allowSaveEvent.emit(true);
    }
    else {
      this.allowSaveEvent.emit(false);
    }
  }

  public save(): void {
    const baseForm = this.baseForm.value;
    console.log(this.baseForm)
    const data: LectureDTO = {
      id: this.data!.id,
      lessonType: this.data!.lessonType,
      name: this.name,
      description: baseForm.description,
      rating: baseForm.rating,
      content: baseForm.content,
      videoUrl: baseForm.videoUrl,
      miniTestMaxScore: baseForm.miniTestMaxScore,
      mustHaveMaterials: baseForm.mustHaveMaterials,
      additionalMaterials: baseForm.additionalMaterials,
      usefulLinks: this.userFulLinks,
      usersIds: baseForm.usersIds,
      courseFolderId: baseForm.courseFolderId
    }
    this.lessonsService.updateLectureById(this.groupId, this.themeId, this.data!.id, data).subscribe(res => {
      this.savedEvent.emit(res);
      this.unsavedChanges = false;
      this.cdr.detectChanges()
    });
  }

  public downloadedMaterialFile(id: string): void {
    const currentMustHaveMaterials = this.baseForm.get('mustHaveMaterials')?.value || [];
    const newMustHaveMaterials = [...currentMustHaveMaterials, id];

    this.baseForm.patchValue({ mustHaveMaterials: newMustHaveMaterials });
    this.baseFormErrors.mustHaveMaterials = "";
  }

  public downloadedAdditionalFile(id: string): void {
    const currentAdditionalMaterials = this.baseForm.get('additionalMaterials')?.value || [];
    const additionalMaterials = [...currentAdditionalMaterials, id];

    this.baseForm.patchValue({ additionalMaterials: additionalMaterials });
    this.baseFormErrors.additionalMaterials = "";
  };

  public removeMaterialFile(id: string): void {
    const currentMustHaveMaterials = this.baseForm.get('mustHaveMaterials')?.value || [];
    const updatedMustHaveMaterials = currentMustHaveMaterials.filter((fileId: string) => fileId !== id);

    this.baseForm.patchValue({ mustHaveMaterials: updatedMustHaveMaterials });
  }

  public removeAdditionalFile(id: string): void {
    const currentAdditionalMaterials = this.baseForm.get('additionalMaterials')?.value || [];
    const updatedAdditionalMaterials = currentAdditionalMaterials.filter((fileId: string) => fileId !== id);

    this.baseForm.patchValue({ additionalMaterials: updatedAdditionalMaterials });
  }

  handleFormControlErrors(form: FormGroup, error: any, controlName: string): void {
    getFormControlErrors(form, error, controlName, this.translateService);
    this.cdr.detectChanges();
  };

  public studentsSelected($event: any[]): void {
    this.baseForm.patchValue({usersIds: $event});
  }
}
