export enum LessonType {
    Lecture = "LECTURE",
    Homework = "HOMEWORK",
    Quiz = "QUIZ",
    WorkOnMistakes = "WORK_ON_MISTAKES",
    Text = "TEXT"
    // Test = "TEST"
}

export enum LessonTypeRU {
    Lecture = "Лекция",
    Homework = "Домашнее задание",
    Quiz = "Тест",
    WorkOnMistakes = "Работа над ошибками",
    Text = "Текстовый урок"
    // Quiz = 'Опрос'
}

export enum LessonTypeKZ {
    Lecture = "Лекция",
    Homework = "Үй жұмысы",
    Quiz = "Тест",
    WorkOnMistakes = "Қатемен жұмыс",
    Text = "Мәтіндік материал"
}

export enum DiffucltLevel {
    A = "A",
    B = "B",
    C = "C"
}

export enum TestType {
    Test = "TEST",
    Open = "OPEN",
    DragAndDrop = "DRAG_AND_DROP",
    Fill = "FILL",
    Matching = "MATCHING",
}

export enum TestTypeKz {
    Test = "Дұрыс жауапты таңдаңыз (жауаптар)",
    Open = "Ашық жауап",
    DragAndDrop = "Жетіспейтін сөздерді жылжытыңыз",
    Fill = "Жетіспейтін сөздерді енгізу",
    Matching = "Жылжыту",
}

export enum TestTypeRu {
    Test = "Выбери правильный ответ (ответы)",
    Open = "Открытый ответ",
    DragAndDrop = "Перетащите пропущенные слова",
    Fill = "Вставка пропущенных слов",
    Matching = "Перетаскивание",
}

export enum CuratorTasksType {
  CheckedTasks = 'checkedTasks',
  NotCheckedTasks = 'notCheckedTasks'
}

export enum StudentTasksType {
  Assigned = 'ASSIGNED',
  Expired = 'EXPIRED',
  OnChecking = 'ON_CHECKING',
  Done = 'DONE'
}

export enum StudentDeadlinesEnum {
  DoneThisWeek = 'DONE_THIS_WEEK',
  DoneNextWeek = 'DONE_NEXT_WEEK',
  DoneLater = 'DONE_LATER',
  ExpiredThisWeek = 'EXPIRED_THIS_WEEK',
  ExpiredNextWeek = 'EXPIRED_NEXT_WEEK',
  ExpiredLater = 'EXPIRED_LATER',
  AssignedThisWeek = 'ASSIGNED_THIS_WEEK',
  AssignedNextWeek = 'ASSIGNED_NEXT_WEEK',
  AssignedLater = 'ASSIGNED_LATER',
  InReviewLater = 'IN_REVIEW_LATER',
  InReviewNextWeek = 'IN_REVIEW_NEXT_WEEK',
  InReviewThisWeek = 'IN_REVIEW_THIS_WEEK'
}
