import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, OnDestroy } from '@angular/core';
import { BaseRoutes, LessonType, ProgressDTO, StudentGroupDTO, StudentGroupThemeDTO, StudentRoutes, StudentThemeDTO } from 'src/app/core/model';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { selectCurrentGroupWithDetails } from 'src/app/core/state/student/groups/groups.selector';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-courses-calendar',
  templateUrl: './courses-calendar.component.html',
  styleUrls: ['./courses-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoursesCalendarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() themes: StudentThemeDTO[] | undefined;
  @Input() progress: ProgressDTO[] | undefined;

  public groupId: string | undefined;
  private currentGroupWithDetails$: Observable<StudentGroupThemeDTO | null | undefined> = this.store.select(selectCurrentGroupWithDetails);
  private currentGroupWithDetailsSubscription!: Subscription;

  constructor(
    private helperService: HelperService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private store: Store
  ){}

  ngOnInit(): void {
    this.currentGroupWithDetailsSubscription = this.currentGroupWithDetails$.subscribe(res => {
      this.groupId = res?.groupId;
      console.log(this.groupId);

      this.cdr.detectChanges();
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if(changes['progress'] && this.themes && this.progress) {
    //   this.themes = this.helperService.linkProgressToThemes(this.themes, this.progress);
    // }
  };

  ngOnDestroy(): void {
    this.currentGroupWithDetailsSubscription.unsubscribe();
  }

  public openLesson(item: StudentThemeDTO): void {
    if(!this.groupId) {
      console.log('groupId is undefined');
      return;
    }

    if(item.lessons) {
      if(item.lessons[0].lessonType === LessonType.Quiz) {
        this.router.navigate([`/${BaseRoutes.Student}/${StudentRoutes.Test}/${StudentRoutes.TestStart}/${this.groupId}/${item.themeId}/${item.lessons[0].lessonId}`])
      }
      if(item.lessons[0].lessonType === LessonType.Lecture) {
        this.router.navigate([`/${BaseRoutes.Student}/${StudentRoutes.Education}/${StudentRoutes.Theory}/${this.groupId}/${item.lessons[0].lessonId}`])
      }
      if(item.lessons[0].lessonType === LessonType.Homework) {
        this.router.navigate([`/${BaseRoutes.Student}/${StudentRoutes.Education}/${StudentRoutes.Homework}/${this.groupId}/${item.lessons[0].lessonId}`])
      }
      if(item.lessons[0].lessonType === LessonType.WorkOnMistakes) {
        this.router.navigate([`/${BaseRoutes.Student}/${StudentRoutes.Education}/${StudentRoutes.WorkOnMistakes}/${this.groupId}/${item.lessons[0].lessonId}`])
      }
    }
  }
}
