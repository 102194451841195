  <textarea
    [placeholder]="'enterText' | translate"
    class="app-type-words"
    [ngClass]="{'answer-correct': (_QuizState.View === quizState)  && (question.correctAnswerTexts | typeWordsQuestion: userAnswer) ,
                'answer-incorrect':  (_QuizState.View === quizState) && !(question.correctAnswerTexts | typeWordsQuestion: userAnswer) }"
    [(ngModel)]="userAnswer"
    [readOnly]="_QuizState.View === quizState || readOnly"
    #textarea></textarea>

  <app-result
    [explanation]="question.explanation"
    [referenceUrl]="question.referenceUrl"
    [explanationImageUrl]="question.explanationImageUrl"
    *ngIf="question.correctAnswerTexts && question.correctAnswerTexts.length > 0 &&   (_QuizState.View === quizState) && (question.correctAnswerTexts[0] !== userAnswer)"
    [score]="question.score"
    [title]="'correctAnswer' | translate">
    <span>{{question.correctAnswerTexts[0]}}</span>
  </app-result>
