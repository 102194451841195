<div class="responsibles-card">
  <app-expander [isExpanded]="isOpen">
    <button class="button" (click)="toggle()" button>
      <div class="responsibles">
<!--        <img *ngIf="curatorImg" class="photo" [src]="curatorImg" alt="" />-->
        <div *ngIf="curatorImg" class="photo" [style.background-image]="'url(' + curatorImg + ')'"></div>
        <div *ngIf="!curatorImg" class="photo-no-image">{{curatorFirstLetter}}</div>
<!--        <img *ngIf="teacherImg" class="photo photo-next" [src]="teacherImg"  alt="" />-->
        <div *ngIf="teacherImg" class="photo" [style.background-image]="'url(' + teacherImg + ')'"></div>
        <div *ngIf="!teacherImg" class="photo-no-image photo-next">{{teacherFirstLetter}}</div>
      </div>
      <span class="professions">{{(teacherFirstLetter && curatorFirstLetter) ? ('curatorAndTeacher' | translate) : ('TEACHER' | translate)}}</span>
      <app-primary-arrow class="arrow" [isOpen]="isOpen" openColor="#6A7186" [initialSlope]="90"
                         [slopeDegree]="90"></app-primary-arrow>
    </button>
    <div class="content" content></div>
  </app-expander>
</div>
