<form class="content" [formGroup]="baseForm">
  <app-select
        name="CHOOSE_FOLDER_FOR_TYPE_LESSON"
        placeholder="CHOOSE_FROM_LIST"
        formControlName="courseFolderId"
        [options]="foldersOptions">
    </app-select>

  <app-multi-select
    *ngIf="!data?.common"
    name="STUDENTS_ASSIGNED"
    [options]="studentsList"
    [withSearch]="true"
    [preSelectedOptions]="baseForm.get('usersIds')?.value"
    (selectedValue)="studentsSelected($event)"/>
  <div class="editor">
    <app-editor
      [error]="baseFormErrors.description"
      name="DESCRIPTION"
      formControlName="description">
    </app-editor>
  </div>
  <div class="material">
    <h1 class="material-title">{{'materials' | translate}}</h1>
    <div  class="material-uploader">
      <app-file-uploader
        [error]="baseFormErrors.materials"
        (downloadedEvent)="downloadedMaterialFile($event)"
        (removeEvent)="removeMaterialFile($event)"
        [idList]="baseForm.get('materials')?.value || ''">
      </app-file-uploader>
    </div>
  </div>
</form>
