<div class="notification-container">
  <div *ngIf="notification;else noContent"
       [class.unread_card]="!isRead"
       class="notification-card">
    <div *ngIf="!notification.creator">
      <img class="notification-card-avatar"
           [src]="notification.imagePath"
           [alt]="notification.fullName">
    </div>
    <div *ngIf="notification.creator">
      <img *ngIf="notification.creator.profilePhotoUrl" class="notification-card-avatar"
           [src]="picture"
           [alt]="notification.creator.firstname + ' ' + notification.creator.lastname">
      <div *ngIf="!notification.creator.profilePhotoUrl" class="notification-card-avatar notification-card-avatar-letter">
        {{notification.creator.firstname[0]}}</div>
    </div>
    <div class="w-100">
      <div class="flex justify-between items-center">
        <p class="notification-card-fullName">
          {{notification.fullName}}
          <span>{{(notification.roleName || 'SYSTEM') | translate}}</span>
        </p>
        <button (click)="readAnnouncement()"
                class="notification-card-is-read-btn"
                [class.blue-btn]="!isRead">
          <div class="btn-tooltip">
            {{(isRead ? 'MARK_AS_UNREAD' : 'MARK_AS_READ') | translate}}
          </div>
        </button>
      </div>

      <h1 class="notification-card-header">{{notification.header}}</h1>

      <div class="notification-card-content" [ngClass]="{'show-more': !isTextFullyVisible}">
        <div [innerHtml]="visibleText" class="message-content"></div>
        <!--        {{ visibleText }}-->
        <button *ngIf="notification && notification.content && notification.content.length > 113"
                (click)="toggleTextVisibility()"
                class="show-more-btn">
          {{ isTextFullyVisible ? ('hide' | translate) : ('SHOW_MORE' | translate) }}
        </button>
      </div>
      <button
        *ngIf="notification.additionalInformation &&
        ((notification.additionalInformation.event && notification.additionalInformation.event.redirectUrl)
        || (notification.additionalInformation.groupId && notification.additionalInformation.courseId && notification.additionalInformation.lessonId && notification.additionalInformation.lessonType))"
        (click)="openLink(notification.additionalInformation, notification.type)"
        class="notification-card-go-to-btn">
        {{'goTo' | translate}}
      </button>
      <p class="notification-card-date">{{notification.date | date : "dd.MM.yyyy, HH:mm"}}</p>
<!--      <a class="notification-card-read-btn" *ngIf="!notification.isRead"-->
<!--         (click)="readAnnouncement()">{{'read' | translate}}</a>-->
    </div>
  </div>
  <ng-template #noContent>{{'strangeSituation' | translate}}</ng-template>
</div>
