import {Injectable} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {StorageService} from '../../services/storage/storage.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {UsersService} from '../../services/users/users.service';
import {RouteHelperServiceService} from 'src/app/shared/services/routeHelperService/route-helper-service.service';
import { CommonRoutes } from '../../model';
import { Location } from '@angular/common';
import { AuthService } from './../../services/auth/auth.service';
import { MessagingService } from "../../../shared/services/firebase-messaging/messaging.service";


@Injectable({
  providedIn: 'root'
})
export class AuthorizedGuard implements CanActivate {
  constructor(
    private storageService: StorageService,
    private jwtHelper: JwtHelperService,
    private router: Router,
    private usersService: UsersService,
    private routeHelper: RouteHelperServiceService,
    private location: Location,
    private authService: AuthService,
    private messagingService: MessagingService
  ) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isAuthorizationPage = this.location.path().includes(CommonRoutes.Authorization) || !this.location.path();

    if(this.storageService.getToken()
    && !this.jwtHelper.isTokenExpired(this.storageService.getToken())
    && isAuthorizationPage) {
      this.messagingService.requestPermission();
      this.router.navigate([`${this.routeHelper.getRouteBaseOnRole(this.authService.getRole())}`])
    }

    return true;
  }

}
