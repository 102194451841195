<h1 class="title">Добавить опросник</h1>
<div class="iconic-line line"></div>
<form [formGroup]="form" class="form">
  <app-input name="Название опроса" placeholder="Введите название" formControlName="name"></app-input>
  <app-select name="Тип опросника" [options]="optionsQuestionnaires" formControlName="type"></app-select>
</form>
<h1 class="question-title">Вопросы</h1>
<ul class="list">
  <li class="item">
    <form class="question-form" [formGroup]="formList">
      <app-input name="1-Вопрос" placeholder="Введите название" formControlName="name"></app-input>
      <app-select name="Тип ответа" [options]="optionsQuestion" formControlName="type"></app-select>
    </form>
  </li>
</ul>
<button class="iconic-button secondary-button button">
  <img src="assets/svg/plus-default-gray.svg" alt="" />
  <span>Добавить вопрос</span>
</button>
<div class="buttons">
  <button class="iconic-button secondary-button button">Отменить</button>
  <button class="iconic-button primary-button button">Сохранить</button>
</div>


