import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from 'src/environment';

@Component({
  selector: 'app-responsible-dialog',
  templateUrl: './responsible-dialog.component.html',
  styleUrls: ['./responsible-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResponsibleDialogComponent {
  @Input() isOpen: boolean = false;
  @Input() curatorFullName: string | null | undefined = null;
  @Input() curatorUsername: string | null  | undefined = null;
  @Input() curatorProfileUrl: string | null  | undefined = null;
  @Input() curatorPhoneNumber: string | null  | undefined = null;
  @Input() teacherFullName: string | null  | undefined = null;
  @Input() teacherProfileUrl: string | null  | undefined = null;
  @Input() teacherDesc: string | null | undefined  = null;
  @Output() closeEvent = new EventEmitter<void>();

  public readonly environment = environment;

  public title: string = "";

  public closeDialog(): void {
    this.closeEvent.emit();
  };
}
