<button (click)="goToCourse(data.id, $event)"
        class="app-course-card"
        [ngClass]="{ not_active: !data.active}">
  <div class="block">
    <div class="block-data">
      <h1 class="title">{{data.name}}</h1>
      <span class="creator">{{data.teacherFullName}} &nbsp;</span>
      <span class="creator">{{(data.month | monthConverter: currentLang: true) + "'" + (data.year % 100) }}</span>
    </div>
    <button class="button menu" mat-button [matMenuTriggerFor]="menu">
      <img src="assets/svg/triple-dot.svg" alt="" />
    </button>
    <mat-menu #menu="matMenu">
      <app-menu-default
          (editClick)="editClick.emit(data.id)"
          (duplicateClick)="duplicateClick.emit(data)"
          (deleteClick)="deleteClick.emit(data.id)"
          ></app-menu-default>
    </mat-menu>
  </div>
  <div class="iconic-line line"></div>
    <form [formGroup]="form" class="block toggle">
        <app-slide-toggle formControlName="active"
                          [disabled]="disabledToggler"
                          [toggleValue]="data.active"
                          [tooltipImg]="true"
                          (toggleValueChange)="changedActive($event)"
                          [text]="data.active ? 'ACTIVE' : 'INACTIVE'">
        </app-slide-toggle>
    </form>
<!-- <button class="app-course-card w-100">
  <div class="block">
    <h1 class="title">{{ data.name }}</h1>
    <button class="button" mat-button [matMenuTriggerFor]="menu">
      <img src="assets/svg/triple-dot.svg" alt="" />
    </button>
    <mat-menu #menu="matMenu">
      <app-menu-default
        (editClick)="editClick.emit(data.id)"
        (duplicateClick)="duplicateClick.emit(data)"
        (deleteClick)="deleteClick.emit(data.id)"
      ></app-menu-default>
    </mat-menu>
  </div>
  <span class="creator">{{ data?.teacherId }}</span>
  <div class="iconic-line line"></div>
  <div class="block"> -->
    <!-- <span class="date" *ngIf="data.month">{{
      dateHelperServiceService.getMonthName(data.month).name
    }}</span>
    <span *ngIf="!data.month"></span>
    <app-slide-toggle
      *ngIf="data.active"
      formControlName="active"
      [disabled]="disabledToggler"
      [toggleValue]="data.active"
      (toggleValueChange)="changedActive($event)"
      [text]="data.active ? 'Активный' : 'Неактивный'"
    ></app-slide-toggle> -->
  <!-- </div> -->
</button>
