<div>
  <button *ngIf="canSee" mat-menu-item (click)="onSeeClick()">
    <img src="assets/svg/eye.svg" alt=""/>
    <span>{{'SEE' | translate}}</span>
  </button>
  <button *ngIf="canEdit" mat-menu-item (click)="onEditClick()">
    <img src="assets/svg/edit.svg" alt=""/>
    <span>{{'edit' | translate}}</span>
  </button>
  <button *ngIf="canDuplicate" mat-menu-item (click)="onDuplicateClick()">
    <img src="assets/svg/duplicate.svg" alt=""/>
    <span>{{'duplicate' | translate}}</span>
  </button>
  <button *ngIf="canDelete" class="button-delete" mat-menu-item (click)="onDeleteClick()">
    <img src="assets/svg/trash.svg" alt=""/>
    <span>{{'delete' | translate}}</span>
  </button>
</div>
