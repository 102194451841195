export interface SystemAlerts {
  id: string;
  message: string;
  messageType: AlertMessageType;
}

export enum AlertMessageType {
  info = 'INFO',
  blocker = 'BLOCKER'
}
