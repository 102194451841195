import { Component, Input, OnInit } from '@angular/core';
import { AdditionalMaterials } from 'src/app/core/model';
import { FileExtension } from 'src/app/shared/models';

@Component({
  selector: 'app-additional-mat-usefull-links-list',
  templateUrl: './additional-mat-usefull-links-list.component.html',
  styleUrls: ['./additional-mat-usefull-links-list.component.scss']
})
export class AdditionalMatUsefullLinksListComponent {

  @Input() additionalMaterials:  AdditionalMaterials | undefined;

  public fileExtension = FileExtension;
  @Input() filesType:FileExtension[] = [this.fileExtension.WORD, this.fileExtension.PDF, this.fileExtension.EXCEL];

  public isAdditionalOpen: boolean = false;


}
