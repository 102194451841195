import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Languages } from 'src/app/shared/models';

@Component({
  selector: 'app-aggrement-viewer',
  templateUrl: './aggrement-viewer.component.html',
  styleUrls: ['./aggrement-viewer.component.scss']
})
export class AggrementViewerComponent implements OnInit {
  public readonly Language = Languages;

  @Input() isOpen: boolean = false;
  @Input() isSignMode: boolean = true;
  @Output() confirmEvent = new EventEmitter<void>();
  @Output() closeEvent = new EventEmitter<void>();
  
  public isChecked: boolean = false;
  public currLang: Languages = Languages.KZ;
  constructor(
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.currLang = this.translateService.getDefaultLang() as Languages;
    this.translateService.onLangChange.subscribe((res) => {
      this.currLang = res.lang as Languages;
      this.cdr.detectChanges();
    });

    const linkElement = document.getElementsByClassName('my-link')[0];
    console.log(linkElement);
    if (linkElement) {
      linkElement.addEventListener('click', this.open);
    }
  }

  public open(): void {
    window.open(window.location.origin + '/assets/для_платформы_договор_JUZ40_ONLINE_Информационных_услуг_онлайн (1).pdf')
  }
}
