<div class="lang-select">
  <div #btnSelect>
    <app-button (onButtonClick)="toggleMenu()"
                [fullWidth]="true"
                [color]="btnColor"
                class="btn"
                [text]="lang === languagesEnum.KZ ? 'KZ' : 'RU'"
                [rightIcon]="'./assets/svg/arraw-default-black.svg'"
    ></app-button>
  </div>
  <div *ngIf="isOpen"
       [ngStyle]="{top: (btnSelect?.offsetHeight ? btnSelect.offsetHeight + 1 : 33) + 'px'}"
       class="lang-select-items flex flex-col">
    <button class="lang-select-items_item" (click)="setCurrentLang(languagesEnum.KZ)">
      <span>KZ</span>
    </button>
    <button class="lang-select-items_item" (click)="setCurrentLang(languagesEnum.RU)">
      <span>RU</span>
    </button>
  </div>
</div>
