import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileFormat',
  pure: true
})
export class FileFormatPipe implements PipeTransform {
  transform(id: string, converted: boolean = false): { fileName: string, icon: string } {
    let fileName: string = "";
    if (id) {
      const lastUnderscoreIndex: number = id.lastIndexOf('_');
      if (lastUnderscoreIndex !== -1) {
        fileName = id.slice(lastUnderscoreIndex + 1);
      } else {
        // Handle case where no underscore is found
      }
    }
    

    const icon = this.getIcon(fileName, converted);
    return { fileName , icon };
  }

  private getIcon(fileName: string | undefined, converted: boolean = false): string {
    if(converted) {
      return 'pdf.svg'
    }
    const lowerCaseFormat = fileName?.split('.')[fileName.split('.').length - 1];
    switch (lowerCaseFormat) {
      case 'pdf':
        return 'pdf.svg';
      case 'xls':
      case 'xlsx':
        return 'excel.svg';
      case 'doc':
      case 'docx':
        return 'word.svg';
      default:
        return 'image.png'
    }
  }
}
