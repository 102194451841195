import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { LoginRegisterComponent } from './login-register/login-register.component';
import { MainComponent } from './main/main.component';
import { LayoutsModule } from '../../layouts/layouts.module';
import { BaseModule } from '../base/base.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NonBaseModule } from '../non-base/non-base.module';
import { TechnicalWorksPageComponent } from './technical-works-page/technical-works-page.component';
import { TranslateModule } from "@ngx-translate/core";



@NgModule({
  declarations: [
    LoginRegisterComponent,
    MainComponent,
    TechnicalWorksPageComponent
  ],
    imports: [
        CommonModule,
        LayoutsModule,
        RouterModule,
        BaseModule,
        ReactiveFormsModule,
        NonBaseModule,
        TranslateModule
    ],
  exports: [
    LoginRegisterComponent,
    MainComponent
  ]
})
export class PagesModule { }
