<div class="backdrop" *ngIf="isShow && backdropClick" (click)="closeMenu()"></div>
<div [ngStyle]="{width: biggerWidth ? '494px' : '394px'}" class="right-menu border p-24"
     [ngClass]="{'right-menu_show': isShow, 'mobile-adaptive': mobileAdaptive}">
  <button
    class="close-btn flex items-center justify-center"
    (click)="closeMenu()"
  >
    <img src="./assets/svg/plus.svg" alt="" />
  </button>

  <ng-content></ng-content>
</div>
