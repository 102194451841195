import { Roles } from "src/app/core/model";

export enum EventAnnounce {
    EVENT = "Event",
    Announce = "ANNOUNCE"
}

export interface TabEventAnnounceT {
    name: string,
    active: boolean,
    role: Roles | null
}