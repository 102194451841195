import { SideBarService } from './../../services/side-bar.service';
import { SubjectsService } from './../../../../core/services/subjects/subjects.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BaseRoutes, MethodistRoutes } from 'src/app/core/model';
import { SelectValue } from 'src/app/shared/models';

@Component({
  selector: 'app-methodist-sidebar',
  templateUrl: './methodist-sidebar.component.html',
  styleUrls: ['./methodist-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MethodistSidebarComponent implements OnInit{
  public subjects: SelectValue[] = [];
  public activeSubject: SelectValue | undefined;

  constructor(
    private subjectsService: SubjectsService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private sideBarService: SideBarService,
    ) {}

  ngOnInit(): void {
    this.subjectsService.getMethodistSubjects().subscribe(res => {
      this.subjects = res;
      //get ID
      const id = this.route.snapshot?.params?.['id'];
      const currentUrl = this.router.url;

      //set ID
      this.sideBarService.setId(this.subjects.find(item => item.value === id) || this.subjects[0]);

      //push to Route
      if(!id && !currentUrl.includes(`${BaseRoutes.Methodist}/${MethodistRoutes.Course}`)) {
        this.router.navigate([`${BaseRoutes.Methodist}/${MethodistRoutes.Main}/${res[0].value}`]);
      }

      //get Id Using Service
      this.sideBarService.getIdSubject().subscribe(res => {
        this.activeSubject = res;
      });

      this.cdr.detectChanges();
    });
  };

  public setActiveSubject(id: string): void {
    this.sideBarService.setId(this.subjects.find(item => item.value === id) || this.subjects[0]);
    this.sideBarService.getIdSubject().subscribe(res => {
      this.activeSubject = res;
      this.router.navigate([`${BaseRoutes.Methodist}/${MethodistRoutes.Main}/${this.activeSubject.value}`]).then();
    })
  }
}
