<div class="header flex items-center">
  <div class="avatar">
    <img [src]="getImageUrl()" alt="" />
  </div>
  <div class="description flex flex-col">
    <div class="name">{{ user?.firstname }} {{ user?.lastname }}</div>
    <div class="group-email flex items-center">
      <div class="group">{{ user?.group }}</div>
      <div class="circle"></div>
      <div class="email">{{ user?.username ?? "-" }}</div>
    </div>
  </div>
</div>
