import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import {
  NoopValueAccessorDirectiveDirective,
  injectNgControl,
} from 'src/app/shared/directives/noop-value-accessor-directive/noop-value-accessor-directive.directive';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [NoopValueAccessorDirectiveDirective],
})
export class TextareaComponent {
  ngControl = injectNgControl();

  @Input() name: string = '';
  @Input() placeholder: string = '';
  @Input() isActive: boolean = true;
  @Input() readOnly: boolean = false;
  @Input() error: string = '';
  @Input() control: AbstractControl | undefined = undefined;

  public focused: boolean = false;

  onFocus(): void {
    this.focused = true;
  }
  onBlur(): void {
    this.focused = false;
  }
}
