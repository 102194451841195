import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StudentThemeDTO } from 'src/app/core/model';
import { LessonStatus } from 'src/app/shared/models';

@Component({
  selector: 'app-item-calender',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemComponent {
  public courseStatus = LessonStatus;
  @Input() isCommented: boolean = false;
  @Input() data: StudentThemeDTO | undefined;
  @Input() status: LessonStatus | undefined;
  @Input() index: number = 0;

  constructor(private datePipe: DatePipe) {}
  
  getStatusIcon(status: LessonStatus | undefined): string {
    let iconFileName = '';
  
    if (status === LessonStatus.Success) {
      iconFileName = 'checkmark-small.svg';
    } else if (status === LessonStatus.Created) {
      iconFileName = 'dotted-circle.svg';
    } else if (!status) {
      iconFileName = 'locked.svg';
    }
  
    return `assets/svg/circles/${iconFileName}`;
  }
  
  getStatusText(status: LessonStatus | undefined): string {
    if(status === LessonStatus.Success) {
      return 'Выполнено';
    }
    if(status === LessonStatus.Created) {
      return 'В процессе';
    }
    return this.datePipe.transform(new Date(this.data?.themeStartTime || new Date()), 'dd.MM.yy') ?? '';
  }
}
