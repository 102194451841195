import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BorderType } from "../../../models/enums/form-field.enum";

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldComponent implements OnInit {
  @Input() name: string = '';
  @Input() error: string = '';
  @Input() focused: boolean = false;
  @Input() isActive: boolean = true;
  @Input() control: AbstractControl | undefined;
  @Input() borderType: BorderType = BorderType.Outline;
  @Input() isLoading? = false;

  public required = false;
  public borderTypeEnum = BorderType;

  ngOnInit(): void {
    this.checkForRequirung();
  }

  private checkForRequirung(): void {
    const validator = this.control?.validator;
    if (!validator) return;
    if (validator({} as AbstractControl)?.['required']) {
      this.required = true;
    }
  }
}
