import { DistributedGroupsOfStreamComponent } from './components/distributed-groups-of-stream/distributed-groups-of-stream.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StreamCardComponent } from './components/stream-card/stream-card.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { ProlongateDateComponent } from './components/prolongate-date/prolongate-date.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CuratorInfoComponent } from './components/curator-info/curator-info.component';
import { ComponentsModule } from 'src/app/shared/components/components.module';
import { TranslateModule } from "@ngx-translate/core";
import { AnalyticsCardComponent } from './components/analytics-card/analytics-card.component';
import { VideoCardComponent } from './components/video-card/video-card.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { QuestionnaireCardComponent } from './components/questionnaire-card/questionnaire-card.component';
import { QuestionnaireCreaterControllerComponent } from './components/questionnaire-creater-controller/questionnaire-creater-controller.component';
@NgModule({
  declarations: [
    StreamCardComponent,
    ProlongateDateComponent,
    CuratorInfoComponent,
    DistributedGroupsOfStreamComponent,
    AnalyticsCardComponent,
    VideoCardComponent,
    QuestionnaireCardComponent,
    QuestionnaireCreaterControllerComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatSlideToggleModule,
    RouterModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    ComponentsModule,
    TranslateModule,
    MatProgressBarModule,
  ],
  exports: [
    StreamCardComponent,
    ProlongateDateComponent,
    CuratorInfoComponent,
    DistributedGroupsOfStreamComponent,
    AnalyticsCardComponent,
    VideoCardComponent,
    QuestionnaireCardComponent,
    QuestionnaireCreaterControllerComponent
  ],
})
export class HeadTeacherModule {}
