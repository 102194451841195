import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { FilesService } from './../../../../core/services/files/files.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AdditionalMaterials } from 'src/app/core/model';
import { Subscription } from 'rxjs';
import { environment } from 'src/environment';

interface Materials {
  id: string,
  link: string,
};

@Component({
  selector: 'app-additional-materials-dialog',
  templateUrl: './additional-materials-dialog.component.html',
  styleUrls: ['./additional-materials-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdditionalMaterialsDialogComponent implements OnChanges {
  @Input() isOpen: boolean = false;
  @Input() data: AdditionalMaterials | undefined;
  @Output() closeEvent = new EventEmitter<void>();

  public filesSubscription: Subscription | undefined;

  public materials: Materials[] = [];
  constructor(
    private filesService: FilesService,
    private helperService: HelperService,
    private cdr: ChangeDetectorRef)
  {}

  ngOnChanges(changes: SimpleChanges): void {
    this.materials = [];
    if(this.data && this.data.additionalMaterials && this.data.additionalMaterials.length > 0) {
      for(let id of this.data.additionalMaterials || []) {
        this.materials.push({
          id: id,
          link: id,
        });
      }
      this.cdr.detectChanges();
    };
  };

  public closeDialog(): void {
    this.closeEvent.emit();
  };

  public openFile(link: string): void {
    window.open(link, '_blank')
  }
}
