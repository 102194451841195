<div class="app-result">
    <div class="info">
        <h1 class="title">{{title}}</h1>
        <span class="add">{{('scores' | translate) + score | localizedNumberFormat}}&nbsp;
        <span class="bold">{{infoText}}</span></span>
    </div>
    <div class="answer">
        <ng-content></ng-content>
        <div *ngIf="explanation || referenceUrl" class="iconic-line line"></div>
        <div *ngIf="explanation || referenceUrl" class="block-wrapper">
          <h1 class="answer-title">{{'explanation' | translate}}</h1>
          <div class="explanation-wrapper">
            <app-editor-viewer class="explanation-text" [content]="explanation"></app-editor-viewer>
            <div class="image">
              <app-view-photo [image]="environment.filesUrl + explanationImageUrl"></app-view-photo>
            </div>
              <div *ngIf="referenceUrl && isShowVideo" class="explanation-video">
                <app-video-player [videoUrl]="referenceUrl" [aspectRation]="0.5"></app-video-player>
              </div>
            <img *ngIf="referenceUrl && !isShowVideo" 
              (click)="showVideo()" 
              class="explanation-video" 
              src="assets/img/fake-video.png" alt=""/>
          </div>
        </div>
    </div>
</div>