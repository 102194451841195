<app-dialog
    [title]="data ? 'EDIT_LESSON' : 'ADD_NEW_LESSON'"
    [isOpen]="isOpen" [disabledConfirm]="form.invalid || isLoading"
    (closeEvent)="closeDialog.emit()"
    (confirmEvent)="createTheme()">
    <form class="form" [formGroup]="form">
        <app-input placeholder="TYPE_LESSON_NAME" name="name" formControlName="name"/>
        <app-date-picker *ngIf="isOpen"
                         name="Дата открытия урока"
                         formControlName="date"></app-date-picker>
        <app-select-input
            placeholder="TYPE_LESSON_BEGIN_TIME"
            name="TIME"
            formControlName="time"
            mask="00:00"
            [options]="timeOptions"
            (input)="handleTimeInput($event)">
        </app-select-input>
    </form>
</app-dialog>
