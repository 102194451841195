import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { IDiscountOfUser, IDiscountsOfUser } from 'src/app/core/model/interface/discounts';
import { DiscountsService } from './../../../../core/services/discounts/discounts.service';

@Component({
  selector: 'app-subjects-with-discounts[username]',
  templateUrl: './subjects-with-discounts.component.html',
  styleUrls: ['./subjects-with-discounts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubjectsWithDiscountsComponent implements OnChanges {
  @Input() username!: string;
  @Input() hideHeader: boolean = false;
  @Input() hideRemoveButton: boolean = false;

  @Output() changedData: EventEmitter<IDiscountOfUser[]> = new EventEmitter<IDiscountOfUser[]>();

  public isCreate: boolean = false;

  public data: IDiscountsOfUser | undefined;

  public isOpen: boolean = false;
  public picked: IDiscountOfUser | undefined;

  public isRemoveOpen: boolean = false;
  constructor(
    private discountsService: DiscountsService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnChanges() {
    if (this.username) {
      this.discountsService.getDiscountStudentDetail(this.username).subscribe({
        next: (res) => {
          console.log(res);
          this.data = res;
          this.cdr.detectChanges();
        },
        error: (err) => {
          console.log(err);
        }
      })
    }
  }

  public open(user: IDiscountOfUser | undefined, isCreate: boolean): void {
    this.picked = user;
    this.isCreate = isCreate;
    this.isOpen = true;
  }
  public close(): void {
    this.picked = undefined;
    this.isOpen = false;
  }

  public confirm(user?: IDiscountOfUser): void {
    if(user && this.data) {
      if(this.isCreate) {
        this.data.discountUserDetails.push(user);
        this.changedData.emit(this.data.discountUserDetails);
        this.close();
        return;
      }
      this.data.discountUserDetails = this.data.discountUserDetails.map(item => {
        if(item.id === user.id) {
          return user;
        }
        else {
          return item;
        }
      });
      this.changedData.emit(this.data.discountUserDetails);
    }
    this.close();

  }

  public openRemover(user: IDiscountOfUser): void {
    this.picked = user;
    this.isRemoveOpen = true;
  }

  public closeRemover(): void {
    this.isRemoveOpen = false;
  }

  public confirmRemover(): void {
    if(this.data) {
      this.data.discountUserDetails = this.data.discountUserDetails.filter(item => item.id !== this.picked!.id)
      this.changedData.emit(this.data.discountUserDetails);
    }
    this.closeRemover();
  }

}
