<div class="accordion" *ngIf="text && label">
  <button
    *ngIf="label"
    class="wrapper w-100 flex justify-between items-center"
    (click)="toggleIsOpen()"
  >
    <div class="label">{{ label }}</div>
    <img class="icon" src="./assets/svg/plus.svg" *ngIf="!isOpen" alt="" />
    <img class="icon" src="./assets/svg/minus.svg" *ngIf="isOpen" alt="" />
  </button>
  <div *ngIf="text" class="description" [ngClass]="{'description--with-video': videoUrl}" [class.description_open]="isOpen">
    <span>{{ text }}</span>
    <div class="video" *ngIf="videoUrl">
      <app-video-player [videoUrl]="videoUrl"></app-video-player>
    </div>
  </div>
</div>
