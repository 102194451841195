import { SelectValue } from "../interfaces";

export enum BaseSubjects {
  ReadingLiteracy = '892f5c1e-ab00-496a-a08e-a877c2a28264',
  MathematicalLiteracy = 'e6d6f884-5f5a-46c0-9b5a-929051b9a3d8',
  QazaqHistory = '2f9a8bf5-4a39-4c5f-aa32-4c7ae09521b2',
  Geometry = 'aefcbf13-8928-40a5-bddb-1b5c7eac2e07',
}

export enum ChoosingSubjects {
  Mathematics = '11c81c50-c914-4030-8083-e5d4bfe6e6d0',
  Physics = '0b14d605-8adb-436d-8953-e2472d4ff048',
  Geography = '3019bfe4-8e9e-4c9a-9059-626d6dff3d48',
  Chemistry = '24beb7d0-dc5f-4e2e-a66c-c44b51af9e67',
  Biology = '3a58ebfe-a668-4761-a5f4-53142a6571c1',
  WorldHistory = '8e0889f0-320c-405a-8d14-44fb9f396ea7',
  LawBasics = '79d6a013-68f6-4829-a75f-f1618fc9c244',
  EnglishLanguage = 'ee08e1f3-3658-44d5-ab8b-206a5049ffc5',
  CreativeExam = 'f6ba7ace-19bd-4a4c-938e-682c37a00b13',
  RussianLanguage = '4e0e069a-0ff8-4664-b01d-c491a69788ee',
  RussianLiterature = '58b3f11e-20cd-453a-a486-afa4cdf261f3',
  KazakhLanguage = 'dc37f366-6f09-41e8-a5d3-3cd925fb72db',
  KazakhLiterature = 'e82b4f1e-6a1f-4b75-b622-6703e2495520',
  Informatics = '6e172165-57c2-4b01-9fd1-70ccca7b96a7',
  Geometry = 'aefcbf13-8928-40a5-bddb-1b5c7eac2e07'
}

export const educationClassesSelectValues: SelectValue[] = [
  { name: '9', value: 9 },
  { name: '10', value: 10 },
  { name: '11', value: 11 },
  { name:  "12", value: 12 },
  { name: 'Колледж', value: 'Колледж' },
  { name: 'Университет', value: 'Университет' }
];

export enum EducationClasses {
  Ten = 10,
  Eleven = 11,
  Twelve = 12,
}

export enum TestFinished {
  ByUser = 'byUser',
  ByTime = 'byTime',
}
