import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { NgxConveyerDirective } from '@egjs/ngx-conveyer';
import { ICatalogue } from 'src/app/core/model/interface/catalogues';
import { CataloguesService } from 'src/app/core/services/catalogues/catalogues.service';
import { FilesService } from 'src/app/core/services/files/files.service';
import { SUBJECTS_ID } from 'src/app/shared/models/subjects';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-course-detail-main',
  templateUrl: './course-detail-main.component.html',
  styleUrls: ['./course-detail-main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseDetailMainComponent implements AfterViewInit {
  @ViewChild('cards') cards: ElementRef | undefined;
  @ViewChildren('singleCard') singleCard: QueryList<ElementRef> | undefined;
  @ViewChild('line') line!: ElementRef;
  @ViewChild("conveyer") conveyer!: NgxConveyerDirective;

  @Input() course!: ICatalogue;
  public subjectsId = SUBJECTS_ID;

  public shows: boolean[] = [];

  constructor(
    private _cataloguesService: CataloguesService,
    private _fs : FilesService,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
   this.countReviewLineWidth();
   document.body.scrollTo(0, 0);
  }

  public prev(): void {
    if(window.innerWidth < 999) {
      this.conveyer.scrollIntoView("prev", {
        align: "center",
        duration: 500,
      });

      return;
    }

    this.conveyer.scrollIntoView("start", {
      align: "center",
      duration: 500,
    });
  }
  public next(): void {
    if(window.innerWidth < 999) {
      this.conveyer.scrollIntoView("next", {
        align: "start",
        duration: 500,
      });
      return;
    }
    this.conveyer.scrollIntoView("end", {
      align: "start",
      duration: 500,
    });
  }

  public onFinishScroll(): void {
    this.countReviewLineWidth();
  }

  public countReviewLineWidth(): void {
    if(this.cards) {
      const cardsElement = this.cards.nativeElement;
      const cardsWidth = cardsElement.offsetWidth;
      const cardsXPosition = cardsElement.getBoundingClientRect().left;
      const currentLeft = cardsWidth + cardsXPosition;

      const singleCardArray = this.singleCard?.toArray();
      const visibleSingleCardNumber: number | undefined = singleCardArray?.findIndex(item => {
        const element = item.nativeElement;
        const xPosition = element.getBoundingClientRect().left;
        return xPosition > currentLeft ? true : false;
      });

      const lineElement = this.line.nativeElement;
      if(this.singleCard && this.singleCard.length > 0 && visibleSingleCardNumber) {
        lineElement.style.width = (visibleSingleCardNumber === -1 ? (this.course.streamInfo?.streamComments.length || 1) : visibleSingleCardNumber)  * 100
        / (this.course.streamInfo?.streamComments.length || 1) + '%';
      }
    }
  };

  public calculateTallestDivHeight(index: number) {
    this.shows[index] = !this.shows[index];

    setTimeout(() => {

      let maxHeight = 0;

      if(!this.singleCard || this.singleCard.length === 0) {
        return;
      }

      this.singleCard.forEach((div: ElementRef) => {
        const height = div.nativeElement.offsetHeight;
        if (height > maxHeight) {
          maxHeight = height;
        }
      });

      this.singleCard.forEach((div: ElementRef) => {
        div.nativeElement.style.minHeight = this.shows[index] ? (maxHeight + 'px') : '100%';
      });
    }, 10)
  }

  public addToCart(course: ICatalogue): void {
    const genius = [
      '34884638-e59c-4225-9ea5-4b87e43fbf89',
      'c819836e-73ed-4154-92f9-54a96a1d9e4b',
      '4ba64454-bacf-46e5-85d2-f9f802cbf760',
      'bd504325-b1c7-4296-9088-aa9b447dd4ec',
      '5e2e0268-f023-478b-924a-85a21a3e07a2',
      '15535607-3d0e-48c6-bc67-7204664521da',
      '54319446-39e1-461b-9904-ed352033266c',
      '8d6ec86c-0b05-48b1-8586-dd33183169c1',
      '31b65925-2606-46b2-b9b2-fce232ba6b1f',
      'd9dbd307-3c26-4f22-b007-b597738a0c21',
      '59d784dd-3b93-458e-8ec2-04edbcc1aa4c',
      'ba7a1248-907e-43bb-827b-41fb5af89de2',
      '2962b0e6-a7c3-4aae-9af4-ae2b88ffc5a2',
      'f86cc706-7546-41f8-9565-681e2d96264f',
      'd89f0f26-2bdd-4c26-83c0-ac32a4e28052',
      'ab3c29de-dac9-4ba3-b268-59372ede0dd2',
      'fd2046da-448d-428f-9a56-5b9855b2a573',
      '4d999883-0cb7-435c-a240-0be9016594ef',
      'adae9e6c-05e5-4119-9afd-b436ab164c40',
      '30bde7fd-0cd1-4a88-8a48-63eb4fe4a38f',
      '536ecde8-2af8-4d20-8d62-7cd831b15f5a',
      'db6a54fb-8c63-48c2-b71f-1042d437c732',
      '27ac2b87-d628-49f6-a5ad-a79d0452bf2c',
      '6a6b4e68-dd3b-419d-ae2a-101c76857c36',
      '3248e108-06a4-4d4c-aa6b-56c253d0090e',
      '8719d23a-fbea-4436-b918-3671018e1c3e',
      'd3a3e890-58cd-4148-ae98-1b48f8933c92',
      'ccf1b889-c284-47b5-a056-7a655135e98a',
      'f2845346-c07c-4a17-8d77-00dbd729ac83',
      'fd2046da-448d-428f-9a56-5b9855b2a573',
      'ab3c29de-dac9-4ba3-b268-59372ede0dd2',
      'c819836e-73ed-4154-92f9-54a96a1d9e4b',
      '34884638-e59c-4225-9ea5-4b87e43fbf89',
      "ccf1b889-c284-47b5-a056-7a655135e98a",
      "d3a3e890-58cd-4148-ae98-1b48f8933c92",
      "8719d23a-fbea-4436-b918-3671018e1c3e",
      "3248e108-06a4-4d4c-aa6b-56c253d0090e",
      "6a6b4e68-dd3b-419d-ae2a-101c76857c36",
      "27ac2b87-d628-49f6-a5ad-a79d0452bf2c",
      "db6a54fb-8c63-48c2-b71f-1042d437c732",
      "adae9e6c-05e5-4119-9afd-b436ab164c40",
      "30bde7fd-0cd1-4a88-8a48-63eb4fe4a38f",
      "f2845346-c07c-4a17-8d77-00dbd729ac83",
      "4d999883-0cb7-435c-a240-0be9016594ef",
      "536ecde8-2af8-4d20-8d62-7cd831b15f5a",
      "fd2046da-448d-428f-9a56-5b9855b2a573",
      "ab3c29de-dac9-4ba3-b268-59372ede0dd2",
      "34884638-e59c-4225-9ea5-4b87e43fbf89",
      "c819836e-73ed-4154-92f9-54a96a1d9e4b",
      "4ba64454-bacf-46e5-85d2-f9f802cbf760",
      "bd504325-b1c7-4296-9088-aa9b447dd4ec",
      "5e2e0268-f023-478b-924a-85a21a3e07a2",
      "15535607-3d0e-48c6-bc67-7204664521da",
      "54319446-39e1-461b-9904-ed352033266c",
      "8d6ec86c-0b05-48b1-8586-dd33183169c1",
      "31b65925-2606-46b2-b9b2-fce232ba6b1f",
      "d9dbd307-3c26-4f22-b007-b597738a0c21",
      "59d784dd-3b93-458e-8ec2-04edbcc1aa4c",
      "ba7a1248-907e-43bb-827b-41fb5af89de2",
      "2962b0e6-a7c3-4aae-9af4-ae2b88ffc5a2",
      "f86cc706-7546-41f8-9565-681e2d96264f",
      "d89f0f26-2bdd-4c26-83c0-ac32a4e28052",
      "ce765669-86cd-4ed3-82ed-841014b44b94",
      "97a32b70-d0ba-49c4-8767-3478cf4ec155",
      "0efa8970-a88a-4205-83b5-ef68c0f802bf",
      "3a9dee6d-87a0-4750-9b46-c23892ed3cee",
      "6c7829b7-abaa-46f6-93a2-0fbf3dee615f",
      "177ec987-bce0-4d13-943b-98e9cf30a31a",
      "1c74aa7e-6eb8-4679-a2c9-07fceb79d2be",
      "3ef5134b-80b5-44fa-915b-ee41fd1e774d",
      "b513dc97-02d0-4e71-8dc6-b74711fdd627",
      "6fb0f68d-230c-441a-88fd-ab8707c1aa7c",
      "e7c4bbc7-7020-4493-930c-7eaa9d179d5a",
      "a9ab2e3e-dd32-4bb9-8840-b4532d3cce42",
      "f9bae0d6-ccd7-4fa5-98f9-ace85e54f612",
      "a2f11a6a-720e-47ac-a819-12c4cb14c5a7",

      "6a584e97-cb61-49b4-b69c-a0eb2b8c4bed",
      "1397ca8c-2f37-40e1-8acc-90f2f8953289",
      "300ccb5b-f351-4c18-bec6-c0db1a120d96",
      "88f09c10-8186-46e1-a8b9-d91ca3787c87",
      "05abab4c-5c49-4432-8bc0-f1c677273746",
      "bb4e733f-9fe3-471a-b2f8-319b4f206841",
      "5aedf553-06ab-4c43-8174-382092d56fc7",
      "815721a2-7bc0-4560-b266-c3a885a2f2d4",
      "007035a7-821a-4c42-8658-c6a0f9f47528",
      "736edcc1-d1ec-4ee5-a481-4d97bb634543",
      "76fb3138-3a63-479e-ac3e-5646a60e26aa",
      "266cc7c3-f822-4c0d-b70c-006599c7f305",
      "92bd58b7-f9eb-42dd-9608-bc4e930f9a9a",
      "341d5d4c-5388-4033-b2e1-837fde3abbe7"
,   ];
    const pickedCourses = this._cataloguesService.getCoursesFromLocalStorage();

    if(localStorage.getItem('token') && localStorage.getItem('token') !== "null") {

      this._cataloguesService.getActiveCourse().subscribe({
        next: (res) => {

          if(genius.includes(course.id) && (res.length !== 0 || pickedCourses.length !== 0)) {
            this.toastrService.info(this.translateService.instant('CANT_BUY_GENIUS'), this.translateService.instant('ATTENTION'));
            return
          }

          if((res.length > 0 && genius.includes(res[0].streamId))
            || (pickedCourses && pickedCourses[0] && genius.includes(pickedCourses[0].id))) {
            this.toastrService.info(this.translateService.instant('CANT_BUY_GENIUS'), this.translateService.instant('ATTENTION'));
            return
          }

          this._cataloguesService.addToCard(course);

          this.cdr.detectChanges();
        },
        error: (err) => {
          console.log(err)
        },
      })
    }
    else {
      if(genius.includes(course.id) && pickedCourses.length !== 0) {
        this.toastrService.info(this.translateService.instant('CANT_BUY_GENIUS'), this.translateService.instant('ATTENTION'));
        return
      }
      if((pickedCourses && pickedCourses[0] && genius.includes(pickedCourses[0].id))) {

        this.toastrService.info(this.translateService.instant('CANT_BUY_GENIUS'), this.translateService.instant('ATTENTION'));
        return
      }
      this._cataloguesService.addToCard(course);
    }     

  }

  public getImageUrl(url : string) : string
 {
  return this._fs.getImageUrl(url)
 }
}
