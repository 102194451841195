import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-store-links',
  templateUrl: './app-store-links.component.html',
  styleUrls: ['./app-store-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppStoreLinksComponent {

}
