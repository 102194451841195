import { Pipe, PipeTransform } from '@angular/core';
import { Languages } from '../../models';
import { TranslateService } from '@ngx-translate/core';
import { TestType, TestTypeKz, TestTypeRu } from 'src/app/core/model';

@Pipe({
  name: 'translateTaskType',
  pure: true,
})
export class TranslateTaskTypePipe implements PipeTransform {
  private language: Languages = Languages.KZ;

  constructor(private translate: TranslateService) {
    this.language = translate.currentLang as Languages;
  }
  
  transform(testType: TestType): TestTypeKz | TestTypeRu {
    switch (this.language) {
      case Languages.KZ:
        switch (testType) {
          case TestType.Test:
            return TestTypeKz.Test;
          case TestType.Open:
            return TestTypeKz.Open;
          case TestType.DragAndDrop:
            return TestTypeKz.DragAndDrop;
          case TestType.Fill:
            return TestTypeKz.Fill;
          case TestType.Matching:
            return TestTypeKz.Matching;
          default:
            return TestTypeKz.Test;
        }
      case Languages.RU:
        switch (testType) {
          case TestType.Test:
            return TestTypeRu.Test;
          case TestType.Open:
            return TestTypeRu.Open;
          case TestType.DragAndDrop:
            return TestTypeRu.DragAndDrop;
          case TestType.Fill:
            return TestTypeRu.Fill;
          case TestType.Matching:
            return TestTypeRu.Matching;
          default:
            return TestTypeRu.Test;
        }
      default:
        return TestTypeKz.Test;
    }
  }

}
