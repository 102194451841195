export enum Roles {
  Student = 'STUDENT',
  Methodist = 'METHODIST',
  Curator = 'CURATOR',
  HeadTeacher = 'HEAD_TEACHER',
  Teacher = 'TEACHER',
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  ExternalManager = 'EXTERNAL_MANAGER',
  InternalManager = 'INTERNAL_MANAGER'
}

export const ROLES_LABEL: Record<string, string> = {
  STUDENT: 'Студент',
  METHODIST: 'Методист',
  CURATOR: 'Куратор',
  TEACHER: 'Преподаватель',
  ADMIN: 'Админ',
  HEAD_TEACHER: 'Завуч',
  MANAGER: 'Менеджер',
  EXTERNAL_MANAGER: 'Внешний менеджер',
  INTERNAL_MANAGER: 'Внутренний менеджер'
};
