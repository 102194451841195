import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CourseDetailMainComponent } from './components/course-detail-main/course-detail-main.component';
import { CartModalComponent } from './components/cart-modal/cart-modal.component';
import { CartModalCardComponent } from './components/cart-modal-card/cart-modal-card.component';
import { AppendCourseInfoComponent } from './components/append-course-info/append-course-info.component';
import { OrderFormComponent } from './components/order-form/order-form.component';
import { OrderInfoComponent } from './components/order-info/order-info.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { OrderDialogComponent } from './components/order-dialog/order-dialog.component';
import { CartModalCardActiveComponent } from './components/cart-modal-card-active/cart-modal-card-active.component';
import { TranslateModule } from "@ngx-translate/core";
import { NgxConveyerModule } from '@egjs/ngx-conveyer';
import { RouterModule } from '@angular/router';
import { CataloguesFooterComponent } from './components/catalogues-footer/catalogues-footer.component';


@NgModule({
  declarations: [
    CourseDetailMainComponent,
    CartModalComponent,
    CartModalCardComponent,
    AppendCourseInfoComponent,
    OrderFormComponent,
    OrderInfoComponent,
    OrderDialogComponent,
    CartModalCardActiveComponent,
    CataloguesFooterComponent,
  ],
  exports: [
    CourseDetailMainComponent,
    CartModalComponent,
    AppendCourseInfoComponent,
    OrderFormComponent,
    OrderInfoComponent,
    CartModalCardActiveComponent,
    CataloguesFooterComponent,
  ],
  imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule, TranslateModule, NgxConveyerModule, RouterModule],
})
export class CataloguesModule {}
