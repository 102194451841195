import { StudentHelperService } from 'src/app/feature/student/service/student-helper.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BaseRoutes, StudentGroupDTO, StudentGroupThemeDTO, StudentRoutes } from 'src/app/core/model';
import { CatalogCoursesButtonTheme } from 'src/app/shared/models/enums/themes.enum';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectCurrentGroupWithDetails, selectGroups } from 'src/app/core/state/student/groups/groups.selector';
import { setCurrentGroupId } from 'src/app/core/state/student/groups/groups.actions';

@Component({
  selector: 'app-student-courses-card',
  templateUrl: './courses-card.component.html',
  styleUrls: ['./courses-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoursesCardComponent implements OnInit, OnDestroy {
  public isOpen: boolean = false;
  public catalogCoursesButtonTheme = CatalogCoursesButtonTheme;

  public groupsList: StudentGroupDTO[] = []; 
  private groups$: Observable<StudentGroupDTO[]> = this.store.select(selectGroups);
  
  public currentGroup: StudentGroupThemeDTO | null | undefined;
  private currentGroupWithDetails$: Observable<StudentGroupThemeDTO | null | undefined> = this.store.select(selectCurrentGroupWithDetails);
  
  private groupsSubscription!: Subscription;
  private currentGroupWithDetailsSubscription!: Subscription;

  constructor(
    private router: Router,
    private studentHelperService: StudentHelperService,
    private store: Store,
    private cdr: ChangeDetectorRef
  ){}

  ngOnInit(): void {
    this.groupsSubscription = this.groups$.subscribe(res => {
      this.groupsList = res;
      this.cdr.detectChanges();
    })
    this.currentGroupWithDetailsSubscription = this.currentGroupWithDetails$.subscribe(res => {
      this.currentGroup = res;
      this.cdr.detectChanges();
    });
  };

  ngOnDestroy(): void {
    this.groupsSubscription.unsubscribe();
    this.currentGroupWithDetailsSubscription.unsubscribe();
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
  };

  public openGroup(id: string): void {
    if(window.innerWidth > 999) {
     this.store.dispatch(setCurrentGroupId({id: id}));
    }
    else {
      this.store.dispatch(setCurrentGroupId({id: id}));
      this.router.navigate([`/${BaseRoutes.Student}/${StudentRoutes.Education}/${StudentRoutes.ThemesMobile}/${id}`]);
    }
  }
}
