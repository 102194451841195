import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RegionDTO } from '../../model';

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  constructor(private httpClient: HttpClient,) { }

  public getRegions(): Observable<RegionDTO[]> {
    return this.httpClient.get<RegionDTO[]>(`/v1/regions`);
  }

}
