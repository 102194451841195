import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { PaymentsService } from 'src/app/core/services/payments/payments.service';

@Component({
  selector: 'app-admin-payments-history',
  templateUrl: './admin-payments-history.component.html',
  styleUrls: ['./admin-payments-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminPaymentsHistoryComponent implements OnChanges {
  @Input() studentId: any;

  public data: any[] = [];

  constructor(
    private paymentsService: PaymentsService,
    private cdr: ChangeDetectorRef
  ) {

  }

  ngOnChanges(): void {
    if (this.studentId) {
      this.paymentsService
        .getPaymentHistoryByStudentId(this.studentId)
        .subscribe({
          next: res => {
            console.log('Payment history:', res);
            this.data = res;
            this.cdr.detectChanges();
          }
        });
    }
  }
}
