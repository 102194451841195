import { Observable } from 'rxjs';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  StudentRoutes,
  BaseRoutes,
  StudentThemeDTO,
  LessonType,
  StudentGroupThemeDTO
} from 'src/app/core/model';
import { selectCurrentGroup } from 'src/app/core/state/student/groups/groups.selector';
import { LessonStatus } from 'src/app/shared/models';
import { LessonsService } from 'src/app/core/services/lessons/lessons.service';
import { DateHelperServiceService } from './../../../../../shared/services/dateHelperService/date-helper-service.service';

@Component({
  selector: 'app-item-sidebar',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemComponent implements OnInit {
  public innerWidth: number = window.innerWidth;

  @Input() data!: StudentThemeDTO;

  public group: StudentGroupThemeDTO | null = null;
  public group$: Observable<StudentGroupThemeDTO | null> = this.store.select(selectCurrentGroup);

  public color: ThemePalette = 'accent';
  public mode: ProgressSpinnerMode = 'determinate';
  public diameter: number = 20;

  public lessonType = LessonType;

  // @Input() outIsOpen: boolean = false;
  @Input() isOpen: boolean = false;
  @Output() toggleEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private router: Router,
    private store: Store,
    private cdr: ChangeDetectorRef,
    private lessonsService: LessonsService,
    private dateHelperServiceService: DateHelperServiceService) {}
  ngOnInit(): void {
    if(this.data) {
      this.lessonsService.getActiveLesson().subscribe({
        next: (id) => {
          if(id && this.data && this.data.lessons && this.data.lessons.length > 0) {
            for(let item of this.data.lessons) {
              if(item.lessonId === id) {
                item.active = true;
              }
              else {
                item.active = false;
              }
            }
          }
          else {
            if(this.data.lessons) {
              for(let item of this.data.lessons) {
                item.active = false;
              }
            }
          }
          this.cdr.detectChanges();
        },
        error: (err) => {
          console.log(err);
        }
      });
    }
    this.group$.subscribe(res => {
      if(res) {
        this.group = res;
      }
      this.cdr.detectChanges();
    });
  };

  toggle() {
    if(this.isFutureTheme()) {
      return;
    }
    this.toggleEvent.emit();
  }

  public navigateToText(lessonId: string): void {
    this.lessonsService.setActiveLesson(lessonId);
    this.router.navigate([`${BaseRoutes.Student}/${StudentRoutes.Education}/${StudentRoutes.Text}/${this.group?.groupId}/${lessonId}`]);
  }

  public navigateToTheory(lessonId: string): void {
    this.lessonsService.setActiveLesson(lessonId);

    this.router.navigate([`${BaseRoutes.Student}/${StudentRoutes.Education}/${StudentRoutes.Theory}/${this.group?.groupId}/${lessonId}`])
  }
  public navigateToHomework(lessonId: string): void {
    this.lessonsService.setActiveLesson(lessonId);

    this.router.navigate([`${BaseRoutes.Student}/${StudentRoutes.Education}/${StudentRoutes.Homework}/${this.group?.groupId}/${lessonId}`])
  }
  public navigateToWorkOnMistakes(lessonId: string): void {
    this.lessonsService.setActiveLesson(lessonId);

    this.router.navigate([`${BaseRoutes.Student}/${StudentRoutes.Education}/${StudentRoutes.WorkOnMistakes}/${this.group?.groupId}/${lessonId}`])
  }
  public navigateToTest(lessonId: string, type?: LessonStatus | number): void {
    this.lessonsService.setActiveLesson(lessonId);

    if(type === LessonStatus.Success) {
      this.router.navigate([`/${BaseRoutes.Student}/${StudentRoutes.Test}/${StudentRoutes.TestPass}/${this.group?.groupId}/${this.group?.courseId}/${lessonId}`])
    }
    else {
      this.router.navigate([`/${BaseRoutes.Student}/${StudentRoutes.Test}/${StudentRoutes.TestStart}/${this.group?.groupId}/${this.group?.courseId}/${lessonId}`])
    }
  }

  public isFutureTheme(): boolean {
    return this.dateHelperServiceService.convertUTCToLocal(new Date(this.data.themeStartTime)) > new Date();
  }

}
