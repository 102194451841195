import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IGroup } from 'src/app/core/model/interface/group';

@Component({
  selector: 'app-admin-modal-curator-card',
  templateUrl: './admin-modal-curator-card.component.html',
  styleUrls: ['./admin-modal-curator-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminModalCuratorCardComponent {
  @Input() group!: IGroup;
}
