<h2 class="curators__header header">
  <span class="header__title"> Кураторы </span>
  <button class="header__link" (click)="showLinkCurator()">
    Прикрепить куратора
  </button>
</h2>
<div class="curators">
  <ng-container *ngIf="curatorsList.length > 0">
    <ng-container *ngFor="let curator of curatorsList">
      <div class="curator__item border">
        <div class="wrapper">
          <div class="curator__header" [class.align-center]="!curator.groups?.length">
            <div class="img__wrapper">
              <app-avatar [firstLetter]="curator.firstname && curator.firstname[0]"></app-avatar>
            </div>
            <div>
              <div class="name">
                {{ curator.firstname }} {{ curator.lastname }}
              </div>
              <div class="groups flex flex-col">
                <div
                  class="group text-md flex items-center"
                  *ngFor="let group of curator.groups"
                >
                  <span>{{group.month | monthConverter: currentLang: true}} {{group.year % 100}} {{ group.name }} </span>

                  <ng-container *ngIf="group.studentCount">
                    <div class="circle"></div>
                    <span>{{ group.studentCount }} учеников</span>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          class="unlink-btn text-md"
          (click)="onUnlinkCurator(curator.id)"
        >
          Открепить
        </button>
      </div>
    </ng-container>
  </ng-container>
  <!-- <app-empty-message
    *ngIf="curatorsList.length === 0"
    message="Пока кураторов не прикреплено"
  >
  </app-empty-message> -->
</div>
