<ng-container *ngIf="manager">
  <div class="manager-info">
    <div class="status info-field">
      <div class="status__title title">{{ "managerStatus" | translate }}:</div>
      <div class="status__value value">
        <span
          [ngClass]="{
            manager_active: manager?.statusEnum === 'ACTIVE',
            manager_fired: manager?.statusEnum === 'FIRED'
          }"
        >
          {{
            (manager?.statusEnum
              ? "managerStatus_" + manager?.statusEnum.toLowerCase()
              : EMPTY_VALUE
            ) | translate
          }}</span
        >
        <ng-container *ngIf="manager?.statusEnum !== 'FIRED'">
          <button (click)="fireManager()" class="fire_manager">
            {{ "fireManager" | translate }}
          </button>
        </ng-container>
        <ng-container *ngIf="manager?.statusEnum === 'FIRED'">
          <button (click)="activateManager()" class="activate_manager">
            {{ "activateManager" | translate }}
          </button>
        </ng-container>
      </div>
    </div>

    <div class="invited-users info-field">
      <div class="invited-users__title title">
        {{ "invitedStudentsCount" | translate }}:
      </div>
      <div class="invited-users__value value">
        {{ manager?.invitedStudentsCount ?? 0 }}
      </div>
    </div>

    <div class="btns"></div>
  </div>
</ng-container>
