import { Injectable } from '@angular/core';
import { LessonStatus, SelectValue } from '../../models';
import {
  LessonType,
  LessonTypeKZ,
  LessonTypeRU,
  ProgressDTO,
  StudentThemeDTO,
  StudentUser,
} from 'src/app/core/model';
import { IGroup } from 'src/app/core/model/interface/group';
import { DateHelperServiceService } from 'src/app/shared/services/dateHelperService/date-helper-service.service';
import { find } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(private dateHelper: DateHelperServiceService) {}

  public convertEnumToSelectValue(enumObj: any): SelectValue[] {
    console.log(enumObj)
    return Object.values(enumObj).map((value: unknown) => ({
      name: value as string,
      value: value as string,
    }));
  }

  public convertEnumToSelectValue2(enumObj: any): SelectValue[] {
    console.log(enumObj)

    return Object.entries(enumObj).map(([key, value]) => ({
      name: key,
      value: value as string,
    }));
  }
  public convertNumericEnumToSelectValue(enumObj: any): SelectValue[] {
    return Object.keys(enumObj)
      .filter((value) => !isNaN(Number(value)))
      .map((value: unknown) => ({
        name: value as string,
        value: value as string,
      }));
  }
  public convertToSelectValueArray(
    arr: any[],
    nameProp: string,
    valueProp: string
  ): SelectValue[] {
    return arr.map((item: any) => ({
      name: item[nameProp],
      value: item[valueProp],
    }));
  }
  public mapTaskTypeToLessonType(
    taskType: LessonTypeKZ | LessonTypeRU
  ): LessonType {
    switch (taskType) {
      case LessonTypeKZ.Lecture:
      case LessonTypeRU.Lecture:
        return LessonType.Lecture;
      case LessonTypeKZ.Homework:
      case LessonTypeRU.Homework:
        return LessonType.Homework;
      case LessonTypeKZ.Quiz:
      case LessonTypeRU.Quiz:
        return LessonType.Quiz;
      case LessonTypeKZ.WorkOnMistakes:
      case LessonTypeRU.WorkOnMistakes:
        return LessonType.WorkOnMistakes;
      case LessonTypeKZ.Text:
      case LessonTypeRU.Text:
        return LessonType.Text;
      default:
        // Handle other cases or throw an error for unsupported values
        throw new Error('Invalid task type');
    }
  }
  public stringToFile(data: string, filename: string, mimeType: string): File {
    const byteCharacters = atob(data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const file = new File(byteArrays, filename, { type: mimeType });
    return file;
  }
  public extractNameFromId(id: string): string {
    return id.match(/_(.*)$/)?.[1] || 'unnamed.txt';
  }
  public downloadFile(file: File): void {
    const blobUrl = URL.createObjectURL(file);
    const anchorElement = document.createElement('a');
    anchorElement.href = blobUrl;
    anchorElement.download = file.name;
    anchorElement.click();
    URL.revokeObjectURL(blobUrl);
  }

  public countProgressForEachTheme(themes: StudentThemeDTO[]): StudentThemeDTO[] {
    for(let item of themes) {
      let sum: number = 0;
      if(item.lessons) {
        for(let lesson of item.lessons) {
          if(lesson.progressStatus === LessonStatus.Success) {
            sum = sum + 100;
          }
        }
      }
      item.value = sum/(item.lessons?.length || 1 );
    }
    return themes;
  }
  public countProgressForGroup(themes: StudentThemeDTO[]): number {
    let sum: number = 0;
    for(let item of themes) {
      sum = sum + item.value;
    }

    return sum/themes.length;
  }

  public groupByKey(arr: any[], key: string, name?: string) {
    if (!arr || arr.length === 0) return;
    const groupArr = arr.reduce((acc, item) => {
      const isExist = acc.find(
        (arrItem: { [x: string]: any }) => arrItem[`${key}`] === item[`${key}`]
      );
      const group: never[] = [];
      if (!isExist) {
        const newObj: { [key: string]: any } = {};
        newObj[`${key}`] = item[`${key}`];
        if (name) newObj['name'] = name;
        group.push(item as never);
        newObj['items'] = group;
        acc.push(newObj);
      } else {
        const indx = acc.findIndex(
          (subitem: { [x: string]: any }) =>
            subitem[`${key}`] === item[`${key}`]
        );
        acc[indx].items.push(item);
      }
      return acc;
    }, []);
    return groupArr.reverse();
  }

  public convertPhoneNumberForSave(phoneNumber: string): string {
    if (!phoneNumber) return phoneNumber;
    return `+7${phoneNumber}`;
  }

  public StudentUsersToSelectValue(studentUsers: StudentUser[]): SelectValue[] {
    return studentUsers.map(item => {
      return {
        name: item.firstname + " " + item.lastname,
        value: item.id || ""
      }
    });
  };

  public IGroupToSelectValue(groups: IGroup[]): SelectValue[] {
    return groups.map(item => {
      return {
        name: item.courseName,
        value: item.id
      }
    });
  }
}
