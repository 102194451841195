import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CrmService } from 'src/app/shared/services/crm/crm.service';

@Component({
  selector: 'app-admin-comments',
  templateUrl: './admin-comments.component.html',
  styleUrls: ['./admin-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminCommentsComponent implements OnInit, OnChanges {

  @Input() student: any;

  public form?: FormGroup;

  constructor(
    private fb: FormBuilder,
    private crmService: CrmService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      comment: new FormControl(''),
    });
  }

  ngOnChanges(): void {
    this.student?.managerComments?.reverse();
  }

  public createComment(comment: string): void {
    this.crmService
      .createManagerComment(this.student.id, comment)
      .subscribe({
        next: res => {
          if (this.student.managerComments) {
            this.student.managerComments = [res, ...this.student.managerComments];
          } else {
            this.student.managerComments = [res];
          }
          this.cdr.detectChanges();
        },
        error: err => {
          console.error('Error:', err);
        }
      })
  }
}
