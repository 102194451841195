import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { HeadTeacherService } from 'src/app/core/services/head-teacher/head-teacher.service';
import { AuthService } from './../../../../core/services/auth/auth.service';
import { BaseRoutes, Roles } from 'src/app/core/model';
@Component({
  selector: 'app-stream-card',
  templateUrl: './stream-card.component.html',
  styleUrls: ['./stream-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StreamCardComponent {
  @Input() title?: string;
  @Input() descriptionInfo?: string | number;
  @Input() footerInfo?: string | number;
  @Input() status?: boolean;
  @Input() courseFinished?: boolean;
  @Input() isOnlyLogged?: boolean;
  @Input() id?: string;
  @Input() link?: string;
  @Input() isCount?: boolean;
  @Input() queryValue?: string | number;

  public canManipulate: boolean = false;
  public showInfoToggle: boolean = false;
  public showInfoStreamToggle: boolean = false;
  public showInfoAuthorizedToggle: boolean = false;

  public isDeleteModalOpen: boolean = false;
  public isLoading: boolean = false;
  public streamIdToDelete: string = '';

  @Output() onSwitchStatus: EventEmitter<any> = new EventEmitter();
  @Output() onSwitchCourseStatus: EventEmitter<any> = new EventEmitter();
  @Output() onSwitchAuthorizedStatus: EventEmitter<any> = new EventEmitter();
  @Output() onTitleClick: EventEmitter<any> = new EventEmitter();

  constructor(
    private _router: Router,
    private headteacherService: HeadTeacherService,
    private _ns: ToastrService,
    private _translate: TranslateService,
    private authService: AuthService
  ) {}

  public onToggleChange(status: any): void {
    this.onSwitchStatus.emit({ status: status.checked, id: this.id });
  }

  public onToggleCourseChange(status: any): void {
    this.onSwitchCourseStatus.emit({ status: status.checked, id: this.id });
  }

  public onToggleAuthorizedChange(status: any): void {
    this.onSwitchAuthorizedStatus.emit({ status: status.checked, id: this.id });
  }

  public onTitleClickHandler(): void {
    this.onTitleClick.emit();
  }

  public edit(): void {
    this.navigateToEdit(this.id!);
  }
  public duplicate(): void {
    this.headteacherService.duplicateStreamById(this.id!).subscribe({
      next: (res) => {
        this.navigateToEdit(res.id);
      },
    });
  }

  public openDeleteModal(): void {
    this.isDeleteModalOpen = true;
  }

  public closeDeleteModal(): void {
    this.isDeleteModalOpen = false;
  }

  public remove(id: string = ''): void {
    this.isLoading = true;
    this.headteacherService.removeStreamById(this.id!).subscribe({
      next: () => {
        this._ns.info(this._translate.instant('streamRemoveSuccess'));
        setTimeout(() => {
          window.location.reload();
        }, 150);
        this.isLoading = false;
      },
      error: err => {
        console.error('Error:', err);
        this.isLoading = false;
      }
    });
  }

  public canAction(): boolean {
    return !!!this.status;
  }

  private navigateToEdit(id: string): void {
    let name: string | undefined;

    switch (this.authService.getRole()) {
      case Roles.HeadTeacher: {
        name = BaseRoutes.HeadTeacher;
        break;
      }
      case Roles.Admin: {
        name = BaseRoutes.Admin;
        break;
      }
    }

    if(!name) {
      return;
    }

    this._router.navigate([name, 'catalogue', 'edit-stream', id]);
  }

  public openCloseStreamTooltip() {
    this.showInfoStreamToggle = !this.showInfoStreamToggle;
  }

  public openCloseCourseTooltip() {
    this.showInfoToggle = !this.showInfoToggle;
  }

  public openCloseAuthorizedTooltip() {
    this.showInfoAuthorizedToggle = !this.showInfoAuthorizedToggle;
  }
}
