import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-control-error-message',
  templateUrl: './control-error-message.component.html',
  styleUrls: ['./control-error-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ControlErrorMessageComponent {

  @Input() control : AbstractControl | undefined 

}
