<app-form-field
  [name]="name"
  [focused]="focused"
  [isActive]="isActive"
  [error]="error"
  [control]="ngControl.control"
  [borderType]="borderType"
  [isLoading]="isLoading"
>
  <select
    class="iconic-input"
    (focus)="onFocus()"
    (blur)="onBlur()"
    [formControl]="ngControl.control"
    (change)="onSelect($event)"
  >
    <option value="" *ngIf="placeholder" disabled selected>
      {{ placeholder | translate }}
    </option>

    <option *ngFor="let option of options" [value]="option.value">
      {{ option.name | translate }} <span *ngIf="showCount && option.count">({{option.count + ' ' + ('PEOPLE' | translate)}})</span>
    </option>
  </select>
</app-form-field>
