import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import videojs from 'video.js';
import 'videojs-contrib-eme';

@Component({
  selector: 'app-test-video-player',
  templateUrl: './test-video-player.component.html',
  styleUrls: ['./test-video-player.component.scss']
})
export class TestVideoPlayerComponent implements OnInit, OnDestroy {
  @ViewChild('target', {static: true}) target!: ElementRef;

  // See options: https://videojs.com/guides/options
  @Input() options!: {
      fluid: boolean,
      aspectRatio: string,
      autoplay: boolean,
      sources: {
          src: string,
          type: string,
      }[],
  };

  //@ts-ignore
  player!: videojs.Player;

  constructor(
    private elementRef: ElementRef,
  ) {}

  // Instantiate a Video.js player OnInit
  ngOnInit() {
    this.player = videojs(this.target.nativeElement, this.options, function onPlayerReady() {
      //@ts-ignore
      console.log('onPlayerReady', this);
    });

    this.player.src({
      src: 'https://media.axprod.net/TestVectors/v6.1-MultiDRM-MultiKey/Manifest_1080p.mpd',
      type: 'application/dash+xml',
      // keySystems: {
      //   'com.widevine.alpha': 'https://widevine.gumlet.com/licence/6679539710a8fc3c1a78bd6c',
      // }
    })

    this.player.eme()

  }

  // Dispose the player OnDestroy
  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}