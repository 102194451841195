import { createFeatureSelector, createSelector } from "@ngrx/store";
import { UserState } from "./user.reducer";

export const selectUserState = createFeatureSelector<UserState>('user');

export const selectProfileState = createSelector(
    selectUserState,
    (state: UserState) => state.profile
);
export const selectUserPayment = createSelector(
    selectUserState,
    (state: UserState) => state.payment
);