<div class="technical-works w-100 h-100 flex justify-center items-center">
  <div class="bg-absolute"></div>
  <div class="technical-works-body flex flex-col items-center">

    <div class="body-header flex justify-between w-100">

      <div class="body-header-img"></div>

      <div class="flex">
        <button (click)="setLang(languagesEnum.KZ)"
                [class.active]="currentLanguage === languagesEnum.KZ"
                class="technical-works-btn">Қазақша</button>
        <button (click)="setLang(languagesEnum.RU)"
                [class.active]="currentLanguage === languagesEnum.RU"
                class="technical-works-btn">Русский</button>
      </div>
    </div>

    <div class="body-content flex w-100">
      <div class="flex flex-col gap-40">

        <h1 class="body-content_title" [innerHTML]="'ERROR_PAGE_TITLE' | translate"></h1>
        <p class="body-content_description">{{'ERROR_PAGE_DESCRIPTION' | translate}}</p>


        <div class="flex body-content_btn">
          <a class="technical-works-btn flex-1 flex justify-center items-center active"
             href="/system-error" rel="noopener noreferrer">
            {{ 'UPDATE' | translate }}
          </a>
          <a class="technical-works-btn flex-1 flex justify-center items-center active gray-bg"
             href="https://youtu.be/lT0MQGhOCHo?si=YNOfMENXKDJv_yIK"
             target="_blank" rel="noopener noreferrer">
            {{ 'SEE_CONCERT' | translate }}
          </a>
        </div>
      </div>

      <img class="body-content_setting-img" src="assets/img/settings-img.png" alt="">
    </div>

    <div class="mobile page-link">
      <a href="/">JUZ40 Online</a>
      <span>, {{ currentYear }}.</span>
    </div>
  </div>
</div>
