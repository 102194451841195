<div class="creator">
    <div class="creator-left">
        <h1 class="creator-title">{{data?.courseName}}</h1>
        <span class="creator-info">{{data?.groupName}}</span>
    </div>
    <form [formGroup]="form" class="creator-right">
        <app-multi-select
            class="select-size"
            [options]="options"
            [preSelectedOptions]="['']"
            (selectedValue)="selectedOptionsEvent($event)"/>
        <button *ngIf="canCreateFolder" (click)="openCreatorDialog()" class="iconic-button primary-button creator-button">
            <img src="assets/svg/plus-white.svg" alt=""/>
            <span class="button-text">{{'CREATE_FOLDER_FOR_TYPE_LESSON' | translate}}</span>
        </button>
    </form>
</div>

<ul class="type-list" cdkDropList (cdkDropListDropped)="dropFolder($event)">
  <ng-container *ngFor="let item of data?.courseFolders; let itemIndex = index;">
    <li *ngIf="(showEmptyFolders ? true : item.lessons.length > 0)
        && (selectedOptions.length > 0 ? selectedOptions.includes(item.courseFolderId) : true)"
        cdkDrag
        [cdkDragDisabled]="!canSortLessons">
      <div class="type-container" cdkDragPlaceholder="">
        <div class="header">
          <h1 class="title">{{item.courseFolderName + '(' + item.lessons.length +')'}}</h1>
          <button *ngIf="canSortFolders" class="menu" [matMenuTriggerFor]="menu">
            <img src="assets/svg/triple-dot.svg" alt="">
          </button>
        </div>
        <ul class="list" cdkDropList (cdkDropListDropped)="dropLesson($event, item.courseFolderId)">
          <li class="item" *ngFor="let elem of item.lessons; let i = index; let last=last;" [cdkDragDisabled]="!canSortLessons" cdkDrag>
            <button class="button" (click)="goToLesson(item.courseFolderId, elem)" cdkDragPlaceholder>
              <div class="left">
                <app-lesson-status-image [active]="false" [type]="elem.lessonType" [status]="undefined"></app-lesson-status-image>
                <span>{{elem.lessonName}}</span>
              </div>
              <div class="right">
                <span *ngIf="elem.lessonDeadline" class="date">{{'DEADLINE' | translate}} {{elem.lessonDeadline | datepipe: 'dd MMMM'}}</span>
                <button *ngIf="canSortLessons" class="menu" [matMenuTriggerFor]="menu">
                  <img src="assets/svg/triple-dot.svg" alt="">
                </button>
              </div>
            </button>

            <mat-menu #menu="matMenu">
              <button class="menu-item" (click)="goLessonUp(item.courseFolderId, elem.lessonId, i)" [disabled]="i === 0" mat-menu-item>
                <img src="assets/svg/icon-arrow-up.svg" alt="" />
                <span>{{'MOVE_UP' | translate}}</span>
              </button>

              <button class="menu-item" (click)="goLessonDown(item.courseFolderId, elem.lessonId, i)" [disabled]="last" mat-menu-item>
                <img src="assets/svg/icon-arrow-down.svg" alt="" />
                <span>{{'MOVE_DOWN' | translate}}</span>
              </button>

              <!-- <button class="menu-item" mat-menu-item>
                  <img src="assets/svg/trash.svg" alt="" />
                  <span>Удалить</span>
              </button> -->
            </mat-menu>
          </li>
        </ul>
      </div>
      <mat-menu id="folders-menu" #menu="matMenu">
        <button class="menu-item" (click)="goFolderUp(item.courseFolderId, itemIndex)" mat-menu-item>
          <img src="assets/svg/icon-arrow-up.svg" alt="" />
          <span>{{'MOVE_UP' | translate}}</span>
        </button>

        <button class="menu-item" (click)="goFolderDown(item.courseFolderId, itemIndex)" mat-menu-item>
          <img src="assets/svg/icon-arrow-down.svg" alt="" />
          <span>{{'MOVE_DOWN' | translate}}</span>
        </button>

        <button class="menu-item" (click)="openEditorDialog(item)" mat-menu-item>
          <img src="assets/svg/edit.svg" alt="" />
          <span>{{'edit' | translate}}</span>
        </button>

        <button class="menu-item" (click)="openDeleteModal(item.courseFolderId)" mat-menu-item>
          <img src="assets/svg/trash.svg" alt="" />
          <span>{{'delete' | translate}}</span>
        </button>
      </mat-menu>
    </li>
  </ng-container>
</ul>

<div *ngIf="!data?.courseFolders?.length" class="empty-folders flex flex-col items-center justify-center">
  <img class="empty-folders-img" src="/assets/svg/empty-folders.svg" alt="">
  <p>{{'ITS_EMPTY_HERE' | translate}}</p>
</div>

<app-dialog
    [isOpen]="isCreatorOpen"
    title="CREATE_NEW_FOLDER"
    confirmBntText="confirm"
    closeBntText="TO_CANCEL"
    (closeEvent)="closeCreatorDialog()"
    (confirmEvent)="confirmCreatorDialog()"
    [disabledConfirm]="creatorForm.invalid"
    >
    <form [formGroup]="creatorForm" class="dialog">
        <app-input name="FOLDER_NAME" placeholder="Введите название папки" formControlName="name"></app-input>
        <span class="dialog-text" [innerHTML]="'STUDENT_FOLDER_DESCRIPTION' | translate"></span>
    </form>
</app-dialog>

<app-dialog
    [isOpen]="isEditerOpen"
    title="EDIT_NEW_FOLDER"
    confirmBntText="confirm"
    closeBntText="TO_CANCEL"
    (closeEvent)="closeEditorDialog()"
    (confirmEvent)="confirmEditorDialog()"
    [disabledConfirm]="editorForm.invalid"
>
    <form [formGroup]="editorForm" class="dialog">
        <app-input name="FOLDER_NAME" placeholder="Введите название папки" formControlName="name"></app-input>
        <span class="dialog-text">{{'STUDENT_FOLDER_DESCRIPTION' | translate}}</span>
    </form>
</app-dialog>

<app-delete-modal
  title="DELETE_FOLDER"
  text="SURE_TO_DELETE_FOLDER"
  confirmText="YES_DELETE_FOLDER"
  cancelText="NO_DELETE_FOLDER"
  (deleteEvent)="deleteFolder($event)"
  (closeEvent)="closeDeleteModal()"
  [isLoading]="isLoading"
  [id]="folderIdToDelete"
  [isModalOpen]="isDeleteModalOpen"
>
</app-delete-modal>
