import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FilesService } from 'src/app/core/services/files/files.service';

@Component({
  selector: 'app-menu-header-student',
  templateUrl: './menu-header-student.component.html',
  styleUrls: ['./menu-header-student.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuHeaderStudentComponent implements OnInit {
  @Input() user: any;

  public imageUrl: string = '';

  constructor(private _fileService: FilesService) {}

  ngOnInit(): void {
    this.imageUrl = this.user.profilePhotoUrl;
    console.log(this.imageUrl);
  }

  public getImageUrl(): string {
    if (this.user && this.user?.profilePhotoUrl) {
      return this._fileService.getImageUrl(this.user.profilePhotoUrl);
    }
    return 'assets/img/default-ava.webp';
  }
}
