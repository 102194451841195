<app-login-register>
    <div class="stepper-wrapper">
        <form class="form form-reset" [formGroup]="form">
            <h1 class="step_title">{{ 'resetPassword' | translate}}</h1>
            <div class="fields">
                <app-input-password
                    [error]="formErrors.password"
                    [name]="'password'| translate"
                    [placeholder]="'createPassword'| translate"
                    formControlName="password"
                    (input)="handleFormControlErrors(form, formErrors, 'password')">
                </app-input-password>
                <app-input-password
                    [error]="formErrors.repassword"
                    [name]="'repeatPassword' | translate"
                    formControlName="repassword"
                    (input)="handleFormControlErrors(form, formErrors, 'repassword')">
                </app-input-password>
            </div>
            <button (click)="resetPassword()" class="confirm-button iconic-button primary-button"
                [disabled]="form.invalid"
                >{{'reset' | translate}}
          </button>
          <a class="button-link last-button" [routerLink]="'/'">{{'toMain' | translate}}</a>
        </form>
    </div>
</app-login-register>
