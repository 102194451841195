
<form class="content" [formGroup]="form">
    <div class="input">
        <app-input
            [error]="formErrors.questionText"
            class="input-input"
            name="QUESTION_TEXT"
            formControlName="questionText"
            (input)="handleFormControlErrors(form, formErrors, 'questionText')"
        ></app-input>
        <div class="input-icon-wrapper" (click)="questionImageInput.click()">
            <div class="input-icon" [style.backgroundImage]="'url(' + (form.get('questionImageUrl')?.value ? form.get('questionImageUrl')?.value : questionImageUrl) + ')'"></div>
        </div>
        <input #questionImageInput id="imageInput" class="image-input" type="file" accept="image/*" (change)="handleQuestionImageInput($event)">
    </div>

    <div class="correctAnswer">
        <div class="correctAnswer-input">
            <label class="correctAnswer-label">{{'RIGHT_ANSWER' | translate}}</label>
            <div class="input-wrapper" [ngClass]="{'input-wrapper--error': formErrors.answer}">
                <input formControlName="answer" class="input"/>
                <button *ngIf="form.get('answer')?.value" class="add-button" (click)="addAnswer()">
                  {{'ADD_OPTION' | translate}}
                </button>
            </div>
            <span class="error-message" *ngIf="formErrors.answer">{{formErrors.answer | translate}}</span>
        </div>
        <ul class="list">
            <li class="item" *ngFor="let item of form.get('answerList')?.value; let i = index;">
                <span class="item-value">{{item}}</span>
                <button (click)="removeAnswer(i)">
                    <img src="assets/svg/circles/close-circle.svg" alt="" />
                </button>
            </li>
        </ul>
    </div>
</form>
