<div class="student-side-bar-courses">
  <button class="button" (click)="toggle()" button>
    <div class="flex items-center justify-center w-100 gap-8">
      <h1 class="name">
        {{ (sortType === "SORT_BY_OLD" ? 'SORT_BY_NEW' : 'SORT_BY_OLD') | translate}}
      </h1>
      <div class="desktop">
        <app-primary-arrow class="arrow" [isOpen]="!isOpen"
                           closeColor="white"/>
      </div>
    </div>
  </button>
</div>
