import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionComponent {

  @Input() label!: string;
  @Input() text!: string;
  @Input() videoUrl: string | undefined;


  public isOpen!: boolean; 

  constructor() {
    this.isOpen = false;
  }

  public toggleIsOpen() : void {
    this.isOpen = !this.isOpen;
  }

}
