import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Roles } from 'src/app/core/model/enum/roles.enum';
import { IGroup } from "../../../../core/model/interface/group";
import { SUBJECTS_ID } from "../../../models/subjects";
import { Store } from "@ngrx/store";
import { getGroupAction, setCurrentGroupId } from "../../../../core/state/student/groups/groups.actions";
import { selectCurrentGroupWithDetails } from "../../../../core/state/student/groups/groups.selector";
import { Observable } from "rxjs";
import { StudentGroupThemeDTO } from "../../../../core/model";

@Component({
  selector: 'app-duty-card',
  templateUrl: './duty-card.component.html',
  styleUrls: ['./duty-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DutyCardComponent {
  @Input() role: Roles = Roles.Student;
  @Input() course!: IGroup;

  public subjectsId = SUBJECTS_ID;
  public RolesEnum = Roles;

  public currentGroup: StudentGroupThemeDTO | null | undefined;
  public currentGroupWithDetails$: Observable<StudentGroupThemeDTO | null | undefined> = this.store.select(selectCurrentGroupWithDetails);

  constructor(private store: Store) {}

  public openGroup(id: string): void {
    this.store.dispatch(setCurrentGroupId({id: id}));
    this.store.dispatch(getGroupAction({groupId: id, direction: 'ASC'}));
  }
}
