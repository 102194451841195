import { Pipe, PipeTransform } from '@angular/core';
import { BaseSubjects, ChoosingSubjects } from '../../models';

@Pipe({
  name: 'subjectColor',
  pure: false
})
export class SubjectColorPipe implements PipeTransform {

  constructor() {}
  
  transform(subjectId: string): string {
    switch(subjectId) {
      case ChoosingSubjects.Mathematics: 
        return 'rgba(247, 42, 165, 1)';
      case ChoosingSubjects.Physics: 
        return 'rgba(255, 150, 249, 1)'; 
      case ChoosingSubjects.Geography: 
        return 'rgba(255, 194, 48, 1)';
      case ChoosingSubjects.Chemistry: 
        return 'rgba(151, 71, 255, 1)';
      case ChoosingSubjects.Biology: 
        return 'rgba(0, 234, 255, 1)';
      case ChoosingSubjects.WorldHistory: 
        return 'rgba(36, 194, 99, 1)';
      case ChoosingSubjects.LawBasics: 
        return 'rgba(239, 62, 62, 1)';
      case ChoosingSubjects.EnglishLanguage: 
        return 'rgba(255, 27, 246, 1)';
      case ChoosingSubjects.RussianLanguage: 
        return 'rgba(255, 133, 21, 1)';
      case ChoosingSubjects.RussianLiterature: 
        return 'rgba(135, 171, 32, 1)';
      case ChoosingSubjects.KazakhLanguage: 
        return 'rgba(191, 89, 193, 1)';
      case ChoosingSubjects.KazakhLiterature: 
        return 'rgba(148, 73, 4, 1)';
      case ChoosingSubjects.Informatics: 
        return 'rgba(66, 123, 207, 1)';

      case BaseSubjects.ReadingLiteracy: 
        return 'rgba(0, 187, 153, 1)';
      case BaseSubjects.MathematicalLiteracy: 
        return 'rgba(212, 91, 53, 1)';
      case BaseSubjects.QazaqHistory: 
        return 'rgba(223, 255, 30, 1)';

      default: 
        return 'rgba(42, 96, 247, 1)';
    }
  }

}
