import { ToastrService } from 'ngx-toastr';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FilesService } from 'src/app/core/services/files/files.service';
import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Subscription, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploaderComponent{
  @Output() downloadedEvent = new EventEmitter<string>();
  @Output() removeEvent = new EventEmitter<string>();

  @Input() fileUrls: string[] = [];
  @Input() error: string = "";
  @Input() allowAdditionalFormats: string = "";
  @Input() isViewTaskMode: boolean = false;
  @Input() courseId: string | undefined;

  public dragging: boolean = false;
  isLoading: boolean = false;
  uploadProgress: number = 0;

  private acceptedTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf'];

  uploadSubscription$: Subscription | null = null;

  constructor(
    private filesService: FilesService,
    private cdr: ChangeDetectorRef,
    private toastrService: ToastrService,
    private httpClient: HttpClient,
    private translateService: TranslateService
  ) {}

  public onDragOver(event: Event): void {
      event.preventDefault();
      this.dragging = true;
  }
  public onDragLeave(): void {
    this.dragging = false;
  };

  public onDropSuccess(event: DragEvent): void {
    event.preventDefault();
    this.dragging = false;
    this.isLoading = true;
    const file = event.dataTransfer?.files;
    this.sendFile(file);
  }


  public onFileSelected(event: Event): void {
    // this.loadingService.showLoadingHomework();
    this.isLoading = true;
    const inputElement = event.target as HTMLInputElement;
    this.sendFile(inputElement.files);
    inputElement.value = '';
  }

  private sendFile(files: FileList | undefined |  null): void {
    if(files && files.length > 0) {
      const errorTranslate = this.translateService.instant('error');
      const maxFileSize: number = 100 * 1024 * 1024; //100mb
      if(files[0].size > maxFileSize) {
        this.uploadProgress = 0;
        this.isLoading = false;
        this.toastrService.error(this.translateService.instant('FILE_SIZE_MUST_BE_LESS_THAN'), errorTranslate);
        this.cdr.detectChanges();
        return;
      }
      let data: FormData = new FormData();
      data.append('material', files[0], files[0].name);
      this.uploadSubscription$ = this.filesService._sendFiles(data, this.courseId!)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            this.uploadProgress = 0;
            this.isLoading = false;
            this.cdr.detectChanges();
            this.toastrService.error(error?.error?.message, errorTranslate);
            return throwError(() => new Error(error.message));
          })
        )
        .subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            if (event.total) {
              this.uploadProgress = Math.round((100 * event.loaded) / event.total);
              this.cdr.detectChanges();
            }
          } else if (event.type === HttpEventType.Response) {
            this.isLoading = false;
            this.uploadProgress = 0;
            if (event.body) {
              // this.fileUrls.push(event.body.fileUrl);
              this.downloadedEvent.emit(event.body.fileUrl);
            }
            this.cdr.detectChanges();
          }
        }
      );
      // this.filesService.sendFiles(data).subscribe({
      //   next: (res) => {
      //     this.fileLinks.push(res.fileId);
      //     this.downloadedEvent.emit(res.fileId);
      //     this.loadingService.hideLoadingHomework();
      //     this.cdr.detectChanges();
      //   },
      //   error: (err) => {
      //     this.loadingService.hideLoadingHomework();
      //     this.cdr.detectChanges();
      //   },
      // });
    }
  }

  public deleteFile(index: number): void {
    this.removeEvent.emit(this.fileUrls[index]);
    // this.fileUrls.splice(index, 1);
  };

  downloadFile(url: string): Promise<Blob | undefined> {
    return this.httpClient.get(url, { responseType: 'blob' }).toPromise();
  }

  private extractFileNameFromUrl(url: string): string {
    // Extract the filename from the URL
    const urlParts = url.split('/');
    const fileName = urlParts[urlParts.length - 1];
    return fileName;
  }

  private changeUntilLastDot(inputString: string, replacement: string): string {
    // Find the index of the last dot in the input string
    const lastDotIndex = inputString.lastIndexOf('.');

    // If there's no dot in the string, return the original string
    if (lastDotIndex === -1) {
        return inputString;
    }

    // Extract the substring until the last dot (excluding)
    const substringUntilLastDot = inputString.slice(0, lastDotIndex);

    // Concatenate the substring with the replacement
    const result = substringUntilLastDot + replacement;

    return result;
  }

  getFileExtension(fileName: string): string {
    return fileName.split('.').pop()?.toLowerCase() || '';
  }

  handleCancel(): void {
    if (this.uploadSubscription$) {
      this.uploadSubscription$.unsubscribe();
      this.isLoading = false;
      this.uploadProgress = 0;
      this.uploadSubscription$ = null;
      console.log('Upload canceled');
    }
  }
}




