import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ForAnswerTestQuestions, MiniTestAnswer, QuizState, UserAnswer } from 'src/app/core/model';
import { QuestionsAmount } from 'src/app/shared/models';

interface CustomizeOptions extends MiniTestAnswer {
  isChecked: boolean
}
@Component({
  selector: 'app-multi-correct',
  templateUrl: './multi-correct.component.html',
  styleUrls: ['./multi-correct.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiCorrectComponent implements OnInit {
  public readonly _QuizState = QuizState;

  @Input() quizState: QuizState = QuizState.Passing;
  @Input() question!: ForAnswerTestQuestions;
  @Input() disabled: boolean = false;
  @Output() answeredEvent: EventEmitter<{ questionId: string, answer: UserAnswer[]}> = new EventEmitter<{ questionId: string, answer: UserAnswer[]}>();

  public userAnswer: string[] = [];
  public questionsAmount: QuestionsAmount = QuestionsAmount.Many;
  public customOptions: CustomizeOptions[] = [];

  ngOnInit(): void {
    this.question.answerList.map(item => this.customOptions.push({isChecked: false, ...item}));

    this.question.userAnswers?.map(item => {
      const index = this.customOptions.findIndex(option => option.id === item.answerId);
      this.customOptions[index].isChecked = true;
    });
  }

  public check(event: Event, index: number): void {
    if(this.quizState === QuizState.View) {
      event.preventDefault();
      return;
    }

    event.stopPropagation();
    this.customOptions[index].isChecked = !this.customOptions[index].isChecked;

    const getUserAnswered = (answer: CustomizeOptions):  UserAnswer | null => {
      if(answer.isChecked) {
        return {answerId: answer.id, answerTextValue: answer.answerText}
      }
      return null;
    }

    const answers: (UserAnswer | null)[] = this.customOptions.map(item => getUserAnswered(item));
    const filteredAnswers: UserAnswer[] = answers.filter((item): item is UserAnswer => item !== null);

    this.answeredEvent.emit({questionId: this.question.id, answer: filteredAnswers})
  }
  public ignoreClick(event: Event, index: number): void {
    if(this.quizState === QuizState.View) {
      event.preventDefault();
      return;
    }
    this.customOptions[index].isChecked = !this.customOptions[index].isChecked;
  }
}
