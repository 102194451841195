<button (click)="isViewMode=true"  *ngIf="image" [ngStyle]="{'background-image': 'url(' + image + ')'}" alt="" class="image" ></button>

<app-dialog [isOpen]="isViewMode" (closeEvent)="closeImageDialog()" [showButtons]="false">
    <div class="image-dialog">
        <img 
          class="image-dialog__img" 
          [src]="image" 
          alt=""
          [style.transform]="'scale(' + zoomLevel + ')'"
          [style.transform-origin]="transformOrigin"
          (wheel)="onScroll($event)"
          style="transition: transform 0.2s;">
      </div>
</app-dialog>
