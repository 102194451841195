<div class="sidebar-item">
  <button
    class="flex items-center justify-between w-100"
    (click)="toggleIsOpen()"
  >
    <div class="flex justify-between items-center">
      <img [src]="group?.imgSrc || './assets/svg/book.svg'" alt="" class="icon" />

      <div class="wrapper">
        <div class="label w-100">{{ (group?.courseName || '') | translate  }}</div>
        <div *ngIf="group?.streamMonth || group?.streamYear || group?.name"
             class="description">
          {{ group?.streamMonth ? (group!.streamMonth | monthConverter) : "-" }}
          {{ group?.streamYear ?? "-" }},
          {{ (group?.name || '') | translate }}
        </div>
      </div>
    </div>
    <img
      *ngIf="!group?.redirectUrl"
      src="./assets/svg/arrow-down.svg"
      alt=""
      class="arrow"
      [class.arrow_open]="isOpen"
    />
    <div *ngIf="taskCount" class="rounded">{{taskCount}}</div>
  </button>

  <ul class="body" [class.body_open]="isOpen">
    <li class="item">
      <button class="folders" (click)="openFolders()">
        <img src="assets/svg/circles/files-circle.svg" alt="" />
        <span class="folders-text">{{'COURSE_LESSON_TYPE' | translate}}</span>
        <app-primary-arrow class="max-left" [initialSlope]="90" [slopeDegree]="90"></app-primary-arrow>
      </button>
    </li>
    <li class="item">
      <a (click)="onLinkClick('RATING')" [routerLink]="[group?.id]">{{'LIST' | translate}}</a>
    </li>
    <li class="item">
      <a (click)="onLinkClick('RATING')" [routerLink]="[group?.id, 'rating']">
        {{'RATING' | translate}}
      </a>
    </li>
    <li class="item" *ngFor="let theme of themes">
      <a (click)="onLinkClick(theme.name)" [routerLink]="[group?.id, theme.id]">
        {{ theme.name }}
      </a>
    </li>
  </ul>
</div>
