import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit,} from '@angular/core';
import {FilesService} from 'src/app/core/services/files/files.service';
import {TranslateService} from "@ngx-translate/core";
import {Languages} from "../../../../shared/models";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-curator-info',
  templateUrl: './curator-info.component.html',
  styleUrls: ['./curator-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CuratorInfoComponent implements OnInit, OnDestroy {
  @Input() userInfo: any = {
    avatar: 'default',
    fullName: 'default',
    phone: 'default',
    instagram: 'default',
    email: null,
  };
  @Input() groups?: any[] | null;
  group = []

  currentLang: Languages = Languages.KZ;

  private languageChangeSubscription$!: Subscription;

  constructor(
    private _fileService: FilesService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.languageChangeSubscription$ = this.translateService.onLangChange.subscribe({
      next: ({lang}: any ) => {
        this.currentLang = lang;
      }
    });
  }

  public getImageUrl(path: string): string {
    return this._fileService.getImageUrl(path);
    return 'assets/img/default-ava.webp';
  }

  ngOnDestroy(): void {
    this.languageChangeSubscription$.unsubscribe();
  }
}
