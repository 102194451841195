import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NoopValueAccessorDirectiveDirective, injectNgControl } from 'src/app/shared/directives/noop-value-accessor-directive/noop-value-accessor-directive.directive';
import { SelectValue } from 'src/app/shared/models';
import { BorderType } from "../../../models/enums/form-field.enum";

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  hostDirectives: [NoopValueAccessorDirectiveDirective],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectComponent {
  ngControl = injectNgControl();

  @Input() name: string = "";
  @Input() isActive: boolean = true;
  @Input() options: SelectValue[] = [];
  @Input() error: string = "";
  @Input() placeholder ? : string
  @Input() isLoading? = false;
  @Input() borderType: BorderType = BorderType.Outline;
  @Output() selectedValue: EventEmitter<string | number> = new EventEmitter<string | number>();

  public focused: boolean = false;

  onFocus(): void {
    this.focused = true;
  };
  onBlur(): void {
    this.focused = false;
  };
  onSelect(event: Event): void {
    const target = event.target as HTMLSelectElement;
    this.selectedValue.emit(target.value);
  }
}
