import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-five-stars',
  templateUrl: './five-stars.component.html',
  styleUrls: ['./five-stars.component.scss']
})
export class FiveStarsComponent implements OnInit {
  public selectedStar: number = 0;

  constructor() { }

  ngOnInit() {
  }

  public setRate(index: number = 0): void {
    this.selectedStar = index + 1;
  }
}
