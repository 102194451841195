import { StudentService } from 'src/app/core/services/student/student.service';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { FormBuilder, FormGroup} from '@angular/forms';
import { FilesService } from './../../../../core/services/files/files.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UsefullLink } from 'src/app/core/model';
import { environment } from 'src/environment';

interface Materials {
  id: string,
  link: string,
  active: boolean
};

@Component({
  selector: 'app-study-material-holder',
  templateUrl: './study-material-holder.component.html',
  styleUrls: ['./study-material-holder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudyMaterialHolderComponent implements OnChanges {
  @Input() name: string = "";
  @Input() required: boolean = false;
  @Input() allowed: boolean = true;
  @Input() filesId: string[] | undefined = [];
  @Input() viewedMaterials: string[] = [];
  @Input() links: UsefullLink[] = [];
  @Input() isViewModeTask: boolean = false;

  @Input() groupId: string | undefined;
  @Input() lessonId: string | undefined;

  public materials: Materials[] = [];

  public form!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private filesService: FilesService,
    private cdr: ChangeDetectorRef,
    private helperService: HelperService,
    private studentService: StudentService
  ) {
    this.form = this.formBuilder.group({
      toggler: [false]
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.filesId && this.filesId.length > 0 && this.viewedMaterials) {
      this.materials = [];
      for(let id of this.filesId || []) {
        this.materials.push({
          id: id,
          link: environment.filesUrl + id,
          active: this.viewedMaterials.indexOf(id) > -1,
        });
      }
    };

    console.log("links : ",this.links)
  }
  public setReviewedMaterials(id: string): void {
    const material = this.materials.find(m => m.id === id);
    if (material) {
      if(material.active) {
        this.studentService.unviewMaterial(this.groupId || '', this.lessonId || '', material.id).subscribe(res => {
          material.active = false;
        })
      }
      else {
        this.studentService.viewMaterial(this.groupId || '', this.lessonId || '', material.id).subscribe(res => {
          material.active = true;
        })
      }
    }
  }

  public openFile(link: string): void {
    window.open(link, '_blank')
  }
}
