import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { YouTubePlayer } from '@angular/youtube-player';
import { VideoDTO } from 'src/app/shared/models';

@Component({
  selector: 'app-youtube-player',
  templateUrl: './youtube-player.component.html',
  styleUrls: ['./youtube-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YoutubePlayerComponent implements OnChanges, AfterViewInit {
  @ViewChild('iframe') iframe!: ElementRef<HTMLDivElement>;
  @ViewChild('youtubePlayer') youtubePlayer!: YouTubePlayer;

  
  @Input() videoUrl: string | undefined;
  @Input() aspectRation: number = 0.6;
  public videoId: string | null = null;

  public videoHeight: number | undefined;
  public videoWidth: number | undefined;
  @Output() sendPlayed: EventEmitter<VideoDTO> = new EventEmitter<VideoDTO>();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['videoUrl'] && this.videoUrl) {
      this.addRequirements();
      this.videoId = this.extractVideoId(this.videoUrl);
    }
  }
  ngAfterViewInit(): void {
    this.onResize();
    window.addEventListener('resize', this.onResize.bind(this));

    setInterval(() => {
      const videoData: VideoDTO = {
        duration: this.youtubePlayer.getDuration(),
        timings: [[0,this.youtubePlayer.getCurrentTime()]],
        percent: this.youtubePlayer.getCurrentTime()/this.youtubePlayer.getDuration()
      }
      this.sendPlayed.emit(videoData)
    }, 2000)
  }

  private extractVideoId(url: string): string | null {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : null;
  } 

  private addRequirements(): void {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
  }

  private onResize(): void {
    // you can remove this line if you want to have wider video player than 1200px
  this.videoWidth = Math.min(
    this.iframe.nativeElement.clientWidth,
    2000
  );
      // so you keep the ratio
  this.videoHeight = this.videoWidth * this.aspectRation;
  this.cdr.detectChanges();
  }
}
