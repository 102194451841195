import { StorageService } from 'src/app/core/services/storage/storage.service';
import { Store } from '@ngrx/store';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { EMPTY_VALUE } from '../../../consts/common';
import { Observable, Subscription, switchMap } from 'rxjs';
import { IPayment } from 'src/app/core/model';
import { selectUserPayment } from 'src/app/core/state/student/user/user.selector';
import { getUserPaymentHistoryAction } from 'src/app/core/state/student/user/user.actions';

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentInfoComponent implements OnInit, OnDestroy, OnChanges {
  public EMPTY = EMPTY_VALUE;
  @Input() paymentInfo?: {
    fio: string;
    email: string;
    date: string;
    price: string;
    manager?: string;
    courses?: {
      name: string;
      date: string;
      waLink: string;
    }[];
  };

  private paymentInfo$: Observable<IPayment> = this.store.select(selectUserPayment);
  private paymentInfoSubcription!: Subscription;

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef,
    private storageService: StorageService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    // Check if the input property paymentInfo has changed
    if (changes['paymentInfo'] && changes['paymentInfo'].currentValue) {
      // If it has changed, update the local paymentInfo
      this.paymentInfo = changes['paymentInfo'].currentValue;
      this.cdr.detectChanges();
    }
  }

  ngOnInit(): void {
    // Dispatch the action to get user payment history
    this.store.dispatch(getUserPaymentHistoryAction());

    if (this.storageService.getToken()) {
      this.paymentInfoSubcription = this.paymentInfo$
        .pipe(
          // Use switchMap to handle asynchronous requests and update paymentInfo
          switchMap((res) => {
            this.paymentInfo = res;
            // Manually trigger change detection
            this.cdr.detectChanges();
            // Return an observable with no data to keep the subscription open
            return this.EMPTY;
          })
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    if (this.paymentInfoSubcription) {
      this.paymentInfoSubcription.unsubscribe();
    }
  }
}