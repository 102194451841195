<ng-container *ngIf="paymentInfo">
  <div class="bg-white flex flex-col border items-center radius-16">
    <div class="content">
      <div class="info-item item">
        <div class="item__label">{{ "lastnameAndFirstname" | translate }}</div>
        <div class="item__value">{{ paymentInfo.fio }}</div>
      </div>
      <div class="info-item item">
        <div class="item__label">{{ "email" | translate }}</div>
        <div class="item__value">{{ paymentInfo.email }}</div>
      </div>
      <div class="info-item item">
        <div class="item__label">{{ "paymentDate" | translate }}</div>
        <div class="item__value">{{ paymentInfo.date }}</div>
      </div>
      <div class="info-item item">
        <div class="item__label">{{ "paid" | translate }}</div>
        <div class="item__value">{{ paymentInfo.price }}</div>
      </div>
      <div class="info-item item" *ngIf="paymentInfo.manager">
        <div class="item__label">{{ "yourManager" | translate }}</div>
        <div class="item__value">{{ paymentInfo.manager }}</div>
      </div>

      <ng-container *ngIf="paymentInfo?.courses">
        <div class="courses">
          <div class="rowh">
            <div class="thh name">{{ "course" | translate }}</div>
            <div class="thh date">{{ "startDate" | translate }}</div>
            <div class="thh wlink">{{ "chat" | translate }}</div>
          </div>

          <ng-container *ngFor="let item of paymentInfo.courses">
            <div class="rowb">
              <div class="tdd name">{{ item.name }}</div>
              <div class="tdd date">{{ item.date }}</div>
              <div class="tdd wlink">
                <ng-container *ngIf="item.waLink !== EMPTY">
                  <img src="assets/svg/whatsapp.svg" alt="Whatsapp logo" />

                  <a class="wlink__text" [href]="item.waLink" target="_blank">{{
                    "chatLink" | translate
                  }}</a>
                </ng-container>
                <ng-container *ngIf="item.waLink === EMPTY">
                  {{ EMPTY }}
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
