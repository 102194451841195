import { AuthorizedGuard } from './core/guards/authorized/authorized.guard';
import { AutorizationGuard } from './core/guards/authorization/autorization.guard';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { RolesGuard } from './core/guards/roles/roles.guard';
import { NgModule } from '@angular/core';
import { BaseRoutes, CommonRoutes } from './core/model';
import { ResetPasswordComponent } from './core/pages/reset-password/reset-password.component';
import { ShorturlComponent } from './core/pages/shorturl/shorturl.component';
import { CustomPreloadingStrategy } from './shared/services/customPreloadStrategy/custom-preload-strategy';
import {
  TechnicalWorksPageComponent
} from "./shared/components/pages/technical-works-page/technical-works-page.component";

const routes: Routes = [
  // { path: '', redirectTo: `${false}`, pathMatch: 'full'},
  {
    path: `${BaseRoutes.Catalogues}`,
    loadChildren: () =>
      import('./core/pages/catalogues/catalogues.module').then(
        (m) => m.CataloguesModule
      ),
  },
  {
    path: `${BaseRoutes.CataloguesWithOpenBasket}`,
    loadChildren: () =>
      import('./core/pages/catalogues/catalogues.module').then(
        (m) => m.CataloguesModule
      ),
  },
  {
    path:  `${BaseRoutes.MobileApp}`,
    loadChildren: () =>
      import('./core/pages/catalogues/catalogues.module').then(
        (m) => m.CataloguesModule
      ),
  },
  {
    path:  `${BaseRoutes.MobileApp}/${BaseRoutes.CataloguesWithOpenBasket}`,
    loadChildren: () =>
      import('./core/pages/catalogues/catalogues.module').then(
        (m) => m.CataloguesModule
      ),
  },
  {
    path: `${CommonRoutes.Authorization}`,
    canActivate: [AuthorizedGuard],
    loadChildren: () =>
      import('./core/pages/authorization/authorization.module').then(
        (m) => m.AuthorizationModule
      ),
  },
  {
    path: `${CommonRoutes.ShortURL}/:id`,
    component: ShorturlComponent,
  },
  {
    path: `${CommonRoutes.Registration}/:registrationToken`,
    loadChildren: () =>
      import('./core/pages/registration/registration.module').then(
        (m) => m.RegistrationModule
      ),
  },
  {
    path: `${CommonRoutes.ResetPassword}/:token`,
    component: ResetPasswordComponent
  },
  {
    path: `${BaseRoutes.Student}/${CommonRoutes.Registration}/:registrationToken`,
    loadChildren: () =>
      import('./core/pages/student/registration/registration.module').then(
        (m) => m.RegistrationModule
      ),
  },
  {
    path: `${BaseRoutes.Student}`,
    canActivate: [AutorizationGuard, RolesGuard],
    loadChildren: () =>
      import('./core/pages/student/student.module').then(
        (m) => m.StudentModule
      ),
  },
  {
    path: `${BaseRoutes.Methodist}`,
    canActivate: [AutorizationGuard, RolesGuard],
    loadChildren: () =>
      import('./core/pages/methodist/methodist.module').then(
        (m) => m.MethodistModule
      ),
  },
  {
    path: `${BaseRoutes.Curator}`,
    canActivate: [AutorizationGuard, RolesGuard],
    loadChildren: () =>
      import('./core/pages/curator/curator.module').then(
        (m) => m.CuratorModule
      ),
  },
  {
    path: `${BaseRoutes.Admin}`,
    canActivate: [AutorizationGuard, RolesGuard],
    loadChildren: () =>
      import('./core/pages/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: `${BaseRoutes.HeadTeacher}`,
    canActivate: [AutorizationGuard, RolesGuard],
    loadChildren: () =>
      import('./core/pages/head-teacher/head-teacher.module').then(
        (m) => m.HeadTeacherModule
      ),
  },
  {
    path: `${BaseRoutes.Manager}`,
    canActivate: [AutorizationGuard, RolesGuard],
    loadChildren: () =>
      import('./core/pages/manager/manager.module').then((m) => m.ManagerModule),
  },
  {
    path: `${BaseRoutes.Docx}`,
    loadChildren: () =>
      import('./core/pages/docx/docx.module').then((m) => m.DocxModule)
  },
  {
    path: `${BaseRoutes.Announcements}`,
    loadChildren: () =>
      import('./core/pages/announcements/announcements.module').then((m) => m.AnnouncementsModule)
  },
  {
    path: 'system-error',
    component: TechnicalWorksPageComponent
  }
];

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
  preloadingStrategy: CustomPreloadingStrategy
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
  providers: [CustomPreloadingStrategy],
})
export class AppRoutingModule {
}
