import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, SimpleChanges, OnChanges, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatchingDTO, TestQuestionCustom } from 'src/app/core/model';
import { debounceTime, distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { getFormControlErrors } from 'src/app/shared/validators';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-matching-test-controller',
  templateUrl: './matching-test-controller.component.html',
  styleUrls: ['./matching-test-controller.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatchingTestControllerComponent implements OnInit, OnChanges, OnDestroy{
  @Input() item!: MatchingDTO;
  @Output() removeEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() saveEvent: EventEmitter<TestQuestionCustom> = new EventEmitter<TestQuestionCustom>();

  private eventsSubscription!: Subscription;
  @Input() saveEventSubject!: Observable<number>;

  @Input() index!: number;
  @Input() withQuestionText: boolean = true;
  @Output() validQuestionStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

  public form!: FormGroup;
  public formErrors = {
    questionText: "",
  };

  public errorVariantText: string[] = [];

  public minimumAmoutOfVariants: number = 3;

  constructor(
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {
    this.form = formBuilder.group({
      questionText: ['', Validators.required],
      questions: formBuilder.array([])
    });

    this.form.statusChanges.subscribe((status) => {
      const anyCustomError: boolean = this.errorVariantText.every(element => element === "");
      this.validQuestionStatus.emit((status === 'VALID' && anyCustomError) ? true : false);
    });

    this.form.valueChanges.subscribe(res => {
      this.errorVariantText = [];
      for(let item of res.questions) {
        if(!item.key || !item.value) {
          this.errorVariantText.push('ADD_ANSWER_OPTIONS')
        }
        else {
          this.errorVariantText.push('')
        }
      }

      const findOriginalAndDuplicateIndexes = (arr: any[]) => {
        const seen = new Map();
        const result: any[] = [];

        arr.forEach((item, index) => {
            const { value, key } = item;

            // Ignore if both key and value are empty
            if (value === "" && key === "") return;

            const identifier = `${value}_${key}`;

            if (seen.has(identifier)) {
                seen.get(identifier).push(index);
            } else {
                seen.set(identifier, [index]);
            }
        });

        // Filter to only include groups with duplicates
        seen.forEach((indexes) => {
            if (indexes.length > 1) {
                result.push(indexes);
            }
        });

        return result;
      };
    
      const originalAndDuplicateIndexes = findOriginalAndDuplicateIndexes(res.questions);
      console.log('originalAndDuplicateIndexes : ',originalAndDuplicateIndexes)
      if(originalAndDuplicateIndexes.length > 0) {
        for(let elements of originalAndDuplicateIndexes) {
          for(let index of elements) {
            this.errorVariantText[index] = "Дубликат"
          }
        }
      }
    })
  };

  ngOnInit(): void {
    if(this.saveEventSubject) {
      this.eventsSubscription = this.saveEventSubject.subscribe((index: number) => {
        if(index === this.index) {
          const data = this.form.value.questions.reduce(
            (result: any, item : any) => {
              result.keyList.push(item.key),
              result.valueList.push(item.value);
              return result;
            },
            { questionText: this.form.get('questionText')?.value, keyList: [], valueList: [] }
          )
          this.saveEvent.emit(data);
        }
      });
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['item'] && changes['item'].firstChange) {
      if(this.item.keyList && this.item.keyList.length > 0) {
        this.questions.clear();
        this.form.patchValue({ questionText: this.item.questionText || ''});
        for(let i = 0; i < this.item.keyList.length; i++) {
          const itemGroup = this.formBuilder.group({
            key: [this.item.keyList[i], Validators.required],
            value: [this.item.valueList![i], Validators.required],
          });
          this.questions.push(itemGroup);
        }
      }
      else {
        this.formErrors.questionText = "REQUIRED_FIELD";
        for(let i = 0; i < this.minimumAmoutOfVariants; i++) {
          this.addQuestion();
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.eventsSubscription.unsubscribe();
  }

  public handleFormControlErrors(form: FormGroup, error: any, controlName: string): void {
    getFormControlErrors(form, error, controlName, this.translateService);
    this.cdr.detectChanges();
  }

  get questions(): FormArray {
    return this.form.controls['questions'] as FormArray;
  }

  public addQuestion(key: string = "", value: string = ""): void {
    const question =  this.formBuilder.group({
      value: ['', Validators.required],
      key: ['',  Validators.required]
    });
    this.questions.push(question);
  };
  public removeQuestion(index: number): void {
    this.questions.removeAt(index);
  }

  public drop(event: CdkDragDrop<string>): void {
    moveItemInArray(this.questions.controls, event.previousIndex, event.currentIndex)
  }

}
