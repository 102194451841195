import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { SubjectDTO } from '../../model/interface/subject';
import { HttpClient } from '@angular/common/http';
import {
  BaseSubjects,
  ChoosingSubjects,
  Languages,
  SelectValue,
} from 'src/app/shared/models';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SubjectsService {
  private language: Languages = Languages.RU;

  public subjectsList: SubjectDTO[] = [];

  constructor(
    private httpClient: HttpClient,
    private translate: TranslateService
  ) {
    this.language = translate.getDefaultLang() as Languages;
  }

  public getSubjectsList(): Observable<SelectValue[]> {
    return this.httpClient.get<SubjectDTO[]>(`/v1/subjects`).pipe(
      map((res: SubjectDTO[]) => {
        const subjectsList: SelectValue[] = [];
        for (const subject of res) {
          const translations = this.subjectTranslations[subject.id];
          if (translations) {
            const subjectName =
              translations[
                (this.translate.getDefaultLang() as Languages) === Languages.RU
                  ? Languages.RU
                  : Languages.KZ
              ]; // Retrieve the subject name in Russian
            subjectsList.push({
              name: subjectName,
              value: subject.id,
            });
          }
        }
        return subjectsList;
      })
    );
  }

  public getRegSubjectsList(): Observable<SelectValue[]> {
    return this.httpClient.get<SubjectDTO[]>(`/v1/subjects`).pipe(
      map((res: SubjectDTO[]) => {
        const subjectsList: SelectValue[] = [];
        for (const subject of res) {
          if (
            !Object.values(BaseSubjects).includes(subject.id as BaseSubjects)
          ) {
            const translations = this.subjectTranslations[subject.id];
            if (translations) {
              const subjectName =
                translations[
                  (this.translate.getDefaultLang() as Languages) ===
                  Languages.RU
                    ? Languages.RU
                    : Languages.KZ
                ]; // Retrieve the subject name in Russian
              subjectsList.push({
                name: subjectName,
                value: subject.id,
              });
            }
          }
        }

        return subjectsList;
      })
    );
  }

  public getSubjectsListNotMapped(): Observable<SubjectDTO[]> {
    return this.httpClient.get<SubjectDTO[]>(`/v1/subjects`)
  }

  private subjectRules: { [key: string]: string[] } = {
    [ChoosingSubjects.Mathematics]: [
      ChoosingSubjects.Physics,
      ChoosingSubjects.Geography,
      ChoosingSubjects.Informatics,
    ],
    [ChoosingSubjects.Physics]: [
      ChoosingSubjects.Mathematics,
      ChoosingSubjects.Chemistry,
    ],
    [ChoosingSubjects.Geography]: [
      ChoosingSubjects.Mathematics,
      ChoosingSubjects.Biology,
      ChoosingSubjects.WorldHistory,
      ChoosingSubjects.EnglishLanguage,
    ],
    [ChoosingSubjects.Chemistry]: [
      ChoosingSubjects.Physics,
      ChoosingSubjects.Biology,
    ],
    [ChoosingSubjects.Biology]: [
      ChoosingSubjects.Chemistry,
      ChoosingSubjects.Geography,
    ],
    [ChoosingSubjects.WorldHistory]: [
      ChoosingSubjects.Geography,
      ChoosingSubjects.LawBasics,
      ChoosingSubjects.EnglishLanguage,
    ],
    [ChoosingSubjects.LawBasics]: [ChoosingSubjects.WorldHistory],
    [ChoosingSubjects.EnglishLanguage]: [
      ChoosingSubjects.WorldHistory,
      ChoosingSubjects.Geography,
    ],
    [ChoosingSubjects.CreativeExam]: [ChoosingSubjects.CreativeExam],
    [ChoosingSubjects.RussianLanguage]: [ChoosingSubjects.RussianLiterature],
    [ChoosingSubjects.RussianLiterature]: [ChoosingSubjects.RussianLanguage],
    [ChoosingSubjects.KazakhLanguage]: [ChoosingSubjects.KazakhLiterature],
    [ChoosingSubjects.KazakhLiterature]: [ChoosingSubjects.KazakhLanguage],
    [ChoosingSubjects.Informatics]: [ChoosingSubjects.Mathematics],
  };

  public subjectTranslations: {
    [key: string]: { [key in Languages]: string };
  } = {
    [ChoosingSubjects.Mathematics]: {
      [Languages.KZ]: 'Математика',
      [Languages.RU]: 'Математика',
    },
    [ChoosingSubjects.Physics]: {
      [Languages.KZ]: 'Физика',
      [Languages.RU]: 'Физика',
    },
    [ChoosingSubjects.Geography]: {
      [Languages.KZ]: 'География',
      [Languages.RU]: 'География',
    },
    [ChoosingSubjects.Geometry]: {
      [Languages.KZ]: 'Геометрия',
      [Languages.RU]: 'Геометрия',
    },
    [ChoosingSubjects.Chemistry]: {
      [Languages.KZ]: 'Химия',
      [Languages.RU]: 'Химия',
    },
    [ChoosingSubjects.Biology]: {
      [Languages.KZ]: 'Биология',
      [Languages.RU]: 'Биология',
    },
    [ChoosingSubjects.WorldHistory]: {
      [Languages.KZ]: 'Дүниежүзі тарихы',
      [Languages.RU]: 'Всемирная история',
    },
    [ChoosingSubjects.LawBasics]: {
      [Languages.KZ]: 'Құқық',
      [Languages.RU]: 'Основы права',
    },
    [ChoosingSubjects.EnglishLanguage]: {
      [Languages.KZ]: 'Ағылшын тілі',
      [Languages.RU]: 'Английский язык',
    },
    [ChoosingSubjects.CreativeExam]: {
      [Languages.KZ]: 'Шығармашылық емтихан',
      [Languages.RU]: 'Творческий экзамен',
    },
    [ChoosingSubjects.RussianLanguage]: {
      [Languages.KZ]: 'Орыс тілі',
      [Languages.RU]: 'Русский язык',
    },
    [ChoosingSubjects.RussianLiterature]: {
      [Languages.KZ]: 'Орыс әдебиеті',
      [Languages.RU]: 'Русская литература',
    },
    [ChoosingSubjects.KazakhLanguage]: {
      [Languages.KZ]: 'Қазақ тілі',
      [Languages.RU]: 'Казахский язык',
    },
    [ChoosingSubjects.KazakhLiterature]: {
      [Languages.KZ]: 'Қазақ әдебиеті',
      [Languages.RU]: 'Казахская литература',
    },
    [ChoosingSubjects.Informatics]: {
      [Languages.KZ]: 'Информатика',
      [Languages.RU]: 'Информатика',
    },
    [BaseSubjects.MathematicalLiteracy]: {
      [Languages.KZ]: 'Математикалық сауаттылық',
      [Languages.RU]: 'Математическая грамотность',
    },
    [BaseSubjects.QazaqHistory]: {
      [Languages.KZ]: 'Қазақстан тарихы',
      [Languages.RU]: 'История Казахстана',
    },
    [BaseSubjects.ReadingLiteracy]: {
      [Languages.KZ]: 'Оқу сауаттылығы',
      [Languages.RU]: 'Грамотность чтения',
    },
  };

  public getAvailableSubjects(selectedSubject: string): SelectValue[] {
    const availableSubjects: SelectValue[] = [];

    const selectedSubjectTranslations =
      this.subjectTranslations[selectedSubject];
    if (!selectedSubjectTranslations) {
      return availableSubjects;
    }

    const availableSubjectIds = this.subjectRules[selectedSubject];
    if (!availableSubjectIds) {
      return availableSubjects;
    }

    for (const availableSubjectId of availableSubjectIds) {
      const availableSubjectTranslations =
        this.subjectTranslations[availableSubjectId];
      if (!availableSubjectTranslations) {
        continue;
      }

      const translation = availableSubjectTranslations[this.language];
      if (translation) {
        const selectValue: SelectValue = {
          name: translation,
          value: String(availableSubjectId),
        };
        availableSubjects.push(selectValue);
      }
    }

    return availableSubjects;
  }

  public getMethodistSubjects(): Observable<SelectValue[]> {
    return this.httpClient.get<SubjectDTO[]>('/v1/methodist/subjects/my').pipe(
      map((res: SubjectDTO[]) => {
        const subjectsList: SelectValue[] = [];
        if (res?.length) {
          res.push({
            bannerUrl: "https://banner.kz/his",
            color: "#24c263",
            id: "892f5c1e-ab00-496a-a08e-a877c2a28264",
            nameKz: "Оқу сауаттылығы",
            nameRu: "Грамотность чтения"
          });
        }
        for (const subject of res) {
          const translations = this.subjectTranslations[subject.id];
          if (translations) {
            const subjectName =
              translations[
                (this.translate.getDefaultLang() as Languages) === Languages.RU
                  ? Languages.RU
                  : Languages.KZ
              ]; // Retrieve the subject name in Russian
            subjectsList.push({
              name: subjectName,
              value: subject.id,
            });
          }
        }
        return subjectsList;
      })
    );
  }
}
