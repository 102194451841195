<div class="container border radius-8">
  <div
    class="students-list-item flex items-center justify-between"
    [ngClass]="{'selected-student': isSelectedStudent}"
  >
    <div class="wrapper flex items-center">
      <div class="avatar">
        <ng-container *ngIf="student?.studentPhotoUrl; else defaultPhoto">
          <img
            class="student__avatar-img"
            [src]="getImageUrl()"
            alt="student avatar"
          />
        </ng-container>
        <ng-template #defaultPhoto>
          <div class="no-photo">{{ (student && student.studentFirstname) ? student.studentFirstname[0] : ''}}</div>
        </ng-template>
      </div>
      <div class="name" (click)="openStudentCard()">{{ student?.studentFirstname }} {{ student?.studentLastname }}</div>
    </div>
    <div class="wrapper flex items-center radius-6">
      <div *ngIf="isLate || showComment || student?.status === 'SUCCESS' || student?.status === 'IN_REVIEW'"
          class="flex gap-12 mobile-reverse items-center">
        <img *ngIf="isLate" src="./assets/svg/timer-filled.svg" alt="" />
        <div *ngIf="showComment" [class.unread]="student?.unreadComments">
          <img src="./assets/svg/comment.svg" alt="" />
        </div>

        <div
          *ngIf="student?.status === 'SUCCESS' && (lesson?.maxScore || lesson?.maxScore === 0)"
          class="grade flex items-center radius-6"
        >
          <img src="./assets/svg/informative/base-verified.svg" alt="" class="icon" />
          <div class="value">Балл: {{ student?.score | localizedNumberFormat }}</div>
        </div>

        <div
          *ngIf="student?.status === 'SUCCESS' && (!lesson?.maxScore && lesson?.maxScore !== 0)"
          class="grade flex items-center radius-6"
        >
          <div class="not-checked value">{{'VERIFIED' | translate}}</div>
        </div>

<!--        <div-->
<!--          *ngIf="student?.status === 'IN_REVIEW'"-->
<!--          class="grade flex items-center radius-6"-->
<!--        >-->
<!--          <div class="not-checked value">{{'NOT_VERIFIED' | translate}}</div>-->
<!--        </div>-->
      </div>

      <button class="detail"
              (click)="onDetailButtonClick()"
              [title]="student?.status">
<!--        {{'detailed' | translate}}-->
      </button>
    </div>
  </div>

  <div *ngIf="curatorCommented" class="flex items-center curator-comment">
    <div class="shown-curator-comment">
      <div class="test-1">
        <img src="assets/svg/ChatCircleDots.svg" alt="" />
        <p
          [ngStyle]="{'width': isShowMoreShown && !isShowMoreActive ? width-240 + 'px' : '100%', 'white-space': isShowMoreShown && !isShowMoreActive ? 'nowrap' : 'normal'}"
          class="curator-text" [innerHTML]="lastCuratorComment"></p>
        <button (click)="open()" *ngIf="isShowMoreShown && !isShowMoreActive" class="show-more">Показать больше</button>
      </div>
      <button (click)="close()"   *ngIf="isShowMoreShown && isShowMoreActive" class="show-more show-more-hide">скрыть</button>
    </div>
  </div>
</div>
