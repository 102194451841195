import { UsersService } from '../../../../core/services/users/users.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, forkJoin, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { Roles } from 'src/app/core/model';
import { HeadTeacherService } from 'src/app/core/services/head-teacher/head-teacher.service';
import { ICdkSelectOption } from 'src/app/shared/components/material/cdk-select/_model';
import { SelectValue } from 'src/app/shared/models';
import { ToastService } from 'src/app/shared/services/toast/toast.service';
import { AdminService } from "../../../../core/services/admin/admin.service";

@Component({
  selector: 'app-distributed-groups-of-stream',
  templateUrl: './distributed-groups-of-stream.component.html',
  styleUrls: ['./distributed-groups-of-stream.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DistributedGroupsOfStreamComponent implements OnInit {
  @Input() streamId!: string;
  @Input() subjectId: string = '';
  @Output() onRowClickedEvent: EventEmitter<{event: Event, groupId: string}> = new EventEmitter<{event: Event, groupId: string}>();

  public coursesOption: ICdkSelectOption[][] = [[]];
  public curatorOption: ICdkSelectOption[][] = [];
  public curatorOption2: SelectValue[][] = [[]];
  public coursesOptionCopy: ICdkSelectOption[][] = [[]];
  public curatorOptionCopy: ICdkSelectOption[][] = [[]];
  public curatorOption2Copy: SelectValue[][] = [];

  public groupsList: any[] = [];
  public groupsForm!: FormGroup;

  public loading: boolean = true;
  public isModalOpen: boolean = false;
  public disableBtn: boolean = false;
  private selectedIndex: number = 0;

  private destroy$ = new Subject<void>();

  constructor(
    private headteacherService: HeadTeacherService,
    private cdr: ChangeDetectorRef,
    private userService: UsersService,
    private ns: ToastService,
    private formBuilder: FormBuilder,
    private adminService: AdminService
  ) {
    this.groupsForm = this.formBuilder.group({
      list: this.formBuilder.array([])
    });
  }

  ngOnInit(): void {
    this.getGroups();
  }

  get groups(): FormArray {
    return this.groupsForm.controls['list'] as FormArray;
  }

  private getGroups(): void {
    this.headteacherService.getGroupsByStreamId(this.streamId)
      .pipe(
        tap((response) => {
          this.groupsList = response;
          for(let item of response) {
            let form = this.formBuilder.group({
              group: [item.name],
              course: [item.courseId],
              curator: [item.curator ? item.curator.id : ''],
              studentCount: [item.studentCount],
              addCurator: [item.additionalCurators.filter((item: { id: any; }) => item.id).map((item: { id: any; }) => item.id)]
            });
            this.groups.push(form);
          }
          // formArray.forEach((item: FormGroup) => {
          //   this.groups.push(item);
          // });
        }),
        switchMap(_ => {
          return forkJoin([
            this.headteacherService.getCoursesBySubjectId(this.subjectId),
            this.userService.getAllUsersByRole([Roles.Curator])
          ])
        })
      )
      .subscribe({
        next: ([courses, curators]) => {
          this.changeGroup();
          this.getCourses(courses);
          this.getCurators(curators);
          this.cdr.detectChanges();
        },
      });
  }

  private changeGroup(): void {
    this.groups.controls.forEach((control: AbstractControl, index: number) => {
      if (control instanceof FormGroup) {
        control.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((changedValues) => {

          const data = {
            name: changedValues.group,
            courseId: changedValues.course,
            curatorId: changedValues.curator,
            additionalCuratorIds: changedValues.addCurator
          };

          const groupId = this.groupsList[index].id;

          this.headteacherService.updateGroup(groupId, data).subscribe({
            next: _ => {
              this.ns.showSuccess(`Группа ${groupId} изменена`);
              this.cdr.detectChanges();
            },
            error: (err) => this.ns.showError(err),
          });

          this.cdr.detectChanges();
        });
      }
    });
  }

  private getCourses(response: any): void {
    const courseOption = response.map((course: any) => ({
      viewValue: course.name,
      value: course.id,
    }));
    this.coursesOption = Array(this.groups.length).fill(courseOption);
    this.coursesOptionCopy = [...this.coursesOption];
    this.cdr.detectChanges();
  }

  private getCurators(response: any): void {
    const curatorOption = response.map((curator: any) => ({
      viewValue: `${curator.firstname} ${curator.lastname}`,
      value: curator.id,
    }));
    const curatorOption2 = response.map((curator: any) => ({
      name: `${curator.firstname} ${curator.lastname}`,
      value: curator.id,
    }));
    this.curatorOption = Array(this.groups.length).fill(curatorOption);
    this.curatorOption2 = Array(this.groups.length).fill(curatorOption2);

    this.curatorOptionCopy = [...this.curatorOption];
    this.curatorOption2Copy = [...this.curatorOption2];
    this.loading = false;
    this.cdr.detectChanges();
  }

  public onCuratorSearchInputChange(search: string, index: number): void {
    // this.userService.getAllUsersByRole(['CURATOR'], search).subscribe({
    //   next: (response) => {
    //     this.curatorOption[index] = response.map((curator) => ({
    //       name: `${curator.firstname} ${curator.lastname}`,
    //       value: curator.id,
    //     }));
    //     this.cdr.detectChanges();
    //   },
    // });
  }

  public onStudentGroupChange(fromGroupId: string, toGroupId: string, student: any): void {
    this.headteacherService
      .switchStudentGroup(this.streamId, student.id, {fromGroup: fromGroupId, toGroup: toGroupId})
      .subscribe({
        next: (res) => {
          this.ns.showSuccess('Студент успешно переведен');
          this.getGroups();
          this.cdr.detectChanges();
        },
        error: (err) => {
          this.ns.showError('Произошла ошибка');
        },
      });
  }

  public onRowClick(event: Event, index: number): void {
    this.onRowClickedEvent.emit({event: event, groupId: this.groupsList[index].id});
  }

  public courseSelected($event: any, index: number): void {
    // this.coursesOption = this.coursesOptionCopy.filter(item => item)
    const groupToUpdate = this.groups.at(index);
    groupToUpdate.patchValue({ course: $event });
  }
  public curatorSelected($event: any, index: number): void {
    const groupToUpdate = this.groups.at(index);
    groupToUpdate.patchValue({ curator: $event });
  }

  public curator2Selected($event: any, index: number): void {
    const groupToUpdate = this.groups.at(index);
    groupToUpdate.patchValue({ addCurator: $event });
  }

  public courseSearching($event: any, index: number): void {
    this.coursesOption[index] = this.coursesOptionCopy[index].filter(item => item.viewValue.toLowerCase().includes($event.toLowerCase()));
  }

  public curatorSearching($event: any, index: number): void {
    this.curatorOption[index] = this.curatorOptionCopy[index].filter(item => item.viewValue.toLowerCase().includes($event.toLowerCase()));
  }

  public curator2Searching($event: any, index: number): void {
    this.curatorOption2[index] = this.curatorOption2Copy[index].filter(item => item.name.toLowerCase().includes($event.toLowerCase()));
  }

  trackByIndex(index: number, item: any): number {
    return index;
  }

  removeGroup(index: number): void {
    this.selectedIndex = index;
    this.isModalOpen = !this.isModalOpen;
  }

  closeDialog():void {
    this.isModalOpen = false;
    this.selectedIndex = 0;
    this.disableBtn = false;
    this.destroy$.next();
    this.destroy$.complete();
    this.cdr.detectChanges();
  }

  delete(): void {
    this.disableBtn = true;
    this.cdr.detectChanges();
    this.adminService.deleteGroup(this.groupsList[this.selectedIndex].id)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: _ => {
          this.loading = true;
          this.groups.removeAt(this.selectedIndex);
          this.groupsList.splice(this.selectedIndex, 1);
          this.coursesOption.splice(this.selectedIndex, 1);
          this.curatorOption.splice(this.selectedIndex, 1);
          this.curatorOption2.splice(this.selectedIndex, 1);
          this.disableBtn = false;
          this.ns.showSuccess("Вы успешно удалили группу");
          this.closeDialog();
          this.loading = false;
          this.cdr.detectChanges();
        },
        error: err => {
          this.disableBtn = false;
          this.loading = false;
          this.ns.showError(err?.error?.messages?.[0]);
          this.closeDialog();
          this.cdr.detectChanges();
        }
    });
  }
}
