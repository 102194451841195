<div class="table">
  <div class="header">
    <div class="row">
      <ng-container *ngFor="let _ of [].constructor(3)">
        <div class="th skeleton"></div>
      </ng-container>
    </div>
  </div>
  <div class="body">
    <ng-container *ngFor="let row of [].constructor(10)">
      <div class="row">
        <ng-container *ngFor="let cols of [].constructor(6)">
          <div class="td skeleton"></div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
