import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { Languages } from "../../../models";
import { TranslateService } from "@ngx-translate/core";
import { StorageService } from "../../../../core/services/storage/storage.service";
import { Keys } from "../../../../core/model";

@Component({
  selector: 'app-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

export class LoginRegisterComponent {
  language: Languages = Languages.KZ;

  constructor(
    private _translateService: TranslateService,
    private storageService: StorageService
  ) {
    this.setLang(this._translateService.currentLang as Languages);
  }

  public setLang(lang: Languages) {
    this.language = lang;
    this.storageService.setItem(Keys.Language, lang);
    this._translateService.use(lang as string);
  }
}
