import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { AdminService } from 'src/app/core/services/admin/admin.service';

@Component({
  selector: 'app-admin-curator-groups',
  templateUrl: './admin-curator-groups.component.html',
  styleUrls: ['./admin-curator-groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminCuratorGroupsComponent implements OnInit {
  @Input() curatorId!: string;
  public curatorGroups: any = [];

  constructor(
    private _adminService: AdminService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getCuratorInfo(this.curatorId);
  }

  public getCuratorInfo(id: string): void {
    this._adminService.getCuratorGroups(id).subscribe({
      next: (response) => {
        this.curatorGroups = response;
        this.cdr.detectChanges();
      },
    });
  }
}
