import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent {
  @Input() totalPages: number = 0;
  @Input() totalCount: number = 0;
  @Input() page: number = 0;
  @Input() size: number = 20;

  @Output() onPageClick: EventEmitter<number> = new EventEmitter<number>();

  public onPageClickHandler(page: number): void {
    this.onPageClick.emit(page);
  }
}
