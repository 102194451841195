<app-dialog
    [isOpen]="isOpen"
    (closeEvent)="closeDialog()"
    (confirmEvent)="save()"
    title="FILTER"
    [disabledConfirm]="form.invalid"
    [showButtons]="true">
    <ng-container>
        <div class="app-filter">
            <form class="form" [formGroup]="form">
                <app-multi-select
                    *ngIf="teachers"
                    name="TEACHER1"
                    [options]="teachers"
                    (selectedValue)="setValue('teachers', $event)"
                    [withSearch]="true"
                ></app-multi-select>
                <app-multi-select
                    name="YEAR"
                    [options]="years"
                    (selectedValue)="setValue('years', $event)"
                ></app-multi-select>
            </form>
        </div>
    </ng-container>
</app-dialog>
