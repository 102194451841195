<div class="container-main">
  <div class="info">
    <span class="ins-name">{{'downloadHomework' | translate}}</span>
    <span *ngIf="!isViewMode" class="info-text">{{'explanationHomeworkPassing' | translate}}</span>
  </div>
  <form *ngIf="!isViewMode" class="container" [formGroup]="form">
    <div class="block">
      <div class="file-uploader">
        <app-file-uploader
          (downloadedEvent)="downloadedFile($event)"
          (removeEvent)="removeFile($event)"
          [idList]="fileIds || []"
          [isViewTaskMode]="isViewTaskMode"
          [allowAdditionalFormats]="'image/*'">
        </app-file-uploader>
      </div>
    </div>

    <div class="block">
      <span class="ins-name">{{'additional' | translate}}</span>
      <app-textarea formControlName="text" placeholder="ENTER_DESCRIPTION" [readOnly]="isViewTaskMode"></app-textarea>
    </div>

    <button
      class="iconic-button primary-button button"
      (click)="passHomework()"
      [disabled]="!isHomeworkExist || isViewTaskMode">
      {{'uploadHomeTask' | translate}}
    </button>
  </form>
  <div *ngIf="isViewMode && data" class="container">
    <div class="body-m">
      <div class="header-m">
        <div class="student-info">
          <app-avatar [firstLetter]="data.studentFirstname && data.studentFirstname[0] || ''"></app-avatar>
          <div class="student-info-inner">
            <span class="name">{{ (data.studentFirstname || '') + ' ' + (data.studentLastname || '')}}</span>
            <span class="date">{{ data.submissionTime | utcToLocal | datepipe : 'd.M.y, HH:mm'}}</span>
          </div>
        </div>
        <button
          *ngIf="canSendHomework"
          class="button-m iconic-button secondary-button"
          (click)="switchViewMode.emit(false)">
          {{'edit' | translate}}
        </button>
      </div>
      <div class="data">
        <ul class="list">
          <li class="item" *ngFor="let link of fileIds; let i = index;">
            <a class="left" target="_blank" [href]="environment.filesUrl +link">
              <img [src]="'assets/svg/files/' + ( link | fileFormat)?.icon" alt=""/>
              <span class="file-name">{{(link | fileFormat).fileName }}</span>
            </a>
          </li>
        </ul>
        <div class="base-text" [innerHTML]="form.get('text')?.value | linkify"></div>

<!--        <span class="base-text">{{form.get('text')?.value}}</span>-->
      </div>
    </div>

  </div>
</div>
