import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, debounce, map, timer } from 'rxjs';
import { TestAnswerDTO, ProgressDTO, HomeworkSubmission } from '../../model';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {

  constructor(private httpClient: HttpClient) { }

  public getProgress(groupId: string): Observable<ProgressDTO[]> {
    return this.httpClient.get<ProgressDTO[]>(`/v1/student/progresses/groups/${groupId}`).pipe(
      map(res => {
        return res;
      })
    )
  };

  public getProgressByLesson(groupId: string, lessonId: string): Observable<ProgressDTO> {
    return this.httpClient.get<ProgressDTO[]>(`/v1/student/progresses/groups/${groupId}`).pipe(
      map(res => {
        const progress: ProgressDTO = res.filter(item => item.lessonId === lessonId)[0];
        return progress;
      })
    )
  };

  public sendTestAnswers(data: TestAnswerDTO): Observable<ProgressDTO> {
    return this.httpClient.patch<ProgressDTO>(`/v1/student/progresses/quizanswers`, data).pipe(
      map(res=> {
        return res;
      }),
    )
  }

  public passedQuiz(groupId: string, quizId: string): Observable<ProgressDTO> {
    return this.httpClient.patch<ProgressDTO>(`/v1/student/progresses/groups/${groupId}/quizzes/${quizId}`, {}).pipe(
      map(res=> {
        return res;
      })
    )
  }

  public passHomeWork(groupId: string, homeworkId: string, submissions: HomeworkSubmission): Observable<string[]> {
    return this.httpClient.patch<string[]>(`/v1/student/progresses/groups/${groupId}/homeworks/${homeworkId}`, submissions).pipe(
      map(res=> {
        return res;
      })
    )
  }

  public passWorkOnMistakes(groupId: string, homeworkId: string, submissions: HomeworkSubmission): Observable<string[]> {
    return this.httpClient.patch<string[]>(`/v1/student/progresses/groups/${groupId}/workonmistakes/${homeworkId}`, submissions).pipe(
      map(res=> {
        return res;
      })
    )
  }
}
