import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import {
  CalculatedPrice,
  ICatalogue,
  IGroupCourseMonthPrice
} from '../../model/interface/catalogues';
import { SortableResponseData } from '../../model';
import { IFaq } from '../../model/interface/faq';
import { ToastService } from '../../../shared/services/toast/toast.service';
import { LOCAL_CART } from 'src/app/shared/consts/course';
import { TranslateService } from '@ngx-translate/core';
import { Roles } from 'src/app/core/model';

const LIMIT_COURSE = 4;
const LIMIT_EXCEPTIONAL_COURSES = 8;
const EXCEPTIONAL_COURSES = [
  "00761d6d-8900-4892-bad7-4bca4b7199ce",
  "96dad223-82dc-4f1c-9ce1-c8732e43c36d",
  "d1a66b15-577c-4f00-9ec6-28a5e983025a",
  "60bf1e57-608b-44a4-bbdc-19e43d84c37a",
  "9be8e6f3-b544-4012-88e6-9e00258439c7",
  "473496e3-4e5a-4128-a9b4-fcd02a4df7a4",
  "9deb6a2d-2b25-4bdd-943b-019b9ed2b850",
  "812ba0c6-8842-4836-bf61-90c8ce6b216e",
  "5fb13f1c-b277-49e6-8d0c-bd33fd9471e8",
  "91cd3b89-d863-4088-8eb9-122b68faf74d",
  "f7e35266-288d-41ec-8764-c00ac3fd2218",
  "a30c732e-d7f7-4fa8-bd66-e1a229fa7a04",
  "dd848173-2260-47ea-8ae8-85507d79232a",
  "3b50795d-5884-48dc-9158-5728234d151d",
  "c905f206-46ee-4a59-8bad-09c55535d11c"
]
export interface IGetCataloguesRequest {
  page?: number;
  size?: number;
  sort?: string;
  searchName?: string;
  subjects?: string[];
  years?: number[];
  monthes: number[];
  onlyActive?: boolean;
  role: Roles
}

@Injectable({
  providedIn: 'root',
})
export class CataloguesService {
  public _isShowCartModal$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  private activeCourses: number = 0;

  public cartItems$: BehaviorSubject<any> = new BehaviorSubject(
    this.getCoursesFromLocalStorage()
  );

  public activeCourses$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private _http: HttpClient,
    private _toastService: ToastService,
    private _translateService: TranslateService
  ) {}

  public getCatalogues({
    page = 0,
    size = 10,
    sort = 'createdAt,desc',
    searchName = '',
    subjects = [],
    monthes = [],
    years = [],
    onlyActive,
    role,
  }: IGetCataloguesRequest): Observable<SortableResponseData<ICatalogue>> {
    const params: any = {
      role,
      page,
      size,
      sort,
      subjectIds: subjects,
      months: monthes,
      years,
    };

    if (onlyActive) params.active = onlyActive;
    if (searchName) params.name = searchName;

    return this._http.get<any>(`/v1/${role === Roles.HeadTeacher ? 'headteacher' : 'admin'}/streams`, { params });
  }

  public getStreams(size: number = 999): Observable<ICatalogue[]> {
    return this._http.get<SortableResponseData<ICatalogue>>(`/v1/streams`, {params: {size}}).pipe(
      map((res:SortableResponseData<ICatalogue>) => {
        return res?.content;
      })
    );
  }

  public getCatalogueList(): Observable<ICatalogue[]> {
    return this._http.get<any>(`/v1/streams/get/active`);
  }

  public toggleIsShowCartModal(): void {
    this._isShowCartModal$.next(!this._isShowCartModal$.getValue());
  }

  public openShowCartModal(): void {
    this._isShowCartModal$.next(true);
  }

  public closeShowCardModal(): void {
    this._isShowCartModal$.next(false);

  }

  public addToCard(course: ICatalogue, showMessage: boolean = true): string | void {
    // return this.cartItems$.getValue() || [];
    const cartItems = this.cartItems$.getValue() || [];
    const isExceptionalCourse = EXCEPTIONAL_COURSES.includes(course.id);

    // Check if the course is already in the cart
    const isCourseInCart = cartItems.find((item: ICatalogue) => item.id === course.id);
    if (isCourseInCart) {
      this._translateService
        .get('thisCourseAlreadyAdded')
        .subscribe((res) => { if(showMessage) this._toastService.showInfo(res) });
      return;
    }

    // Determine the current limit based on the type of course
    const currentLimit = isExceptionalCourse ? LIMIT_EXCEPTIONAL_COURSES : LIMIT_COURSE - this.activeCourses;
    const currentCourseCount = cartItems.filter((item: ICatalogue) =>
      isExceptionalCourse ? EXCEPTIONAL_COURSES.includes(item.id) : !EXCEPTIONAL_COURSES.includes(item.id)
    ).length;

    // Check if adding this course exceeds the limit
    if (currentCourseCount >= currentLimit) {
      this._translateService
        .get('ableToAddSpecificNumberOfCourses', { value: currentLimit })
        .subscribe((res) => { if(showMessage) this._toastService.showInfo(res) });
    } else {
      // Add course to the cart
      this._translateService
        .get('courseAddedToCart')
        .subscribe((res) => { if(showMessage) this._toastService.showSuccess(res) });

      const updatedCartItems = [...cartItems, course];
      localStorage.setItem(LOCAL_CART, JSON.stringify(updatedCartItems));
      this.cartItems$.next(updatedCartItems);
    }
  }

  public removeCardFromCart(card: ICatalogue): void {
    const newArr = this.cartItems$
      .getValue()
      .filter((item: ICatalogue) => item.id !== card.id);

    localStorage.setItem(LOCAL_CART, JSON.stringify(newArr));
    this.cartItems$.next(newArr);
    this._translateService
      .get('courseRemovedFromCart')
      .subscribe((res) => this._toastService.showInfo(res));
  }

  public getCourseById(id: string): Observable<ICatalogue> {
    return this._http.get<ICatalogue>(`/v1/streams/${id}`);
  }

  public getPaymentLink(body: any): Observable<any> {
    return this._http.post(`/v1/paymenthistories/paymentlink`, body);
  }

  public clearCart(): void {
    this._translateService.get('cartCleared').subscribe((res) => {
      this._toastService.showInfo(res);
    });
    this.cartItems$.next(null);
  }

  public getFaqs(): Observable<IFaq[]> {
    return this._http.get<IFaq[]>(`/v1/faqs`);
  }

  public getCoursesFromLocalStorage(): ICatalogue[] | any{
    return JSON.parse(localStorage.getItem(LOCAL_CART) || '[]');
  }

  public getActiveCourse(): Observable<IGroupCourseMonthPrice[]> {
    return this._http
      .get<IGroupCourseMonthPrice[]>(`/v1/student/monthlypayments/groups`)
      .pipe(
        map((group) => {
          this.activeCourses$.next(group);
          this.activeCourses = group.length;
          return group;
        })
      );
  }

  calculateTotalSum(streamIds: string[], username: string | null, phoneNumber: string | null, promoCode: string | null = null): Observable<CalculatedPrice> {
    // Construct query parameters
    const params = new HttpParams()
      .set('streamIds', streamIds.join(','))
      .set('username', username || "") // Convert array to comma-separated string
      .set('phoneNumber', phoneNumber || "")
      .set('promoCode', promoCode || "")

    return this._http.get<CalculatedPrice>(`/v1/student/monthlypayments/calculate`, { params });
  }

  public checkCoursesInCart(catalogues: ICatalogue[]): void {
    const cartItems = this.cartItems$.getValue();
    if (cartItems?.length) {
      const ids = catalogues.map(catalogue => catalogue.id);
      const updatedCartItems = cartItems.filter((item: ICatalogue) => ids.includes(item.id));

      localStorage.setItem(LOCAL_CART, JSON.stringify(updatedCartItems));
      this.cartItems$.next(updatedCartItems);
    }
  }
}
