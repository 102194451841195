import { DateHelperServiceService } from './../../../services/dateHelperService/date-helper-service.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseRoutes, CoursesDTO, MethodistRoutes } from 'src/app/core/model';
import { CoursesService } from 'src/app/core/services/courses/courses.service';
import { Languages } from "../../../models";

@Component({
  selector: 'app-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CourseCardComponent {
  @Input() data!: CoursesDTO;
  @Input() currentLang?: Languages;
  @Output() editClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() duplicateClick: EventEmitter<CoursesDTO> = new EventEmitter<CoursesDTO>();
  @Output() deleteClick: EventEmitter<string> = new EventEmitter<string>();

  public disabledToggler: boolean = false;
  public form!: FormGroup;
  constructor(
    public dateHelperServiceService: DateHelperServiceService,
    private coursesService: CoursesService,
    private formBuilder: FormBuilder,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.form = this.formBuilder.group({
      active: [false, Validators.required]
    })
  }

  public changedActive(active: boolean): void {
    this.disabledToggler = true;
    this.coursesService.updateCourseById(this.data.id, {
      subjectId: this.data.subjectId,
      name: this.data.name,
      teacherId: this.data.teacherId,
      year: this.data.year,
      month: this.data.month,
      active: active
    })
    .subscribe(res => {
      this.disabledToggler = false;
      this.cdr.detectChanges()
    })
  };

  public goToCourse(id: string, event: Event): void {
    let clickedElement = event.target as HTMLElement;
    let isChildClicked = false;

    // Check if clicked element or any of its parents have the class or tag name of the child elements
    while (clickedElement) {
      if (clickedElement.classList.contains('button') || clickedElement.tagName === 'APP-SLIDE-TOGGLE') {
        isChildClicked = true;
        break;
      }
      clickedElement = clickedElement.parentElement!;
    }

    if (!isChildClicked) {
      this.router.navigate([`${BaseRoutes.Methodist}/${MethodistRoutes.Course}/${id}`]);
    }
  }


}
