import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {LoadingService} from 'src/app/shared/services/loading/loading.service';

@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressSpinnerComponent {
  isLoading: boolean = false;

  constructor(
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.loadingService.isLoading$.subscribe({
      next: (isLoading) => {
        this.isLoading = isLoading;
        this.cdr.detectChanges();
      },
      complete: () => setTimeout(() => this.cdr.detectChanges(), 10)
    });
  }
}
