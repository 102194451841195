<app-dialog
    [isOpen]="isOpen"
    (closeEvent)="closeDialog()"
    [title]="(curatorFullName && teacherFullName) ? ('curatorAndTeacher' | translate) : ('TEACHER' | translate)"
    [showButtons]="false">
    <ng-container>
        <div class="app-responsible-dialog">
            <div class="item" *ngIf="curatorUsername">
<!--                <img class="image" *ngIf="curatorProfileUrl" [src]="curatorProfileUrl" alt=""/>-->
                <div *ngIf="curatorProfileUrl" class="image" [style.background-image]="'url(' + curatorProfileUrl + ')'"></div>
                <div *ngIf="!curatorProfileUrl" class="no-image">{{curatorFullName![0]}}</div>
                <div class="base">
                    <h1 class="name">{{curatorFullName}} <span class="position">({{'CURATOR' | translate}})</span></h1>
                    <span class="email">{{curatorUsername}}</span>
                    <button *ngIf="curatorPhoneNumber"  class="iconic-button secondary-button button">
                        <img src="assets/svg/socials/logos_whatsapp-icon.svg" alt="" />
                        <a target="_blank" [href]="'https://wa.me/' + curatorPhoneNumber" >{{'writeToWhatsapp' | translate}}</a>
                    </button>
                </div>
            </div>
            <div class="item" *ngIf="teacherFullName">
<!--                <img class="image" *ngIf="teacherProfileUrl" [src]="teacherProfileUrl" alt=""/>-->
                <div *ngIf="teacherProfileUrl" class="image" [style.background-image]="'url(' + teacherProfileUrl + ')'"></div>
                <div *ngIf="!teacherProfileUrl" class="no-image">{{teacherFullName[0]}}</div>
                <div class="base">
                    <h1 class="name">{{teacherFullName}} <span class="position">({{'TEACHER' | translate}})</span></h1>
                    <!-- <span class="email">{{teacherUsername}}</span> -->
                    <p class="desc">{{teacherDesc}}</p>
                </div>
            </div>
        </div>
    </ng-container>
</app-dialog>

