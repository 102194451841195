import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommentDTO, LessonDTO, LessonType, Roles, StudentProgress } from 'src/app/core/model';
import { FilesService } from 'src/app/core/services/files/files.service';
import { UsersService } from 'src/app/core/services/users/users.service';
import { environment } from 'src/environment';
import { Languages, LessonStatus } from 'src/app/shared/models';
import { PdfFileServiceService } from './../../../../shared/services/pdf-file-service/pdf-file-service.service';
import { Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "../../../../core/services/auth/auth.service";

const lessonTypesTranslates = {
  QUIZ: {
    kk: 'Тест',
    ru: 'Тест',
  },
  HOMEWORK: {
    kk: 'Үй жұмысы',
    ru: 'Домашнее задание',
  },
  LECTURE: {
    kk: 'Лекция',
    ru: 'Лекция',
  },
  WORK_ON_MISTAKES: {
    kk: 'Қатемен жұмыс',
    ru: 'Работа над ошибками',
  },
  TEXT: {
    kk: 'Мәтіндік материал',
    ru: 'Текстовый урок',
  },
};

@Component({
  selector: 'app-student-progress',
  templateUrl: './student-progress.component.html',
  styleUrls: ['./student-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class StudentProgressComponent implements OnInit, OnChanges {
  @Input() progress!: StudentProgress;
  @Input() deadline: string = '';
  @Input() themeId: string = '';
  @Input() groupId: string = '';
  @Input() canGrade = true;
  @Input() isSendingComments: boolean = false;

  @Input() first: boolean = false;
  @Input() last: boolean = false;
  @Output() goToLeftEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() goToRightEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() returnLesson: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() markCompletedLesson: EventEmitter<boolean> = new EventEmitter<boolean>();

  public readonly LessonStatus = LessonStatus;
  public readonly LessonType = LessonType;
  public _lesson: LessonDTO | null = null;
  public language: any = Languages.RU;

  @Input() set lesson(lesson: LessonDTO) {
    this._lesson = lesson;
    this.form
      .get('grade')
      ?.addValidators([Validators.max(lesson.lessonMaxScore)]);
  }
  get lesson(): LessonDTO {
    return this._lesson!;
  }

  @Output() onGradeLesson: EventEmitter<any> = new EventEmitter();
  @Output() onSubmitListener: EventEmitter<any> = new EventEmitter();
  @Output() onRemoveComment: EventEmitter<string> = new EventEmitter();
  @Output() editCommentChange: EventEmitter<CommentDTO> = new EventEmitter();

  public imageUrl: string = '';
  public form: FormGroup;
  public formComment: FormGroup;
  public lessonTypesTranslates: any = lessonTypesTranslates;
  public myId: string = '';
  public filesLinks: string[] = [];

  public timeZone: string = "";
  commentValue: string = '';

  public isLate: boolean = false;
  public blockReturnLessonBtn: boolean = false;
  public blockCompletedLessonBtn: boolean = false;
  public pdfFileState$!: Observable<any>;

  public openFileLink: string = "";
  constructor(
    private _fileService: FilesService,
    private usersService: UsersService,
    private cdr: ChangeDetectorRef,
    private _router: Router,
    private PdfFileServiceService: PdfFileServiceService,
    private translateService: TranslateService,
    private authService: AuthService
  ) {
    this.language = this.translateService.currentLang as Languages;
    this.form = new FormGroup({
      grade: new FormControl('', [Validators.required]),
    });

    this.formComment = new FormGroup({
      text: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.form.get('grade')?.setValue(this.progress?.score || '');
    this.pdfFileState$ = this.PdfFileServiceService.state$;
    if(this.progress.studentPhotoUrl) {
      this.imageUrl = this.progress.studentPhotoUrl;
    }
    this.usersService.getUserProfile().subscribe((res) => {
      this.myId = res.id!;
      this.cdr.detectChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['progress']) {
      this.form.get('grade')?.setValue(this.progress?.score || '');
      this.form.reset();
      // this.formComment.reset();
      this.filesLinks = this.progress.submissions ?? [];
      if(this.PdfFileServiceService.isOpen()) {
        if(this.filesLinks[0]) {
          this.PdfFileServiceService.open(this.filesLinks[0])
        }
        else {
          this.PdfFileServiceService.open(null)
        }
      }
    }

    if(this._lesson && this._lesson.deadline && this.progress && this.progress.submissionTime) {
      if((new Date(this._lesson.deadline).getTime() - new Date(this.progress.submissionTime).getTime()) < 0) {
        this.isLate = true;
      }
      else {
        this.isLate = false;

      }
      this.cdr.detectChanges();
    }
  }

  public getImageUrl(): string {
    if (this.progress && this.progress.studentPhotoUrl) {
      return this._fileService.getImageUrl(this.progress.studentPhotoUrl);
    }
    return 'assets/img/default-ava.webp';
  }

  public submit(): void {
    if (this.form.invalid) return;

    this.onGradeLesson.emit({
      grade: this.form.controls['grade'].value,
      progress: this.progress,
    });

    // this.form.disable();
  }

  public onSubmit(): void {
    if (this.formComment.invalid) return;
    this.onSubmitListener.emit({
      text: this.formComment.controls['text'].value?.replace(/\n/g, '<br>'),
      id: this.progress.id,
      progress: this.progress,
    });
    this.formComment.controls['text'].setValue('');
  }

  public openFile(id: string): void {
    const fileExtension = id.split('.').pop()!.toLowerCase();
    const validFormats = ['doc', 'docx', 'png', 'jpg', 'pdf', 'jpeg'];
    const isValidFormat = validFormats.includes(fileExtension);

    if(!isValidFormat) {
      window.open(environment.filesUrl + id)
      return;
    }

    const converterId = id;
    if(converterId === this.PdfFileServiceService.getUrl()) {
      this.PdfFileServiceService.close();
      this.openFileLink = "";
    }
    else {
      this.openFileLink = converterId;
      this.PdfFileServiceService.open(converterId);
    }
    this.cdr.detectChanges();
  }

  public onTestResultClick(): void {
    this._router.navigate([
      '/curator/',
      'student-test',
      this.groupId,
      this.lesson.id,
      this.progress.studentId,
    ]);
  }

  public dateDifferenceInHours(deadline: string | undefined, submissionTime: string | undefined | null): number {
    if(!deadline || !submissionTime) {
      return 0;
    }
    const timeDifference = new Date(deadline).getTime() - new Date(submissionTime).getTime();
    return Math.floor(timeDifference / (1000 * 3600));
  }

  formatTimeDifference(deadline: string | undefined, submissionTime: string | undefined | null): string {
    if(!deadline || !submissionTime) {
      return '';
    }
    const differenceTime = new Date(submissionTime).getTime() - new Date(deadline).getTime();
    const timeDifference = Math.abs(differenceTime);

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

    if (this.language === Languages.RU) {
      return `${days} дней, ${hours} часов, ${minutes} минут`;
    } else {
      return `${days} күн, ${hours} сағат, ${minutes} минут`;
    }
  }

  public getMainImageUrl(photoUrl: string):string {
    return environment.filesUrl + photoUrl;
  }

  public editComment(comment: CommentDTO, index: number) {
    this.commentValue = comment?.commentText?.replaceAll('<br>', '\n');
    this.progress.comments.forEach((comm, i) => {
      if (i !== index) {
        comm.isEdit = false;
      } else {
        comm.isEdit = !comm.isEdit;
      }
    });
  }

  public submitComment(comment: CommentDTO) {
    const commentText = this.commentValue?.replace(/\n/g, '<br>');
    if (commentText !== comment.commentText) {
      comment.commentText = commentText;
      this.editCommentChange.emit(comment);
    }
    comment.isEdit = false;
  }

  public removeComment(commentId: string) {
    this.onRemoveComment.emit(commentId);
  }

  public canRemoveComment(commenterId: string) {
    return commenterId === this.myId;
  }

  public openLeftStudent(): void {
    this.goToLeftEvent.emit(this.progress.studentId)
  }
  public openRightStudent(): void {
    this.goToRightEvent.emit(this.progress.studentId)
  }

  returnLessonEvent(): void {
    this.returnLesson.emit();
    this.blockReturnLessonBtn = true;
  }

  getLessonTypeName(lessonType: string): string {
    return this.lessonTypesTranslates[lessonType][this.language] || '-';
  }

  markAsCompleted(): void {
    this.markCompletedLesson.emit();
    this.blockCompletedLessonBtn = true;
  }

  get showMarkAsCompleted(): boolean {
    const role = this.authService.getRole();
    return (role === Roles.Curator || role === Roles.Methodist || role === Roles.HeadTeacher )
      && this.progress.status === 'IN_REVIEW'
      && this.lesson.lessonType === LessonType.Homework
      && this.lesson?.maxScore !== 0
      && !this.lesson?.maxScore;
  }

  get canReturn(): boolean {
    return this.progress.status === 'IN_REVIEW' && (this.lesson.lessonType === LessonType.Homework || this.lesson.lessonType === LessonType.WorkOnMistakes);
  }
}
