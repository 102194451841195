<div class="container">
  <ul class="navigator navigator-pointer">
    <li  *ngFor="let quesiton of data.lesson.questions;let i = index" class="nav">
      <button
        class="nav-button"
        (click)="go(i)"
        [ngClass]="{
          'nav-button-answered': (
            quizState === QuizState.Passing && (data.lesson.questions![i].userAnswers || checkProgress(data.lesson.questions![i].id)) && currentStep !== i),
          'nav-button-current': quizState === QuizState.Passing && currentStep === i,
          'nav-button-correct': quizState === QuizState.View && ((data.lesson.maxScore! / data.lesson.questions!.length) === quesiton.score),
          'nav-button-incorrect': quizState === QuizState.View && ((data.lesson.maxScore! / data.lesson.questions!.length) !== quesiton.score),
        }">
        <span class="desktop">{{i+1}}</span>
      </button>
    </li>
  </ul>
  <div class="iconic-line"></div>
  <ul class="stepper">
    <li class="step" *ngFor="let quesiton of data.lesson.questions; let i = index">
      <div *ngIf="currentStep === i" class="step-wrapper">
        <app-questions
                [pattern]="quesiton.questionPattern || ''"
                [position]="i"
                [question]="quesiton"
                [quizState]="quizState"
                [isViewTaskMode]="isViewTaskMode"
                (answeredEvent)="questionAnswered($event)"
              >
        </app-questions>
      </div>
    </li>
  </ul>
  <div class="buttons">
    <button class="arrow-button mobile" (click)="openQuestionList()">
      <img src="assets/svg/menu.svg"
      alt=""
      />
    </button>
    <button
      class="arrow-button"
      (click)="prev()"
      [disabled]="currentStep===0">
      <img src="assets/svg/arrow-left-2.svg" alt="">
    </button>
    <button
      *ngIf="quizState === QuizState.Passing"
      class="iconic-button primary-button button"
      (click)="next()"
      [disabled]="isViewTaskMode"
      >{{'confirm' | translate}}</button>
    <button
      class="arrow-button"
      (click)="next()"
      [disabled]="currentStep=== (data.lesson.questions!.length-1)">
      <img src="assets/svg/arrow-right-2.svg" alt="">
    </button>
  </div>
</div>

<div>
<app-dialog [isOpen]="isQuestionListOpen" (closeEvent)="closeQuestionList()" [showButtons]="false">
  <ul *ngFor="let quesiton of data.lesson.questions;let i = index">
    <li
    class="mobile-nav-item"
    (click)="mobileGo(i)"
      [ngClass]="{
        'nav-mobile-button-answered': (quizState === QuizState.Passing && (data.lesson.questions![i].userAnswers || checkProgress(data.lesson.questions![i].id)) && currentStep !== i),
        'nav-mobile-button-current': quizState === QuizState.Passing && currentStep === i,
        'nav-mobile-button-correct': quizState === QuizState.View && ((data.lesson.maxScore! / data.lesson.questions!.length) === quesiton.score),
        'nav-mobile-button-incorrect': quizState === QuizState.View && ((data.lesson.maxScore! / data.lesson.questions!.length) !== quesiton.score),
      }">
      <img
        [ngStyle]="{ 'visibility': (quizState === QuizState.Passing && (data.lesson.questions![i].userAnswers || checkProgress(data.lesson.questions![i].id)) && currentStep !== i) ? 'visible' : 'hidden'}"
        src="assets/svg/check-mark.svg" alt="" />
      <span>{{('QUESTION' | translate) + " " + (i + 1)}}</span>
    </li>
  </ul>
</app-dialog>
