import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ForAnswerTestQuestions, TestType } from 'src/app/core/model';

@Component({
  selector: 'app-test-content-closed',
  templateUrl: './test-content-closed.component.html',
  styleUrls: ['./test-content-closed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestContentClosedComponent implements OnChanges {
  public testType = TestType;
  @Input() isExpanded: boolean = false;
  @Input() data: any;
  @Input() index: number = 0;
  @Input() type: TestType = TestType.Test;
  @Output() OpenEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() RemoveEvent: EventEmitter<number> = new EventEmitter<number>();


  ngOnChanges(changes: SimpleChanges): void {
      // console.log(this.data);
  }
}
