<div class="student-sidebar">
    <div class="desktop">
        <div class="header">
            <img width="88px" height="39px" src="assets/img/IMG_11-min.PNG" alt="" />
            <app-courses-catalog-button ></app-courses-catalog-button>
        </div>
    </div>
  <ng-container *ngIf="!(loading$ | async)">
    <div *ngIf="currentGroup" class="desktop">
      <div class="courses-card">
        <app-side-bar-courses-card
          (setLoading)="load()"
          [sortType]="sortType"
          [currentGroup]="currentGroup"
          [groupsList]="groupsCopy"/>

        <app-side-bar-courses-sort [sortType]="sortType" (setSortGroup)="setSort($event)"/>
      </div>
    </div>
    <ul
      *ngIf="currentGroup && currentGroup.themes && currentGroup.themes.length > 0">
      <li>
        <app-additional-mat-usefull-links-list [additionalMaterials]="{ additionalMaterials: currentGroup.additionalMaterials || [], usefulLinks: currentGroup.usefulLinks || []}"></app-additional-mat-usefull-links-list>
      </li>
      <li>
        <a class="folders" (click)="openFolders()">
          <img src="assets/svg/circles/folders-circle.svg" alt="" />
          <span class="folders-text">{{'COURSE_LESSON_TYPE' | translate}}</span>
        </a>
      </li>
      <ul [ngClass]="{'sort-asc': sortType === 'SORT_BY_OLD', 'sort-desc': sortType === 'SORT_BY_NEW'}"
        *ngIf="currentGroup && currentGroup.themes && currentGroup.themes.length > 0">
        <li *ngFor="let item of currentGroup.themes; let i = index;">
          <app-item-sidebar [data]="item" [isOpen]="isOpenList[i]" (toggleEvent)="toggleItem(i)"></app-item-sidebar>
        </li>
      </ul>
    </ul>
  </ng-container>
  <app-page-progress-spinner *ngIf="loading$ | async"></app-page-progress-spinner>
</div>
