import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseRoutes, LessonType, StudentDeadline, StudentRoutes } from 'src/app/core/model';
import { StudentService } from './../../../../core/services/student/student.service';
import { DateHelperServiceService } from './../../../services/dateHelperService/date-helper-service.service';
import { RedirectInfo, Languages } from 'src/app/shared/models';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription, debounceTime, distinctUntilChanged, skip } from 'rxjs';
import { StudentHelperService } from 'src/app/feature/student/service/student-helper.service';

@Component({
  selector: 'app-closest-deadline',
  templateUrl: './closest-deadline.component.html',
  styleUrls: ['./closest-deadline.component.scss']
})
export class ClosestDeadlineComponent implements OnInit, OnDestroy {
  public readonly LessonType = LessonType;

  public deadline: StudentDeadline | null = null;

  public hours!: number;
  public minutes!: number;
  public seconds!: number;
  public totalSeconds!: number;
  public timer: any;

  public language: Languages = Languages.KZ;
  private languageSubscription!: Subscription;
  constructor(
    private studentService: StudentService,
    private cdr: ChangeDetectorRef,
    private dateHelper: DateHelperServiceService,
    private router: Router,
    private translate: TranslateService,
    private studentHelperService: StudentHelperService
    ) {
      this.languageSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.language = event.lang as Languages;
        this.cdr.detectChanges();
      });
  }

  ngOnInit() {
    this.studentHelperService.updateDeadline$.pipe(skip(1)).subscribe({
      next: (res) => {
        this.loadData();
        this.cdr.detectChanges();
      },
      error: (err) => {
        console.log(err);
      }
    });
    this.loadData();
  }

  ngOnDestroy(): void {
    if(this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  private loadData(): void {
    this.studentService.getClosestDeadlines().subscribe({
      next: (res) => {
        if(!res) {
          this.deadline = null;
          return;
        }
        const currentTime = new Date();

        const timeDifferenceInSeconds = (this.dateHelper.convertUTCToLocal(new Date(res.deadline)).getTime() - currentTime.getTime()) / 1000;

        const twentyFourHourInSeconds = 86400;
        const oneHourInSeconds = 3600;
        if (timeDifferenceInSeconds < twentyFourHourInSeconds) {
          this.deadline = res;
          this.totalSeconds = Math.floor((timeDifferenceInSeconds));
          this.hours = Math.floor(this.totalSeconds / 3600);

          if (timeDifferenceInSeconds < oneHourInSeconds) {
            this.minutes = Math.floor(this.totalSeconds / 60);
            this.seconds = this.totalSeconds % 60;
          }

          this.startTimer();
        }

        this.cdr.detectChanges();
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  startTimer() {
    const oneHourInSeconds = 3600;
    this.timer = setInterval(() => {
      if (this.totalSeconds > 0) {
        this.totalSeconds--;
        if (this.totalSeconds >= oneHourInSeconds) {
          this.hours = Math.floor(this.totalSeconds / 3600);
        } else {
          this.hours = 0;
          this.minutes = Math.floor(this.totalSeconds / 60);
          this.seconds = this.totalSeconds % 60;
        }
        this.cdr.detectChanges();
      } else {
        this.stopTimer();
      }
    }, 1000);
  }

  stopTimer() {
    this.deadline = null;
    this.cdr.detectChanges()
    clearInterval(this.timer);
  }

  public goToLesson(): void {
    if(!this.deadline) {
      console.log('deadline is undefined');
      return
    }
    const headerMenuData: RedirectInfo = {
      groupId: this.deadline.groupId,
      courseId: this.deadline.courseId,
      id: this.deadline.lessonId,
      name: this.deadline.lessonName,
      lessonType: this.deadline.lessonType
    }
    this.router.navigate([`${BaseRoutes.Student}/${this.generateLessonsSiblingsRoute(headerMenuData)}`]);
  }

  public generateLessonsSiblingsRoute(item: RedirectInfo): string {
    let lessonSubRoute = StudentRoutes.Theory;
    switch(item.lessonType) {
      case LessonType.Homework: {
        lessonSubRoute = StudentRoutes.Homework;
        break;
      }
      case LessonType.Quiz: {
        lessonSubRoute = StudentRoutes.Test;
        break;
      }
      case LessonType.WorkOnMistakes: {
        lessonSubRoute = StudentRoutes.WorkOnMistakes
      }
    }
    const route = lessonSubRoute ===
    StudentRoutes.Test ? `${lessonSubRoute}/${StudentRoutes.TestStart}/${item.groupId}/${item.courseId}/${item.id}`
    : `${StudentRoutes.Education}/${lessonSubRoute}/${item.groupId}/${item.id}`
    return route;
  }
}
