import { DateHelperServiceService } from './../../../../shared/services/dateHelperService/date-helper-service.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SelectValue } from 'src/app/shared/models';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterComponent {
  @Input() isOpen: boolean = true;
  @Input() teachers: SelectValue[] = [];
  @Output() closeEvent = new EventEmitter<void>();
  @Output() saveEvent:EventEmitter<{ years: number[], teachers: string[] }> = new EventEmitter<{ years: number[], teachers: string[] }>();

  public years: SelectValue[] = this.dateHelperServiceService.getYears();

  public form!: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private dateHelperServiceService: DateHelperServiceService) {
    this.form = this._formBuilder.group({
      teachers: [''],
      years: [''],
    });
  }

  public closeDialog(): void {
    this.closeEvent.emit();
  }
  public save(): void {
    this.saveEvent.emit({years: this.form.value.years, teachers: this.form.value.teachers});
  }

  public setValue(name: string, value: any[]): void {
    this.form.get(name)?.setValue(value);
  };
}
