import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {LessonDTO, Roles} from "../../../../../../core/model";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {LessonsService} from "../../../../../../core/services/lessons/lessons.service";
import {Observable, Subscription} from "rxjs";
import {AuthService} from "../../../../../../core/services/auth/auth.service";
import {ActivatedRoute, NavigationStart, Router} from "@angular/router";
import {CuratorService} from "../../../../../../core/services/curator/curator.service";
import {SelectValue} from "../../../../../../shared/models";
import { FoldersService } from './../../../../../../core/services/folders/folders.service';

@Component({
  selector: 'app-text-controller',
  templateUrl: './text-controller.component.html',
  styleUrls: ['./text-controller.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextControllerComponent implements OnInit, OnChanges, OnDestroy  {
  @Input() data: LessonDTO | undefined;
  @Input() name!: string;
  @Input() isOpen: boolean = false;

  @Input() saveEvent!: Observable<void>;
  @Output() allowSaveEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() savedEvent: EventEmitter<LessonDTO> = new EventEmitter<LessonDTO>();

  private eventsSubscription!: Subscription;
  private routerSubscription!: Subscription;
  public baseForm!: FormGroup;
  public baseFormErrors = {
    description: "",
    materials: ""
  }

  public unsavedChanges: boolean = false;
  public confirmationShown: boolean = false;
  public roles = Roles;
  public role: Roles = Roles.Methodist;
  public groupId: string = "";
  public themeId: string = ""

  public studentsList: SelectValue[] = [];

  public foldersOptions: SelectValue[] = [];

  constructor(
    private lessonsService: LessonsService,
    private curatorService: CuratorService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private FoldersService: FoldersService

  ) {
    this.baseForm = this.formBuilder.group({
      description: ['', Validators.required],
      materials: [[]],
      usersIds: [[]],
      courseFolderId: ['']
    });

    this.baseForm.statusChanges.subscribe(status => {
      const anyCustomError: boolean =
        !!(this.baseFormErrors.description
          && this.baseFormErrors.materials);
      this.allowSaveEvent.emit((status === 'VALID' && !anyCustomError));
    });

    this.baseForm.valueChanges.subscribe(res => {
      this.unsavedChanges = true;
      if(!res.description) {
        this.baseFormErrors.description = "TYPE_DESCRIPTION";
        this.allowSaveEvent.emit(false);
      }
      else {
        this.baseFormErrors.description = "";
      }

      this.cdr.detectChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['data'] && this.data) {
      if(this.authService.getRole() === Roles.Methodist) {
        this.route.params.subscribe(params => {
          this.FoldersService.getFoldersMethodist(params['id']!).subscribe({
            next: (res) => {
              this.foldersOptions = res[0].courseFolders.map(item => {
                return {
                  name: item.courseFolderName,
                  value: item.courseFolderId
                }
              });
              this.cdr.detectChanges();
              this.baseForm.patchValue({ courseFolderId: this.data?.courseFolderId || []})

              this.cdr.detectChanges();
            },
            error: (err) => {
              console.log(err);
            }
          })
          this.cdr.detectChanges();
        })
      }
      if(this.authService.getRole() === Roles.Curator) {
        this.route.params.subscribe(params => {
          this.groupId = params['groupId'];
          this.themeId = params['themeId'];

          this.FoldersService.getFoldersCurator( this.groupId).subscribe({
            next: (res) => {

              this.foldersOptions = res[0].courseFolders.map(item => {
                return {
                  name: item.courseFolderName,
                  value: item.courseFolderId
                }
              });
              this.cdr.detectChanges();
              this.baseForm.patchValue({ courseFolderId: this.data?.courseFolderId || []})

              this.cdr.detectChanges();
            },
            error: (err) => {
              console.log(err);
            }
          })

          this.curatorService.getStudentsByGroupIds([this.groupId]).subscribe(res => {
            this.studentsList = res.map(item => {
              return {
                name: item.firstname + " " + item.lastname,
                value: item.id || ''
              }
            }).sort((a, b) => (a.name).localeCompare((b.name)));
            this.cdr.detectChanges();
          });

          this.cdr.detectChanges();
        });

        if(!this.data?.common) {
          this.baseForm.setControl('usersIds', new FormControl([], []));
          this.baseForm.patchValue({'usersIds': this.data?.usersIds});
        }
      }

      if(this.authService.getRole() === Roles.HeadTeacher) {
        this.route.params.subscribe(params => {
          const groupId = params['groupId'];
          const courseId = params['id'];
          this.FoldersService.getFoldersHeadTeacher(courseId, groupId).subscribe({
            next: (res) => {

              this.foldersOptions = res[0].courseFolders.map(item => {
                return {
                  name: item.courseFolderName,
                  value: item.courseFolderId
                }
              });
              this.cdr.detectChanges();
              this.baseForm.patchValue({ courseFolderId: this.data?.courseFolderId || []})

              this.cdr.detectChanges();
            },
            error: (err) => {
              console.log(err);
            }
          })
          this.cdr.detectChanges();
        });

        if(!this.data?.common) {
          this.baseForm.setControl('usersIds', new FormControl([], []));
          this.baseForm.patchValue({'usersIds': this.data?.usersIds});
        }
      }

      this.baseForm.patchValue({ description: this.data?.description});
      this.baseForm.patchValue({ materials: this.data?.materials});
      this.baseForm.patchValue({ usersIds: this.data?.usersIds || []});
      this.cdr.detectChanges();
    }
  }

  ngOnInit(): void {
    this.role = this.authService.getRole();

    this.eventsSubscription = this.saveEvent.subscribe(() => {
      this.save();
    });

    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.isOpen && this.unsavedChanges && !this.confirmationShown) {
          this.confirmationShown = true;
          const shouldContinue = confirm(`В "${this.data?.name}" есть не сохраненые изменения.Хотите продолжить?`);
          if (!shouldContinue) {
            const currentRoute = this.router.routerState;
            this.router.navigateByUrl(currentRoute.snapshot.url, { skipLocationChange: true }).then();
          }
        }
      }
    });
  }

  public downloadedMaterialFile(id: string): void {
    const currentMaterials = this.baseForm.get('materials')?.value || [];
    const newMaterials = [...currentMaterials, id];

    this.baseForm.patchValue({ materials: newMaterials });
    this.baseFormErrors.materials = "";
  }

  public removeMaterialFile(id: string): void {
    const currentMaterials = this.baseForm.get('materials')?.value || [];
    const updatedMaterials = currentMaterials.filter((fileId: string) => fileId !== id);

    this.baseForm.patchValue({ materials: updatedMaterials });
  }

  private save(): void {
    const baseForm = this.baseForm.value;
    console.log(this.baseForm)
    const data = {
      id: this.data!.id,
      lessonType: this.data!.lessonType,
      name: this.name,
      description: baseForm.description,
      rating: false,
      content: '',
      videoUrl: '',
      miniTestMaxScore: 0,
      mustHaveMaterials: [],
      materials: baseForm.materials,
      additionalMaterials: [],
      usefulLinks: [],
      usersIds: baseForm.usersIds,
      courseFolderId: baseForm.courseFolderId
    }
    this.lessonsService.updateTextById(this.groupId, this.themeId, this.data!.id, data).subscribe(res => {
      this.savedEvent.emit(res);
      this.unsavedChanges = false;
      this.cdr.detectChanges()
    });
  }

  public studentsSelected($event: any[]): void {
    this.baseForm.patchValue({usersIds: $event});
  }

  ngOnDestroy(): void {
    this.eventsSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }
}
