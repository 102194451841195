import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export interface ISidebarItem {
  id: number | string;
  label: string;
  icon?: string;
  link: string;
  subitems?: { label: string; link: string }[];
}

@Component({
  selector: 'app-sidebar-item',
  templateUrl: './sidebar-item.component.html',
  styleUrls: ['./sidebar-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SidebarItemComponent {
  @Input() sidebar!: ISidebarItem;

  public isOpen: boolean = true;

  public toggleIsOpen(): void {
    this.isOpen = !this.isOpen;
  }
}
