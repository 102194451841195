import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LessonsItemComponent } from './lessons-item/lessons-item.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { CdkDrag, DragDropModule, CdkDragPreview } from '@angular/cdk/drag-drop';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { CdkAccordionModule } from '@angular/cdk/accordion';

import { TheoryControllerComponent } from './lessons-item/theory-controller/theory-controller.component';
import { HomeworkControllerComponent } from './lessons-item/homework-controller/homework-controller.component';
import { MiniTestCreatorComponent } from './lessons-item/mini-test-creator/mini-test-creator.component';
import { LessonsListComponent } from './lessons-list.component';
import { TestControllerComponent } from './lessons-item/test-controller/test-controller.component';
import { OpenTestCreatorComponent } from './lessons-item/test-controller/open-test-creator/open-test-creator.component';
import { MatchingTestControllerComponent } from './lessons-item/test-controller/matching-test-controller/matching-test-controller.component';
import { InsertTestControllerComponent } from './lessons-item/test-controller/insert-test-controller/insert-test-controller.component';
import { TestTestControllerComponent } from './lessons-item/test-controller/test-test-controller/test-test-controller.component';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { TestContentClosedComponent } from './lessons-item/test-controller/test-content-closed/test-content-closed.component';
import { WorkOnMistakesControllerComponent } from './lessons-item/work-on-mistakes-controller/work-on-mistakes-controller.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TextControllerComponent } from './lessons-item/text-controller/text-controller.component';
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [
    LessonsListComponent,
    LessonsItemComponent,
    TheoryControllerComponent,
    HomeworkControllerComponent,
    MiniTestCreatorComponent,
    TestControllerComponent,
    OpenTestCreatorComponent,
    MatchingTestControllerComponent,
    InsertTestControllerComponent,
    TestTestControllerComponent,
    TestContentClosedComponent,
    WorkOnMistakesControllerComponent,
    TextControllerComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,

    FormsModule,
    ReactiveFormsModule,

    CdkDrag,
    DragDropModule,
    CdkDragPreview,
    MatMenuModule,
    MatCheckboxModule,
    PipesModule,
    MatExpansionModule,
    CdkAccordionModule,
    MatSlideToggleModule,
    TranslateModule
  ],
  exports: [
    LessonsListComponent,
    MatchingTestControllerComponent
  ],
})
export class LessonsListModule { }
