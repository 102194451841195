import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ForAnswerTestQuestions, QuizState, UserAnswer } from 'src/app/core/model';
import { ICdkSelectOption } from 'src/app/shared/components/material/cdk-select/_model';

interface CustomValueList {
  index: number,
  value: string | number
}

@Component({
  selector: 'app-multidropdown',
  templateUrl: './multidropdown.component.html',
  styleUrls: ['./multidropdown.component.scss']
})
export class MultidropdownComponent implements OnInit, OnChanges {
  public readonly _QuizState = QuizState;
  @Input() quizState: QuizState = QuizState.Passing;
  @Input() question!: ForAnswerTestQuestions;
  @Input() disabled: boolean = false;
  @Output() answeredEvent: EventEmitter<{ questionId: string, answer: UserAnswer[]}> = new EventEmitter<{ questionId: string, answer: UserAnswer[]}>();
  
  public options: ICdkSelectOption[] = [];

  public userAnswers: UserAnswer[] = [];
  constructor(
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
      if(changes['question'] && this.question) {
        this.options = this.question.answers.map(item => {
          return {
            value: item,
            viewValue: item
          }
        })
      }
  }


  public selected(index: number, $event: any) {
    this.userAnswers[index] = {answerTextValue:  this.question.questions[index] +  ' ' + $event}
    this.answeredEvent.emit({ questionId: this.question.id, answer: this.userAnswers})
  }
  
  public getAnswer(answer: string, index: number): string | undefined{
    const ua = this.question.userAnswers?.find(item => item.answerTextValue?.includes(this.question.questions[index]));
    return ua ? this.question.answers.find(item => ua.answerTextValue?.includes(item)) : answer;
  }
}
