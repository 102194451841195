import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IShortLesson, LessonDTO } from '../../model';

@Injectable({
  providedIn: 'root'
})
export class MethodistService {

  constructor(private httpClient: HttpClient) { }

  public getLessonsList(themeId: string): Observable<IShortLesson[]> {
    return this.httpClient.get<IShortLesson[]>(`/v2/methodist/themes/${themeId}/lessons`);
  }

  public getLesson(lessonId: string): Observable<LessonDTO> {
    return this.httpClient.get<LessonDTO>(`/v2/methodist/lessons/${lessonId}`)
  }

  public addLessons(body: {
    themeId: string,
    lessonIds: string[]
  }): Observable<any> {
    return this.httpClient.post<any>(`/v2/methodist/themes/add-lessons`, body);
  }

  public addToThemes(body: {
    lessonId: string,
    themeIds: string[]
  }): Observable<any> {
    return this.httpClient.post<any>(`/v2/methodist/lesson/add-to-themes`, body);
  }
}
