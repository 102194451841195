<app-dialog
    [isOpen]="isOpen"
    [title]="'personalInformation' | translate"
    (closeEvent)="closeEvent.emit()"
    (confirmEvent)="editProfile()"
    [showButtons]="true"
    [disabledConfirm]="userForm.invalid"
  >
  <form [formGroup]="userForm" class="app-dialog-content">
    <app-input
      *ngIf="youRole === Roles.Admin"
      [error]="userFormGroupError.email"
      [name]="'email' | translate"
      formControlName="email"
      (input)="emailTyping(userForm, userFormGroupError, 'email')"
    ></app-input>
    <app-input
      *ngIf="youRole === Roles.Admin"
      [error]="userFormGroupError.phoneNumber"
      [name]="'phoneNumber' | translate"
      formControlName="phoneNumber"
      (input)="phoneTyping(userForm, userFormGroupError, 'phoneNumber')"
      prefix="+7"
      [mask]="' (000) 000-00-00'"
    ></app-input>
    <app-input
      [error]="userFormGroupError.firstname"
      [name]="'firstName' | translate"
      formControlName="firstname"
      (input)="
        handleFormControlErrors(userForm, userFormGroupError, 'firstname')
      "
    >
    </app-input>
    <app-input
      [error]="userFormGroupError.lastname"
      [name]="'lastName' | translate"
      formControlName="lastname"
      (input)="
        handleFormControlErrors(userForm, userFormGroupError, 'lastname')
      "
    >
    </app-input>
    <app-input
      [name]="'middleName' | translate"
      formControlName="middlename"
    >
    </app-input>
    <app-input
      *ngIf="role === Roles.Student"
      [error]="userFormGroupError.parentPhoneNumber"
      [name]="'parentsPhoneNumber' | translate"
      formControlName="parentPhoneNumber"
      prefix="+7"
      mask=" (000) 000-00-00"
      (input)="
        handleFormControlErrors(
          userForm,
          userFormGroupError,
          'parentPhoneNumber'
        )
      "
    >
    </app-input>
    <app-select-town
      [preSelectedValue]="userForm.get('regionId')?.value"
      (input)="handleFormControlErrors(userForm, userFormGroupError, 'grade')"
      (valueSelected)="userForm.patchValue({regionId: $event})">
    </app-select-town>
    <app-select
      *ngIf="role === Roles.Student"
      [error]="userFormGroupError.grade"
      [name]="'educationGrade' | translate"
      formControlName="grade"
      [options]="educationClasses"
      (input)="
        handleFormControlErrors(userForm, userFormGroupError, 'grade')
      "
    >
    </app-select>
    <app-select
      *ngIf="role === Roles.Student"
      [error]="userFormGroupError.firstSubjectId"
      [name]="'firstExamineSubject' | translate"
      formControlName="firstSubjectId"
      [options]="firstSubjects"
      (selectedValue)="onFirstSubjectChoosed($event)"
      (input)="
        handleFormControlErrors(
          userForm,
          userFormGroupError,
          'firstSubjectId'
        )
      "
    >
    </app-select>
    <app-select
      *ngIf="role === Roles.Student"
      [error]="userFormGroupError.secondSubjectId"
      [name]="'secondExamineSubject' | translate"
      formControlName="secondSubjectId"
      [options]="secondSubjects"
      (selectedValue)="onSecondSubjectChoosed($event)"
      (input)="
        handleFormControlErrors(
          userForm,
          userFormGroupError,
          'secondSubjectId'
        )
      "
    >
    </app-select>
    <app-input
      [error]="userFormGroupError.instagramLink"
      name="Инстаграм"
      formControlName="instagramLink"
      prefix="instagram.com/"
      mask="A*"
      (input)="
        handleFormControlErrors(
          userForm,
          userFormGroupError,
          'instagramLink'
        )
      "
    >
    </app-input>
  </form>
</app-dialog>
