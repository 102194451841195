import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CourseSkeletonComponent } from './course-skeleton/course-skeleton.component';
import { StreamSkeletonComponent } from './stream-skeleton/stream-skeleton.component';
import { TableSkeletonComponent } from './table-sceleton/table-skeleton.component';
import { UserCardSkeletonComponent } from './user-card-skeleton/user-card-skeleton.component';



@NgModule({
  declarations: [
    CourseSkeletonComponent,
    StreamSkeletonComponent,
    TableSkeletonComponent,
    UserCardSkeletonComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CourseSkeletonComponent,
    StreamSkeletonComponent,
    TableSkeletonComponent,
    UserCardSkeletonComponent,
  ]
})
export class SkeletonsModule { }
