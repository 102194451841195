import { PaymentsService } from 'src/app/core/services/payments/payments.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UsersService } from './../../../services/users/users.service';
import { Injectable } from "@angular/core";
import { getProfileInfo, getProfileInfoSuccess, getUserPaymentHistoryAction, getUserPaymentHistorySuccessAction, updateUserProfileInfo, updateUserProfileInfoSuccess } from './user.actions';
import { EMPTY, catchError, map, mergeMap, of } from 'rxjs';
import { StudentUser } from 'src/app/core/model';

@Injectable()
export class UserEffects {

    loadUser$ = createEffect(() => this.actions$.pipe(
        ofType(getProfileInfo),
        mergeMap(() => this.usersService.getUserProfile()
        .pipe(
            map(res => getProfileInfoSuccess({ payload: (res as StudentUser) })),
            catchError(() => EMPTY)
        ))
    ));

    constructor(
        private actions$: Actions,
        private usersService: UsersService,
    ) {}
}


@Injectable()
export class UserUpdateEffects {

    updateUserProfileInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserProfileInfo),
      mergeMap((action) =>
        this.usersService.updateUserProfile(action.payload).pipe(
          map((user) => updateUserProfileInfoSuccess({ payload: user })),
          catchError((error) => of(/* handle error here */))
        )
      )
    )
  );

    constructor(
        private actions$: Actions,
        private usersService: UsersService,
    ) {}
}

@Injectable()
export class PaymentEffects {

    loadPayment$ = createEffect(() => this.actions$.pipe(
        ofType(getUserPaymentHistoryAction),
        mergeMap(() => this.paymentsService.getMyPaymentHistory()
        .pipe(
            map(res => getUserPaymentHistorySuccessAction({ payload: res})),
            catchError(() => EMPTY)
        ))
    ))

    constructor(
        private actions$: Actions,
        private paymentsService: PaymentsService
    ) {}
}