import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StudentModule } from './student/student.module';
import { MethodistModule } from './metodist/methodist.module';
import { CuratorFeatureModule } from './curator/curator.module';
import { HeadTeacherModule } from './head-teacher/head-teacher.module';
import { CataloguesModule } from './catalogues/catalogues.module';
import { AdminModule } from './admin/admin.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StudentModule,
    MethodistModule,
    CuratorFeatureModule,
    HeadTeacherModule,
    CataloguesModule,
    AdminModule,
  ],
  exports: [
    StudentModule,
    MethodistModule,
    HeadTeacherModule,
    CataloguesModule,
    CuratorFeatureModule,
    AdminModule,
  ],
})
export class FeatureModule {}
