<div class="table bg-white radius-8 border">
  <div class="flex wrapper">
    <div
      class="flex flex-col"
      *ngFor="let spanHeader of headerColumns; let lastHeaderSpan = last; first as first"
      [class.d-sticky]="spanHeader.acessor === 'fio'"
    >
      <div
        class="header__item flex items-center justify-center"
        [ngStyle]="{
          'min-width': spanHeader.width + 'px',
          'max-width': spanHeader.width + 'px',
          'border-right': lastHeaderSpan ? 'none' : ''
        }"
      >
        <div class="header__item-label" [class.justify-center]="!first">
          {{ spanHeader.label | translate }}
        </div>

        <div class="header__item-max-score" *ngIf="spanHeader.maxScore">
          <span class="label">{{'MAX_SHORT' | translate}}:</span>
          <span class="max-score">{{ (spanHeader.maxScore | localizedNumberFormat) ?? "-" }}</span>
        </div>
      </div>

      <ng-container
        *ngFor="let row of rowColumns; let last = last; let i = index;"
      >
        <div >
          <div
            *ngIf="
              row[spanHeader.acessor] &&
              spanHeader.acessor !== 'action' &&
              spanHeader.acessor !== 'fio' &&
              spanHeader.acessor !== 'group' &&
              spanHeader.acessor !== 'finalScore'
        "
            class="row__item"
            [ngStyle]="{
              'min-width': spanHeader.width + 'px',
              'max-width': spanHeader.width + 'px',
              'border-bottom': last ? 'none' : '',
              'border-right': lastHeaderSpan ? 'none' : ''
            }"
          >
            {{ row[spanHeader.acessor]  | localizedNumberFormat}}
          </div>

          <div
            *ngIf="
              spanHeader.acessor !== 'action' &&
              spanHeader.acessor !== 'fio' &&
              spanHeader.acessor !== 'group' &&
              spanHeader.acessor !== 'finalScore'
            "
            class="row__item progress"
            [class.myself]="role === Roles.Student && row.id === userId"
            [ngStyle]="{
              'min-width': spanHeader.width + 'px',
              'max-width': spanHeader.width + 'px',
              'border-right': lastHeaderSpan ? 'none' : ''
            }"
            (click)="progressClick(row, spanHeader, i)"
          >
            <span class="row-of-table-for-detect-spinner">{{ getScrore(row.items, spanHeader.acessor) | localizedNumberFormat }} </span>
          </div>

          <!-- // for fio click -->
          <div
            *ngIf="
              row[spanHeader.acessor] &&
              spanHeader.acessor !== 'action' &&
              (spanHeader.acessor === 'fio' || spanHeader.acessor === 'group' || spanHeader.acessor === 'finalScore')
            "
            class="row__item"
            [class.myself]="role === Roles.Student && row.id === userId"
            [ngStyle]="{
              'min-width': spanHeader.width + 'px',
              'max-width': spanHeader.width + 'px',
              'border-right': lastHeaderSpan ? 'none' : ''
            }"
          >
            <button
              (click)="onFioClick(row)"
              *ngIf="spanHeader.acessor === 'fio'"
              class="button-fio item"
              [ngClass]="{
                'gold': role === Roles.Student && i === 0,
                'silver': role === Roles.Student &&  i === 1,
                'bronze': role === Roles.Student && i === 2,
                'special': role === Roles.Student && i < 3,
                'myself': role === Roles.Student && (spanHeader.studentId === userId || row.id === userId)
              }"
            >
              <div class="left">
                <div class="position" [class.hide]="hidePositionMobile">
                  <span class="position-text">{{i + 1  | localizedNumberFormat}}</span>
                </div>
                <app-avatar *ngIf="row.studentProfilePhotoUrl || row.studentFirstLetter"
                            [imageSrc]="row.studentProfilePhotoUrl"
                            [firstLetter]="row.studentFirstLetter"
                            size="small"/>
<!--                <img class="image" *ngIf="row.studentProfilePhotoUrl" [src]="fileBaseUrl + row.studentProfilePhotoUrl" alt="User Image"/>-->
                <div class="image-text" *ngIf="!spanHeader.studentProfilePhotoUrl">
                  <span>{{row.id === userId ? ('you' | translate) : row[spanHeader.acessor]  | localizedNumberFormat}}</span>
                </div>
              </div>
            </button>
            <span *ngIf="spanHeader.acessor === 'group'">
              {{ row[spanHeader.acessor] | localizedNumberFormat }}
            </span>
            <span *ngIf="spanHeader.acessor === 'finalScore'">
              {{ row[spanHeader.acessor] !== '-' ? (row[spanHeader.acessor] | localizedNumberFormat) : row[spanHeader.acessor]}}
            </span>
          </div>

          <div
            class="row__item"
            *ngIf="row[spanHeader.acessor] && spanHeader.acessor === 'action'"
            [ngStyle]="{
            'min-width': spanHeader.width + 'px',
            'max-width': spanHeader.width + 'px',
            'border-right': lastHeaderSpan ? 'none' : '',

          }"
          >
            <button class="action-btn" (click)="onActionClick(row.id)">
              {{'MORE_DETAILS' | translate}}
            </button>
          </div>
          <div
            class="row__item"
            *ngIf="
              !row[spanHeader.acessor] &&
              row[spanHeader.acessor] !== 0 &&
              !row.items
            "
            [ngStyle]="{
            'min-width': spanHeader.width + 'px',
            'max-width': spanHeader.width + 'px',
            'border-right': lastHeaderSpan ? 'none' : '',

          }"
          >
            -
          </div>
        </div>
      </ng-container>
      <div class="row" *ngIf="rowColumns.length > 0 && isAverageRow">
        <div class="row__item flex items-center justify-center">
          <img
            class="average-icon"
            src="./assets/svg/average.svg"
            alt=""
            *ngIf="spanHeader.acessor === 'fio'"
          />

          <span *ngIf="spanHeader.acessor === 'fio'">{{'AVERAGE_RATING' | translate}}</span>
          <span *ngIf="spanHeader.acessor === 'group'"></span>

          <span
            class="rounded flex items-center"
            *ngIf="
              spanHeader.acessor !== 'fio' && spanHeader.acessor !== 'group'
            "
            >{{ getAverage(rowColumns, spanHeader.acessor)  | localizedNumberFormat }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="footer flex" *ngIf="totalPages && totalPages > 1">
  <ng-container *ngFor="let page of [].constructor(totalPages); let i = index">
    <button
      class="page flex items-center justify-center"
      [ngClass]="{ page_active: pageNumber === i }"
      (click)="onPageClick(i)"
    >
      {{ i + 1  | localizedNumberFormat }}
    </button>
  </ng-container>
</div>
