import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { SidebarMainComponent } from './sidebar-main/sidebar-main.component';
import {NotificationModule} from "../../feature/notification/notification.module";
import {TranslateModule} from "@ngx-translate/core";
import { NonBaseModule } from '../components/non-base/non-base.module';

@NgModule({
  declarations: [HeaderComponent, SidebarMainComponent],
    imports: [CommonModule, RouterModule, MatMenuModule, NotificationModule, TranslateModule, NonBaseModule],
  exports: [HeaderComponent, SidebarMainComponent,],
})
export class LayoutsModule {}
