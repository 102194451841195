import { TranslateService } from '@ngx-translate/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { getFormControlErrors, passwordMatchValidator } from 'src/app/shared/validators';
import jwt_decode from "jwt-decode";
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonRoutes, Decode, RecoverPassword } from '../../model';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordComponent implements OnInit {
  public form!: FormGroup;
  public formErrors = {
    password: "",
    repassword: "",
  };

  public token: string = "";

  constructor(
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private authService: AuthService,
    private router: Router,
    )
  {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      repassword: ['', [Validators.required, Validators.minLength(6)]]
    }, { validator: passwordMatchValidator() });

    this.route.paramMap.subscribe(params => {
      this.token = params.get('token') || "";
      if(this.token) {
        try {
          const decoded = jwt_decode(this.token) as Decode;

          this.translateService.use(decoded.language);

          if(decoded.username) {
            this.form.addControl('username', this.formBuilder.control(decoded.username, Validators.required))
          }
          if(decoded.phoneNumber) {
            this.form.addControl('phoneNumber', this.formBuilder.control(decoded.phoneNumber, Validators.required))
          }
          this.cdr.detectChanges();
        }
        catch (e) {
          if (e instanceof Error) {
            console.error('An error occurred:', e);
            this.toastr.error('Қате токен', 'Токен');
          } else {
            console.error('Unknown error:', e);
            this.toastr.error('Қате токен', 'Токен');
          }
        }
      }
    })
  }

  public handleFormControlErrors(form: FormGroup, error: any, controlName: string): void {
    getFormControlErrors(form, error, controlName, this.translateService);
    this.cdr.detectChanges();
  };

  public resetPassword(): void {
    const data: RecoverPassword = {
      token: this.token,
      newPassword: this.form.get('repassword')?.value
    }

    if(this.form.get('username')) {
      data.username = this.form.get('username')?.value;
    }
    if(this.form.get('phoneNumber')) {
      data.phoneNumber = this.form.get('phoneNumber')?.value;
    }

    this.authService.recoverPassword(data).subscribe({
      next: () => {
        this.toastr.success(this.translateService.instant('passwordRecoderedSuccess'))
        this.router.navigate([`${CommonRoutes.Authorization}`]);
        this.cdr.detectChanges();
      },
      error: (error) => {
        console.log(error.error.messages[0]);
        // this.toastr.error(error.error.messages[0], this.translateService.instant('error'));
        this.cdr.detectChanges();
      }
    })
  }
}
