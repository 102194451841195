<div class="container">
  <app-expander [isExpanded]="isOpen">
    <button class="button" (click)="toggle()" button>
      <h1 class="title">{{subject.subjectNameKz}}</h1>
      <app-primary-arrow [isOpen]="!isOpen" [openColor]="'#9399AB'"></app-primary-arrow>
    </button>
    <div content>
      <div class="iconic-line line"></div>
      <div class="content">
        <ul class="list">
          <li *ngFor="let disc of discounts" class="item">
            <button
              class="percent-button"
              [ngClass]="{'percent-button--active': isOpen && disc.isActive}"
              (click)="setActiveDiscount(disc.id)"
              >
              {{ disc.percent + '% (' + disc.studentsCount + ')'}}
            </button>
          </li>
        </ul>
        <table class="table">
          <thead class="head">
            <tr class="row">
              <th
                class="cell head-cell"
                *ngFor="let item of headers;let first=first"
                [ngClass]="{'head-cell--first': first }">{{item}}</th>
            </tr>
          </thead>
          <tbody class="body">
            <tr
              class="row body-row"
              *ngFor="let row of rows;let i = index"
              (click)="pickedUser(i)"
              >
              <td
                class="cell row-cell"
                *ngFor="let key of Object.keys(row);let first=first"
                [ngClass]="{'row-cell--first': first}"
                style="width: 234px;height: 32px;vertical-align: middle;">
                <div [class.justify-center]="!first" style="display: flex;flex-flow: row;gap: 8px;align-items: center;"
                >
                  <div *ngIf="first && data[i].studentProfilePhotoUrl"
                  [ngStyle]="{'background-image': 'url('+ environment.filesUrl + data[i].studentProfilePhotoUrl +')'}"
                  style="min-width: 32px !important; min-height: 32px !important;border-radius: 100%;background-position: center;background-size: cover;"
                  ></div>
                  <span [innerHTML]="row[key]">
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </app-expander>
</div>
