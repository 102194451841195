import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SystemAlerts } from "../../../shared/models/interfaces/system-alerts";

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  currentSystemAlert$: BehaviorSubject<SystemAlerts | null> = new BehaviorSubject<SystemAlerts | null>(null);

  constructor(private _http: HttpClient) {}

  public getSystemAlerts(): Observable<SystemAlerts[]> {
    return this._http.get<SystemAlerts[]>('/v1/system-alerts');
  }

  public getSystemAlert(): SystemAlerts | null {
    return this.currentSystemAlert$.value;
  }

  public setSystemAlert(value: SystemAlerts | null): void {
    this.currentSystemAlert$.next(value);
  }
}
