<div class="app-editor" [ngClass]="{'view-mode': isViewMode, 'read-mode': !isViewMode}">
    <h1 class="name">{{name | translate}}</h1>
    <ckeditor
        id="editor"
        (change)="change.emit()"
        [ngClass]="{'editor-error': error}"
        [formControl]="ngControl.control"
        class="editor disable-selection"
        [editor]="Editor"
        (ready)="onReady($event)"
        [config]="editorConfig"
        ></ckeditor>
    <span class="error-message" *ngIf="error">{{error | translate}}</span>
</div>
