import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ROLES_LABEL } from 'src/app/core/model';
import { AdminService } from 'src/app/core/services/admin/admin.service';
import { UsersService } from 'src/app/core/services/users/users.service';
import { ToastService } from 'src/app/shared/services/toast/toast.service';

@Component({
  selector: 'app-user-right-modal',
  templateUrl: './user-right-modal.component.html',
  styleUrls: ['./user-right-modal.component.scss'],
})
export class UserRightModalComponent implements OnInit {
  // public isShow: boolean = false;
  public selectedUser: any;
  public rolesLabel = ROLES_LABEL;
  private userId: string;
  public isDataLoading = false;
  constructor(
    private _userService: UsersService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserRightModalComponent>,
    private cdr: ChangeDetectorRef,
    public ts: ToastService
  ) {
    this.userId = data.userId;
  }

  ngOnInit(): void {
    this.getUserInfo();
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public getUserInfo(): void {
    this.isDataLoading = true;
    this._userService.getUserById(this.userId).subscribe({
      next: (res) => {
        this.selectedUser = res;
        this.cdr.detectChanges();
      },
      error: (err) => {
        this.ts.showError('Ошибка при получении данных по пользователю');
      },
      complete: () => {
        this.isDataLoading = false;
        this.cdr.detectChanges();
      },
    });
  }
}
