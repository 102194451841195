import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconicTableComponent } from './iconic-table/iconic-table.component';
import { PaginatorFilterTableComponent } from './paginator-filter-table/paginator-filter-table.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [IconicTableComponent, PaginatorFilterTableComponent],
  exports: [IconicTableComponent, PaginatorFilterTableComponent]
})
export class TablesModule { }
