import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounce, timer } from 'rxjs';
import { SearchSize } from 'src/app/shared/models/enums/search.enum';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit {
  @Input() isSecondary: boolean = false;
  @Input() placeholder: string = '';

  @Input() size: SearchSize = SearchSize.Small;
  @Input() fullWidth: boolean = false;
  @Input() width50: boolean = false;
  @Input() allowTypingSpace: boolean = true;
  @Output() searchListener: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    this.searchText.valueChanges
      .pipe(debounce(() => timer(800)))
      .subscribe((response) => this.searchListener.emit(response));
  }

  public searchText: FormControl = new FormControl('');

  public searchSize = SearchSize;

  onSpaceKeydown(event: any): void {
    if (!this.allowTypingSpace) {
      event.preventDefault();
    }
  }
}
