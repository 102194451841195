<div class="user-info">
  <div class="flex">
    <div class="img skeleton"></div>
    <div class="flex-col">
      <div class="skeleton-text skeleton"></div>
      <div class="skeleton-text-mini skeleton"></div>
    </div>
  </div>
  <div class="user__body">
    <div class="flex">
      <div class="skeleton-text skeleton"></div>
      <div class="skeleton-text-mini skeleton"></div>
    </div>
    <div class="flex">
      <div class="skeleton-text skeleton"></div>
      <div class="skeleton-text-mini skeleton"></div>
    </div>
    <div class="flex">
      <div class="skeleton-text skeleton"></div>
      <div class="skeleton-text-mini skeleton"></div>
    </div>
    <div class="flex">
      <div class="skeleton-text skeleton"></div>
      <div class="skeleton-text-mini skeleton"></div>
    </div>
  </div>
</div>
