import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { LessonSummary } from 'src/app/core/model';
import { CuratorService } from 'src/app/core/services/curator/curator.service';
import { EducationService } from "../../../../shared/services/education/education.service";
import {ButtonColors, ButtonSize, ButtonTypes} from "../../../../shared/models/enums/button.enum";

@Component({
  selector: 'app-lecture-card',
  templateUrl: './lecture-card.component.html',
  styleUrls: ['./lecture-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LectureCardComponent {
  @Input() lesson!: LessonSummary;
  @Input() groupId: string = '';
  @Input() themeId: string = '';

  @Output() onSelectLessonListener: EventEmitter<any> = new EventEmitter();

  public editButtonType: ButtonTypes = ButtonTypes.Outline;
  public editButtonSize: ButtonSize = ButtonSize.Small;
  public editButtonColor: ButtonColors = ButtonColors.Black;

  public isMobile: boolean = false;
  constructor(
    private _curatorService: CuratorService,
    private educationService: EducationService
  ) {

    if(window.innerWidth > 999) {
      this.isMobile = false;
    }
    else {
      this.isMobile = true
    }
    
  }

  public onSelectLesson(): void {
    this._curatorService.selectedLesson$!.next(this.lesson)!;
    this.onSelectLessonListener.emit(this.lesson);
  }

  public navigateTo() {
    this.educationService.navigateByLessonType(this.lesson, this.groupId, this.themeId);
  }
}
