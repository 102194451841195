<header class="header">
  <h1 class="title">{{'SUBJECTS' | translate}}</h1>
  <button class="add-button" (click)="openAddSubjects()">{{'ADD_SUBJECT' | translate}}</button>
</header>
<ul class="list">
  <li class="item" *ngFor="let item of subjectsCopy">
    <h1 class="name">{{item.nameKz}}</h1>
    <button class="remove-button" (click)="removeSubject(item.id)">Открепить</button>
  </li>
</ul>

<app-admin-headteacher-link-subject
  [headteacherId]="headteacherId"
  [isOpen]="isAddSubjectsOpen"
  [currentSubjects]="subjectsCopy"
  (closeEvent)="closeAddSubjects()"
  [role]="role"
  (confirmEvent)="confirmAddSubjects($event)"

  >
</app-admin-headteacher-link-subject>
