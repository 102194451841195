import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { SelectValue } from 'src/app/shared/models';
import { PaymentsService } from 'src/app/core/services/payments/payments.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.scss']
})
export class RefundComponent implements OnInit , OnChanges{
  @Input() isOpen: boolean = false;
  @Output() closeEvent = new EventEmitter<void>();
  @Input() data: any;

  public form!: FormGroup;
  
  public refundOptions: SelectValue[] = [
    {
      value: 'PARTIAL_REFUND',
      name: 'Частично возвращено'
    },
    {
      value: 'FULL_REFUND',
      name: 'Возвращено'
    },
    {
      value: "NULL_REFUND",
      name: '-'
    },
  ]
  constructor(
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private paymentsService: PaymentsService,
    private toastrService: ToastrService
  ) {

    this.form = new FormGroup({
      refundStatus: new FormControl(null, Validators.required),
      refundComment: new FormControl('')
    })
   }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
      if(changes['data'] && this.data) {
        this.form.patchValue({refundComment: this.data.refundComment})
        this.form.get('refundStatus')?.setValue(this.data.refundStatus === '—' 
          ? "NULL_REFUND" : this.refundOptions.find(item => this.data.refundStatus === item.name)?.value);
        console.log(this.form.value)
      }
  }
  public save(): void {
    const data: any = this.form.value;
    if(data.refundStatus === "NULL_REFUND") {
      data.refundStatus = null;
    }
    this.paymentsService.refund(data, this.data.id).subscribe({
      next: (res) => {
        this.toastrService.success(res.message,'Успех');
        this.closeEvent.emit();
        this.cdr.detectChanges();
      },
      error: (err) => {
        this.toastrService.error(err.message,'Ошибка')
        this.cdr.detectChanges();
      }
    })
  }
}
