import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-menu-default',
  templateUrl: './menu-default.component.html',
  styleUrls: ['./menu-default.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuDefaultComponent {
  @Input() canSee: boolean = false;
  @Input() canEdit: boolean = true;
  @Input() canDuplicate: boolean = true;
  @Input() canDelete: boolean = true;

  @Output() editClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() duplicateClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleteClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() seeClick: EventEmitter<void> = new EventEmitter<void>();

  onEditClick(): void {
    this.editClick.emit();
  }

  onDuplicateClick(): void {
    this.duplicateClick.emit();
  }

  onDeleteClick(): void {
    this.deleteClick.emit();
  }

  onSeeClick(): void {
    this.seeClick.emit();
  }
}
