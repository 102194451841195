import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AdminService } from 'src/app/core/services/admin/admin.service';
import { ToastService } from 'src/app/shared/services/toast/toast.service';
import { AdminStudentAddCourseComponent } from '../admin-student-add-course/admin-student-add-course.component';
import { CoursesService } from 'src/app/core/services/courses/courses.service';
import { Roles } from 'src/app/core/model';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CuratorService } from "../../../../../services/curator/curator.service";

@Component({
  selector: 'app-admin-student-courses',
  templateUrl: './admin-student-courses.component.html',
  styleUrls: ['./admin-student-courses.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminStudentCoursesComponent implements OnInit {
  @Input() studentId!: string;
  @Input() hideCourseEvents: boolean = false;
  public selectedStudentGroupsList: any[] = [];

  public readonly roles = Roles;
  public role: Roles = Roles.Curator;

  constructor(
    private _adminService: AdminService,
    private _curatorService: CuratorService,
    private cdr: ChangeDetectorRef,
    private ts: ToastService,
    private dialog: MatDialog,
    private coursesService: CoursesService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.role = this.authService.getRole();

    this.getStudentInfo();
  }

  public getStudentInfo(): void {
    this._adminService.getStudentGroups(this.studentId).subscribe({
      next: (response) => {
        this.selectedStudentGroupsList = response;
        this.selectedStudentGroupsList.forEach((group: any) => {
          this.coursesService.getCourseById(group.courseId).subscribe({
            next: (res) => {
              group.subjectId = res.subjectId;
              this.cdr.detectChanges();
            },
          });
        });
      },
    });
  }

  public removeFromCourseHandler(groupId: string): void {
    if (confirm(`Вы уверены, что хотите исключить студента из группы?`))
      this.getApiService(groupId)
        .subscribe({
          next: _ => {
            this.ts.showSuccess('Студент исключён');
            this.selectedStudentGroupsList =
              this.selectedStudentGroupsList.filter(
                (item) => item.id !== groupId
              );
            this.cdr.detectChanges();
          },
          error: (err: any) => {
            this.ts.showError(`Произошла ошибка ${err}`);
          },
          complete: (): void => {},
        });
  }

  private getApiService(groupId: string) {
    return this.role === Roles.Curator ?
      this._curatorService
        .removeStudentFromGroup(this.studentId, groupId) :
      this._adminService
        .removeStudentFromGroup(this.studentId, groupId);
  }

  public addCourse(): void {
    const dia = this.dialog.open(AdminStudentAddCourseComponent, {
      data: { studentId: this.studentId },
    });

    dia.afterClosed().subscribe((res: any) => {
      console.log(res);
      if (res.event === 'success') {
        this.selectedStudentGroupsList = [
          res.data.group,
          ...this.selectedStudentGroupsList,
        ];
        this.cdr.detectChanges();
      }
    });
  }
}
