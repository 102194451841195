<div class="app-multi-correct">
  <button
    class="variant"
    [ngClass]="{
            'answer-correct': (_QuizState.View === quizState) && option.isChecked && question.correctAnswerTexts && (question.correctAnswerTexts.includes(option.answerText)),
            'answer-incorrect': (_QuizState.View === quizState) && option.isChecked && question.correctAnswerTexts && (!question.correctAnswerTexts.includes(option.answerText))
        }"
    *ngFor="let option of customOptions; let i = index"
    (click)="check($event, i)"
    [disabled]="disabled"
  >
    <img class="image" *ngIf="option.answerImageUrl" [src]="option.answerImageUrl" alt=""/>
    <div class="checkbox">
      <mat-checkbox
        [disabled]="quizState === _QuizState.View || disabled"
        color="primary"
        (click)="ignoreClick($event, i)"
        [(ngModel)]="option.isChecked">
      </mat-checkbox>
      <app-editor-viewer [content]="option.answerText"></app-editor-viewer>
    </div>
  </button>
</div>
<app-result
  [explanation]="question.explanation"
  [referenceUrl]="question.referenceUrl"
  [explanationImageUrl]="question.explanationImageUrl"
  [score]="question.score"
  *ngIf="(_QuizState.View === quizState) && question.correctAnswerTexts"
  [title]="'correctAnswer' | translate"
  infoText="">
  <div class="answer-wrapper">
    <div class="answer" *ngFor="let answer of question.correctAnswerTexts;">
      <img src="assets/svg/circles/checkmark-circle.svg" alt=""/>
      <app-editor-viewer [content]="answer.toString()"></app-editor-viewer>
    </div>
  </div>
</app-result>
