import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface ToggleState {
  isOpen: boolean;
  url: string | null;
}

@Injectable({
  providedIn: 'root'
})
export class PdfFileServiceService {

  // BehaviorSubject to keep track of the open/closed state and URL
  private stateSubject: BehaviorSubject<ToggleState> = new BehaviorSubject<ToggleState>({ isOpen: false, url: null });
  public state$ = this.stateSubject.asObservable();

  constructor() { }

  // Method to open and set URL
  open(url: string | null) {
    this.stateSubject.next({ isOpen: true, url });
  }

  // Method to close and clear URL
  close() {
    this.stateSubject.next({ isOpen: false, url: null });
  }

  // Method to toggle state
  toggle(url?: string) {
    const currentState = this.stateSubject.value;
    if (!currentState.isOpen && url) {
      this.open(url);
    } else {
      this.close();
    }
  }

  // Method to get current state (optional)
  isOpen(): boolean {
    return this.stateSubject.value.isOpen;
  }

  // Method to get current URL (optional)
  getUrl(): string | null {
    return this.stateSubject.value.url;
  }
}
