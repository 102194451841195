<div class="stream-card border bg-white radius-16"
     [ngClass]="{ not_active: !status && !courseFinished }">
  <div class="header">
    <div class="description flex flex-col">
      <div class="title">
        <span *ngIf="!link" (click)="onTitleClickHandler()">{{ title }}</span>

        <ng-container *ngIf="link">
          <a [routerLink]="[link]" *ngIf="!isCount">{{ title ?? "-" }}</a>
          <a
            [routerLink]="[link]"
            *ngIf="isCount"
            [queryParams]="{ count: queryValue }"
            >{{ title ?? "-" }}</a
          >
        </ng-container>
        <button [matMenuTriggerFor]="menu">
          <img class="icon" src="assets/svg/triple-dot.svg" alt="" />
        </button>
      </div>
      <div class="month">
        {{ descriptionInfo ?? "-" }},
        {{ footerInfo ?? "-" }}
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="footer-item switcher">
      <mat-slide-toggle
        color="accent"
        [checked]="status"
        (change)="onToggleChange($event)"
      >
        {{ (status ? 'SALES_ACTIVE' : "SALES_NOT_ACTIVE") | translate }}
        <div class="toggle-info" (mouseenter)="openCloseStreamTooltip()" (mouseleave)="openCloseStreamTooltip()">
          <img class="ml-10" src="./assets/svg/informative/base-info.svg" alt="">
          <app-info-tooltip [isShow]="showInfoStreamToggle" text="STREAM_INFO_TOGGLE"></app-info-tooltip>
        </div>
      </mat-slide-toggle>
    </div>
    <div class="footer-item switcher">
      <mat-slide-toggle
        color="accent"
        [checked]="courseFinished"
        (change)="onToggleCourseChange($event)"
      >
        {{ (courseFinished ? 'COURSE_ACTIVATED' : "COURSE_COMPLETED") | translate }}
        <div class="toggle-info" (mouseenter)="openCloseCourseTooltip()" (mouseleave)="openCloseCourseTooltip()">
          <img class="ml-10" src="./assets/svg/informative/base-info.svg" alt="">
          <app-info-tooltip [isShow]="showInfoToggle" text="COURSE_INFO_TOGGLE_1"/>
        </div>
      </mat-slide-toggle>
    </div>
    <div class="footer-item switcher">
      <mat-slide-toggle
        color="accent"
        [checked]="isOnlyLogged"
        (change)="onToggleAuthorizedChange($event)"
      >
        {{ (isOnlyLogged ? 'AUTHORIZED' : "NOT_AUTHORIZED") | translate }}
        <div class="toggle-info" (mouseenter)="openCloseAuthorizedTooltip()" (mouseleave)="openCloseAuthorizedTooltip()">
          <img class="ml-10" src="./assets/svg/informative/base-info.svg" alt="">
          <app-info-tooltip [isShow]="showInfoAuthorizedToggle" text="COURSE_INFO_AUTHORIZED_TOGGLE"/>
        </div>
      </mat-slide-toggle>
    </div>
  </div>

  <mat-menu #menu="matMenu">
    <app-menu-default
      (editClick)="edit()"
      (deleteClick)="openDeleteModal()"
      (duplicateClick)="duplicate()"
      [canDelete]="canAction()"
      [canEdit]="canAction()"
    ></app-menu-default>
  </mat-menu>
</div>

<app-delete-modal
  title="DELETE_STREAM"
  text="ARE_U_SURE_DELETE_STREAM"
  confirmText="YES_REMOVE_STREAM"
  cancelText="NO_REMOVE_STREAM"
  [isModalOpen]="isDeleteModalOpen"
  [id]="streamIdToDelete"
  [isLoading]="isLoading"
  (deleteEvent)="remove($event)"
  (closeEvent)="closeDeleteModal()"
></app-delete-modal>
