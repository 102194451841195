import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit,} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
  AdminHeadteacherLinkCuratorComponent
} from '../admin-headteacher-link-curator/admin-headteacher-link-curator.component';
import {AdminService} from 'src/app/core/services/admin/admin.service';
import {
  DateHelperServiceService
} from "../../../../../../shared/services/dateHelperService/date-helper-service.service";
import {Languages} from "../../../../../../shared/models";
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-admin-headteacher-curators',
  templateUrl: './admin-headteacher-curators.component.html',
  styleUrls: ['./admin-headteacher-curators.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminHeadteacherCuratorsComponent implements OnInit, OnDestroy {
  @Input() headteacherId!: string;
  public curatorsList: any[] = [];
  currentLang: Languages = Languages.KZ;
  private languageChangeSub$!: Subscription;

  constructor(
    private cdr: ChangeDetectorRef,
    private _adminService: AdminService,
    private dialog: MatDialog,
    public dateHelperService: DateHelperServiceService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.languageChangeSub$ = this.translateService.onLangChange.subscribe((res) => this.currentLang = res.lang as Languages);
    this.getHeadTeacher();
  }
  public getHeadTeacher(): void {
    this._adminService.getHeadTeacherCurators(this.headteacherId).subscribe({
      next: (response: any) => {
        this.curatorsList = response;
        this.cdr.detectChanges();
      },
    });
  }

  public onUnlinkCurator(id: string): void {
    this._adminService
      .unlinkCuratorToHeadteacher(this.headteacherId, [id])
      .subscribe({
        next: _ => {
          this.getHeadTeacher();
          this.cdr.detectChanges();
        },
      });
  }

  public showLinkCurator(): void {
      this.dialog.open(AdminHeadteacherLinkCuratorComponent, {
        data: {
          headteacherId: this.headteacherId,
        },
      }).afterClosed().subscribe({
        next: res => {
          if (res) {
            this.getHeadTeacher();
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.languageChangeSub$.unsubscribe();
  }
}
