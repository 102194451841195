import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HeadTeacherService } from './../../../../../services/head-teacher/head-teacher.service';
import { SelectValue } from 'src/app/shared/models';
import { AdminService } from 'src/app/core/services/admin/admin.service';
import { LinkSubject, SubjectDTO } from 'src/app/core/model/interface/subject';
import { ToastrService } from 'ngx-toastr';
import { Roles } from 'src/app/core/model';

@Component({
  selector: 'app-admin-headteacher-link-subject',
  templateUrl: './admin-headteacher-link-subject.component.html',
  styleUrls: ['./admin-headteacher-link-subject.component.scss']
})
export class AdminHeadteacherLinkSubjectComponent implements OnInit, OnChanges {
  @Input() isOpen: boolean = false;
  @Input() role: Roles = Roles.Methodist;
  @Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmEvent: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Input() headteacherId: string = "";
  @Input() currentSubjects: SubjectDTO[] = [];

  public form!: FormGroup;
  public subjectOptions: SelectValue[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private headTeacherService: HeadTeacherService,
    private adminService: AdminService,
    private toastrService: ToastrService,
    private cdr: ChangeDetectorRef,
    )
  {
    this.form = this.formBuilder.group({
      subjects: [[], Validators.required]
    })
   }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(changes['currentSubjects']) {
      this.subjectOptions = this.headTeacherService.getSubjects();

      this.subjectOptions = this.subjectOptions.filter(item => !this.currentSubjects.find(sub => sub.id === item.value));

      this.cdr.detectChanges();
    }
  }

  public subjectSelected(list: string[]): void {
    this.form.patchValue({subjects: list});
  }

  public addSubjects(): void {
    switch(this.role) {
      case Roles.HeadTeacher: {
        const data: LinkSubject = {
          headteacherId: this.headteacherId,
          subjectIds: this.form.get('subjects')?.value
        }
        this.adminService.addSubjectsToHeadteacher(data).subscribe({
          next: (res) => {
            this.toastrService.success('Вы успешно добавили предмет', 'Успех');
            this.confirmEvent.emit(data.subjectIds);
            this.cdr.detectChanges();
          },
          error: (err) => {
            this.toastrService.success(err, 'Ошибка')
          }
        })
        break;
      };
      case Roles.Methodist: {
        const data: LinkSubject = {
          methodistId: this.headteacherId,
          subjectIds: this.form.get('subjects')?.value
        }
        this.adminService.addSubjectsToMethodist(data).subscribe({
          next: (res) => {
            this.toastrService.success('Вы успешно добавили предмет', 'Успех');
            this.confirmEvent.emit(data.subjectIds);
            this.cdr.detectChanges();
          },
          error: (err) => {
            this.toastrService.success(err, 'Ошибка')
          }
        })
        break;
      }
    }

  }
}
