<ng-container *ngIf="imageUrl || imageSrc; else default">
  <img
    class="student__avatar-img"
    [ngClass]="size"
    [style.background-image]="'url(' + imageUrl + ')'"
    [src]="imageSrc ? imageSrc : null"
    alt="student avatar"
    (error)="onError()"
  />
</ng-container>
<ng-template #default>
  <div class="no-image"
       [ngClass]="size">{{firstLetter?.toUpperCase()}}</div>
</ng-template>
