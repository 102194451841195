import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnChanges, OnDestroy, OnInit, SimpleChanges, ChangeDetectorRef, } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { TestQuestionCustom, TestQuestionDTO } from 'src/app/core/model';
import { getFormControlErrors } from 'src/app/shared/validators';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-open-test-creator',
  templateUrl: './open-test-creator.component.html',
  styleUrls: ['./open-test-creator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenTestCreatorComponent implements OnInit, OnChanges, OnDestroy{
  @Input() item!: TestQuestionDTO;
  @Output() removeEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() saveEvent: EventEmitter<TestQuestionCustom> = new EventEmitter<TestQuestionCustom>();
  @Output() validQuestionStatus: EventEmitter<boolean> = new EventEmitter<boolean>();


  private eventsSubscription!: Subscription;
  @Input() saveEventSubject!: Observable<number>;

  @Input() index!: number;
  @Input() withQuestionText: boolean = true;

  public form!: FormGroup;

  public formErrors = {
    questionText: "",
    answer: ""
  }
  public answer: string = "";

  public questionImageUrl: string = '/assets/svg/image-upload.svg';

  constructor(
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {
    this.form = this.formBuilder.group({
      questionText: ['', Validators.required],
      questionImageUrl: [''],
      answerList: [[]],
      answer: ['']
    });

    this.form.statusChanges.subscribe((status) => {
      const anyCustomError: boolean = this.formErrors.answer ? true : false;
      this.validQuestionStatus.emit((status === 'VALID' && !anyCustomError) ? true : false);
    });

    this.form.valueChanges.subscribe((res) => {
      if(!res.questionText) {
        this.formErrors.questionText = "REQUIRED_FIELD"
      }
      else {
        this.formErrors.questionText = "";
      }
      if(res.answerList && res.answerList.length === 0) {
        this.formErrors.answer = "ADD_RIGHT_OPTIONS";
        this.validQuestionStatus.emit(false);
      }
      else {
        this.formErrors.answer = "";
        this.validQuestionStatus.emit(true);
      }
    })

  }

  ngOnInit(): void {
    if(this.saveEventSubject) {
      this.eventsSubscription = this.saveEventSubject.subscribe((index: number) => {
        if(index === this.index) {
          this.form.patchValue({questionImageUrl:
            this.form.get('questionImageUrl')?.value === this.questionImageUrl ? "" : this.form.get('questionImageUrl')?.value})
          this.saveEvent.emit(this.form.value);
        }
      });
    };
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['item'] && changes['item'].firstChange) {
      this.form.patchValue({ questionText: this.item.questionText });
      this.form.patchValue({ questionImageUrl: this.item.questionImageUrl });
      this.form.patchValue({ answerList: this.item.answerList ? this.item.answerList : [],});
      this.form.patchValue({ answer: [''] });
    }
  }
  ngOnDestroy(): void {
    this.eventsSubscription.unsubscribe();
  }

  public addAnswer(): void {
    const currentValue = this.form.get('answerList')?.value;
    const updatedValue = [...currentValue, this.form.get('answer')?.value.trim()];

    this.form.patchValue({ answerList: updatedValue });
    this.form.patchValue({answer: ""});
  }

  public handleFormControlErrors(form: FormGroup, error: any, controlName: string): void {
    getFormControlErrors(form, error, controlName, this.translateService);
    this.cdr.detectChanges();
  }

  public removeAnswer(index: number): void {
    const currentValue = this.form.get('answerList')?.value;
    const updatedValue = currentValue.filter((item : any, i: number) => i !== index);
    this.form.patchValue({ answerList: updatedValue });
  }

  public handleQuestionImageInput(event: Event): void {
    const imageElement = event.target as HTMLInputElement;
    if(imageElement.files && imageElement.files.length > 0) {
      const file = imageElement.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.form.patchValue({questionImageUrl: reader.result});
        this.cdr.detectChanges();
      }
      reader.readAsDataURL(file);
    }
  }

}
