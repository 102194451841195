<div class="lecture-card bg-white radius-16 border w-100">
  <div class="header">
    <div class="title flex justify-between">
      <button (click)="onSelectLesson()">
        {{ lesson.name }}
      </button>
      <app-button
        leftIcon="./assets/svg/eye.svg"
        text="SEE"
        [type]="editButtonType"
        [size]="editButtonSize"
        [color]="editButtonColor"
        (onButtonClick)="navigateTo()"
      ></app-button>
    </div>
    <div class="subject-type">
      <span>{{'TYPE' | translate}}:</span>
      {{ lesson.lessonType ? (lesson!.lessonType | reverseLessonType) : "-" }}
    </div>
  </div>

  <div class="desktop">
    <div class="blocks">
      <div class="blocks-item">
        <div class="label">{{'course' | translate}}</div>
        <div class="value">{{ lesson!.courseName || '' }}</div>
      </div>
      <div class="blocks-divider"></div>
      <div class="blocks-item">
        <div class="flex-col flex items-center" [ngSwitch]="role">
          <ng-container *ngSwitchDefault>
            <div class="label">{{'PASSED' | translate}}</div>
            <div class="value">
              {{ lesson.submittedCount | localizedNumberFormat }} /
              {{ lesson.studentsCount | localizedNumberFormat }}
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="rolesEnum.Curator">
            <div class="label">{{'DID_NOT_PASS' | translate}}</div>
            <div class="value">
              {{ lesson.notSubmittedCount | localizedNumberFormat }} /
              {{ lesson.studentsCount | localizedNumberFormat }}
            </div>
          </ng-container>
        </div>
      </div>
      <span class="blocks-line"></span>
      <div class="blocks-divider"></div>
      <div class="blocks-item">
        <div
          class="radius-8 flex flex-col rated"
          [ngClass]="{
            'all-checked':
              lesson.reviewedCount ===
              lesson.submittedCount
          }"
        >
          <div class="label">{{'RATED' | translate}}</div>
          <div class="value">
            <img *ngIf="lesson.reviewedCount && (lesson.reviewedCount === lesson.submittedCount); else default"
                src="./assets/svg/informative/base-verified.svg" alt="">
            <ng-template #default>
              <img src="./assets/svg/informative/verify.svg" alt="">
            </ng-template>

            {{ lesson.reviewedCount | localizedNumberFormat }} /
            {{ lesson.submittedCount | localizedNumberFormat }}
          </div>
        </div>
      </div>
      <div class="blocks-divider"></div>
      <div class="blocks-item">
        <div class="flex-col flex items-center">
          <div class="label">{{'AVERAGE_RATING' | translate}}</div>
          <div class="value">
            {{ lesson.averageScore ? (lesson.averageScore | localizedNumberFormat) : '-' }} /
            {{ (lesson.maxScore | localizedNumberFormat) || "-" }}
          </div>
        </div>
      </div>
      <div class="blocks-divider"></div>
      <div class="blocks-item">
        <div>
          <div class="label">{{'DEADLINE_EXAM' | translate}}</div>
          <div class="value flex items-center flex" [ngStyle]="{color: isItLessThanHour(lesson.deadline | utcToLocal) ? '#E73152' : ''}">
            <img [src]="'./assets/svg/timer-' + (isItLessThanHour(lesson.deadline | utcToLocal) ? 'pause.svg' : 'timer.svg')" alt="">
            {{ lesson.deadline ? (lesson.deadline | utcToLocal |  date : "HH:mm dd.MM.yyyy") : '-' }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mobile">
    <div class="blocks">
      <div class="blocks-item">
        <div class="label">{{'course' | translate}}</div>
        <div class="value">{{ lesson!.courseName || '' }}</div>
      </div>
      <div class="blocks-divider"></div>
      <div class="blocks-item">
        <div class="flex-col flex items-center">
          <div class="label">{{'AVERAGE_RATING' | translate}}</div>
          <div class="value">
            {{ lesson.averageScore ? (lesson.averageScore  | localizedNumberFormat) : '-' }} /
            {{ (lesson.maxScore | localizedNumberFormat) || "-" }}
          </div>
        </div>
      </div>
      <span class="blocks-line"></span>
      <div class="blocks-divider"></div>
      <div class="blocks-item">
        <div class="flex-col flex items-center">
          <div class="label">{{'PASSED' | translate}}</div>
          <div class="value">
            {{ lesson.submittedCount | localizedNumberFormat }} /
            {{ lesson.studentsCount | localizedNumberFormat }}
          </div>
        </div>
      </div>
      <div class="blocks-divider"></div>
      <div class="blocks-item">
        <div
          class="radius-8 flex flex-col rated"
          [ngClass]="{
            'all-checked':
              lesson.reviewedCount ===
              lesson.submittedCount
          }"
        >
          <div class="label">{{'RATED' | translate}}</div>
          <div class="value">
            <img *ngIf="lesson.reviewedCount && (lesson.reviewedCount === lesson.submittedCount); else default"
                src="./assets/svg/informative/base-verified.svg" alt="">
            <ng-template #default>
              <img src="./assets/svg/informative/verify.svg" alt="">
            </ng-template>

            {{ lesson.reviewedCount | localizedNumberFormat }} /
            {{ lesson.submittedCount | localizedNumberFormat }}
          </div>
        </div>
      </div>
      <div class="blocks-divider"></div>
      <div class="blocks-item">
        <div>
          <div class="label">{{'DEADLINE_EXAM' | translate}}</div>
          <div class="value flex items-center flex" [ngStyle]="{color: isItLessThanHour(lesson.deadline | utcToLocal) ? '#E73152' : ''}">
            <img [src]="'./assets/svg/timer-' + (isItLessThanHour(lesson.deadline | utcToLocal) ? 'pause.svg' : 'timer.svg')" alt="">
            {{ lesson.deadline ? (lesson.deadline | utcToLocal |  date : "HH:mm dd.MM.yyyy") : '-' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
