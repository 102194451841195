import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private storage: Storage;
  private tokenKey = 'token';
  private refreshToken = 'refreshToken';
  private fcmToken = 'fcmToken';

  constructor() {
    this.storage = localStorage;
  }

  getItem(key: string): any {
    const item = this.storage.getItem(key);
    return item ? item : null;
  }

  setItem(key: string, value: any): void {
    const item = typeof value === 'string' ? value: JSON.stringify(value);
    this.storage.setItem(key, item);
  }
  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  public setRefreshToken(token: string): void {
    localStorage.setItem(this.refreshToken, token);
  }

  public getRefreshToken(): string {
    return localStorage.getItem(this.refreshToken) || '';
  }

  public removeRefreshToken() : void {
   this.storage.removeItem(this.refreshToken)
  }

  getToken(): string | null {
    return this.storage.getItem(this.tokenKey);
  }

  setToken(token: string): void {
    this.storage.setItem(this.tokenKey, token);
  }

  removeToken(): void {
    this.storage.removeItem(this.tokenKey);
  }

  getFcmToken(): string | null {
    return this.storage.getItem(this.fcmToken);
  }

  setFcmToken(token: string): void {
    this.storage.setItem(this.fcmToken, token);
  }

  removeFcmToken(): void {
    this.storage.removeItem(this.fcmToken);
  }
}
