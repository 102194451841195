import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {StorageService} from '../../services/storage/storage.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {CommonRoutes} from '../../model';
import {MessagingService} from "../../../shared/services/firebase-messaging/messaging.service";
import {SystemService} from "../../services/system/system.service";
import {AlertMessageType} from "../../../shared/models/interfaces/system-alerts";
import {AuthService} from "../../services/auth/auth.service";


@Injectable({
  providedIn: 'root'
})
export class AutorizationGuard implements CanActivate {
  constructor(
    private storageService: StorageService,
    private jwtHelper: JwtHelperService,
    private router: Router,
    private messagingService: MessagingService,
    private systemService: SystemService,
    private authService: AuthService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const systemAlert = this.systemService.getSystemAlert();
    if (systemAlert && systemAlert.messageType === AlertMessageType.blocker) {
      this.router.navigate(['system-error']).then();
    } else if (!this.storageService.getToken() || this.jwtHelper.isTokenExpired(this.storageService.getToken())) {
      this.messagingService.requestPermission();
      this.router.navigate([`${CommonRoutes.Authorization}`])
    }
    return true;
  }

}
