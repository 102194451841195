import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ManagerService } from 'src/app/core/services/manager/manager.service';
import { EMPTY_VALUE } from 'src/app/shared/consts/common';
import { ToastService } from 'src/app/shared/services/toast/toast.service';

@Component({
  selector: 'app-admin-manager-info',
  templateUrl: './admin-manager-info.component.html',
  styleUrls: ['./admin-manager-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminManagerInfoComponent implements OnInit {
  public EMPTY_VALUE = EMPTY_VALUE;
  @Input() public manager: any;
  public isDataLoading = false;
  constructor(
    private _managerService: ManagerService,
    private cdr: ChangeDetectorRef,
    private ts: ToastService,
    private _translate: TranslateService
  ) {}
  ngOnInit(): void {}

  public fireManager(): void {
    console.log('fire');
    this._managerService.fireManager(this.manager?.id).subscribe({
      next: () =>
        this.ts.showSuccess(
          this._translate.instant('fireManagerSuccess') ??
            'Менеджер успешно уволен'
        ),
      error: () =>
        this.ts.showSuccess(
          this._translate.instant('fireManagerError') ??
            'Ошибка при увольнении менеджера'
        ),
      complete: () => window.location.reload(),
    });
  }

  public activateManager(): void {
    console.log('activate');
    this._managerService.activateManager(this.manager?.id).subscribe({
      next: () =>
        this.ts.showSuccess(
          this._translate.instant('activateManagerSuccess') ??
            'Менеджер успешно восстановлен'
        ),
      error: () =>
        this.ts.showSuccess(
          this._translate.instant('activateManagerError') ??
            'Ошибка при восстановлении менеджера'
        ),
      complete: () => window.location.reload(),
    });
  }
}
