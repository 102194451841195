import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-past-events-card',
  templateUrl: './past-events-card.component.html',
  styleUrls: ['./past-events-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PastEventsCardComponent {

}
