<button *ngIf="deadline" (click)="goToLesson()" class="notification">
  <div class="notification-info">
    <span class="notification-key">{{deadline.lessonType | reverseLessonType : language}}</span>
    <span class="notification-value">{{deadline.lessonName}}</span>
  </div>
  <div class="timer">
    <img src="assets/svg/timer-pause.svg" alt="" class="timer-icon">
    <span class="timer-number" *ngIf="hours;else timer">{{ hours + ' ' + ('HOURS_SHORT' | translate)}}</span>
    <ng-template #timer>
      <span class="timer-number">{{ minutes }}:{{ seconds | number: "2.0" }}</span>
    </ng-template>
  </div>
</button>
