<form class="content" [formGroup]="form">
  <app-input name="Вопрос" class="input" formControlName="questionText"></app-input>
  <ul class="list"  formArrayName="subQuestions">
    <li class="item" *ngFor="let questionForm of subQuestions.controls;let i = index" [formGroupName]="i">
      <label for="questions">Под-вопрос {{ i + 1 }}</label>
      <app-input name="Вопрос" class="input" formControlName="questions"></app-input>
      <app-input name="Ответ" formControlName="correctAnswers"></app-input>
      <button *ngIf="i >= 2" type="button" class="delete" (click)="removeSubQuestion(i)">Удалить под-вопрос</button>
    </li>
    <button type="button" class="iconic-button secondary-button button" (click)="addSubQuestion()">Добавить под-вопрос</button>
  </ul>

</form>