import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { VideoDTO } from 'src/app/shared/models';
import * as iframeApiLoader from '@kinescope/player-iframe-api-loader';
import * as uuid from 'uuid';

@Component({
  selector: 'app-kinoscope-player',
  templateUrl: './kinoscope-player.component.html',
  styleUrls: ['./kinoscope-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KinoscopePlayerComponent implements AfterViewInit {
  public uniqueId = uuid.v4();
  @ViewChild('#iframe') iframeRef: ElementRef | undefined;

  @Input() lessonId: string = '';
  @Input() videoUrl: string | undefined;
  @Output() sendPlayed: EventEmitter<VideoDTO> = new EventEmitter<VideoDTO>();

  videoDuration: number = 0;
  watchedPercentage: number = 0;
  private timeIntervals: Set<number> = new Set();

  public player: any;

  ngAfterViewInit() {
    const storedData = sessionStorage.getItem(`${this.lessonId}-kinescope`);
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      this.timeIntervals = new Set(parsedData);
    }

    this.createPlayer();
  }

  async createPlayer(): Promise<void> {
    const factory = await iframeApiLoader.load();
    const self = this;

    this.player = await factory.create(this.uniqueId , {
      url: this.videoUrl!,
      size: { width: '100%', height: '100%' },
    })
    .then(function (player) {
      player.on('timeupdate' as any, (event: any) => {
        const currentTime = event.data.currentTime;
        if (!self.videoDuration) {
          self.videoDuration = event.data.duration;
        }
        self.timeIntervals.add(Math.floor(currentTime));
        self.calculateWatchedPercentage();
      });

      // setInterval(() => {
      //   player.getCurrentTime().then((res) => {
      //     if (!isStartedPlay && res) {
      //       self.sendPlayed.emit({
      //         duration: self.videoDuration,
      //         timings: null,
      //         percent: self.watchedPercentage
      //       });
      //       isStartedPlay = true;
      //     }
      //     // console.log(res);
      //   })
      // }, 2000);
    });
  }

  calculateWatchedPercentage(): void {
    this.saveToStorage();
    const watchedSeconds = this.timeIntervals.size;
    this.watchedPercentage = watchedSeconds / this.videoDuration;

    this.sendPlayed.emit({
      duration: this.videoDuration,
      timings: null,
      percent: this.watchedPercentage
    });
  }

  private saveToStorage(): void {
    const arrayData = Array.from(this.timeIntervals);
    sessionStorage.setItem(`${this.lessonId}-kinescope`, JSON.stringify(arrayData));
  }
}
