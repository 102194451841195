import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  ButtonColors, ButtonRadius,
  ButtonSize,
  ButtonTypes,
} from '../../../models/enums/button.enum';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ButtonComponent {
  @Input() text: string = '';
  @Input() color: ButtonColors = ButtonColors.Brand;
  @Input() leftIcon?: string = '';
  @Input() rightIcon?: string = '';
  @Input() type?: ButtonTypes = ButtonTypes.Flat;
  @Input() size?: ButtonSize = ButtonSize.Medium;
  @Input() radius?: ButtonRadius | string = '';
  @Input() isDisabled?: boolean;
  @Input() fullWidth?: boolean;

  @Output() onButtonClick: EventEmitter<any> = new EventEmitter();

  public buttonColors = ButtonColors;
  public buttonTypes = ButtonTypes;
  public buttonSizes = ButtonSize;
  public buttonRadius = ButtonRadius;

  public handlerOnButtonClick(): void {
    this.onButtonClick.emit();
  }
}
