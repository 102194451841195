import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProgressDTO, StudentProgress } from 'src/app/core/model';

@Component({
  selector: 'app-menu-student-grade',
  templateUrl: './menu-student-grade.component.html',
  styleUrls: ['./menu-student-grade.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class MenuStudentGradeComponent implements OnInit {
  @Input() progress!: StudentProgress;
  @Output() onGradeLesson: EventEmitter<any> = new EventEmitter<any>();

  public form: FormGroup;

  constructor() {
    this.form = new FormGroup({
      grade: new FormControl('', [Validators.required]),
    });
  }
  ngOnInit(): void {
    this.form.valueChanges.subscribe((response) => console.log(response));
  }

  public submit(): void {
    this.onGradeLesson.emit({
      grade: this.form.controls['grade'].value,
      progress: this.progress,
    });
  }
}
