import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminService } from 'src/app/core/services/admin/admin.service';
import { UsersService } from 'src/app/core/services/users/users.service';
import { SelectValue } from 'src/app/shared/models';

@Component({
  selector: 'app-admin-headteacher-link-curator',
  templateUrl: './admin-headteacher-link-curator.component.html',
  styleUrls: ['./admin-headteacher-link-curator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminHeadteacherLinkCuratorComponent implements OnInit{
  public headteacherId!: string;
  public curatorOptions: SelectValue[] = [];
  public searchText?: string;
  constructor(
    private cdr: ChangeDetectorRef,
    private _adminService: AdminService,
    private _userService: UsersService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AdminHeadteacherLinkCuratorComponent>
  ) {
    this.headteacherId = data.headteacherId;
  }

  ngOnInit(): void {
      this.getCurators();
  }

  public selectedCuratorsId: string[] = [];

  public onCofirmSelectCurators(): void {
    this._adminService
      .linkCuratorToHeadteacher(this.headteacherId, this.selectedCuratorsId)
      .subscribe({
        next: _ => {
          this.cdr.detectChanges();
          this.dialogRef.close(true);
        }
      });
  }

  public onSelectCuratorsForLinkHeadTeacher(ids: string[]) {
    this.selectedCuratorsId = ids.filter((item) => item);
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public onSearch(search: string): void {
    this.searchText = search;
    this.getCurators();
  }

  public getCurators(): void {
    this._userService.getAllUsersByRole(['CURATOR'], this.searchText).subscribe({
      next: (response) => {
        this.curatorOptions = response.map((user) => ({
          value: user.id,
          name: `${user.firstname} ${user.lastname}`,
        }));
        this.cdr.detectChanges();
      },
    });
  }
}
