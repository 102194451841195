import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ConverterService } from '../../../../core/services/converter/converter.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PdfFileServiceService } from '../../../services/pdf-file-service/pdf-file-service.service';
import { ToastrService } from "ngx-toastr";
import { debounceTime, distinctUntilChanged, fromEvent, Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environment";

@Component({
  selector: 'app-pdf-file-viewer',
  templateUrl: './pdf-file-viewer.component.html',
  styleUrls: ['./pdf-file-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfFileViewerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() mobileAdaptive: boolean = false;
  @Input() url: string | null = null;
  @Input() isLocalFile: boolean = false;
  @Input() height: number = 100;
  iframeSrc: SafeResourceUrl | undefined;
  isMobilePdfLoading: boolean = false;
  public mobileUrl: string = "";

  @ViewChild('content', { static: false }) content!: ElementRef;

  resizeObservable$!: Observable<Event>;
  resizeSubscription$!: Subscription;

  private destroy$ = new Subject<void>();

  constructor(private converterService: ConverterService,
    private sanitizer: DomSanitizer,
    private PdfFileServiceService: PdfFileServiceService,
    private toastrService: ToastrService,
    private cdr: ChangeDetectorRef,
    private http: HttpClient
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['url'] && this.url) {
      this.tryUrl(environment.filesUrl);
      // this.mobileUrl = "";
      // // this.mobileUrl = "" +
      // this.mobileUrl = environment.converterFilesUrl +
      //   (this.isLocalFile ? window.location.origin + '/' : '')
      //   + this.url;
      //
      // console.log('url: ', this.url)
      // console.log('mobileUrl: ', this.mobileUrl)
      // this.iframeSrc =
      // this.sanitizer.bypassSecurityTrustResourceUrl(this.mobileUrl + '#toolbar=1');
      //
      // this.isMobilePdfLoading = window.innerWidth < 999;
    }
    if(changes['url'] && !this.url) {
      this.iframeSrc = undefined;
    }
  }

  ngOnInit(): void {
    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.pipe(distinctUntilChanged(), debounceTime(1500)).subscribe( evt => {
      console.log('resizeSubscription$')
      if(!this.mobileUrl) {
        // this.mobileUrl = environment.converterFilesUrl +
        //   (this.isLocalFile ? window.location.origin + '/' : '')
        //   + this.url;
        //
        // this.isMobilePdfLoading = window.innerWidth < 999;
        this.tryUrl(environment.filesUrl);
      }
      this.cdr.detectChanges();
    })
  }

  public close(): void {
    this.PdfFileServiceService.close();
  }

  setPdfLoading(): void {
    this.isMobilePdfLoading = true;
  }

  offPdfLoading(): void {
    this.isMobilePdfLoading = false;
  }

  setError(event: { message: string }): void {
    this.toastrService.warning(event?.message || '', 'Ошибка');
    // this.setIframeSrc();
    this.offPdfLoading();
  }

  private tryUrl(baseUrl: string): void {
    this.isMobilePdfLoading = true;
    this.cdr.detectChanges();
    const fullUrl = environment.converterFilesUrl
      +  (this.isLocalFile ? window.location.origin + '/' : '')
      + (baseUrl === environment.fallbackFilesUrl ?
        this.url?.replace(environment.filesUrl, environment.fallbackFilesUrl) :
        this.url);

    this.http.head(fullUrl)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: () => {
          this.mobileUrl = fullUrl;
          this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(fullUrl + '#toolbar=1');
          this.offPdfLoading();
          // this.isMobilePdfLoading = false;
          this.isMobilePdfLoading = window.innerWidth < 999;
          this.cdr.detectChanges();
        },
        error: () => {
          if (baseUrl === environment.filesUrl) {
            console.warn('filesUrl failed, trying fallback...');
            this.tryUrl(environment.fallbackFilesUrl);
          } else {
            console.error('Both filesUrl and fallbackFilesUrl failed.');
            this.isMobilePdfLoading = false;
            this.cdr.detectChanges();
          }
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
