<form class="content" [formGroup]="form" #base>
    <div class="full">
      <app-input
        [error]="formErrors.questionText"
        class="input-input"
        name="QUESTION_TEXT"
        formControlName="questionText"
        (input)="handleFormControlErrors(form, formErrors, 'questionText')">
      </app-input>
    </div>
    <label class="label">{{'QUESTION' | translate}}</label>
    <div
      #input
      contenteditable="true"
      appLongPress
      id="#input"
      class="input"
      [ngClass]="{'input-error': formErrors.answer}"
      role="textbox"
      (paste)="onPaste($event)"
      (input)="checkIsAnswerExist()"
      (keydown)="onKeyDown($event)"
      (contextmenu)="onRightClick($event)"
      (longPress)="onRightClick($event)"
      (dblclick)="onRightClick($event)"
    >&#8203;</div>
    <span *ngIf="formErrors.answer" class="error-message">{{formErrors.answer | translate}}</span>
    <button [hidden]="!isOpen" class="button" (click)="convert()" #transformer>{{'CONVERT_TO_OPTION' | translate}}</button>
</form>
