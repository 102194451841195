import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
@Component({
  selector: 'appp-activated-card',
  templateUrl: './activated-card.component.html',
  styleUrls: ['./activated-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivatedCardComponent {
  @Input() title?: string;
  @Input() description?: string | number;
  @Input() footer?: string | number;
  @Input() status?: boolean;

  @Input() canEdit: boolean = false;
  @Input() canDelete: boolean = false;
  @Input() canDuplicate: boolean = false;

  @Output() onSwitchStatus: EventEmitter<any> = new EventEmitter();
  @Output() onTitleClick: EventEmitter<any> = new EventEmitter();

  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onDuplicate: EventEmitter<any> = new EventEmitter();

  constructor() {}

  public onToggleChange(status: any): void {
    this.onSwitchStatus.emit(status);
  }

  public onTitleClickHandler(): void {
    this.onTitleClick.emit();
  }

  public editClick(): void {
    this.onEdit.emit();
  }

  public deleteClick(): void {
    this.onDelete.emit();
  }

  public duplicateClick(): void {
    this.onDuplicate.emit();
  }

  public canManipulate(): boolean {
    return this.canDelete || this.canEdit || this.canDuplicate;
  }
}
