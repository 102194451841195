<div class="wrapper" [formGroup]="form!">
  <app-input name="LEAVE_COMMENT" formControlName="comment" [showSubmitButton]="true"
    (submitEvent)="createComment($event)"></app-input>

  <div class="comment-list" *ngIf="student?.managerComments?.length">
    <div class="comment__item" *ngFor="let item of student?.managerComments">
      <app-avatar class="avatar" [size]="'small'"
        [firstLetter]="item.commenterName[0] ? item.commenterName[0] : '-'"
        [imageUrl]="item.commenterPhotoUrl"></app-avatar>

      <div class="comment__item-wrapper">
        <div class="comment__item-header">
          <p class="comment__item-name">
            {{item.commenterName}}
            <span *ngIf="item.commenterRole">({{item.commenterRole}})</span>
          </p>

          <p class="comment__item-date">{{item.commentTime | date: 'dd.MM.yyyy'}}</p>
        </div>

        <p class="comment__item-content">{{item.commentText}}</p>
      </div>
    </div>
  </div>
</div>
