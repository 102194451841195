// long-press.directive.ts
import { Directive, Output, EventEmitter, HostListener, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Directive({
  selector: '[appLongPress]',
  standalone: true
})
export class LongPressDirective {
  @Output() longPress = new EventEmitter();
  private longPressTimeout: any;
  constructor(private cdr: ChangeDetectorRef) {}
  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent): void {
    this.longPressTimeout = setTimeout(() => {
      this.longPress.emit(event);
      this.cdr.detectChanges()
    }, 500); // 500ms threshold for long press
  }

  @HostListener('touchend')
  onTouchEnd(): void {
    clearTimeout(this.longPressTimeout);
  }
}
