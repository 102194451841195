<div class="app-default-question">
  <mat-radio-group [(ngModel)]="userAnswer" class="list">
    <button #parentDiv class="option"
            *ngFor="let option of question.answerList"
            (click)="triggeredClick(option)"
            [disabled]="disabled"
            [ngClass]="{
                'correct': (_QuizState.View === quizState) && question.userAnswers && question.userAnswers.length && question.userAnswers[0].answerTextValue === option.answerText && correctAnswer && (correctAnswer === option.answerText),
                'mistake': (_QuizState.View === quizState) && userAnswer === option.id && correctAnswer && (correctAnswer !== option.answerText)}">
      <div *ngIf="option.answerImageUrl" class="image">
        <app-view-photo class="image" [image]="option.answerImageUrl"></app-view-photo>
      </div>
      <mat-radio-button
        class="item"
        color="primary"
        labelPosition="after"
        [value]="option.id">
        <app-editor-viewer [content]="option.answerText"></app-editor-viewer>
      </mat-radio-button>
    </button>
  </mat-radio-group>
  <app-result
    [score]="question.score"
    [explanation]="question.explanation"
    [referenceUrl]="question.referenceUrl"
    [explanationImageUrl]="question.explanationImageUrl"
    *ngIf="(_QuizState.View === quizState) && correctAnswer"
    [title]="'correctAnswer' | translate">
    <div class="answer">
      <img src="assets/svg/circles/checkmark-circle.svg" alt="">
      <app-editor-viewer [content]="correctAnswer.toString()"></app-editor-viewer>
    </div>
  </app-result>
</div>
