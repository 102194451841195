import { CoursesService } from 'src/app/core/services/courses/courses.service';
import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { AdditionalMaterials, UsefullLink } from 'src/app/core/model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-additional-materials-controller',
  templateUrl: './additional-materials-controller.component.html',
  styleUrls: ['./additional-materials-controller.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdditionalMaterialsControllerComponent implements OnInit, OnChanges {
  @Input() data: AdditionalMaterials | undefined;
  @Input() isOpen: boolean = false;
  @Output() cancelEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() saveEvent: EventEmitter<void> = new EventEmitter<void>();

  public courseId: string = "";
  public links: UsefullLink[] = [];
  public file: string[] | undefined;

  public isLinksValid: boolean = true;
  public fileUploaderError: string = "";
  constructor(
    private coursesService: CoursesService,
    private route: ActivatedRoute,
  ){}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.courseId = params.get('id') || '';
    })
  };
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['data'] && changes['data']) {
      this.links = this.data?.usefulLinks || [];
      this.file = this.data?.additionalMaterials;

      if(this.file && this.file?.length > 0) {
        this.fileUploaderError = "";
      }
      else {
        this.fileUploaderError = "UPLOAD_FILE"
      }
    }
  }
  public userLinkChanged(links: UsefullLink[]): void {
    this.links = links;
  };

  public downloaded(file: string): void {
    this.fileUploaderError = "";
    if(this.file) {
      this.file?.push(file);
      return;
    }
    this.file = [file];
  };

  public removed(id: string): void {
    this.file = this.file?.filter(item => item !== id);

    if(this.file?.length === 0) {
      this.fileUploaderError = "UPLOAD_FILE";
    }
  }

  public save(): void {
    const data: AdditionalMaterials = {
      additionalMaterials: this.file || [],
      usefulLinks: this.links
    }
    this.coursesService.usefulinkandadditionalmaterial(this.courseId, data).subscribe(res => {
      this.saveEvent.emit()
    })
  };

  public setValidOfLinks(isValid: boolean): void {
    this.isLinksValid = isValid;
  }
}
