import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-side-bar-courses-sort',
  templateUrl: './side-bar-courses-sort.component.html',
  styleUrls: ['./side-bar-courses-sort.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideBarCoursesSortComponent {
  public isOpen: boolean = true;
  @Input() sortType: 'SORT_BY_NEW' | 'SORT_BY_OLD' = 'SORT_BY_OLD';

  @Output() setSortGroup: EventEmitter<'SORT_BY_NEW' | 'SORT_BY_OLD'> = new EventEmitter<'SORT_BY_NEW' | 'SORT_BY_OLD'>();

  constructor() {}

  toggle(): void {
    this.isOpen = !this.isOpen;
    if(this.isOpen) {
      this.setSortGroup.emit('SORT_BY_OLD');
    }
    else {
      this.setSortGroup.emit('SORT_BY_NEW');
    }
  };

  public openGroup(sortType: 'SORT_BY_NEW' | 'SORT_BY_OLD'): void {
    if (sortType !== this.sortType) {
      this.setSortGroup.emit(sortType);
    }
    this.toggle();
  }
}
