import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultQuestionComponent } from './default-question/default-question.component';
import { QuestionsComponent } from './questions.component';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';

import { CdkDrag, DragDropModule, CdkDragPreview } from '@angular/cdk/drag-drop';
import { ResultComponent } from './result/result.component';
import { DragWordsComponent } from './drag-words/drag-words.component';
import { InsertWordsComponent } from './insert-words/insert-words.component';
import { ChangeQueueComponent } from './change-queue/change-queue.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TypeWordsComponent } from './type-words/type-words.component';
import { MultiCorrectComponent } from './multi-correct/multi-correct.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {TranslateModule} from "@ngx-translate/core";
import { PipesModule } from 'src/app/shared/pipes/pipes.module';

import { NgxImageZoomModule } from 'ngx-image-zoom';
import { MultidropdownComponent } from './multidropdown/multidropdown.component';

@NgModule({
  declarations: [
    DefaultQuestionComponent,
    QuestionsComponent,
    ResultComponent,
    DragWordsComponent,
    InsertWordsComponent,
    ChangeQueueComponent,
    TypeWordsComponent,
    MultiCorrectComponent,
    MultidropdownComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        MatRadioModule,
        FormsModule,
        MatCheckboxModule,
        CdkDrag,
        DragDropModule,
        CdkDragPreview,
        TranslateModule,
        PipesModule,
        NgxImageZoomModule
    ],
  exports: [
    QuestionsComponent
  ]
})
export class QuestionsModule { }
