<div class="order-form p-24 radius-16 border">
  <h1 class="title">{{ "enterData" | translate }}</h1>

  <form class="form flex flex-col" [formGroup]="form">
    <div class="flex first">
      <div class="flex flex-col w-100">
        <app-input
          formControlName="firstName"
          [name]="'firstName' | translate"
          [control]="form.controls['firstName']"
        ></app-input>
      </div>
      <div class="flex flex-col w-100">
        <app-input
          formControlName="lastName"
          [name]="'lastName' | translate"
          [control]="form.controls['lastName']"
        ></app-input>
      </div>
    </div>

    <div class="flex first w-100">
      <div class="flex flex-col w-100">
        <app-input
          formControlName="phone"
          name="Телефон (ватсап)"
          prefix="+7"
          mask=" (000) 000-00-00"
          [control]="form.controls['phone']"
        ></app-input>
      </div>
    </div>

    <app-input
      type="email"
      name="Email"
      formControlName="email"
      [control]="form.controls['email']"
    ></app-input>

    <app-select
      [options]="managerOptions"
      name="Ваш менеджер"
      formControlName="managerId"
      [isLoading]="isManagerOptionsLoading"
      placeholder="Выберите менеджера"
    ></app-select>

    <div class="email-important__notification">
      <div class="email-important__notification-img-wrapper">
        <img
          class="email-important__notification-img"
          src="/assets/img/warning.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="email-important__notification-text-wrapper">
        <div
          class="email-important__notification-text-title"
          [innerHTML]="'beAwareMailPaymentTitle' | translate"
        ></div>
        <div
          class="email-important__notification-text-description"
          [innerHTML]="'beAwareMailPaymentText' | translate"
        ></div>
      </div>
    </div>
  </form>
</div>
