import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class NotificationService {

  public _isShowNotificationModal$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  public toggleIsShowNotificationModal(): void {
    this._isShowNotificationModal$.next(!this._isShowNotificationModal$.getValue());
  }
}
