<div id="cart-modal" class="cart-modal p-24 border" [class.cart-modal_show]="isShow">
  <div class="header flex justify-between">
    <div class="title">{{'cart' | translate}}</div>
    <button
      class="close-btn flex items-center justify-center"
      (click)="toggleIsShowModal()"
    >
      <img src="./assets/svg/plus.svg" alt=""/>
    </button>
  </div>
  <div
    class="body"
    [ngClass]="{
      'flex items-center justify-center flex-col': cartItems.length === 0
    }"
  >
    <ng-container *ngIf="cartItems.length > 0">
      <div class="courses flex flex-col">
        <ng-container *ngIf="cartItems.length > 0">
          <app-cart-modal-card
            *ngFor="let card of cartItems"
            [card]="card"
          ></app-cart-modal-card>
        </ng-container>
      </div>

      <!-- <div class="hints" >
        <div class="title">Получите скидку при покупке двух и более курсов</div>
        <div class="flex flex-col">
          <app-cart-modal-card></app-cart-modal-card>
          <app-cart-modal-card></app-cart-modal-card>
        </div>
      </div> -->
    </ng-container>

    <!-- <h1>{{test}}</h1>
    <h1>{{test1}}</h1>
    <h1>{{test2}}</h1>
    <h1>{{test3}}</h1> -->
    <div
      class="active-courses flex flex-col rg-16 w-100"
      *ngIf="activeCourses.length > 0"
    >
      <div class="title">{{ 'activeCourses' | translate }}</div>

      <ng-container *ngFor="let item of activeCourses">
        <app-cart-modal-card-active
          [card]="item"
          (onCheckClick)="onToggleCheckedActiveCourse($event)"
        ></app-cart-modal-card-active>
      </ng-container>
    </div>
    <app-empty-message
      *ngIf="cartItems.length === 0 && activeCourses.length === 0"
      [message]="'emptyCart' | translate"
    ></app-empty-message>
  </div>

  <ng-container *ngIf="cartItems.length > 0 || activeCourses.length > 0">
    <div class="price flex flex-col">
      <div class="sum flex justify-between">
        <div class="label">{{'sum' | translate}}</div>
        <div class="value">{{ totalPrice | number }} ₸</div>
      </div>
      <div class="discount flex justify-between">
        <div class="label">{{'discount' | translate}}</div>
        <div class="value value-error">-{{ discountPrice | number }} ₸</div>
      </div>
      <div class="total-price flex justify-between">
        <div class="label">{{'total' | translate}}</div>
        <div class="value">{{ sumPrice | number }} ₸</div>
      </div>
    </div>
    <button
      class="button-primary radius-8"
      [disabled]="streamIds.length === 0"
      (click)="goToOrder()"
    >
      {{'goToPayment' | translate}}
    </button>
  </ng-container>
</div>
