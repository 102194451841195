import {
  FormGroup,
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY_VALUE } from '../consts/common';

export function passwordMatchValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const formGroup = control as FormGroup;
    const password = formGroup.get('password');
    const confirmPassword = formGroup.get('repassword');

    if (
      password &&
      confirmPassword &&
      password.value !== confirmPassword.value
    ) {
      return { passwordMismatch: true };
    } else {
      return null;
    }
  };
}

export function selectRequiredValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if ( !control.value ||  control.value === EMPTY_VALUE || control.value === "" ) return { required: true };
    return null;
  };
}

export function getFormControlErrors(
  form: FormGroup,
  error: any,
  controlName: string,
  translateService?: TranslateService
): void {
  if (form && form.errors) {
    Object.keys(form.errors).forEach((key) => {
      switch (key) {
        case 'passwordMismatch':
          error['password'] =
            translateService?.instant('passwordMismatch') ??
            'Пароли не совпадают';
          error['repassword'] =
            translateService?.instant('passwordMismatch') ??
            'Пароли не совпадают';
          break;
      }
    });
    return;
  } else {
    error['password'] = '';
    error['repassword'] = '';
  }

  const control = form.get(controlName);
  if (control && control.errors) {
    Object.keys(control.errors).forEach((key) => {
      switch (key) {
        case 'required':
          error[controlName] =
            translateService?.instant('requiredField') ??
            'REQUIRED_FIELD';
          break;
        case 'email':
          error[controlName] =
            translateService?.instant('invalidEmail') ??
            'Некорректный формат email';
          break;
        case 'minlength':
          error[controlName] =
            translateService?.instant('passwordInvalidLength') ??
            'Пароль должен содержать не менее 8 символов';
          break;
        default:
          error[controlName] =
            translateService?.instant('unknownError') ?? 'Неизвестная ошибка';
          break;
      }
    });
  } else {
    error[controlName] = '';
  }
}
