export enum ButtonColors {
  Brand = 'brand',
  Success = 'success',
  Error = 'error',
  Black = 'black',
  Default = 'default',
  DefaultBorder = 'default-border',
  Filter = 'Filter'
}

export enum ButtonTypes {
  Outline = 'outline',
  Flat = 'flat',
}

export enum ButtonSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum ButtonRadius {
  Medium = '100px'
}
