import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Roles } from '../../model';
import { CoursesFolder } from '../../model/interface/folders';

@Injectable({
  providedIn: 'root'
})
export class FoldersService {

  constructor(private http: HttpClient) {}


  public getFoldersMethodist(courseId: string): Observable<CoursesFolder[]> {
    return this.http.get<CoursesFolder[]>(`/v2/methodist/courses/${courseId}/course-folders/lessons`);
  }

  public getFoldersStudent(groupId: string): Observable<CoursesFolder> {
    return this.http.get<CoursesFolder>(`/v2/student/groups/${groupId}/course-folders`);
  }

  public getFoldersCurator(groupId: string): Observable<CoursesFolder[]> {
    return this.http.get<CoursesFolder[]>(`/v2/curator/groups/${groupId}/course-folders/lessons`)
  }

  public getFoldersHeadTeacher(courseId: string, groupId: string): Observable<CoursesFolder[]> {
    return this.http.get<CoursesFolder[]>(`/v2/headteacher/courses/${courseId}/groups/${groupId}/course-folders/lessons`)
  }

  public createFolder(courseId: string, name: string): Observable<any> {
    return this.http.post<any>(`/v2/methodist/courses/${courseId}/course-folders`, {name: name});
  }

  public editFolder(courseId: string, courseFolderId: string, name: string): Observable<any> {
    return this.http.put<any>(`/v2/methodist/courses/${courseId}/course-folders/${courseFolderId}`, {name: name});
  }

  public switchFolders(courseId: string, courseFolderId: string, newIndex: number): Observable<CoursesFolder[]> {
    return this.http.patch<CoursesFolder[]>(`/v2/methodist/courses/${courseId}/course-folders/${courseFolderId}`, {}, {
      params: {
        newIndex
      }
    })
  }

  public switchLessons(courseId: string, courseFolderId: string, lessonId: string, newIndex: number): Observable<CoursesFolder[]> {
    return this.http.patch<CoursesFolder[]>(`/v2/methodist/courses/${courseId}/course-folders/${courseFolderId}/lessons/${lessonId}`, {}, {
      params: {
        newIndex
      }
    })
  }

  deleteCourseFolder(courseId: string, courseFolderId: string): Observable<void> {
    return this.http.delete<void>( `/v2/methodist/courses/${courseId}/course-folders/${courseFolderId}`);
  }
}
