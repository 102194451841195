import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { environment } from 'src/environment';

@Component({
  selector: 'app-responsibles-card',
  templateUrl: './responsibles-card.component.html',
  styleUrls: ['./responsibles-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResponsiblesCardComponent {
  @Input() teacherFirstLetter: string | undefined = '';
  @Input() curatorFirstLetter: string | undefined = '';
  @Input() isOpen: boolean = false;
  @Input() teacherImg: string | undefined;
  @Input() curatorImg: string | undefined;

  public readonly environment = environment;

  toggle(): void {
    this.isOpen = !this.isOpen;
  }
}
