import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';
import { BaseRoutes, StudentRoutes } from 'src/app/core/model';

interface Navigation {
  imgActive: string,
  imgNotActive: string,
  isActive: boolean,
  name: Names,
}

enum Names {
  main = "MAIN_PAGE",
  education = "Обучение",
  calendar = "Календарь",
  allTasks = "MY_TASKS"
}
@Component({
  selector: 'app-navigator-mobile',
  templateUrl: './navigator-mobile.component.html',
  styleUrls: ['./navigator-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigatorMobileComponent implements OnInit {
  public navs: Navigation[] = [
    {
      name: Names.main,
      imgActive: './assets/svg/mobile/main.svg',
      imgNotActive: './assets/svg/mobile/main-active.svg',
      isActive: true,
    },
    {
      name: Names.calendar,
      imgActive: './assets/svg/mobile/calendar.svg',
      imgNotActive: './assets/svg/mobile/calendar-active.svg',
      isActive: false,
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef
  ){}

  ngOnInit(): void {

    this.router.events.subscribe((event:any) => {
      if(!event.routerEvent) {
        return;
      }
      const currentRoute = event.routerEvent.url.split('/');

      if(currentRoute.includes(BaseRoutes.Student)) {
        if (this.navs.findIndex(x => x.name === Names.allTasks) < 0) {
          this.navs = [
            ...this.navs.slice(0, 1),
            {
              name: Names.allTasks,
              imgActive: './assets/svg/check-circle.svg',
              imgNotActive: './assets/svg/check-circle-active.svg',
              isActive: true,
            },
            ...this.navs.slice(1)
          ];
        }
      }

      if(currentRoute.includes(StudentRoutes.Main)) {
        this.navs[0].isActive = true;
        this.navs[1].isActive = false;
        if (this.navs.length > 2) {
          this.navs[2].isActive = false;
        }
      }
      if(currentRoute.includes(BaseRoutes.Calendar)) {
        this.navs[1].isActive = true;
        if (this.navs.length > 2) {
          this.navs[2].isActive = true;
          this.navs[1].isActive = false;
          this.navs[0].isActive = false;
        }
      }
      if(currentRoute.includes(StudentRoutes.AllTasks)) {
        this.navs[1].isActive = true;
        this.navs[2].isActive = false;
        this.navs[0].isActive = false;
      }

      this.cdr.detectChanges();
    })
  }

  public navigateTo(name: Names): void {
    this.navs.map(item => {
      if(item.name === name) {
        item.isActive = true;
      }
      else {
        item.isActive = false;
      }
    });

    if(name === Names.main) {
      this.router.navigate([`${BaseRoutes.Student}/${StudentRoutes.Main}`]);
    }
    if(name === Names.calendar) {
      this.router.navigate([`${BaseRoutes.Student}/${BaseRoutes.Calendar}`]);
    }
    if(name === Names.allTasks) {
      this.router.navigate([`${BaseRoutes.Student}/${StudentRoutes.AllTasks}`]);
    }
  }
}
