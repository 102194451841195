import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {SystemService} from "../../../../core/services/system/system.service";
import {BehaviorSubject} from "rxjs";
import {AlertMessageType, SystemAlerts} from "../../../models/interfaces/system-alerts";
import {Router} from "@angular/router";

@Component({
  selector: 'app-announcements-alert',
  templateUrl: './announcements-alert.component.html',
  styleUrls: ['./announcements-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnouncementsAlertComponent implements OnInit {
  @Input() title: string = 'TECHNICAL_WORKS_ON_SYSTEM';
  @Input() description: string = 'TECHNICAL_WORKS_ON_SYSTEM_DESCRIPTION';

  systemAlert$: BehaviorSubject<SystemAlerts | null> = new BehaviorSubject<SystemAlerts | null>(null);

  constructor(
    private systemService: SystemService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.systemAlert$ = this.systemService.currentSystemAlert$;
    if (!this.systemAlert$.value) {
      this.systemService.getSystemAlerts().subscribe({
        next: alerts => {
          // alerts = [
          //   {
          //     id: '1',
          //     message: 'test',
          //     messageType: AlertMessageType.info
          //   }
          // ]

          this.systemService.setSystemAlert(alerts?.length ? alerts[0] : null);
          if (alerts?.length && alerts[0]?.messageType === AlertMessageType.blocker) {
            this.router.navigate(['/system-error']).then();
          }
        },
        error: _ => {
          this.router.navigate(['/system-error']).then();
        }
      });
    }
  }
}
