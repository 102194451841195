<form class="links" [formGroup]="form">
  <h1 class="links-title">{{'helpfulLinks' | translate}}</h1>
  <ul class="links-list" formArrayName="list">
    <li class="links-item" *ngFor="let link of usefulLinks.controls; let i = index;" [formGroupName]="i">
      <div class="links-input">
        <app-input
          [error]="formErrors[i].name"
          (input)="save()"
          [name]="'name' | translate"
          formControlName="name"
          [placeholder]="'enterName' | translate"
        >
        </app-input>
      </div>
      <div class="links-input">
        <app-input
          [error]="formErrors[i].link"
          (input)="save()"
          [name]="'linkAddress' | translate"
          formControlName="link"
          [placeholder]="'enterLinkAddress' | translate">
        </app-input>
      </div>
      <button (click)="removeLink(i)">
        <img src="assets/svg/informative/trash.svg" alt=""/>
      </button>
    </li>
  </ul>
  <button class="iconic-button additional-button button" (click)="addLink()">{{'add' | translate}}</button>
</form>
