<div class="container">
  <button class="radio-container">
    <input type="radio" id="yes" name="choice" class="radio-input">
    <label for="yes" class="radio-label">ДА</label>
  </button>

  <button class="radio-container">
    <input type="radio" id="yes" name="choice" class="radio-input">
    <label for="yes" class="radio-label">НЕТ</label>
  </button>
</div>