<app-dialog [title]="title" [isOpen]="isModalOpen" (closeEvent)="closeDialog()" [showButtons]="false">
  <div class="confirm-modal flex flex-col">
    <p class="confirm-modal_content">
      {{text | translate}}
    </p>
    <div class="confirm-modal_footer flex justify-center">
      <button (click)="deleteHandler()" [disabled]="isLoading" class="iconic-button error-button button">
        {{confirmText | translate}}
      </button>
      <button (click)="closeDialog()" [disabled]="isLoading" class="iconic-button secondary-button button">
        {{cancelText |translate}}
      </button>
    </div>
  </div>
</app-dialog>
