import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-iconic-table',
  templateUrl: './iconic-table.component.html',
  styleUrls: ['./iconic-table.component.scss']
})
export class IconicTableComponent implements OnInit {
  @Input() headers: string[] = [];
  @Input() rows: any[] = [];
  @Output() rowClickedEvent: EventEmitter<number> = new EventEmitter<number>();
  public readonly Object = Object;
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
  
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  
}
