import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IDicountsSubjects, IDiscountOfUser, IDiscountOfUserBase, IDiscountsOfUser, IDiscountsUserDetails } from '../../model/interface/discounts';

@Injectable({
  providedIn: 'root'
})
export class DiscountsService {
  pipe(arg0: any) {
    throw new Error('Method not implemented.');
  }

  constructor(private http: HttpClient) {}

  public getSubjects(): Observable<IDicountsSubjects[]> {
    return this.http.get<IDicountsSubjects[]>(`/v1/headteacher/discounts/subjects`);
  }

  public getDiscountUserDetails(discountId: string): Observable<IDiscountsUserDetails[]> {
    return this.http.get<IDiscountsUserDetails[]>(`/v1/headteacher/discounts/${discountId}/discount-user-details`);
  }

  public getDiscountStudentDetail(username: string): Observable<IDiscountsOfUser> {
    return this.http.get<IDiscountsOfUser>(`/v1/headteacher/discounts/students/${username}`);
  }
  public createDiscountStudentDetail(data: IDiscountOfUserBase): Observable<IDiscountOfUser> {
    return this.http.post<IDiscountOfUser>(`/v1/headteacher/discounts/${data.discountId}/discount-user-details`, data)
  }
  public updateDiscountStudentDetail(discountUserDetailId: string,data: IDiscountOfUserBase): Observable<IDiscountOfUser> {
    return this.http.put<IDiscountOfUser>(`/v1/headteacher/discounts/discount-user-details/${discountUserDetailId}`, data)
  }
  public deleteDiscountStudentDetail(discountUserDetailId: string): Observable<IDiscountOfUserBase> {
    return this.http.delete<IDiscountOfUserBase>(`/v1/headteacher/discounts/discount-user-details/${discountUserDetailId}`)
  }

  public searchDiscount(username: string): Observable<IDiscountsUserDetails[]> {
    return this.http.get<IDiscountsUserDetails[]>(`/v1/headteacher/discounts/student/search`, {
      params: {
        username
      }
    })
  }

  public importFile(file: FormData): Observable<string> {
    return this.http.post<string>(`/v1/headteacher/discounts/discount-user-details/import`, file);
  }

}
