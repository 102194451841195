import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { SUBJECTS_OPTIONS } from 'src/app/shared/models/subjects';
import { CoursesDTO, MethodistUser, Roles, StudentGroupDTO, StudentUser } from '../../model';
import { IEvent, IEventCreate } from '../../model/interface/event';
import { IAnnouncementCreate } from '../../model/interface/announcement';
import { LinkSubject, SubjectDTO } from '../../model/interface/subject';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  public isShowModal$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public selectedUser$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public catalogPaginatorPage$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private _http: HttpClient) {}

  public toggleIsShowModal(): void {
    this.isShowModal$.next(!this.isShowModal$.getValue());
  }

  public getCuratorGroups(curatorId: string): Observable<any> {
    return this._http.get(`/v1/admin/curator/${curatorId}/groups`);
  }

  public getHeadTeacherCurators(headteacherId: string): Observable<any> {
    return this._http.get(`/v1/admin/headteacher/${headteacherId}/curators`);
  }

  public linkCuratorToHeadteacher(
    headteacher: string,
    curatorsIds: string[]
  ): Observable<any> {
    return this._http.post(`/v1/admin/link/headteacher/curator`, {
      headteacherId: headteacher,
      curatorIds: curatorsIds,
    });
  }
  public unlinkCuratorToHeadteacher(
    headteacher: string,
    curatorsIds: string[]
  ): Observable<any> {
    return this._http.post(`/v1/admin/unlink/headteacher/curator`, {
      headteacherId: headteacher,
      curatorIds: curatorsIds,
    });
  }

  public getStudentGroups(studentId: string): Observable<any[]> {
    return this._http.patch<any[]>(`/v1/admin/students/${studentId}/groups`, {
      params: {
        studentId,
      },
    });
  }

  public getSubjects(): { name: string; value: string }[] {
    return SUBJECTS_OPTIONS;
  }

  public getCourses(id: string): Observable<any> {
    return this._http
      .get<any[]>(`/v1/headteacher/subjects/${id}/courses`)
      .pipe(
        map((res) =>
          res.map((item: any) => ({ name: item.name, value: item.id }))
        )
      );
  }

  public getCuratorList({
    page,
    size,
    search,
    sort
  }: {
    page: number;
    size: number;
    search?: string;
    sort?: string;
  }): Observable<any> {
    let params: any = {
      page: page,
      size: size,
      sort: [sort ?? '']
    };

    if (search) params.search = search;

    return this._http.get<any[]>(`/v1/admin/curator/list`, {
      params,
    });
  }

  public getGroupsOptions(id: string): Observable<any> {
    return this._http
      .get<any[]>(`/v1/headteacher/courses/${id}/groups`)
      .pipe(
        map((res) =>
          res.map((item: any) => ({ name: item.name, value: item.id }))
        )
      );
  }

  public getGroups(id: string): Observable<any> {
    return this._http.get<any[]>(`/v1/headteacher/courses/${id}/groups`);
  }

  public addStudentToCourse(
    studentId: string,
    groupId: string,
    periodIds: string[],
    managerId?: string
  ): Observable<any> {
    const params: any = {
      studentId,
      groupId,
    };

    if (managerId) params.managerId = managerId;
    return this._http.patch(
      `/v1/admin/groups/${groupId}/students/${studentId}`,
      {
        paymentDateIds: periodIds,
      },
      {
        params,
      }
    );
  }

  public removeStudentFromGroup(
    studentId: string,
    groupId: string
  ): Observable<any> {
    return this._http.patch(
      `/v1/admin/groups/${groupId}/students/${studentId}/remove`,
      {
        params: {
          studentId,
          groupId,
        },
      }
    );
  }

  public getStatistics(): Observable<any> {
    return this._http.get(`/v1/admin/statistics`);
  }

  public generateRegistrationLink(paymentHistoryId: string): Observable<{link: string}> {
    return this._http.get<{link: string}>(`/v1/admin/registration/link/${paymentHistoryId}`);
  }

  public changeUserInfo(userId: string,data: any): Observable<any> {
    return this._http.post<any>(`/v1/admin/users${userId}/change`, data);
  }


  public updateEvent(id: string,  data: IEventCreate): Observable<IEvent> {
    return this._http.put<IEvent>(`/v1/admin/events/${id}`, data);
  }
  public removeEvent(id: string): Observable<IEvent> {
    return this._http.delete<IEvent>(`/v1/admin/events/${id}`);
  }

  public createAnnounce(data: IAnnouncementCreate): Observable<any> {
    return this._http.post<any>(`/v1/admin/announcements`, data);
  }

  public updateAnnounce(announcementId: string, data: IAnnouncementCreate): Observable<any> {
    return this._http.put<any>(`/v1/curator/announcements/${announcementId}`, data);
  }


  public getHeadteachersList(): Observable<StudentUser[]> {
    return this._http.get<StudentUser[]>(`/v1/admin/headteachers`);
  }

  public addSubjectsToHeadteacher(data: LinkSubject): Observable<LinkSubject> {
    return this._http.post<LinkSubject>(`/v1/admin/link/headteacher/subject`, data);
  }
  public removeSubjectsToHeadteacher(data: LinkSubject): Observable<LinkSubject> {
    return this._http.post<LinkSubject>(`/v1/admin/unlink/headteacher/subject`, data);
  }

  public addSubjectsToMethodist(data: LinkSubject): Observable<LinkSubject> {
    return this._http.post<LinkSubject>(`/v1/admin/link/methodist/subject`, data);
  }
  public removeSubjectsToMethodist(data: LinkSubject): Observable<LinkSubject> {
    return this._http.post<LinkSubject>(`/v1/admin/unlink/methodist/subject`, data);
  }

  public getStudentsByGroupIds(groupIds: string[]): Observable<StudentUser[]> {
    return this._http.post<StudentUser[]>(`/v1/admin/studentsbygroupids`, groupIds);
  };

  public getEvents(): Observable<IEvent[]> {
    return this._http.get<IEvent[]>(`/v1/admin/events`);
  }

  public getEventById(id: string): Observable<IEvent> {
    return this._http.get<IEvent>(`/v1/admin/events/${id}`);
  }

  public createEvent(data: IEventCreate): Observable<IEvent> {
    return this._http.post<IEvent>(`/v1/admin/events`, data);
  };

  public getCoursesBySubjectIds(subjectIds: string[]): Observable<CoursesDTO[]> {
    return this._http.post<CoursesDTO[]>(`/v1/admin/coursesbysubjectids`, subjectIds);
  };

  public getGroupsByCourseIds(courseIds: string[]): Observable<StudentGroupDTO[]> {
    return this._http.post<StudentGroupDTO[]>(`/v1/admin/groupsbycourseids`, courseIds);
  };

  public getCuratorsByCourseIds(courseIds: string[]): Observable<StudentUser[]> {
    return this._http.post<StudentUser[]>(`/v1/admin/curatorsbycourseids`, courseIds);
  };

  public editProfileInfoOfUser(
    userid: string,
    data: StudentUser | MethodistUser
  ): Observable<StudentUser | MethodistUser> {
    return this._http
      .post<StudentUser | MethodistUser>(`/v1/admin/users/${userid}/change`, data)
      .pipe(
        map((res: StudentUser | MethodistUser) => {
          return res;
        })
      );
  }

  public getAnnouncements(pageable: ServiceDto): Observable<any> {
    if (!pageable?.roles?.length) {
      pageable.roles = [Roles.HeadTeacher, Roles.Curator, Roles.Student];
    }
    const params: any = pageable;
    return this._http.get<any>('/v1/admin/announcements', {
      params,
    });
  }

  public deleteAnnouncement(announcementId: string): Observable<void> {
    return this._http.delete<void>(`/v1/admin/announcements/${announcementId}`);
  }

  public discountsMigrate(fromUserName: string, toUserName: string): Observable<void> {
    return this._http.put<void>(`/v1/headteacher/discounts/migrate/from/${fromUserName}/to/${toUserName}`, {});
  }

  public getMySubjects(): Observable<SubjectDTO[]> {
    return this._http.get<SubjectDTO[]>('/v1/admin/subjects');
  }

  public deleteGroup(groupId: string): Observable<void> {
    return this._http.delete<void>(`/v1/admin/course-group/delete/${groupId}`);
  }
}

interface ServiceDto {
  page?: number;
  size?: number;
  search?: string;
  sort?: string[],
  groupIds?: string[],
  courseIds?: string[],
  roles?: Roles[]
}
