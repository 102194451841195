<div class="link-curator flex flex-col rg-16">
  <h2 class="title">Прикрепление куратора</h2>

  <app-multi-select
    [options]="curatorOptions"
    [withSearch]="true"
    (selectedValue)="onSelectCuratorsForLinkHeadTeacher($event)"
    (searchInputHandler)="onSearch($event)"
    name="Кураторы"
    [isActive]="true"
  ></app-multi-select>

  <div class="btns flex cg-16 justify-between">
    <app-button text="Отменить" (onButtonClick)="cancel()"></app-button>
    <app-button
      text="Прикрепить"
      (onButtonClick)="onCofirmSelectCurators()"
    ></app-button>
  </div>
</div>
