<app-dialog 
  [isOpen]="isOpen"
  (closeEvent)="closeEvent.emit()"
  (confirmEvent)="save()"
  [disabledConfirm]="!form.valid"
  title="Возврат"
  >
  <form [formGroup]="form" class="refund">
    <app-select name="Статус возврата" formControlName="refundStatus" [options]="refundOptions"></app-select>
    <app-input name="Примечание  " formControlName="refundComment"></app-input>
  </form>
</app-dialog>