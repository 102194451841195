import { UsersService } from './../../../../core/services/users/users.service';
import { StudentService } from './../../../../core/services/student/student.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseRoutes, BodyStudentRating, MethodistUser, StudentGroupThemeDTO, StudentRoutes, StudentUser } from 'src/app/core/model';
import { debounceTime, distinctUntilChanged, Observable, Subscription, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectCurrentGroupWithDetails } from 'src/app/core/state/student/groups/groups.selector';
import { environment } from 'src/environment';
import { selectProfileState } from 'src/app/core/state/student/user/user.selector';

@Component({
  selector: 'app-rating-card',
  templateUrl: './rating-card.component.html',
  styleUrls: ['./rating-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatingCardComponent implements OnInit, OnDestroy {
  public userId: string | undefined;
  public ratingList!: BodyStudentRating[];

  public currentGroup: StudentGroupThemeDTO | null | undefined;
  private currentGroupWithDetails$: Observable<StudentGroupThemeDTO | null | undefined> = this.store.select(selectCurrentGroupWithDetails);
  private currentGroupWithDetailsSubscription!: Subscription;

  public fileBaseUrl: string = environment.filesUrl;

  public currentUserInfo: BodyStudentRating | undefined;

  public averageScore: number = 0;

  public isRatingPage: boolean = false;

  public profile$: Observable<StudentUser | MethodistUser | null | undefined> = this.store.select(selectProfileState);
  public profileSubscription!: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private studentService: StudentService,
    private cdr: ChangeDetectorRef,
    private usersService: UsersService,
    private store: Store
  ) {}

  ngOnInit(): void {
    if(this.router.url === `/${BaseRoutes.Student}/${StudentRoutes.Rating}`) {
      this.isRatingPage = true;
    }
    this.profileSubscription = this.profile$.pipe(take(1),debounceTime(1000),distinctUntilChanged((prev, curr) => prev?.id === curr?.id)).subscribe({
      next: (res) => {
        if(res) {
          this.userId = res.id;
          this.currentGroupWithDetailsSubscription = this.currentGroupWithDetails$.pipe(distinctUntilChanged((prev, curr) => prev?.groupId === curr?.groupId)).subscribe({
            next: (res) => {
              if(res) {
                if(window.innerWidth > 999) {
                  this.studentService.getTopSudents(res.groupId).subscribe(res => {
                    this.ratingList = res;
                    this.cdr.detectChanges();
                  });
                } else {
                  if(this.isRatingPage) {
                    this.studentService.getStudentRating(res?.groupId).pipe(take(1)).subscribe(res => {
                      const filteredByTotalScore: BodyStudentRating[] = res.body.sort((a, b) => b.totalScore - a.totalScore);

                      this.ratingList = filteredByTotalScore.map((item: BodyStudentRating, index: number) => {
                        return {
                          ...item,
                          rank: index + 1
                        }
                      });

                      const totalScoreSum: number = filteredByTotalScore.reduce((sum, studentRating) => sum + studentRating.totalScore, 0);
                      this.averageScore = totalScoreSum / filteredByTotalScore.length;

                      this.currentUserInfo = this.ratingList.find(item => item.studentId == this.userId);
                      this.cdr.detectChanges();
                    });
                  }
                  else {
                    this.studentService.getTopSudents(res.groupId).subscribe(res => {
                      this.ratingList = res;
                      this.cdr.detectChanges();
                    });
                  }
                }
              }
              this.cdr.detectChanges();
            },
            error(err) {
              console.log(err)
            },
          })
        }
        this.cdr.detectChanges();
      },
      error(err) {
        console.log(err);
      },
    })
  }

  ngOnDestroy(): void {
    if(this.currentGroupWithDetailsSubscription) {
      this.currentGroupWithDetailsSubscription.unsubscribe();
    }
  }

  public goToRating(): void {
    this.router.navigate([`${BaseRoutes.Student}/${StudentRoutes.Rating}`]);
  };

  public goToScoresDeatilsPage(): void {
    this.router.navigate([`${BaseRoutes.Student}/${StudentRoutes.ScoresDetails}`]);
  }
}
