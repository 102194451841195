<div class="student-progress">
  <div class="header flex items-center">
    <app-avatar class="avatar" [firstLetter]="userInfo?.fullName[0]" [imageUrl]="userInfo?.avatar"></app-avatar>
    <div class="description flex flex-col">
      <div class="name">{{ userInfo?.fullName }}</div>
      <div class="group-email flex items-center">
        <div class="email">{{ userInfo?.email ?? "—" }}</div>
      </div>
    </div>
  </div>
  <app-block-role-phone-insta-info
    [role]="userInfo?.role"
    [phone]="userInfo?.phone"
    [instagram]="userInfo?.instagramLink"
  ></app-block-role-phone-insta-info>

  <div class="groups" *ngIf="group">
    <h3 class="groups__header">{{'groups' | translate}}</h3>

    <div *ngIf="group.length; else noGroups" class="content flex flex-col">
      <div *ngFor="let group of groups" class="admin-modal-curator-card border radius-8">
        <div class="flex flex-col wrapper">
          <div class="course-name">{{ group.courseName }}</div>
          <div class="info flex items-center">
            <div
              class="info__item"
              *ngIf="group.streamYear || group.streamMonth"
            >
              {{ group.streamMonth | monthConverter: currentLang: true }} {{ group.streamYear % 100 }}
            </div>

            <div
              class="circle"
              *ngIf="(group.streamMonth || group.streamYear) && group.name"
            ></div>
            <div class="info__item">
              {{ group.name }}
            </div>
            <div class="circle" *ngIf="group.studentCount"></div>
            <div class="info__item">{{ group.studentCount }} учеников</div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #noGroups>
      <div class="groups__no-groups">{{'notAssignedToGroup' | translate}}</div>
    </ng-template>
  </div>
</div>
