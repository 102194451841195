import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { getProgressAction, getProgressActionSuccess, getProgressActionFailure, finishQuiz, finishQuizSuccess, startQuiz, startQuizSuccess } from './progress.actions';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ProgressService } from './../../../services/progress/progress.service';
import { of } from 'rxjs';
import { StudentService } from "src/app/core/services/student/student.service";

@Injectable()
export class ProgressEffects {

    loadProgress$ = createEffect(() => this.actions$.pipe(
        ofType(getProgressAction),
        switchMap(action => {
            const groupId = action.groupId;
            return this.progressService.getProgress(groupId).pipe(
                map(progressList => getProgressActionSuccess({ progressList })),
                catchError(error => of(getProgressActionFailure({ error })))
            );
        })
    ));
    
    constructor(
        private actions$: Actions,
        private progressService: ProgressService
    ) {}
}

@Injectable()
export class FinishQuizEffect {

    finishQuiz$ = createEffect(() => this.actions$.pipe(
        ofType(finishQuiz),
        switchMap(action => {
            const groupId = action.groupId;
            const lessonId = action.lessonId;
            const answerList = action.userAnswerList;
            return this.studentService.finishQuiz(groupId, lessonId, answerList).pipe(
                map(progress => finishQuizSuccess({ progress })),
                catchError(error => of(getProgressActionFailure({ error })))
            );
        })
    ));

    constructor(
        private actions$: Actions,
        private studentService: StudentService,

    ) {}
}

@Injectable()
export class StartQuizEffect {

    startQuiz$ = createEffect(() => this.actions$.pipe(
        ofType(startQuiz),
        switchMap(action => {
            console.log(action);
            const groupId = action.groupId;
            const lessonId = action.lessonId;
            return this.studentService.startQuiz(groupId, lessonId).pipe(
                map(progress => startQuizSuccess({ progress })),
                catchError(error => of(getProgressActionFailure({ error })))
            );
        })
    ));

    constructor(
        private actions$: Actions,
        private studentService: StudentService,

    ) {}
}
