<div class="container">
  <ul class="left">
    <li *ngFor="let item of question.questions; let i = index;">
      <h1
      *ngIf="_QuizState.View === quizState"
      [ngClass]="{
        'answer-correct' : 
        (_QuizState.View === quizState) 
        && question.correctAnswerTexts && question.correctAnswerTexts[i]
        && question.userAnswers && question.userAnswers[i]
        && question.correctAnswerTexts[i] === question.userAnswers[i].answerTextValue,
        'answer-incorrect': 
        (_QuizState.View === quizState) 
        && question.correctAnswerTexts && question.correctAnswerTexts[i]
        && question.userAnswers && question.userAnswers[i]
        && question.correctAnswerTexts[i] !== question.userAnswers[i].answerTextValue,

    }" 
      class="question">{{ item }}</h1>
    </li>
  </ul>
  <ul class="right">
    <li *ngFor="let item of question.answers; let i = index;">
      <app-cdk-select *ngIf="_QuizState.Passing === quizState" [value]="item" (selectChangeHandler)="selected(i, $event)" [options]="options"></app-cdk-select>
      <h1
      *ngIf="_QuizState.View === quizState"
        [ngClass]="{
          'answer-correct' : 
          (_QuizState.View === quizState) 
          && question.correctAnswerTexts && question.correctAnswerTexts[i]
          && question.userAnswers && question.userAnswers[i]
          && question.correctAnswerTexts[i] === question.userAnswers[i].answerTextValue,
          'answer-incorrect': 
          (_QuizState.View === quizState) 
          && question.correctAnswerTexts && question.correctAnswerTexts[i]
          && question.userAnswers && question.userAnswers[i]
          && question.correctAnswerTexts[i] !== question.userAnswers[i].answerTextValue,

      }" 
      class="question">{{getAnswer(item, i)}}</h1>
    </li>
  </ul>
</div>

<app-result
  [explanation]="question.explanation"
  [referenceUrl]="question.referenceUrl"
  [explanationImageUrl]="question.explanationImageUrl"
  [score]="question.score"
  *ngIf="(_QuizState.View === quizState) && question.correctAnswerTexts"
  [title]="'correctAnswer' | translate"
  infoText="">
  <div class="answer-wrapper">
    <div class="answer" *ngFor="let answer of question.correctAnswerTexts;">
      <app-editor-viewer [content]="answer.toString()"></app-editor-viewer>
    </div>
  </div>
</app-result>