import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, QueryList, ViewChildren, OnInit, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { Subject, debounceTime } from 'rxjs';
import { ForAnswerTestQuestions, QuizState, UserAnswer } from 'src/app/core/model';

@Component({
  selector: 'app-insert-words',
  templateUrl: './insert-words.component.html',
  styleUrls: ['./insert-words.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsertWordsComponent implements OnInit, AfterViewInit {
  @ViewChildren('myInput') myInput!: QueryList<ElementRef<HTMLInputElement>>;
  @ViewChildren('sentences') sentenceList!: QueryList<ElementRef<HTMLInputElement>>;
  private debouncer: Subject<UserAnswer[] | null> = new Subject<UserAnswer[] | null>();

  public readonly _QuizState = QuizState;
  @Input() quizState: QuizState = QuizState.Passing;
  @Input() question!: ForAnswerTestQuestions;
  @Input() readOnly: boolean = false;
  @Output() answeredEvent: EventEmitter<{ questionId: string, answer: UserAnswer[]}> = new EventEmitter<{ questionId: string, answer: UserAnswer[]}>();

  public sentences: string[] = [];
  public options: string[] = [];


  constructor(
    private cdr: ChangeDetectorRef
  ) {
    this.debouncer.pipe(
      debounceTime(500)
    ).subscribe((list: UserAnswer[] | null) => {
      this.answeredEvent.emit({questionId: this.question.id, answer: list || []})
    })
  }

  ngOnInit(): void {
    this.sentences = this.question.questionPattern!.split('{}');
  }

  ngAfterViewInit(): void {
    if(this.question.userAnswers) {
      this.myInput?.map((input, index) => input.nativeElement.value = this.question.userAnswers![index]?.answerTextValue || '');
      this.cdr.detectChanges();

      if(this.question.userAnswers && this.question.userAnswers.length > 0) {
        this.myInput?.map((input, index) => input.nativeElement.value = this.question.userAnswers![index]?.answerTextValue || '');
        this.cdr.detectChanges();
      }
    }
  }

  public sendAnswers(): void {
    if(this.myInput) {
      const list: UserAnswer[] = [];
      for(let element of this.myInput) {
        list.push({ answerTextValue: element.nativeElement.value.trim() });
      }
      if(list.every(item => !item.answerTextValue)) {
        this.debouncer.next(null);
      } else {
        this.debouncer.next(list)
      }
    }
  }

  calculateWidth(value: string): number {
    const pixelPerCharacter = 10;
    const minLength = 106;
    const length = value.length;
    let width = length * pixelPerCharacter;
    return Math.max(width, minLength);
  }
}
