import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linkify'
})
export class LinkifyPipe implements PipeTransform {
  transform(html: string): string {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    this.processNode(tempDiv);

    return tempDiv.innerHTML;
  }

  private processNode(node: Node): void {
    const urlPattern = /(https?:\/\/[^\s]+)/g;

    if (node.nodeType === Node.TEXT_NODE) {
      const textContent = node.nodeValue || '';
      if (urlPattern.test(textContent)) {
        const replacedHtml = textContent.replace(urlPattern, (url) => {
          return `<a href="${url}" target="_blank" rel="noopener noreferrer" class="highlight-link">${url}</a>`;
        });

        const wrapper = document.createElement('span');
        wrapper.innerHTML = replacedHtml;

        (node as ChildNode).replaceWith(...wrapper.childNodes as any);
      }
    }
    else if (node.nodeType === Node.ELEMENT_NODE) {
      const anchorElements = (node as HTMLElement).querySelectorAll('a');

      anchorElements.forEach((anchor) => {
        if (!anchor.classList.contains('highlight-link')) {
          anchor.classList.add('highlight-link');
        }
      });

      Array.from(node.childNodes).forEach((child) => this.processNode(child));
    }
  }
}
