import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { FileDTO } from '../../model/interface/files';
import { environment } from 'src/environment';
import { AuthService } from './../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) { }


  public getFiles(fileId: string): Observable<Blob> {
    if(!fileId) {
      const mockFile = new Blob(['Mock file content'], { type: 'text/plain' });
      return of(mockFile);
    }
    else {
      return this.httpClient.get(`/v1/files/${fileId}`, { responseType: 'blob' });
    }
  }

  public sendFiles(material: FormData, courseId: string): Observable<FileDTO> {
    return this.httpClient.post<FileDTO>(`${environment.uploadFilesUrl}converter/upload`, material,
      {
        params: {
          path: `${this.authService.getRole().toLocaleLowerCase()}/course/${courseId}`
        }
      }
    ).pipe(
      map(res => {
        return res;
      })
    );
  }

  public _sendFiles(material: FormData, courseId: string  ): Observable<HttpEvent<FileDTO>> {
    return this.httpClient.post<FileDTO>(`${environment.uploadFilesUrl}converter/upload`, material, {
      reportProgress: true,
      observe: 'events',
      params: {
        path: `${this.authService.getRole().toLocaleLowerCase()}/course/${courseId}`
      }
    });
  }

  public removeFiles(url: string): Observable<string> {
    return this.httpClient.delete<string>(`/v1/files/${url}`).pipe(
      map(res => {
        return res;
      })
    )
  }

  public removeListOfFiles(fileIds: string[]): Observable<string> {
    return this.httpClient.delete<string>(`${environment.uploadFilesUrl}converter`, {
      body: fileIds
    })
  }

  public getImageUrl(url : string) : string {
    return `${environment.filesUrl}${url}`
  }
}
