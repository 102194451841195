import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CrmService {

  constructor(
    private http: HttpClient
  ) { }

  public getPlan(filter: any): Observable<any> {
    return this.http.get<any>(`/v1/internal-crm/plan-fact`, { params: filter });
  }

  public createManagerComment(studentId: string, comment: string): Observable<any> {
    return this.http.post<any>(`/v1/internal-crm/students/${studentId}/manager-comment`, { commentText: comment });
  }

  public goingToLeave(studentId: string, value: boolean): Observable<any> {
    return this.http.patch<any>(`/v1/internal-crm/students/${studentId}/going-to-leave?isGoingToLeave=${value}`, {});
  }
}
