<div class="student-list-status border radius-8 bg-white w-100">
  <div class="title flex items-center">
    <ng-container *ngIf="status === 'PASSED'">
      <img src="./assets/svg/timer-filled.svg" alt="" />
      <span>{{'PASSED' | translate}}</span>
    </ng-container>
    <ng-container *ngIf="status === 'VERIFIED'">
      <img src="./assets/svg/informative/base-verified.svg" alt="" />
      <span>{{'VERIFIED' | translate}}</span>
    </ng-container>
    <ng-container *ngIf="status === 'PASSOFF'">
      <img src="./assets/svg/informative/forbidden.svg" alt="" />
      <span>{{'DID_NOT_PASS' | translate}}</span>
    </ng-container>
  </div>

  <ul class="list flex flex-col">
    <ng-container *ngIf="students.length > 0">
      <li *ngFor="let student of students">
        <app-students-list-item
          [width]="width"
          [lesson]="lesson"
          [student]="student"
          [isSelectedStudent]="selectedStudentId === student.studentId"
          (onDetailClick)="onDetailClickHandler($event)"
        ></app-students-list-item>
      </li>
    </ng-container>

    <app-empty-message
      class="h-100"
      *ngIf="students.length === 0"
      message="EMPTY_STUDENTS_LIST"
    ></app-empty-message>
  </ul>
</div>
