<div class="subheader flex flex-col">
  <div class="subheader__item flex role" *ngIf="role">
    <div class="label text-md">{{ "role" | translate }}</div>
    <div class="value text-md">{{ rolesLabel[role] }}</div>
  </div>

  <div *ngIf="user" class="subheader__item flex">
    <div class="label text-md">{{'USERNAME' | translate}}</div>
    <div class="value text-md">{{ user.username }}</div>
  </div>

  <div class="subheader__item flex">
    <div class="label text-md">{{'PHONE' | translate}}</div>
    <div class="value text-md">{{ phone | phone }}</div>
  </div>

  <div class="subheader__item flex">
    <div class="label text-md">Instagram</div>
    <div class="value text-md">{{ instagram ?? "-" }}</div>
  </div>
  <app-expander [showContentFirst]="true" [isExpanded]="isExpanded">
    <div class="hidden-content" content>
      <div class="subheader__item flex">
        <span class="label text-md">Email</span>
        <span class="value text-md">{{ user?.username ?? "-" }}</span>
      </div>
      <div class="subheader__item flex">
        <span class="label text-md">{{'REGION' | translate}}</span>
        <span class="value text-md">{{ user?.regionNameKz ?? "-" }}</span>
      </div>
      <div *ngIf="user?.grade" class="subheader__item flex">
        <span class="label text-md">{{'educationGrade' | translate}}</span>
        <span class="value text-md">{{ user?.grade ?? "-" }}</span>
      </div>
      <div *ngIf="user?.firstSubjectId || user?.secondSubjectId" class="subheader__item flex">
        <span class="label text-md">{{'CHOSEN_SUBJECTS' | translate}}</span>
        <span class="value text-md">{{ subjectsService.subjectTranslations[user?.firstSubjectId] ? subjectsService.subjectTranslations[user?.firstSubjectId][language] : '' || "-" }},&nbsp;</span>
        <span class="value text-md">{{ subjectsService.subjectTranslations[user?.secondSubjectId] ? subjectsService.subjectTranslations[user?.secondSubjectId][language] : '' || "-" }}</span>
      </div>
      <div class="subheader__item flex">
        <span class="label text-md">{{'middleName' | translate}}</span>
        <span class="value text-md">{{ user?.middlename ?? "-" }}</span>
      </div>
      <div *ngIf="user?.parentPhoneNumber" class="subheader__item flex">
        <span class="label text-md">{{'PARENTS_CONTACT' | translate}}</span>
        <span class="value text-md">{{ user?.parentPhoneNumber ?? "-" }}</span>
      </div>
    </div>
    <button class="button-wrapper" [class.mt-12]="isExpanded" button>
      <a (click)="toggle()" class="button" > {{ (isExpanded ? 'HIDE' : 'EXPAND') | translate}}</a>
    </button>
  </app-expander>

  <app-button
    *ngIf="yourRole === Roles.Curator"
    [fullWidth]="true"
    [type]="buttonTypes.Outline"
    text="SHOW_PROGRESS"
    (onButtonClick)="navigateProgress()"
  />

  <div class="flex flex-row buttons">
    <button
      *ngIf="!hideEditBtn
      && (yourRole === Roles.Admin
      || yourRole === Roles.Manager
      || yourRole === Roles.InternalManager)"
      class="iconic-button secondary-button"
      (click)="openDialog()">
      {{'edit' | translate}}
    </button>
    <a
      *ngIf="phone"
      [href]="'https://wa.me/' + phone"
      target="_blank"
      class="whatsapp-btn radius-8 border flex items-center cg-16"
    >
      <img src="assets/svg/socials/logos_whatsapp-icon.svg" alt="" />
      <span class="nowrap"> {{ "writeToWhatsapp" | translate }} </span>
    </a>
  </div>
  <button
    *ngIf="!isRegistered"
    class="iconic-button secondary-button"
    (click)="sendRegistration()">
    {{'RESEND_REGISTRATION' | translate}}
  </button>
</div>

<div *ngIf="yourRole === Roles.Admin || yourRole === Roles.Manager || yourRole === Roles.InternalManager && role">
  <app-personal-info-editor
    [data]="user"
    [role]="role"
    [isOpen]="isOpen"
    [userId]="user?.id"
    [notRegisteredUser]="!!username"
    (closeEvent)="closeDialog()"
    (confirmEvent)="confirmDialog($event)">
  </app-personal-info-editor>
  <app-contact-info-editor
    [data]="user"
    [isOpen]="isOpenContact"
    [notRegisteredUser]="!(user?.username && user?.phoneNumber)"
    (closeEvent)="closeDialog()"
    (confirmEvent)="confirmDialog($event)">
  </app-contact-info-editor>
</div>
