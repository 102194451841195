<!-- <app-dialog [isOpen]="isOpen" (closeEvent)="closeEvent.emit()" [showButtons]="false" title="Удаление скидки">
    <p class="text">Вы действительно хотите удалить скидку по данному предмету для этого студента?</p>
    <div class="buttons">
        <button class="iconic-button secondary-button">Отменить</button>
        <button class="iconic-button primary-button button" (click)="remove()">Удалить</button>
    </div>
</app-dialog> -->

<app-delete-modal
  title="DELETE_STUDENT_DISCOUNT"
  text="ARE_U_SURE_DELETE_STUDENT_DISCOUNT"
  confirmText="YES_REMOVE_STUDENT_DISCOUNT"
  cancelText="NO_REMOVE_STUDENT_DISCOUNT"
  [isModalOpen]="isOpen"
  [id]="discountUserDetailId"
  [isLoading]="isLoading"
  (deleteEvent)="remove($event)"
  (closeEvent)="closeEvent.emit()"
></app-delete-modal>
