import { SelectValue } from '../models';

export const MONTH_OPTIONS = [
  {
    name: 'Январь',
    value: 1,
  },
  {
    name: 'Февраль',
    value: 2,
  },
  {
    name: 'Март',
    value: 3,
  },
  {
    name: 'Апрель',
    value: 4,
  },
  {
    name: 'Май',
    value: 5,
  },
  {
    name: 'Июнь',
    value: 6,
  },
  {
    name: 'Июль',
    value: 7,
  },
  {
    name: 'Август',
    value: 8,
  },
  {
    name: 'Сентябрь',
    value: 9,
  },
  {
    name: 'Октябрь',
    value: 10,
  },
  {
    name: 'Ноябрь',
    value: 11,
  },
  {
    name: 'Декабрь',
    value: 12,
  },
];

export const EMPTY_VALUE: string = '—';
export const EMPTY_SELECT_VALUE: SelectValue = {
  name: EMPTY_VALUE,
  value: EMPTY_VALUE,
};

export const ADMIN_TABLE_SIZE: number = 10;

export const ROLES_OPTIONS = [
  {
    name: 'Студенты',
    value: 'STUDENT',
  },
  {
    name: 'Методисты',
    value: 'METHODIST',
  },
  {
    name: 'Кураторы',
    value: 'CURATOR',
  },
  {
    name: 'Завучи',
    value: 'HEAD_TEACHER',
  },
  {
    name: 'Преподаватель',
    value: 'TEACHER',
  },
  {
    name: 'Менеджер',
    value: 'MANAGER',
  },
];

export const ACTIVITY_OPTIONS = [
  // {
  //   name: 'Выбрать все',
  //   value: 'ALL',
  // },
  {
    name: 'ACTIVE',
    value: 'ACTIVE',
  },
  {
    name: 'PENDING',
    value: 'PENDING',
  },
  {
    name: 'ARCHIVED',
    value: 'ARCHIVED',
  }
];

export const MANAGERS_OPTIONS = [
  {
    name: 'EXTERNAL_MANAGERS',
    value: 'MANAGER',
  },
  {
    name: 'INTERNAL_MANAGERS',
    value: 'INTERNAL_MANAGER',
  }
];
