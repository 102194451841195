<div class="quiz-content-closed" *ngIf="isExpanded">
    <div class="closed-left">
        <h1 class="question-text" [innerHTML]="data?.questionText"></h1>
        <div class="left-botton">
            <span *ngIf="data?.quizQuestionType" class="question-info">{{'Сложность ' + data?.difficultyLevel}}</span>
            <span *ngIf="data?.answerList.length >= 0" class="question-info desktop">{{type === testType.Matching ? data.keyList.length : data?.answerList.length + " варианта ответов"}}</span>
            <span *ngIf="data?.quizQuestionType" class="line-text">|</span>
            <span *ngIf="data?.quizQuestionType" class="question-info">{{data?.quizQuestionType}}</span>
        </div>
    </div>
    <div class="closed-right">
        <button (click)="OpenEvent.emit(index)">
            <img src="assets/svg/informative/edit.svg" alt="" />
        </button>
        <button (click)="RemoveEvent.emit(index)">
            <img src="assets/svg/informative/trash.svg" alt="" />
        </button>
    </div>
</div>