import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { BaseRoutes, MethodistRoutes, StudentRoutes } from 'src/app/core/model';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent {
  public hideSideBarRoutes: string[] = [`${BaseRoutes.Curator}/student-test`, `${BaseRoutes.Student}/${StudentRoutes.Test}`, `${BaseRoutes.Methodist}/${MethodistRoutes.Course}`, BaseRoutes.Calendar];
  public isSideBarShown: boolean = true;

  public noPaddingPagges: string[] = [
    `${BaseRoutes.Methodist}/${MethodistRoutes.Course}`,
    BaseRoutes.Calendar, '/no-padding',
    `${BaseRoutes.Student}/${StudentRoutes.ScoresDetails}`
  ];
  public isPaddingHidden: boolean = false;

  constructor(private router: Router) {
    this.checkCurrentRoute();
    // Subscribe to router events to update sidebar visibility dynamically
    this.router.events.subscribe(() => {
      this.checkCurrentRoute();
    });
  }

  private checkCurrentRoute() {
    const currentRoute = this.router.url;
    this.isSideBarShown = !this.hideSideBarRoutes.some(route => currentRoute.includes(route));

    this.isPaddingHidden = this.noPaddingPagges.some(route => currentRoute.includes(route));
  }
}
