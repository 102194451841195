import { Component, Input, OnInit } from '@angular/core';
import { QuestionnaireTypes } from 'src/app/core/model/interface/questionnaire';

@Component({
  selector: 'app-questionnaire-card',
  templateUrl: './questionnaire-card.component.html',
  styleUrls: ['./questionnaire-card.component.scss']
})
export class QuestionnaireCardComponent implements OnInit {
  @Input() isDateShown: boolean = false;
  public readonly QuestionnaireTypes = QuestionnaireTypes;

  public isOpen: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  public toggleExpander(): void {
    this.isOpen = !this.isOpen;
  }
}
