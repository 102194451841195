import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GroupsState } from './groups.reducer';

// Select the feature state
export const selectGroupsState = createFeatureSelector<GroupsState>('groups');

// Select the groups array from the feature state
export const selectGroups = createSelector(
  selectGroupsState,
  (state: GroupsState) => state.groups
);

// Select the currentGroupId from the feature state
export const selectCurrentGroup = createSelector(
  selectGroupsState,
  (state: GroupsState) => state.currentGroup
);

// Select a specific group by ID from the groups array
export const selectGroupById = createSelector(
  selectGroupsState,
  (state: GroupsState) => state.currentGroupId
);

// Example of a combined selector to get the current group with its details
export const selectCurrentGroupWithDetails = createSelector(
  selectCurrentGroup,
  (currentGroup) => {
    return currentGroup;
  }
);
