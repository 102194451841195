import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-video-player-dialog',
  templateUrl: './video-player-dialog.component.html',
  styleUrls: ['./video-player-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoPlayerDialogComponent {
  @Input() isOpen: boolean = false;
  @Input() videoUrl: string | undefined;

  @Output() close: EventEmitter<void> = new EventEmitter<void>();
}
