<div
  class="student-modal bg-white border"
  [ngClass]="{
    'student-modal_open' : isShowModal
  }"
>
  <button class="close-btn flex items-center justify-center"
  (click)="toggleShowModalStudent()"
  >
    <img src="./assets/svg/plus.svg" alt="" />
  </button>
  <div class="header flex items-center">
    <div class="avatar">
      <img src="./assets/img/catalogues-card-image.png" alt="" />
    </div>
    <div class="description flex flex-col">
      <div class="name">Арманов Арман</div>
      <div class="group-email flex items-center">
        <div class="group">Поток №1</div>
        <div class="circle"></div>
        <div class="email">armanov@mail.ru</div>
      </div>
    </div>
  </div>

  <div class="body">
    <app-student-modal-reply-student></app-student-modal-reply-student>
    <app-student-modal-reply-curator></app-student-modal-reply-curator>
  </div>

  <div class="reply">
    <textarea class="textarea radius-8"></textarea>
    <button class="send-button flex justify-center items-center">
      <img src="./assets/svg/plane-right.svg" alt="" />
    </button>
  </div>
</div>
