import { Injectable } from '@angular/core';
import { BaseRoutes, Roles } from 'src/app/core/model';

@Injectable({
  providedIn: 'root',
})
export class RouteHelperServiceService {
  constructor() {}

  // @ts-ignore
  getRouteBaseOnRole(role: Roles): BaseRoutes {
    switch (role) {
      case Roles.Student:
        return BaseRoutes.Student;
      case Roles.Methodist:
        return BaseRoutes.Methodist;
      case Roles.Curator:
        return BaseRoutes.Curator;
      case Roles.HeadTeacher:
        return BaseRoutes.HeadTeacher;
      case Roles.Teacher:
        return BaseRoutes.Teacher;
      case Roles.Admin:
        return BaseRoutes.Admin;
      case Roles.Manager:
        return BaseRoutes.Manager
      case Roles.InternalManager:
        return BaseRoutes.InternalManager;
    }
  }
}
