import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { VideoDTO } from 'src/app/shared/models';

enum VideoPlayers {
  Vimeo = 'vimeo',
  Iframe = 'iframe',
  Youtube = 'youtube',
  IframeVimeo = 'iframevimeo',
  Kinoscope = 'Kinoscope',
  IframeKinoscope = 'iframekinoscope'
}
@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoPlayerComponent implements OnChanges {
  @Input() videoTimings!: number[][] | null;
  @Input() videoUrl: string | undefined;
  @Input() aspectRation: number = 0.6;
  @Output() sendPlayed: EventEmitter<VideoDTO> = new EventEmitter<VideoDTO>();

  public VideoPlayers = VideoPlayers;
  public type: VideoPlayers | undefined;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['videoUrl'] && this.videoUrl) {
        if(this.videoUrl?.includes('</iframe>') && this.videoUrl?.includes('vimeo')) {
          this.type = VideoPlayers.IframeVimeo;
        }
        else if(this.videoUrl?.includes('</iframe>') && this.videoUrl?.includes('kinescope')) {
          this.type = VideoPlayers.IframeKinoscope;
        }
        else if(this.videoUrl?.includes('kinescope')) {
          this.type = VideoPlayers.Kinoscope;
        }
        else if(this.videoUrl?.includes('youtube')) {
          this.type = VideoPlayers.Youtube;
        }
        else if(this.videoUrl?.includes('</iframe>')) {
          this.type = VideoPlayers.Iframe;
        } 
        else if(this.videoUrl?.includes('vimeo')) {
          this.type = VideoPlayers.Vimeo;
        }
        else {
          console.log('Данный формат видео не поддерживаем')
        }
        this.cdr.detectChanges();
    }
  };
}
