import { ChangeDetectionStrategy, Component } from '@angular/core';
import {BaseRoutes, DocumentRoutes} from "../../../../core/model";

@Component({
  selector: 'app-catalogues-footer',
  templateUrl: './catalogues-footer.component.html',
  styleUrls: ['./catalogues-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CataloguesFooterComponent {
  public baseRoutes = BaseRoutes;
  public docxRoutes = DocumentRoutes;
}
