import {DateHelperServiceService} from 'src/app/shared/services/dateHelperService/date-helper-service.service';
import {CoursesService} from '../../../../core/services/courses/courses.service';
import {ThemeService} from 'src/app/core/services/theme/theme.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import {ThemeDTO} from 'src/app/core/model/interface/theme';
import {AdditionalMaterials, Roles} from 'src/app/core/model';
import {HelperService} from 'src/app/shared/services/helper/helper.service';
import {FilesService} from 'src/app/core/services/files/files.service';
import {environment} from 'src/environment';
import {AuthService} from "../../../../core/services/auth/auth.service";

interface Materials {
  id: string,
  link: string,
  active: boolean
};

@Component({
  selector: 'app-themes-list',
  templateUrl: './themes-list.component.html',
  styleUrls: ['./themes-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThemesListComponent implements OnChanges {
  @Input() courseId: string = "";
  @Input() list: ThemeDTO[] = [];
  @Input() addMaterials: AdditionalMaterials | undefined;
  public materials: Materials[] = [];
  @Output() openTheme: EventEmitter<string> = new EventEmitter<string>();
  @Output() createEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() openFolders: EventEmitter<void> = new EventEmitter<void>();

  public themeTitle: string = "";
  public isThemeCreateOpen: boolean = false;
  public themeData: ThemeDTO | undefined;
  public isAddMatExpanded: boolean = false;

  public isAdditionalMaterialsOpen: boolean = false;

  constructor(
    private themeService: ThemeService,
    private cdr: ChangeDetectorRef,
    private helperService: HelperService,
    private filesService: FilesService,
    private coursesService: CoursesService,
    private dateHelper: DateHelperServiceService,
    private authService: AuthService
  ){}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['addMaterials'] && this.addMaterials && this.addMaterials.additionalMaterials && this.addMaterials.additionalMaterials.length > 0) {
      this.materials = [];
      for(let id of this.addMaterials.additionalMaterials) {
        this.materials.push({
          id: id,
          link: environment.baseUrl + id,
          active: true,
        });
      }
      this.cdr.detectChanges();
    }
  }

  public openCreateTheme(): void {
    this.themeData = undefined;
    this.isThemeCreateOpen = true;
  };
  public openCreateThemeInEditMode(data: ThemeDTO): void {
    this.themeData = data;
    this.isThemeCreateOpen = true;
  }
  public closeCreateTheme(): void {
    this.isThemeCreateOpen = false;
  };

  public doOpenTheme(id: string, event: Event): void {
    const element = event.target as HTMLElement;
    if(element.className !== "icon") {
      this.openTheme.emit(id);
    }
  }


  public themeCreated(): void {
    this.createEvent.emit();
    this.isThemeCreateOpen = false;
  }

  public formatDate(inputDate: string) {
    const dateObj = this.dateHelper.convertUTCToLocal(new Date(inputDate));

    const day = dateObj.getDate().toString().padStart(2, '0');
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObj.getFullYear().toString();

    const hours = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');

    const formattedDate = `${day}.${month}.${year} в ${hours}:${minutes}`;

    return formattedDate;
  }

  public delete(id: string): void {
    this.themeService.deleteTheme(id).subscribe( res => {
      this.list = this.list.filter((item: ThemeDTO) => item.id !== id);
      this.cdr.detectChanges();
    });
  };
  public openFile(link: string): void {
    window.open(link, '_blank')
  }

  public toggleAddMat(): void {
    this.isAddMatExpanded = !this.isAddMatExpanded;
  }

  get isCanDelete(): boolean {
    return this.authService.getRole() !== Roles.HeadTeacher;
  }

  public openAddtionalMaterials(): void {
    this.isAdditionalMaterialsOpen = true;
  }
  public closeAdditionalMaterials(): void {
    location.reload();
    this.isAdditionalMaterialsOpen = false;
  }

  public deleteAdditionalMaterials(): void {
    const emptyData: AdditionalMaterials = {
      additionalMaterials: [],
      usefulLinks: []
    }
    this.coursesService.usefulinkandadditionalmaterial(this.courseId,emptyData).subscribe(res => {
      location.reload();
    })
  }
}
