<div class="app-methodist-sidebar">
    <app-sidebar>
        <h1 class="title">{{'SUBJECTS' | translate}}</h1>
        <ul class="list ">
            <li class="item" *ngFor="let subject of subjects">
                <button (click)="setActiveSubject(subject.value.toString())" class="button" [ngClass]="{'button-active': activeSubject?.value === subject.value}">
                    <div class="icon" [ngClass]="{'icon-active': activeSubject?.value === subject.value}"></div>
                    <span class="name">{{subject.name}}</span>
                </button>
            </li>
        </ul>
    </app-sidebar>
</div>
