<app-vimeo-player
    *ngIf="type === VideoPlayers.Vimeo"
    [videoTimings]="videoTimings"
    [videoUrl]="videoUrl"
    (sendPlayed)="sendPlayed.emit($event)">
</app-vimeo-player>

<app-iframe-kinoscope-player
    *ngIf="type === VideoPlayers.IframeKinoscope"
    [videoUrl]="videoUrl"
    (sendPlayed)="sendPlayed.emit($event)">

</app-iframe-kinoscope-player>

<app-kinoscope-player
    *ngIf="type === VideoPlayers.Kinoscope"
    [videoUrl]="videoUrl"
    [lessonId]="lessonId"
    (sendPlayed)="sendPlayed.emit($event)"
>

</app-kinoscope-player>

<app-iframe-player
    *ngIf="type === VideoPlayers.Iframe"
    [videoUrl]="videoUrl">
</app-iframe-player>

<app-youtube-player
    *ngIf="type === VideoPlayers.Youtube"
    [videoUrl]="videoUrl"
    [aspectRation]="aspectRation"
    (sendPlayed)="sendPlayed.emit($event)">
</app-youtube-player>

<app-iframe-vimeo-player
    *ngIf="type === VideoPlayers.IframeVimeo"
    [videoTimings]="videoTimings"
    [videoUrl]="videoUrl"
    (sendPlayed)="sendPlayed.emit($event)">
</app-iframe-vimeo-player>

<!-- <app-test-video-player></app-test-video-player> -->
