import { Pipe, PipeTransform } from '@angular/core';
import { Languages } from '../../models';

@Pipe({
  name: 'monthConverter',
})
export class MonthConverterPipe implements PipeTransform {
  transform(month: unknown, language: Languages = Languages.KZ, isShortName: boolean = false): string {
    switch (language) {
      case Languages.KZ:
        switch (month) {
          case 1:
            return 'Қаңтар';
          case 2:
            return 'Ақпан';
          case 3:
            return 'Наурыз';
          case 4:
            return 'Сәуір';
          case 5:
            return 'Мамыр';
          case 6:
            return 'Маусым';
          case 7:
            return 'Шілде';
          case 8:
            return 'Тамыз';
          case 9:
            return 'Қыркүйек';
          case 10:
            return 'Қазан';
          case 11:
            return 'Қараша';
          case 12:
            return 'Желтоқсан';
          default:
            return '';
        }
      case Languages.RU:
        switch (month) {
          case 1:
            return isShortName ? 'Янв' : 'Январь';
          case 2:
            return isShortName ? 'Февр' : 'Февраль';
          case 3:
            return 'Март';
          case 4:
            return isShortName ? 'Апр' : 'Апрель';
          case 5:
            return 'Май';
          case 6:
            return 'Июнь';
          case 7:
            return 'Июль';
          case 8:
            return isShortName ? 'Авг' : 'Август';
          case 9:
            return isShortName ? 'Сен' : 'Сентябрь';
          case 10:
            return isShortName ? 'Окт' : 'Октябрь';
          case 11:
            return isShortName ? 'Нояб' : 'Ноябрь';
          case 12:
            return isShortName ? 'Дек' : 'Декабрь';
          default:
            return '';
        }
      default:
        return '';
    }
  }
}
