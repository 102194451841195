
<div class="app-main-page">

  <div class="sidebar desktop" *ngIf="isSideBarShown">
    <ng-content select="[sidebar]"></ng-content>
  </div>
  <div class="content" [ngClass]="{wide: !isSideBarShown}">
    <app-header />
    <main class="main" [ngClass]="{'main-no-padding': isPaddingHidden}">
      <router-outlet></router-outlet>
    </main>
  </div>
  <button class="connection">
    <ng-content select="[button]"></ng-content>
  </button>
</div>
