import { Component, Input, OnInit } from '@angular/core';
import { QuestionnaireTypes } from 'src/app/core/model/interface/questionnaire';

@Component({
  selector: 'app-questionnaire-view-controller',
  templateUrl: './questionnaire-view-controller.component.html',
  styleUrls: ['./questionnaire-view-controller.component.scss']
})
export class QuestionnaireViewControllerComponent implements OnInit {
  public readonly QuestionnaireTypes = QuestionnaireTypes;
  @Input() index: number = 0;
  @Input() type: QuestionnaireTypes = QuestionnaireTypes.YerOrNo;
  constructor() { }

  ngOnInit() {
  }

}
