<ng-container *ngIf="form">
  <form class="add-course" [formGroup]="form">
    <app-select
      [options]="subjectOptions"
      (selectedValue)="onSelectSubject($event)"
      name="Предмет"
      formControlName="subject"
    ></app-select>

    <app-select
      [options]="coursesOptions"
      (selectedValue)="onSelectCourse($event)"
      name="Курс"
      formControlName="course"
      [isLoading]="isCourseOptionsIsLoading"
    ></app-select>

    <app-select
      [options]="groupsOptions"
      (selectedValue)="onSelectGroup($event)"
      name="Группы"
      formControlName="group"
      [isLoading]="isGroupOptionsIsLoading"
    ></app-select>

    <app-select
      [options]="periodOptions"
      (selectedValue)="onSelectPeriod($event)"
      name="Периоды"
      formControlName="group"
      [isLoading]="isPeriodOptionsIsLoading"
    ></app-select>

    <app-select
      [options]="managerOptions"
      (selectedValue)="onSelectManager($event)"
      name="Ваш менеджер"
      formControlName="managerId"
      [isLoading]="isManagerOptionsLoading"
      placeholder="Выберите менеджера"
    ></app-select>

    <div class="btns flex justify-between">
      <button class="btn btn-cancel radius-8" (click)="cancel()">
        Отменить
      </button>
      <app-button
        (onButtonClick)="addStudentToCourse()"
        [fullWidth]="true"
        class="btn"
        text="Создать"
        [isDisabled]="!periodIds || isAddingStudentToCourse"
      ></app-button>
    </div>
  </form>
</ng-container>
