<div class="active-course">
  <h1 class="active-course_title">{{'activeCourses' | translate}}</h1>

  <div class="active-course_content flex flex-col">
    <ng-container *ngFor="let course of activeCourses; last as last">
      <div class="active-course_content-item flex">
        <div class="content-img">
          <img [src]="subjectsId[course.subjectId || '']" alt="subjectId">
        </div>

        <div class="content-body flex flex-col"
             [class.justify-center]="!(course.prolongDateFrom && course.prolongDateTo)">
          <div class="content-body_title">
            {{course.courseName}}
          </div>

          <div *ngIf="course.prolongDateFrom && course.prolongDateTo"
               [ngSwitch]="isCurrentDateBetweenDates(course.prolongDateFrom, course.prolongDateTo)"
               class="content-body_payment flex flex-col">
            <ng-container *ngSwitchCase="true">
              <div class="payment-title">
                {{'PAYMENT_REQUIRED' | translate}}
              </div>
              <div class="payment-info flex">
                <img src="./assets/svg/timer-pause.svg" alt="">
                {{'DAYS_LEFT' | translate: {days: course.prolongDateTo | dateFormat: 'daysLeft'} }}
              </div>
            </ng-container>
            <div *ngSwitchDefault class="payment-subtitle flex">
              {{'PAYMENT_DATE' | translate: {
                from: course.prolongDateFrom | date: 'dd.MM.YYYY',
                to: course.prolongDateTo | date: 'dd.MM.YYYY'
              } }}
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!(last && !getCoursesNeedPayment.length)" class="divider"></div>
    </ng-container>

    <app-button *ngIf="getCoursesNeedPayment.length" text="goToPayment" (onButtonClick)="navigateToCatalogues()"></app-button>
  </div>
</div>
