<div class="form-field">
  <label *ngIf="name"
         [ngClass]="{ required: required }"
         class="label">
    {{name | translate}}
  </label>

  <ng-container *ngIf="isLoading">
    <app-control-skeleton></app-control-skeleton>
  </ng-container>
  <ng-container *ngIf="!isLoading">
    <div
      class="input"
      [ngClass]="{
        unactive: !isActive,
        outline: borderType === borderTypeEnum.Outline,
        flat: borderType === borderTypeEnum.Flat,
        'input-focused': focused,
        'input-error': error
      }"
    >
      <ng-content></ng-content>
    </div>
  </ng-container>
  <span *ngIf="error" class="error-text">{{ error | translate}}</span>
  <!-- <div class="error" *ngIf="control && control['errors']">
    <app-control-error-message [control]="control"></app-control-error-message>
  </div> -->
</div>
