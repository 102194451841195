<ng-container *ngIf="selectedUser">
  <div class="admin-modal p-24 border">
    <button
      class="close-btn flex items-center justify-center"
      (click)="cancel()"
    >
      <img src="./assets/svg/plus.svg" alt="" />
    </button>

    <div class="wrapper">
      <ng-container *ngIf="isDataLoading">
        <app-user-card-skeleton></app-user-card-skeleton>
      </ng-container>
      <ng-container *ngIf="!isDataLoading">
        <div class="header flex items-center">
          <app-avatar
            class="avatar"
            [firstLetter]="selectedUser?.firstname[0]"
            [imageUrl]="selectedUser?.profilePhotoUrl"
          ></app-avatar>
          <div class="info flex flex-col">
            <div class="user-name">
              {{ selectedUser?.firstname }} {{ selectedUser?.lastname }}
            </div>
            <div class="user-email text-md">{{ selectedUser?.username }}</div>
          </div>
        </div>
        <app-block-role-phone-insta-info
          [role]="selectedUser?.roles[0]"
          [phone]="selectedUser?.phoneNumber"
          [instagram]="selectedUser?.instagramLink"
        ></app-block-role-phone-insta-info>
      </ng-container>
    </div>

    <!-- <div class="body">
      <ng-container *ngIf="selectedUser?.roles[0] === 'STUDENT'">
        <app-student-courses
          [studentId]="selectedUser.id"
        ></app-student-courses>
      </ng-container>
    </div> -->
  </div>
</ng-container>
