import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dicount-user-info-editor',
  templateUrl: './dicount-user-info-editor.component.html',
  styleUrls: ['./dicount-user-info-editor.component.scss']
})
export class DicountUserInfoEditorComponent implements OnInit {
  @Input() isOpen: boolean = false;
  @Output() confirmEvent = new EventEmitter<void>();
  @Output() closeEvent = new EventEmitter<void>();
  
  constructor() {}

  ngOnInit() {}

}
