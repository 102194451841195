import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Languages } from '../../models';

@Pipe({
  name: 'letterAtPosition',
  pure: true,
})
export class LetterAtPositionPipe implements PipeTransform {

  constructor(private translate: TranslateService) {}

  transform(position: number): string {
    let codePoint: number;
    const currentLang = this.translate.currentLang;

    if (currentLang === Languages.KZ) {
      codePoint = 1040 + position;
    } else if (currentLang === Languages.KZ) {
      codePoint = 1040 + position;
    } else {
      return ''; // Invalid language or language not set
    }

    return String.fromCodePoint(codePoint);
  }

}
