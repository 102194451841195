<app-form-field
  [name]="name"
  [focused]="focused"
  [isActive]="isActive"
  [error]="error"
  [control]="control"
>
  <div class="select-input">
    <div class="input-component">
      <input
        #inputField
        [placeholder]="placeholder | translate"
        class="iconic-input"
        (focus)="onFocus()"
        (blur)="onBlur()"
        [prefix]="prefix"
        [mask]="mask"
        [formControl]="ngControl.control"
        (input)="handleInputChange()"
      />
      <div (click)="clearInput()" class="delete-button">
        <img
          [hidden]="!focused"
          src="assets/svg/circles/close-circle.svg"
          alt=""
        />
      </div>
    </div>
    <div *ngIf="isSelectShown" class="button-list">
      <button
        (click)="onSelect(option.value)"
        class="option"
        *ngFor="let option of options"
        [value]="option.value"
      >
        {{ option.name }}
      </button>
    </div>
  </div>
</app-form-field>
