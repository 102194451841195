import { createAction, props } from "@ngrx/store";
import { IPayment, MethodistUser, StudentUser } from "src/app/core/model";

export const resetUser = createAction('[User] Reset');

export const getProfileInfo = createAction('[User API] Fetch Profile Info');
export const getProfileInfoSuccess = createAction('[User API] Fetch Profile Info Success', props<{ payload: StudentUser }>());

export const updateUserProfileInfo = createAction('[User API] Update Profile Info',props<{ payload: StudentUser | MethodistUser }>());
export const updateUserProfileInfoSuccess = createAction('[User API] Update Profile Info Success',props<{ payload: StudentUser | MethodistUser }>());

export const getUserPaymentHistoryAction = createAction('[Payment API] Fetch User Payment History');
export const getUserPaymentHistorySuccessAction = createAction('[Payment API] Fetch User Payment History Success', props<{ payload: IPayment }>());