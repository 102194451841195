import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phone', pure: false })
export class PhonePipe implements PipeTransform {
  constructor() {}

  transform(value: string | undefined | null, args?: any): any {
    if (!value) return '—';
    return `${value.substring(0, 2)} (${value.substring(2, 5)}) ${value.substring(5)}`;
  }
}
