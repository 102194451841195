import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private _isShowRightModal$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  constructor() {}

  public getIsShowRightMenu(): Observable<boolean> {
    return this._isShowRightModal$;
  }

  public setIsShowRightMenu(state: boolean): void {
    this._isShowRightModal$.next(state);
  }

  public toggleIsShowRightMenu(): void {
    this._isShowRightModal$.next(!this._isShowRightModal$.getValue());
  }
}
