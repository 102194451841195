import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MethodistUser, Roles, StudentUser } from 'src/app/core/model';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ChoosingSubjects,EducationClasses,educationClassesSelectValues, SelectValue } from 'src/app/shared/models';
import { getFormControlErrors } from 'src/app/shared/validators';
import { SubjectsService } from './../../../../core/services/subjects/subjects.service';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from './../../../services/helper/helper.service';
import { UsersService } from 'src/app/core/services/users/users.service';
import { AdminService } from 'src/app/core/services/admin/admin.service';
import { Store } from '@ngrx/store';
import { updateUserProfileInfo } from 'src/app/core/state/student/user/user.actions';
import { HeadTeacherService } from './../../../../core/services/head-teacher/head-teacher.service';

@Component({
  selector: 'app-personal-info-editor',
  templateUrl: './personal-info-editor.component.html',
  styleUrls: ['./personal-info-editor.component.scss']
})
export class PersonalInfoEditorComponent implements OnInit {
  @Input() data!: StudentUser | MethodistUser;
  @Input() isOpen: boolean = false;
  @Input() userId: string = "";
  @Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmEvent: EventEmitter<StudentUser | MethodistUser> = new EventEmitter<StudentUser | MethodistUser>();

  @Input() notRegisteredUser: boolean = false;

  public readonly Roles = Roles;
  @Input() role: Roles = Roles.Methodist;
  public userForm!: FormGroup;

  public youRole: Roles = Roles.Student;

  public firstSubjects: SelectValue[] = [];
  public secondSubjects: SelectValue[] = [];
  public educationClasses: SelectValue[] = educationClassesSelectValues;


  public userFormGroupError = {
    firstname: '',
    lastname: '',
    middlename: '',
    parentPhoneNumber: '',
    grade: '',
    firstSubjectId: '',
    secondSubjectId: '',
    instagramLink: '',
    middleName: '',
    email: '',
    phoneNumber: '',
    regionId: ''
  };

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private subjectsService: SubjectsService,
    private translateService: TranslateService,
    private helperService: HelperService,
    private usersService: UsersService,
    private adminService: AdminService,
    private store: Store,
    private headTeacherService: HeadTeacherService
  ) {

  }

  ngOnInit() {
    this.youRole = this.authService.getRole();

    switch (this.role) {
    case Roles.Student:
      this.userForm = this.formBuilder.group({
        id: ['', Validators.required],
        username: ['', Validators.required],
        firstname: ['', Validators.required],
        lastname: ['', Validators.required],
        middlename: [''],
        parentPhoneNumber: ['', Validators.required],
        grade: ['', Validators.required],
        firstSubjectId: ['', Validators.required],
        secondSubjectId: ['', Validators.required],
        instagramLink: ['', Validators.required],
        regionId: ['', Validators.required]
      });
      if(this.notRegisteredUser) {
        this.userForm.removeControl('id');
      }
      break;
    default:
      this.userForm = this.formBuilder.group({
        id: ['', Validators.required],
        username: ['', Validators.required],
        firstname: ['', Validators.required],
        lastname: ['', Validators.required],
        middlename: [''],
        instagramLink: ['', Validators.required],
      });
    };

    if(!this.notRegisteredUser) {
      this.userForm.get('id')?.setValue(this.data.id);
    }
    this.userForm.get('firstname')?.setValue(this.data.firstname);
    this.userForm.get('lastname')?.setValue(this.data.lastname);
    this.userForm.get('middlename')?.setValue(this.data.middlename);
    this.userForm.get('instagramLink')?.setValue(this.data.instagramLink);
    this.userForm.get('username')?.setValue(this.data.username);

    if (this.role === Roles.Student) {
      this.userForm
        .get('parentPhoneNumber')
        ?.setValue((this.data as StudentUser).parentPhoneNumber?.substring(2));
      this.userForm.get('grade')?.setValue((this.data as StudentUser).grade);
      this.userForm
        .get('firstSubjectId')
        ?.setValue((this.data as StudentUser).firstSubjectId);
      this.userForm
        .get('secondSubjectId')
        ?.setValue((this.data as StudentUser).secondSubjectId);

      this.userForm
        .get('regionId')
        ?.setValue((this.data as StudentUser).regionId);

      this.onFirstSubjectChoosed((this.data as StudentUser).firstSubjectId);
    }

    if(this.youRole === Roles.Admin) {
      this.userForm.setControl('email', new FormControl('', [Validators.required, Validators.email]));
      this.userForm.setControl('phoneNumber', new FormControl('', [Validators.required]));
      this.userForm.patchValue({email: this.data.username});
      this.userForm.patchValue({phoneNumber: this.data?.phoneNumber?.substring(2)});
    }

    this.firstSubjects = this.headTeacherService._getSubjects();

    this.userForm.valueChanges.subscribe((res) => {
      console.log(this.userForm);
    })
  }

  handleFormControlErrors(
    form: FormGroup,
    error: any,
    controlName: string
  ): void {
    getFormControlErrors(form, error, controlName, this.translateService);
    this.cdr.detectChanges();
  }
  onFirstSubjectChoosed(value: number | string): void {
    this.userForm.get('firstSubjectId')?.setValue(value);
    this.secondSubjects = this.subjectsService.getAvailableSubjects(
      value as ChoosingSubjects
    );
  }
  onSecondSubjectChoosed(value: any) {
    this.userForm.get('secondSubjectId')?.setValue(value);
  }

  public editProfile(): void {
    let editedProfileData = this.userForm.value;
    editedProfileData.parentPhoneNumber = this.helperService.convertPhoneNumberForSave(editedProfileData.parentPhoneNumber);
    editedProfileData.profilePhotoUrl = this.data.profilePhotoUrl;
    console.log(editedProfileData);
    if(this.authService.getRole() === Roles.Admin) {

      editedProfileData.phoneNumber = this.helperService.convertPhoneNumberForSave(editedProfileData.phoneNumber);
      editedProfileData.username = this.userForm.get('email')?.value;

      this.adminService.editProfileInfoOfUser(this.data.id!, editedProfileData).subscribe({
        next: (res) => {
          this.confirmEvent.emit(res)
          this.cdr.detectChanges();
        },
        error: (err) => {
          console.log(err);
        }
      })
    }
    else if(this.authService.getRole() === Roles.Student) {
      this.store.dispatch(updateUserProfileInfo({payload: editedProfileData}));
      this.closeEvent.emit();
    }
    else {
      this.usersService.updateUserProfile(editedProfileData).subscribe({
        next: (res) => {
          location.reload();
          this.cdr.detectChanges();
        },
        error(err) {
          console.log(err);
        },
      });
    }
  }

  phoneTyping(form: FormGroup, error: any, controlName: string): void {
    if(this.userForm.get('phoneNumber')?.status === 'VALID') {
      this.usersService.checkNumberExist(this.userForm.get('phoneNumber')?.value).subscribe({
        next: (res) => {
          console.log(this.userForm.get('phoneNumber')?.value)
          if(!res || (('+7' + this.userForm.get('phoneNumber')?.value) === this.data.phoneNumber)) {
            this.userFormGroupError.phoneNumber = "";
            this.userForm.get('phoneNumber')?.setErrors(null);
          }
          else {
            this.userFormGroupError.phoneNumber = 'Пользователь с таким номером телефона существует, попробуйте другой';
            this.userForm.get('phoneNumber')?.setErrors({ customError: true })
          }
          this.cdr.detectChanges();
        },
        error: (error) => {
          console.log(error);
          this.cdr.detectChanges();
        }
      });
    };
  }

  emailTyping(form: FormGroup, error: any, controlName: string): void {
    if(this.userForm.get('email')?.status === 'VALID') {
      this.usersService.checkUsernameExist(this.userForm.get('email')?.value).subscribe({
        next: (res) => {
          if(!res || (this.userForm.get('email')?.value === this.data.email)) {
            this.userFormGroupError.email = "";
            this.userForm.get('email')?.setErrors(null);
          }
          else {
            this.userFormGroupError.email = 'Пользователь с такой почтой существует, попробуйте другой';
            this.userForm.get('email')?.setErrors({ customError: true })
          }
          this.cdr.detectChanges();
        },
        error: (error) => {
          console.log(error);
          this.cdr.detectChanges();
        }
    });
    }
  }
}
