import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import {
  NoopValueAccessorDirectiveDirective,
  injectNgControl,
} from 'src/app/shared/directives/noop-value-accessor-directive/noop-value-accessor-directive.directive';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  hostDirectives: [NoopValueAccessorDirectiveDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent {
  ngControl = injectNgControl();

  @Input() name: string = '';
  @Input() isActive: boolean = true;
  @Input() prefix: string = '';
  @Input() suffix: string = '';
  @Input() mask: string | null | undefined;
  @Input() pattern: any;
  @Input() error: string = '';
  @Input() control: AbstractControl | undefined = undefined;
  @Input() placeholder: string = '';
  @Input() dropSpecialCharacters: boolean = true;
  @Input() readOnly: boolean = false;
  @Input() showSubmitButton: boolean = false;

  @Output() submitEvent: EventEmitter<string> = new EventEmitter<string>();

  public focused: boolean = false;

  @ViewChild('inputField', { static: true })
  inputField!: ElementRef<HTMLInputElement>;

  onFocus(): void {
    this.focused = true;
  }
  onBlur(): void {
    this.focused = false;
  }

  keypressHandler(e: KeyboardEvent): void {
    if (this.showSubmitButton && e.code === 'Enter') {
      this.submit(e);
    }
  }

  clearInput(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.inputField.nativeElement.value = '';
    this.inputField.nativeElement.focus();
  }

  submit(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    if (this.inputField.nativeElement.value) {
      this.submitEvent.emit(this.inputField.nativeElement.value);
      this.inputField.nativeElement.value = '';
    }

    this.inputField.nativeElement.focus();
  }
}
