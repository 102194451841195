import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import {
  NoopValueAccessorDirectiveDirective,
  injectNgControl,
} from 'src/app/shared/directives/noop-value-accessor-directive/noop-value-accessor-directive.directive';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  hostDirectives: [NoopValueAccessorDirectiveDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent {
  ngControl = injectNgControl();

  @Input() name: string = '';
  @Input() isActive: boolean = true;
  @Input() prefix: string = '';
  @Input() suffix: string = '';
  @Input() mask: string | null | undefined;
  @Input() pattern: any;
  @Input() error: string = '';
  @Input() control: AbstractControl | undefined = undefined;
  @Input() placeholder: string = '';
  @Input() dropSpecialCharacters: boolean = true;

  public focused: boolean = false;

  @ViewChild('inputField', { static: true })
  inputField!: ElementRef<HTMLInputElement>;

  onFocus(): void {
    this.focused = true;
  }
  onBlur(): void {
    this.focused = false;
  }

  clearInput(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.inputField.nativeElement.value = '';
    this.inputField.nativeElement.focus();
  }
}
