import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeWordsQuestion',
  pure: true
})
export class TypeWordsQuestionPipe implements PipeTransform {

  transform(correctAnswers: string[], userAnswer: string): boolean {
    const normalizedCorrectAnswers: string[] = correctAnswers.map(item => item.toLowerCase().trim());
    return normalizedCorrectAnswers.includes(userAnswer.toLowerCase().trim()) ? true : false;
  }

}
