import { DateHelperServiceService } from './../../../../shared/services/dateHelperService/date-helper-service.service';
import { CoursesService } from './../../../../core/services/courses/courses.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoursesDTO } from 'src/app/core/model/interface/courses';
import { SelectValue } from 'src/app/shared/models';
import { SideBarService } from '../../services/side-bar.service';

@Component({
  selector: 'app-add-new-course',
  templateUrl: './add-new-course.component.html',
  styleUrls: ['./add-new-course.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddNewCourseComponent implements OnInit, OnChanges{
  @Input() data: CoursesDTO | undefined;
  @Input() isOpen: boolean = true;
  @Input() teachers: SelectValue[] | undefined;
  @Output() closeEvent = new EventEmitter<void>();
  @Output() saveEvent = new EventEmitter<void>();

  public years: SelectValue[] = [];
  public months: SelectValue[] = [];

  public form!: FormGroup;

  public subjectId: string = "";
  constructor(
    private _formBuilder: FormBuilder,
    private coursesService: CoursesService,
    private dateHelperServiceService: DateHelperServiceService,
    private sideBarService: SideBarService) 
  {
    this.form = this._formBuilder.group({
      name: ['', Validators.required],
      teachers: ['', Validators.required],
      year: [''],
      month: ['']
    });
    this.months = dateHelperServiceService.getMonths();
    this.years = dateHelperServiceService.getYears();
  }

  ngOnInit(): void {
    this.sideBarService.getIdSubject().subscribe(res => {
      this.subjectId = res.value.toString();
    })
  }
  ngOnChanges(changes: SimpleChanges): void {  
    this.setForm();
  };

  private setForm(): void {
    if(this.data) {
      this.form.patchValue({
        name: this.data.name,
        teachers: this.data.teacherId,
        year: this.data.year,
        month: this.data.month
      });
    } else { 
      this.form.patchValue({
        name: '',
        teachers: '',
        year: '',
        month: ''
      });
    }
  } 
  public closeDialog(): void {
    this.closeEvent.emit();
  }
  public save(): void {
    const courseData = {      
      name: this.form.get("name")?.value,
      subjectId: this.subjectId,
      teacherId: this.form.get("teachers")?.value,
      year: this.form.get("year")?.value,
      month: this.form.get("month")?.value,
      active: this.data?.active ? this.data.active : true,
    };
  
    const saveObservable = this.data?.id
      ? this.coursesService.updateCourseById(this.data.id, courseData)
      : this.coursesService.createCourse(courseData);
  
    saveObservable.subscribe(() => {
      this.saveEvent.emit();
    });
  }
  
}
