import { DateHelperServiceService } from './../../../../shared/services/dateHelperService/date-helper-service.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs';
import { HeadTeacherService } from 'src/app/core/services/head-teacher/head-teacher.service';
import { ToastService } from 'src/app/shared/services/toast/toast.service';

interface PaymentDates {
  id: string;
  paymentAccessibleFrom: string | Date;
  paymentAccessibleTo: string;
  isEdit?: boolean;
}

@Component({
  selector: 'app-prolongate-date',
  templateUrl: './prolongate-date.component.html',
  styleUrls: ['./prolongate-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProlongateDateComponent implements OnInit {
  @Input() streamId!: string;

  public form: FormGroup | null = null;
  public paymentDateForm!: FormGroup;
  public fromMinDate: Date | string | null = new Date();
  public toMinDate: Date | string | null = new Date();
  isEditFinishDate: boolean = false;

  public paymentDates: PaymentDates[] = [];

  constructor(
    private _headteacherService: HeadTeacherService,
    private _cdr: ChangeDetectorRef,
    private _ts: ToastService,
    private dateHelper: DateHelperServiceService
  ) {}

  ngOnInit(): void {
    this.getPaymentDates();
    this._createFinishDateForm();
  }

  private getPaymentDates(): void {
    this._headteacherService
      .getStreamById(this.streamId)
      .pipe(map((item) => item.streamPaymentDates))
      .subscribe({
        next: (response) => {
          this.paymentDates = response;

          this.paymentDates.sort(
            (a: any, b: any) =>
              new Date(a.paymentAccessibleFrom).getTime() -
              new Date(b.paymentAccessibleFrom).getTime()
          );

          this.paymentDates = this.paymentDates.map(item => {
            return {
              id: item.id,
              paymentAccessibleFrom: this.dateHelper.convertUTCToLocal(new Date(item.paymentAccessibleFrom)).toString(),
              paymentAccessibleTo: this.dateHelper.convertUTCToLocal(new Date(item.paymentAccessibleTo)).toString()
            }
          })
          this._cdr.detectChanges();
        },
      });
  }

  public removeMonth(date: any): void {
    if (this.isEditFinishDate) {
      this.isEditFinishDate = false;
      date.isEdit = false;
      this.paymentDateForm.reset();
    } else {
      this._headteacherService
        .removePaymentDateOnStream(this.streamId, date.id)
        .subscribe({
          next: _ => {
            this._ts.showError('Удалено');
            this.getPaymentDates();
          },
        });
    }
  }

  public isValidForm(): boolean {
    return !!this.form && this.form.valid || (!!this.paymentDateForm && this.paymentDateForm.valid);
  }

  public canAdd(): boolean {
    return !this.form && !this.isEditFinishDate;
  }

  public onSubmitProlongate(): void {
    if (this.isEditFinishDate) {
      this.onSubmitFinishDate();
      return;
    }

    if (this.form?.invalid) {
      this.form.markAllAsTouched();
      this._ts.showError('Заполните поля корректно');
      return;
    }

    const draftDate = this.form!.value;

    const hours1 = draftDate.paymentAccessibleFromTime.slice(0, 2);
    const minutes1 = draftDate.paymentAccessibleFromTime.slice(-2);
    const date1 = new Date(draftDate.paymentAccessibleFromDate);

    const utcDate1 = new Date(
      date1.getUTCFullYear(),
      date1.getUTCMonth(),
      date1.getUTCDate(),
      hours1,
      minutes1,
    );

    const hours2 = draftDate.paymentAccessibleToTime.slice(0, 2);
    const minutes2 = draftDate.paymentAccessibleToTime.slice(-2);
    const date2 = new Date(draftDate.paymentAccessibleToDate);

    const utcDate2 = new Date(
      date2.getUTCFullYear(),
      date2.getUTCMonth(),
      date2.getUTCDate(),
      hours2,
      minutes2,
    );

    const dateData = {
      paymentAccessibleFrom: utcDate1,
      paymentAccessibleTo: utcDate2
    };

    this._headteacherService
      .updatePaymentsDates(dateData, this.streamId)
      .subscribe({
        next: (response) => {
          this.paymentDates = [
            ...this.paymentDates,
            response.streamPaymentDates,
          ];
          this.getPaymentDates();
          this._ts.showSuccess('Добавлено');
          this.form = null;
        },
        error: _ => {
          this._ts.showError('Произошла ошибка! Проверьте корректность заполненных данных')
        }
      });
  }

  private onSubmitFinishDate() {
    if (this.paymentDateForm?.invalid) {
      this.paymentDateForm.markAllAsTouched();
      this._ts.showError('Заполните поля корректно');
      return;
    }
    const formValue = this.paymentDateForm.getRawValue();

    const hours = formValue.paymentAccessibleToTime.slice(0, 2);
    const minutes = formValue.paymentAccessibleToTime.slice(-2);
    const date = new Date(formValue.paymentAccessibleToDate);

    const utcDate = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      hours,
      minutes,
    );

    this._headteacherService
      .updatePaymentsToDate({paymentAccessibleTo: utcDate}, this.streamId, formValue.id)
      .subscribe({
        next: _ => {
          const index = this.paymentDates.findIndex(x => x.id === formValue.id);
          if (index > -1) {
            this.paymentDates[index].paymentAccessibleFrom = utcDate;
            this.paymentDates[index].isEdit = false;
          }
          this.getPaymentDates();
          this.isEditFinishDate = false;
          this.paymentDateForm.reset();
          this._ts.showSuccess('Изменено');
        },
        error: _ => {
          this._ts.showError('Произошла ошибка! Проверьте корректность заполненных данных');
        }
      });
  }

  public addMonth(): void {
    this._createForm();
  }

  public removeForm(): void {
    this.form = null;
  }

  onEditDate(payment: PaymentDates) {
    this.isEditFinishDate = !this.isEditFinishDate;
    payment.isEdit = !payment.isEdit;

    const hours = new Date(payment.paymentAccessibleTo).getHours();
    const hh = hours < 10 ? `0${hours}` : hours;
    const minutes = new Date(payment.paymentAccessibleTo).getMinutes();
    const mm = minutes < 10 ? `0${minutes}` : minutes;

    this.paymentDateForm.patchValue({
      id: payment.id,
      paymentAccessibleToDate: this.formatDate(payment.paymentAccessibleTo),
      paymentAccessibleToTime: `${hh}${mm}`
    });
  }

  private formatDate(date: string) {
    const year = new Date(date).getFullYear();
    const month = new Date(date).getMonth() + 1;
    const mm = month < 10 ? `0${month}` : month;
    const day = new Date(date).getDate();
    const dd = day < 10 ? `0${day}` : day;

    return `${year}-${mm}-${dd}`;
  }

  private _createFinishDateForm() {
    this.paymentDateForm = new FormGroup({
      id: new FormControl('', [Validators.required]),
      paymentAccessibleToDate: new FormControl('', [Validators.required]),
      paymentAccessibleToTime: new FormControl('', [Validators.required])
    });
  }

  private _createForm(): void {
    const fg = new FormGroup({
      paymentAccessibleFromDate: new FormControl('', [Validators.required]),
      paymentAccessibleFromTime: new FormControl('', [Validators.required]),
      paymentAccessibleToDate: new FormControl('', [Validators.required]),
      paymentAccessibleToTime: new FormControl('', [Validators.required]),
    });

    fg.get('paymentAccessibleFromDate')!.valueChanges.subscribe(
      (fromMinDate) => {
        this.toMinDate = fromMinDate;
      }
    );
    this.form = fg;
  }
}
