<div class="stream-card border bg-white radius-16">
    <div class="header flex flex-col">
      <div class="title">
        <div class="title__text skeleton"></div>
        <img class="icon" src="assets/svg/triple-dot.svg" alt="" />
      </div>
      <div class="description skeleton"></div>
    </div>
  <div class="footer flex justify-between">
    <div class="footer_year skeleton"></div>
    <div class="footer_switcher skeleton"></div>
  </div>
</div>
