import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import VimeoPlayer from '@vimeo/player';
import { VideoDTO } from 'src/app/shared/models';

@Component({
  selector: 'app-iframe-vimeo-player',
  templateUrl: './iframe-vimeo-player.component.html',
  styleUrls: ['./iframe-vimeo-player.component.scss']
})
export class IframeVimeoPlayerComponent implements OnInit, AfterViewInit {
  @ViewChild('#iframe') iframeRef: ElementRef | undefined;
  
  @Input() videoTimings!: number[][] | null;
  @Input() videoUrl: string | undefined;
  @Output() sendPlayed: EventEmitter<VideoDTO> = new EventEmitter<VideoDTO>();

  public iFrame: SafeHtml | undefined;

  constructor(
    private sanitizer: DomSanitizer,
  ) 
    {}

  ngOnInit() {
    if(this.videoUrl) {
      const updatedUrl = this.videoUrl.replace(/width="\d+"/, 'width="100%"').replace(/height="\d+"/, 'height="100%"');
      this.iFrame = this.sanitizer.bypassSecurityTrustHtml(updatedUrl);
    }
  }
  ngAfterViewInit(): void {
    const iframe = document.querySelector('iframe');
    if(iframe) {
      const player = new VimeoPlayer(iframe)

      player?.on('play', () => {
  
        player?.getDuration().then(videoDuration => {
          setInterval(() => {
            player?.getPlayed().then((watchedTimePeriods: number[][]) => {
              console.log(watchedTimePeriods)

              let totalTimings;
              const roundedeWatchedTimePeriods: number[][] = this.roundNumbers(watchedTimePeriods);

              if(this.videoTimings) {
                totalTimings = this.mergeTimings(this.videoTimings, roundedeWatchedTimePeriods);
              } 
              else {
                totalTimings = roundedeWatchedTimePeriods;
              }
              
              let totalWatchedTime = this.countWatchedTime(totalTimings);
              const played = totalWatchedTime/videoDuration;
              
              this.sendPlayed.emit({
                duration: videoDuration,
                timings: totalTimings,
                percent: played
              });
            });
          }, 2000)
        });
      });
    }
  }
  private mergeTimings(beforeTimings: number[][], currentTimings: number[][]): number[][] {
    const allIntervals = beforeTimings.concat(currentTimings);
    if (allIntervals.length <= 1) {
      return allIntervals; // No need to merge if there's only one or zero intervals
    }

    // Sort the intervals based on their duration (ascending order)
    allIntervals.sort((a, b) => (a[1] - a[0]) - (b[1] - b[0]));

    const mergedIntervals: number[][] = [];
    
    for (let i = 0; i < allIntervals.length; i++) {
      let isMerged = false;
      for(let j = 0; j < allIntervals.length; j++) {
        if(allIntervals[i][1] > allIntervals[j][0] && allIntervals[i][0] < allIntervals[j][0]) {
          isMerged = true;
          mergedIntervals.push([Math.min(allIntervals[i][0], allIntervals[j][0]), Math.max(allIntervals[i][1], allIntervals[j][1])]);
        }
      }

      if(!isMerged) {
        mergedIntervals.push(allIntervals[i]);
      }
    }
    return mergedIntervals;
  };

  private roundNumbers(array: number[][]): number[][] {
    return array.map(subArray => subArray.map(number => Math.round(number)));
  }

  private countWatchedTime(time: number[][]): number {
    return time.map(item => item[1] - item[0]).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }
}

