import { createAction, props } from '@ngrx/store';
import { LessonWithProgress, ProgressDTO, QuizFinishDTO, QuizState, TestAnswerDTO, UserAnswer } from 'src/app/core/model';

export const resetProgress = createAction('[Progress] Reset');

// Action to initiate fetching progress
export const getProgressAction = createAction('[Progress API] Fetch Progress',props<{ groupId: string }>());

// Action dispatched on successful progress retrieval
export const getProgressActionSuccess = createAction('[Progress API] Fetch Progress Success',props<{ progressList: ProgressDTO[] }>());

// Action dispatched on failure during progress retrieval
export const getProgressActionFailure = createAction('[Progress API] Fetch Progress Failure',props<{ error: any }>());

export const answerQuestion = createAction('[Question] Answer', props<{ progressId: string, questionId: string, answer: UserAnswer[] | null}>());

export const checkAllQuestionAnswered = createAction('[Check] Quiz', props<{ progressId: string }>());

export const startQuiz = createAction('[Start] Quiz', props<{groupId: string, lessonId: string}>());
export const startQuizSuccess = createAction('[Start] Quiz Success', props<{data: LessonWithProgress}>());

export const finishQuiz = createAction('[Finish] Quiz', props<{ groupId: string, lessonId: string, userAnswerList: QuizFinishDTO }>());
export const finishQuizSuccess = createAction('[Finish] Quiz Success', props<{progress: ProgressDTO}>());

export const setQuizState = createAction('[SET] Quiz State', props<{quizState: QuizState}>());

export const updateProgressOfLesson = createAction('[SET] Update Lesson Progress', props<{ progress: ProgressDTO }>());
