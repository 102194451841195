import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SystemService } from "../../../../core/services/system/system.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Languages } from "../../../models";
import { CommonRoutes, Keys } from "../../../../core/model";
import { StorageService } from "../../../../core/services/storage/storage.service";
import { AlertMessageType } from "../../../models/interfaces/system-alerts";

@Component({
  selector: 'app-technical-works-page',
  templateUrl: './technical-works-page.component.html',
  styleUrls: ['./technical-works-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechnicalWorksPageComponent implements OnInit {
  languagesEnum = Languages;
  currentLanguage: Languages | null = null;
  currentYear: number = new Date().getFullYear();

  constructor(
    private systemService: SystemService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.currentLanguage = this.translate.currentLang as Languages;

    const storageLanguage = this.storageService.getItem(Keys.Language) as Languages;
    if(storageLanguage) {
      this.setLang((storageLanguage as string) === "kz" ? Languages.KZ : storageLanguage);
    }
    else {
      this.setLang(this.translate.getDefaultLang() as Languages);
    }

    this.systemService.getSystemAlerts()
      .subscribe({
        next: values => {
          if (values?.length) {
            this.systemService.setSystemAlert(values[0]);
            if (values[0].messageType === AlertMessageType.info) {
              this.router.navigate([CommonRoutes.Authorization]).then();
            }
            this.cd.detectChanges();
          } else {
            this.router.navigate([CommonRoutes.Authorization]).then();
          }
        }
      });
  }

  setLang(lang: Languages): void {
    this.currentLanguage = lang;
    this.storageService.setItem(Keys.Language, lang);
    this.translate.use(lang);
    this.cd.detectChanges();
  }
}
