import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from 'src/environment';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResultComponent implements OnChanges {
  public readonly environment = environment;

  @Input() title: string = "";
  @Input() infoText: string = ""; 
  @Input() score: number = 0;
  @Input() explanation: string = "";
  @Input() explanationImageUrl: string | undefined;
  @Input() referenceUrl: string | undefined;

  public isOpen: boolean = false;
  public isShowVideo: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['explanationImageUrl']) {
      this.isOpen = false;
    }
    if(changes['referenceUrl']) {
      this.isShowVideo = false;
    }
  }

  public showVideo(): void {
    this.isShowVideo = true;
  }
  public hideVideo(): void {
    this.isShowVideo = false;
  }
}
