import {
  ChangeDetectionStrategy,
  Component, ElementRef,
  EventEmitter, HostListener,
  Input,
  Output
} from '@angular/core';
import { Languages } from "../../../models";
import { ButtonColors } from "../../../models/enums/button.enum";

@Component({
  selector: 'app-translation-select',
  templateUrl: './translation-select.component.html',
  styleUrls: ['./translation-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TranslationSelectComponent implements LangSelectInputs, LangSelectOutputs {
  @Input() lang: Languages = Languages.KZ;
  @Output() change: EventEmitter<Languages> = new EventEmitter<Languages>();

  public isOpen: boolean = false;
  public btnColor: ButtonColors = ButtonColors.Black;

  languagesEnum = Languages;

  constructor(
    private el: ElementRef
  ) {}

  @HostListener('document:click', ['$event'])
  handleClick(event: Event) {
    if (!this.el?.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  toggleMenu() {
    this.isOpen = !this.isOpen;
  }

  setCurrentLang(lang: Languages) {
    this.isOpen = false;
    this.change.emit(lang);
  };
}

interface LangSelectInputs {
  lang: Languages;
}

interface LangSelectOutputs {
  change: EventEmitter<Languages>;
}
