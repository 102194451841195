import {LessonsService} from '../../../../core/services/lessons/lessons.service';
import {HelperService} from '../../../../shared/services/helper/helper.service';
import {TranslateService} from '@ngx-translate/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Languages, SelectValue} from 'src/app/shared/models';
import {CoursesDTO, LessonCreateRequest, LessonTypeKZ, LessonTypeRU, Roles} from 'src/app/core/model';
import {AuthService} from "../../../../core/services/auth/auth.service";
import {CuratorService} from "../../../../core/services/curator/curator.service";
import {ActivatedRoute} from "@angular/router";
import { HeadTeacherService } from 'src/app/core/services/head-teacher/head-teacher.service';
import { ToastrService } from 'ngx-toastr';
import {SUBJECTS_OPTIONS} from "../../../../shared/models/subjects";
import {CoursesService} from "../../../../core/services/courses/courses.service";
import {ThemeService} from "../../../../core/services/theme/theme.service";
import {ThemeDTO} from "../../../../core/model/interface/theme";
import {of, Subscription, switchMap} from "rxjs";
import {MethodistService} from "../../../../core/services/methodist/methodist.service";

@Component({
  selector: 'app-add-lesson-to-course',
  templateUrl: './add-lesson-to-course.component.html',
  styleUrls: ['./add-lesson-to-course.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddLessonToCourseComponent implements OnInit, OnChanges {
  @Input() isOpen: boolean = false;
  @Input() lessonId: string = "";

  @Output() closeDialog = new EventEmitter<void>();
  @Output() createEvent = new EventEmitter<void>();

  public form!: FormGroup;

  public subjectOptions: any[] = SUBJECTS_OPTIONS;
  public coursesOptions: SelectValue[] = [];
  public themeList: any[] = [];
  public taskType!: any;

  public isSaving: boolean = false;
  public isCourseOptionsIsLoading: boolean = false;
  public isThemesOptionsIsLoading: boolean = false;

  private subs$: Subscription[] = [];

  constructor(
      private formBuilder: FormBuilder,
      private translate: TranslateService,
      private helperService: HelperService,
      private lessonsService: LessonsService,
      private curatorService: CuratorService,
      private authService: AuthService,
      private route: ActivatedRoute,
      private headTeacherService: HeadTeacherService,
      private toastrService: ToastrService,
      private cdr: ChangeDetectorRef,
      private coursesService: CoursesService,
      private themeService: ThemeService,
      private methodistService: MethodistService
    ) {
    if (translate.getDefaultLang() === Languages.KZ) {
      this.taskType = LessonTypeKZ;
    }
    if (translate.getDefaultLang() === Languages.RU) {
      this.taskType = LessonTypeRU;
    }
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      list: new FormArray([])
    });

    this.addListItem();
  };

  public addListItem() {
    const item = this.formBuilder.group({
      subject: [null, Validators.required],
      course: [null, Validators.required],
      courseList: [null],
      themeList: [null],
      theme: [null, Validators.required]
    });

    this.subscribeToCourse(item);
    this.list.push(item);
  }

  private subscribeToCourse(itemListForm: FormGroup) {
    const sub =
      itemListForm.get('subject')!.valueChanges
        .pipe(
          switchMap(subject => {
            console.log('subject: ', subject)
            this.isCourseOptionsIsLoading = true;
            if (subject) {
              return this.coursesService.getCoursesListMethodist(subject, 0, '', 100);
            }
            return of(null);
          })
        )
        .subscribe({
          next: (courses) => {
            if (courses?.content?.length) {
              itemListForm.get('courseList')?.setValue(courses.content.map(t => {
                return {
                  name: t.name,
                  value: t.id
                }
              })
              );
            } else if (itemListForm.get('subject')?.value) {
              this.toastrService.warning('Нет курсов!');
            }

            this.isCourseOptionsIsLoading = false;
            this.cdr.detectChanges();
          }
        });

    const sub1 =
      itemListForm.get('course')!.valueChanges
        .pipe(
          switchMap(course => {
            console.log('course: ', course)
            this.isThemesOptionsIsLoading = true;
            if (course) {
              return this.themeService.getThemeList(course);
            }
            return of(null);
          })
        )
        .subscribe({
          next: (themes) => {
            if (themes?.length) {
              itemListForm.get('themeList')?.setValue(themes.map(t => {
                return {
                  name: t.name,
                  value: t.id
                }
              }));
            } else if (itemListForm.get('course')?.value) {
              this.toastrService.warning('Нет тем!');
            }

            this.isThemesOptionsIsLoading = false;
            this.cdr.detectChanges();
          }
        });

    this.subs$.push(sub, sub1);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isOpen'] && this.form) {
      for (let i = 1; i < this.list.length; i++) {
        this.list.removeAt(i);
      }
      this.form.reset();
    } else {
      this.subs$.forEach(x => x.unsubscribe());
      this.subs$ = [];
    }
  }

  get list(): FormArray {
    return this.form.get("list") as FormArray;
  }

  public addLesson() {
    const list = this.list.getRawValue();
    const themeIds = list.map(t => t.theme);

    this.methodistService.addToThemes({
      lessonId: this.lessonId,
      themeIds: themeIds
    })
      .subscribe({
        next: _ => {
          this.toastrService.success('Вы успешно добавили урок в другой курс', 'Успех');
          this.closeDialog.emit();
        },
        error: err => {
          this.toastrService.error(err?.error?.messages?.[0], "Ошибка");
        }
      });
  }
}
