import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {LessonDTO, LessonType, Roles, WorkOnMistakesDTO} from "../../../../../../core/model";
import {Observable, Subscription} from "rxjs";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {LessonsService} from "../../../../../../core/services/lessons/lessons.service";
import {ActivatedRoute, NavigationStart, Router} from "@angular/router";
import {
  DateHelperServiceService
} from "../../../../../../shared/services/dateHelperService/date-helper-service.service";
import {DatePipe} from "@angular/common";
import {TranslateService} from "@ngx-translate/core";
import {getFormControlErrors} from "../../../../../../shared/validators";
import {SelectValue} from "../../../../../../shared/models";
import {getTimeOptions} from "../common/common";
import {AuthService} from "../../../../../../core/services/auth/auth.service";
import {CuratorService} from "../../../../../../core/services/curator/curator.service";
import { FoldersService } from './../../../../../../core/services/folders/folders.service';

@Component({
  selector: 'app-work-on-mistakes-controller',
  templateUrl: './work-on-mistakes-controller.component.html',
  styleUrls: ['./work-on-mistakes-controller.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkOnMistakesControllerComponent implements OnInit, OnChanges {
  @Input() data: LessonDTO | undefined;
  @Input() name: string = "";
  @Input() saveClicked: boolean = false;
  @Input() isOpen: boolean = false;
  public currentUserRole: Roles;
  public roles = Roles;
  public studentsList: SelectValue[] = [];

  private eventsSubscription!: Subscription;
  private routerSubscription!: Subscription;
  public timeOptions: SelectValue[];
  @Input() saveEvent!: Observable<void>;
  @Output() allowSaveEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() savedEvent: EventEmitter<LessonDTO> = new EventEmitter<LessonDTO>();


  public groupId: string = "";
  public themeId: string = ""

  public unsavedChanges: boolean = false;
  public confirmationShown: boolean = false;

  public baseForm!: FormGroup;
  public baseFormErrors = {
    id: '',
    name: '',
    description: '',
    rating: '',
    date: '',
    time: '',
    maxScore: '',
    materials: '',
    videoUrl: "",
  }

  public foldersOptions: SelectValue[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private lessonsService: LessonsService,
    private el: ElementRef,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private dateHelper: DateHelperServiceService,
    private datePipe: DatePipe,
    private translateService: TranslateService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private curatorService: CuratorService,
    private FoldersService: FoldersService

  ) {
    this.currentUserRole = this.authService.getRole();
    this.timeOptions = getTimeOptions();
    this.baseForm = this.formBuilder.group({
      id: [''],
      name: [''],
      description: ['', Validators.required],
      rating: [true, Validators.required],
      date: ['', Validators.required],
      time: ['', Validators.required],
      maxScore: ['', Validators.required],
      videoUrl: ['', Validators.required],
      materials: [[]],
      usersIds: [[]],
      courseFolderId: ['']
    });

    this.baseForm.statusChanges.subscribe(status => {
      this.allowSaveEvent.emit(status === 'VALID');
    });
    this.baseForm.valueChanges.subscribe(res => {
      this.unsavedChanges = true;

      const timeFormatRegex1: RegExp = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/;
      const timeFormatRegex2: RegExp = /^([0-1][0-9]|2[0-3])[0-5][0-9]$/;
      const timeFormatRegex3: RegExp = /^([0-1][0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/;
      if (res.maxScore) {
        this.baseFormErrors.maxScore = ""
      } else {
        this.baseFormErrors.maxScore = "SHOW_MAX_SCORE_FOR_TASK"
      }
      if (res.date) {
        this.baseFormErrors.date = ""
      } else {
        this.baseFormErrors.date = "SHOW_DEADLINE"
      }
      if (timeFormatRegex1.test(res.time) || timeFormatRegex2.test(res.time) || timeFormatRegex3.test(res.time)) {
        this.baseFormErrors.time = ""
      } else {
        this.baseFormErrors.time = "REQUIRED_FIELD"
      }

      if (!res.videoUrl) {
        this.baseFormErrors.videoUrl = "SHOW_LINK_TO_VIDEO";
      } else {
        this.baseFormErrors.videoUrl = "";
      }

      if (res.description) {
        this.baseFormErrors.description = ""
      } else {
        this.baseFormErrors.description = "REQUIRED_FIELD"
      }
    });
  }

  ngOnInit(): void {
    this.initSaveSubscription();
    this.initRouterSubscription();
  };

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['data'] && this.data) {
      if(this.authService.getRole() === Roles.Methodist) {
        this.patchFormValues();

        this.route.params.subscribe(params => {
          this.FoldersService.getFoldersMethodist(params['id']!).subscribe({
            next: (res) => {
              this.foldersOptions = res[0].courseFolders.map(item => {
                return {
                  name: item.courseFolderName,
                  value: item.courseFolderId
                }
              });
              this.cdr.detectChanges();

              console.log(this.foldersOptions)
              this.baseForm.patchValue({ courseFolderId: this.data?.courseFolderId || []})

              this.cdr.detectChanges();
            },
            error: (err) => {
              console.log(err);
            }
          })
          this.cdr.detectChanges();
        })
      }

      if(this.authService.getRole() === Roles.HeadTeacher) {
        this.route.params.subscribe(params => {
          const groupId = params['groupId'];
          const courseId = params['id'];
          this.FoldersService.getFoldersHeadTeacher(courseId, groupId).subscribe({
            next: (res) => {

              this.foldersOptions = res[0].courseFolders.map(item => {
                return {
                  name: item.courseFolderName,
                  value: item.courseFolderId
                }
              });
              this.cdr.detectChanges();
              this.baseForm.patchValue({ courseFolderId: this.data?.courseFolderId || []})

              this.cdr.detectChanges();
            },
            error: (err) => {
              console.log(err);
            }
          })
          this.cdr.detectChanges();
        });

        if(!this.data?.common) {
          this.baseForm.setControl('usersIds', new FormControl([], []));
          this.baseForm.patchValue({'usersIds': this.data?.usersIds});
        }
      }
      if (this.currentUserRole === Roles.Curator) {
        this.route.params.subscribe(params => {
          this.groupId = params['groupId'];
          this.themeId = params['themeId'];
          this.loadStudents([this.groupId]);

          this.FoldersService.getFoldersCurator(this.groupId).subscribe({
            next: (res) => {
              this.foldersOptions = res[0].courseFolders.map(item => {
                return {
                  name: item.courseFolderName,
                  value: item.courseFolderId
                }
              });
              this.cdr.detectChanges();
              this.baseForm.patchValue({ courseFolderId: this.data?.courseFolderId || []})

              this.cdr.detectChanges();
            },
            error: (err) => {
              console.log(err);
            }
          })
          this.cdr.detectChanges();
        });
      }
    }
  }
  private loadStudents(groups: [string]) {
    this.curatorService.getStudentsByGroupIds(groups).subscribe(res => {
      this.studentsList = res.map(item => {
        return {
          name: item.firstname + " " + item.lastname,
          value: item.id || ''
        }
      }).sort((a, b) => (a.name).localeCompare((b.name)));;

      this.patchFormValues();
      this.cdr.detectChanges();
    });
  }

  private patchFormValues() {
    if (this.currentUserRole === Roles.Curator) {
      if (!this.data?.common) {
        this.baseForm.setControl('usersIds', new FormControl([], ));
        this.baseForm.patchValue({'usersIds': this.data?.usersIds})
      }
    }

    if(this.data?.deadline) {
      const deadline = this.dateHelper.convertUTCToLocal(new Date(this.data?.deadline));
      this.datePipe.transform(deadline, 'yyyy-MM-dd');

      this.baseForm.patchValue({date: this.datePipe.transform(deadline, 'yyyy-MM-dd')});
      this.baseForm.patchValue({
        time: (deadline.getHours() < 9 ? ('0' + deadline.getHours()) : deadline.getHours())
          + ':' + ((deadline.getMinutes() < 9) ? '0' + deadline.getMinutes() : deadline.getMinutes())
      });
    }


    this.baseForm.patchValue({description: this.data?.description});
    this.baseForm.patchValue({rating: this.data?.rating});
    this.baseForm.patchValue({maxScore: this.data?.maxScore});
    this.baseForm.patchValue({videoUrl: this.data?.videoUrl});
    this.baseForm.patchValue({materials: this.data?.materials});

    this.cdr.detectChanges();
  }

  private initSaveSubscription() {
    this.eventsSubscription = this.saveEvent.subscribe(() => {
      this.save();
    });
  }

  private initRouterSubscription() {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.isOpen && this.unsavedChanges && !this.confirmationShown) {
          this.confirmationShown = true;
          const shouldContinue = confirm(`В "${this.data?.name}" есть не сохраненые изменения.Хотите продолжить?`);
          if (!shouldContinue) {
            const currentRoute = this.router.routerState;
            this.router.navigateByUrl(currentRoute.snapshot.url, {skipLocationChange: true});
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.eventsSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }

  public toggleExpander(): void {
    this.isOpen = !this.isOpen;
  }

  public materialDownloaded(id: string): void {
    const currentAdditionalMaterials = this.baseForm.get('materials')?.value || [];
    const materials = [...currentAdditionalMaterials, id];

    this.baseForm.patchValue({materials: materials});
    this.baseFormErrors.materials = "";
  }

  public save(): void {
    const baseForm = this.baseForm.value;

    const hours = baseForm.time.slice(0, 2);
    const minutes = baseForm.time.slice(-2);
    const date = new Date(baseForm.date);

    const utcDate = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      hours,
      minutes,
    );


    const data: WorkOnMistakesDTO = {
      lessonType: LessonType.WorkOnMistakes,
      id: this.data!.id,
      name: this.name,
      description: baseForm.description,
      rating: baseForm.rating,
      deadline: utcDate.toISOString(),
      maxScore: baseForm.maxScore,
      materials: baseForm.materials,
      videoUrl: baseForm.videoUrl,
      usersIds: baseForm.usersIds,
      courseFolderId: baseForm.courseFolderId
    }
    this.lessonsService.updateWorkOnMistakes(this.groupId, this.themeId, this.data!.id, data).subscribe(res => {
      this.savedEvent.emit({...res, courseFolderId: baseForm.courseFolderId});
      this.unsavedChanges = false;
      this.cdr.detectChanges()
    })
  }

  handleTimeInput(event: Event): void {
    this.handleFormControlErrors(this.baseForm, this.baseFormErrors, 'time')
    const regex: RegExp = /^(?:[01]?[0-9]|2[0-3]):[0-5][0-9]$/;
    const value = (event.target as HTMLInputElement).value;
    if (!regex.test(value)) {
      this.el.nativeElement.value = '';
    }
  };

  handleFormControlErrors(form: FormGroup, error: any, controlName: string): void {
    getFormControlErrors(form, error, controlName, this.translateService);
    this.cdr.detectChanges();
  };

  public removed(id: string): void {
    const currentMaterials = this.baseForm.get('materials')?.value || [];
    const updatedMaterials = currentMaterials.filter((fileId: string) => fileId !== id);

    this.baseForm.patchValue({materials: updatedMaterials});
    // this.baseFormErrors.materials = updatedMaterials.length === 0 ? "Загрузите файл" : "";
  }

  public studentsSelected($event: any[]): void {
    this.baseForm.patchValue({usersIds: $event});
  }
}
