<h1 class="incorrect-message" *ngIf="quizState === _QuizState.View && !this.question.userAnswers;else showen">Вы не ответили на данный вопрос.</h1>
<ng-template  #showen>
  <div class="app-change-queue">
    <div class="wrapper">
      <div
        class="question card disable-selection"
        [class]="'question' + i"
        [ngClass]="{
                  'answer-correct' : (_QuizState.View === quizState) && question.correctAnswerTexts && (question.correctAnswerTexts.includes(key + ' ' + customValueList[i].value)),
                  'answer-incorrect' : (_QuizState.View === quizState) && question.correctAnswerTexts && (!question.correctAnswerTexts.includes(key + ' ' + customValueList[i].value)),
              }"
        *ngFor="let key of question.keyList; let i = index;">
        <span class="bold">{{ i + 1 + "."}}</span>
        <span>{{ key }}</span>
      </div>
    </div>
    <div class="wrapper answers-wrapper" [ngClass]="{'answers-wrapper--showen' : (_QuizState.View === quizState)}"
        cdkDropList
        [cdkDropListDisabled]="disabled"
        (cdkDropListDropped)="drop($event)">
      <div
        class="answer disable-selection"
        [class]="'answer' + i"
        *ngFor="let answer of customValueList; let i = index; let first = first; let last = last;"
        cdkDrag [cdkDragDisabled]="(_QuizState.View === quizState) || disabled">
        <img src="assets/svg/ic_sharp-drag-indicator.svg" alt=""/>
        <div
          class="card"
          [ngClass]="{
                      'answer-correct' : (_QuizState.View === quizState) && question.correctAnswerTexts && (question.correctAnswerTexts.includes(question.keyList[i] + ' ' + answer.value)),
                      'answer-incorrect' : (_QuizState.View === quizState) && question.correctAnswerTexts && (!question.correctAnswerTexts.includes(question.keyList[i] + ' ' + answer.value)),
                  }">
          <span class="bold">{{ (answer.index | letterAtPosition) + "."}}</span>
          <span>{{answer.value}}</span>
        </div>
        <div *ngIf="!(_QuizState.View === quizState)" class="controller">
          <app-primary-arrow (click)="stepForward(first, i)" [closeColor]="first ? '#E8E9EE' : '#1C202C'"
                            [initialSlope]="0"></app-primary-arrow>
          <app-primary-arrow (click)="stepBack(last, i)" [closeColor]="last ? '#E8E9EE' : '#1C202C'"
                            [initialSlope]="180"></app-primary-arrow>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<app-result
  [explanation]="question.explanation"
  [referenceUrl]="question.referenceUrl"
  [explanationImageUrl]="question.explanationImageUrl"
  [score]="question.score"
  *ngIf="(_QuizState.View === quizState) && question.correctAnswerTexts" [title]="'result' | translate">
  <div class="answer-wrapper">
    <div class="answer-answer">
      <h1 class="answer-title">{{'correctAnswer' | translate}}</h1>
      <div class="answer-answers-wrapper">
        <div class="answer-list">
          <div class="card" *ngFor="let option of question.keyList; let i = index">
            <span class="bold">{{ (i + 1) + '. '}}</span>
            <span class="option">{{option}}</span>
          </div>
        </div>
        <div class="answer-list">
          <div class="card" *ngFor="let answer of question.correctAnswerTexts;let i = index;">
            <span class="bold">{{ (i | letterAtPosition) + '. '}}</span>
            <a>{{answer.toString().replace(question.keyList[i], '')}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-result>
