<div class="table radius-6 border" (scroll)="scroll($event)">
  <div class="flex header">
    <div class="p-24" *ngFor="let span of headerColumns">
      {{ span.label }}
    </div>
  </div>
  <div class="body flex flex-col">
    <div class="row flex" *ngFor="let headerSpan of headerColumns">
      <ng-container *ngFor="let row of rows">
        <ng-container *ngFor="let span of row.spans">
          <div class="" *ngFor="let item of span.items">
            <span
              class="p-24"
              >{{ item.lessonName }}</span
            >
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <!-- <div class="header flex">
    <ng-container *ngFor="let span of headerColumns; let i = index">
      <div
        *ngIf="span.type === 'single'"
        class="header-span flex items-center justify-center"
        [style.min-width.px]="span.width"
        [style.max-width.px]="span.width"
        [style.border-right]="i + 1 === headerColumns.length ? 0 : ''"
        [style.position]="i < 2 ? 'sticky' : ''"
        [style.z-index]="i < 2 ? '10' : ''"
        [style.left.px]="i === 1 ? headerColumns[i - 1].width : ''"
        [class.shadow]="i === 1 && isScrollingTableByY"
      >
        {{ span.label }}
ш      </div>
      <div
        *ngIf="span.type === 'double'"
        class="wrapper flex flex-col justify-between"
        [style.border-right]="i + 1 === headerColumns.length ? 0 : ''"
        [style.min-width.px]="span.width"
      >
        <div class="header-span_top flex justify-center items-center">
          {{ span.label }}
        </div>
        <div class="header-span_bottom flex justify-center">
          Макс:
          <span class="value">
            {{ span.maxValue }}
          </span>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="body">
    <div
      class="flex row"
      *ngFor="let row of rows; let i = index"
      [style.border-bottom]="rows.length === i + 1 ? '0' : ''"
    >
      <div
        *ngFor="let span of row.spans; let spanIndex = index"
        class="span flex justify-center items-center"
        [style.min-width.px]="span.width"
        [style.max-width.px]="span.width"
        [style.border-right]="spanIndex + 1 === row.spans.length ? 0 : ''"
        [style.position]="spanIndex < 2 ? 'sticky' : ''"
        [style.z-index]="spanIndex < 2 ? '10' : ''"
        [style.left.px]="spanIndex === 1 ? row.spans[spanIndex - 1].width : ''"
        [class.shadow]="spanIndex === 1 && isScrollingTableByY"
        [style.cursor]="span.acessor === 'name' ? 'pointer' : ''"
        (click)="
          span.acessor === 'name' ? onNameClick(span.value, span.id) : ''
        "
      >
        <img *ngIf="span.img" class="avatar" [src]="span.img" alt="" />

        {{ span.value }}
      </div>
    </div>
  </div> -->
</div>
