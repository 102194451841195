import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { ICdkSelectOption } from './_model';
import { SearchSize } from "../../../models/enums/search.enum";
import { MatSelect } from "@angular/material/select";
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-cdk-select',
  templateUrl: './cdk-select.component.html',
  styleUrls: ['./cdk-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CdkSelectComponent {
  @ViewChild('select') select!: MatSelect;

  @Input() name?: string;
  @Input() options: ICdkSelectOption[] = [];
  @Input() value?: string;
  @Input() withApply: boolean = false;
  @Input() withSearch: boolean = false;

  @Output() selectChangeHandler: EventEmitter<any> = new EventEmitter();
  @Output() searchInputHandler: EventEmitter<any> = new EventEmitter();

  protected readonly SearchSize = SearchSize;

  @ViewChild(CdkVirtualScrollViewport) viewport!: CdkVirtualScrollViewport;

  constructor(private cdr: ChangeDetectorRef) {}

  public selectChange(data: any): void {
    this.selectChangeHandler.emit(data)
  }

  public onOpenedChange() {
    const selectedIndex = this.options.findIndex(option => option.value === this.value);
    if (selectedIndex !== -1 && this.viewport) {
      setTimeout(() => {
        this.viewport.scrollToOffset(selectedIndex * 48, 'smooth');
      });
    }
  }

  public onSearchInput(searchInput: string): void {
    this.searchInputHandler.emit(searchInput)
  }

  closeSelect(): void {
    this.select.close();
  }

  get optionName(): string {
    return this.options.find(t => t.value === this.value)?.viewValue || '';
  }
}
