import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { MenuService } from 'src/app/shared/services/menuService/menu-service.service';

@Component({
  selector: 'app-right-menu',
  templateUrl: './right-menu.component.html',
  styleUrls: ['./right-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class RightMenuComponent implements OnInit {
  public isShow: boolean = true;

  @Input() mobileAdaptive: boolean = false;
  @Input() backdropClick: boolean = false;
  @Input() biggerWidth: boolean = false;
  @Output() closeEvent = new EventEmitter();

  constructor(
    private _menuService: MenuService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._menuService
      .getIsShowRightMenu()
      .subscribe((response) => { 
        this.isShow = response;
        this.cdr.detectChanges()
    });
  }

  public closeMenu(): void {
    this.closeEvent.emit();
    this._menuService.setIsShowRightMenu(false);
  }
}
