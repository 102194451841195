<div class="courses-card">
  <app-expander [isExpanded]="isOpen">
    <button class="button" (click)="toggle()" button>
      <div class="info">
        <h1 class="name">{{currentGroup ? currentGroup.courseName : ""}}</h1>
        <span class="stream">{{currentGroup?.courseName || ''}}</span>
      </div>
      <app-primary-arrow closeColor="white" [isOpen]="isOpen"></app-primary-arrow>
    </button>
    <div class="content" content>
      <ul class="list">
        <li *ngFor="let item of groupsList" class="item">
          <button (click)="openGroup(item.groupId)" class="item-button">
            <h1 class="item-name">{{item ? item.courseId : ""}}</h1>
            <span class="item-stream">{{item.courseName}}</span>
          </button>
        </li>
      </ul>
      <div *ngIf="groupsList.length < 2" class="one-course">
        <div class="one-course-info">
          <img class="one-course-icon" src="assets/svg/informative/Info.svg" alt=""/>
          <p class="one-course-text">{{'noOtherPurchasedCourses' | translate}}</p>
        </div>
        <app-courses-catalog-button [theme]="catalogCoursesButtonTheme.LIGHT"></app-courses-catalog-button>
      </div>
    </div>
  </app-expander>
</div>
