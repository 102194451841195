<mat-form-field class="w-100" [appearance]="'outline'">
  <mat-label *ngIf="name">{{ name }}</mat-label>
  <mat-select
    #select
    [value]="value"
    (openedChange)="onOpenedChange()"
    (valueChange)="selectChange($event)">
    <app-search *ngIf="withSearch" class="cdk-select-search"
                [size]="SearchSize.Medium"
                (searchListener)="onSearchInput($event)"/>
    <ng-container *ngIf="options.length < 1000">
      <mat-option *ngFor="let option of options" [value]="option.value">
        {{ option.viewValue }}
      </mat-option>
    </ng-container>
    <cdk-virtual-scroll-viewport *ngIf="options.length > 1000" appendOnly
                                 [itemSize]="10" [style.height.px]=200>
      <mat-option
        color="primary"
        *cdkVirtualFor="let option of options; index as index"
        [value]="option.value"
      >
        {{ option.viewValue }}
      </mat-option>
      <mat-option *ngIf="value" [value]="value">
        {{ optionName }}
      </mat-option>
    </cdk-virtual-scroll-viewport>
    <button *ngIf="withApply"
            (click)="closeSelect()"
      class="cdk-close-select">{{'save' | translate}}</button>
  </mat-select>
</mat-form-field>
