import { AfterContentInit, AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { Subscription, debounceTime } from 'rxjs';
import { UsefullLink } from 'src/app/core/model';
import { getFormControlErrors } from 'src/app/shared/validators';
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'app-user-ful-links',
  templateUrl: './user-ful-links.component.html',
  styleUrls: ['./user-ful-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserFulLinksComponent implements OnInit, OnDestroy {
  @Input() list: UsefullLink[] = [];
  @Output() valueChangesEvent: EventEmitter<UsefullLink[]> = new EventEmitter<UsefullLink[]>();
  @Output() validQuestionStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

  public form!: FormGroup;
  private valueChangesSubscription: Subscription | undefined;

  public formErrors: UsefullLink[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {
    this.form = formBuilder.group({
      list: formBuilder.array([])
    });
    this.form.statusChanges.subscribe((status) => {
      const anyCustomError: boolean = this.formErrors.some(item => item.name || item.link);
      this.validQuestionStatus.emit((status === 'VALID' && !anyCustomError) ? true : false);
    });

    this.form.valueChanges.subscribe(res => {
      if(res.list.length > 0) {
        for(let i = 0; i < res.list.length; i++) {
          if(res.list[i].name) {
            this.formErrors[i].name = ""
          }
          else {
            this.formErrors[i].name = "Обязательное поле"
          }
          if(res.list[i].link) {
            this.formErrors[i].link = ""
          }
          else {
            this.formErrors[i].link = "Обязательное поле"
          }
        }
      }
    });
  }

  ngOnInit(): void {

    this.valueChangesSubscription = this.form.valueChanges
      .pipe(debounceTime(500)) // Adjust the time in milliseconds as needed
      .subscribe(() => {
        this.save(); // Call the save method after a pause in typing (optional)
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['list'] && this.list && this.usefulLinks.value.length === 0) {
      if(this.list) {
        for(let item of this.list) {
          this.formErrors.push({name: '', link: ''})
          this.usefulLinks.push(this.formBuilder.group({name: item.name, link: item.link}));
        }
      };
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from valueChangesSubscription to prevent memory leaks
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
  };

  get usefulLinks(): FormArray {
    return this.form.controls['list'] as FormArray;
  }

  public addLink(): void {
    this.formErrors.push({name: 'Обязательное поле', link: 'Обязательное поле'})

    this.usefulLinks.push(this.formBuilder.group({
      name: ['', Validators.required],
      link: ['', Validators.required]
    }));
  }
  public removeLink(index: number): void {
    this.usefulLinks.removeAt(index);

    this.formErrors.splice(index, 1);
  }

  public save(): void {
    this.valueChangesEvent.emit(this.usefulLinks.value)
  }

  public handleFormControlErrors(form: FormGroup, error: any, controlName: string): void {
    getFormControlErrors(form, error, controlName, this.translateService);
    this.cdr.detectChanges();
  };

}
