import { Injectable } from '@angular/core';
import { EducationRoutes, LessonDTO, LessonSummary, LessonType, Roles, TestRoutes } from "../../../core/model";
import { Router } from "@angular/router";
import { LessonsService } from "../../../core/services/lessons/lessons.service";
import { AuthService } from "../../../core/services/auth/auth.service";
import { HeaderService } from "../../../core/services/header/header.service";

@Injectable({
  providedIn: 'root'
})
export class EducationService {

  constructor(
    private router: Router,
    private lessonsService: LessonsService,
    private authService: AuthService,
    private headerService: HeaderService
  ) { }

  navigateByLessonType(lesson: LessonDTO | LessonSummary | undefined, groupId: string | undefined, themeId: string = '') {
    const role = this.authService.getRole();
    if (!lesson || (!groupId && role !== Roles.Methodist)) return;

    const mainPath = role === Roles.HeadTeacher ? 'headTeacher' : role.toLowerCase();
    this.headerService.setBackButtonName('BACK');
    this.headerService.setBackButtonValue(this.router.url);

    let url = `${mainPath}/${EducationRoutes.Main}/${EducationRoutes.Theory}/${groupId}/${lesson?.id}`;
    switch (lesson?.lessonType) {
      case LessonType.Homework:
        url = `${mainPath}/${EducationRoutes.Main}/${EducationRoutes.Homework}/${groupId}/${lesson?.id}`;
        break;
      case LessonType.WorkOnMistakes:
        url = `${mainPath}/${EducationRoutes.Main}/${EducationRoutes.WorkOnMistakes}/${groupId}/${lesson?.id}`;
        break;
      case LessonType.Quiz:
        url = `${mainPath}/${TestRoutes.Test}/${TestRoutes.TestPass}/${groupId}/${lesson?.id}`;
        break;
      case LessonType.Text:
        url = `${mainPath}/${EducationRoutes.Main}/${EducationRoutes.Text}/${groupId}/${lesson?.id}`;
        break;
    }
    this.router.navigate([url]).then();
  }
}
