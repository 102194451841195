<div class="expander" [class.reverseContent]="showContentFirst">
    <button class="button">
        <ng-content select="[button]"></ng-content>
    </button>
    <div class="content" id="expander-content" #expanderContent>
        <ng-content
            select="[content]"
            [hidden]="!isExpanded">
        </ng-content>
    </div>
</div>
