import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-info-tooltip',
  templateUrl: './info-tooltip.component.html',
  styleUrls: ['./info-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoTooltipComponent {
  @Input() text: string = '';
  @Input() isShow: boolean = false;
}
