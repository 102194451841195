<div class="table-wrapper">
  <table class="table">
    <thead>

    </thead>
    <tbody>
      <tr>
        <th>{{'DATE' | translate}}</th>
        <th>{{'SUMMA' | translate}}</th>
        <th>{{'PAYMENT_TYPE' | translate}}</th>
        <th>{{'SUBJECTS' | translate}}</th>
      </tr>
      <tr *ngFor="let item of data">
        <td class="date">{{item.paymentHistoryDateTime | date: 'dd.MM.yyyy hh:mm'}}</td>
        <td>{{item.paymentHistoryTotalPrice | number}}</td>
        <td>{{item.paymentHistoryType | titlecase}}</td>
        <td>
          <ol>
            <li *ngFor="let subject of item.paymentHistoryStreamNames; let i = index">
              <span>{{i+1}}.</span>
              {{subject}}
            </li>
          </ol>
        </td>
      </tr>
    </tbody>
  </table>
</div>
