import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Languages} from './shared/models';
import { CataloguesService } from 'src/app/core/services/catalogues/catalogues.service';
import { ICatalogue } from './core/model/interface/catalogues';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  public test1:any = "";
  public test2:any = "";
  public test3:any = "";
  public test4:any = "";
  constructor(
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private cataloguesService: CataloguesService
  ) {
    translate.setDefaultLang(Languages.KZ);
    translate.use(Languages.KZ);          
    const message = {
      content: 'Login',
      channel: 'authorization' // Specify the channel identifier
    };

    window.parent.postMessage(message, '*');

    localStorage.setItem('activeCourses', '')
  }
  
  ngOnInit(): void { 
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if(localStorage.getItem('mobile_app')) {
        const userId = localStorage.getItem('userId');
        const prevUserId = localStorage.getItem('prev_userId');

        this.test2 = userId;
        this.test3 = prevUserId;
        this.test4 = localStorage.getItem('cart');
        // straigt to catalogue and no user
        if(!userId) {
          this.test1 = '1'
          this.cataloguesService.clearCart()
        }
    
        // from app to catalogue and first time
        if(!prevUserId && userId) {
          this.test1 = '2'

          localStorage.setItem('prev_userId', userId!)
          this.cataloguesService.clearCart()
        }
        
        // from app to catalogue and not first time
        if(prevUserId && prevUserId === userId) {
          // this.test1 = '3'

        }
    
        // from app to catalogue and not first time and another account
        if(prevUserId && prevUserId !== userId) {
          this.cataloguesService.clearCart();
          localStorage.setItem('prev_userId', userId!);
          this.test1 = '4';
        }

        this.cdr.detectChanges();
      }
    }, 1000)
  }
}