<div class="student-side-bar-courses">
  <app-expander [isExpanded]="isOpen">
    <button class="button" (click)="toggle(currentGroup?.groupId)" button>
      <div class="flex justify-between items-center w-100">
        <div class="base">
          <h1 class="name">
            {{ currentGroup ? currentGroup.groupName : ''}}
            <span *ngIf="currentGroup?.isCourseFinished">({{'COMPLETED' | translate}})</span>
          </h1>
          <span class="stream">{{currentGroup?.courseName}}</span>
        </div>
        <div class="desktop">
          <app-primary-arrow *ngIf="groupsList && groupsList.length > 0" class="arrow" [isOpen]="!isOpen"
                             closeColor="white"></app-primary-arrow>
        </div>
      </div>
<!--      <div class="additional">-->
<!--        <app-progress-bar [value]="percent || 0"></app-progress-bar>-->
<!--        <div class="properties">-->
<!--          <span class="key">{{'myProgress' | translate}}</span>-->
<!--          <span class="value">{{(percent || 0 | number: '1.0-0') + '%'}}</span>-->
<!--        </div>-->
<!--      </div>-->
    </button>
    <div class="desktop" content>
      <div class="content">
        <ul class="list">
          <li class="item" *ngFor="let item of groupsList;">
            <button (click)="openGroup(item.groupId)"
                    class="item-button">
              {{item.groupName}}
              <span *ngIf="item.isCourseFinished">({{'COMPLETED' | translate}})</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </app-expander>
</div>
