<div class="content mobile">
    <ul class="list">
        <li class="item" *ngFor="let item of navs">
            <button (click)="navigateTo(item.name)" class="button">
                <img [src]="!item.isActive ? item.imgActive : item.imgNotActive" alt="" />
                <h1 class="name" [class]="{'name-active': item.isActive}">{{item.name | translate}}</h1>
            </button>
        </li>
    </ul>
</div>
