import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BaseRoutes } from 'src/app/core/model';
import { TestFinished } from 'src/app/shared/models';

@Component({
  selector: 'app-test-result',
  templateUrl: './test-result.component.html',
  styleUrls: ['./test-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestResultComponent {
  @Input() byWhat: TestFinished = TestFinished.ByTime;
  @Input() score: number = 0;
  @Input() maxScore: number | undefined;
  public testFinished = TestFinished;

  constructor(private router: Router){}
  public toHome(): void {
    this.router.navigate([`/${BaseRoutes.Student}`])
  }
}
