import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, map, Observable} from 'rxjs';
import {IGroup} from '../../model/interface/group';
import {
  IShortLesson,
  LessonCreateRequest,
  LessonCreateResponse,
  LessonDTO,
  LessonSummary,
  QuizWithProgress,
  Roles,
  StudentProgress, StudentTasks,
  StudentUser,
} from '../../model';
import {IEvent, IEventCreate} from '../../model/interface/event';
import {IAnnouncementCreate} from '../../model/interface/announcement';
import {MyTasks} from "../../model/interface/my-tasks";

@Injectable({
  providedIn: 'root',
})
export class CuratorService {
  public _isShowStudentModal: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  public selectedLesson$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private _http: HttpClient) {}

  public getGroups(): Observable<IGroup[]> {
    return this._http.get<IGroup[]>(`/v1/curator/groups`);
  }

  public getThemes(groupId: string): Observable<any> {
    return this._http.get(`/v1/curator/groups/${groupId}/themes`);
  }

  public getMaterialsByGroupAndThemeId(
    groupId: string,
    themeId: string
  ): Observable<any> {
    return this._http
      .get(`/v1/curator/groups/${groupId}/themes/${themeId}/lessons`)
      .pipe(
        map((response: any) => {
          let mapped = response?.map((item: any) => {
            item.lessonMaxScore =
              item.lessonType === 'LECTURE'
                ? item.miniTestMaxScore
                : item.maxScore;
            if (item.lessonProgress.averageScore === 'NaN' || !item.lessonProgress.averageScore) {
              item.lessonProgress.averageScore = '-';
            } else {
              console.log(item.lessonProgress)
              item.lessonProgress.averageScore =
                item.lessonProgress.averageScore.toFixed(2);
            }
            return item;
          });
          return mapped;
        })
      );
  }

  public changeLecturesOrder(
    groupId: string,
    themeId: string,
    arrId: string[]
  ): Observable<any> {
    return this._http.put(
      `/v1/curator/groups/${groupId}/themes/${themeId}/order`,
      arrId
    );
  }

  public getRating({
    groupIds,
    themeIds,
    lessonIds,
  }: {
    groupIds: string[];
    themeIds: string[];
    lessonIds: string[];
  }): Observable<any> {
    return this._http.get<any>(`/v1/curator/rating`, {
      params: { groupIds, themeIds, lessonIds },
    });
  }

  public toggleIsShowStudentModal(): void {
    this._isShowStudentModal.next(!this._isShowStudentModal.getValue());
  }

  public getProgresses(groupId: string, lessonsId: string): Observable<any> {
    return this._http
      .get(`/v1/curator/groups/${groupId}/lessons/${lessonsId}/progresses`)
      .pipe(
        map((response: any) => {
          let mapped = response?.map((item: any) => {
            item.lessonMaxScore =
              item.lessonType === 'LECTURE'
                ? item.miniTestMaxScore
                : item.maxScore;

            return item;
          });
          return mapped;
        })
      );
  }

  public getQuizWithProgress(groupId: string, studentId: string, lessonsId: string): Observable<QuizWithProgress> {
    return this._http.get<QuizWithProgress>(`/v1/curator/groups/${groupId}/students/${studentId}/lessons/${lessonsId}/progresses`)
  }
  public getStudentFullInfo(
    studentId: string,
    groupId: string,
    courseId: string
  ): Observable<any> {
    return this._http.get<any>(
      `/v1/curator/students/${studentId}/groups/${groupId}/courses/${courseId}`
    );
  }

  public gradeLesson({
    grade,
    groupId,
    lessonId,
    studentId,
  }: {
    grade: number | null;
    groupId: string;
    lessonId: string;
    studentId: string;
  }): Observable<any> {
    return this._http.put(`/v1/curator/progresses/groups/${groupId}/lessons/${lessonId}/students/${studentId}`, {
      score: grade
    });
  }

  public commentLesson({
    text,
    groupId,
    lessonId,
    studentId,
  }: {
    text: string;
    groupId: string;
    lessonId: string;
    studentId: string;
  }): Observable<any> {
    return this._http.patch(`/v1/curator/progresses/groups/${groupId}/lessons/${lessonId}/students/${studentId}/comment`, {
      commentText: text,
    });
  }

  public getLessonsByThemes(ids: string[]): Observable<any> {
    return this._http.get(`/v1/curator/rating/lessonsbythemeids`, {
      params: {
        themeIds: ids,
      },
    });
  }

  public getThemesByGroups(ids: string[]): Observable<any> {
    return this._http.get(`/v1/curator/rating/themesbygroupids`, {
      params: {
        groupIds: ids,
      },
    });
  }

  public getStudentsByGroups(ids: string[]): Observable<any> {
    return this._http.get(`/v1/curator/announcements/studentsbygroupids`, {
      params: { groupIds: ids },
    });
  }

  public getAnnounceStudents(
    ids: string[],
    title: string,
    text: string
  ): Observable<any> {
    return this._http.post(`/v1/curator/announcements`, {
      students: ids,
      topic: title,
      text: text,
    });
  }

  public createAnnounce(data: IAnnouncementCreate): Observable<any> {
    return this._http.post<any>(`/v1/curator/announcements`, data);
  }

  public updateAnnounce(announcementId: string, data: IAnnouncementCreate): Observable<any> {
    return this._http.put<any>(`/v1/curator/announcements/${announcementId}`, data);
  }

  public getStudentsByGroupIds(ids: string[]): Observable<StudentUser[]> {
    return this._http.post<StudentUser[]>(`/v1/curator/studentsbygroupids`, ids);
  }

  public getStudentsByGroupId(groupId: string): Observable<StudentUser[]> {
    return this._http.get<StudentUser[]>(`/v2/curator/groups/${groupId}/students`);
  }

  public getLesonsByThemeIds(ids: string[]): Observable<any> {
    return this._http.get(`/v1/curator/rating/lessonsbythemeids`, {
      params: {
        themeIds: ids,
      },
    });
  }

  public getLesonsByGroupsIds(ids: string[]): Observable<any> {
    return this._http.get(`/v1/curator/rating/lessonsbygroupids`, {
      params: {
        groupIds: ids,
      },
    });
  }

  public getExcelFile(
    groupIds: string[],
    themeIds: string[],
    lessonIds: string[]
  ): Observable<Blob> {
    return this._http.get(`/v1/curator/rating/excel/download`, {
      params: {
        groupIds: groupIds,
        themeIds: themeIds,
        lessonIds: lessonIds,
      },
      responseType: 'blob',
    });
  }

  public sendToParents(
    groupIds: string[],
    themeIds: string[],
    lessonIds: string[]
  ): Observable<any> {
    return this._http.post(`/v1/curator/rating/excel/sendtoparents`, null, {
      params: {
        groupIds: groupIds,
        themeIds: themeIds,
        lessonIds: lessonIds,
      },
    });
  }

  public sendToStudents(
    groupIds: string[],
    themeIds: string[],
    lessonIds: string[]
  ): Observable<any> {
    return this._http.post(`/v1/curator/rating/excel/sendtostudents`, null, {
      params: {
        groupIds: groupIds,
        themeIds: themeIds,
        lessonIds: lessonIds,
      },
    });
  }

  public createLesson(
    data: LessonCreateRequest,
    groupId: string
  ): Observable<LessonCreateResponse> {
    return this._http
      .post<LessonCreateResponse>(`/v1/curator/groups/${groupId}/lessons`, data)
      .pipe(
        map((res: LessonCreateResponse) => {
          return res;
        })
      );
  }

  public createStudentProgress(
    groupId: string,
    lessonsId: string,
    studentId: string
  ): Observable<any> {
    return this._http.post(
      `/v1/curator/groups/${groupId}/lessons/${lessonsId}/students/${studentId}/progresses`,
      {
        groupId,
        lessonsId,
        studentId,
      }
    );
  }

  public getEvents(): Observable<IEvent[]> {
    return this._http.get<IEvent[]>(`/v1/curator/events`);
  }

  public getEventById(id: string): Observable<IEvent> {
    return this._http.get<IEvent>(`/v1/curator/events/${id}`);
  }

  public createEvent(data: IEventCreate): Observable<IEvent> {
    return this._http.post<IEvent>(`/v1/curator/events`, data);
  }
  public updateEvent(id: string,  data: IEventCreate): Observable<IEvent> {
    return this._http.put<IEvent>(`/v1/curator/events/${id}`, data);
  }
  public removeEvent(id: string): Observable<IEvent> {
    return this._http.delete<IEvent>(`/v1/curator/events/${id}`);
  }

  public removeLessonById(lessonId: string): Observable<boolean> {
    return this._http.delete<boolean>(`/v1/curator/lessons/${lessonId}`);
  }

  public getMyCuratorList({ page, size, search,}: { page: number; size: number; search?: string; }): Observable<any> {
    let params: any = { page, size };
    if (search) params.search = search;

    return this._http.get<any[]>(`/v1/headteacher/curators`, {
      params,
    });
  }

  public getLessonsWithSummaries(groupId: string, themeId: string): Observable<LessonSummary[]> {
    return this._http.get<LessonSummary[]>(`/v2/curator/groups/${groupId}/themes/${themeId}/lessons/summary`)
  }

  public getStudentProgresses(groupId: string, lessonId: string): Observable<StudentProgress[]> {
    return this._http.get<StudentProgress[]>(`/v2/curator/groups/${groupId}/lessons/${lessonId}/progresses`);
  }

  public getLessonsList(groupId: string, themeId: string): Observable<IShortLesson[]> {
    return this._http.get<IShortLesson[]>(`/v2/curator/groups/${groupId}/themes/${themeId}/lessons`)
  }

  public getLesson(groupId: string, lessonId: string): Observable<LessonDTO> {
    return this._http.get<LessonDTO>(`/v2/curator/groups/${groupId}/lessons/${lessonId}`)
  }

  public getMyTasks(search: string = ''): Observable<MyTasks> {
    let params:any = {}
      params['search'] = search
    return this._http.get<MyTasks>('/v2/curator/mytasks', {params});
  }

  public editComment(commentId: string, commentText: string): Observable<void> {
    return this._http.put<void>(`/v1/curator/progresses/comments/${commentId}`, {
      commentText: commentText
    });
  }

  public deleteComment(commentId: string): Observable<void> {
    return this._http.delete<void>(`/v1/curator/progresses/comments/${commentId}`);
  }

  public getAnnouncements(pageable: ServiceDto): Observable<any> {
    if (!pageable?.roles?.length) {
      pageable.roles = [Roles.Student];
    }
    const params: any = pageable;
    return this._http.get<any>('/v1/curator/announcements', {
      params,
    });
  }

  public getAnnouncementsGroups(): Observable<any> {
    return this._http.get<any>('/v1/curator/announcements/groups');
  }

  public deleteAnnouncement(announcementId: string): Observable<void> {
    return this._http.delete<void>(`/v1/curator/announcements/${announcementId}`);
  }

  public duplicateLesson(groupId: string, lessonId: string): Observable<LessonDTO> {
    return this._http.post<LessonDTO>(`/v2/curator/groups/${groupId}/lessons/${lessonId}/duplicate`, {})
  }

  public commentedLesson(groupId: string, lessonId: string, studentId: string): Observable<LessonDTO> {
    return this._http.patch<LessonDTO>(`/v1/curator/progresses/groups/${groupId}/lessons/${lessonId}/students/${studentId}/commented`, {})
  }

  public returnLesson(groupId: string, lessonId: string, studentId: string): Observable<any> {
    return this._http.patch<any>(`/v1/curator/progresses/groups/${groupId}/lessons/${lessonId}/students/${studentId}/return`, {});
  }

  public removeStudentFromGroup(
    studentId: string,
    groupId: string
  ): Observable<any> {
    return this._http.patch(
      `/v1/curator/groups/${groupId}/students/${studentId}/remove`,
      {
        params: {
          studentId,
          groupId,
        },
      }
    );
  }

  public getStudentTask(groupId: string, studentId: string): Observable<StudentTasks> {
    return this._http.get<StudentTasks>(`/v1/curator/groups/${groupId}/students/${studentId}/deadlines/upcoming`);
  }
}

interface ServiceDto {
  page?: number;
  size?: number;
  search?: string;
  sort?: string[],
  groupIds?: string[],
  courseIds?: string[],
  roles?: Roles[]
}
