import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, tap, BehaviorSubject } from 'rxjs';
import {
  StudentUser,
  MethodistUser,
  SortableResponseData,
  ICommonUser, CuratorUser,
} from '../../model';
import { SelectValue } from 'src/app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  userProfile$: BehaviorSubject<StudentUser & MethodistUser & CuratorUser | null> =
    new BehaviorSubject<StudentUser & MethodistUser & CuratorUser | null>(null);

  constructor(private httpClient: HttpClient) {}

  public getUserProfile(): Observable<StudentUser & MethodistUser & CuratorUser> {
    return this.httpClient
      .get<StudentUser & MethodistUser & CuratorUser>(`/v1/users/profile`)
      .pipe(
        tap((res: StudentUser & MethodistUser & CuratorUser) => {
          if (res.instagramLink) {
            res.instagramLink = res.instagramLink.replace(
              'https://www.instagram.com/',
              ''
            );
          }
        }),
        map((res: StudentUser & MethodistUser & CuratorUser) => {
          return res;
        })
      );
  }
  public updateUserProfile(
    data: StudentUser | MethodistUser
  ): Observable<StudentUser | MethodistUser> {
    return this.httpClient
      .put<StudentUser | MethodistUser>(`/v1/users/profile`, data)
      .pipe(
        map((res: StudentUser | MethodistUser) => {
          return res;
        })
      );
  }

  getTeachersList(): Observable<SelectValue[]> {
    return this.httpClient.get<StudentUser>('/v1/users/teachers').pipe(
      map((res: StudentUser) => {
        const selectValues: SelectValue[] = res.map((user: StudentUser) => ({
          name: `${user.firstname} ${user.lastname}`,
          value: user.id || '',
        }));

        return selectValues;
      })
    );
  }

  public getUsers({
    page,
    size,
    roles,
    search,
  }: {
    page: number;
    size: number;
    roles: string[];
    search?: string;
  }): Observable<SortableResponseData<any>> {
    let params: any = { page, size, roles };
    if (search) params.search = search;
    return this.httpClient.get<SortableResponseData<any>>(`/v1/users`, {
      params,
    });
  }

  public inviteUser({
    email,
    roles,
    lastName,
    firstName,
    phone,
    subjects
  }: {
    email: string;
    roles: string[];
    lastName?: string;
    firstName?: string;
    phone?: string | null;
    subjects?: string[]
  }): Observable<any> {
    const params: any = {
      email,
      roles: [...roles],
      phoneNumber: phone
    };

    if (lastName) params.lastname = lastName;
    if (firstName) params.firstname = firstName;
    if (subjects) params.subjects = subjects;
    return this.httpClient.post(`/v1/users/sendregistrationlink/new`, params);
  }

  public saveUser(data: any): Observable<any> {
    return this.httpClient.post(`/v1/users`, data);
  }

  public getAllUsersByRole(
    roles: string[],
    search: string = ''
  ): Observable<ICommonUser[]> {
    return this.httpClient.get<ICommonUser[]>(`/v2/users`, {
      params: {
        roles,
        search,
      },
    });
  }

  public getTeachers(): Observable<any[]> {
    return this.httpClient.get<any[]>('/v1/users/teachers');
  }

  public getUserById(userId: string): Observable<any> {
    return this.httpClient.get<any>(`/v1/users/${userId}`);
  }

  public checkUserRegistered(username: string): Observable<boolean> {
    return this.httpClient.get<boolean>(`/v1/users/username/${username}/registered`)
  }

  public checkNumberExist(phone: string): Observable<boolean> {
    return this.httpClient.get<boolean>(`/v1/users/phonenumber/${phone}/exist`)
  }

  public checkUsernameExist(username: string): Observable<boolean> {
    return this.httpClient.get<boolean>(`/v1/users/username/${username}/exist`)
  }

  public getStudents(): Observable<StudentUser[]> {
    return this.httpClient.get<StudentUser[]>(`/v1/users/students`)
  }

  public getMetaInfo(username: string): Observable<any> {
    return this.httpClient.get<any>(`/v1/users/${username}/metainfo`);
  }

  public sendRegistrationLink(userId: string): Observable<boolean> {
    return this.httpClient.post<boolean>(`/v1/users/${userId}/sendregistrationlink`, {})
  }
}
