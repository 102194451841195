import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { environment } from 'src/environment';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AvatarComponent {
  @Input() imageUrl!: string | null;
  @Input() imageSrc!: string | null;
  @Input() firstLetter!: string | null;
  @Input() size: 'small' | 'medium' | 'large' = 'medium';

  public readonly environment = environment;

  onError():void {
    this.imageUrl = null;
    this.imageSrc = null;
  }
}
