import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-attach-file',
  templateUrl: './attach-file.component.html',
  styleUrls: ['./attach-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachFileComponent {
  @Input() isOpen: boolean = false;
  @Input() courseId: string = "";
  @Output() closeEvent = new EventEmitter<void>();
  @Output() nextEvent = new EventEmitter<string[]>();

  public files: string[] = [];
  public nextStepShown: boolean = false;

  goNextStep(file: string): void {
    this.files.push(file)
    this.nextStepShown = true;
  }
  noNextStep(file: string): void {
    const index = this.files.indexOf(file);
    if (index > -1) {
      this.files.splice(index, 1);
    }
    if (this.files.length === 0) {
      this.nextStepShown = false;
    }
  }
}
