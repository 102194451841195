<div class="app-search-functionality">
    <div class="search-wrapper">
        <app-search 
            [fullWidth]="fullWidth"
            [allowTypingSpace]="allowTypingSpace" 
            (searchListener)="searchListener.emit($event)" 
            [placeholder]="placeholder"></app-search>
    </div>
    <ng-content></ng-content>
</div>
