import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeDiffToMinutesAndSeconds'
})
export class TimeDiffToMinutesAndSecondsPipe implements PipeTransform {

  transform(startDate: Date, endDate: Date): string {
    if (!startDate || !endDate || isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      return 'Invalid dates'; // Handle invalid date inputs
    }

    console.log('endDate', endDate)
    console.log('startDate', startDate)

    const timeDiffInMilliseconds = Math.abs(endDate.getTime() - startDate.getTime());
    const minutes = Math.floor(timeDiffInMilliseconds / 60000); // Convert milliseconds to minutes
    const seconds = Math.floor((timeDiffInMilliseconds % 60000) / 1000); // Convert remaining milliseconds to seconds

    return `${minutes} minutes and ${seconds} seconds`;
  }
}
