export const SUBJECTS_ID: Record<string, string> = {
  '11c81c50-c914-4030-8083-e5d4bfe6e6d0':'./assets/img/subjects/mathemathic.jpg',
  'dc37f366-6f09-41e8-a5d3-3cd925fb72db':'./assets/img/subjects/kz-language.jpg',
  '0b14d605-8adb-436d-8953-e2472d4ff048': './assets/img/subjects/physics.jpg',
  '3019bfe4-8e9e-4c9a-9059-626d6dff3d48': './assets/img/subjects/geography.jpg',
  '24beb7d0-dc5f-4e2e-a66c-c44b51af9e67': './assets/img/subjects/chemistry.jpg',
  '3a58ebfe-a668-4761-a5f4-53142a6571c1': './assets/img/subjects/biology.jpg',
  '8e0889f0-320c-405a-8d14-44fb9f396ea7': './assets/img/subjects/creative-exam.jpg', // поменять на всемирная история
  'ee08e1f3-3658-44d5-ab8b-206a5049ffc5': './assets/img/subjects/english.jpg',
  // 'f6ba7ace-19bd-4a4c-938e-682c37a00b13':
  //   './assets/img/subjects/creative-exam.jpg',
  '4e0e069a-0ff8-4664-b01d-c491a69788ee': './assets/img/subjects/russian-language.jpg',
  '58b3f11e-20cd-453a-a486-afa4cdf261f3': './assets/img/subjects/russian-literture.jpg',
  'e82b4f1e-6a1f-4b75-b622-6703e2495520': './assets/img/subjects/kz-literature.jpg',
  '6e172165-57c2-4b01-9fd1-70ccca7b96a7': './assets/img/subjects/informatics.jpg',
  '79d6a013-68f6-4829-a75f-f1618fc9c244': './assets/img/subjects/general-law.jpg',
  '2f9a8bf5-4a39-4c5f-aa32-4c7ae09521b2': './assets/img/subjects/kz-history.jpg',
  'e6d6f884-5f5a-46c0-9b5a-929051b9a3d8': './assets/img/subjects/mathematical-literacy.jpeg', // Математическая граммотность
  'aefcbf13-8928-40a5-bddb-1b5c7eac2e07': './assets/img/subjects/geometry.jpg', // геометрия
};

export const SUBJECTS_LABEL = {
  '11c81c50-c914-4030-8083-e5d4bfe6e6d0': 'Математика',
  'dc37f366-6f09-41e8-a5d3-3cd925fb72db': 'Казахский язык',
  '0b14d605-8adb-436d-8953-e2472d4ff048': 'Физика',
  '3019bfe4-8e9e-4c9a-9059-626d6dff3d48': 'География',
  '24beb7d0-dc5f-4e2e-a66c-c44b51af9e67': 'Химия',
  '3a58ebfe-a668-4761-a5f4-53142a6571c1': 'Биология',
  '8e0889f0-320c-405a-8d14-44fb9f396ea7': 'Всемирная история',
  'ee08e1f3-3658-44d5-ab8b-206a5049ffc5': 'Английский язык',
  // 'f6ba7ace-19bd-4a4c-938e-682c37a00b13': 'Творческий экзамен', // ??
  '4e0e069a-0ff8-4664-b01d-c491a69788ee': 'Русский язык',
  '58b3f11e-20cd-453a-a486-afa4cdf261f3': 'Русская литература',
  'e82b4f1e-6a1f-4b75-b622-6703e2495520': 'Казахская литература',
  '6e172165-57c2-4b01-9fd1-70ccca7b96a7': 'Информатика',
  '79d6a013-68f6-4829-a75f-f1618fc9c244': 'Основы права',
  '2f9a8bf5-4a39-4c5f-aa32-4c7ae09521b2': 'История Казахстана',
  'aefcbf13-8928-40a5-bddb-1b5c7eac2e07': 'Геометрия',
  'e6d6f884-5f5a-46c0-9b5a-929051b9a3d8': 'Математическая граммотность',
};

export const SUBJECTS_OPTIONS = [
  {
    name: 'Математика',
    value: '11c81c50-c914-4030-8083-e5d4bfe6e6d0',
  },
  {
    name: 'Казахский язык',
    value: 'dc37f366-6f09-41e8-a5d3-3cd925fb72db',
  },
  {
    name: 'Физика',
    value: '0b14d605-8adb-436d-8953-e2472d4ff048',
  },
  {
    name: 'География',
    value: '3019bfe4-8e9e-4c9a-9059-626d6dff3d48',
  },
  {
    name: 'Химия',
    value: '24beb7d0-dc5f-4e2e-a66c-c44b51af9e67',
  },
  {
    name: 'Биология',
    value: '3a58ebfe-a668-4761-a5f4-53142a6571c1',
  },

  {
    name: 'Английский язык',
    value: 'ee08e1f3-3658-44d5-ab8b-206a5049ffc5',
  },
  // {
  //   name: 'Творческий экзамен',
  //   value: 'f6ba7ace-19bd-4a4c-938e-682c37a00b13',
  // },
  {
    name: 'Русский язык',
    value: '4e0e069a-0ff8-4664-b01d-c491a69788ee',
  },
  {
    name: 'Русская литература',
    value: '58b3f11e-20cd-453a-a486-afa4cdf261f3',
  },
  {
    name: 'Казахская литература',
    value: 'e82b4f1e-6a1f-4b75-b622-6703e2495520',
  },
  {
    name: 'Информатика',
    value: '6e172165-57c2-4b01-9fd1-70ccca7b96a7',
  },
  {
    name: 'Основы права',
    value: '79d6a013-68f6-4829-a75f-f1618fc9c244',
  },
  {
    name: 'Математическая грамотность',
    value: 'e6d6f884-5f5a-46c0-9b5a-929051b9a3d8',
  },
  {
    name: 'Всемирная история',
    value: '8e0889f0-320c-405a-8d14-44fb9f396ea7',
  },
  {
    name: 'Геометрия',
    value: 'aefcbf13-8928-40a5-bddb-1b5c7eac2e07',
  },
  {
    name: 'История Казахстана',
    value: '2f9a8bf5-4a39-4c5f-aa32-4c7ae09521b2',
  },
];

