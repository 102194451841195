<form class="base" [formGroup]="baseForm">
  <app-select
    name="CHOOSE_FOLDER_FOR_TYPE_LESSON"
    placeholder="CHOOSE_FROM_LIST"
    formControlName="courseFolderId"
    [options]="foldersOptions">
  </app-select>

  <app-multi-select
    *ngIf="!data?.common"
    name="STUDENTS_ASSIGNED"
    [options]="studentsList"
    [withSearch]="true"
    [preSelectedOptions]="baseForm.get('usersIds')?.value"
    (selectedValue)="studentsSelected($event)">
  </app-multi-select>
  <app-slide-toggle text="TAKE_IN_RATING" formControlName="rating" class="note-in-rating"></app-slide-toggle>
  <app-input
    [error]="baseFormErrors.maxScore"
    name="MAX_POINTS_FOR_TASK"
    formControlName="maxScore"
    mask="9*"
    (input)="handleFormControlErrors(baseForm, baseFormErrors, 'maxScore')">
  </app-input>
  <app-date-picker
    *ngIf="isOpen"
    [error]="baseFormErrors.date"
    name="COMPLETION_DEADLINE"
    formControlName="date"
    (input)="handleFormControlErrors(baseForm, baseFormErrors, 'date')">
  </app-date-picker>
  <app-select-input
    [error]="baseFormErrors.time"
    name="DEADLINE_TIME"
    formControlName="time"
    mask="00:00"
    [options]="timeOptions">
  </app-select-input>
<!--  <app-input-->
<!--    [error]="baseFormErrors.videoUrl"-->
<!--    formControlName="videoUrl"-->
<!--    name="LINK_TO_VIDEO"-->
<!--    class="link-to-video"-->
<!--    placeholder="http://example.com"-->
<!--    (input)="handleFormControlErrors(baseForm, baseFormErrors, 'videoUrl')"-->
<!--  />-->
  <app-input
    [error]="baseFormErrors.kinescopeVideoUrl"
   (input)="handleFormControlErrors(baseForm, baseFormErrors, 'kinescopeVideoUrl')"
    formControlName="kinescopeVideoUrl"
    name="LINK_TO_VIDEO_KINESCOPE"
    class="link-to-video"
    placeholder="http://example.com"
  />
  <app-editor
    [error]="baseFormErrors.description"
    formControlName="description"
    name="descriptionAndInstructions"
    (change)="handleFormControlErrors(baseForm, baseFormErrors, 'description')">
  </app-editor>
  <div class="uploader">
    <span class="uploader-title">{{'materials' | translate}}</span>
    <div class="uploader-wrapper">
      <app-file-uploader
        [fileUrls]="baseForm.get('materials')?.value || []"
        [error]="baseFormErrors.materials"
        (removeEvent)="removed($event)"
        [courseId]="courseId"
        (downloadedEvent)="materialDownloaded($event)">
      </app-file-uploader>
    </div>
  </div>
</form>
