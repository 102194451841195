import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

import { ExpanderComponent } from './expander/expander.component';
import { DutyCardComponent } from './duty-card/duty-card.component';
import { MaterialModule } from '../material/material.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MenuDefaultComponent } from './menu-default/menu-default.component';
import { SearchFunctionalityComponent } from './search-functionality/search-functionality.component';
import { BaseModule } from '../base/base.module';
import { CourseCardComponent } from './course-card/course-card.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { PipesModule } from '../../pipes/pipes.module';
import { EditorComponent } from './editor/editor.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserFulLinksComponent } from './user-ful-links/user-ful-links.component';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { PaginationComponent } from './pagination/pagination.component';
import { TranslateModule } from "@ngx-translate/core";
import { ViewPhotoComponent } from './view-photo/view-photo.component';
import { EditorViewerComponent } from './editor-viewer/editor-viewer.component';
import { VideoPlayerModule } from './video-player/video-player.module';
import { TableGradeComponent } from './table-grade/table-grade.component';
import { AvatarComponent } from './avatar/avatar.component';
import { TabsComponent } from './tabs/tabs.component';
import { RightMenuComponent } from './right-menu/right-menu.component';
import { BlockRolePhoneInstaInfoComponent } from './block-role-phone-insta-info/block-role-phone-insta-info.component';
import { ActivatedCardComponent } from './activated-card/activated-card.component';
import { PaymentInfoComponent } from './payment-info/payment-info.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AccordionComponent } from './accordion/accordion.component';
import { EmptyMessageComponent } from './empty-message/empty-message.component';
import { SidebarItemComponent } from './sidebar-item/sidebar-item.component';
import { RouterModule } from '@angular/router';
import { VideoPlayerDialogComponent } from './video-player-dialog/video-player-dialog.component';
import { ClosestDeadlineComponent } from './closest-deadline/closest-deadline.component';
import { PersonalInfoEditorComponent } from './personal-info-editor/personal-info-editor.component';
import { EventsAnnoucementsControllerComponent } from './events-annoucements-controller/events-annoucements-controller.component';
import { TranslationSelectComponent } from './translation-select/translation-select.component';
import { InfoTooltipComponent } from "./info-tooltip/info-tooltip.component";
import { ActiveCourseComponent } from './active-course/active-course.component';
import { SelectTownComponent } from './select-town/select-town.component';
import { AnnouncementsFilterComponent } from './announcements-filter/announcements-filter.component';
import { ContactInfoEditorComponent } from "./contact-info-editor/contact-info-editor.component";
import { AppStoreLinksComponent } from './app-store-links/app-store-links.component';
import { PdfFileViewerComponent } from './pdf-file-viewer/pdf-file-viewer.component';
import { FoldersComponent } from './folders/folders.component';
import { CdkDrag, DragDropModule, CdkDragPreview } from '@angular/cdk/drag-drop';
import { AdditionalMaterialsControllerComponent } from './additional-materials-controller/additional-materials-controller.component';
import { LessonStatusImageComponent } from 'src/app/shared/components/non-base/lesson-status-image/lesson-status-image.component';
import { AnnouncementsAlertComponent } from './announcements-alert/announcements-alert.component';
import { AggrementViewerComponent } from './aggrement-viewer/aggrement-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { QuestionnaireViewControllerModule } from './questionnaire-view-controller/questionnaire-view-controller.module';
import { CircularProgressComponent } from "./circular-progress/circular-progress.component";


@NgModule({
  declarations: [
    ExpanderComponent,
    DutyCardComponent,
    SidebarComponent,
    MenuDefaultComponent,
    SearchFunctionalityComponent,
    CourseCardComponent,
    FileUploaderComponent,
    EditorComponent,
    UserFulLinksComponent,
    ImageUploaderComponent,
    PaginationComponent,
    ViewPhotoComponent,
    EditorViewerComponent,
    TableGradeComponent,
    AvatarComponent,
    TabsComponent,
    RightMenuComponent,
    BlockRolePhoneInstaInfoComponent,
    ActivatedCardComponent,
    PaymentInfoComponent,
    AccordionComponent,
    EmptyMessageComponent,
    SidebarItemComponent,
    VideoPlayerDialogComponent,
    ClosestDeadlineComponent,
    PersonalInfoEditorComponent,
    EventsAnnoucementsControllerComponent,
    TranslationSelectComponent,
    InfoTooltipComponent,
    ActiveCourseComponent,
    SelectTownComponent,
    AnnouncementsFilterComponent,
    ContactInfoEditorComponent,
    AppStoreLinksComponent,
    PdfFileViewerComponent,
    FoldersComponent,
    AdditionalMaterialsControllerComponent,
    LessonStatusImageComponent,
    AnnouncementsAlertComponent,
    AggrementViewerComponent,
    CircularProgressComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    MatMenuModule,
    MatButtonModule,
    BaseModule,
    PipesModule,
    CKEditorModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    VideoPlayerModule,
    MatSlideToggleModule,
    RouterModule,
    PdfViewerModule,
    CdkDrag,
    DragDropModule,
    CdkDragPreview,
    QuestionnaireViewControllerModule
  ],
  exports: [
    ExpanderComponent,
    DutyCardComponent,
    SidebarComponent,
    CourseCardComponent,
    MenuDefaultComponent,
    SearchFunctionalityComponent,
    FileUploaderComponent,
    EditorComponent,
    UserFulLinksComponent,
    ImageUploaderComponent,
    PaginationComponent,
    ViewPhotoComponent,
    EditorViewerComponent,
    VideoPlayerModule,
    TableGradeComponent,
    AvatarComponent,
    TabsComponent,
    RightMenuComponent,
    BlockRolePhoneInstaInfoComponent,
    ActivatedCardComponent,
    PaymentInfoComponent,
    AccordionComponent,
    EmptyMessageComponent,
    SidebarItemComponent,
    VideoPlayerDialogComponent,
    ClosestDeadlineComponent,
    PersonalInfoEditorComponent,
    EventsAnnoucementsControllerComponent,
    TranslationSelectComponent,
    InfoTooltipComponent,
    ActiveCourseComponent,
    SelectTownComponent,
    AnnouncementsFilterComponent,
    AppStoreLinksComponent,
    PdfFileViewerComponent,
    FoldersComponent,
    AdditionalMaterialsControllerComponent,
    LessonStatusImageComponent,
    AnnouncementsAlertComponent,
    AggrementViewerComponent,
    QuestionnaireViewControllerModule
  ],
})
export class NonBaseModule {}
