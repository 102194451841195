import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FilesService } from 'src/app/core/services/files/files.service';
import { ToastService } from 'src/app/shared/services/toast/toast.service';
import { environment } from 'src/environment';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageUploaderComponent implements OnInit {
  @ViewChild('fileInput') inputFile!: ElementRef<HTMLImageElement>;
  @Input() name: string = '';
  @Output() onFileSelect: EventEmitter<any> = new EventEmitter();
  @Input() imageId: string = '';

  @Input() imageSrc: string = '';

  constructor(private _fs: FilesService, private _ns: ToastService) {}
  ngOnInit(): void {
    console.log(this.imageId, this.imageSrc )
  }

  public onOpenInput(): void {
    this.inputFile.nativeElement.click();
  }

  public onFileInput(event: any): void {
    const file = event.target.files[0];
    const allowedFormat = ['jpg', 'jpeg', 'png'];
    const tempArr = file.name.split('.');
    const format = tempArr[tempArr.length - 1];

    if (allowedFormat.includes(format)) {
      const fd = new FormData();
      fd.append('material', file, file.name);

      this._fs.sendFiles(fd).subscribe({
        next: (response) => {
          this.imageId = response.fileId;
          this.imageSrc = `${environment.filesUrl}${this.imageId}`;
          this.onFileSelect.emit(response.fileId);
        },
      });
    } else {
      this._ns.showError('Можно загрузить только картинки');
    }
  }
}
