<div
  class="app-search"
  [class.secondary]="isSecondary"
  [ngClass]="{
    small: searchSize.Small === size,
    medium: searchSize.Medium === size,
    default: searchSize.Defaul === size,
    'w-100': fullWidth,
    'w-50' : width50
  }"
>
  <img class="icon" src="assets/svg/search-normal.svg" alt=""/>
  <input
    [placeholder]="(placeholder || 'search') | translate"
    [formControl]="searchText"
    (keydown.space)="onSpaceKeydown($event)">
<!--    (keydown.space)="allowTypingSpace ? '' : $event.preventDefault()"/>-->
</div>
