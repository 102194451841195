<div class="student-side-bar-item">
  <app-expander [isExpanded]="isOpen">
    <button class="toggler" (click)="toggle()" button>
      <div class="left">
        <ng-container *ngIf="isFutureTheme(); else checkMarkOrProgress">
          <img class="status" src="assets/svg/circles/lock-circle.svg" alt=""/>
        </ng-container>

        <ng-template #checkMarkOrProgress>
          <ng-container *ngIf="data.value === 100; else progressSpinner">
            <img class="status" src="assets/svg/circles/checkmark-circle.svg" alt=""/>
          </ng-container>

          <ng-template #progressSpinner>
            <div *ngIf="data.value > 0 && data.value <= 99" class="status">
              <mat-progress-spinner
                class="example-margin"
                [color]="color"
                [mode]="mode"
                [value]="data.value"
                [diameter]="diameter">
              </mat-progress-spinner>
            </div>

            <img *ngIf="!data.value" class="status" src="assets/svg/circles/zero-status-circle.svg" alt=""/>
          </ng-template>
        </ng-template>

        <span class="name">{{ data.themeName }}</span>
      </div>

      <button class="arrow">
        <app-primary-arrow [initialSlope]="90" [slopeDegree]="90"
                           [openColor]="innerWidth < 999 ? 'rgba(50, 56, 74, 1)' : 'white'"
                           [isOpen]=" isOpen"></app-primary-arrow>
      </button>
    </button>
    <nav class="navigation" content>
      <ul class="list">
        <li class="item">
          <div class="buttons" *ngFor="let item of data?.lessons;let last = last;">
            <button class="button" [ngClass]="{'button-last': last, 'button-active': item.active}"
              *ngIf="lessonType.Lecture === item.lessonType"
              (click)="navigateToTheory(item.lessonId)">
              <app-lesson-status-image [active]="item.active" [type]="item.lessonType" [status]="item.progressStatus"></app-lesson-status-image>
              <span>{{item.lessonName}}</span>
            </button>
            <button class="button" [ngClass]="{'button-last': last, 'button-active': item.active}"
              *ngIf="lessonType.Text === item.lessonType"
              (click)="navigateToText(item.lessonId)">
              <app-lesson-status-image [active]="item.active" [type]="item.lessonType" [status]="item.progressStatus"></app-lesson-status-image>
              <span>{{item.lessonName}}</span>
            </button>
            <button class="button" [ngClass]="{'button-last': last, 'button-active': item.active}"
              *ngIf="lessonType.Homework === item.lessonType"
              (click)="navigateToHomework(item.lessonId)">
              <app-lesson-status-image [active]="item.active" [type]="item.lessonType" [status]="item.progressStatus"></app-lesson-status-image>
              <span>{{item.lessonName}}</span>
            </button>
            <button class="button" [ngClass]="{'button-last': last, 'button-active': item.active}"
              *ngIf="lessonType.WorkOnMistakes === item.lessonType"
              (click)="navigateToWorkOnMistakes(item.lessonId)">
              <app-lesson-status-image [active]="item.active"  [type]="item.lessonType" [status]="item.progressStatus"></app-lesson-status-image>
              <span>{{item.lessonName}}</span>
            </button>
            <button class="button" [ngClass]="{'button-last': last, 'button-active': item.active}"
                    *ngIf="lessonType.Quiz === item.lessonType"
                    (click)="navigateToTest(item.lessonId, data.value)">
              <app-lesson-status-image [active]="item.active" [type]="item.lessonType" [status]="item.progressStatus"></app-lesson-status-image>
              <span>{{item.lessonName}}</span>
            </button>
          </div>
        </li>
      </ul>
    </nav>
  </app-expander>
</div>
