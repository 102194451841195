import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { TestQuestionCustom, TestQuestionDTO } from 'src/app/core/model';
import { getFormControlErrors } from 'src/app/shared/validators';
import {ToastService} from "../../../../../../../shared/services/toast/toast.service";
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'app-test-test-controller',
  templateUrl: './test-test-controller.component.html',
  styleUrls: ['./test-test-controller.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TestTestControllerComponent implements OnInit, OnDestroy {
  @ViewChild('questionImageUploader') questionImageUploader!: ElementRef<HTMLInputElement>;
  @ViewChild('variantImageUploader') variantImageUploader!: ElementRef<HTMLInputElement>;

  @Input() item!: TestQuestionDTO;
  @Output() removeEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() saveEvent: EventEmitter<TestQuestionCustom> = new EventEmitter<TestQuestionCustom>();
  @Output() validQuestionStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

  private eventsSubscription!: Subscription;
  @Input() saveEventSubject!: Observable<number>;

  @Input() index!: number;
  @Input() withQuestionText: boolean = true;

  public form!: FormGroup;
  public formErrors = {
    questionText: "",
  }
  public errorVariantText: string[] = [];
  public questionImageUrl: string = '/assets/svg/image-upload.svg';

  public minimumAmoutOfVariants: number = 3;

  handleFormControlErrors(form: FormGroup, error: any, controlName: string): void {
    getFormControlErrors(form, error, controlName, this.translateService);
    this.cdr.detectChanges();
  }

  constructor(
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private toastService: ToastService,
    private translateService: TranslateService,
    ) {
    this.form = formBuilder.group({
      questionText: ['', Validators.required],
      shuffle: [true],
      questionImageUrl: [],
      answerList: formBuilder.array([])
    });

    this.form.valueChanges.subscribe(res => {
      // console.log(this.formErrors.questionText)
    })
  }

  ngOnInit(): void {
    if(this.saveEventSubject) {
      this.eventsSubscription = this.saveEventSubject.subscribe((index: number) => {
        if(index === this.index) {
          this.form.patchValue({questionImageUrl:
            this.form.get('questionImageUrl')?.value === this.questionImageUrl ? "" : this.form.get('questionImageUrl')?.value})
          this.saveEvent.emit({ isValid: this.form.valid, ...this.form.value });
        }
      });
    };
    if(this.item) {
      this.form.reset();

      this.form.patchValue({ questionImageUrl: this.item.questionImageUrl });
      this.form.patchValue({ questionText: this.item.questionText })
      this.form.patchValue({ shuffle: this.item.shuffle });
      this.form.patchValue({ isSaved: true });

      if(this.item.answerList && this.item.answerList.length > 0) {
        for(let item of this.item.answerList) {
          this.answerList().push(this.formBuilder.group({
            id: item.id,
            answerImageUrl: item.answerImageUrl,
            answerText: item.answerText,
            correct: item.correct
          }));
          this.errorVariantText.push("");
        };
        this.validQuestionStatus.emit(true);
      }
      else {
        this.formErrors.questionText = "REQUIRED_FIELD";
        for(let i = 0; i < this.minimumAmoutOfVariants; i++) {
          this.addAnswerItem();
        }
      };

      this.answerList().valueChanges.subscribe(() => {

        // check one emptyness of each variant text
        if(this.answerList().value.some((item: any) => !item['answerText'])) {
          for(let i = 0; i < this.answerList().length; i++) {
            if(this.answerList().at(i).get('answerText')?.value) {
              this.errorVariantText[i] = "";
            }
            else {
              this.errorVariantText[i] = "TYPE_TEXT_OF_ANSWER";
            }
          }
        }
        //if no one correct answer is checked
        else if(!this.answerList().value.some((item: any) => item['correct'])) {
          for (let i = 0; i < this.errorVariantText.length; i++) {
            this.errorVariantText[i] = "CHOOSE_RIGHT_ANSWER";
          }
        }
        //if one image picked
        else
        if(this.answerList().value.some((item: any) => item['answerImageUrl'])) {
          for (let i = 0; i < this.errorVariantText.length; i++) {
            if(this.answerList().at(i).get('answerImageUrl')?.value) {
              this.errorVariantText[i] = "";
            }
            else {
              this.errorVariantText[i] = "CHOOSE_IMG"
            }
          }
        }
        else {
          for (let i = 0; i < this.errorVariantText.length; i++) {
            this.errorVariantText[i] = "";
          }
        };

        const anyCustomError: boolean =
          this.errorVariantText.every(element => element === "");
        this.validQuestionStatus.emit((this.form.status === 'VALID' && anyCustomError) ? true : false);
      });
    }
  };

  ngOnDestroy(): void {
    this.eventsSubscription.unsubscribe();
  }

  public answerList(): FormArray {
    return this.form.controls['answerList'] as FormArray;
  }
  public addAnswerItem(): void {
    const answerItemFormGroup = this.formBuilder.group({
      answerImageUrl: '',
      answerText: '',
      correct: false
    });

    this.errorVariantText.push("TYPE_TEXT_OF_ANSWER");
    this.answerList().push(answerItemFormGroup);
  }
  public removeAnswerItem(index: number): void {
    if(this.answerList().length <= this.minimumAmoutOfVariants) {
      this.toastService.showError(`Минимальное количество вариантов - ${this.minimumAmoutOfVariants}`)
      return;
    };
    const answerListFormArray = this.answerList();
    answerListFormArray.removeAt(index);

    this.errorVariantText.splice(index, 1)
  }

  public handleQuestionImageInput(event: Event): void {
    const imageElement = event.target as HTMLInputElement;
    if(imageElement.files && imageElement.files.length > 0) {
      const file = imageElement.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        this.form.patchValue({questionImageUrl: reader.result});
        this.cdr.detectChanges();
      }
      reader.readAsDataURL(file);
    }
  }
  public handleAnswerItemImageUpload(answerItemIndex: number, event: Event): void {
    const imageElement = event.target as HTMLInputElement;
    if (imageElement.files && imageElement.files.length > 0) {
      const file = imageElement.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        const answerListFormGroup = this.answerList().at(answerItemIndex) as FormGroup;
        answerListFormGroup.patchValue({ answerImageUrl: reader.result });
        this.cdr.detectChanges();
      };
      reader.readAsDataURL(file);
    }
  }
}
