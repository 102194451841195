import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import {
  ICatalogue,
  IGroupCourseMonthPrice,
} from 'src/app/core/model/interface/catalogues';
import { CataloguesService } from 'src/app/core/services/catalogues/catalogues.service';
import { SUBJECTS_ID } from 'src/app/shared/models/subjects';

@Component({
  selector: 'app-cart-modal-card-active',
  templateUrl: './cart-modal-card-active.component.html',
  styleUrls: ['./cart-modal-card-active.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CartModalCardActiveComponent {
  @Input() card!: any;
  @Input() checked: boolean = false;

  @Output() onCheckClick: EventEmitter<any> = new EventEmitter();

  public price: number = 32000;

  public subjectsId = SUBJECTS_ID;


  public p70k = [
    "ccf1b889-c284-47b5-a056-7a655135e98a",
    "d3a3e890-58cd-4148-ae98-1b48f8933c92",
    "8719d23a-fbea-4436-b918-3671018e1c3e",
    "3248e108-06a4-4d4c-aa6b-56c253d0090e",
    "6a6b4e68-dd3b-419d-ae2a-101c76857c36",
    "27ac2b87-d628-49f6-a5ad-a79d0452bf2c",
    "db6a54fb-8c63-48c2-b71f-1042d437c732",
    "adae9e6c-05e5-4119-9afd-b436ab164c40",
    "30bde7fd-0cd1-4a88-8a48-63eb4fe4a38f",
    "f2845346-c07c-4a17-8d77-00dbd729ac83",
    "4d999883-0cb7-435c-a240-0be9016594ef",
    "536ecde8-2af8-4d20-8d62-7cd831b15f5a",
    "fd2046da-448d-428f-9a56-5b9855b2a573",
    "ab3c29de-dac9-4ba3-b268-59372ede0dd2",
    "34884638-e59c-4225-9ea5-4b87e43fbf89",
    "c819836e-73ed-4154-92f9-54a96a1d9e4b",
    "4ba64454-bacf-46e5-85d2-f9f802cbf760",
    "bd504325-b1c7-4296-9088-aa9b447dd4ec",
    "5e2e0268-f023-478b-924a-85a21a3e07a2",
    "15535607-3d0e-48c6-bc67-7204664521da",
    "54319446-39e1-461b-9904-ed352033266c",
    "8d6ec86c-0b05-48b1-8586-dd33183169c1",
    "31b65925-2606-46b2-b9b2-fce232ba6b1f",
    "d9dbd307-3c26-4f22-b007-b597738a0c21",
    "59d784dd-3b93-458e-8ec2-04edbcc1aa4c",
    "ba7a1248-907e-43bb-827b-41fb5af89de2",
    "2962b0e6-a7c3-4aae-9af4-ae2b88ffc5a2",
    "f86cc706-7546-41f8-9565-681e2d96264f",
    "d89f0f26-2bdd-4c26-83c0-ac32a4e28052",
    "d89f0f26-2bdd-4c26-83c0-ac32a4e28052",
    "ce765669-86cd-4ed3-82ed-841014b44b94",
    "97a32b70-d0ba-49c4-8767-3478cf4ec155",
    "0efa8970-a88a-4205-83b5-ef68c0f802bf",
    "3a9dee6d-87a0-4750-9b46-c23892ed3cee",
    "6c7829b7-abaa-46f6-93a2-0fbf3dee615f",
    "177ec987-bce0-4d13-943b-98e9cf30a31a",
    "1c74aa7e-6eb8-4679-a2c9-07fceb79d2be",
    "3ef5134b-80b5-44fa-915b-ee41fd1e774d",
    "b513dc97-02d0-4e71-8dc6-b74711fdd627",
    "6fb0f68d-230c-441a-88fd-ab8707c1aa7c",
    "e7c4bbc7-7020-4493-930c-7eaa9d179d5a",
    "a9ab2e3e-dd32-4bb9-8840-b4532d3cce42",
    "f9bae0d6-ccd7-4fa5-98f9-ace85e54f612",
    "a2f11a6a-720e-47ac-a819-12c4cb14c5a7"
];

  public p52500 = [
    'fd2046da-448d-428f-9a56-5b9855b2a573',
    'ab3c29de-dac9-4ba3-b268-59372ede0dd2',
    'c819836e-73ed-4154-92f9-54a96a1d9e4b',
    '34884638-e59c-4225-9ea5-4b87e43fbf89'
  ]
  constructor(private _catalogueService: CataloguesService) {}

  ngOnInit(): void {
    if(this.p70k.includes(this.card.streamId)) {
      this.price = 70000
    }

    if(this.p52500.includes(this.card.streamId)) {
      this.price = 52500
    }
  }
  public removeItemFromCart(card: ICatalogue): void {
    this._catalogueService.removeCardFromCart(card);
  }

  public toggleChecked(id: string): void {
    this.onCheckClick.emit(id);
  }
}
