<div class="comment">
    <div class="title">Арманов Бейсенбин <span class="position">(Куратор)</span></div>
    <div class="date-time">5 апреля 2023, 08:54</div>


    <div class="description">
      <div class="text">Чуть не успел. Прошу помиловать</div>
      <div class="grade radius-6 flex items-center">
        <img src="./assets/svg/circles/checkmark-circle.svg" alt="" class="icon">
        <span class="label">Оценка:</span>
        <span class="value">20</span>
      </div>
    </div>
  </div>