import { EMPTY, catchError, map, mergeMap, tap } from 'rxjs';
import { StudentService } from './../../../services/student/student.service';
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { getGroupAction, getGroupActionSuccess, getGroupsAction, getGroupsActionSuccess } from './groups.actions';

@Injectable()
export class GroupsEffects {
    loadGroups$ = createEffect(() => this.actions$.pipe(
        ofType(getGroupsAction),
        mergeMap(() => this.studentService.getStudentGroups3()
        .pipe(
            map(groupsRes => getGroupsActionSuccess({ payload: groupsRes })),
            catchError(() => EMPTY)
        ))
    ));

    constructor(
        private actions$: Actions,
        private studentService: StudentService
    ) {}
}

@Injectable()
export class GroupEffects {
    loadGroups$ = createEffect(() => this.actions$.pipe(
        ofType(getGroupAction),
        mergeMap(action => {
            const groupId = action.groupId;
            const direction = action.direction;
            return this.studentService.getStudentGroupById(groupId, direction)
            .pipe(
                map(groupsRes => getGroupActionSuccess({ payload: groupsRes })),
                catchError(() => EMPTY)
            )
        }
    )
    ));

    constructor(
        private actions$: Actions,
        private studentService: StudentService
    ) {}
}
