import { Component, OnInit } from '@angular/core';
import { QuestionnaireTypes } from 'src/app/core/model/interface/questionnaire';

@Component({
  selector: 'app-questionnaire-passing',
  templateUrl: './questionnaire-passing.component.html',
  styleUrls: ['./questionnaire-passing.component.scss']
})
export class QuestionnairePassingComponent implements OnInit {
  public readonly QuestionnaireTypes = QuestionnaireTypes;
  constructor() { }

  ngOnInit() {
  }

}
