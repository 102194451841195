import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeDiffToDays'
})
export class TimeDiffToDaysPipe implements PipeTransform {
  transform(timeDiffInMilliseconds: number | null): number | null {
    if(!timeDiffInMilliseconds) {
      return null;
    }
    const millisecondsInOneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in one day
    const days = Math.floor(timeDiffInMilliseconds / millisecondsInOneDay);
    
    return days;
  }
}
