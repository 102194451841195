import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Dialog } from '@angular/cdk/dialog';

@Component({
  selector: 'app-cdk-dialog',
  templateUrl: './cdk-dialog.component.html',
  styleUrls: ['./cdk-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CdkDialogComponent implements OnInit, OnChanges {
  @ViewChild('myTemplate') customTemplate!: TemplateRef<any>;

  @Input() isOpen!: boolean;
  @Input() title!: string;

  constructor(public dialog: Dialog) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  ngOnInit(): void {
    if (this.isOpen) {
      this.dialog.open(this.customTemplate, { disableClose: true });
    } else {
      this.dialog.closeAll();
    }
  }
}
