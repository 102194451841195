<div class="main flex flex-col">
  <div class="billboard flex justify-between">
    <div class="wrapper flex border radius-16">
      <div class="description h-100 flex flex-col justify-between radius-16">
        <div class="flex flex-col">
          <h1 class="description__title">{{ course.name }}</h1>
          <div class="description__text">
            {{ course.description }}
          </div>
        </div>
        <div class="description__date-time flex items-center">
          <ng-container *ngIf="course.duration">
            {{ "trainingDuration" | translate }}:
            {{ course.duration }}
            <ng-container *ngIf="course.duration === 1">
              {{ "durationMonth" | translate }}
            </ng-container>
            <ng-container *ngIf="course.duration > 1 && course.duration < 5">
              {{ "durationMonths" | translate }}
            </ng-container>
            <ng-container *ngIf="course.duration >= 5">
              {{ "durationMonth5" | translate }}
            </ng-container>
            <div class="circle"></div>
          </ng-container>
          {{ "trainingStart" | translate }}:
          {{ course.startDate | datepipe : "dd MMMM" }}
        </div>
      </div>
      <div class="image radius-16">
        <img [src]="subjectsId[course.subjectId]" alt="" />
      </div>
    </div>

    <div class="price-info radius-16 border flex flex-col justify-between">
      <div class="flex flex-col">
        <div class="price-info_old flex items-center" *ngIf="course.oldPrice">
          <div class="value">{{ course.oldPrice | number }} ₸</div>
          <div class="discount radius-6">
            -{{
              (course.oldPrice - course.price) / (course.oldPrice / 100)
                | number : "1.0-0"
            }}%
          </div>
        </div>
        <div class="price-info_new">{{ course.price | number }} ₸</div>
        <ul class="list flex flex-col">
          <li class="item">
            {{ "secondCoursePurchaseDiscountAvailable" | translate }}
          </li>
          <!-- <li class="item">Возможно оплаты с Kaspi RED</li> -->
        </ul>
      </div>
      <button class="primary-button button-cart radius-8" (click)="addToCart(course)">
        {{ "addToCart" | translate }}
      </button>
    </div>
  </div>
  <div class="content flex flex-col">
    <div class="video radius-16 border" *ngIf="course.videoUrl">
      <div class="video__title">Видеообзор</div>

      <div class="video__player radius-16">
        <app-video-player [videoUrl]="course.videoUrl"></app-video-player>
    </div>

    <div
      class="teacher-block p-24 radius-16 bg-white flex justify-between border"
    >
      <div class="description flex flex-col justify-center">
        <div class="title" [innerHTML]="'courseTeacher' | translate"></div>
        <div class="text">
          {{ course.streamInfo?.teacherDescription }}
        </div>
      </div>
      <div class="teacher radius-16 p-24 flex flex-col items-center">
        <div class="avatar">
          <img
            [src]="
              course.streamInfo?.teacherImageUrl
                ? getImageUrl(course.streamInfo!.teacherImageUrl)
                : './assets/img/default-ava.webp'
            "
            alt=""
          />
        </div>
        <div class="name">{{ course.streamInfo?.teacher }}</div>
        <div class="position">
          {{ course.streamInfo?.teacherRegalia }}
        </div>
      </div>
    </div>

    <div
      class="reviews border p-24 bg-white radius-16"
      *ngIf="course.streamInfo && course.streamInfo.streamComments.length > 0"
    >
      <div class="title" [innerHTML]="'courseReview' | translate"></div>
      <div
        class="cards cards-review"
        (finishScroll)="onFinishScroll()"
        [ngxConveyer]="{ horizontal: true }"
        #conveyer="ngxConveyer"
        #cards
      >
        <div
          class="review-card p-24 border radius-16"
          *ngFor="let comment of course.streamInfo?.streamComments;let i=index;"
          [ngClass]="{'review-card--show': shows[i]}"
          #singleCard
        >
          <div class="header flex items-center">
            <div class="avatar">
              <img
                [src]="
                  comment.commenterImageUrl
                    ? getImageUrl(comment.commenterImageUrl)
                    : './assets/img/default-ava.webp'
                "
                alt=""
              />
            </div>
            <div class="wrapper">
              <div class="name">{{ comment.commenter }}</div>
              <div class="date">
                {{ comment.commentDate | date : "dd.MM.yyyy" }}
              </div>
            </div>
          </div>
          <div #content [class.show]="shows[i]" [ngClass]="{'text--show': shows[i]}" class="text">
            {{ comment.commentText }}
          </div>
          <button *ngIf="shows[i] || (content.scrollHeight > content.clientHeight)" class="show-button"
            (click)="calculateTallestDivHeight(i)">{{ !shows[i] ? ('open' | translate) : ('hide' | translate) }}</button>
        </div>
      </div>

      <div class="footer flex">
        <div class="scroll">
          <div class="value" #line></div>
        </div>
        <div class="btns flex">
          <button
            class="btn btn-left flex items-center justify-center"
            (click)="prev()"
          >
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.04346 11L1.04346 6L6.04346 1"
                stroke="#32384A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button
            class="btn btn-right flex items-center justify-center"
            (click)="next()"
          >
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.04346 11L6.04346 6L1.04346 1"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <div class="content-block bg-white p-24 radius-16">
      <div class="title" [innerHTML]="'courseContent' | translate"></div>

      <div class="tags flex justify-between">
        <div
          class="tag flex items-center"
          *ngIf="
            course.streamInfo &&
            course.streamInfo.streamCourseInfo &&
            course.streamInfo.streamCourseInfo.videoLessonCount
          "
        >
          <div class="tag__value">
            {{ course.streamInfo.streamCourseInfo.videoLessonCount }}
          </div>
          <div class="tag__label">{{ "videoLessons" | translate }}</div>
        </div>
        <div
          class="tag flex items-center"
          *ngIf="
            course.streamInfo &&
            course.streamInfo.streamCourseInfo &&
            course.streamInfo.streamCourseInfo.moduleCount
          "
        >
          <div class="tag__value">
            {{ course.streamInfo.streamCourseInfo.moduleCount }}
          </div>
          <div class="tag__label">{{ "modules" | translate }}</div>
        </div>
        <div
          class="tag flex items-center"
          *ngIf="
            course.streamInfo &&
            course.streamInfo.streamCourseInfo &&
            course.streamInfo.streamCourseInfo.moduleCount
          "
        >
          <div class="tag__value">
            {{ course.streamInfo.streamCourseInfo.testCount }}
          </div>
          <div class="tag__label">{{ "thematicTests" | translate }}</div>
        </div>
      </div>

      <div
        class="list"
        *ngIf="
          course.streamInfo &&
          course.streamInfo?.streamCourseInfo &&
          (course.streamInfo?.streamCourseInfo)!.streamThemeInfos.length > 0
        "
      >
        <app-accordion
          *ngFor="
            let info of (course.streamInfo?.streamCourseInfo)!.streamThemeInfos
          "
          [label]="info.name"
          [text]="info.description"
        ></app-accordion>
      </div>
    </div>
  </div>
</div>
