<div class="progress-circle flex justify-center items-center">
  <svg width="80" height="80">
    <!-- Background circle -->
    <circle
      cx="40"
      cy="40"
      r="35"
      fill="none"
      stroke="#E8E9EE"
      stroke-width="4"
    ></circle>

    <!-- Progress circle -->
    <circle
      cx="40"
      cy="40"
      r="35"
      fill="none"
      stroke="#285B6E"
    stroke-width="4"
    stroke-linecap="round"
    [style.strokeDasharray]="circumference"
    [style.strokeDashoffset]="strokeDashoffset"
    transform="rotate(-90 40 40)"
    ></circle>
  </svg>

  <!-- Percentage text in the center -->
  <div class="percentage-text">{{ percentage }}%</div>
</div>

<p class="upload-text">{{ 'UPLOADING_FILE' | translate }}</p>

<div class="flex justify-center">
  <app-button
    (onButtonClick)="handleClick()"
    [type]="ButtonTypes.Outline"
    [size]="ButtonSize.Small"
    text="cancel"/>
</div>
