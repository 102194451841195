import { BaseModule } from './../base/base.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { DialogComponent } from './dialog/dialog.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SlideToggleComponent } from './slide-toggle/slide-toggle.component';
import { CdkDialogComponent } from './cdk-dialog/cdk-dialog.component';
import { DialogModule } from '@angular/cdk/dialog';
import { CdkSelectComponent } from './cdk-select/cdk-select.component';
import { TranslateModule } from "@ngx-translate/core";
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PageProgressSpinnerComponent } from './page-progress-spinner/page-progress-spinner.component';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [
    ProgressBarComponent,
    DialogComponent,
    MultiSelectComponent,
    SlideToggleComponent,
    CdkDialogComponent,
    CdkSelectComponent,
    ProgressSpinnerComponent,
    PageProgressSpinnerComponent,
  ],
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatDialogModule,
    MatSelectModule,
    BaseModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    DialogModule,
    TranslateModule,
    MatProgressSpinnerModule,
    ScrollingModule
  ],
  exports: [
    ProgressBarComponent,
    DialogComponent,
    MultiSelectComponent,
    SlideToggleComponent,
    CdkDialogComponent,
    CdkSelectComponent,
    ProgressSpinnerComponent,
    PageProgressSpinnerComponent
  ],
})
export class MaterialModule {}
