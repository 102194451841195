import { ChangeDetectionStrategy, Component, Inject, OnChanges, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-order-dialog',
  templateUrl: './order-dialog.component.html',
  styleUrls: ['./order-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

}
