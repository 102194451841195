import { ForAnswerTestQuestions, QuizState, UserAnswer } from 'src/app/core/model';
import { QuestionsAmount } from './../../../../../shared/models/enums/questions.enum';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { debounceTime, fromEvent } from 'rxjs';

@Component({
  selector: 'app-type-words',
  templateUrl: './type-words.component.html',
  styleUrls: ['./type-words.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TypeWordsComponent implements OnInit, AfterViewInit {
  @ViewChild('textarea') textarea!: ElementRef;

  public readonly _QuizState = QuizState;
  @Input() quizState: QuizState = QuizState.Passing;
  @Input() question!: ForAnswerTestQuestions;
  @Input() readOnly: boolean = false;
  @Output() answeredEvent: EventEmitter<{ questionId: string, answer: UserAnswer[]}> = new EventEmitter<{ questionId: string, answer: UserAnswer[]}>();

  public userAnswer: string = "";
  public correctAnswer: string = "";
  public questionsAmount: QuestionsAmount = QuestionsAmount.One;


  ngOnInit(): void {
    if(this.question && this.question.userAnswers && this.question.userAnswers[0].answerTextValue) {
      this.userAnswer = this.question.userAnswers[0].answerTextValue;
    }
  };

  ngAfterViewInit(): void {
    fromEvent(this.textarea.nativeElement, 'input').pipe(
      debounceTime(500)
    ).subscribe(() => {
      this.answeredEvent.emit({questionId: this.question.id, answer: [{ answerTextValue: this.userAnswer.toString().trim()}]})
    })
  };

}
