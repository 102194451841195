import { createReducer, on } from "@ngrx/store";
import { getProgressAction, getProgressActionSuccess, getProgressActionFailure, answerQuestion, checkAllQuestionAnswered, setQuizState, finishQuiz, finishQuizSuccess, startQuiz, startQuizSuccess, resetProgress, updateProgressOfLesson } from "./progress.actions";
import { ProgressDTO, QuestionPassingDto, QuizState } from "src/app/core/model";

export interface ProgressState {
    progressList: ProgressDTO[];
    loading: boolean;
    error: any;
    answeredToAllQuestions: boolean,
    quizState: QuizState,
    currentQuestion: QuestionPassingDto | null | undefined;
}

export const initialState: ProgressState = {
    progressList: [],
    loading: false,
    error: null,
    answeredToAllQuestions: false,
    quizState: QuizState.Passing,
    currentQuestion: undefined,
};

export const progressReducer = createReducer(
  initialState,
  on(resetProgress, () => initialState),
  on(getProgressAction, (state) => {
      return {
          ...state,
          loading: true,
          error: null,
      };
  }),
  on(getProgressActionSuccess, (state, { progressList }) => {
      return {
          ...state,
          progressList: progressList,
          loading: false,
          error: null,
      };
  }),
  on(getProgressActionFailure, (state, { error }) => {
      return {
          ...state,
          loading: false,
          error: error,
      };
  }),
  on(answerQuestion, (state, { progressId, questionId, answer }) => {

    const updatedProgress = state.progressList.map(progress => {
      if (progress.id === progressId) {
        return {
          ...progress,
          questionPassingDtos: progress.questionPassingDtos.map(item =>
            item.questionId === questionId ? { ...item, userAnswers: answer } : item
          ),
        };
      } else {
        return progress;
      }
    });
    return {
      ...state,
      progressList: updatedProgress,
    };
  }),
  on(checkAllQuestionAnswered, (state, { progressId }) => {
    const progress = state.progressList.find(item => item.id === progressId);
    const answeredToAllQuestions =
      progress?.questionPassingDtos?.every(item => item.userAnswers && item.userAnswers.length > 0) ?? false;
    return {
      ...state,
      answeredToAllQuestions: answeredToAllQuestions,
      quizState: answeredToAllQuestions ? QuizState.Passing : QuizState.Attention
    };
  }),

  on(setQuizState, (state, { quizState }) => {
    return {
      ...state,
      quizState: quizState
    }
  }),
  on(finishQuiz, (state) => {
    return {
      ...state,
    }
  }),
  on(finishQuizSuccess, (state, { progress}) => {
    let index = state.progressList.findIndex(item => item.id === progress.id);
    const updatedProgressList = [...state.progressList];
    updatedProgressList[index] = progress;

    return {
      ...state,
      progressList: updatedProgressList
    }
  }),
  on(startQuiz, (state) => {
    return {
      ...state
    }
  }),
  on(startQuizSuccess, (state, { progress }) => {
    let index = state.progressList.findIndex(item => item.id === progress.id);
    const updatedProgressList = [...state.progressList];
    updatedProgressList[index] = progress;
    return {
      ...state,
      progressList: updatedProgressList
    }
  }),
  on(updateProgressOfLesson, (state, { progress }) => {
    let updatedProgressList;
    if (progress) {
      updatedProgressList = state.progressList.map(item => {
        if (item.id === progress.id) {
          return progress;
        }
        return item;
      });
    }
    return {
      ...state,
      progressList: updatedProgressList ? updatedProgressList : state.progressList
    };
  })  
);
