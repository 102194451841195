<div *ngIf="data" class="header">
  <h1 class="subjects">{{'SUBJECTS' | translate}} ({{data.discountUserDetails.length }})</h1>
  <button class="button" (click)="open(undefined, true)">
    {{'ADD_SUBJECT_WITH_DISCOUNT' | translate}}
  </button>
</div>
<ul class="list">
  <li class="item" *ngFor="let item of data?.discountUserDetails">
    <div class="card">
      <div class="left">
        <h1 class="title">{{item.subjectNameKz ? item.subjectNameKz : 'Барлық пәндер'}}</h1>
        <span class="add-info">{{item.percent}}% <span class="dot"></span> {{item.always ? 'Навсегда' : 'Ограниченный'}}  <span class="dot"></span>Лимит: {{item.discountLeft}}</span>
      </div>
      <div class="right">
        <button class="button" (click)="open(item, false)">{{'CHANGE' | translate}}</button>
        <button class="button button--remove" (click)="openRemover(item)">{{'delete' | translate}}</button>
      </div>
    </div>
  </li>
</ul>

<app-discount-creater
  [editData]="picked"
  [isCreate]="isCreate"
  [isOpen]="isOpen"
  [username]="username"
  (closeEvent)="close()"
  (confirmEvent)="confirm($event)">
</app-discount-creater>

<app-discount-remove
  [discountUserDetailId]="picked?.id || ''"
  [isOpen]="isRemoveOpen"
  (closeEvent)="closeRemover()"
  (confirmEvent)="confirmRemover()">
</app-discount-remove>


