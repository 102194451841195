import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseRoutes, HeadTeacherRoutes } from 'src/app/core/model';

@Component({
  selector: 'app-analytics-card',
  templateUrl: './analytics-card.component.html',
  styleUrls: ['./analytics-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticsCardComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  public goTo(): void {
    this.router.navigate([BaseRoutes.HeadTeacher, HeadTeacherRoutes.StreamVideos])
  }

}
