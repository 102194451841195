import { createReducer, on } from "@ngrx/store";
import { getGroupAction, getGroupActionSuccess, getGroupsAction, getGroupsActionSuccess, resetGroups, setCurrentGroupId, setLessonProgressStatus } from "./groups.actions";
import { StudentGroupDTO, StudentGroupThemeDTO } from "src/app/core/model";

export interface GroupsState {
  groups: StudentGroupDTO[];
  currentGroup: StudentGroupThemeDTO | null,
  currentGroupId: string | null

}
export const initialState: GroupsState = {
  groups: [],
  currentGroup: null,
  currentGroupId: null
};

export const groupsReducer = createReducer(
  initialState,
  on(resetGroups, () => initialState),
  on(getGroupsAction, (state) => state),
  on(getGroupsActionSuccess, (state, { payload }) => {    
    return {
      ...state,
      groups: payload,
    };
  }),
  on(getGroupAction, (state) => state),
  on(getGroupActionSuccess, (state, { payload }) => {
    return {
      ...state,
      currentGroup: payload
    }
  }),
  on(setCurrentGroupId, (state, { id }) => {
    return {
      ...state,
      currentGroupId: id,
    };
  }),

  on(setLessonProgressStatus, (state, { lessonId, status }) => {
    if (state.currentGroup) {
      const foundLesson = state.currentGroup.themes.find(theme => {
        if(theme.lessons) {
          theme.lessons.find(lesson => lesson.lessonId === lessonId)
        }
      });
      console.log("found Lesson: ", foundLesson);
    }
    return {
        ...state
    };
  })
);
