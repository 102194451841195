<div class="content w-100">
  <div class="header" [ngClass]="{'header-baseline' : isDateShown}">
    <div class="header-title">
      <h1 class="title">Название опросника</h1>
      <span class="type">Тип: Про куратора</span>
    </div>
    <div *ngIf="!isDateShown" class="buttons">
      <button class="iconic-button secondary-button button button-red">Удалить</button>
      <button class="iconic-button secondary-button button">Отозвать</button>
    </div>
    <span *ngIf="isDateShown" class="date">20.01.24 - 30.01.24</span>
  </div>
  <app-expander [isExpanded]="isOpen">
      <button *ngIf="!isOpen" class="expander-button" (click)="toggleExpander()" button>
          <app-primary-arrow [isOpen]="!isOpen" [openColor]="'#9399AB'"></app-primary-arrow>
      </button>
      <div class="expander-content" content>
        <ul class="list">
          <li class="item">
            <app-questionnaire-view-controller [index]="0" [type]="QuestionnaireTypes.FiveStars"></app-questionnaire-view-controller>
          </li>
          <li class="item">
            <app-questionnaire-view-controller [index]="1" [type]="QuestionnaireTypes.YerOrNo"></app-questionnaire-view-controller>
          </li>
          <li class="item">
          <app-questionnaire-view-controller [index]="2" [type]="QuestionnaireTypes.Open"></app-questionnaire-view-controller>
        </li>
        </ul>
        <button class="iconic-button secondary-button button-change">Изменить</button>
        <button *ngIf="isOpen" class="expander-button" (click)="toggleExpander()" button>
          <app-primary-arrow [isOpen]="!isOpen" [openColor]="'#9399AB'"></app-primary-arrow>
      </button>
      </div>
  </app-expander>
</div>
