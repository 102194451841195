import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss'],
})
export class DeleteModalComponent {

  @Input() title: string = '';
  @Input() text: string = '';
  @Input() confirmText: string = '';
  @Input() cancelText: string = '';
  @Input() isModalOpen: boolean = false;
  @Input() id: any;
  @Input() isLoading: boolean = false;

  @Output() deleteEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();

  deleteHandler(): void {
    this.deleteEvent.emit(this.id);
  }

  closeDialog(): void {
    this.closeEvent.emit();
  }
}
