import {LessonsService} from './../../../../core/services/lessons/lessons.service';
import {HelperService} from './../../../../shared/services/helper/helper.service';
import {TranslateService} from '@ngx-translate/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Languages, SelectValue} from 'src/app/shared/models';
import {IShortLesson, LessonCreateRequest, LessonTypeKZ, LessonTypeRU, Roles} from 'src/app/core/model';
import {AuthService} from "../../../../core/services/auth/auth.service";
import {CuratorService} from "../../../../core/services/curator/curator.service";
import {ActivatedRoute} from "@angular/router";
import { HeadTeacherService } from 'src/app/core/services/head-teacher/head-teacher.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-new-lesson',
  templateUrl: './add-new-lesson.component.html',
  styleUrls: ['./add-new-lesson.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddNewLessonComponent implements OnInit, OnChanges {
  @Input() isOpen: boolean = false;
  @Input() themeId: string = "";
  @Output() closeDialog = new EventEmitter<void>();
  @Output() createEvent:EventEmitter<IShortLesson> = new EventEmitter<IShortLesson>();
  public form!: FormGroup;
  public options: SelectValue[] = [];
  public taskType!: any;

  public isSaving: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private helperService: HelperService,
    private lessonsService: LessonsService,
    private curatorService: CuratorService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private headTeacherService: HeadTeacherService,
    private toastrService: ToastrService,
    private cdr: ChangeDetectorRef) {
    if (translate.getDefaultLang() === Languages.KZ) {
      this.taskType = LessonTypeKZ;
    }
    if (translate.getDefaultLang() === Languages.RU) {
      this.taskType = LessonTypeRU;
    }
    this.options = this.helperService.convertEnumToSelectValue(this.taskType)
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      type: [LessonTypeKZ.Quiz, Validators.required],
      name: ['', Validators.required],
    });
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isOpen'] && this.form) {
      this.form.reset();
    }
  }

  public createLesson(): void {
    this.isSaving = true;
    const formValues = this.form.value;
    const data: LessonCreateRequest = {
      lessonType: this.helperService.mapTaskTypeToLessonType(formValues.type),
      themeId: this.themeId,
      name: formValues.name
    };

    if (this.authService.getRole() === Roles.Curator) {
      this.route.params.subscribe(params => {
        const groupId = params['groupId'];
        if (groupId) {
          this.curatorService.createLesson(data, groupId)
            .subscribe({
              next: res => {
                this.isSaving = false;
                this.createEvent.emit({
                  name: res.name,
                  id: res.id,
                  published: false,
                  lessonType: res.lessonType,
                  general: false,
                  checked: false
                });
                this.cdr.detectChanges();
              }, error: err => {
                this.handleError(err);
              }
            });
        }
      });
    } else if(this.authService.getRole() === Roles.HeadTeacher) {
      this.toastrService.info('Завуч не может создавать уроки.','Важно')
    } else if (this.authService.getRole() === Roles.Methodist) {
      this.lessonsService.createLesson(data)
        .subscribe({
          next: _ => {
            this.isSaving = false;
            this.createEvent.emit();
            this.cdr.detectChanges();
          },
          error: err => {
            this.handleError(err);
          }
        })
    }
  }

  private handleError(err: any): void {
    this.isSaving = false;
    this.toastrService.error(err?.error?.messages?.[0], "Ошибка");
    this.cdr.detectChanges();
  }
}
