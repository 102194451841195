import { Component, OnInit } from '@angular/core';
import { CuratorService } from 'src/app/core/services/curator/curator.service';

@Component({
  selector: 'app-student-modal',
  templateUrl: './student-modal.component.html',
  styleUrls: ['./student-modal.component.scss'],
})
export class StudentModalComponent implements OnInit {
  public isShowModal: boolean = false;

  constructor(private _curatorService: CuratorService) {}
  ngOnInit(): void {
    this._curatorService._isShowStudentModal.subscribe((response) => {
      this.isShowModal = response;
    });
  }

  public toggleShowModalStudent(): void {
    this._curatorService.toggleIsShowStudentModal();
  }
}
