import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConverterService {

constructor(private httpClient: HttpClient) { }

convert(links: string[]): Observable<string> {
  return this.httpClient.get<string>(`https://converter.juz40-edu.kz/converter/convert`, {
      params: {
          links
      }
  }).pipe(
    map(res => {
      return "https://converter.juz40-edu.kz/converter/convert" + links[0];
    })
  )
}
}
