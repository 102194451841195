import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ButtonSize, ButtonTypes } from "../../../models/enums/button.enum";

@Component({
  selector: 'app-circular-progress',
  templateUrl: './circular-progress.component.html',
  styleUrls: ['./circular-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CircularProgressComponent implements OnChanges {
  @Input() percentage: number = 0;
  @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

  circumference: number = 2 * Math.PI * 35;
  strokeDashoffset: number = this.circumference;

  protected readonly ButtonTypes = ButtonTypes;
  protected readonly ButtonSize = ButtonSize;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['percentage']) {
      this.strokeDashoffset = this.circumference * (1 - this.percentage / 100);
    }
  }

  handleClick(): void {
    this.onCancel.emit();
  }
}
