import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Component({
  selector: 'app-iframe-player',
  templateUrl: './iframe-player.component.html',
  styleUrls: ['./iframe-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IframePlayerComponent implements OnInit {
  @ViewChild('#iframe') iframeRef: ElementRef | undefined;
  
  @Input() videoUrl: string | undefined;

  public iFrame: SafeHtml | undefined;

  constructor(
    private sanitizer: DomSanitizer,
  ) 
    {}

  ngOnInit() {
    if(this.videoUrl) {
      const updatedUrl = this.videoUrl.replace(/width="\d+"/, 'width="100%"').replace(/height="\d+"/, 'height="100%"');
      this.iFrame = this.sanitizer.bypassSecurityTrustHtml(updatedUrl);
    }
  }
}
