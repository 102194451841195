import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentsModule } from './components/components.module';
import { ServicesModule } from './services/services.module';
import { LayoutsModule } from './layouts/layouts.module';
import { PipesModule } from './pipes/pipes.module';


@NgModule({
  declarations: [
    ],
  imports: [
    CommonModule,
    ComponentsModule,
    ServicesModule,
    LayoutsModule,
    PipesModule
  ],
  exports: [
    CommonModule,
    ComponentsModule,
    ServicesModule,
    LayoutsModule,
    PipesModule,
  ],
})
export class SharedModule { }
