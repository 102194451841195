import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { IGroup } from 'src/app/core/model/interface/group';
import { ThemeDTO } from 'src/app/core/model/interface/theme';
import { CuratorService } from 'src/app/core/services/curator/curator.service';
import { HeaderService } from 'src/app/core/services/header/header.service';
import { Router } from "@angular/router";
import { BaseRoutes, CuratorRoutes } from "../../../../core/model";

@Component({
  selector: 'app-curator-sidebar-item',
  templateUrl: './curator-sidebar-item.component.html',
  styleUrls: ['./curator-sidebar-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CuratorSidebarItemComponent implements OnInit {
  @Input() group: IGroup | undefined;
  @Input() taskCount: number = 0;

  public isOpen: boolean = false;
  public themes: ThemeDTO[] | undefined;

  constructor(
    private _curatorService: CuratorService,
    private headerService: HeaderService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.themes = this.group?.themes;
    // this.headerService.setBackButtonName('Назад');
    // this.headerService.setName(' ');
  }

  public toggleIsOpen(): void {
    if (this.group?.redirectUrl) {
      this.router.navigate([`${BaseRoutes.Curator}/${CuratorRoutes.MyTasks}`]).then();
    } else {
      this.isOpen = !this.isOpen;
    }
  }

  public onLinkClick(theme: any): void {
    this.headerService.setBackButtonName('BACK');
    this.headerService.setName(theme);
  }

  public openFolders(): void {
    this.router.navigate([`/${BaseRoutes.Curator}/${CuratorRoutes.FoldersControl}/${this.group!.courseId}/${this.group!.id}`])
  }
}
