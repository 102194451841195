import { DateHelperServiceService } from './../../../../shared/services/dateHelperService/date-helper-service.service';
import { DatePipe, formatDate } from '@angular/common';
import { ThemeService } from './../../../../core/services/theme/theme.service';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CreateThemeRequest, ThemeDTO } from 'src/app/core/model/interface/theme';
import { SelectValue } from 'src/app/shared/models';

@Component({
  selector: 'app-add-new-theme',
  templateUrl: './add-new-theme.component.html',
  styleUrls: ['./add-new-theme.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddNewThemeComponent implements OnChanges {
  @Input() data: ThemeDTO | undefined;
  @Input() isOpen: boolean = false;
  @Output() closeDialog = new EventEmitter<void>();
  @Output() createEvent = new EventEmitter<void>();

  public timeOptions: SelectValue[] = [
    { name: '00:00', value: '00:00' },
    { name: '00:15', value: '00:15' },
    { name: '00:30', value: '00:30' },
    { name: '00:45', value: '00:45' },
    { name: '01:00', value: '01:00' },
    { name: '01:15', value: '01:15' },
    { name: '01:30', value: '01:30' },
    { name: '01:45', value: '01:45' },
    { name: '02:00', value: '02:00' },
    { name: '02:15', value: '02:15' },
    { name: '02:30', value: '02:30' },
    { name: '02:45', value: '02:45' },
    { name: '03:00', value: '03:00' },
    { name: '03:15', value: '03:15' },
    { name: '03:30', value: '03:30' },
    { name: '03:45', value: '03:45' },
    { name: '04:00', value: '04:00' },
    { name: '04:15', value: '04:15' },
    { name: '04:30', value: '04:30' },
    { name: '04:45', value: '04:45' },
    { name: '05:00', value: '05:00' },
    { name: '05:15', value: '05:15' },
    { name: '05:30', value: '05:30' },
    { name: '05:45', value: '05:45' },
    { name: '06:00', value: '06:00' },
    { name: '06:15', value: '06:15' },
    { name: '06:30', value: '06:30' },
    { name: '06:45', value: '06:45' },
    { name: '07:00', value: '07:00' },
    { name: '07:15', value: '07:15' },
    { name: '07:30', value: '07:30' },
    { name: '07:45', value: '07:45' },
    { name: '08:00', value: '08:00' },
    { name: '08:15', value: '08:15' },
    { name: '08:30', value: '08:30' },
    { name: '08:45', value: '08:45' },
    { name: '09:00', value: '09:00' },
    { name: '09:15', value: '09:15' },
    { name: '09:30', value: '09:30' },
    { name: '09:45', value: '09:45' },
    { name: '10:00', value: '10:00' },
    { name: '10:15', value: '10:15' },
    { name: '10:30', value: '10:30' },
    { name: '10:45', value: '10:45' },
    { name: '11:00', value: '11:00' },
    { name: '11:15', value: '11:15' },
    { name: '11:30', value: '11:30' },
    { name: '11:45', value: '11:45' },
    { name: '12:00', value: '12:00' },
    { name: '12:15', value: '12:15' },
    { name: '12:30', value: '12:30' },
    { name: '12:45', value: '12:45' },
    { name: '13:00', value: '13:00' },
    { name: '13:15', value: '13:15' },
    { name: '13:30', value: '13:30' },
    { name: '13:45', value: '13:45' },
    { name: '14:00', value: '14:00' },
    { name: '14:15', value: '14:15' },
    { name: '14:30', value: '14:30' },
    { name: '14:45', value: '14:45' },
    { name: '15:00', value: '15:00' },
    { name: '15:15', value: '15:15' },
    { name: '15:30', value: '15:30' },
    { name: '15:45', value: '15:45' },
    { name: '16:00', value: '16:00' },
    { name: '16:15', value: '16:15' },
    { name: '16:30', value: '16:30' },
    { name: '16:45', value: '16:45' },
    { name: '17:00', value: '17:00' },
    { name: '17:15', value: '17:15' },
    { name: '17:30', value: '17:30' },
    { name: '17:45', value: '17:45' },
    { name: '18:00', value: '18:00' },
    { name: '18:15', value: '18:15' },
    { name: '18:30', value: '18:30' },
    { name: '18:45', value: '18:45' },
    { name: '19:00', value: '19:00' },
    { name: '19:15', value: '19:15' },
    { name: '19:30', value: '19:30' },
    { name: '19:45', value: '19:45' },
    { name: '20:00', value: '20:00' },
    { name: '20:15', value: '20:15' },
    { name: '20:30', value: '20:30' },
    { name: '20:45', value: '20:45' },
    { name: '21:00', value: '21:00' },
    { name: '21:15', value: '21:15' },
    { name: '21:30', value: '21:30' },
    { name: '21:45', value: '21:45' },
    { name: '22:00', value: '22:00' },
    { name: '22:15', value: '22:15' },
    { name: '22:30', value: '22:30' },
    { name: '22:45', value: '22:45' },
    { name: '23:00', value: '23:00' },
    { name: '23:15', value: '23:15' },
    { name: '23:30', value: '23:30' },
    { name: '23:45', value: '23:45' }
  ];
  public form!: FormGroup;
  isLoading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private themeService: ThemeService,
    private route: ActivatedRoute,
    private el: ElementRef,
    private dateHelper: DateHelperServiceService,
    private datePipe: DatePipe
  )
  {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      date: [''],
      time: ['']
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.data) {
      const startTime = this.dateHelper.convertUTCToLocal(new Date(this.data.startTime));

      this.form.patchValue({name: this.data.name});
      this.form.patchValue({date: this.datePipe.transform(startTime, 'yyyy-MM-dd') });
      this.form.patchValue({ time:  (startTime.getHours() < 9 ? ('0'+ startTime.getHours()) : startTime.getHours())
      + ':' + ((startTime.getMinutes() < 9) ? '0' + startTime.getMinutes() : startTime.getMinutes())});
    }
    else {
      this.form.patchValue({name: ''});
      this.form.patchValue({date: '' });
      this.form.patchValue({time: '' });
    }
  }

  public createTheme(): void {
    const formValues = this.form.value;

    const hours = formValues.time.slice(0, 2);
    const minutes = formValues.time.slice(-2);
    const date = new Date(formValues.date);

    const utcDate = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      hours,
      minutes,
    );

    let data: CreateThemeRequest = {
      name: formValues.name,
      courseId: this.route.snapshot.paramMap.get('id') || '',
      startTime: undefined
    }

    if(utcDate) {
      data.startTime = utcDate ? utcDate : undefined;
    }

    this.isLoading = true;

    const onNext = {
      next: () => {
        this.createEvent.emit();
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      }
    };

    if(this.data) {
      this.themeService.updateTheme(this.data.id ,data).subscribe(onNext);
      return;
    }
    this.themeService.createTheme(data).subscribe(onNext)
  };

  private distinguishDateAndTime(dateTimeStr: string) {
    // Split the date and time parts using the 'T' separator
    const [datePart, timePart] = dateTimeStr.split('T');
    return {
      date: datePart,
      time: timePart,
    };
  }

  handleTimeInput(event: Event): void {
    const regex: RegExp = /^(?:[01]?[0-9]|2[0-3]):[0-5][0-9]$/;
    const value = (event.target as HTMLInputElement).value;
    if (!regex.test(value)) {
      this.el.nativeElement.value = '';
    }
  }
}
