<div class="catalogues-footer">
  <a [routerLink]="[baseRoutes.Catalogues]">
    <img class="catalogues-footer-logo" src="./assets/img/logo-new.png" alt="Logo"/>
  </a>
  <div class="catalogues-footer-docx">
    <a target="_blank"
       [routerLink]="'/' + docxRoutes.PrivacyPolicy">{{'PRIVACY_POLICY' | translate}}</a>
    <a target="_blank"
       [routerLink]="'/' + docxRoutes.PublicOffer">{{'PUBLIC_OFFER' | translate}}</a>
  </div>
</div>
