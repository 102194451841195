<app-dialog title="ADD_LESSON_FROM_COURSE"
            confirmBntText="ADD_LESSON"
            [isOpen]="isOpen"
            [disabledConfirm]="form.invalid || disableBtn || isDisabledBtn"
            (confirmEvent)="addLesson()"
            (closeEvent)="closeDialog.emit()">
    <form class="form" [formGroup]="form">
      <div class="flex gap-16">
        <app-select
          class="w-100"
          [options]="subjectOptions"
          (selectedValue)="onSelectSubject($event)"
          name="Предмет"
          formControlName="subject"/>

        <app-select
          class="w-100"
          [options]="coursesOptions"
          [isActive]="!!coursesOptions.length"
          (selectedValue)="onSelectCourse($event)"
          name="Курс"
          formControlName="course"
          [isLoading]="isCourseOptionsIsLoading"/>

        <app-select
          class="w-100"
          [options]="themeList"
          [isActive]="!!themeList!.length"
          (selectedValue)="onSelectTheme($event)"
          name="Тема"
          formControlName="theme"
          [isLoading]="isThemesOptionsIsLoading"/>
      </div>

      <div class="lessons">
        <button *ngFor="let lesson of lessonsList"
                (click)="checkLesson(lesson)"
                class="lesson flex justify-between"
                [class.checked]="lesson.checked">
          <div class="flex flex-col lesson-description">
            <h2 class="lesson-name">{{lesson.name}}</h2>
            <p class="lesson-type">{{'TYPE' | translate}}: {{lesson.lessonType | reverseLessonType}}</p>
          </div>

          <mat-checkbox color="accent" [checked]="lesson.checked"></mat-checkbox>
        </button>
      </div>
    </form>
</app-dialog>
