import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NoopValueAccessorDirectiveDirective, injectNgControl } from 'src/app/shared/directives/noop-value-accessor-directive/noop-value-accessor-directive.directive';

@Component({
  selector: 'app-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
  hostDirectives: [NoopValueAccessorDirectiveDirective],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlideToggleComponent {
  ngControl = injectNgControl();

  @Input() disabled: boolean = false;
  @Input() toggleValue: boolean = false;
  @Input() tooltipImg: boolean = false;
  @Input() text: string = "";
  @Output() toggleValueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public showInfo: boolean = false;

  // constructor() {
  //   if (this.ngControl) {

  //     this.ngControl.valueAccessor = NOOP_VALUE_ACCESSOR;
  //   }
  // }
  onToggleChange(event: any) {
    const toggleValue: boolean = event.checked;
    this.toggleValueChange.emit(toggleValue);
  }

  openCloseTooltip() {
    this.showInfo = !this.showInfo;
  }
}
