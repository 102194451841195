import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DiscountsService } from './../../../../core/services/discounts/discounts.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-discount-remove',
  templateUrl: './discount-remove.component.html',
  styleUrls: ['./discount-remove.component.scss']
})
export class DiscountRemoveComponent implements OnInit {
  @Input() isOpen: boolean = false;
  @Input() discountUserDetailId: string = "";
  @Output() confirmEvent = new EventEmitter<void>();
  @Output() closeEvent = new EventEmitter<void>();

  constructor(
    private discountsService: DiscountsService,
    private cdr: ChangeDetectorRef,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {

  }

  public remove(): void {
    this.discountsService.deleteDiscountStudentDetail(this.discountUserDetailId).subscribe({
      next: (res) => {
        this.toastrService.success('Вы успешно удалили скидку!','Успех');
        this.confirmEvent.emit();
        this.cdr.detectChanges();
      },
      error: (err) => {
        console.log(err)
      }
    })
  }
}
