import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-editor-viewer',
  templateUrl: './editor-viewer.component.html',
  styleUrls: ['./editor-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditorViewerComponent implements OnChanges {
  @Input() content: string = "";
  public config = { 
    toolbar: [],
    link : {
      addTargetToExternalLinks: true
    }
  }
  public form: FormGroup = this.formBuilder.group({
    content: ['']
  });

  constructor(private formBuilder: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(this.content) {
      this.form.patchValue({'content': this.content});
    }
  }
}
