<div class="announce-filter flex flex-col">
  <div class="announce-filter_header flex justify-between items-center">
    <h1>{{'FILTER' | translate}}</h1>
    <button
      class="close-btn flex items-center justify-center"
      (click)="closeMenu()"
    >
      <img src="./assets/svg/plus.svg" alt="" />
    </button>
  </div>
  <form *ngIf="role !== rolesEnum.Curator" action="" [formGroup]="form" class="flex flex-col">
    <app-multi-select
      [options]="rolesOptions"
      (selectedValue)="multiSelect($event, 'role')"
      [preSelectedOptions]="form.get('role')?.value"
      name="WHOM_TO_SENT"
      placeholder="CHOOSE_FROM_LIST"
      [withSearch]="true"
      [isActive]="true"
    ></app-multi-select>
  </form>

  <div class="btns flex justify-end cg-16">
    <app-button
      text="reset"
      (onButtonClick)="resetForm()"
      [type]="buttonTypes.Outline"
    ></app-button>
    <app-button
      text="ACCEPT"
      [isDisabled]="!form.get('role')?.value"
      (onButtonClick)="closeMenu(true)"
    ></app-button>
  </div>
</div>


