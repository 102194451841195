import { DateHelperServiceService } from 'src/app/shared/services/dateHelperService/date-helper-service.service';
import { LessonsService } from 'src/app/core/services/lessons/lessons.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, PipeTransform, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LessonDTO, HomeworkDTO, LessonType, Roles } from 'src/app/core/model';
import { Observable, Subscription } from 'rxjs';
import { SelectValue } from 'src/app/shared/models';
import { getFormControlErrors } from 'src/app/shared/validators';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CuratorService } from 'src/app/core/services/curator/curator.service';
import { getTimeOptions } from "../common/common";
import { FoldersService } from './../../../../../../core/services/folders/folders.service';

@Component({
  selector: 'app-homework-controller',
  templateUrl: './homework-controller.component.html',
  styleUrls: ['./homework-controller.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeworkControllerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data: LessonDTO | undefined;
  @Input() name: string = "";
  @Input() saveClicked: boolean = false;
  @Input() isOpen: boolean = false;

  private eventsSubscription!: Subscription;
  private routerSubscription!: Subscription;

  @Input() saveEvent!: Observable<void>;
  @Output() allowSaveEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() savedEvent: EventEmitter<LessonDTO> = new EventEmitter<LessonDTO>();

  public unsavedChanges: boolean = false;
  public confirmationShown: boolean = false;

  public baseForm!: FormGroup;
  public baseFormErrors = {
    id: '',
    name: '',
    description: '',
    rating: '',
    date: '',
    time: '',
    maxScore: '',
    materials: '',
  }

  public timeOptions: SelectValue[];

  public roles = Roles;
  public role: Roles = Roles.Methodist;

  public studentsList: SelectValue[] = [];
  public groupId: string = "";
  public themeId: string = ""

  public foldersOptions: SelectValue[] = [];

  public courseId: string = "";
  constructor(
    private formBuilder: FormBuilder,
    private lessonsService: LessonsService,
    private el: ElementRef,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private dateHelper: DateHelperServiceService,
    private datePipe: DatePipe,
    private translateService: TranslateService,
    private authService: AuthService,
    private curatorService: CuratorService,
    private route: ActivatedRoute,
    private FoldersService: FoldersService
  ) {
    this.timeOptions = getTimeOptions();
    this.baseForm = this.formBuilder.group({
      id: [''],
      name: [''],
      description: ['', Validators.required],
      rating: [true, Validators.required],
      hasMaxScore: [true],
      hasDate: [true],
      date: ['', Validators.required],
      time: ['', Validators.required],
      maxScore: ['', Validators.required],
      materials: [[]],
      usersIds: [[]],
      courseFolderId: ['']
    });

    this.baseForm.statusChanges.subscribe(status => {
      this.allowSaveEvent.emit(status === 'VALID');
    });

    this.baseForm.valueChanges.subscribe(res => {
      this.unsavedChanges = true;

      if (res.description) {
        this.baseFormErrors.description = ""
      }
      else {
        this.baseFormErrors.description = "REQUIRED_FIELD"
      }

      if (res.hasMaxScore) {
        if (res.maxScore) {
          this.baseFormErrors.maxScore = ""
        }
        else {
          this.baseFormErrors.maxScore = "SHOW_MAX_SCORE_FOR_TASK"
        }
        if (!this.baseForm.get('maxScore')?.hasValidator(Validators.required)) {
          this.baseForm.get('maxScore')?.setValidators(Validators.required);
          this.baseForm.get('maxScore')?.updateValueAndValidity();
        }
      } else {
        this.baseFormErrors.maxScore = "";
        if (this.baseForm.get('maxScore')?.hasValidator(Validators.required)) {
          this.baseForm.get('maxScore')?.clearValidators();
          this.baseForm.get('maxScore')?.updateValueAndValidity();
        }
      }

      if (res.hasDate) {
        if (res.date) {
          this.baseFormErrors.date = ""
        }
        else {
          this.baseFormErrors.date = "SHOW_DEADLINE"
        }
        if (!this.baseForm.get('date')?.hasValidator(Validators.required)) {
          this.baseForm.get('date')?.setValidators(Validators.required);
          this.baseForm.get('date')?.updateValueAndValidity();
        }

        const timeFormatRegex1: RegExp = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/;
        const timeFormatRegex2: RegExp = /^([0-1][0-9]|2[0-3])[0-5][0-9]$/;
        const timeFormatRegex3: RegExp = /^([0-1][0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/;

        if (timeFormatRegex1.test(res.time) || timeFormatRegex2.test(res.time) || timeFormatRegex3.test(res.time)) {
          this.baseFormErrors.time = ""
        }
        else {
          this.baseFormErrors.time = "REQUIRED_FIELD"
        }
        if (!this.baseForm.get('time')?.hasValidator(Validators.required)) {
          this.baseForm.get('time')?.setValidators(Validators.required);
          this.baseForm.get('time')?.updateValueAndValidity();
        }
      } else {
        this.baseFormErrors.date = "";
        if (this.baseForm.get('date')?.hasValidator(Validators.required)) {
          this.baseForm.get('date')?.clearValidators();
          this.baseForm.get('date')?.updateValueAndValidity();
        }
        if (this.baseForm.get('time')?.hasValidator(Validators.required)) {
          this.baseForm.get('time')?.clearValidators();
          this.baseForm.get('time')?.updateValueAndValidity();
        }
      }
      // if(!res.materials || res.materials.length === 0) {
      //   this.baseFormErrors.materials = "Загрузите файл";
      // }
      // else {
      //   this.baseFormErrors.materials = "";
      // }
    });
  }

  ngOnInit(): void {
    this.role = this.authService.getRole();

    this.route.params.subscribe(params => {
      if (this.authService.getRole() === Roles.Methodist) {
        this.courseId = params['id'];
      }

      if (this.authService.getRole() === Roles.Curator) {
        this.courseId = params['groupId'];
      }
      this.cdr.detectChanges();
    });

    this.eventsSubscription = this.saveEvent.subscribe(() => {
      this.save();
    });

    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.isOpen && this.unsavedChanges && !this.confirmationShown) {
          this.confirmationShown = true;
          const shouldContinue = confirm(`В "${this.data?.name}" есть не сохраненые изменения.Хотите продолжить?`);
          if (!shouldContinue) {
            const currentRoute = this.router.routerState;
            this.router.navigateByUrl(currentRoute.snapshot.url, { skipLocationChange: true });
          }
        }
      }
    });
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && this.data) {
      if (this.authService.getRole() === Roles.Methodist) {
        this.route.params.subscribe(params => {
          this.courseId = params['id'];

          this.FoldersService.getFoldersMethodist(params['id']!).subscribe({
            next: (res) => {
              this.foldersOptions = res[0].courseFolders.map(item => {
                return {
                  name: item.courseFolderName,
                  value: item.courseFolderId
                }
              });
              this.cdr.detectChanges();
              this.baseForm.patchValue({ courseFolderId: this.data?.courseFolderId || null })

              this.cdr.detectChanges();
            },
            error: (err) => {
              console.log(err);
            }
          })
          this.cdr.detectChanges();
        })
      }
      if (this.authService.getRole() === Roles.Curator) {
        this.route.params.subscribe(params => {
          this.groupId = params['groupId'];
          this.themeId = params['themeId'];

          const groups = [this.groupId];

          this.curatorService.getStudentsByGroupIds(groups).subscribe(res => {
            this.studentsList = res.map(item => {
              return {
                name: item.firstname + " " + item.lastname,
                value: item.id || ''
              }
            }).sort((a, b) => (a.name).localeCompare((b.name)));
            this.cdr.detectChanges();
          });

          if (!this.data?.common) {
            this.baseForm.setControl('usersIds', new FormControl([],));
            this.baseForm.patchValue({ 'usersIds': this.data?.usersIds })
          }

          this.FoldersService.getFoldersCurator(this.groupId).subscribe({
            next: (res) => {
              this.foldersOptions = res[0].courseFolders.map(item => {
                return {
                  name: item.courseFolderName,
                  value: item.courseFolderId
                }
              });
              this.cdr.detectChanges();
              this.baseForm.patchValue({ courseFolderId: this.data?.courseFolderId || null })

              this.cdr.detectChanges();
            },
            error: (err) => {
              console.log(err);
            }
          })
          this.cdr.detectChanges();

        });
      }

      if (this.authService.getRole() === Roles.HeadTeacher) {
        this.route.params.subscribe(params => {
          const groupId = params['groupId'];
          this.courseId = params['id'];

          this.FoldersService.getFoldersHeadTeacher(this.courseId, groupId).subscribe({
            next: (res) => {

              this.foldersOptions = res[0].courseFolders.map(item => {
                return {
                  name: item.courseFolderName,
                  value: item.courseFolderId
                }
              });
              this.cdr.detectChanges();
              this.baseForm.patchValue({ courseFolderId: this.data?.courseFolderId || null })

              this.cdr.detectChanges();
            },
            error: (err) => {
              console.log(err);
            }
          })
          this.cdr.detectChanges();
        });

        if (!this.data?.common) {
          this.baseForm.setControl('usersIds', new FormControl([], []));
          this.baseForm.patchValue({ 'usersIds': this.data?.usersIds });
        }
      }

      if (this.data?.deadline) {
        const deadline = this.dateHelper.convertUTCToLocal(new Date(this.data?.deadline));
        this.datePipe.transform(deadline, 'yyyy-MM-dd');
        this.baseForm.patchValue({ date: this.datePipe.transform(deadline, 'yyyy-MM-dd') });
        this.baseForm.patchValue({
          time: (deadline.getHours() < 9 ? ('0' + deadline.getHours()) : deadline.getHours())
            + ':' + ((deadline.getMinutes() < 9) ? '0' + deadline.getMinutes() : deadline.getMinutes())
        });
      }

      this.baseForm.patchValue({ description: this.data?.description });
      this.baseForm.patchValue({ rating: this.data?.rating });
      this.baseForm.patchValue({ maxScore: this.data?.maxScore });
      this.baseForm.patchValue({ hasMaxScore: this.data?.hasMaxScore || true });
      this.baseForm.patchValue({ hasDate: this.data?.hasDate || true });
      this.baseForm.patchValue({ materials: this.data?.materials });
      this.baseForm.patchValue({ usersIds: this.data?.usersIds || [] });
    }
  }

  ngOnDestroy(): void {
    this.eventsSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }

  public toggleExpander(): void {
    this.isOpen = !this.isOpen;
  }

  public materialDownloaded(id: string): void {
    const currentAdditionalMaterials = this.baseForm.get('materials')?.value || [];
    const materials = [...currentAdditionalMaterials, id];

    this.baseForm.patchValue({ materials: materials });
    this.baseFormErrors.materials = "";
  }

  public save(): void {
    const baseForm = this.baseForm.value;

    const hours = baseForm.time.slice(0, 2);
    const minutes = baseForm.time.slice(-2);
    const date = new Date(baseForm.date);

    const utcDate = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      hours,
      minutes,
    );


    const data = {
      // const data: HomeworkDTO = {
      lessonType: LessonType.Homework,
      id: this.data!.id,
      name: this.name,
      description: baseForm.description,
      rating: baseForm.rating,
      deadline: baseForm.hasDate ? utcDate.toISOString() : null,
      maxScore: baseForm.hasMaxScore ? baseForm.maxScore : null,
      materials: baseForm.materials,
      usersIds: baseForm.usersIds,
      courseFolderId: baseForm.courseFolderId
    }

    this.lessonsService.updateHomeworksById(this.groupId, this.themeId, this.data!.id, data).subscribe(res => {
      this.savedEvent.emit(res);
      this.unsavedChanges = false;
      this.cdr.detectChanges()
    })
  }

  handleTimeInput(event: Event): void {
    this.handleFormControlErrors(this.baseForm, this.baseFormErrors, 'time')
    const regex: RegExp = /^(?:[01]?[0-9]|2[0-3]):[0-5][0-9]$/;
    const value = (event.target as HTMLInputElement).value;
    if (!regex.test(value)) {
      this.el.nativeElement.value = '';
    }
  };

  handleFormControlErrors(form: FormGroup, error: any, controlName: string): void {
    getFormControlErrors(form, error, controlName, this.translateService);
    this.cdr.detectChanges();
  };

  public removed(id: string): void {
    const currentMaterials = this.baseForm.get('materials')?.value || [];
    const updatedMaterials = currentMaterials.filter((fileId: string) => fileId !== id);

    this.baseForm.patchValue({ materials: updatedMaterials });
  }

  public studentsSelected($event: any[]): void {
    this.baseForm.patchValue({ usersIds: $event });
  }
}
