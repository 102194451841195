import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, map, of } from 'rxjs';
import { Languages } from '../../models';

@Injectable()
export class TranslationService implements TranslateLoader {
  private LOCAL = 'LOCALE_';
  constructor(private http: HttpClient) {}

  getTranslation(lang: Languages): Observable<any> {
    const localLocale: any = localStorage.getItem(`${this.LOCAL}_${lang}`);
    let needRequest = true;
    let localeObject;
    if (localLocale) {
      localeObject = JSON.parse(localLocale);
      const localeDate = localeObject.now;
      if (new Date().getTime() - localeDate < 600_000 && localeObject.data) {
        needRequest = false;
      }
    }

    if (needRequest)
      console.log("lang : ", lang);
      return this.http.get(`/assets/i18n/${lang}.json`).pipe(
        map((res) => {
          const now = new Date().getTime();
          localStorage.setItem(
            `${this.LOCAL}_${lang}`,
            JSON.stringify({ now, data: res })
          );
          return res;
        })
      );

    return of(localeObject.data);
  }
}
