<div class="course">
  <div class="course__image skeleton"></div>
  <div class="course__body">
    <div class="course__title skeleton"></div>
    <div class="course__info info">
      <div class="info__1 skeleton"></div>
      <div class="info__2 skeleton"></div>
      <div class="info__3 skeleton"></div>
    </div>
    <div class="footer">
      <div class="footer__price skeleton"></div>
      <div class="footer__button skeleton"></div>
    </div>
  </div>
</div>
