<div *ngIf="ratingList?.length" class="app-rating-card">
  <h1 class="title">{{'studentRating' | translate}}</h1>
  <div *ngIf="isRatingPage" class="mobile">
    <div class="header">
      <img class="start" src="assets/svg/Stars.svg" alt="">
      <h1 class="place">
      {{'RANK_PLACE' | translate: {rank: currentUserInfo?.rank} }}
      </h1>
      <span class="average">{{'AVERAGE_RATING' | translate}}: <span>{{averageScore.toFixed(2)}}</span></span>
      <button (click)="goToScoresDeatilsPage()" class="iconic-button secondary-button my-scores">
      {{'MY_MARKS' | translate}}
      </button>
    </div>
  </div>
 <ul class="list">
   <ng-container *ngFor="let user of ratingList; let i = index">
     <li class="item" [ngClass]="{
               'gold': i === 0,
               'silver': i === 1,
               'bronze': i === 2,
               'special': i < 3,
               'myself': user.studentId === userId
           }">
       <div class="left">
         <div class="position">
           <span class="position-text">{{user.rank}}</span>
         </div>
         <div
            class="bg-image"
            *ngIf="user.studentProfilePhotoUrl"
            [ngStyle]="{'background-image': 'url(' + fileBaseUrl + user.studentProfilePhotoUrl + ')'}">
         </div>
         <div *ngIf="!user.studentProfilePhotoUrl" class="bg-no-image">
          <span>
            {{user.studentFullName[0] || ''}}
          </span>
          </div>
         <div>
           <span *ngIf="user.studentId !== userId;else its_me" class="name">{{ user.studentFullName }}</span>
           <ng-template #its_me>
             <span class="name">{{'you' | translate}}</span>
           </ng-template>
         </div>
       </div>
       <span class="score">{{ user.totalScore | localizedNumberFormat }}</span>
     </li>
   </ng-container>
 </ul>
 <button *ngIf="!isRatingPage" (click)="goToRating()" class="iconic-button secondary-button button">
   <span>{{'seeFullList' | translate}}</span>
   <img src="assets/svg/arrow-sidebar.svg" alt=""/>
 </button>
</div>
