<div class="content">
    <h1 class="title">Ваши ответы помогут сделать наши курсы лучше!</h1>
    <div class="about">
        <img class="profile" src="assets/svg/gray-profile.svg" alt="" />
        <div class="info">
            <h1 class="name">Коныратбаев Б.</h1>
            <span class="role">Куратор (ФИЗ-МАТ)</span>
        </div>
    </div>
    <ul class="list">
        <li class="item">
            <app-questionnaire-view-controller [index]="0" [type]="QuestionnaireTypes.FiveStars"></app-questionnaire-view-controller>
          </li>
          <li class="item">
            <app-questionnaire-view-controller [index]="1" [type]="QuestionnaireTypes.YerOrNo"></app-questionnaire-view-controller>
          </li>
          <li class="item">
          <app-questionnaire-view-controller [index]="2" [type]="QuestionnaireTypes.Open"></app-questionnaire-view-controller>
        </li>
    </ul>
    <button class="iconic-button primary-button button">Отправить</button>
</div>
