import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
} from '@angular/core';
import { AdminService } from 'src/app/core/services/admin/admin.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'src/app/shared/services/toast/toast.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StreamService } from 'src/app/core/services/stream/stream.service';
import { DatePipe } from '@angular/common';
import { MyDatePipe } from 'src/app/shared/pipes/date-translate/date-translate.pipe';
import { EMPTY_SELECT_VALUE, EMPTY_VALUE } from 'src/app/shared/consts/common';
import { ManagerService } from 'src/app/core/services/manager/manager.service';
import { DiscountsService } from './../../../../../services/discounts/discounts.service';

@Component({
  selector: 'app-admin-student-add-course',
  templateUrl: './admin-student-add-course.component.html',
  styleUrls: ['./admin-student-add-course.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminStudentAddCourseComponent implements OnInit {
  @Output() courseAddedEvent = new EventEmitter();
  public studentId: string;
  public subjectOptions: any[] = [];
  public subjectId: string | null = null;

  public coursesOptions: any[] = [];
  public courseId: string | null = null;
  public isCourseOptionsIsLoading = false;

  public groupsOptions: any[] = [];
  public groupsMap: any = {};
  public groupId: string | null = null;
  public isGroupOptionsIsLoading = false;

  public periodOptions: any[] = [];
  public periodIds?: string[] | null;
  public isPeriodOptionsIsLoading = false;

  public isAddingStudentToCourse = false;

  public form: FormGroup = new FormGroup({
    subject: new FormControl(null, [Validators.required]),
    course: new FormControl(null, [Validators.required]),
    group: new FormControl(null, [Validators.required]),
    managerId: new FormControl(null, [Validators.required]),
  });

  public managerOptions: any[] = [];
  public isManagerOptionsLoading = false;
  public selectedManagerId?: string;

  constructor(
    private _adminService: AdminService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AdminStudentAddCourseComponent>,
    private ts: ToastService,
    private _streamService: StreamService,
    private datePipe: MyDatePipe,
    private cdr: ChangeDetectorRef,
    private _managerService: ManagerService,
    private discountsService: DiscountsService
  ) {
    console.log(data);
    this.studentId = data.studentId;
  }

  public ngOnInit(): void {
    this.discountsService.getSubjects().subscribe({
      next: (res) => {
        this.subjectOptions = [{ name: 'Выберите', value: null }, 
        ...res.map(item => { 
          return {
            name: item.subjectNameKz,
            value: item.subjectId
          }
        })]
        this.cdr.detectChanges();
      },
      error: (err) => {
        console.log(err);
      }
    })

    this.getManagers();
  }

  public onSelectSubject(event: any): void {
    if (!event) return;
    this.courseId = null;
    this.coursesOptions = [];
    this.groupId = null;
    this.groupsOptions = [];

    this.subjectId = event;
    this.isCourseOptionsIsLoading = true;
    this._adminService.getCourses(event).subscribe({
      next: (res: any) =>
        (this.coursesOptions = [{ name: 'Выберите', value: null }, ...res]),
      complete: () => {
        this.isCourseOptionsIsLoading = false;
        this.cdr.detectChanges();
      },
    });
  }

  public onSelectCourse(event: any): void {
    if (!event) return;
    this.groupId = null;
    this.groupsOptions = [];

    this.courseId = event;
    this.isGroupOptionsIsLoading = true;
    this._adminService.getGroups(event).subscribe({
      next: (res: any) =>
        (this.groupsOptions = [
          { name: 'Выберите', value: null },
          ...res.map((item: any) => {
            this.groupsMap[item.id] = item;
            return { name: item.name, value: item.id };
          }),
        ]),
      complete: () => {
        this.isGroupOptionsIsLoading = false;
        this.cdr.detectChanges();
      },
    });
  }

  public onSelectGroup(groupId: any): void {
    if (!groupId) return;
    this.groupId = groupId;
    this.isPeriodOptionsIsLoading = true;
    this.periodOptions = [];
    this._streamService.getById(this.groupsMap[groupId].streamId).subscribe({
      next: (res: any) => {
        this.periodOptions = [
          { ...EMPTY_SELECT_VALUE, notPeriod: true },
          { name: 'Учится бесплатно', value: 'ALL', notPeriod: true },
          { name: 'Оплачено за первый месяц', value: 'FIRST', notPeriod: true },
        ];
        if (!res.streamPaymentDates?.length) return;
        this.periodOptions = [
          ...this.periodOptions,
          ...res.streamPaymentDates?.map((item: any) => ({
            name: `${this.datePipe.transform(
              item.paymentAccessibleFrom,
              'dd MMMM'
            )} - ${this.datePipe.transform(
              item.paymentAccessibleTo,
              'dd MMMM'
            )}`,
            value: item.id,
          })),
        ];
      },
      complete: () => {
        this.isPeriodOptionsIsLoading = false;
        this.cdr.detectChanges();
      },
    });
  }

  public onSelectManager(managerId: any): void {
    this.selectedManagerId = managerId;
  }

  public onSelectPeriod(period: any): void {
    if (period === EMPTY_VALUE) {
      this.periodIds = null;
      this.cdr.detectChanges();
      return;
    }
    if (period === 'ALL') {
      this.periodIds = this.periodOptions
        .filter((item) => !item.notPeriod)
        .map((item) => item.value);
      return;
    }
    if (period === 'FIRST') {
      this.periodIds = [];
      return;
    }

    const allPeriodsBeforeSelected: any[] = [];
    for (let item of this.periodOptions) {
      if (item.notPeriod) continue;
      allPeriodsBeforeSelected.push(item.value);
      if (item.value === period) break;
    }

    this.periodIds = [...allPeriodsBeforeSelected];
  }

  public addStudentToCourse(): void {

    this.isAddingStudentToCourse = true;
    if(this.selectedManagerId === '—') {
      this.selectedManagerId = undefined;
    }
    this._adminService
      .addStudentToCourse(
        this.studentId,
        this.groupId!,
        this.periodIds!,
        this.selectedManagerId
      )
      .subscribe({
        next: (res: any) => {
          this.ts.showSuccess('Студент успешно прикреплен');
          this.isAddingStudentToCourse = false;
          this.dialogRef.close({
            event: 'success',
            data: {
              subjectId: this.subjectId,
              group: {
                subjectId: this.subjectId,
                ...this.groupsMap[this.groupId!],
              },
            },
          });
          this.cdr.detectChanges();
        },
        error: (err) => {
          // this.ts.showError('Произошла ошибка');
          this.isAddingStudentToCourse = false;
          this.cdr.detectChanges();
        },
        complete: () => {
          this.isAddingStudentToCourse = false;
          this.cdr.detectChanges();
        },
      });
  }

  public cancel(): void {
    this.dialogRef.close({ event: 'cancel' });
  }

  public getManagers(): void {
    const monthNumber = new Date().getMonth() + 1;
    this.isManagerOptionsLoading = true;
    this._managerService
      .getAllPageable({
        page: 0,
        size: 999,
        month: monthNumber,
      })
      .subscribe({
        next: (res: any) => {
          const options = res.content?.map((item: any) => ({
            name: `${item.lastname} ${item.firstname}`,
            value: item.id,
          }));
          this.managerOptions = [
            {
              name: 'Без менеджера',
              value: EMPTY_VALUE,
            },
            ...options,
          ];
        },
        error: (error) => {
          console.log(error);
        },
        complete: () => {
          this.isManagerOptionsLoading = false;
          this.cdr.detectChanges();
        },
      });
  }
}
