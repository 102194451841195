import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import VimeoPlayer from '@vimeo/player';
import { VideoDTO } from 'src/app/shared/models';

@Component({
  selector: 'app-vimeo-player',
  templateUrl: './vimeo-player.component.html',
  styleUrls: ['./vimeo-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VimeoPlayerComponent implements OnChanges, AfterViewInit {
  @ViewChild('vimeoPlayer') vimeoPlayer: ElementRef | undefined;

  @Input() videoTimings!: number[][] | null;
  @Input() videoUrl: string | undefined;
  @Output() sendPlayed: EventEmitter<VideoDTO> = new EventEmitter<VideoDTO>();

  private player: VimeoPlayer | undefined;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['videoUrl'] && this.videoUrl) {
      this.subscribeToVideoUrl();
    }
  }
  ngAfterViewInit(): void {
   this.subscribeToVideoUrl();
  }

  private subscribeToVideoUrl(): void {
    if(!this.videoUrl) {
      return;
    }

      const targetElement = this.vimeoPlayer?.nativeElement;

      if(this.player) {
        this.player.destroy()
      }

      if(this.videoUrl && this.vimeoPlayer) {

        this.player = new VimeoPlayer(targetElement, {
          url: this.videoUrl,
          loop: false,
        });
      };

      this.player?.on('play', () => {
        this.player?.getDuration().then(videoDuration => {
          setInterval(() => {
            this.player?.getPlayed().then((watchedTimePeriods: number[][]) => {
              let totalTimings;
              const roundedeWatchedTimePeriods: number[][] = this.roundNumbers(watchedTimePeriods);
        
              if(this.videoTimings) {
                totalTimings = this.mergeTimings(this.videoTimings, roundedeWatchedTimePeriods);
              }
              else {
                totalTimings = roundedeWatchedTimePeriods;
              }
        
              let totalWatchedTime = this.countWatchedTime(totalTimings);
              const played = totalWatchedTime/videoDuration;
  
              this.sendPlayed.emit({
                duration: videoDuration,
                timings: totalTimings,
                percent: played
              });
            });
          }, 2000)
        });
      });
      this.cdr.detectChanges();
  }

  private mergeTimings(beforeTimings: number[][], currentTimings: number[][]): number[][] {
    const allIntervals = beforeTimings.concat(currentTimings);
    if (allIntervals.length <= 1) {
      return allIntervals; // No need to merge if there's only one or zero intervals
    }

    // Sort the intervals based on their duration (ascending order)
    allIntervals.sort((a, b) => (a[1] - a[0]) - (b[1] - b[0]));

    const mergedIntervals: number[][] = [];

    for (let i = 0; i < allIntervals.length; i++) {
      let isMerged = false;
      for(let j = 0; j < allIntervals.length; j++) {
        if(allIntervals[i][1] > allIntervals[j][0] && allIntervals[i][0] < allIntervals[j][0]) {
          isMerged = true;
          mergedIntervals.push([Math.min(allIntervals[i][0], allIntervals[j][0]), Math.max(allIntervals[i][1], allIntervals[j][1])]);
        }
      }

      if(!isMerged) {
        mergedIntervals.push(allIntervals[i]);
      }
    }
    return mergedIntervals;
  };

  private roundNumbers(array: number[][]): number[][] {
    return array.map(subArray => subArray.map(number => Math.round(number)));
  }

  private countWatchedTime(time: number[][]): number {
    return time.map(item => item[1] - item[0]).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }
}
