import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { StudentSideBarComponent } from './student-side-bar/student-side-bar.component';
import { CoursesCardComponent } from './courses-card/courses-card.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ResponsiblesCardComponent } from './responsibles-card/responsibles-card.component';
import { CoursesCatalogButtonComponent } from './courses-catalog-button/courses-catalog-button.component';
import { PastEventsCardComponent } from './past-events-card/past-events-card.component';
import { ItemComponent as SidebarItemComponent} from './student-side-bar/item/item.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SideBarCoursesCardComponent } from './student-side-bar/side-bar-courses-card/side-bar-courses-card.component';
import { CoursesCalendarComponent } from './courses-calendar/courses-calendar.component';
import { ItemComponent as CalendarItemComponent} from './courses-calendar/item/item.component';
import { ProgressCardComponent } from './progress-card/progress-card.component';
import { RatingCardComponent } from './rating-card/rating-card.component';
import { ResponsibleDialogComponent } from './responsible-dialog/responsible-dialog.component';
import { StudyMaterialHolderComponent } from './study-material-holder/study-material-holder.component';
import { MiniTestComponent } from './mini-test/mini-test.component';
import { QuestionsModule } from './questions/questions.module';
import { AdditionalMaterialsDialogComponent } from './additional-materials-dialog/additional-materials-dialog.component';
import { AttachFileComponent } from './attach-file/attach-file.component';
import { FinishTestComponent } from './finish-test/finish-test.component';
import { TestResultComponent } from './test-result/test-result.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsMobileModule } from '../components-mobile/components-mobile.module';
import { TranslateModule } from "@ngx-translate/core";
import { QuestionsStepperComponent } from './questions-stepper/questions-stepper.component';
import { DownloadHomeworkComponent } from './download-homework/download-homework.component';
import { AppGroupsListComponent } from './app-groups-list/app-groups-list.component';
import { SideBarCoursesSortComponent } from "./student-side-bar/side-bar-courses-sort/side-bar-courses-sort.component";
import { AdditionalMatUsefullLinksListComponent } from './student-side-bar/additional-mat-usefull-links-list/additional-mat-usefull-links-list.component';
import { QuestionnairePassingComponent } from './questionnaire-passing/questionnaire-passing.component';

@NgModule({
    declarations: [
        CoursesCardComponent,
        ResponsiblesCardComponent,
        CoursesCatalogButtonComponent,
        PastEventsCardComponent,
        StudentSideBarComponent,
        SidebarItemComponent,
        CoursesCardComponent,
        SideBarCoursesCardComponent,
        CoursesCalendarComponent,
        CalendarItemComponent,
        ProgressCardComponent,
        RatingCardComponent,
        ResponsibleDialogComponent,
        StudyMaterialHolderComponent,
        MiniTestComponent,
        AdditionalMaterialsDialogComponent,
        AttachFileComponent,
        FinishTestComponent,
        TestResultComponent,
        QuestionsStepperComponent,
        DownloadHomeworkComponent,
        AppGroupsListComponent,
        SideBarCoursesSortComponent,
        AdditionalMatUsefullLinksListComponent,
        QuestionnairePassingComponent
    ],
    exports: [
        StudentSideBarComponent,
        CoursesCardComponent,
        ResponsiblesCardComponent,
        PastEventsCardComponent,
        MatCheckboxModule,
        CoursesCalendarComponent,
        ProgressCardComponent,
        RatingCardComponent,
        ResponsibleDialogComponent,
        StudyMaterialHolderComponent,
        MiniTestComponent,
        QuestionsModule,
        AdditionalMaterialsDialogComponent,
        AttachFileComponent,
        FinishTestComponent,
        TestResultComponent,
        ComponentsMobileModule,
        SideBarCoursesCardComponent,
        QuestionsStepperComponent,
        DownloadHomeworkComponent,
        AppGroupsListComponent,
        AdditionalMatUsefullLinksListComponent,
        QuestionnairePassingComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        MatProgressSpinnerModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        QuestionsModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentsMobileModule,
        TranslateModule
    ],
    providers: [
        DatePipe
    ]
})
export class ComponentsModule { }
