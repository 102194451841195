import {Injectable} from '@angular/core';
import {ToastrService} from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class ToastService {


  constructor(private toastr: ToastrService) {
  }

  public showSuccess(message: string) {
    this.toastr.success(message, '', {toastClass: 'toastr-success'});
  }

  public showError(message: string): void {
    this.toastr.error(message, '', {
      toastClass: 'toastr-error',
    });
  }

  public showInfo(message: string): void {
    this.toastr.info(message, '', {
      toastClass: 'toastr-info',
    });
  }

}
