<app-dialog title="ADD_LESSON_TO_COURSE"
            confirmBntText="ADD_LESSON"
            [isOpen]="isOpen"
            [disabledConfirm]="form.invalid || isSaving"
            (confirmEvent)="addLesson()"
            (closeEvent)="closeDialog.emit()">
    <form class="form" [formGroup]="form">
      <ng-container formArrayName="list">
        <div class="flex gap-16"
             *ngFor="let item of list.controls; index as index"
             [formGroupName]="index">

          <app-select
            class="w-100"
            [options]="subjectOptions"
            name="Предмет"
            formControlName="subject"/>

          <app-select
            class="w-100"
            [options]="list.controls[index].get('courseList')?.value"
            [isActive]="!!list.controls[index].get('courseList')?.value?.length"
            name="Курс"
            formControlName="course"
            [isLoading]="isCourseOptionsIsLoading"
          ></app-select>

          <app-select
            class="w-100"
            [options]="list.controls[index].get('themeList')?.value"
            [isActive]="!!list.controls[index].get('themeList')?.value?.length"
            name="Тема"
            formControlName="theme"
            [isLoading]="isThemesOptionsIsLoading"
          ></app-select>
        </div>
      </ng-container>

      <app-button (onButtonClick)="addListItem()" text="add" leftIcon="./assets/svg/plus-white.svg"/>
    </form>
</app-dialog>
