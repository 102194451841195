import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StudentModalComponent } from './components/student-modal/student-modal.component';
import { StudentModalReplyCuratorComponent } from './components/student-modal-reply-curator/student-modal-reply-curator.component';
import { StudentModalReplyStudentComponent } from './components/student-modal-reply-student/student-modal-reply-student.component';
import { LectureCardComponent } from './components/lecture-card/lecture-card.component';
import { LectureCardEditComponent } from './components/lecture-card-edit/lecture-card-edit.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { StudentsListItemComponent } from './components/students-list-item/students-list-item.component';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { StudentListStatusComponent } from './components/student-list-status/student-list-status.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CuratorSidebarItemComponent } from './components/curator-sidebar-item/curator-sidebar-item.component';
import { MenuHeaderStudentComponent } from './components/menu-header-student/menu-header-student.component';
import { MenuHeaderStudentStatusComponent } from './components/menu-header-student-status/menu-header-student-status.component';
import { MenuHeaderStudentCommentsComponent } from './components/menu-header-student-comments/menu-header-student-comments.component';
import { MenuStudentGradeComponent } from './components/menu-student-grade/menu-student-grade.component';
import { MenuStudentCommentComponent } from './components/menu-student-comment/menu-student-comment.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StudentProgressComponent } from './components/student-progress/student-progress.component';
import { ComponentsModule } from 'src/app/shared/components/components.module';
import { TranslateModule } from '@ngx-translate/core';
import { TaskCardComponent } from "./components/task-card/task-card.component";

@NgModule({
  declarations: [
    StudentModalComponent,
    StudentModalReplyCuratorComponent,
    StudentModalReplyStudentComponent,
    LectureCardComponent,
    LectureCardEditComponent,
    StudentsListItemComponent,
    StudentListStatusComponent,
    CuratorSidebarItemComponent,
    MenuHeaderStudentComponent,
    MenuHeaderStudentStatusComponent,
    MenuHeaderStudentCommentsComponent,
    MenuStudentGradeComponent,
    MenuStudentCommentComponent,
    StudentProgressComponent,
    TaskCardComponent
  ],
  exports: [
    StudentModalComponent,
    StudentModalReplyCuratorComponent,
    StudentModalReplyStudentComponent,
    LectureCardComponent,
    LectureCardEditComponent,
    StudentsListItemComponent,
    StudentListStatusComponent,
    CuratorSidebarItemComponent,
    MenuHeaderStudentComponent,
    MenuHeaderStudentStatusComponent,
    MenuHeaderStudentCommentsComponent,
    MenuStudentGradeComponent,
    MenuStudentCommentComponent,
    StudentProgressComponent,
    TaskCardComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    RouterModule,
    PipesModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    ComponentsModule,
    TranslateModule
  ],
})
export class CuratorFeatureModule {}
