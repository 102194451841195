import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LinkSubject, SubjectDTO } from 'src/app/core/model/interface/subject';
import { AdminService } from 'src/app/core/services/admin/admin.service';
import { SubjectsService } from './../../../../../services/subjects/subjects.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Roles } from 'src/app/core/model';

@Component({
  selector: 'app-admin-headteacher-subjects',
  templateUrl: './admin-headteacher-subjects.component.html',
  styleUrls: ['./admin-headteacher-subjects.component.scss']
})
export class AdminHeadteacherSubjectsComponent implements OnInit, OnChanges {
  @Input() headteacherId!: string;
  @Input() subjects: SubjectDTO[] = [];
  @Input() role: Roles = Roles.Methodist;
  
  public subjectsCopy: SubjectDTO[] = [];
  public fullSubjectsList: SubjectDTO[] = [];

  public isAddSubjectsOpen: boolean = false;

  constructor(
    private adminService: AdminService,
    private cdr: ChangeDetectorRef,
    private subjectsService: SubjectsService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.subjectsService.getSubjectsListNotMapped().subscribe({
      next: (res) => {
        this.fullSubjectsList = res;
        this.cdr.detectChanges();
      }
    })
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.subjectsCopy = [...this.subjects];
  }

  public openAddSubjects(): void {
    this.isAddSubjectsOpen = true;
  }

  public closeAddSubjects(): void {
    this.isAddSubjectsOpen = false;
  }

  public removeSubject(id: string): void {
    
    switch(this.role) {
      case Roles.HeadTeacher: {
        const data: LinkSubject = {
          headteacherId: this.headteacherId,
          subjectIds: [id]
        }
        this.adminService.removeSubjectsToHeadteacher(data).subscribe({
          next: (res) => {
            this.subjectsCopy = this.subjectsCopy.filter(item => item.id !== id);
            this.cdr.markForCheck();
          }
        })
        break;
      };
      case Roles.Methodist: {
        const data: LinkSubject = {
          methodistId: this.headteacherId,
          subjectIds: [id]
        }
        this.adminService.removeSubjectsToMethodist(data).subscribe({
          next: (res) => {
            this.subjectsCopy = this.subjectsCopy.filter(item => item.id !== id);
            this.cdr.markForCheck();
          }
        })
        break;
      }
    }
  }

  public confirmAddSubjects(list: string[]): void {
    for(let item of this.fullSubjectsList) {
      if(list.find(id => id === item.id)) {
        this.subjectsCopy.push(item);
      }
    }
    this.closeAddSubjects();
  }
}
