import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeDiffToHours'
})
export class TimeDiffToHoursPipe implements PipeTransform {

  transform(timeDiffInMilliseconds: number | null): number | null {
    if (timeDiffInMilliseconds == null) {
      return null; // Return null if timeDiffInMilliseconds is null or undefined
    }
  
    const millisecondsInOneHour = 60 * 60 * 1000; // Number of milliseconds in one hour
    const hours = Math.floor(timeDiffInMilliseconds / millisecondsInOneHour);
  
    return hours;
  }
  

}
