<app-dialog
  title="ADD_SUBJECT"
  [isOpen]="isOpen"
  (closeEvent)="closeEvent.emit()"
  (confirmEvent)="addSubjects()"
  [disabledConfirm]="form.invalid">
  <form [formGroup]="form">
    <app-multi-select
          *ngIf="form.get('subjects')"
          name="SUBJECTS"
          [options]="subjectOptions"
          [withSearch]="true"
          (selectedValue)="subjectSelected($event)">
      </app-multi-select>
  </form>
</app-dialog>
