import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BaseRoutes, LessonType, StudentGroupDTO, StudentRoutes } from 'src/app/core/model';
import { Languages, RedirectInfo } from 'src/app/shared/models';
import { SUBJECTS_ID } from 'src/app/shared/models/subjects';
import { StudentHelperService } from './../../service/student-helper.service';
import { Router } from '@angular/router';

interface CuratorTeacherInputs {
  curatorFullName: string | null;
  curatorUsername: string | null;
  curatorProfileUrl: string | null;
  curatorPhoneNumber: string | null;
  teacherFullName: string | null;
  teacherProfileUrl: string | null;
  teacherDesc: string | null;
}

@Component({
  selector: 'app-groups-list',
  templateUrl: './app-groups-list.component.html',
  styleUrls: ['./app-groups-list.component.scss']
})
export class AppGroupsListComponent implements OnInit, OnDestroy {
  @Input() groups: StudentGroupDTO[] = [];

  public subjectsId = SUBJECTS_ID;

  public language: Languages = Languages.KZ;
  private languageSubscription!: Subscription;

  public currentTime = new Date();
  public currentPlusOneHour: Date = new Date();
  public currentPlusOneDay: Date = new Date(new Date().getTime() + 86400000);
  public currentPlusOneWeek: Date = new Date();

  public responsiblesIsOpen: boolean = false;

  public currentResponsables: CuratorTeacherInputs | undefined = undefined;

  constructor(
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private studentHelperService: StudentHelperService,
    private router: Router
  ) {
    this.languageSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.language = event.lang as Languages;
      this.cdr.detectChanges();
    });
  }

  ngOnInit(): void {
    console.log(this.currentTime)
    this.currentPlusOneHour.setHours(this.currentPlusOneHour.getHours() + 1);
    this.currentPlusOneWeek.setDate(this.currentPlusOneWeek.getDate() + 7);
  }
  ngOnDestroy(): void {
    this.languageSubscription.unsubscribe();
  }

  public openLesson(groupId: string, lessonId: string, name: string, type: LessonType, courseId?: string): void {
    const data: RedirectInfo = {
      groupId: groupId,
      courseId: courseId,
      id: lessonId,
      name: name,
      lessonType: type
    };
    this.router.navigate([`${BaseRoutes.Student}/${this.studentHelperService.generateLessonsSiblingsRoute(data)}`])
  }

  public goToCatalog(): void {
    this.router.navigate([`/${BaseRoutes.CataloguesWithOpenBasket}`]);
  }

  public toggleResponsibleDialog(item: StudentGroupDTO): void {
    this.responsiblesIsOpen = !this.responsiblesIsOpen;
  }

  public openResponsable(item: StudentGroupDTO): void {
    this.responsiblesIsOpen = true;
    if (this.responsiblesIsOpen) {
      // Initialize currentResponsables when the dialog is opened
      this.currentResponsables = {
        curatorFullName: item.curatorFirstName ? (item.curatorFirstName + ' ' + item.curatorLastName) : null,
        curatorUsername: item.curatorUsername,
        curatorProfileUrl: item.curatorProfileUrl,
        curatorPhoneNumber: item.curatorPhoneNumber,
        teacherFullName: item.teacherFullName,
        teacherProfileUrl: item.teacherProfileUrl,
        teacherDesc: item.teacherRegalia
      };

      console.log(this.currentResponsables)
    } else {
      // Reset currentResponsables when the dialog is closed
      this.currentResponsables = undefined;
    }
  }

  public closeResponsibleDialog(): void {
    this.responsiblesIsOpen = false;
    this.currentResponsables = undefined;
  }

  public goToWhatsappGroup(link: string): void {
    window.open(link, "_blank")
  }

  public goToGroupPage(groupId: string): void {
    this.studentHelperService.setCurrentGroupId(groupId);
    this.router.navigate([`${BaseRoutes.Student}/${StudentRoutes.Education}/${StudentRoutes.ThemesMobile}/${groupId}`]);
  }
}
