import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ProgressDTO, StudentProgress } from 'src/app/core/model';

@Component({
  selector: 'app-menu-student-comment',
  templateUrl: './menu-student-comment.component.html',
  styleUrls: ['./menu-student-comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuStudentCommentComponent implements OnInit, OnDestroy {
  public form: FormGroup;

  @Input() progress: StudentProgress | ProgressDTO | undefined;
  @Input() buttonOutOfBox: boolean = false;
  @Input() isViewTaskMode: boolean = false;
  @Input() canSendItself: boolean = true;
  @Input() saveEventSubject: Observable<void> | undefined;
  @Input() clearInputSubject: Observable<void> | undefined;
  @Output() onSubmitListener: EventEmitter<any> = new EventEmitter();

  private saveEventSubscription: Subscription | undefined;
  private clearEventSubscription: Subscription | undefined;


  constructor() {
    this.form = new FormGroup({
      text: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.saveEventSubscription = this.saveEventSubject?.subscribe(() => {
      this.onSubmit();
    });

    this.clearEventSubscription = this.clearInputSubject?.subscribe(() => {
      this.form.reset();
    })
  };

  ngOnDestroy(): void {
    this.saveEventSubscription?.unsubscribe();
    this.clearEventSubscription?.unsubscribe();
  }

  public onSubmit(): void {
    this.onSubmitListener.emit({
      text: this.form.controls['text'].value?.replace(/\n/g, '<br>'),
      progress: this.progress,
    });
  }
}
