import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utcToLocalDateFormet'
})
export class UtcToLocalDateFormetPipe implements PipeTransform {

  transform(utcTime: Date | string | null): Date {

    // Check if utcTime is provided
    if (!utcTime) {
      return new Date();
    }
    // Convert UTC time to local time
    const utcDate = new Date(utcTime);
    const localTimezoneOffset = new Date().getTimezoneOffset();
    const localTimeDate = new Date(utcDate.getTime() - localTimezoneOffset * 60000);

    // Format the local time as a string
    return localTimeDate;
  }

}
