import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { FormControl, FormGroup } from "@angular/forms";
import { ROLES_OPTIONS } from "../../../consts/common";
import { ButtonTypes } from "../../../models/enums/button.enum";
import { SelectValue } from "../../../models";
import { AuthService } from "../../../../core/services/auth/auth.service";
import { Roles } from "../../../../core/model";

@Component({
  selector: 'app-announcements-filter',
  templateUrl: './announcements-filter.component.html',
  styleUrls: ['./announcements-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnouncementsFilterComponent implements OnInit {
  form!: FormGroup;

  public buttonTypes = ButtonTypes;
  public rolesOptions = ROLES_OPTIONS;
  public rolesEnum = Roles;
  public courseOptions: SelectValue[] = [];
  public groupOptions: SelectValue[] = [];
  public role: Roles = Roles.Curator;

  constructor(
    public dialogRef: MatDialogRef<AnnouncementsFilterComponent>,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.role = this.authService.getRole();

    switch (this.role) {
      case Roles.HeadTeacher: {
        this.rolesOptions = this.rolesOptions.filter(x => {
          return x.value === Roles.Curator || x.value === Roles.Student;
        });
        break;
      }
      case Roles.Admin: {
        this.rolesOptions = this.rolesOptions.filter(x => {
          return x.value === Roles.Curator ||
            x.value === Roles.HeadTeacher ||
            x.value === Roles.Student;
        });
        break;
      }
    }
  }

  private createForm(): void {
    this.form = new FormGroup({
      role: new FormControl('')
    });
  }

  public multiSelect(list: string[], formControlName: string) {
    this.form?.patchValue({[formControlName]: list});
  }

  public resetForm(): void {
    this.form.reset();
  }

  public closeMenu(isSubmit: boolean = false): void {
    this.dialogRef.close(isSubmit ? this.form.getRawValue() : null);
  }
}
