import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import {
  BodyStudentRating,
  CommentDTO,
  LessonDTO,
  ProgressDTO,
  StudentCourseDTO,
  StudentDeadline,
  StudentGroupDTO,
  StudentRating,
  StudentTasks,
  TestAnswerDTO,
  LessonWithProgress,
  StudentGroupThemeDTO,
  QuizFinishDTO,
} from '../../model';
import { SubjectDTO } from '../../model/interface/subject';
import { IGroup } from "../../model/interface/group";
import { RedirectInfo } from 'src/app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class StudentService {
  private currentGroupId: string = "";

  private mobileVersionStartFrom = 999;

  constructor(private httpClient: HttpClient,) { }

  public getStudentGroups(): Observable<StudentGroupDTO[]> {
    return this.httpClient.get<StudentGroupDTO[]>(`/v1/student/groups`).pipe(
      map(res => {
        return res;
      })
    )
  };

  public getCurrentGroupById(): string {
    return this.currentGroupId;
  }
  public setCurrentGroupId(id: string): void {
    this.currentGroupId = id;
  }

  public getStudentCourse(groupId: string, courseId: string): Observable<StudentCourseDTO> {
    return this.httpClient.get<StudentCourseDTO>(`/v1/student/groups/${groupId}/courses/${courseId}`).pipe(
      map(res => {
        return res;
      })
    )
  };

  public getStudentGroupById(groupId: string, direction: 'ASC' | 'DESC' = 'ASC'): Observable<StudentGroupThemeDTO> {
    return this.httpClient.get<StudentGroupThemeDTO>(`/v2/student/groups/${groupId}/themes?direction=${direction}`);
  }

  public getStudentGroupsAllInfo(): Observable<StudentGroupDTO[]> {
    return this.httpClient.get<StudentGroupDTO[]>(`/v2/student/groups/allinfo`)
  }

  public getStudentGroups3(): Observable<any[]> {
    return this.httpClient.get<any[]>(`/v2/student/groups`);
  }

  public getStudentLesson(groupId: string, lessonId: string): Observable<LessonDTO> {
    return this.httpClient.get<LessonDTO>(`/v1/student/groups/${groupId}/lessons/${lessonId}`).pipe(
      map(res => {
        return res;
      })
    )
  };

  public getStudentLessonTest(groupId: string, lessonId: string): Observable<LessonWithProgress> {
    return this.httpClient.get<LessonWithProgress>(`/v1/student/groups/${groupId}/lessons/${lessonId}`).pipe(
      map(res => {
        return res;
      })
    )
  };

  public getStudentLesson2(groupId: string, lessonId: string): Observable<LessonWithProgress> {
    return this.httpClient.get<LessonWithProgress>(`/v2/student/groups/${groupId}/lessons/${lessonId}`).pipe(
      map(res => {
        return res;
      })
    )
  };

  public getStudentProgress(groupId: number, themeId: number): Observable<StudentGroupDTO[]> {
    const params = new HttpParams()
      .set('groupId', groupId.toString())
      .set('themeId', themeId.toString());

    return this.httpClient.get<StudentGroupDTO[]>('/v1/student/progress', { params }).pipe(
      map(res => res)
    );
  }

  public startQuiz(groupId: string, quizId: string): Observable<LessonWithProgress> {
    return this.httpClient.patch<LessonWithProgress>(`/v2/student/progresses/groups/${groupId}/quizzes/${quizId}/start`, {}).pipe(
      map(res => {
        return res;
      })
    )
  }
  public finishQuiz(groupId: string, quizId: string, userAnswers: QuizFinishDTO): Observable<ProgressDTO> {
    console.log('x')
    return this.httpClient.patch<ProgressDTO>(`/v2/student/progresses/groups/${groupId}/quizzes/${quizId}/finish`, userAnswers).pipe(
      map(res => {
        return res;
      })
    )
  }

  public isMobileVersionCheck(currWidthInPx: number): boolean {
    if(currWidthInPx > this.mobileVersionStartFrom) {
      return false;
    }
    return true;
  }

  public getLessonsSiblings(groupId: string,lessonId: string): Observable<RedirectInfo[]> {
    return this.httpClient.get<RedirectInfo[]>(`/v1/student/groups/${groupId}/lessons/${lessonId}/siblings`);
  };

  public patchVideoTimings(groupId: string, lectureId: string, data: number[][]): Observable<ProgressDTO> {
    return this.httpClient.patch<ProgressDTO>(`/v1/student/progresses/groups/${groupId}/lectures/${lectureId}/videotimings`, data);
  }

  public getClosestDeadlines(): Observable<StudentDeadline | null> {
    return this.httpClient.get<StudentDeadline | null>(`/v1/student/deadlines/closest`);
  }

  public getStudentRating(groupId: string): Observable<StudentRating> {
    return this.httpClient.get<StudentRating>(`/v1/student/rating`, { params: { groupId } });
  }

  public saveComment(groupId: string, lessonId: string, data: Partial<CommentDTO>): Observable<ProgressDTO> {
    return this.httpClient.patch<ProgressDTO>(`/v1/student/progresses/groups/${groupId}/lessons/${lessonId}/comment`, data);
  }

  public viewMaterial(groupId: string, homeworkId: string, fileId: string): Observable<string> {
    return this.httpClient.patch<string>(`/v1/student/progresses/groups/${groupId}/lectures/${homeworkId}/viewedmaterails/add`, {material: fileId});
  }
  public unviewMaterial(groupId: string, homeworkId: string, fileId: string): Observable<string> {
    return this.httpClient.patch<string>(`/v1/student/progresses/groups/${groupId}/lectures/${homeworkId}/viewedmaterails/remove`, { material: fileId});
  }

  public viewedVideo(groupId: string, lectureId: string): Observable<ProgressDTO> {
    return this.httpClient.patch<ProgressDTO>(`/v1/student/progresses/groups/${groupId}/lectures/${lectureId}/videoviewed`, {});
  }

  public getMySubjects(): Observable<SubjectDTO[]> {
    return this.httpClient.get<SubjectDTO[]>(`/v1/student/subjects`);
  }

  public getTopSudents(groupId: string): Observable<BodyStudentRating[]> {
    return this.httpClient.get<BodyStudentRating[]>(`/v1/student/rating/top`, {
      params: {
        groupId: groupId
      }
    })
  }

  public getStudentTask(): Observable<StudentTasks> {
    return this.httpClient.get<StudentTasks>('/v1/student/deadlines/upcoming');
  }

  public getCourseFinished(id: string, status: boolean = false): Observable<any> {
    return this.httpClient.post<any>(`/v1/streams/${id}/coursefinished/${status}`, {
      id: id,
      status: status
    });
  }

  public getStudentGroupsInfo(): Observable<any> {
    return this.httpClient.get<any>(`/v2/student/groups/allinfo`);
  }

  public getStudentActive(): Observable<IGroup[]> {
    return this.httpClient.get<IGroup[]>(`/v1/student/groups/active`);
  }

  public getStudentInactive(): Observable<IGroup[]> {
    return this.httpClient.get<IGroup[]>(`/v1/student/groups/inactive`);
  }

  public editComment(commentId: string, data: Partial<CommentDTO>): Observable<ProgressDTO> {
    return this.httpClient.put<ProgressDTO>(`/v1/student/progresses/comments/${commentId}`, {commentText: data.commentText});
  }

  public deleteComment(commentId: string): Observable<void> {
    return this.httpClient.delete<void>(`/v1/student/progresses/comments/${commentId}`);
  }

  public commented(groupId: string, lessonId: string): Observable<any> {
    return this.httpClient.patch<any>(`/v1/student/progresses/groups/${groupId}/lessons/${lessonId}/commented`, {});
  }

  public checkActivePromoCode(streamIds: string[], subjectIds: string[]): Observable<boolean> {
    return this.httpClient.get<boolean>(`/v1/student/promocodes/activeexits`, {
      params: {
        streamIds: streamIds,
        subjectIds: subjectIds
      }
    });
  }

  public rateVideoLesson(groupId: string, lessonId: string, rating: number = 0, comment: string = ''): Observable<any> {
    return this.httpClient.post<any>(`/v2/student/ratings/groups/${groupId}/lessons/${lessonId}`, {
      rating: rating,
      comment: comment
    });
  }

  public finishTextLesson(groupId: string, lessonId: string): Observable<ProgressDTO> {
    return this.httpClient.post<ProgressDTO>(`/v2/student/progresses/groups/${groupId}/lessons/${lessonId}/complete`, {})
  }
}
