import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeDiff',
  pure: true
})
export class TimeDiffPipe implements PipeTransform {
  transform(dividend: string | Date | null, divisor: string | Date | null): number | null {
    if (dividend == null || divisor == null) {
      return null; // Return null if either parameter is null
    }
  
    const dividendDate = this.parseDate(dividend);
    const divisorDate = this.parseDate(divisor);

    const timeDiff = divisorDate.getTime() - dividendDate.getTime();
  
    return Math.abs(timeDiff);
  }
  


  private parseDate(input: string | Date): Date {
    if (input instanceof Date) {
      return input;
    } else {
      return new Date(input);
    }
  }
}
