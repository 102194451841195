import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { LessonDTO, StudentProgress } from 'src/app/core/model';

export type StudentListStatus = 'PASSED' | 'PASSOFF' | 'VERIFIED';

@Component({
  selector: 'app-student-list-status',
  templateUrl: './student-list-status.component.html',
  styleUrls: ['./student-list-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class StudentListStatusComponent {
  @Input() students: StudentProgress[] = [];
  @Input() status: StudentListStatus | null = null;
  @Input() lesson: LessonDTO | undefined;
  @Input() selectedStudentId: string = "";
  @Output() onDetailStudentClick: EventEmitter<StudentProgress> = new EventEmitter();
  @Input() width: number = 0;

  public onDetailClickHandler(student: StudentProgress): void {
    this.onDetailStudentClick.emit(student);
  }
}
