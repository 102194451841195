import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {
  CoursesDTO,
  CuratorUser,
  ICommonUser,
  MethodistUser,
  Roles,
  StudentGroupDTO,
  StudentUser
} from 'src/app/core/model';
import { IEvent, IEventCreate } from 'src/app/core/model/interface/event';
import { EventAnnounce, SelectValue, TabEventAnnounceT } from 'src/app/shared/models';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HeadTeacherService } from 'src/app/core/services/head-teacher/head-teacher.service';
import { CuratorService } from 'src/app/core/services/curator/curator.service';
import { AdminService } from 'src/app/core/services/admin/admin.service';
import { ToastrService } from 'ngx-toastr';
import { DateHelperServiceService } from 'src/app/shared/services/dateHelperService/date-helper-service.service';
import { DatePipe } from '@angular/common';
import { BehaviorSubject } from "rxjs";
import { UsersService } from "../../../../core/services/users/users.service";

@Component({
  selector: 'app-events-annoucements-controller',
  templateUrl: './events-annoucements-controller.component.html',
  styleUrls: ['./events-annoucements-controller.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class EventsAnnoucementsControllerComponent implements OnChanges {
  @Input() isOpen: boolean = false;
  @Input() mode: EventAnnounce = EventAnnounce.EVENT;
  @Input() data: IEvent | undefined;
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  public editorConfig = {
    toolbar: {
      items: [
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList'
      ]
    }
  };

  public readonly AnnounceEventType = EventAnnounce;
  public timeOptions: SelectValue[] = [
    { name: '00:00', value: '00:00' },
    { name: '00:15', value: '00:15' },
    { name: '00:30', value: '00:30' },
    { name: '00:45', value: '00:45' },
    { name: '01:00', value: '01:00' },
    { name: '01:15', value: '01:15' },
    { name: '01:30', value: '01:30' },
    { name: '01:45', value: '01:45' },
    { name: '02:00', value: '02:00' },
    { name: '02:15', value: '02:15' },
    { name: '02:30', value: '02:30' },
    { name: '02:45', value: '02:45' },
    { name: '03:00', value: '03:00' },
    { name: '03:15', value: '03:15' },
    { name: '03:30', value: '03:30' },
    { name: '03:45', value: '03:45' },
    { name: '04:00', value: '04:00' },
    { name: '04:15', value: '04:15' },
    { name: '04:30', value: '04:30' },
    { name: '04:45', value: '04:45' },
    { name: '05:00', value: '05:00' },
    { name: '05:15', value: '05:15' },
    { name: '05:30', value: '05:30' },
    { name: '05:45', value: '05:45' },
    { name: '06:00', value: '06:00' },
    { name: '06:15', value: '06:15' },
    { name: '06:30', value: '06:30' },
    { name: '06:45', value: '06:45' },
    { name: '07:00', value: '07:00' },
    { name: '07:15', value: '07:15' },
    { name: '07:30', value: '07:30' },
    { name: '07:45', value: '07:45' },
    { name: '08:00', value: '08:00' },
    { name: '08:15', value: '08:15' },
    { name: '08:30', value: '08:30' },
    { name: '08:45', value: '08:45' },
    { name: '09:00', value: '09:00' },
    { name: '09:15', value: '09:15' },
    { name: '09:30', value: '09:30' },
    { name: '09:45', value: '09:45' },
    { name: '10:00', value: '10:00' },
    { name: '10:15', value: '10:15' },
    { name: '10:30', value: '10:30' },
    { name: '10:45', value: '10:45' },
    { name: '11:00', value: '11:00' },
    { name: '11:15', value: '11:15' },
    { name: '11:30', value: '11:30' },
    { name: '11:45', value: '11:45' },
    { name: '12:00', value: '12:00' },
    { name: '12:15', value: '12:15' },
    { name: '12:30', value: '12:30' },
    { name: '12:45', value: '12:45' },
    { name: '13:00', value: '13:00' },
    { name: '13:15', value: '13:15' },
    { name: '13:30', value: '13:30' },
    { name: '13:45', value: '13:45' },
    { name: '14:00', value: '14:00' },
    { name: '14:15', value: '14:15' },
    { name: '14:30', value: '14:30' },
    { name: '14:45', value: '14:45' },
    { name: '15:00', value: '15:00' },
    { name: '15:15', value: '15:15' },
    { name: '15:30', value: '15:30' },
    { name: '15:45', value: '15:45' },
    { name: '16:00', value: '16:00' },
    { name: '16:15', value: '16:15' },
    { name: '16:30', value: '16:30' },
    { name: '16:45', value: '16:45' },
    { name: '17:00', value: '17:00' },
    { name: '17:15', value: '17:15' },
    { name: '17:30', value: '17:30' },
    { name: '17:45', value: '17:45' },
    { name: '18:00', value: '18:00' },
    { name: '18:15', value: '18:15' },
    { name: '18:30', value: '18:30' },
    { name: '18:45', value: '18:45' },
    { name: '19:00', value: '19:00' },
    { name: '19:15', value: '19:15' },
    { name: '19:30', value: '19:30' },
    { name: '19:45', value: '19:45' },
    { name: '20:00', value: '20:00' },
    { name: '20:15', value: '20:15' },
    { name: '20:30', value: '20:30' },
    { name: '20:45', value: '20:45' },
    { name: '21:00', value: '21:00' },
    { name: '21:15', value: '21:15' },
    { name: '21:30', value: '21:30' },
    { name: '21:45', value: '21:45' },
    { name: '22:00', value: '22:00' },
    { name: '22:15', value: '22:15' },
    { name: '22:30', value: '22:30' },
    { name: '22:45', value: '22:45' },
    { name: '23:00', value: '23:00' },
    { name: '23:15', value: '23:15' },
    { name: '23:30', value: '23:30' },
    { name: '23:45', value: '23:45' }
  ];
  public tabs: TabEventAnnounceT[] = [];
  public title: string = "";
  public disabled: boolean = true;
  public form!: FormGroup;

  public readonly Roles = Roles;
  public creatorRole: Roles = Roles.Curator;

  public invitedsLabelName: string = "";
  public isInvitedOneLine: boolean = false;

  public subjectOptions: SelectValue[] = [];
  public courseOptions: SelectValue[] = [];
  public groupOptions: SelectValue[] = [];
  public invitedOptions: SelectValue[] = [];

  public service!: any;

  private userProfile$: BehaviorSubject<StudentUser & MethodistUser & CuratorUser | null> =
    new BehaviorSubject<StudentUser & MethodistUser & CuratorUser | null>(null);

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private el: ElementRef,
    private cdr: ChangeDetectorRef,
    private headTeacherService: HeadTeacherService,
    private curatorService: CuratorService,
    private adminService: AdminService,
    private toastrService: ToastrService,
    private dateHelper: DateHelperServiceService,
    private datePipe: DatePipe,
    private usersService: UsersService
    )
  {
    this.form = this.formBuilder.group({
      header: ['', Validators.required],
      description: ['', Validators.required],
    });

    this.userProfile$ = this.usersService.userProfile$;
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    this.creatorRole = this.authService.getRole();

    if(!this.isOpen) {
      this.form.reset();
      this.tabs = [];
    }

    if(this.data) {
      this.setForm();

      this.form.patchValue({header: this.data.header});
      this.form.patchValue({description: this.data.description});
      this.form.patchValue({redirectUrl: this.data.redirectUrl});
      this.setCurrentCreatingForRole(this.data.role);

      if(this.data.role) {
        this.tabs.find(item => {
          if(item.role === this.data?.role) {
            item.active = true;
          }
          else {
            item.active = false;
          }
        })
      }

      if(this.mode === EventAnnounce.EVENT) {
        const startTime = this.dateHelper.convertUTCToLocal(new Date(this.data.startTime))
        const endTime = this.dateHelper.convertUTCToLocal(new Date(this.data.endTime))
        const startDate = this.dateHelper.convertUTCToLocal(new Date(this.data.startTime));

        this.form.patchValue({startTime:  (startTime.getHours() < 9 ? ('0'+ startTime.getHours()) : startTime.getHours())
        + ':' + ((startTime.getMinutes() < 9) ? '0' + startTime.getMinutes() : startTime.getMinutes())});
        this.form.patchValue({ endTime:  (endTime.getHours() < 9 ? ('0'+ endTime.getHours()) : endTime.getHours())
        + ':' + ((endTime.getMinutes() < 9) ? '0' + endTime.getMinutes() : endTime.getMinutes())});
        this.form.patchValue({'startDate': this.datePipe.transform(startDate, 'yyyy-MM-dd')});
      }

      switch (this.creatorRole) {
        case Roles.Curator: {
          const groups: string[] = this.data.groupList.map(item => item.id);
          this.form.patchValue({groups: groups});
          this.curatorService.getStudentsByGroupIds(groups).subscribe({
            next: (res) => {
              this.setStudentsOptions(res);
              if(this.data && this.data.invitedList && this.data.invitedList.length > 0) {
                this.form.patchValue({inviteds: this.data?.invitedList.map(item => item.id)});
              }
              this.cdr.detectChanges();
            },
            error(err) {
              console.log(err);
            },
          })
          break;
        }
        case Roles.HeadTeacher: {
          this.groupSelected(this.data.groupList);
          switch (this.data.role) {
            case Roles.Student: {
            const subjectList: string[] = this.data.subjectList.map(item => item.id);
            this.form.patchValue({subjects: subjectList});

            this.headTeacherService.getCoursesBySubjectIds(subjectList).subscribe({
              next: (res: CoursesDTO[]) => {
                this.setCoursesOptions(res);
                const courses = this.data?.courseList.map(item => item.id);
                this.form.patchValue({courses: courses});

                this.headTeacherService.getGroupsByCourseIds(courses!).subscribe({
                  next: (res: StudentGroupDTO[]) => {
                    const groups = this.data?.groupList.map(item => item.id);
                    this.setGroupsOptions(res);
                    this.form.patchValue({groups: groups});

                    this.headTeacherService.getStudentsByGroupIds(groups!).subscribe({
                      next: (res) => {
                        this.setStudentsOptions(res);
                        this.form.patchValue({inviteds: this.data?.invitedList.map(item => item.id)});
                        this.cdr.detectChanges();
                      },
                      error: (err) => {
                        console.log(err);
                      }
                    })
                    this.cdr.detectChanges();
                  },
                  error: (err: any) => {
                    console.log(err);
                  }
                })
                this.cdr.detectChanges();
              },
              error: (err: any) => {
                console.log(err);
              }
            });
            this.cdr.detectChanges();
            break;
            }
            case Roles.Curator: {
              const subjectList: string[] = this.data.subjectList.map(item => item.id);
              this.form.patchValue({subjects: subjectList});

              this.headTeacherService.getCoursesBySubjectIds(subjectList).subscribe({
                next: (res: CoursesDTO[]) => {
                  this.setCoursesOptions(res);
                  const courses = this.data?.courseList.map(item => item.id);
                  this.form.patchValue({courses: courses});

                  this.headTeacherService.getCuratorsByCourseIds(courses!).subscribe({
                    next: (res: StudentUser[]) => {
                      this.setStudentsOptions(res);
                      this.form.patchValue({inviteds: this.data?.invitedList.map(item => item.id)});
                      this.cdr.detectChanges();
                    },
                    error: (err: any) => {
                      console.log(err);
                    }
                  })
                  this.cdr.detectChanges();
                },
              });
              break;
            }
          }
          break;
        }
        case Roles.Admin: {
         switch (this.data.role) {
          case Roles.Student: {
            const subjectList: string[] = this.data.subjectList.map(item => item.id);
            this.form.patchValue({subjects: subjectList});

            this.adminService.getCoursesBySubjectIds(subjectList).subscribe({
              next: (res: CoursesDTO[]) => {
                this.setCoursesOptions(res);
                const courses = this.data?.courseList.map(item => item.id);
                this.form.patchValue({courses: courses});

                this.adminService.getGroupsByCourseIds(courses!).subscribe({
                  next: (res: StudentGroupDTO[]) => {
                    const groups = this.data?.groupList.map(item => item.id);
                    this.setGroupsOptions(res);
                    this.form.patchValue({groups: groups});

                    this.adminService.getStudentsByGroupIds(groups!).subscribe({
                      next: (res) => {
                        this.setStudentsOptions(res);
                        this.form.patchValue({inviteds: this.data?.invitedList?.map(item => item.id)});
                        this.cdr.detectChanges();
                      },
                      error: (err) => {
                        console.log(err);
                      }
                    })
                    this.cdr.detectChanges();
                  },
                  error: (err: any) => {
                    console.log(err);
                  }
                })
                this.cdr.detectChanges();
              },
              error: (err: any) => {
                console.log(err);
              }
            });
            this.cdr.detectChanges();
            break;
          }
          case Roles.Curator: {
            const subjectList: string[] = this.data.subjectList.map(item => item.id);
            this.form.patchValue({subjects: subjectList});

            this.adminService.getCoursesBySubjectIds(subjectList).subscribe({
              next: (res: CoursesDTO[]) => {
                this.setCoursesOptions(res);
                const courses = this.data?.courseList.map(item => item.id);
                this.form.patchValue({courses: courses});

                this.adminService.getCuratorsByCourseIds(courses!).subscribe({
                  next: (res: StudentUser[]) => {
                    this.setStudentsOptions(res);
                    this.form.patchValue({inviteds: this.data?.invitedList?.map(item => item.id)});
                    this.cdr.detectChanges();
                  },
                  error: (err: any) => {
                    console.log(err);
                  }
                })
                this.cdr.detectChanges();
              },
            });
            break;
          }
          case Roles.HeadTeacher: {
            this.setHeadteachers(this.data.invitedList);
            break;
          }
          break;
         }
        }
      }
    }
    else {
      this.setForm();
      const creatingForRole = this.tabs.find(item => item.active)?.role;
      this.setCurrentCreatingForRole(creatingForRole);
    }

  };

  public setForm(): void {
    switch (this.creatorRole) {
      case Roles.Curator: {
        this.service = this.curatorService;
        break;
      }
      case Roles.HeadTeacher: {
        this.service = this.headTeacherService;

        this.tabs = [
          {
            name: 'FOR_STUDENTS',
            active: true,
            role: Roles.Student
          },
          {
            name: 'FOR_CURATORS',
            active: false,
            role: Roles.Curator
          },
        ];
        break;
      }
      case Roles.Admin: {
        this.service = this.adminService;

        this.tabs = [
          {
            name: 'FOR_ALL',
            active: true,
            role: null,
          },
          {
            name: 'FOR_STUDENTS',
            active: false,
            role: Roles.Student
          },
          {
            name: 'FOR_CURATORS',
            active: false,
            role: Roles.Curator
          },
          {
            name: 'FOR_HEADTEACHERS',
            active: false,
            role: Roles.HeadTeacher
          }
        ];
      }
    }

    switch (this.mode) {
      case EventAnnounce.EVENT: {
        this.form.addControl('startDate', new FormControl('', Validators.required));
        this.form.addControl('startTime', new FormControl('', Validators.required));
        this.form.addControl('endTime', new FormControl('', Validators.required));
        this.form.addControl('redirectUrl', new FormControl(''));
        break;
      }
    }

    this.setTitle();
  }

  public setTitle(): void {
    this.title = "";
    if(this.data) {
      this.title = "EDIT"
    }
    else {
      this.title = "CREATE"
    }

    if(this.mode === EventAnnounce.Announce) {
      this.title = this.title + "_ANNOUNCEMENT"
    }
    if(this.mode === EventAnnounce.EVENT) {
      this.title = this.title + "_EVENT"
    }
  };

  handleTimeInput(event: Event): void {
    const regex: RegExp = /^(?:[01]?[0-9]|2[0-3]):[0-5][0-9]$/;
    const value = (event.target as HTMLInputElement).value;
    if (!regex.test(value)) {
      this.el.nativeElement.value = '';
    }
  }

  public tabClicked(tab: TabEventAnnounceT): void {
    if(this.data) {
      return;
    }
    this.tabs.map(item => {
      if(item.name === tab.name) {
        item.active = true;
      }
      else {
        item.active = false;
      }
    });

    if(this.tabs.find(item => item.active)?.role === Roles.HeadTeacher) {
      this.setHeadteachers();
    }
    this.setCurrentCreatingForRole(this.tabs.find(item => item.active)?.role);
  }

  public setCurrentCreatingForRole(creatingRole: Roles | null | undefined): void {
    this.form.removeControl('subjects');
    this.form.removeControl('courses');
    this.form.removeControl('groups');
    this.form.removeControl('inviteds');
    this.isInvitedOneLine = false;

    switch (this.creatorRole) {
      case Roles.Curator: {
        this.invitedsLabelName = "STUDENTS";

        this.curatorService.getGroups().subscribe({
          next: (res) => {
            this.setGroupsOptions(res)
            this.cdr.detectChanges();
          },
          error(err) {
            console.log(err);
          },
        })

        this.form.addControl('groups', new FormControl('', Validators.required));
        this.form.addControl('inviteds', new FormControl('', Validators.required));
        break;
      }
      case Roles.HeadTeacher: {
        this.form.addControl('subjects', new FormControl('', Validators.required));
        this.form.addControl('courses', new FormControl('', Validators.required));
        this.form.addControl('inviteds', new FormControl('', Validators.required));

        if(creatingRole === Roles.Student) {
          this.form.addControl('groups', new FormControl('', Validators.required));
        }
        this.setSubjectsOptions();

        switch (creatingRole) {
          case Roles.Student: {
            this.invitedsLabelName = "STUDENTS";
            break;
          }
          case Roles.Curator: {
            this.invitedsLabelName = "curators";
            break;
          }
        }
        break;
      }
      case Roles.Admin: {
        switch (creatingRole) {
          case Roles.Student: {
            this.form.addControl('subjects', new FormControl('', Validators.required));
            this.form.addControl('courses', new FormControl('', Validators.required));
            this.form.addControl('groups', new FormControl('', Validators.required));
            this.form.addControl('inviteds', new FormControl('', Validators.required));

            this.invitedsLabelName = "STUDENTS";

            this.setSubjectsOptions();

            break;
          }
          case Roles.Curator: {
            this.form.addControl('subjects', new FormControl('', Validators.required));
            this.form.addControl('courses', new FormControl('', Validators.required));
            this.form.addControl('inviteds', new FormControl('', Validators.required));

            this.invitedsLabelName = "curators";

            this.setSubjectsOptions();

            break;
          }
          case Roles.HeadTeacher: {
            this.isInvitedOneLine = true;
            this.form.addControl('inviteds', new FormControl('', Validators.required));

            this.invitedsLabelName = "HEAD_TEACHERS";

            break;
          }
        }
        break;
      }
    }
  }

  public subjectSelected($event: any) {
    this.form.patchValue({'subjects': $event});

    this.service.getCoursesBySubjectIds($event).subscribe({
      next: (res: CoursesDTO[]) => {
        this.setCoursesOptions(res);
        this.cdr.detectChanges();
      },
      error: (err: any) => {
        console.log(err);
      }
    })
  }
  public courseSelected($event: any) {
    this.form.patchValue({'courses': $event});

    const creatingRole = this.tabs.find(item => item.active)?.role;

    switch (creatingRole) {
      case Roles.Student: {
        this.service.getGroupsByCourseIds($event).subscribe({
          next: (res: StudentGroupDTO[]) => {
            this.setGroupsOptions(res);
            this.cdr.detectChanges();
          },
          error: (err: any) => {
            console.log(err);
          }
        });
        break;
      }
      case Roles.Curator: {
        this.service.getCuratorsByCourseIds($event?.filter((event: any) => event)).subscribe({
          next: (res: CuratorUser[]) => {
            const userProfile = this.usersService.userProfile$.value;
            const filteredCurators = res.filter((curator) => curator.headTeachers?.filter((headTeacher) => headTeacher.id === userProfile?.id).length);
            this.setStudentsOptions(userProfile ? filteredCurators : res);
            this.cdr.detectChanges();
          },
          error: (err: any) => {
            console.log(err);
          }
        })
      }
    }
  }
  public groupSelected($event: any) {
    console.log('event: ', $event)
    this.form.patchValue({'groups': $event});

    const creatingRole = this.tabs.find(item => item.active)?.role;

    switch (this.creatorRole) {
      case Roles.Curator: {
        this.curatorService.getStudentsByGroupIds($event).subscribe({
          next: (res) => {
            this.setStudentsOptions(res);
            this.cdr.detectChanges();
          },
          error: (err) => {
            console.log(err)
          }
        })
        break;
      }
      case Roles.HeadTeacher: {
        switch (creatingRole) {
          case Roles.Student: {
            this.headTeacherService.getStudentsByGroupIds($event).subscribe({
              next: (res) => {
                this.setStudentsOptions(res);
                this.cdr.detectChanges();
              },
              error: (err) => {
                console.log(err);
              }
            })
            break;
          }
          case Roles.Curator: {
            this.headTeacherService.getCuratorsByCourseIds($event).subscribe({
              next: (res) => {
                this.setStudentsOptions(res);
                this.cdr.detectChanges();
              },
              error: (err) => {
                console.log(err);
              }
            });
            break;
          }
        }

        break;
      }
      case Roles.Admin: {
        switch(creatingRole) {
          case Roles.Student: {
            this.adminService.getStudentsByGroupIds($event).subscribe({
              next: (res) => {
                this.setStudentsOptions(res);
                this.cdr.detectChanges();
              },
              error: (err) => {
                console.log(err);
              }
            })
            break;
          }
        }
      }
    }
  }
  public invitedSelected($event: any) {
    this.form.patchValue({'inviteds': $event});

  }

  public save(): void {
    const formValue = this.form.value;
    console.log(formValue);
    const data: IEventCreate = {
      header: formValue.header || '',
      description: formValue.description || '',
      invitedList: formValue.students || formValue.inviteds || formValue.curators || [],
      startTime: this.mode === EventAnnounce.EVENT ? this.dateHelper.timeAndDateToOneValue(formValue.startTime || '', formValue.startDate || '') : "",
      endTime: this.mode === EventAnnounce.EVENT ? this.dateHelper.timeAndDateToOneValue(formValue.endTime || '', formValue.startDate || '') : "",
      subjectList: formValue.subjects || [],
      courseList: formValue.courses || [],
      groupList: formValue.groups || [],
      forAll: (this.creatorRole === Roles.Admin && this.tabs.find(item => item.active)?.role === null) ? true : false,
      role: this.tabs.find(item => item.active)?.role || (this.creatorRole === Roles.Curator ? Roles.Student : null),
      redirectUrl: formValue.redirectUrl
    }

    switch (this.mode) {
      case EventAnnounce.Announce: {
        const api = this.data ?
          this.service.updateAnnounce(this.data.id, data) :
          this.service.createAnnounce(data);
        api.subscribe({
          next: () => {
            this.toastrService.success((this.data ?
              "Вы успешно изменили объявление" :
              "Вы успешно создали объявление"), "Успех");
            this.closeEvent.emit(true);
            this.cdr.detectChanges();
          },
          error: (err: string | undefined)=> {
            this.toastrService.error(err, 'Ошибка');
            this.cdr.detectChanges();
          }
        })
        break;
      }
      case EventAnnounce.EVENT: {
        if(this.data) {
          this.service.updateEvent(this.data.id,data).subscribe({
            next: () => {
              this.toastrService.success("Вы успешно изменили событие", "Успех");
              this.closeEvent.emit(true);
              location.reload();
              this.cdr.detectChanges();
            },
            error: (err: string | undefined)=> {
              this.toastrService.error(err, 'Ошибка');
              this.cdr.detectChanges();
            }
          })
        }
        else {
          this.service.createEvent(data).subscribe({
            next: () => {
              this.toastrService.success("Вы успешно создали событие", "Успех");
              location.reload();
              this.closeEvent.emit(true);
              this.cdr.detectChanges();
            },
            error: (err: string | undefined)=> {
              this.toastrService.error(err, 'Ошибка');
              this.cdr.detectChanges();
            }
          })
        }
        break;
      }
    }
  }

  public setSubjectsOptions(): void {
    if(this.creatorRole === Roles.HeadTeacher) {
      this.headTeacherService.getMySubjects().subscribe({
        next: (res) => {
          this.subjectOptions = res.map(item => {
            return  {
              name: item.nameKz,
              value: item.id
            }
          })
          this.cdr.detectChanges();
        },
        error: (err) => {
          console.log(err);
        }
      })
    }
    else {
      this.subjectOptions = this.headTeacherService.getSubjects();
    }
    console.log(this.subjectOptions);
  };

  public setCoursesOptions(courses: CoursesDTO[]): void {
    this.courseOptions = courses.map(item => {
      return {
        name: item.name,
        value: item.id
      }
    })
  }
  public setGroupsOptions(courses: any[]): void {
    console.log('courses: ', courses);
    console.log('this: ', this.form.get('groups')?.value);
    this.groupOptions = courses.map(item => {
      return {
        name: item.name,
        value: item.id
      }
    })
  }
  public setStudentsOptions(courses: StudentUser[] | CuratorUser[]): void {
    this.invitedOptions = courses.map(item => {
      return {
        name: item.firstname + ' ' + item.lastname,
        value: item.id!
      }
    })
  }

  public setHeadteachers(list?: ICommonUser[]): void {
    this.adminService.getHeadteachersList().subscribe({
      next: (res) => {
        this.setStudentsOptions(res);
        if(list && list.length > 0) {
          this.form.patchValue({inviteds: list.map(item => item.id)});
        }
        this.cdr.detectChanges();
      },
      error: (res) => {
        console.log(res);
      }
    })
  }

  get isDisableForm() {
    return this.data && this.mode === EventAnnounce.Announce ?
      !(this.form.get('header')?.value && this.form.get('description')?.value) :
      this.form.invalid;
  }
}
