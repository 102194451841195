<div class="name" *ngIf="name">{{ name | translate}}</div>

<div class="image-uploader border radius-8 bg-white">
  <input
    #fileInput
    type="file"
    accept=".jpg, .jpeg, .png" (change)="onFileInput($event)"
  />
  <ng-container *ngIf="!imageId">
    <img
      class="plus-icon"
      src="./assets/svg/plus-default-gray.svg"
      (click)="onOpenInput()"
      alt=""
    />
  </ng-container>

  <ng-container *ngIf="imageId">
    <img class="uploaded-image" [src]="imageSrc" alt=""/>
  </ng-container>
</div>
<button class="edit text-md" *ngIf="imageId" (click)="onOpenInput()">
  {{'edit' | translate}}
</button>
