<div class="menu-student-comment">
  <form action="" class="form" [formGroup]="form" (submit)="onSubmit()">
    <div class="wrapper">
      <textarea
        class="text w-100 border radius-8"
        rows="10"
        formControlName="text"
        [placeholder]="'enterComments' | translate"
        [readOnly]="isViewTaskMode"
      >
      </textarea>

      <button
        *ngIf="canSendItself && !isViewTaskMode"
        class="btn flex items-center justify-center"
        [disabled]="form.invalid"
      >
        <img src="assets/svg/send.svg" alt="" />
      </button>
    </div>
  </form>
  <button *ngIf="buttonOutOfBox && !isViewTaskMode" (click)="onSubmit()" class="button iconic-button button-secondary">{{'addComment' | translate}}</button>
</div>
