<div class="comment">
    <div class="title">Домашнее задание</div>
    <div class="file border radius-8 flex items-center">
      <img src="./assets/svg/files/excel.svg" alt="" class="icon">
      hometask.xls</div>

    <div class="description">
      <div class="label">Комментарий:</div>
      <div class="text">Чуть не успел. Прошу помиловать</div>
    </div>
  </div>