<form class="content" [formGroup]="baseForm">
    <app-select
        name="CHOOSE_FOLDER_FOR_TYPE_LESSON"
        placeholder="CHOOSE_FROM_LIST"
        formControlName="courseFolderId"
        [options]="foldersOptions">
    </app-select>

    <app-multi-select
        *ngIf="!data?.common"
        name="STUDENTS_ASSIGNED"
        [options]="studentsList"
        [withSearch]="true"
        [preSelectedOptions]="baseForm.get('usersIds')?.value"
        (selectedValue)="studentsSelected($event)">

    </app-multi-select>
    <div class="editor">
        <app-editor
            [error]="baseFormErrors.description"
            name="DESCRIPTION"
            formControlName="description">
        </app-editor>
    </div>
    <app-input
        [error]="baseFormErrors.videoUrl"
        formControlName="videoUrl"
        name="LINK_TO_VIDEO"
        class="link-to-video"
        placeholder="http://example.com"
        (input)="handleFormControlErrors(baseForm, baseFormErrors, 'videoUrl')"
    >
    </app-input>
    <div class="mini-test">
        <h1 class="test-title">Мини-тест</h1>
        <app-slide-toggle formControlName="rating" [toggleValue]="baseForm.get('rating')?.value" text="TAKE_IN_RATING"></app-slide-toggle>
        <app-input
            [error]="baseFormErrors.miniTestMaxScore"
            formControlName="miniTestMaxScore"
            name="MAX_POINTS_FOR_TASK"
            mask="9*"
            (input)="handleFormControlErrors(baseForm, baseFormErrors, 'miniTestMaxScore')">
        </app-input>
        <div *ngIf="data" class="mini-test-wrapper">
            <app-mini-test-creator [lectureId]="data.id"></app-mini-test-creator>
        </div>
    </div>
    <div class="material">
        <h1 class="material-title">{{'requiredMaterials' | translate}}</h1>
        <div  class="material-uploader">
            <app-file-uploader
                [error]="baseFormErrors.mustHaveMaterials"
                (downloadedEvent)="downloadedMaterialFile($event)"
                (removeEvent)="removeMaterialFile($event)"
                [idList]="baseForm.get('mustHaveMaterials')?.value ? baseForm.get('mustHaveMaterials')?.value : ''">
            </app-file-uploader>
        </div>
    </div>
    <div class="material">
        <h1 class="material-title">{{'additionalMaterials' | translate}}</h1>
        <div  class="material-uploader">
            <app-file-uploader
                [error]="baseFormErrors.additionalMaterials"
                (downloadedEvent)="downloadedAdditionalFile($event)"
                (removeEvent)="removeAdditionalFile($event)"
                [idList]="baseForm.get('additionalMaterials')?.value ? baseForm.get('additionalMaterials')?.value : ''">
            </app-file-uploader>
        </div>
        <app-user-ful-links [list]="userFulLinks" (valueChangesEvent)="userFulLinksChanged($event)"></app-user-ful-links>
    </div>
</form>

