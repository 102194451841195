import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'progressStatusConverter',
})
export class ProgressStatusConverterPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): string {
    switch (value) {
      case 'SUCCESS':
        return 'STATUS_SUCCESS';
      case 'CREATED':
        return 'STATUS_CREATED';
      case 'IN_REVIEW':
        return 'STATUS_IN_REVIEW';
      default:
        return '-';
    }
  }
}
