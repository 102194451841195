<ng-container>
  <div class="admin-modal p-24 border">
    <button
      class="close-btn flex items-center justify-center"
      (click)="cancel()"
    >
      <img src="./assets/svg/plus.svg" alt="" />
    </button>

    <div class="wrapper">
      <ng-container>
        <div class="header flex items-center">
          <app-avatar
            class="avatar"
            [size]="avatarSize"
            [firstLetter]="selectedUser && selectedUser.firstname ? selectedUser.firstname[0] : '-'"
            [imageUrl]="selectedUser?.profilePhotoUrl"
          ></app-avatar>
          <div class="info flex flex-col">
            <div class="user-name">
              {{ selectedUser?.firstname }} {{ selectedUser?.lastname }}
            </div>
            <div class="user-email">{{ selectedUser?.username }}</div>
          </div>
        </div>
        <app-block-role-phone-insta-info
          *ngIf="selectedUser"
          [role]="getManagerTypeRole"
          [phone]="selectedUser?.phoneNumber || selectedUser?.studentUsername"
          [instagram]="selectedUser?.instagramLink"
          [user]="selectedUser"
          [username]="username"
          [isDiscount]="isDiscount"
          (editedEvent)="edited($event)"
          (navigateToProgress)="navigate()"
          [isRegistered]="isRegistered"
          [hideEditBtn]="hideEditBtn"
        ></app-block-role-phone-insta-info>
      </ng-container>
    </div>

    <div class="body">
      <ng-container *ngIf="!isFromCrmPage">
        <ng-container *ngIf="selectedUser && selectedUser.roles && selectedUser.roles.length>0 && selectedUser?.roles[0] === 'CURATOR'">
          <app-admin-curator-groups
            [curatorId]="selectedUser.id"
          ></app-admin-curator-groups>
        </ng-container>
        <ng-container class="list" *ngIf="selectedUser && selectedUser.roles && selectedUser.roles.length>0 && selectedUser?.roles[0] === 'HEAD_TEACHER' || selectedUser?.roles[0] === 'METHODIST'">
          <div class="list">
            <app-admin-headteacher-subjects
              [headteacherId]="selectedUser.id"
              [subjects]="selectedUser.subjects"
              [role]="role">
            </app-admin-headteacher-subjects>
            <div class="iconic-line"></div>
            <app-admin-headteacher-curators
              [headteacherId]="selectedUser.id"
            ></app-admin-headteacher-curators>
          </div>
        </ng-container>
        <ng-container *ngIf="selectedUser && selectedUser.roles && selectedUser.roles.length>0 && selectedUser?.roles[0] === 'STUDENT' && !isDiscount">
          <app-admin-student-courses
            [hideCourseEvents]="hideCourseEvents"
            [hideTitle]="isFromCrmPage"
            [studentId]="selectedUser.id"
          ></app-admin-student-courses>
        </ng-container>
        <ng-container *ngIf="isDiscount">
          <app-subjects-with-discounts (changedData)="changedEvent($event)"
                                       [username]="username || selectedUser.username">
          </app-subjects-with-discounts>
        </ng-container>
        <ng-container *ngIf="selectedUser && selectedUser.roles && selectedUser.roles.length>0 && selectedUser?.roles[0] === 'MANAGER'">
          <app-admin-manager-info
            [manager]="selectedUser"
          ></app-admin-manager-info>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="isFromCrmPage">
        <app-admin-crm [selectedUser]="selectedUser" (isGoingToLeaveToggleEvent)="isGoingToLeaveToggleEvent.emit()"></app-admin-crm>
      </ng-container>
    </div>
  </div>
</ng-container>
