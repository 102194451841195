<mat-checkbox [(ngModel)]="selectedUser.goingToLeave" (ngModelChange)="toggleIsGoingToLeave()">{{'GOING_TO_LEAVE_COURSE' | translate}}</mat-checkbox>

<div class="wrapper">
  <mat-tab-group>
    <mat-tab [label]="'COMMENTS' | translate">
      <app-admin-comments [student]="selectedUser"></app-admin-comments>
    </mat-tab>

    <mat-tab [label]="'PAYMENTS_HISTORY' | translate">
      <app-admin-payments-history [studentId]="selectedUser?.id"></app-admin-payments-history>
    </mat-tab>

    <mat-tab [label]="'DISCOUNTS' | translate">
      <app-subjects-with-discounts [username]="selectedUser?.username" [hideHeader]="true" [hideRemoveButton]="true">
      </app-subjects-with-discounts>
    </mat-tab>

    <mat-tab [label]="'COURSES' | translate">
      <app-admin-student-courses [hideCourseEvents]="true" [hideTitle]="true"
        [studentId]="selectedUser?.id"></app-admin-student-courses>
    </mat-tab>
  </mat-tab-group>
</div>
