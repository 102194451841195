<app-dialog
    [isOpen]="isOpen"
    [title]="'EDIT_STUDENT_INFO' | translate"
    (closeEvent)="closeEvent.emit()"
    (confirmEvent)="editProfile()"
    [showButtons]="true"
    [disabledConfirm]="userForm.invalid"
  >
  <form [formGroup]="userForm" class="app-dialog-content">
    <app-input
      *ngIf="userForm.get('phoneNumber')"
      [error]="userFormGroupError.phoneNumber"
      [name]="'PHONE_NUMBER' | translate"
      formControlName="phoneNumber"
      (input)="phoneTyping()"
      prefix="+7"
      [mask]="'(000) 000-00-00'"
    ></app-input>
    <app-input
      *ngIf="userForm.get('email')"
      [error]="userFormGroupError.email"
      [name]="'email' | translate"
      formControlName="email"
      (input)="emailTyping()"
    ></app-input>
  </form>
</app-dialog>
