<div class="flex flex-col errors">
  <p *ngIf="control?.touched && control?.errors?.['required']">
    {{'requiredField' | translate}}
  </p>
  <p *ngIf="control?.touched && control?.errors?.['email']">
    {{'invalidEmail' | translate}}
  </p>
  <p *ngIf="control?.touched && control?.errors?.['minlength']">
    {{'minLength' | translate:{value: control?.errors?.['minlength'].requiredLength} }}
  </p>
  <p *ngIf="control?.touched && control?.errors?.['maxlength']">
    {{'maxlength' | translate:{value: control?.errors?.['maxlength'].requiredLength} }}
  </p>
</div>
