import { createReducer, on } from "@ngrx/store";
import { IPayment, MethodistUser, StudentUser } from "src/app/core/model";
import { ChoosingSubjects, SelectValue } from "src/app/shared/models";
import { getProfileInfo, getProfileInfoSuccess, getUserPaymentHistoryAction, getUserPaymentHistorySuccessAction, resetUser, updateUserProfileInfo, updateUserProfileInfoSuccess } from "./user.actions";

export interface UserState {
    profile: StudentUser | MethodistUser,
    payment: IPayment
}

export const initialState: UserState = {
    profile: {
        map: function (arg0: (user: StudentUser) => { name: string; value: string; }): SelectValue[] {
            throw new Error("Function not implemented.");
        },
        username: "",
        password: "",
        repassword: "",
        email: "",
        firstname: "",
        lastname: "",
        middlename: "",
        parentPhoneNumber: "",
        parentEmail: "",
        grade: "",
        firstSubjectId: ChoosingSubjects.Mathematics,
        secondSubjectId:  ChoosingSubjects.Mathematics,
        instagramLink: "",
        phoneNumber: "",
        roles: [],
        firstSubject:  ChoosingSubjects.Mathematics,
        secondSubject:  ChoosingSubjects.Mathematics
    },
    payment: {
        fio: "",
        email: "",
        date: "",
        price: ""
    }
}

export const userReducer = createReducer(
    initialState,
    on(resetUser, () => initialState),
    on(getProfileInfo, (state) => state),
    on(getProfileInfoSuccess, (state, { payload }) => {
        localStorage.setItem('username', payload.username);
        return {
            ...state,
            profile: payload
        }
    }),
    on(updateUserProfileInfo, (state) => state),
    on(updateUserProfileInfoSuccess, (state, { payload }) => {
        return {
            ...state,
            profile: payload
        }
    }), 
    on(getUserPaymentHistoryAction, (state) => state),
    on(getUserPaymentHistorySuccessAction, (state, { payload }) => {
        return {
            ...state,
            payment: payload
        }
    })
  );