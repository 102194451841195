<form [formGroup]="groupsForm">
    <div formArrayName="list" *ngIf="!loading">
        <div class="form" *ngFor="let group of groups.controls; let i = index; let lastRow = last; trackBy: trackByIndex" [formGroupName]="i"
            >
            <div
                class="row flex justify-between items-center"
                [ngStyle]="{ 'border-bottom': lastRow ? 'none' : '' }"
            >
                <div class="number">#{{ i + 1 }}</div>
                <div class="name">
                <app-input
                    formControlName="group"
                ></app-input>
                </div>

                <div class="count" (click)="onRowClick($event, i)">
                {{ group.get('studentCount')?.value ?? "-" }}
                </div>
                <div class="course">
                  <app-cdk-select
                      [options]="coursesOption[i]"
                      (selectChangeHandler)="courseSelected($event, i)"
                      (searchInputHandler)="courseSearching($event, i)"
                      [withSearch]="true"
                      [value]="group.get('course')?.value"
                  ></app-cdk-select>
                </div>
                <div class="curator">

                  <app-cdk-select
                      [options]="curatorOption[i]"
                      (selectChangeHandler)="curatorSelected($event, i)"
                      (searchInputHandler)="curatorSearching($event, i)"
                      [withSearch]="true"
                      [value]="group.get('curator')?.value"
                  ></app-cdk-select>
                </div>
                <div class="add-curator">
                  <app-multi-select
                      [options]="curatorOption2[i]"
                      (selectedValue)="curator2Selected($event, i)"
                      (searchInputHandler)="curator2Searching($event, i)"
                      [withSearch]="true"
                      [preSelectedOptions]="group.get('addCurator')?.value"
                  ></app-multi-select>
                </div>
                <button (click)="removeGroup(i)" class="menu-item">
                  <img src="assets/svg/trash.svg" alt="" />
                </button>
            </div>
        </div>
    </div>

    <app-page-progress-spinner *ngIf="loading"/>
</form>

<app-dialog
  title="DELETE_GROUP"
  [isOpen]="isModalOpen"
  (closeEvent)="closeDialog()"
  [showButtons]="false">
  <div class="confirm-modal flex flex-col">
    <p class="confirm-modal_content">
      {{'ARE_U_SURE_DELETE_GROUP' | translate}}
    </p>
    <div class="confirm-modal_footer flex justify-center">
      <button (click)="delete()"
              [disabled]="disableBtn"
              class="iconic-button error-button button">
        {{'YES_REMOVE_GROUP' | translate}}
      </button>
      <button (click)="closeDialog()"
              class="iconic-button secondary-button button">
        {{'NO_REMOVE_GROUP'  |translate}}
      </button>
    </div>
  </div>
</app-dialog>
