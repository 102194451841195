<div class="discussion">
  <app-expander [isExpanded]="isOpen" [updateEvent]="updateExpanderSubject.asObservable()">
    <button class="button-expander" (click)="toggle()" button>
      <h1 class="disc-title">Вопросы-ответы</h1>
      <app-primary-arrow [isOpen]="isOpen" openColor="black" closeColor="black"/>
    </button>
    <div class="tab" content>
      <ul class="list" [ngClass]="{'baseClose': !baseOpen, 'baseOpen': baseOpen}">
        <form [formGroup]="form" class="search-container">
          <input class="input" placeholder="Поиск по вопросам" />
          <app-select placeholder="Все темы" [options]="themesOptions" formControlName="themes"></app-select>
          <app-select placeholder="Неотвеченные" [options]="lessonsOptions" formControlName="lessons"></app-select>
        </form>
        <li *ngFor="let number of [0,1,2,3,4]" class="item">
          <div class="question">
            <div class="header">
              <h1 class="title">А почему здесь это самое ну то что это как бы то?</h1>
              <div class="desktop">
                <span class="date">20 Апр, 2023, 14:00</span>
              </div>
            </div>
            <div class="content">
              <div class="author">
                <h1 class="author-text">Ответ Куратора</h1>
                <img class="author-image" src="assets/svg/verify-green.svg" alt=""/>
              </div>
              <span class="content-text">Ну вот получается надо это так чтобы ну как это будет самое то же.</span>  
            </div>
            <div class="footer">
              <div class="footer-content">
                <span class="footer-text">Первый месяц, первая неделя</span>
                <img src="assets/svg/informative/divide-line-vertical.svg" alt="" />
                <span class="footer-text">Числовые функции</span>
                <img src="assets/svg/informative/divide-line-vertical.svg" alt="" />
                <span class="footer-text">Математика</span>
              </div>
              <div class="desktop">
                <button (click)="goToTest()" class="comments-count">
                  <span class="comments-number">36</span>
                  <img src="assets/svg/comment.svg" alt=""/>
                </button>
              </div>
            </div>
            <div class="mobile">
              <div class="footer-mobile">
                <span class="date">20 Апр, 2023, 14:00</span>
                <button (click)="goToTest()" class="comments-count">
                  <span class="comments-number">36</span>
                  <img src="assets/svg/comment.svg" alt=""/>
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="one-comment" [ngClass]="{'addClose': baseOpen, 'addOpen': !baseOpen}">
        <div class="buttons">
          <button (click)="goToBack()" class="iconic-button secondary-button button back-button">
            <img src="assets/svg/arrow-left.svg" alt="" />
            <span>Назад ко всем вопросам</span>
          </button>
          <button class="iconic-button secondary-button button">Лекция данного вопроса</button>
        </div>
        <div class="question question-second">
          <div class="header">
            <h1 class="title">А почему здесь это самое ну то что это как бы то?</h1>
            <div class="desktop">
              <span class="date">20 Апр, 2023, 14:00</span>
            </div>
          </div>
          <div class="content">
            <div class="author">
              <h1 class="author-text">Ответ Куратора</h1>
              <img class="author-image" src="assets/svg/verify-green.svg" alt=""/>
            </div>
            <span class="content-text">Ну вот получается надо это так чтобы ну как это будет самое то же.</span>  
          </div>
          <div class="footer">
            <div class="footer-content">
              <span class="footer-text">Первый месяц, первая неделя</span>
              <img src="assets/svg/informative/divide-line-vertical.svg" alt="" />
              <span class="footer-text">Числовые функции</span>
              <img src="assets/svg/informative/divide-line-vertical.svg" alt="" />
              <span class="footer-text">Математика</span>
            </div>
            <div class="desktop">
              <button (click)="goToTest()" class="comments-count">
                <span class="comments-number">36</span>
                <img src="assets/svg/comment.svg" alt=""/>
              </button>
            </div>
          </div>
          <div class="mobile">
            <div class="footer-mobile">
              <span class="date">20 Апр, 2023, 14:00</span>
              <button (click)="goToTest()" class="comments-count">
                <span class="comments-number">36</span>
                <img src="assets/svg/comment.svg" alt=""/>
              </button>
            </div>
          </div>
        </div>
        <ul class="list">
          <li class="item">
            <div class="sub-question">
              <div class="header">
                <span class="title">Анонимная Зайка</span>
                <div class="date-delete">
                  <img src="assets/svg/informative/trash.svg" alt="" />
                  <span class="date">20 Апр, 2023, 14:00</span>
                </div>
              </div>
              <span class="content-text">Вопросы, кажущиеся глупыми, часто на самом деле служат важной цели — они помогают нам лучше понять основные концепции и устранить пробелы в знаниях. Вопросы, кажущиеся глупыми, часто на самом деле служат важной цели — они помогают нам лучше понять основные концепции и устранить пробелы в знаниях. Вопросы, кажущиеся глупыми, часто на самом деле служат важной цели — они помогают нам лучше понять основные концепции и устранить пробелы в знаниях.</span>
              <div class="sub-footer">
                <button class="mark-answer desktop">Указать как ответ</button>
                <button class="answer">Ответить</button>
                <button class="show-more">Показать все ответы (4)</button>
              </div>
              <ul class="list">
                <li class="item">
                  <div class="sub-sub">
                    <div class="header">
                      <span class="title">Анонимная Зайка</span>
                      <div class="date-delete">
                        <img src="assets/svg/informative/trash.svg" alt="" />
                        <span class="date">20 Апр, 2023, 14:00</span>
                      </div>
                    </div>
                    <span class="content-text">Вопросы, кажущиеся глупыми, часто на самом деле служат важной цели — они помогают нам лучше понять основные концепции и устранить пробелы в знаниях. Вопросы, кажущиеся глупыми, часто на самом деле служат важной цели — они помогают нам лучше понять основные концепции и устранить пробелы в знаниях. Вопросы, кажущиеся глупыми, часто на самом деле служат важной цели — они помогают нам лучше понять основные концепции и устранить пробелы в знаниях.</span>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <button class="iconic-button secondary-button button button-show-more">Показать больше</button>
      </div>
      <div class="write-comment">
        <input class="input" placeholder="Напишите ответ">
        <button class="iconic-button primary-button button desktop">Отправить</button>
        <button class="iconic-button primary-button button mobile mobile-button">
          <img src="assets/svg/ArrowUp.svg" alt=""/>
        </button>
      </div>
    </div>
  </app-expander>
</div>