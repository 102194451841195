import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Keys, QuizState, QuizWithProgress, TestType, UserAnswer } from 'src/app/core/model';
import { AmplitudeService } from 'src/app/core/services/analytics/amplitude.service';

@Component({
  selector: 'app-questions-stepper',
  templateUrl: './questions-stepper.component.html',
  styleUrls: ['./questions-stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionsStepperComponent implements OnInit {
  public readonly QuizState = QuizState;
  @Input() data!: QuizWithProgress;
  @Input() quizState!: QuizState;
  @Input() checkIsAllAnswered!: Observable<void>;
  @Input() isViewTaskMode: boolean = false;
  @Output() resultAllAnsweredEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  public currentStep: number = 0;

  public isQuestionListOpen: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private amplitudeService: AmplitudeService
  ) {}

  ngOnInit(): void {
    if(this.quizState === QuizState.Passing) {
      this.setQuestionsFromLocalStorage();
    }

    this.checkIsAllAnswered.subscribe({
      next: () => {
        const isAllAnswered = this.data.lesson.questions?.every(item => item.userAnswers);
        this.resultAllAnsweredEvent.emit(isAllAnswered);
        this.cdr.detectChanges();
      },
    })
  };

  public prev(): void {
    if(this.currentStep > 0) {
      this.currentStep--;
    }
  }

  public next(): void {
    if(this.currentStep < this.data.lesson.questions!.length - 1) {
      this.currentStep++;
    }
  }

  public go(index: number): void {
    this.currentStep = index;
  }

  public mobileGo(index: number): void {
    this.go(index);
    this.closeQuestionList()
  }

  public questionAnswered(answer: {questionId: string; answer: UserAnswer[];}): void {
    const question = this.data.lesson.questions!.find(item => item.id === answer.questionId);
    if(question) {
      question.userAnswers = answer.answer;
    }
    this.setQuestionsToLocalStorage();
    this.amplitudeService.trackEvent('questionAnswered', this.data.lesson)

  }
  public checkProgress(id: string): boolean {
    const question = this.data.lesson.questions!.find(item => item.id === id);
    const userAnswers = question?.userAnswers;
    const hasUserAnswer =  userAnswers ? userAnswers.length > 0 ? true : false : false;
    if(question?.quizQuestionType === TestType.DragAndDrop) {
      return userAnswers?.some(item => item.answerTextValue) ? true : false;
    }

    return hasUserAnswer;
  }

  public openQuestionList(): void {
    this.isQuestionListOpen = true;
  }
  public closeQuestionList(): void {
    this.isQuestionListOpen = false;
  }

  public setQuestionsFromLocalStorage(): void {
    const localQuestionsState = localStorage.getItem(`${Keys.QuizPassing}-${this.data.lesson.id}`);
    if (localQuestionsState) {
      const parsedQuestions = JSON.parse(localQuestionsState);
      this.data.lesson.questions = parsedQuestions;
    }
  }
  public setQuestionsToLocalStorage(): void {
    localStorage.setItem(`${Keys.QuizPassing}-${this.data.lesson.id}`, JSON.stringify(this.data.lesson.questions));
  }
}
