import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ForAnswerTestQuestions, QuizState, UserAnswer } from 'src/app/core/model';
import { CdkDragEnd } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-drag-words',
  templateUrl: './drag-words.component.html',
  styleUrls: ['./drag-words.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DragWordsComponent implements OnInit, AfterViewInit {
  @ViewChildren('answerText') answerTextElements!: QueryList<ElementRef>;
  @ViewChild('container') container: ElementRef | undefined;

  public readonly _QuizState = QuizState;
  @Input() quizState: QuizState = QuizState.Passing;
  @Input() question!: ForAnswerTestQuestions;
  @Input() disabled: boolean = false;
  @Output() answeredEvent: EventEmitter<{ questionId: string, answer: UserAnswer[]}> = new EventEmitter<{ questionId: string, answer: UserAnswer[]}>();

  plugText: string = "";

  public sentences: string[] = [];
  public options: string[] = [];

  constructor(
    private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.options = this.question.answerList.map((item: any) => item);
    this.sentences = this.question.questionPattern!.split('{}');
  }

  ngAfterViewInit(): void {
    if(this.question.userAnswers) {
      this.answerTextElements.map((item, index) => {
        item.nativeElement.innerHTML = this.question.userAnswers![index].answerTextValue;
        item.nativeElement.classList.add('answer-text--dropped');
      });
      this.question.userAnswers.forEach(userAnswer => {
        if (userAnswer.answerTextValue) {
          this.options = this.options.filter(option => option !== userAnswer.answerTextValue);
        }
      });
      this.cdr.detectChanges();
    }
    else {
      this.answerTextElements.map((item, index) => {
        item.nativeElement.innerHTML = "";
        item.nativeElement.classList.add('answer-text--dropped');
      });
    }
  };

  onDragEnd(event: CdkDragEnd, index: number) {
    const x = event.dropPoint.x;
    const y = event.dropPoint.y;

    const elements = document.querySelectorAll('*');

    let targetElement: Element | undefined;

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      const rect = element.getBoundingClientRect();

      if (x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom) {
        if(element.classList.contains('answer-text')) {
          targetElement = element;
          break;
        }
      }
    }


    if(!targetElement) {
      event.source._dragRef.reset();
      return;
    }
    //if you are on target element and it has element inside and it's not plugText
    if(targetElement.innerHTML && targetElement.innerHTML.trim() !== this.plugText) {
      //return element inside to variants
      this.options.push(targetElement.innerHTML);
    }

    targetElement.innerHTML = (event.event.target as HTMLElement).innerHTML;
    targetElement.classList.add('answer-text--dropped');

    //remove element
    this.options.splice(index, 1);

    this.sendAnswers();
  }

  onDragReturn(event: CdkDragEnd, index: number) {
    const x = event.dropPoint.x;
    const y = event.dropPoint.y;
    const targetElement = document.elementFromPoint(x, y) as HTMLElement;
    const hasCorrectClass = targetElement.classList.contains('options')
    || targetElement.classList.contains('option-option')
    || targetElement.classList.contains('answer-text');

    if(!hasCorrectClass) {
      return;
    }


    const draggingElement = (event.event.target as HTMLElement);

    //check if dragElement has element
    if(draggingElement.innerHTML === this.plugText) {
      return;
    };

    //check if you put to inside answers
    if(targetElement.classList.contains('answer-text') && targetElement.innerHTML.trim() === this.plugText) {
      targetElement.innerHTML = draggingElement.innerHTML;
      targetElement.classList.add('answer-text--dropped');
      event.source._dragRef.reset();
    }
    else {
      //return element to variants
      this.options.push(draggingElement.innerHTML);
      event.source._dragRef.reset();
    }

    //return plug text when u return to option
    draggingElement.innerHTML = this.plugText;
    draggingElement.classList.remove('answer-text--dropped');
    this.sendAnswers();
  }

  private sendAnswers(): void {
    if(this.container) {
      const elementsWithClass = this.container.nativeElement.getElementsByClassName('answer-text');
      const list: UserAnswer[] = [];
      for(let element of elementsWithClass) {
        list.push((element.innerHTML === this.plugText) ? {answerTextValue: ''} : {answerTextValue: element.innerHTML.trim() });
      }
      this.answeredEvent.emit({questionId: this.question.id, answer: list})
    }
  }

  public isQuizStateView(): boolean {
    return this.quizState === this._QuizState.View;
  }
}
