import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CataloguesService } from 'src/app/core/services/catalogues/catalogues.service';
import { getFormControlErrors, selectRequiredValidator } from 'src/app/shared/validators';
import { TranslateService } from '@ngx-translate/core';
import { ManagerService } from 'src/app/core/services/manager/manager.service';
import { EMPTY_VALUE } from 'src/app/shared/consts/common';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class OrderFormComponent implements OnInit {
  @Output() validListener: EventEmitter<any> = new EventEmitter();

  public managerOptions: any[] = [];
  public isManagerOptionsLoading = false;
  public form: FormGroup;
  public userFormGroupError = {
    firstname: '',
    lastname: '',
    phone: '',
    iin: '',
    email: '',
    // manager: null,
  };

  constructor(
    private _cataloguesService: CataloguesService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private _managerService: ManagerService
  ) {
    this.form = this._createForm();
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((response) => {
      const email = response.email;
      const trimmedValue = email?.trim();
      if (trimmedValue !== email) {
        this.form.get('email')?.setValue(trimmedValue);
      }
      
      this.validListener.emit(this.form);
    });

    this.getManagers();
  }

  private _createForm(): FormGroup {
    return new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      managerId: new FormControl(EMPTY_VALUE, [selectRequiredValidator()]),
    });
  }

  handleFormControlErrors(
    form: FormGroup,
    error: any,
    controlName: string
  ): void {
    getFormControlErrors(form, error, controlName, this.translateService);
    this.cdr.detectChanges();
  }

  public getManagers(): void {
    const monthNumber = new Date().getMonth() + 1;
    this.isManagerOptionsLoading = true;
    this._managerService
      .getAllPageable({
        page: 0,
        size: 999,
        month: monthNumber,
      })
      .subscribe({
        next: (res: any) => {
          const options = res.content?.map((item: any) => ({
            name: `${item.lastname} ${item.firstname}`,
            value: item.id,
          }));
          this.managerOptions = [
            {
              name: EMPTY_VALUE,
              value: EMPTY_VALUE,
            },
            {
              name: 'Без менеджера',
              value: null,
            },
            ...options,
          ];
        },
        error: (error) => {
          console.log(error);
        },
        complete: () => {
          this.isManagerOptionsLoading = false;
          this.cdr.detectChanges();
        },
      });
  }
}
