<app-form-field [name]="name" [error]="error" [focused]="focused" [control]="ngControl.control">
    <div class="input-password-component">
        <input 
            #inputField
            class="iconic-input" 
            type="password"
            (focus)="onFocus()" 
            (blur)="onBlur()" 
            [formControl]="ngControl.control"
            [placeholder]="placeholder"
        />
        <button class="toggle-button" (click)="togglePassword()">
            <img class="password-icon" *ngIf="!isPasswordShown" src="assets/svg/eye.svg"  alt=""/>
            <img class="password-icon" *ngIf="isPasswordShown" src="assets/svg/eye-slash.svg"  alt=""/>
        </button>
    </div>
</app-form-field>