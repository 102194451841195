import { LessonsService } from 'src/app/core/services/lessons/lessons.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { TestQuestionCustom, CustomTestAnswerItem, MiniTestCreateRequest, MiniTestQuestion } from 'src/app/core/model';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-mini-test-creator',
  templateUrl: './mini-test-creator.component.html',
  styleUrls: ['./mini-test-creator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MiniTestCreatorComponent implements OnInit {
  @Input() lectureId: string = "";
  public questionImageUrl: string = '/assets/svg/image-upload.svg';

  public questionGroupName: number = 0;
  public questions: TestQuestionCustom[] = [];

  public saveQuizQuestionSubject: Subject<number> = new Subject<number>();

  constructor(
    private cdr: ChangeDetectorRef,
    private lessonsService: LessonsService
  ) {}

  ngOnInit(): void {
    this.lessonsService.miniTestQuestionsList(this.lectureId).subscribe((res: MiniTestQuestion[]) => {
      for(let question of res) {
        let answerList: CustomTestAnswerItem[] = [];
        if(question.answerList) {
          question.answerList.forEach((answerItem: CustomTestAnswerItem) => {
            answerList.push({
              id: answerItem.id,
              answerImageUrl: answerItem.answerImageUrl,
              answerText: answerItem.answerText,
              correct: answerItem.correct,
            });
          });
        }

        this.questions.push({
          id: question.id,
          questionImageUrl: question.questionImageUrl,
          questionText: question.questionText,
          shuffle: question.shuffle,
          answerList: answerList,
          isSaved: true,
          isValid: false
        });
      }
      this.cdr.detectChanges();
    })
  }

  public addQuestion(): void {
    this.questions.push({
      questionImageUrl: this.questionImageUrl,
      questionText: '',
      shuffle: true,
      answerList: [],
      isSaved: false,
      isValid: false
    });
  };
  public removeQuestion(index: number, id?: string): void {
    if(!id) {
      this.questions.splice(index, 1);
      return;
    }
    this.lessonsService.miniTestRemoveQuestion(this.lectureId, id).subscribe(res => {
      this.questions.splice(index, 1);
      this.cdr.detectChanges();
    })
  }
  public openQuestion(index: number): void {
    this.questions[index].isSaved = false;
    this.cdr.detectChanges();
  }
  public editQuestion(index: number): void {
    this.questions[index].isSaved = false;
    this.questions[index].isSaved = true;

  }
  public saveQuestion(index: number = -1,item: TestQuestionCustom): void {
    this.questions[index].isSaved = true;

    const data: MiniTestCreateRequest  = {
      lectureLessonId: this.lectureId,
      questionText: item.questionText,
      questionImageUrl: item.questionImageUrl === this.questionImageUrl ? "" : item.questionImageUrl,
      shuffle: item.shuffle,
      answerList: item.answerList,
      several: item.several,
      id: item.id
    }
    
    const miniTestQuestionId = this.questions[index].id;

    if(miniTestQuestionId) {
      this.lessonsService.miniTestUpdateQuestion(this.lectureId, miniTestQuestionId, data).subscribe(res => {
        this.questions[index] = item;
        this.questions[index].id = res.id;
        this.questions[index].isSaved = true;
        this.cdr.detectChanges();
      });
      return;
    }
    this.lessonsService.miniTestCreateQuestion(this.lectureId, data).subscribe((res: MiniTestQuestion) => {
      this.questions[index] = item;
      this.questions[index].id = res.id;
      this.questions[index].isSaved = true;
      this.cdr.detectChanges();
    });
  }
  public drop(event: any): void {
    const currentIndex = event.currentIndex;
    const items = this.questions;
    moveItemInArray(items, event.previousIndex, currentIndex);
  }
  public sendSaveToChild(index: number): void {
    this.saveQuizQuestionSubject.next(index);
  }
  public setValidityOfQuestion(isValid: boolean, index: number): void {
    this.questions[index].isValid = isValid;
  }
}
