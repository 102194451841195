<div class="order-dialog">
  <div class="flex items-center">
    <img src="./assets/svg/success.svg" class="icon" alt=""/>
    <p class="text">
      {{'paymentLinkCreated' | translate}}
      <a
        class="link"
        target="_blank"
        [href]="data?.link ? data?.link : 'https://www.google.kz/'"
      >
        {{'link' | translate}}
      </a>
    </p>
  </div>
</div>
