import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { Roles, SortableResponseData } from "../../model";
import { IAnnouncement } from "../../model/interface/announcement";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "../auth/auth.service";

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {
  nonReadExists$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  updateAnnouncementList$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor (
    private httpClient: HttpClient,
    private authService: AuthService
  ) {}

  public getAnnouncements(page: number = 0): Observable<SortableResponseData<IAnnouncement>> {
    return this.httpClient.get<SortableResponseData<IAnnouncement>>(`/v1/${this.getRole()}/announcements/my`, {
      params: {
        page,
        size: 30,
        sort: 'createdAt,DESC'
      }
    });
  };

  // public readAnnouncement(id: string): Observable<IAnnouncement> {
  //   return this.httpClient.patch<IAnnouncement>(`/v1/${this.getRole()}/announcements/${id}`,{});
  // }

  public readAnnouncement(id: string | null = null): Observable<IAnnouncement> {
    const body = {
      announcementIds: [id]
    };

    return this.httpClient.patch<IAnnouncement>(`/v1/${this.getRole()}/announcements/read`, id ? body : {});
  }

  public unReadAnnouncement(id: string): Observable<IAnnouncement> {
    return this.httpClient.patch<IAnnouncement>(`/v1/${this.getRole()}/announcements/unread`,{
      announcementIds: [id]
    });
  }

  public nonReadExists(): Observable<boolean> {
    return this.httpClient.get<boolean>(`/v1/${this.getRole()}/announcements/nonreadexists`);
  }

  private getRole(): string {
    const role = this.authService.getRole() ?? Roles.Student;

    switch (role) {
      case Roles.HeadTeacher:
        return 'headteacher';
      case Roles.Admin:
        return 'admin';
      case Roles.Curator:
        return 'curator';
      case Roles.Methodist:
        return 'methodist';
      default:
        return 'student';
    }
  }
}
