import { Component, OnInit } from '@angular/core';
import { MenuService } from './../../../../shared/services/menuService/menu-service.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SelectValue } from 'src/app/shared/models';
import { Roles } from 'src/app/core/model';
import { QuestionnaireTypes } from 'src/app/core/model/interface/questionnaire';

@Component({
  selector: 'app-questionnaire-creater-controller',
  templateUrl: './questionnaire-creater-controller.component.html',
  styleUrls: ['./questionnaire-creater-controller.component.scss']
})
export class QuestionnaireCreaterControllerComponent implements OnInit {
  public form!: FormGroup;

  public formList!: FormGroup; 
  
  public optionsQuestionnaires: SelectValue[] = [
    {
      name: 'Завуч',
      value: Roles.HeadTeacher,
    },
    {
      name: 'Куратор',
      value: Roles.Curator,
    },
    {
      name: 'Студент',
      value: Roles.Student,
    },
  ]; 

  public optionsQuestion: SelectValue[] = [
    {
      name: '5 звезд',
      value: QuestionnaireTypes.FiveStars,
    },
    {
      name: 'Да/Нет',
      value: QuestionnaireTypes.YerOrNo,
    },
    {
      name: 'Открытый ответ',
      value: QuestionnaireTypes.Open,
    },
  ]; 
  constructor(
    private menuService: MenuService,
    private fb: FormBuilder
  ) { 
    this.form = this.fb.group({
      name: [''],
      type: [Roles.HeadTeacher]
    });

    this.formList = this.fb.group({
      name: [''],
      type: [QuestionnaireTypes.FiveStars]
    })
  }

  ngOnInit() {
  }

  
}
