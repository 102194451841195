<a
  *ngIf="sidebar && !sidebar.subitems"
  [routerLink]="sidebar.link"
  class="sidebar-item flex items-center"
  routerLinkActive="sidebar-item_active"
>
  <img *ngIf="sidebar.icon" [src]="sidebar.icon" alt="" class="icon" />
  <div class="label">{{ sidebar.label | translate }}</div>
</a>

<div
  *ngIf="sidebar && sidebar.subitems"
  class="sidebar-item sidebar-item_subitems flex flex-col"
>
  <button class="flex justify-between items-center" (click)="toggleIsOpen()">
    <div class="wrapper flex">
      <img [src]="sidebar.icon" alt="" class="icon" />
      <div class="label">{{ sidebar.label | translate}}</div>
    </div>
    <img
      src="./assets/svg/arrow-down.svg"
      alt=""
      class="arrow"
      [class.arrow_open]="isOpen"
    />
  </button>
  <ul class="body flex flex-col" [class.body_open]="isOpen">
    <a
      *ngFor="let subitem of sidebar.subitems"
      class="subitem"
      [routerLink]="[subitem.link]"
      routerLinkActive="subitem_active"
    >
      <li class="label">
        {{ subitem.label | translate}}
      </li>
    </a>
  </ul>
</div>
