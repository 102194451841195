import { createAction, props } from "@ngrx/store";
import { StudentGroupDTO, StudentGroupThemeDTO } from "src/app/core/model";
import { LessonStatus } from "src/app/shared/models";

export const resetGroups = createAction('[Groups] Reset');
export const getGroupsAction = createAction('[Groups API] Fetch Groups');
export const getGroupsActionSuccess = createAction('[Groups API] Fetch Groups Success', props<{ payload: StudentGroupDTO[] }>());

export const getGroupAction = createAction('[Groups API] Fetch Group', props<{ groupId: string, direction: 'ASC' | 'DESC' }>());
export const getGroupActionSuccess = createAction('[Groups API] Fetch Group Success', props<{ payload: StudentGroupThemeDTO }>());
export const setCurrentGroupId = createAction('[Groups API] Set Current Group ID', props<{id: string}>());

export const setLessonProgressStatus = createAction('Set Lesson Progress Status', props<{lessonId: string, status: LessonStatus}>())
