import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IGroup } from "../../../../core/model/interface/group";
import { SUBJECTS_ID } from "../../../models/subjects";
import { DateHelperServiceService } from "../../../services/dateHelperService/date-helper-service.service";
import { CataloguesService } from "../../../../core/services/catalogues/catalogues.service";
import { Router } from "@angular/router";
import { BaseRoutes } from 'src/app/core/model';

@Component({
  selector: 'app-active-course',
  templateUrl: './active-course.component.html',
  styleUrls: ['./active-course.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActiveCourseComponent {
  @Input() activeCourses: IGroup[] = [];

  public subjectsId = SUBJECTS_ID;

  constructor(
    private dateHelper: DateHelperServiceService,
    private router: Router
  ) {}

  public isCurrentDateBetweenDates(prolongDateFrom: string | null, prolongDateTo: string | null): boolean {
    if (prolongDateTo && prolongDateFrom) {
      const from = this.dateHelper.convertUTCToLocal(new Date(prolongDateFrom));
      const to = this.dateHelper.convertUTCToLocal(new Date(prolongDateTo));
      const currentDate: Date = new Date();
      return currentDate >= from && currentDate <= to;
    }

    return false;
  }

  public navigateToCatalogues() {
    this.router.navigate([`/${BaseRoutes.CataloguesWithOpenBasket}`]);

  }

  get getCoursesNeedPayment() {
    return this.activeCourses.filter(course => course.prolongDateTo
      && course.prolongDateFrom
      && this.isCurrentDateBetweenDates(course.prolongDateFrom, course.prolongDateTo));
  }
}
