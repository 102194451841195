<ul class="tabs radius-8 flex items-center border">
  <li *ngFor="let tab of tabs">
    <button
      [ngClass]="{
        'tab_active bg-white': tab.isActive
      }"
      class="tab radius-8 flex items-center justify-center"
      (click)="onTabClick(tab)"
    >
      {{ tab.label }}
    </button>
  </li>
</ul>
