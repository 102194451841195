<div class="container">
  <div class="top">
    <h1 class="title">Высшая математика - Физика</h1>
    <span class="name">Арман Байжанов</span>
  </div>
  <div class="iconic-line"></div>
  <div class="bottom">
    <span class="date">Сен’23</span>
    <div class="bottom-right">
      <div class="rating">
        <span class="number">4.1</span>
        <img src="assets/svg/star-yellow.svg" alt="" />
      </div>
      <button (click)="goTo()" class="iconic-button secondary-button button">
        <span>Перейти</span>
        <img src="assets/svg/arrow-right-2.svg" alt="" />
      </button>
    </div>
  </div>
</div>