import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, Observable, Subscription } from 'rxjs';
import { ToastService } from 'src/app/shared/services/toast/toast.service';

@Component({
  selector: 'app-multidropdown-test-controller',
  templateUrl: './multidropdown-test-controller.component.html',
  styleUrls: ['./multidropdown-test-controller.component.scss']
})
export class MultidropdownTestControllerComponent implements OnInit, OnChanges {
  @Input() index!: number;
  @Input() withQuestionText: boolean = true;
  @Input() item!: any;
  @Output() removeEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() validQuestionStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
  private eventsSubscription!: Subscription;
  @Input() saveEventSubject!: Observable<number>;

  public form!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    ) {
      
  }
  
  ngOnInit(): void {
    if(this.saveEventSubject) {
      this.eventsSubscription = this.saveEventSubject.subscribe((index: number) => {
        if(index === this.index) {
          this.saveEvent.emit(this.form.value);
        }
      });
    };

    this.form = this.formBuilder.group({
      questionText: ["", Validators.required], // Основной текст вопроса
      subQuestions: this.formBuilder.array([]) // Массив под-вопросов
    });
  
    // По умолчанию добавляем два под-вопроса
    for (let i = 0; i < 2; i++) {
      this.addSubQuestion();
    }

    this.form.statusChanges.subscribe((status) => {
      this.validQuestionStatus.emit((status === 'VALID') ? true : false);
    });

    if(this.item) {
      this.form.patchValue({ questionText: this.item.questionText })
      this.subQuestions.clear();

      // Iterate over the provided data and populate the FormArray
      this.item.questions.forEach((question: string, index: number) => {
        const subQuestionGroup = this.formBuilder.group({
          questions: [question || "", Validators.required], // Default to empty string if undefined
          correctAnswers: [this.item.answers[index] || null, Validators.required] // Default to null if undefined
        });

        this.subQuestions.push(subQuestionGroup);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if(changes['item'] && changes['item'].firstChange) {
    //   debugger
    //   if(this.item.questions && this.item.questions.length > 0) {
    //     if(this.subQuestions) {
    //       this.subQuestions.clear();
    //     }
    //     this.form.patchValue({ questionText: this.item.questionText || ''});
    //     for(let i = 0; i < this.item.keyList.length; i++) {
    //       const itemGroup = this.formBuilder.group({
    //         questions: [this.item.questions[i], Validators.required],
    //         correctAnswers: [this.item.correctAnswers![i], Validators.required],
    //       });
    //       this.subQuestions.push(itemGroup);
    //     }
    //   }
    // }
  }

  get subQuestions(): FormArray {
    return this.form.controls['subQuestions'] as FormArray;
  }
  
  public addSubQuestion(): void {
    const subQuestionGroup = this.formBuilder.group({
      questions: ["", Validators.required], // Текст под-вопроса
      correctAnswers: [null, Validators.required] // Обязательный правильный ответ
    });
  
    this.subQuestions.push(subQuestionGroup);
  }
  
  public removeSubQuestion(index: number): void {
    if (this.subQuestions.length > 1) {
      this.subQuestions.removeAt(index);
    }
  }
  
  // Метод для создания варианта ответа
  private createAnswer(): FormGroup {
    return this.formBuilder.group({
      answerText: ["", Validators.required] // Текст варианта
    });
  }
  
  public addAnswer(subQuestionIndex: number): void {
    const answers = this.subQuestions.at(subQuestionIndex).get('answers') as FormArray;
    answers.push(this.createAnswer());
  }
  
  public removeAnswer(subQuestionIndex: number, answerIndex: number): void {
    const answers = this.subQuestions.at(subQuestionIndex).get('answers') as FormArray;
    if (answers.length > 2) {
      answers.removeAt(answerIndex);
    }
  }


  getAnswersControls(subQuestion: AbstractControl): FormArray {
    return subQuestion.get('answers')! as FormArray; // Non-null assertion
  }
}
