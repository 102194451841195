import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, tap } from 'rxjs';
import { SortableResponseData } from '../../model';
import { SelectValue } from 'src/app/shared/models';
import { EMPTY_VALUE } from 'src/app/shared/consts/common';
import { DatePipe } from '@angular/common';
import { UtcToLocalPipe } from 'src/app/shared/pipes/utc-to-local/utc-to-local.pipe';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  constructor(
    private httpClient: HttpClient,
    private datePipe: DatePipe,
    private utcToLocalPipe: UtcToLocalPipe
  ) {}

  public getPayments({
    page,
    size,
    unusual,
    searchText,
    from,
    to,
    paymentType,
    managerIds,
    searchFio,
    searchEmail,
    searchPhone,
    courseId,
    hasNoManager,
    streamType
  }: {
    page: number;
    size: number;
    unusual: boolean | null;
    searchText: string;
    from?: string;
    to?: string;
    managerIds?: string[];
    paymentType?: string;
    searchFio?: string;
    searchEmail?: string;
    searchPhone?: string;
    courseId?: string;
    hasNoManager?: boolean;
    streamType?: string;
  }): Observable<SortableResponseData<any>> {
    const params: any = {
      page,
      size,
      isUnusual: unusual ? true : '',
      sort: ['createdAt'],
    };

    if (searchText) params.search = searchText;
    if (from) params.dateFrom = `${from}T00:00:00.000`;
    if (to) params.dateTo = `${to}T00:00:00.000`;
    if (paymentType) params.paymentType = paymentType;
    if (managerIds) params.managerIds = managerIds;
    if (searchFio) params.studentFio = searchFio;
    if (searchEmail) params.studentEmail = searchEmail;
    if (searchPhone) params.studentPhoneNumber = searchPhone;
    if (courseId) params.courseId = courseId;
    if (hasNoManager) params.hasNoManager = true;
    if (streamType) params.streamType = streamType;


    return this.httpClient.get<SortableResponseData<any>>(
      `/v1/admin/paymenthistories/successful`,
      {
        params,
      }
    );
  }

  public getPaymentsExcel({
    page,
    size,
    unusual,
    searchText,
    from,
    to,
    paymentType,
    streamType,
    managerIds,
    searchFio,
    searchEmail,
    searchPhone,
    courseId,
    hasNoManager
  }: {
    page: number;
    size: number;
    unusual: boolean | null;
    searchText: string;
    from?: string;
    to?: string;
    managerIds?: string[];
    paymentType?: string;
    streamType?: string;
    searchFio?: string;
    searchEmail?: string;
    searchPhone?: string;
    courseId?: string;
    hasNoManager?: boolean;
  }): Observable<any[]> {
    const params: any = {
      page,
      size,
      isUnusual: unusual ? true : '',
    };

    if (searchText) params.mail = searchText;
    if (from) params.dateFrom = `${from}T00:00:00.000`;
    if (to) params.dateTo = `${to}T00:00:00.000`;
    if (paymentType) params.paymentType = paymentType;
    if (streamType) params.streamType = streamType;
    if (managerIds) params.managerIds = managerIds;
    if (searchFio) params.searchFio = searchFio;
    if (searchEmail) params.searchEmail = searchEmail;
    if (searchPhone) params.studentPhoneNumber = searchPhone;
    if (courseId) params.courseId = courseId;
    if (hasNoManager) params.hasNoManager = true;
    return this.httpClient.get<any[]>(
      `/v1/admin/paymenthistories/successful/all`,
      {
        params,
      }
    );
  }

  public getPaymentsDashboard({
    page,
    size,
    unusual,
    searchText,
    from,
    to,
    paymentType,
    managerIds,
    courseId,
    hasNoManager,
    streamType
  }: {
    page: number;
    size: number;
    unusual: boolean | null;
    searchText: string;
    from?: string;
    to?: string;
    managerIds?: string[];
    paymentType?: string;
    courseId?: string;
    hasNoManager: null | boolean
    streamType: null | string
  }): Observable<any> {
    const params: any = {
      page,
      size,
      isUnusual: unusual ? true : '',
    };

    if(typeof hasNoManager === 'boolean') {
      params.hasNoManager = hasNoManager;
    }

    if (searchText) params.search = searchText;
    if (from) params.dateFrom = `${from}T00:00:00.000`;
    if (to) params.dateTo = `${to}T00:00:00.000`;
    if (paymentType) params.paymentType = paymentType;
    if (managerIds) params.managerIds = managerIds;
    if (courseId) params.courseId = courseId;
    if (streamType) params.streamType = streamType;
    return this.httpClient.get<any>(`/v1/admin/paymenthistories/dashboard`, {
      params,
    });
  }

  public getPaymentsCoursesDashboard({
    page,
    size,
    unusual,
    searchText,
    from,
    to,
    paymentType,
    managerIds,
    streamType,
    courseId,
  }: {
    page: number;
    size: number;
    unusual: boolean | null;
    searchText: string;
    from?: string;
    to?: string;
    managerIds?: string[];
    paymentType?: string;
    courseId?: string;
    streamType?: string;
  }): Observable<any> {
    const params: any = {
      page,
      size,
      isUnusual: unusual ? true : '',
    };
    if (searchText) params.mail = searchText;
    if (from) params.dateFrom = `${from}T00:00:00.000`;
    if (to) params.dateTo = `${to}T00:00:00.000`;
    if (paymentType) params.paymentType = paymentType;
    if (managerIds) params.managerIds = managerIds;
    if (streamType) params.streamType = streamType;
    return this.httpClient.get<any>(
      `/v1/admin/paymenthistories/dashboard/courses`,
      {
        params,
      }
    );
  }

  public unlinkStream({
    paymentId,
    streamId,
  }: {
    paymentId: string;
    streamId: string;
  }): Observable<SortableResponseData<any>> {
    return this.httpClient.post<any>(
      `/v1/admin/unlink/stream/${streamId}/from/paymentHistory/${paymentId}`,
      {
        params: {
          paymentHistoryId: paymentId,
          streamId,
        },
      }
    );
  }

  public linkStream({
    paymentId,
    streamId,
  }: {
    paymentId: string;
    streamId: string;
  }): Observable<SortableResponseData<any>> {
    return this.httpClient.post<any>(
      `/v1/admin/link/stream/${streamId}/to/paymentHistory/${paymentId}`,
      {
        params: {
          paymentHistoryId: paymentId,
          streamId,
        },
      }
    );
  }

  public changeEmail({
    paymentId,
    email,
  }: {
    paymentId: string;
    email: string;
  }): Observable<any> {
    return this.httpClient.post<any>(`/v1/admin/change/email`, {
      paymentHistoryId: paymentId,
      email,
    });
  }

  public changePhoneNumber({
    paymentId,
    phoneNumber,
  }: {
    paymentId: string;
    phoneNumber: string;
  }): Observable<any> {
    return this.httpClient.post<any>(`/v1/admin/change/phoneNumber`, {
      paymentHistoryId: paymentId,
      phoneNumber,
    });
  }

  public resendInvite(
    paymentId: string,
    type: string
  ): Observable<SortableResponseData<any>> {
    return this.httpClient.post<any>(
      `/v1/admin/resend/registration/link/${paymentId}`, {},
      {
        params: { type }
      },
    );
  }

  public getPaymentInfo(mail: string): Observable<any> {
    return this.httpClient
      .get<any>(`/v1/paymenthistories/mails/${mail}`, {
        params: { mail },
      })
      .pipe(
        map((paymentInfo) => ({
          fio: `${paymentInfo.lastName} ${paymentInfo.firstName}`,
          email: paymentInfo.mail,
          date: this.datePipe.transform(
            this.utcToLocalPipe.transform(paymentInfo.createdAt),
            'HH:mm:ss | dd.MM.yy'
          ),
          price: paymentInfo.totalPrice,
          manager: paymentInfo.manager?.id
            ? `${paymentInfo.manager.lastname} ${paymentInfo.manager.firstname}`
            : EMPTY_VALUE,
          courses: paymentInfo.streamDto?.map((item: any) => ({
            name: item.name,
            date: this.datePipe.transform(item.startDate, 'dd.MM.yy'),
            waLink: item.whatsappLink ?? EMPTY_VALUE,
          })),
        }))
      );
  }

  public getPaymentHistoryById(id: string): Observable<any> {
    return this.httpClient.get<any>(`/v1/admin/paymenthistories/${id}`);
  }

  public getMyPaymentHistory(): Observable<any> {
    return this.httpClient.get<any>(`/v1/paymenthistories/my/mails`).pipe(
      map((paymentInfo) => ({
        fio: `${paymentInfo.lastName} ${paymentInfo.firstName}`,
        email: paymentInfo.mail,
        date: this.datePipe.transform(
          this.utcToLocalPipe.transform(paymentInfo.createdAt),
          'HH:mm:ss | dd.MM.yy'
        ),
        price: paymentInfo.totalPrice,
        manager: paymentInfo.manager?.id
          ? `${paymentInfo.manager.lastname} ${paymentInfo.manager.firstname}`
          : EMPTY_VALUE,
        courses: paymentInfo.streamDto?.map((item: any) => ({
          name: item.name,
          date: this.datePipe.transform(item.startDate, 'dd.MM.yy'),
          waLink: item.whatsappLink ?? EMPTY_VALUE,
        })),
      }))
    );
  }

  getDashboardCoursesCsv({
                           from,
                           to,
                           paymentType,
                           managerIds,
                           streamType,
                         }: {
    from?: string;
    to?: string;
    managerIds?: string[];
    paymentType?: string;
    streamType?: string;
  } ): Observable<any> {
    const params: any = {};
    if (from) params.dateFrom = `${from}T00:00:00.000`;
    if (to) params.dateTo = `${to}T00:00:00.000`;
    if (paymentType) params.paymentType = paymentType;
    if (managerIds) params.managerIds = managerIds;
    if (streamType) params.streamType = streamType;
    return this.httpClient.get(`/v1/admin/paymenthistories/dashboard/courses/csv/download`, {
      responseType: 'text',
      params
    });
  }

  public checkIsProlong(orderId: string): Observable<boolean> {
    return this.httpClient.get<boolean>(`/v1/paymenthistories/${orderId}/isprolong`);
  }

  public getRegistrationLink(paymentHistoryId: string): Observable<{link: string}> {
    return this.httpClient.get<{link: string}>(`/v1/paymenthistories/registration/link/${paymentHistoryId}`);
  }

  public refund(data: any, phid: string): Observable<any> {
    return this.httpClient.patch<any>(`/v1/paymenthistories/${phid}/refund`, data)
  }
}
