<div class="container">
  <h1 class="title">{{'YOURS_COURSES' | translate}}</h1>
  <ul class="list">
    <li class="item" *ngFor="let item of groups; let last = last;">
      <div class="card">
        <div class="card-header">
          <div class="image" [style.background-image]="'url(' + subjectsId[item.subjectId] + ')'"></div>
          <div class="base-info mobile">
            <h1 class="name">{{ item.courseName ? item.courseName : item.streamName}}</h1>
            <div *ngIf="item.groupName" class="add-info">
              {{item.groupName}}
              <div class="dot desktop"></div>
              <span class="desktop">
                {{item.streamDuration}} {{'durationMonth5' | translate}}
              </span>
              <div class="dot desktop"></div>
              <span class="desktop">
                {{item.lessonCount}} {{'CLASSES' | translate}}
              </span>
            </div>
            <div *ngIf="!item.courseName" class="add-info">
              {{'COURSE_START_DATE' | translate}} {{item.streamStartDate | utcToLocal | date: 'dd.MM.yyyy'}}
            </div>
          </div>
        </div>
        <div class="card-main">
          <div class="base-info desktop">
            <h1 class="name">{{ item.courseName ? item.courseName : item.streamName}}</h1>
            <div *ngIf="item.groupName" class="add-info">
              {{item.groupName}}
              <div class="dot desktop"></div>
              <span class="desktop"> {{item.streamDuration}} {{'durationMonth5' | translate}} </span>
              <div class="dot desktop"></div>
              <span class="desktop">{{item.lessonCount}} {{'CLASSES' | translate}} </span>
            </div>
            <div *ngIf="!item.courseName" class="add-info"> {{'COURSE_START_DATE' | translate}} {{item.streamStartDate | utcToLocal | date: 'dd.MM.yyyy'}}</div>
          </div>
          <div class="buttons">
            <div class="width-full">
              <app-responsibles-card
                (click)="openResponsable(item)"
                [curatorFirstLetter]="item.curatorFirstName ? item.curatorFirstName[0] : ''"
                [curatorImg]="item.curatorProfileUrl ? item.curatorProfileUrl : ''"
                [teacherFirstLetter]="item.teacherFullName ? item.teacherFullName[0] : ''"
                [teacherImg]="item.teacherProfileUrl ? item.teacherProfileUrl : ''"
                [isOpen]="responsiblesIsOpen">
              </app-responsibles-card>
            </div>
            <button
              *ngIf="item.nextLessonId"
              (click)="openLesson(item.groupId, item.nextLessonId, item.nextLessonName!, item.nextLessonType, item.courseId)"
              class="iconic-button primary-button button button-continue">{{'CONTINUE_LESSON' | translate}}</button>
            <button *ngIf="item.groupId" class="mobile iconic-button secondary-button" (click)="goToGroupPage(item.groupId)">
              {{'LESSONS_LIST' | translate}}
            </button>
            <button
              (click)="goToWhatsappGroup(item.whatsappLink)"
              class="button iconic-button secondary-button whatsapp-button"
              *ngIf="item.whatsappLink && !item.groupId">
              <img src="assets/svg/socials/logos_whatsapp-icon.svg" alt="" />
              <span class="whatsapp-text">{{'ADD_TO_THE_STREAM_CHAT' | translate}}</span>
            </button>
          </div>
          <button (click)="openLesson(item.groupId, item.upcomingLessonId!, item.upcomingLessonName!, item.upcomingLessonType)" *ngIf="item.upcomingLessonId && (item.upcomingLessonDeadline | earlierThanGivenTime: currentPlusOneDay)" class="deadline">
            <div class="deadline-info">
              <span class="deadline-type">{{item.upcomingLessonType | reverseLessonType : language}}</span>
              <h1 class="deadline-name">{{item.upcomingLessonName}}</h1>
            </div>
            <div class="deadline-timer">
              <img src="assets/svg/timer-pause.svg" alt="" class="timer-icon">
              <span class="timer-number" *ngIf="item.upcomingLessonDeadline && (currentPlusOneHour | earlierThanGivenTime: (item.upcomingLessonDeadline | utcToLocalDateFormet) );else timer">{{ (( item.upcomingLessonDeadline | utcToLocalDateFormet | timeDiff: currentTime) | timeDiffToHours) + ' ' + ('HOURS_SHORT' | translate)}}</span>
              <ng-template #timer>
                <span class="timer-number">{{ currentTime | timeDiffToMinutesAndSeconds: (item.upcomingLessonDeadline | utcToLocalDateFormet) }}</span>
              </ng-template>
            </div>
          </button>
          <div *ngIf="item.prolongDeadlineTo
            && (item.prolongDeadlineFrom | utcToLocalDateFormet | earlierThanGivenTime: currentTime)
            && (currentTime | earlierThanGivenTime: (item.prolongDeadlineTo | utcToLocalDateFormet))" class="payment-deadline">
            <div class="payment-text">
              <img src="assets/svg/timer-pause.svg" alt="" class="timer-icon">
              <span>
                {{'PAYMENT_FOR_THE_COURSE' | translate}}
                {{(((item.prolongDeadlineTo | utcToLocalDateFormet) | earlierThanGivenTime: currentPlusOneDay) ?
                (((item.prolongDeadlineTo | utcToLocalDateFormet | timeDiff: currentTime) | timeDiffToHours ) + ' ' + ('HOURS' | translate)) :
                ((currentTime | timeDiff: (item.prolongDeadlineTo | utcToLocalDateFormet)) | timeDiffToDays) + ('DAYS' | translate)) + ('LEFT' | translate)}}
              </span>
            </div>
            <button (click)="goToCatalog()" class="payment-button">
              <span>{{'PAY_FOR_COURSE' | translate}}</span>
              <img class="button-button" src="assets/svg/right-arrow-blue.svg" alt="" />
            </button>
          </div>
          <span *ngIf="
            item.prolongDeadlineFrom
            && (currentTime | earlierThanGivenTime: (item.prolongDeadlineFrom | utcToLocalDateFormet))"
          class="payment-period">
             {{'PAYMENT_DATE' | translate: {
            from: item.prolongDeadlineFrom | utcToLocal | date : 'dd-MM-yyyy',
            to: item.prolongDeadlineTo| utcToLocal | date : 'dd-MM-yyyy'
          } }}
          </span>

          <!-- <span
            class="payment-period payment-period-not-payed"
            *ngIf="item.prolongDeadlineTo
            && ((item.prolongDeadlineTo | utcToLocalDateFormet) | earlierThanGivenTime: currentTime)">
            Курс не оплачен
          </span> -->
          <div class="payment-text payment-period-not-payed"
          *ngIf="item.prolongDeadlineTo
            && ((item.prolongDeadlineTo | utcToLocalDateFormet) | earlierThanGivenTime: currentTime)">
            <img src="assets/svg/timer-pause.svg" alt="" class="timer-icon">
            <span>{{'COURSE_NOT_PAID' | translate}}</span>
          </div>
        </div>
      </div>
      <div *ngIf="!last" class="iconic-line"></div>
    </li>
  </ul>
</div>

<app-responsible-dialog
  [curatorFullName]="currentResponsables?.curatorFullName"
  [curatorUsername]="currentResponsables?.curatorUsername"
  [curatorProfileUrl]="currentResponsables?.curatorProfileUrl"
  [curatorPhoneNumber]="currentResponsables?.curatorPhoneNumber"
  [teacherFullName]="currentResponsables?.teacherFullName"
  [teacherProfileUrl]="currentResponsables?.teacherProfileUrl"
  [teacherDesc]="currentResponsables?.teacherDesc"
  [isOpen]="responsiblesIsOpen"
  (closeEvent)="closeResponsibleDialog()"
></app-responsible-dialog>
