import { Pipe, PipeTransform } from '@angular/core';
import { DateHelperServiceService } from "../../services/dateHelperService/date-helper-service.service";
import { Languages } from "../../models";
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  private months: string[] = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];
  private monthsKk: string[] = ['Қаңтар', 'Ақпан', 'Наурыз', 'Сәуір', 'Мамыр', 'Маусым', 'Шілде', 'Тамыз', 'Қыркүйек', 'Қазан', 'Қараша', 'Желтоқсан'];
  private shortMonths: string[] = ['Янв', 'Февр', 'Марта', 'Апр', 'Мая', 'Июня', 'Июля', 'Авг', 'Сен', 'Окт', 'Нояб', 'Дек'];

  constructor(
    private dateHelperService: DateHelperServiceService
  ) {}

  transform(date: string | null, arg: string, startTime?: string | null, language: Languages = Languages.KZ): string {
    if (!date) return '';
    if (arg === 'today') {
      return this.isToday(date);
    } if (arg === 'deadlineTimes') {
      return `${moment(startTime).format('HH:mm')} - ${moment(date).format('HH:mm')}`
    } else if (arg === 'dateMonthName') {
      const parsedDate = moment(date);
      return parsedDate.format('DD')
        + ' '
        + this.getMonthName(new Date(date).getMonth(), language)
        + ' '
        + parsedDate.format('YYYY')
        + ', '
        + parsedDate.format('HH:mm');
    } else if (arg === 'dateMonthShortName') {
      const parsedDate = moment(date);
      return parsedDate.format('DD')
        + ' '
        + this.getMonthShortName(new Date(date).getMonth())
        + ', '
        + parsedDate.format('HH:mm');
    } else if (arg === 'deadlineDays') {
      return this.formatDate(date, false, language);
    } else if (arg === 'deadlineDate') {
      return this.formatDate(date, true);
    } else if (arg === 'daysLeft') {
      return this.dateHelperService.daysToDate(date).toString();
    }
    return '';
  }

  private getMonthName(index: number, language: Languages = Languages.KZ) {
    if (language === Languages.KZ) {
      return this.monthsKk[index];
    }
    return this.months[index];
  }

  private getMonthShortName(index: number) {
    return this.shortMonths[index];
  }

  private formatDate(apiDate: string, showTime: boolean = false, language: Languages = Languages.KZ): string {
    const parsedDate = moment(apiDate);
    const currentDate = moment();

    let daysAgo = currentDate.diff(parsedDate, 'days');
    if (parsedDate.isSame(currentDate, 'day') || daysAgo === 1) {
      if (showTime) {
        return this.daysWithTimes(apiDate);
      }
      return 'TODAY';
    } else if (parsedDate.isSame(currentDate.clone().subtract(1, 'day'), 'day')) {
      return 'YESTERDAY';
    } else if ((daysAgo * -1) > -1) {
      daysAgo = daysAgo * -1;
      if (daysAgo) {
        return language === Languages.RU
          ? `через ${daysAgo} ${this.daysCount(daysAgo)}`
          : `${daysAgo} күн кейін`;
      }
      return 'TOMORROW';
    } else if (daysAgo > 1) {
      return language === Languages.RU
        ? `${daysAgo} ${this.daysCount(daysAgo)} назад`
        : `${daysAgo} күн бұрын`;
    } else {
      return 'idk';
    }
  }

  private daysCount(day: number) {
    if (day > 1 && day < 5) {
      return 'дня';
    } else if (day > 4) {
      return 'дней';
    } else {
      return 'день';
    }
  }

  private daysWithTimes(date: string) {
    const differenceMs = new Date(date).getTime() - new Date().getTime();
    let days = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
    let hours = Math.floor((differenceMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((differenceMs % (1000 * 60 * 60)) / (1000 * 60));

    let res = '';
    if (minutes > 0) {
      res = `${minutes} минут`;
    }
    if (hours > 0) {
      res = `${hours} часов, ` + res;
    }
    if (days > 0) {
      res = `${this.daysCount(days)}, ` + res;
    }
    if (!res) {
      minutes *= -1;
      hours += 1;
      days += 1;
      if (minutes > 0) {
        res = `${minutes} минут назад`;
      }
      if (hours > 0) {
        res = `${hours} часов, ` + res;
      }
      if (days > 0) {
        res = `${this.daysCount(days)}, ` + res;
      }
    }
    return res;
  }

  private isToday(date: string): string {
    const newDate = new Date();
    if (newDate.toDateString() === new Date(date).toDateString()) {
      return 'TODAY';
    }
    return moment(date).format('DD.MM.YYYY');
  }
}
