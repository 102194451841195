<div class="flex wrapper">

    <img *ngIf="!isOpen" class="drag-icon" src="./assets/svg/ic_sharp-drag-indicator.svg" alt="">

    <div class="content w-100">
        <div class="header">
        <div class="header-title">
            <div *ngIf="!isNameEditingMode" class="title-wrapper">
                <h1 class="title">{{nameForm.get('name')?.value}}</h1>
                <img class="edit-icon" src="assets/svg/informative/edit.svg" alt="" (click)="activateNameEditingMode()"/>
            </div>
            <form *ngIf="isNameEditingMode" class="title-wrapper" [formGroup]="nameForm">
                <app-input formControlName="name"></app-input>
                <div class="title-buttons">
                    <button class="iconic-button primary-button" (click)="saveName()" [disabled]="nameForm.invalid">
                      {{'save' | translate}}
                    </button>
                    <button class="iconic-button secodary-button" (click)="deactivateNameEditingMode()">
                      {{'TO_CANCEL' | translate}}
                    </button>
                </div>
            </form>
            <span class="type">{{'TYPE' | translate}}: {{shortLesson.lessonType | reverseLessonType}}</span>
        </div>
        <div class="buttons">
            <mat-slide-toggle
                color="accent"
                *ngIf="!(role === Roles.Curator && shortLesson.general)"
                [checked]="shortLesson.published"
                (change)="onToggleChange($event)"
                >
              {{'PUBLISHED' | translate}}
            </mat-slide-toggle>

            <button class="buttons-menu" [matMenuTriggerFor]="menu">
                <img class="icon" src="assets/svg/triple-dot.svg" alt="" />
            </button>

            <button class="buttons-menu" (click)="addToCourse()">
                <img class="icon" src="assets/svg/arrowBendUpRight.svg" alt="" />
            </button>
        </div>
        <mat-menu class="default-menu" #menu="matMenu">
            <app-menu-default [canDelete]="isCanDelete" [canSee]="true" [canEdit]="!isOpen" (seeClick)="navigate()" (editClick)="edit()" (duplicateClick)="duplicate()" (deleteClick)="openDeleteModal()"></app-menu-default>
        </mat-menu>
    </div>
    <app-expander [isExpanded]="isOpen">
      <button *ngIf="!isOpen" class="expander-button" (click)="toggleExpander()" button>
          <app-primary-arrow [isOpen]="!isOpen" [openColor]="'#9399AB'"></app-primary-arrow>
      </button>
      <div class="expander-content" content>
          <app-theory-controller
              *ngIf="shortLesson.lessonType === lessonType.Lecture"
              [data]="lesson"
              [saveEvent]="saveSubject.asObservable()"
              [name]="nameForm.get('name')?.value"
              (allowSaveEvent)="toggleAllowSave($event)"
              (savedEvent)="lessonSaved($event)"
              [isOpen]="isOpen">
          </app-theory-controller>
          <app-homework-controller
              *ngIf="shortLesson.lessonType === lessonType.Homework"
              [data]="lesson"
              [saveEvent]="saveSubject.asObservable()"
              [name]="nameForm.get('name')?.value"
              (allowSaveEvent)="toggleAllowSave($event)"
              (savedEvent)="lessonSaved($event)"
              [isOpen]="isOpen">
          </app-homework-controller>
          <app-test-controller
              *ngIf="shortLesson.lessonType === lessonType.Quiz"
              [data]="lesson"
              [saveEvent]="saveSubject.asObservable()"
              [name]="nameForm.get('name')?.value"
              (allowSaveEvent)="toggleAllowSave($event)"
              (savedEvent)="lessonSaved($event)"
              [isOpen]="isOpen">
          </app-test-controller>
          <app-work-on-mistakes-controller
              *ngIf="shortLesson.lessonType === lessonType.WorkOnMistakes"
              [data]="lesson"
              [saveEvent]="saveSubject.asObservable()"
              [name]="nameForm.get('name')?.value"
              (allowSaveEvent)="toggleAllowSave($event)"
              (savedEvent)="lessonSaved($event)"
              [isOpen]="isOpen">
          </app-work-on-mistakes-controller>
          <app-text-controller
              *ngIf="shortLesson.lessonType === lessonType.Text"
              [data]="lesson"
              [saveEvent]="saveSubject.asObservable()"
              [name]="nameForm.get('name')?.value"
              (allowSaveEvent)="toggleAllowSave($event)"
              (savedEvent)="lessonSaved($event)"
              [isOpen]="isOpen">
          </app-text-controller>
      <div class="actions">
          <button class="iconic-button secondary-button button" (click)="toggleExpander()">
            {{'TO_CANCEL' | translate}}
          </button>
          <button class="iconic-button primary-button button" [disabled]="!allowSave" (click)="emitSaveToChild()">
            {{'save' | translate}}
          </button>
      </div>
      <button *ngIf="isOpen" class="expander-button" (click)="toggleExpander()" button>
        <app-primary-arrow [initialSlope]="180" [isOpen]="isOpen" [openColor]="'#9399AB'"></app-primary-arrow>
      </button>
      </div>
    </app-expander>
</div>
</div>

<!-- <app-dialog
  title="DELETE_TASK"
  [isOpen]="isModalOpen"
  (closeEvent)="closeDialog()"
  [showButtons]="false">
  <div class="confirm-modal flex flex-col">
    <p class="confirm-modal_content">
      {{'ARE_U_SURE_DELETE_TASK' | translate}}
    </p>
    <div class="confirm-modal_footer flex justify-center">
      <button (click)="closeDialog()"
              class="iconic-button secondary-button button">
        {{'NO'  |translate}}
      </button>
      <button (click)="delete()"
        class="iconic-button primary-button button">
        {{'YES' | translate}}
      </button>
    </div>
  </div>
</app-dialog> -->

<app-delete-modal
  title="DELETE_TASK"
  text="ARE_U_SURE_DELETE_TASK"
  confirmText="YES_DELETE_TASK"
  cancelText="NO_DELETE_TASK"
  [id]="lessonIdToDelete"
  [isModalOpen]="isDeleteModalOpen"
  (closeEvent)="closeDeleteModal()"
  (deleteEvent)="delete($event)"
>
</app-delete-modal>

