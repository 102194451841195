import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SelectValue } from 'src/app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class SideBarService {
  private subject: BehaviorSubject<SelectValue> = new BehaviorSubject<SelectValue>({name: '', value: ''});

  getIdSubject() {
    return this.subject;
  }

  setId(subject: SelectValue) {
    this.subject.next(subject);
  }
}
