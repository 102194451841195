<div class="prolongate-date bg-white border">
  <h2 class="title">{{'RENEWAL_DATES' | translate}}</h2>

  <div class="header flex justify-between">
    <div class="month">{{'MONTH' | translate}}</div>
    <div class="start">{{'RENEWAL_START' | translate}}</div>
    <div class="finish">{{'RENEWAL_DEADLINE' | translate}}</div>
    <div class=""></div>
  </div>

  <div class="body">
    <ng-container *ngFor="let payment of paymentDates; let i = index">
      <div class="prolongate row flex items-center">
        <div class="month">Месяц {{ i + 1 }}</div>
        <div class="start">
          с {{ payment.paymentAccessibleFrom | date : "dd.MM.yyyy HH:mm" }}
        </div>
        <div class="finish">
          <ng-container *ngIf="!payment.isEdit; else editDate">
            по {{ payment.paymentAccessibleTo | date : "dd.MM.yyyy HH:mm" }}
            <span></span>
            <button (click)="onEditDate(payment)" class="finish-edit"></button>
          </ng-container>
          <ng-template #editDate [formGroup]="paymentDateForm">
            <app-date-picker
              formControlName="paymentAccessibleToDate"
              [minDate]="toMinDate | date : 'yyyy-MM-dd'"
            ></app-date-picker>
            <app-input
              class="time-picker"
              formControlName="paymentAccessibleToTime"
              placeholder="23:59"
              mask="99:99"
            >
            </app-input>
          </ng-template>
        </div>
        <button class="btn-delete" (click)="removeMonth(payment)">
          <img src="./assets/svg/plus-default-gray.svg" alt="" />
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="form">
      <form
        class="prolongate form flex items-center row"
        [formGroup]="form"
        (ngSubmit)="onSubmitProlongate()"
      >
        <div class="month">Месяц {{ paymentDates.length + 1 }}</div>
        <div class="start cg-16">
          с
          <app-date-picker
            formControlName="paymentAccessibleFromDate"
            [minDate]="fromMinDate | date : 'yyyy-MM-dd'"
          ></app-date-picker>
          <app-input
            class="time-picker"
            formControlName="paymentAccessibleFromTime"
            placeholder="00:00"
            mask="99:99"
          >
          </app-input>
        </div>
        <div class="finish cg-16">
          по
          <app-date-picker
            formControlName="paymentAccessibleToDate"
            [minDate]="toMinDate | date : 'yyyy-MM-dd'"
          ></app-date-picker>
          <app-input
            class="time-picker"
            formControlName="paymentAccessibleToTime"
            placeholder="23:59"
            mask="99:99"
          >
          </app-input>
        </div>
        <button class="btn-delete" (click)="removeForm()">
          <img src="./assets/svg/plus-default-gray.svg" alt="" />
        </button>
      </form>
    </ng-container>
  </div>

  <div class="footer flex justify-between">
    <app-button
      [isDisabled]="!canAdd()"
      text="ADD_PROLONGATE_DATE"
      (onButtonClick)="addMonth()"
    ></app-button>
    <app-button
      [isDisabled]="!isValidForm()"
      text="SAVE_CHANGES"
      (onButtonClick)="onSubmitProlongate()"
    ></app-button>
  </div>
</div>
