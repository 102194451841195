import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-events-creator',
  templateUrl: './events-creator.component.html',
  styleUrls: ['./events-creator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventsCreatorComponent {
  @Input() isOpen: boolean = false;
}
