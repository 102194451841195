<div class="stream-card border bg-white radius-16">
  <div class="header">
    <div class="description flex flex-col">
      <div class="title">
        <span (click)="onTitleClickHandler()">{{ title }}</span>

        <ng-container *ngIf="canManipulate()">
          <button [matMenuTriggerFor]="menu">
            <img class="icon" src="assets/svg/triple-dot.svg" alt="" />
          </button>
        </ng-container>
      </div>
      <div class="month">{{ description ?? "-" }}</div>
    </div>
  </div>
  <div class="footer flex justify-between">
    <div class="year">{{ footer ?? "-" }}</div>
    <div class="switcher">
      <mat-slide-toggle
        color="accent"
        [checked]="status"
        (change)="onToggleChange($event)"
      >
        {{ (status ? "ACTIVE" : "INACTIVE") | translate}}
      </mat-slide-toggle>
    </div>
  </div>
  <mat-menu #menu="matMenu">
    <ng-container *ngIf="canManipulate()">
      <app-menu-default
        [canEdit]="canEdit"
        [canDelete]="canDelete"
        [canDuplicate]="canDuplicate"
        (editClick)="editClick()"
        (deleteClick)="deleteClick()"
        (duplicateClick)="duplicateClick()"
      ></app-menu-default>
    </ng-container>
  </mat-menu>
</div>
