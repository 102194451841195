import { FilesService } from 'src/app/core/services/files/files.service';
import { TranslateService } from '@ngx-translate/core';
import { SideBarService } from 'src/app/feature/metodist/services/side-bar.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { UsersService } from './../../services/users/users.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import {
  HeaderSize,
  Languages,
} from 'src/app/shared/models';
import {Roles} from 'src/app/core/model/enum/roles.enum';
import {HeaderService} from 'src/app/core/services/header/header.service';
import {
  CuratorUser,
  ICommonUser,
  MethodistRoutes,
  MethodistUser,
  StudentRoutes,
  StudentUser,
} from '../../model';
import { ButtonColors } from 'src/app/shared/models/enums/button.enum';
import { Observable, Subscription, zip } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectProfileState } from '../../state/student/user/user.selector';
import { updateUserProfileInfo } from '../../state/student/user/user.actions';
import { SubjectsService } from './../../services/subjects/subjects.service';
import { StudentService } from "../../services/student/student.service";
import { IGroup } from "../../model/interface/group";

enum VideoTabs {
  All = 'all',
  First = 'first',
  Last = 'last',
}

@Component({
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent implements OnInit {
  public profile?: any;
  public profile$: Observable<StudentUser | MethodistUser | null | undefined> = this.store.select(selectProfileState);
  public profileSubscription!: Subscription;

  public studentActiveCourses: IGroup[] = [];
  public studentInactiveCourses: IGroup[] = [];
  public headTeachers?: ICommonUser[] = [];
  public RolesEnum = Roles;

  public role: Roles = Roles.Student;
  isInfoOpen: boolean = false;

  public isOpen: boolean = false;
  public imageUrl: string = '';

  public VideoTabsEnum = VideoTabs;
  public activeVideoTab: VideoTabs = VideoTabs.All;

  public isDialogOpen: boolean = false;
  public loading: boolean = true;

  public buttonColor: ButtonColors = ButtonColors.Brand;

  public language: Languages = Languages.KZ;

  public isAttentionOpen: boolean = false;
  constructor(
    private cdr: ChangeDetectorRef,
    private headerService: HeaderService,
    private authService: AuthService,
    private usersService: UsersService,
    private MethodistSideBarService: SideBarService,
    private translateService: TranslateService,
    private filesService: FilesService,
    private store: Store,
    public subjectsService: SubjectsService,
    private studentService: StudentService
  ) {
    this.language = this.translateService.currentLang as Languages;
    if (window.innerWidth > 999) {
      headerService.setName('Профиль');
      headerService.setSize(HeaderSize.DEFAULT);
      headerService.setBackButtonName('MAIN_PAGE');
      headerService.setBackButtonValue(`${StudentRoutes.Main}`);
    }

    this.role = this.authService.getRole();
  }

  ngOnInit(): void {
    if(this.role === Roles.Student) {
      this.profileSubscription = this.profile$.subscribe({
        next: (res) => {
          console.log('profile: ', res)
          if(res && res.id) {
            this.profile = res;
            this.imageUrl = res.profilePhotoUrl ? res.profilePhotoUrl : '';
            this.role = res.roles[0];

            this.getStudentCourseFinished();
          }
          this.loading = false;
          this.cdr.detectChanges();
        },
        error: (err) => {
          console.log(err);
          this.loading = false;
          this.cdr.detectChanges();
        }
      });
    }
    else {
      this.usersService
        .getUserProfile()
        .subscribe((res: StudentUser & MethodistUser & CuratorUser) => {
          this.profile = res;
          this.imageUrl = res.profilePhotoUrl ? res.profilePhotoUrl : '';
          this.role = res.roles[0];

          if (this.role === Roles.Methodist) {
            this.MethodistSideBarService.getIdSubject().subscribe((res) => {
              this.headerService.setBackButtonValue(
                `${MethodistRoutes.Main}/${res.value}`
              );
            });
          }

          if (this.role === Roles.Curator) {
            this.headTeachers = (res as CuratorUser).headTeachers
          }
          this.loading = false;
          this.cdr.detectChanges();
        });
    }
  }

  public openAttention(): void {
    const url = window.location.origin + '/assets/для_платформы_договор_JUZ40_ONLINE_Информационных_услуг_онлайн (1).pdf';
    window.open(url, '_blank')?.focus();
  }
  public closeAttention(): void {
    this.isAttentionOpen = false;
  }
  
  private getStudentCourseFinished() {
    zip([
      this.studentService.getStudentActive(),
      this.studentService.getStudentInactive()
    ])
      .subscribe({
        next: res => {
          if (res.length) {
            if (res[0]) {
              this.studentActiveCourses = res[0] || [];
            }
            if (res[1]) {
              this.studentInactiveCourses = res[1] || [];
            }
            this.cdr.detectChanges();
          }
        }
      });
  }

  public openFilePicker(): void {
    document.getElementById('imageInput')!.click();
  }

  public onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e: any) => {
      let data: FormData = new FormData();
      data.append('material', file, file.name);
      this.filesService.sendFiles(data, this.profile.id).subscribe((res) => {
        this.store.dispatch(updateUserProfileInfo({payload: {...this.profile, profilePhotoUrl: res.fileUrl}}));
        this.imageUrl = res.fileUrl;

        if(this.authService.getRole() === Roles.Methodist) {
          this.headerService.setProfilePicture(this.imageUrl)
        }
        this.cdr.detectChanges();
      });
    };
    reader.readAsDataURL(file);
  }

  public toggle(): void {
    this.isOpen = !this.isOpen;
  }

  public setActiveTab(tab: VideoTabs): void {
    this.activeVideoTab = tab;
  }

  public openDialog(): void {
    this.isDialogOpen = true;
  }

  public closeDialog(): void {
    this.isDialogOpen = false;
  }
  public confirmDialog(): void {
    this.isDialogOpen = false;
  }

  toggleProfileInfo(): void {
    this.isInfoOpen = !this.isInfoOpen;
  }
}
