import { AuthService } from '../../../../../services/auth/auth.service';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Roles } from 'src/app/core/model';
import { SUBJECTS_ID } from 'src/app/shared/models/subjects';

@Component({
  selector: 'app-admin-student-card',
  templateUrl: './admin-student-card.component.html',
  styleUrls: ['./admin-student-card.component.scss'],
})
export class AdminStudentCardComponent implements OnInit {
  public subjectsId = SUBJECTS_ID;
  @Input() hideCourseEvents: boolean = false;
  @Input() group!: any;
  @Output() removeFromCourseListener = new EventEmitter<string>();

  public readonly roles = Roles;
  public role: Roles = Roles.Curator;

  constructor(private authService: AuthService) {

  }

  ngOnInit(): void {
    this.role = this.authService.getRole();
    console.log(this.group);
  }

  public removeFromCourse(): void {
    this.removeFromCourseListener.emit(this.group.id);
  }
}
