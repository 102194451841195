<div class="app-multi-select">
  <app-form-field
    [name]="name"
    [focused]="focused"
    [isActive]="isActive"
    [error]="error"
  >
    <mat-select
      #select
      color="primary"
      [ngClass]="{'listbox-overflow-hidden': (options.length > 1000)}"
      (focus)="onFocus()"
      (blur)="onBlur()"
      (closed)="onClosed()"
      (valueChange)="onValueChange($event)"
      panelWidth="350px"
      multiple
      [(ngModel)]="preSelectedOptions"
      [placeholder]="placeholder | translate"
      [disabled]="disabled"
    >
      <app-search
        *ngIf="withSearch"
        (searchListener)="onSearch($event)"
      ></app-search>
      <div class="options__wrapper"
           [ngClass]="{
            'options__wrapper-big': (options.length > 1000),
            'options__wrapper-with-apply': withApply,
            'options__wrapper-with-apply-search': withApply && withSearch,
            'options__wrapper-350': setHeight350
            }"
      >
        <mat-option
          *ngIf="withSearch"
          color="primary"
          (click)="toggleAllSelection()"
        >
          {{ "chooseAll" | translate }}
        </mat-option>
        <cdk-virtual-scroll-viewport *ngIf="options.length > 1000" appendOnly  [itemSize]="10" [style.height.px]=200  >
          <mat-option
          color="primary"
          *cdkVirtualFor="let option of filteredOptions"
          [value]="option.value"
        >
          {{ option.name }}
        </mat-option>
        </cdk-virtual-scroll-viewport>
        <div *ngIf="options.length <= 1000">
          <mat-option
            color="primary"
            *ngFor="let option of filteredOptions"
            [value]="option.value"
          >
            {{ option.name }}
          </mat-option>
        </div>
      </div>
      <ng-container *ngIf="withApply">
        <div class="close-select" (click)="closeSelect()">{{'save' | translate}}</div>
      </ng-container>
    </mat-select>
  </app-form-field>
</div>
