import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TableInfoComponent } from './table-info/table-info.component';
import { SharedModule } from '../../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { DiscountComponent } from './discount/discount.component';
import { SubjectsWithDiscountsComponent } from './subjects-with-discounts/subjects-with-discounts.component';
import { DiscountCreaterComponent } from './discount-creater/discount-creater.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DiscountRemoveComponent } from './discount-remove/discount-remove.component';
import { DicountUserInfoEditorComponent } from './dicount-user-info-editor/dicount-user-info-editor.component';
import { RefundComponent } from './refund/refund.component';

@NgModule({
  declarations: [
    TableInfoComponent, 
    DiscountComponent, 
    SubjectsWithDiscountsComponent, 
    DiscountCreaterComponent,
    DiscountRemoveComponent,
    DicountUserInfoEditorComponent,
    RefundComponent
  ],
  exports: [
    TableInfoComponent, 
    DiscountComponent, 
    SubjectsWithDiscountsComponent, 
    DiscountCreaterComponent,
    DiscountRemoveComponent,
    DicountUserInfoEditorComponent,
    RefundComponent
  ],
  imports: [
    CommonModule, 
    RouterModule, 
    SharedModule, 
    TranslateModule, 
    ReactiveFormsModule
  ],
})
export class ComponentsModule {}
