import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  IRatingTableHeader,
  IRatingTableRow,
} from 'src/app/core/model/interface/catalogues';
import { CuratorService } from 'src/app/core/services/curator/curator.service';

@Component({
  selector: 'app-table-grade',
  templateUrl: './table-grade.component.html',
  styleUrls: ['./table-grade.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TableGradeComponent implements OnInit {
  @Input() headerColumns: IRatingTableHeader[] = [];
  @Input() rows: IRatingTableRow[] = [];
  @Input() footerSpans?: any;

  public isScrollingTableByY: boolean = false;

  constructor(private _curatorService: CuratorService) {}
  ngOnInit(): void {

  }

  public scroll(event: any) {
    if (event.target.scrollLeft > 10) {
      this.isScrollingTableByY = true;
    } else {
      this.isScrollingTableByY = false;
    }
  }

  public onNameClick(name: string, id: number): void {
    this._curatorService.toggleIsShowStudentModal();
  }
}
