import { TranslateService } from '@ngx-translate/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Languages, SelectValue } from 'src/app/shared/models';
import { IShortLesson, LessonTypeKZ, LessonTypeRU } from 'src/app/core/model';
import { ToastrService } from 'ngx-toastr';
import { SUBJECTS_OPTIONS } from "../../../../shared/models/subjects";
import { CoursesService } from "../../../../core/services/courses/courses.service";
import { ThemeService } from "../../../../core/services/theme/theme.service";
import { ThemeDTO } from "../../../../core/model/interface/theme";
import { MethodistService } from "../../../../core/services/methodist/methodist.service";

@Component({
  selector: 'app-add-lesson-from-course',
  templateUrl: './add-lesson-from-course.component.html',
  styleUrls: ['./add-lesson-from-course.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddLessonFromCourseComponent implements OnInit, OnChanges {
  @Input() isOpen: boolean = false;
  @Input() themeId: string = "";

  @Output() closeDialog = new EventEmitter<void>();
  @Output() createEvent = new EventEmitter<void>();

  public form!: FormGroup;

  public subjectOptions: any[] = SUBJECTS_OPTIONS;
  public coursesOptions: SelectValue[] = [];
  public themeList: any[] = [];
  public lessonsList!: IShortLesson[];

  public taskType!: any;

  public isCourseOptionsIsLoading: boolean = false;
  public isThemesOptionsIsLoading: boolean = false;
  public isDisabledBtn: boolean = false;

  constructor(
      private formBuilder: FormBuilder,
      private translate: TranslateService,
      private toastrService: ToastrService,
      private cdr: ChangeDetectorRef,
      private coursesService: CoursesService,
      private themeService: ThemeService,
      private methodistService: MethodistService
    ) {
    if (translate.getDefaultLang() === Languages.KZ) {
      this.taskType = LessonTypeKZ;
    }
    if (translate.getDefaultLang() === Languages.RU) {
      this.taskType = LessonTypeRU;
    }
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      subject: [null, Validators.required],
      course: [null, Validators.required],
      theme: [null, Validators.required]
    });
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isOpen'] && this.form) {
      this.form.reset();
      this.lessonsList = [];
      this.coursesOptions = [];
      this.themeList = [];
    }
  }

  public onSelectSubject(event: any): void {
    if (!event) return;
    this.getMethodistCourses(event);
  }

  public onSelectCourse(event: string | number | any): void {
    if (!event) return;
    this.getThemeList(event as string);
  }

  public onSelectTheme(event: string | number | any): void {
    if (!event) return;
    this.getLessonList(event as string);
  }

  private getMethodistCourses(subjectId: string) {
    this.isCourseOptionsIsLoading = true;
    this.lessonsList = [];
    this.coursesOptions = [];
    this.themeList = [];
    this.form.get('course')?.reset();
    this.form.get('theme')?.reset();
    this.coursesService.getCoursesListMethodist(subjectId, 0, '', 100)
      .subscribe({
        next: (res) => {
          this.coursesOptions = res.content.map(t => {
            return {
              name: t.name,
              value: t.id
            }
          });

          if (!this.coursesOptions?.length) {
            this.toastrService.warning('Нет курсов!');
          }
          this.isCourseOptionsIsLoading = false;
          this.cdr.detectChanges();
        }
      });
  }

  private getThemeList(courseId: string): void {
    this.isThemesOptionsIsLoading = true;
    this.themeList = [];
    this.lessonsList = [];
    this.form.get('theme')?.reset();

    this.themeService.getThemeList(courseId)
      .subscribe((res: ThemeDTO[]) => {
        this.themeList = res.map(t => {
          return {
            name: t.name,
            value: t.id
          }
        });

        if (!this.themeList?.length) {
          this.toastrService.warning('Нет тем!');
        }
        this.isThemesOptionsIsLoading = false;
        this.cdr.detectChanges();
      });
  }

  private getLessonList(themeId: string) {
    this.methodistService.getLessonsList(themeId)
      .subscribe({
        next: (res) => {
          this.lessonsList = res;
          if (!this.lessonsList?.length) {
            this.toastrService.warning('Нет уроков!');
          }
          this.cdr.detectChanges();
        },
        error: (err) => {
          console.log(err);
        }
      });
  }

  get list(): FormArray {
    return this.form.get("list") as FormArray;
  }

  public checkLesson(lesson: IShortLesson): void {
    lesson.checked = !lesson.checked;
  }

  public addLesson() {
    this.isDisabledBtn = true;
    this.cdr.detectChanges();
    this.methodistService.addLessons({
      themeId: this.themeId,
      lessonIds: this.lessonsList?.filter(x => x.checked)?.map(t => t.id)
    })
      .subscribe({
        next: _ => {
          this.toastrService.success('Вы успешно добавили урок с другого курса', 'Успех');
          this.createEvent.emit();
          this.closeDialog.emit();
        },
        error: err => {
          this.toastrService.error(err?.error?.messages?.[0], "Ошибка");
          this.isDisabledBtn = false;
          this.cdr.detectChanges();
        }
      });
  }

  get disableBtn(): boolean {
    return this.lessonsList?.findIndex(t => t.checked) < 0;
  }
}
