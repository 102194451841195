<app-form-field
  [name]="name"
  [focused]="focused"
  [isActive]="isActive"
  [error]="error"
>
  <textarea
    class="iconic-textarea"
    (focus)="onFocus()"
    (blur)="onBlur()"
    [formControl]="ngControl.control"
    [placeholder]="placeholder | translate"
    [readOnly]="readOnly"
  >
  </textarea>
</app-form-field>
