<form class="content" [formGroup]="form">
    <div class="full">
      <app-input
        [error]="formErrors.questionText"
        class="input-input"
        name="QUESTION_TEXT"
        formControlName="questionText"
        (input)="handleFormControlErrors(form, formErrors, 'questionText')">
      </app-input>
    </div>
    <h1 class="name">{{'ANSWER_OPTIONS' | translate}}</h1>
    <ul class="list" cdkDropList (cdkDropListDropped)="drop($event)" formArrayName="questions">
      <li class="item" *ngFor="let questionForm of questions.controls;let i = index" [formGroupName]="i" cdkDrag>
        <div class="question" cdkDragPlaceholder>
          <img src="assets/svg/ic_sharp-drag-indicator.svg" alt="" />
          <div class="question-body-wrapper">
            <div class="question-body" [ngClass]="{'question-body--error': errorVariantText[i]}">
              <input class="input" [placeholder]="'QUESTION' | translate" formControlName="key">
              <div class="iconic-line"></div>
              <input class="input" [placeholder]="'ANSWER' | translate" formControlName="value">
            </div>
            <span class="error-message" *ngIf="errorVariantText[i]">{{errorVariantText[i] | translate}}</span>
          </div>
        </div>
      </li>
    </ul>
    <button class="button" (click)="addQuestion()">{{'ADD_OPTION' | translate}}</button>
</form>
