import { BaseRoutes, Keys, LessonType, MethodistUser, Roles, StudentUser } from 'src/app/core/model/index';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HeaderSize } from 'src/app/shared/models/enums/themes.enum';
import { Languages, RedirectInfo } from '../../models';
import { HeaderService } from 'src/app/core/services/header/header.service';
import { RoleService } from "../../../core/services/role/role.service";
import { NotificationService } from "../../../core/services/notification/notification.service";
import { environment } from "../../../../environment";
import { MatMenuTrigger } from '@angular/material/menu';
import { Store } from '@ngrx/store';
import { resetProgress } from 'src/app/core/state/student/progress/progress.actions';
import { resetGroups } from 'src/app/core/state/student/groups/groups.actions';
import { resetUser } from 'src/app/core/state/student/user/user.actions';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import { AnnouncementService } from "../../../core/services/announcement/announcement.service";
import { StudentHelperService } from 'src/app/feature/student/service/student-helper.service';
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { selectProfileState } from 'src/app/core/state/student/user/user.selector';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/core/services/users/users.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('lessonMenuTrigger') lessonMenuTrigger: MatMenuTrigger | undefined;
  @ViewChild('baseMenuTrigger') baseMenuTrigger: MatMenuTrigger | undefined;

  private isLessonMenuOpen: boolean = false;
  private isBaseMenuOpen: boolean = false;

  public readonly LessonType = LessonType;
  headerSizeEnum = HeaderSize;

  public name: string = "";
  public backButtonName: string = "";
  public backButtonValue: string = "";
  public backButtonValueQueryParams: any = null;
  public size: HeaderSize = HeaderSize.DEFAULT;
  public desc: string = "";
  public fioField: string = "";
  public menuList: RedirectInfo[] = [];
  public activeMenuName: string = "";
  public showLessonMenu: boolean = false;

  public imageUrl: string = "";
  public userName: string = "";
  languagesEnum = Languages;
  public currentLang: Languages = Languages.KZ;

  public nonReadExists$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public isMobile: boolean = false;

  public Roles = Roles;
  public role: Roles = Roles.Student;

  public profile$: Observable<StudentUser | MethodistUser | null | undefined> = this.store.select(selectProfileState);
  public profileSubscription!: Subscription;

  public isStudentRoute: boolean = false;
  public isFullScreen: boolean = false;

  constructor(
    private announcementService: AnnouncementService,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private headerService: HeaderService,
    private cdr: ChangeDetectorRef,
    private roleService: RoleService,
    private store: Store,
    private storageService: StorageService,
    private studentHelperService: StudentHelperService,
    private router: Router,
    private usersService: UsersService
  ) {
    const storageLanguage = this.storageService.getItem(Keys.Language);
    if(storageLanguage) {
      if((storageLanguage as string) === "kz") {
        this.setCurrentLang(Languages.KZ)
      }
      else {
        this.setCurrentLang(storageLanguage);
      }
    }
    else {
      this.setCurrentLang(translate.getDefaultLang() as Languages);
    }

    if(window.innerWidth < 999) {
      this.isMobile = true;
    }
  }


  ngOnInit(): void {
    this.role = this.authService.getRole();

    this.router.events.subscribe(() => {
      this.isStudentRoute = this.router.url.includes(BaseRoutes.Student);
      this.cdr.detectChanges()
    });

    this.nonReadExists$ = this.announcementService.nonReadExists$;
    this.announcementService.nonReadExists().subscribe({
      next: (nonReadExists: boolean) => {
        this.announcementService.nonReadExists$.next(nonReadExists);
      },
      error: (err) => {
        console.log(err);
      }
    });

    this.headerService.getName().subscribe(name => {
      this.name = name;
      this.cdr.detectChanges();
    });

    this.headerService.getBackButtonName().subscribe(backButtonName => {
      this.backButtonName = backButtonName;
      this.cdr.detectChanges();
    });

    this.headerService.getBackButtonValue().subscribe(backButtonValue => {
      this.backButtonValue = backButtonValue;
      if (this.backButtonValue.includes('?')) {
        const parse = this.parseUrl(this.backButtonValue);
        this.backButtonValue = parse.path;
        this.backButtonValueQueryParams = parse.queryParams;
      } else {
        this.backButtonValueQueryParams = null;
      }
      this.cdr.detectChanges();
    });

    this.headerService.getDescription().subscribe(description => {
      this.desc = description;
      this.cdr.detectChanges();
    });

    this.headerService.getFioFieldValue().subscribe(fio => {
      this.fioField = fio;
      this.cdr.detectChanges();
    });

    this.headerService.getLessonMenuValue().subscribe(isShowLessonMenu => {
      this.showLessonMenu = isShowLessonMenu;
      this.cdr.detectChanges();
    });

    this.headerService.getSize().subscribe(size => {
      this.size = size;
      this.cdr.detectChanges();
    });

    this.headerService.getMenuList().subscribe(menu => {
      this.menuList = menu;
      this.cdr.detectChanges();
    });

    this.headerService.getActiveMenuName().subscribe(name => {
      this.activeMenuName = name;
      this.cdr.detectChanges();
    });

    if(this.authService.getRole() !== Roles.Methodist) {
      this.profileSubscription = this.profile$.subscribe({
        next: (res) => {
          if(res) {
            if(res.profilePhotoUrl) {
              this.imageUrl = res.profilePhotoUrl || '';
            }
            else {
              this.userName = res.firstname;
            }
          }
          this.cdr.detectChanges();
        },
        error: (err) => {
          console.log(err);
        }
      });
    }
    else {
      this.usersService.getUserProfile().subscribe({
        next: (res) => {
          if(res) {
            if(res.profilePhotoUrl) {
              this.imageUrl = res.profilePhotoUrl || '';
            }
            else {
              this.userName = res.firstname;
            }
          }
          this.cdr.detectChanges();
        },
        error: (err) => {
          console.log(err);
        }
      });

      this.headerService.getProfilePicture().subscribe({
        next: (res) => {
          if(res) {
            this.imageUrl =  res || '';
          }
          this.cdr.detectChanges();
        },
        error: (err) => {

        }
      })
    }
  };

  ngOnDestroy(): void {
    if(this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
  }

  private parseUrl(urlString: string) {
    const url = new URL(urlString, window.location.origin);
    const path = url.pathname;

    const queryParams: { [key: string]: string } = {};
    url.searchParams.forEach((value, key) => {
      queryParams[key] = value;
    });

    return {path, queryParams};
  }

  convertUtcToLocal(utcTimeString: string): Date {
    const utcTime = new Date(utcTimeString);
    return new Date(utcTime.getTime() - utcTime.getTimezoneOffset() * 60000);
  };

  public emptyName(): void {
    this.name = '';
  }

  public navigateTo(): void {
    this.router.navigate([`/${this.role.toLowerCase()}${this.role !== Roles.Admin ? '/main' : ''}`]).then();
  }

  public setCurrentLang(lang: Languages): void {
    this.currentLang = lang;
    this.storageService.setItem(Keys.Language, lang);
    this.translate.use(lang);
  }

  public logout(): void {
    this.authService.logout();
    this.store.dispatch(resetProgress());
    this.store.dispatch(resetUser());
    this.store.dispatch(resetGroups());
  }

  public generateProfileRoute(): string {
    return `/${this.roleService.getBaseRouteByCurrentRole()}/profile`;
  };

  public isAbleToShowLoginThroughAnotherUser(): boolean {
    return Roles.Admin == this.authService.getRole();
  }

  toggleNotificationModal() {
    this.notificationService.toggleIsShowNotificationModal();
  }

  toggleLessonMenu(): void {
    if(!this.lessonMenuTrigger) {
      return;
    }

    if(this.isLessonMenuOpen) {
      this.lessonMenuTrigger.closeMenu();
      this.isLessonMenuOpen = false;
    }
    else {
      this.lessonMenuTrigger.openMenu();
      this.isLessonMenuOpen = true;
    }
  }

  closeLessonMenu(): void {
    if(!this.lessonMenuTrigger) {
      return;
    }

    this.lessonMenuTrigger.closeMenu();
    this.isLessonMenuOpen = false;
  }

  toggleBaseMenu(): void {
    if(!this.baseMenuTrigger) {
      return;
    }

    if(this.isBaseMenuOpen) {
      this.baseMenuTrigger.closeMenu();
      this.isBaseMenuOpen = false;
    }
    else {
      this.baseMenuTrigger.openMenu();
      this.isBaseMenuOpen = true;
    }
  }

  public generateLessonsSiblingsRoute(item: RedirectInfo): string {
    return this.studentHelperService.generateLessonsSiblingsRoute(item);
  }

  get isStudentRole() {
    return this.authService.getRole() === Roles.Student;
  }

  public toggleFullScreen(): void {
    this.isFullScreen = !this.isFullScreen;
    if(this.isFullScreen) {
      this.open()
    }
    else {
      this.close();
    }
  }
  open() {
    const elem: any = document.getElementsByTagName('body')[0];


    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }

  close(): void {
    const doc: any = document;
    if (doc.exitFullscreen) {
      doc.exitFullscreen();
    } else if (doc.mozCancelFullScreen) { /* Firefox */
      doc.mozCancelFullScreen();
    } else if (doc.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      doc.webkitExitFullscreen();
    } else if (doc.msExitFullscreen) { /* IE/Edge */
      doc.msExitFullscreen();
    }
  }
}
