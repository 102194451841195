import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ICatalogue } from 'src/app/core/model/interface/catalogues';
import { CataloguesService } from 'src/app/core/services/catalogues/catalogues.service';
import { SUBJECTS_ID } from 'src/app/shared/models/subjects';

@Component({
  selector: 'app-cart-modal-card',
  templateUrl: './cart-modal-card.component.html',
  styleUrls: ['./cart-modal-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartModalCardComponent implements OnInit {
  @Input() card!: ICatalogue;

  public subjectsId = SUBJECTS_ID

constructor(
  private _catalogueService: CataloguesService
) {

}

  ngOnInit(): void {
  }

  public removeItemFromCart(card: ICatalogue) : void {
    this._catalogueService.removeCardFromCart(card)
  }
}
